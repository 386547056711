import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import { responseMessage } from '../../../utils/alert';
import Footer from "../../Includes/Footer/Footer";

import { fetchInvitationData } from '../../../actions/authenticationActions';
import Header from '../../Includes/Header/Header';
import { ChevronRight } from 'react-bootstrap-icons';

const WelcomeLogo = () => (
  <svg className='w-100' width="183" height="182" viewBox="0 0 183 182" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M78.2164 60.0064C79.5711 62.3503 81.5186 64.2964 83.8635 65.6495C86.2083 67.0025 88.8679 67.7148 91.5751 67.7148C94.2823 67.7148 96.9419 67.0025 99.2867 65.6495C101.632 64.2964 103.579 62.3503 104.934 60.0064L115.406 41.8513L125.879 23.7177C127.237 21.3745 127.953 18.7148 127.956 16.0066C127.958 13.2984 127.247 10.6374 125.893 8.29162C124.54 5.94588 122.592 3.99833 120.246 2.64517C117.9 1.29201 115.239 0.58108 112.531 0.583993V0.583993C109.823 0.583252 107.163 1.29714 104.819 2.65362C102.475 4.0101 100.531 5.96109 99.1827 8.3095L78.2164 44.684C76.8661 47.0271 76.1553 49.6839 76.1553 52.3882C76.1553 55.0925 76.8661 57.7492 78.2164 60.0922" fill="white" />
    <path d="M67.7436 41.8513L68.9237 43.89L93.8601 0.583993H70.6192C67.9109 0.58108 65.2498 1.29201 62.9039 2.64517C60.5579 3.99833 58.6102 5.94588 57.2568 8.29162C55.9033 10.6374 55.1918 13.2984 55.1943 16.0066C55.1969 18.7148 55.9133 21.3745 57.2712 23.7177L67.7436 41.8513Z" fill="white" />
    <path d="M44.9316 79.4705C46.2843 81.8135 48.23 83.759 50.5732 85.1114C52.9163 86.4638 55.5741 87.1753 58.2795 87.1746H58.8375C61.5457 87.1775 64.2069 86.4666 66.5528 85.1135C68.8987 83.7603 70.8467 81.8128 72.2002 79.467C73.5537 77.1213 74.2649 74.4602 74.2623 71.752C74.2598 69.0438 73.5434 66.3841 72.1855 64.0409L61.4556 45.242L50.597 26.4431C49.2423 24.0993 47.2947 22.1531 44.9499 20.8C42.6051 19.447 39.9455 18.7347 37.2383 18.7347C34.5311 18.7347 31.8715 19.447 29.5267 20.8C27.1818 22.1531 25.234 24.0993 23.8793 26.4431L23.5788 26.9796C22.2248 29.3292 21.5122 31.9934 21.5122 34.7052C21.5122 37.417 22.2248 40.0812 23.5788 42.4308L44.9316 79.4705Z" fill="white" />
    <path d="M15.4248 87.1746H39.5242L14.0943 43.2462L12.9354 45.242L2.07684 64.0409C0.718958 66.3841 0.00254783 69.0438 6.78515e-06 71.752C-0.00253426 74.4602 0.708688 77.1213 2.06217 79.467C3.41565 81.8128 5.36361 83.7603 7.70953 85.1135C10.0555 86.4666 12.7166 87.1775 15.4248 87.1746" fill="white" />
    <path d="M58.5805 125.416C61.2851 125.412 63.9414 124.699 66.2838 123.347C68.6261 121.995 70.5725 120.052 71.9285 117.712L72.2075 117.218C73.5594 114.873 74.2703 112.212 74.2686 109.505C74.2669 106.797 73.5528 104.138 72.1981 101.794C70.8433 99.4495 68.8955 97.5032 66.5504 96.15C64.2054 94.7968 61.5455 94.0846 58.838 94.0847H15.4462C12.7387 94.0846 10.0789 94.7968 7.7338 96.15C5.38872 97.5032 3.44092 99.4495 2.08617 101.794C0.73142 104.138 0.0172951 106.797 0.0156279 109.505C0.0139607 112.212 0.724878 114.873 2.07674 117.218L2.39843 117.755C3.75023 120.105 5.69838 122.057 8.0458 123.413C10.3932 124.77 13.057 125.483 15.7682 125.481L58.5805 125.416Z" fill="white" />
    <path d="M62.4651 133.957H11.6909L12.8496 135.974L23.7084 154.773C25.0602 157.118 27.0055 159.065 29.3487 160.418C31.6919 161.772 34.3503 162.485 37.0564 162.485C39.7625 162.485 42.4209 161.772 44.7641 160.418C47.1073 159.065 49.0526 157.118 50.4044 154.773L61.2632 135.974L62.4651 133.957Z" fill="white" />
    <path d="M105.063 121.231L104.784 120.759C103.429 118.415 101.482 116.469 99.1368 115.116C96.792 113.763 94.1324 113.051 91.4252 113.051C88.718 113.051 86.0584 113.763 83.7136 115.116C81.3687 116.469 79.4209 118.415 78.0662 120.759L67.1647 139.537L56.4347 158.336C55.0769 160.679 54.3605 163.338 54.3579 166.047C54.3554 168.755 55.0669 171.416 56.4203 173.762C57.7738 176.107 59.7215 178.055 62.0674 179.408C64.4134 180.761 67.0745 181.472 69.7827 181.469H70.4052C73.1159 181.469 75.7786 180.756 78.1257 179.399C80.4727 178.043 82.4211 176.092 83.7746 173.744L105.235 136.64C106.585 134.297 107.296 131.64 107.296 128.936C107.296 126.231 106.585 123.574 105.235 121.231" fill="white" />
    <path d="M115.621 139.537L114.44 137.391L89.0962 181.384H113.131C115.84 181.386 118.501 180.675 120.847 179.322C123.193 177.969 125.14 176.022 126.494 173.676C127.847 171.33 128.559 168.669 128.556 165.961C128.553 163.253 127.837 160.593 126.479 158.25L115.621 139.537Z" fill="white" />
    <path d="M137.982 101.918C136.628 99.5771 134.682 97.6347 132.338 96.286C129.995 94.9373 127.338 94.2299 124.634 94.235H124.076C121.371 94.2343 118.713 94.9456 116.37 96.2977C114.028 97.6497 112.082 99.5947 110.729 101.937C109.376 104.28 108.664 106.937 108.664 109.642C108.663 112.347 109.376 115.005 110.728 117.347L121.565 136.146L132.424 154.945C133.779 157.289 135.726 159.235 138.071 160.588C140.416 161.941 143.076 162.653 145.783 162.653C148.49 162.653 151.15 161.941 153.494 160.588C155.839 159.235 157.787 157.289 159.141 154.945L159.442 154.408C160.796 152.063 161.509 149.402 161.509 146.694C161.509 143.985 160.796 141.324 159.442 138.979L137.982 101.918Z" fill="white" />
    <path d="M167.575 94.235H143.476L168.906 138.163L170.065 136.146L180.923 117.369C182.281 115.025 182.997 112.366 183 109.658C183.003 106.949 182.291 104.288 180.938 101.943C179.584 99.5968 177.637 97.6492 175.291 96.2961C172.945 94.9429 170.284 94.2321 167.575 94.235" fill="white" />
    <path d="M180.687 63.6331C179.331 61.2885 177.382 59.3422 175.035 57.9898C172.688 56.6375 170.026 55.9267 167.318 55.929H124.398C121.692 55.9391 119.036 56.6576 116.694 58.013C114.352 59.3684 112.406 61.3135 111.05 63.6546L110.771 64.1267C109.419 66.4725 108.708 69.1328 108.71 71.8403C108.712 74.5478 109.426 77.2072 110.781 79.5514C112.135 81.8955 114.083 83.8419 116.428 85.1951C118.773 86.5482 121.433 87.2606 124.141 87.2605H167.532C170.24 87.2606 172.9 86.5482 175.245 85.1951C177.59 83.8419 179.538 81.8955 180.892 79.5514C182.247 77.2072 182.961 74.5478 182.963 71.8403C182.965 69.1328 182.254 66.4725 180.902 64.1267L180.687 63.6331Z" fill="white" />
    <path d="M120.536 47.4524H171.31L170.151 45.4566L159.292 26.6577C157.941 24.3134 155.995 22.3665 153.652 21.0128C151.309 19.6591 148.65 18.9464 145.944 18.9464C143.238 18.9464 140.58 19.6591 138.236 21.0128C135.893 22.3665 133.948 24.3134 132.596 26.6577L121.738 45.4566L120.536 47.4524Z" fill="white" />
  </svg>


)

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchInvitation: null,
      redirect: false,
      plan_529: false,
      plan_tr: false,
      errors: null
    }
  }
  componentDidMount() {
    const { fetchInvitationData } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    const plan_529 = query.get('plan_529');
    const plan_tr = query.get('plan_tr');

    if (token === null) {
      this.setState({ redirect: true });
    } else {
      this.setState({
        redirect: false,
        plan_529: plan_529,
        plan_tr: plan_tr
      });
      fetchInvitationData(token);
    }
  }
  componentWillReceiveProps = (nextProps) => {

    if (this.props.fetchInvitation !== nextProps.fetchInvitation) {
      this.setState({
        fetchInvitation: nextProps.fetchInvitation
      })
      console.log(`/registration?token=${nextProps.fetchInvitation.invitation_token}`)
      this.props.history.push({
        pathname: `/registration`,
        search: `?token=${nextProps.fetchInvitation.invitation_token}`
      })
    }
    if (this.props.errors !== nextProps.errors) {
      responseMessage("error", nextProps.errors.errors.error, "");
      this.setState({ redirect: true });
    }
  }
  render() {
    const { redirect, fetchInvitation } = this.state;
    if (redirect) {
      return <Redirect to='/login' />;
    }
    else if (fetchInvitation) {
      return (
        <>
          <Header showLogoOnly={true} />
          <div className="G-page-background-color">
            <div className="G-page-main-container my-5">
              <div className="bg-white p-4 Gborder-05 rounded-lg G-grid11-2 mob-reverse-1200">
                <div>
                  <h3 className="Gfs-225 mb-4 Gt-slate-5">Hey  <span className="G-text-grad sans-bold">
                    {fetchInvitation.full_name}!</span>
                  </h3>
                  <p className="sans-medium Gfs-125">
                    Your employer has partnered with Vault to help you find the best path to freedom from student debt.
                  </p>
                  <p className="sans-semibold Gfs-125">
                    Create an account and lower your payments, reduce your interest rates, and more.
                  </p>
                  <p className="sans-medium Gfs-125">
                    The average Vault user reduces their loan balance by over $6,500 and their payoff time by over 6 years. Let's go!
                  </p>
                  <div className='d-flex flex-wrap'>
                    <Link
                      to={{
                        pathname: `/registration`,
                        search: `?token=${fetchInvitation.invitation_token}`
                      }}>
                      <button className='G-orange-button mr-3'>Yes! Let's go!</button>
                    </Link>
                    <Link to="/login" className='my-3 G-link text-decoration-none cancelLink'>
                      I already have an account. I'll sign in <ChevronRight />
                    </Link>
                  </div>
                </div>
                <div className="introAdvisorBannerHeight my-auto rounded-lg tuitionIntroBannerBackground d-flex align-items-center p-4">
                  <WelcomeLogo />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )
    } else {
      return null
    }
  }
}

Index.propTypes = {
  fetchInvitationData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  fetchInvitation: state.authentication.fetchInvitation,
  errors: state.errors
});
export default connect(mapStateToProps, { fetchInvitationData })(Index);