import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { sortAdvisorServicers } from '../dashboardCommon';
import { sortLoanType } from '../dashboardCommon';

import { getAdvisorServicers, getLoanById, getLoanType, updateLoan, deleteLoan, resetDeletedLoanState } from '../../../actions/advisorActions';
import { fetchCustomer } from '../../../actions/customerActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";

import { Form } from 'react-bootstrap';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage'
import Select from 'react-select'
import CustomModal from '../../shared/CustomModal/CustomModal';
import { Check, InfoCircle } from 'react-bootstrap-icons';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb'
import { getEditLoanBreadcrumb } from '../CommonBreadcrumb/Breadcrumb.constant';


class LoansEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advisorServicers: [],
      customer: null,
      loan: {},
      loanTypeData: [],
      servicerId: "",
      otherServicerName: "",
      loanType: "",
      federal: "",
      showOtherServicer: false,
      currentBalance: "",
      originationBalance: "",
      monthlyPayment: "",
      interestRate: "",
      originationDate: "",
      deleteLoanResponse: 0,
      modalProp: {
        show: false,
        title: '',
        bodyText: '',
        footerButton: true,
        footerButtonName: '',
        svgType: '', //check, info
        footerButtonOnclick: '',
        footerButtonHref: '',
        footerButtonOnclickArgs: ''
      }
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor | Edit Loan Details"
    const { getAdvisorServicers, fetchCustomer, getLoanById, getLoanType } = this.props;
    const loanId = this.props.match.params.loanId;

    getAdvisorServicers();
    fetchCustomer();
    getLoanById(loanId);
    getLoanType();
    intercomTrackEvent(eventNames.visitedAdvisorEditLoan);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loan !== this.props.loan) {
      const servicerId = this.props.loan.relationships.servicer.data.id;
      const loanType = this.props.loan.attributes.loan_type;
      const federal = this.props.loan.attributes.loan_type !== 'Private' ? true : false;
      const otherServicerName = this.props.loan.attributes.other_servicer_name ? this.props.loan.attributes.other_servicer_name : "";
      const currentBalance = this.props.loan.attributes.origination_balance_cents ? this.props.loan.attributes.current_balance_cents / 100 : 0;
      const originationBalance = this.props.loan.attributes.origination_balance_cents ? this.props.loan.attributes.origination_balance_cents / 100 : 0;
      const monthlyPayment = this.props.loan.attributes.monthly_payment_cents ? this.props.loan.attributes.monthly_payment_cents / 100 : 0;
      const interestRate = this.props.loan.attributes.interest_rate;
      const originationDate = moment(this.props.loan.attributes.origination_date).toDate();

      this.setState({
        loan: this.props.loan,
        servicerId: servicerId,
        loanType: loanType,
        federal: federal,
        otherServicerName: otherServicerName,
        currentBalance: currentBalance,
        originationBalance: originationBalance,
        monthlyPayment: monthlyPayment,
        interestRate: interestRate,
        originationDate: originationDate
      })
    }
    if (prevProps.loanTypeData !== this.props.loanTypeData) {
      const loanTypeData = this.props.loanTypeData.sort(sortLoanType);
      this.setState({
        loanTypeData: loanTypeData
      })
    }
    if (prevProps.updateLoanData !== this.props.updateLoanData) {

      let fromForgivenessEligibility = !!this.props?.history?.location?.state?.fromForgivenessEligibility;
      this.setState({
        ...this.state,
        modalProp: {
          ...this.state.modalProp,
          show: true,
          title: 'Loan details saved!',
          bodyText: 'Your loan details have been successfully updated. Redirecting you back to your dashboard in 5 seconds...',
          footerButton: true,
          footerButtonName: "Go Back To Dashboard",
          footerButtonOnclick: '',
          footerButtonHref: fromForgivenessEligibility ? '/advice-and-tools/forgiveness-eligibility' : '/advice-and-tools/dashboard',
          footerButtonOnclickArgs: '',
          svgType: 'check'
        }
      });
      localStorage.setItem("didChange", true)
      this.timerID = setTimeout(() =>
        this.props.history.push({

          pathname: fromForgivenessEligibility ? '/advice-and-tools/forgiveness-eligibility' : '/advice-and-tools/dashboard'
        }), 5000
      )
    }
    if (prevProps.deleteLoanResponse !== this.props.deleteLoanResponse) {

      let fromForgivenessEligibility = !!this.props?.history?.location?.state?.fromForgivenessEligibility;
      this.setState({
        ...this.state,
        modalProp: {
          ...this.state.modalProp,
          show: true,
          title: 'Loan removed successfully',
          bodyText: 'Your loan has been removed successfully. Redirecting you back to your dashboard in 5 seconds...',
          footerButton: true,
          footerButtonName: "Go Back To Dashboard",
          footerButtonOnclick: '',
          footerButtonHref: fromForgivenessEligibility ? '/advice-and-tools/forgiveness-eligibility' : '/advice-and-tools/dashboard',
          footerButtonOnclickArgs: '',
          svgType: 'check'
        }
      });
      localStorage.setItem("didChange", true);
      this.props.resetDeletedLoanState();
      this.timerID = setTimeout(() =>
        this.props.history.push({

          pathname: fromForgivenessEligibility ? '/advice-and-tools/forgiveness-eligibility' : '/advice-and-tools/dashboard'
        }), 5000
      )
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.advisorServicers !== prevState.advisorServicers) {
      const advisorServicers = nextProps.advisorServicers.sort(sortAdvisorServicers)
      return ({
        advisorServicers: advisorServicers
      })
    }
    if (nextProps.customer !== prevState.customer) {
      return ({
        customer: nextProps.customer
      })
    }
    return null;
  }
  changeLoanServicer = (e) => {
    const { loan, advisorServicers } = this.state;
    const servicerId = e.value;
    let servicer = advisorServicers.filter(function (value) {
      return value.id === servicerId;
    });
    let label = servicer[0].attributes.name;

    let showOtherServicer = false;
    loan.relationships.servicer.data.id = servicerId;

    if (label === 'Other') {
      showOtherServicer = true;
    } else {
      loan.attributes.other_servicer_name = null;
    }

    this.setState({
      servicerId: servicerId,
      showOtherServicer: showOtherServicer,
      loan: loan
    })
  }
  handleInputChange = (e) => {
    const { loan } = this.state;
    loan.attributes.other_servicer_name = e.target.value;

    this.setState({
      otherServicerName: e.target.value,
      loan: loan
    })
  }
  makeLoanFederal = (value, event) => {
    const { loan } = this.state;
    let loanType = '';
    if (value === false) {
      loanType = 'Private';
      loan.attributes.loan_type = loanType;
    }

    this.setState({
      federal: value,
      loanType: loanType,
      loan: loan
    })
  }
  changeLoanType = (e) => {
    const { loan } = this.state;
    loan.attributes.loan_type = e.value;

    this.setState({
      loanType: e.value,
      loan: loan
    })
  }
  handleOriginationDateChange = (date) => {
    const { loan } = this.state;
    const originationDate = date;
    loan.attributes.origination_date = moment(originationDate).format('Y-MM-DD');
    this.setState({
      originationDate: originationDate,
      loan: loan
    })
  }
  handleOriginationBalanceChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    const originationBalance = floatValue * 100;
    loan.attributes.origination_balance_cents = originationBalance;

    this.setState({
      originationBalance: value,
      loan: loan
    })
  }
  handleCurrentBalanceChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    const currentBalance = floatValue * 100;
    loan.attributes.current_balance_cents = currentBalance;

    this.setState({
      currentBalance: value,
      loan: loan
    })
  }
  handleInterestRateChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    loan.attributes.interest_rate = floatValue;

    this.setState({
      interestRate: value,
      loan: loan
    })
  }
  handleMonthlyPaymentChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    const monthlyPayment = floatValue * 100;
    loan.attributes.monthly_payment_cents = monthlyPayment;

    this.setState({
      monthlyPayment: value,
      loan: loan
    })
  }
  handleSubmit = (e) => {
    if (this.validator.allValid()) {
      const loanId = this.state.loan.id;
      const loanRequest = {
        "data": this.state.loan
      }
      this.props.updateLoan(loanId, loanRequest);
    } else {
      this.validator.showMessages();
    }
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  handleCancel = (e) => {
    let fromForgivenessEligibility = !!this.props?.history?.location?.state?.fromForgivenessEligibility;
    this.props.history.push({

      pathname: fromForgivenessEligibility ? '/advice-and-tools/forgiveness-eligibility' : '/advice-and-tools/dashboard'
    })
  }
  handleDelete = (e) => {
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: true,
        title: 'Delete loan details?',
        bodyText: 'Are you sure? Deleting loan details will remove this from your account completely.',
        footerButton: true,
        footerButtonName: "I'm sure",
        footerButtonOnclick: this.proceedDeleteLoan,
        footerButtonHref: '',
        footerButtonOnclickArgs: '',
        svgType: 'info'
      }
    });

  }

  proceedDeleteLoan = () => {
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: false,
      }
    });
    const loanId = this.props.match.params.loanId;
    this.props.deleteLoan(loanId)
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  render() {
    const {
      advisorServicers,
      loanTypeData,
      servicerId,
      loanType,
      federal,
      otherServicerName,
      showOtherServicer,
      currentBalance,
      originationBalance,
      monthlyPayment,
      interestRate,
      originationDate
    } = this.state;

    const defaultServicerValue = advisorServicers.filter((x) => x.id === servicerId)
    let fromForgivenessEligibility = !!this.props?.history?.location?.state?.fromForgivenessEligibility;
    return (

      <>
        <Header {...this.props} />

        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={getEditLoanBreadcrumb(fromForgivenessEligibility)} />
            <div className=" p-4 Gborder-05 bg-white G-grid11-2 my-3 rounded-lg">
              <div>
                <div className="pinkCircularDiv mb-3">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M30 17.5C23.1 17.5 17.5 23.1 17.5 30C17.5 36.9 23.1 42.5 30 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 30 17.5ZM30 40C24.4875 40 20 35.5125 20 30C20 24.4875 24.4875 20 30 20C35.5125 20 40 24.4875 40 30C40 35.5125 35.5125 40 30 40ZM35.7375 24.475L27.5 32.7125L24.2625 29.4875L22.5 31.25L27.5 36.25L37.5 26.25L35.7375 24.475Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h2>Edit Loan Details</h2>
                <p className="sans-medium">
                  Use this form to update the details of your loan. Please make sure all the information is accurate and up-to-date.
                </p>
              </div>
              <div>
                <Form.Group className='mb-4'>
                  <Form.Label >Who is the loan service provider?</Form.Label>
                  <Select
                    className="basic-single"
                    classNamePrefix="reactSelect"
                    onChange={this.changeLoanServicer.bind(this)}
                    onBlur={(e) => this.showValidationMessage(e)}
                    name="servicerId"
                    placeholder='Select Servicer Account'
                    isSearchable={true}
                    value={defaultServicerValue.length > 0 ? { label: defaultServicerValue[0]?.attributes?.name, value: defaultServicerValue[0]?.id } : null}
                    options={
                      advisorServicers.map(item => {
                        return {
                          label: item.attributes.name,
                          value: item.id
                        }
                      })
                    }
                  />
                  <ValidatorMessage validator={this.validator} fieldName='account_number' fieldValue={this.state.servicerId} message="* Servicer can' t be blank" />
                </Form.Group>
                {showOtherServicer ?
                  <Form.Group className='mb-4'>
                    <Form.Label>What is the name of your servicer?</Form.Label>
                    <Form.Control type="text" name='other_servicer_name' placeholder="Servicer Name" value={otherServicerName} onChange={this.handleInputChange.bind(this)} onBlur={(e) => this.showValidationMessage(e)} />
                    <ValidatorMessage validator={this.validator} fieldName='other_servicer_name' fieldValue={this.state.otherServicerName} message='* Other servicer name required' />
                  </Form.Group>
                  : null}

                <div className="my-4">
                  <div>
                    <h4 className="Gt-slate-5 Gfs-1">Is it a Private or Federal Loan?</h4>
                  </div>
                  <div className='d-flex flex-wrap'>
                    <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (federal === false ? ' Gb-pink-8' : '')} onClick={this.makeLoanFederal.bind(this, false)}>
                      <p className={"mb-0" + (federal === false ? ' text-white' : ' Gt-pink-8')}>
                        PRIVATE
                      </p>
                    </div>

                    <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (federal === true ? ' Gb-pink-8' : '')} onClick={this.makeLoanFederal.bind(this, true)}>
                      <p className={"mb-0" + (federal === true ? ' text-white' : ' Gt-pink-8')}>
                        FEDERAL
                      </p>
                    </div>
                  </div>
                  <div>
                    {
                      this.validator.message(
                        "federal",
                        this.state.federal,
                        "required", {
                        messages: {
                          required: "* Select an option"
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {federal ?
                  <Form.Group className='mb-4'>
                    <Form.Label >What is the Federal Loan Type?</Form.Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="reactSelect"
                      onChange={this.changeLoanType.bind(this)}
                      onBlur={(e) => this.showValidationMessage(e)}
                      name="loan_type"
                      placeholder='Select Federal Loan Type'
                      isSearchable={true}
                      value={loanType.length > 0 ? { label: loanType, value: loanType } : null}
                      options={
                        loanTypeData &&
                        loanTypeData.filter((item) => item !== 'Private')
                          .map((item) => ({
                            label: item,
                            value: item
                          })
                          )
                      }
                    />
                  </Form.Group>
                  : null}

                <Form.Group className='mb-4'>
                  <Form.Label>On what date did the loan originate?</Form.Label>
                  <DatePicker
                    onKeyDown={event => {
                      event.preventDefault();
                      return false;
                    }}
                    className="form-control"
                    onChange={this.handleOriginationDateChange.bind(this)}
                    dateFormat="MM/dd/yyyy"
                    selected={originationDate}
                    name='originationDate'
                  />
                  <ValidatorMessage validator={this.validator} fieldName='originationDate' fieldValue={this.state.originationDate} message="* Origination date can' t be blank" />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label className='mb-0'>What was the original borrowed amount?</Form.Label>
                  <Form.Text className="text-muted sans-medium">If your access to Vault was provided by your employer, this information will not be shared with them</Form.Text>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={originationBalance}
                    prefix="$"
                    decimalSeparator="."
                    displayType="input"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    className="form-control"
                    id="origination_balance_cents"
                    name="origination_balance_cents"
                    onBlur={e => this.showValidationMessage(e)}
                    onValueChange={(values) => { this.handleOriginationBalanceChange(values) }}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='origination_balance_cents' fieldValue={this.state.originationBalance} message="* Borrowed Amount must be greater than zero" />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label className='mb-0'>What is the current balance?</Form.Label>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={currentBalance}
                    prefix="$"
                    decimalSeparator="."
                    displayType="input"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    className="form-control"
                    id="current_balance_cents"
                    name="current_balance_cents"
                    onBlur={e => this.showValidationMessage(e)}
                    onValueChange={(values) => { this.handleCurrentBalanceChange(values) }}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='current_balance_cents' fieldValue={this.state.currentBalance} message="* Current Balance can't be blank" />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label className='mb-0'>What is the interest rate?</Form.Label>
                  <Form.Text className="text-muted sans-medium">Don't know the exact rate? Include an estimate.</Form.Text>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={interestRate}
                    suffix="%"
                    decimalSeparator="."
                    displayType="input"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={false}
                    type="text"
                    className="form-control"
                    id="interest_rate"
                    name="interest_rate"
                    onBlur={e => this.showValidationMessage(e)}
                    onValueChange={(values) => { this.handleInterestRateChange(values) }}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='interest_rate' fieldValue={this.state.interestRate} message="* Interest Rate must be greater than zero" />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label className='mb-0'>What is the minimum monthly payment?</Form.Label>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={monthlyPayment}
                    prefix="$"
                    decimalSeparator="."
                    displayType="input"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    className="form-control"
                    id="monthly_payment_cents"
                    name="monthly_payment_cents"
                    onBlur={e => this.showValidationMessage(e)}
                    onValueChange={(values) => { this.handleMonthlyPaymentChange(values) }}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='monthly_payment_cents' fieldValue={this.state.monthlyPayment} message="* Monthly Payment must be greater than zero" />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button className="G-cancel-button mx-3 mob-separate-v6" onClick={this.handleCancel.bind(this)}>
                Go Back
              </button>
              <button className="G-cancel-button Gb-pink-8 mob-separate-v6 mr-3 text-white" onClick={this.handleDelete.bind(this)}>
                Delete
              </button>
              <button className="G-orange-button mob-separate-v6" onClick={this.handleSubmit.bind(this)}>
                Save Details
              </button>
            </div>
          </div>
        </div>
        <Footer />

        <CustomModal svgComponent={this.state.modalProp.svgType === 'check' ?
          <div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div> :
          <div className="yellowCircularDiv p-3 mb-3 mr-3"><InfoCircle size={28} className="Gt-yellow-6" /></div>
        }
          modalProp={{ ...this.state.modalProp }} />
      </>
    )
  }
}
LoansEdit.propTypes = {
  getAdvisorServicers: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  getLoanById: PropTypes.func.isRequired,
  getLoanType: PropTypes.func.isRequired,
  updateLoan: PropTypes.func.isRequired,
  deleteLoan: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  advisorServicers: state.advisor.advisorServicers,
  customer: state.customer_array.customerData,
  loan: state.advisor.loan,
  loanTypeData: state.advisor.loanTypeData,
  updateLoanData: state.advisor.updateLoanData,
  deleteLoanResponse: state.advisor.deleteLoanResponse,
  errors: state.errors
});
export default connect(mapStateToProps, { getAdvisorServicers, fetchCustomer, getLoanById, getLoanType, updateLoan, deleteLoan, resetDeletedLoanState })(LoansEdit);