import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { setTuitionDetailsAccordionData, setBasicDetailsAccordionData, setBankDetailsAccordionData, tuitionReimburseBreadcrumbData } from './TuitionReimbursementStatus.constant';
import BankDetailsAccordion from './BankDetailsAccordion';
import TuitionDetailsAccordion from './TuitionDetailsAccordion';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import BasicDetailsAccordion from './BasicDetailsAccordion';

const TuitionReimbursementStatusApproved = ({ employerData, servicerAccountData, addressData, data }) => {
  function paymentDate(employer) {
    if (employer) {
      const date = (employer.attributes.listed_in_current_invoice === false) ? employer.attributes.subsequent_payroll_date : employer.attributes.next_payroll_date;
      return moment.utc(date).add(1, 'days').format('MM/DD/YYYY');
    }
  }

  const redirectUrl = "/tuition-reimbursement-history";

  return (
    <>
      <Breadcrumb list={tuitionReimburseBreadcrumbData()} />
      <div className='p-4 Gborder-05 rounded-lg G-grid11-2 bg-white my-4'>
        <div >
          <div className='pinkCircularDiv mb-3'>
            <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.25 13.25L2 8L0.25 9.75L7.25 16.75L22.25 1.75L20.5 0L7.25 13.25Z" fill="var(--icon-color)" />
            </svg>
          </div>
          <div className='my-2'>
            <span className='G-green-badge'>Approved</span>
          </div>
          <h3 className='Gt-slate-5 Gfs-175 my-2'>Employer Approval Status</h3>
          <p className='sans-medium'>Your employer has reviewed your request and has approved it.</p>
          <div className='my-2'>
            <span className='G-badge-purple'>Payment is scheduled to Arrive</span>
            <p className='my-2 Gfs-175 Gt-pink-8 sans-extrabold'>{paymentDate(employerData)}</p>
          </div>
          <div className='my-2'>
            <span className='G-badge-purple'>Payment will be sent to</span>
            <p className='my-2 Gfs-175 Gt-pink-8 sans-extrabold'>{servicerAccountData?.attributes?.bank_name || "N/A"} </p>
          </div>
          <div className='mt-4'>
            <Link to={redirectUrl} className="G-cancel-button cancelLink text-decoration-none Gt-pink-8">Go Back</Link>
          </div>
        </div>
        <div>
          <h3 className='Gt-slate-5 Gfs-15 mb-3'>Submitted Information</h3>
          <BasicDetailsAccordion basicData={setBasicDetailsAccordionData(data.data)} />
          <TuitionDetailsAccordion tuitionData={setTuitionDetailsAccordionData(data)} />
          <BankDetailsAccordion bankData={setBankDetailsAccordionData(servicerAccountData, addressData)} />
        </div>
      </div>
    </>
  )
}

export default TuitionReimbursementStatusApproved;
