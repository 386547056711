import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FeedbackModalData, FeedbackRouteName } from "../../../helpers/translations";
import { satisfactionFeedback } from "../../../actions/advisorActions";
import { connect } from "react-redux";

const FeedbackModal = ({
    showFeedbackModal,
    closeFeedbackModal,
    contentType,
    satisfactionFeedback,
    satisfactionFeedbackStatus
}) => {
    const [show, setShow] = useState(false);
    const [selectedCard, setSelectedCard] = useState(0);
    const [feedbackContent, setFeedbackContent] = useState(null);
    const [feedbackNotes, setFeedbackNotes] = useState('');

    const handleClose = () => {
        if (localStorage.getItem('feedbackClosed')) {
            let closed = localStorage.getItem('feedbackClosed');
            localStorage.setItem('feedbackClosed', Number(closed) + 1);
        } else {
            localStorage.setItem('feedbackClosed', 1);
        }
        setShow(false);

    }

    // const handleShow = () => setShow(true);

    const submitFeedback = () => {
        if (!!selectedCard) {
            satisfactionFeedback({ data: { attributes: { rate: selectedCard, key: contentType, feedback: feedbackNotes } } })

        }
    }

    useEffect(() => {
        setShow(showFeedbackModal);
    }, [showFeedbackModal]);

    useEffect(() => {
        const data = FeedbackModalData.filter((feedbackObject) => feedbackObject.name === contentType);
        setFeedbackContent(data[0]);
    }, [contentType])

    useEffect(() => {
        if (!!satisfactionFeedbackStatus?.id) handleClose();
    }, [satisfactionFeedbackStatus])

    return (
        <>
            {!!show && (
                <div className="G-Feedback-modal">
                    <header>
                        <div>
                            <p className="d-flex justify-content-between mb-0 G-Feedback-header Gfs-087 sans-bold"><span>{feedbackContent?.header}</span> <span className="G-pointer" onClick={handleClose}><svg className="G-close" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3.5" stroke="gray">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            </span></p>
                            <p className="mb-0 pt-2 Gfs-065 text-justify">{feedbackContent?.text}</p>
                        </div>

                    </header>
                    <div>
                        <div className="d-flex gap-3 flex-wrap py-2">
                            <div className={`p-1 m-1 d-flex flex-column ${selectedCard === 1 ? 'G-cardBorderSelected' : 'G-cardBorder'}`} onClick={() => setSelectedCard(1)}>
                                <div className="d-flex align-items-end justify-content-center">
                                    <svg className="w-50 h-50" xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64" fill="none">
                                        <path d="M32.9999 63.3233C17.3663 63.3233 0.499878 53.5173 0.499878 32C0.499878 10.4827 17.3663 0.676697 32.9999 0.676697C41.6853 0.676697 49.6982 3.53446 55.6379 8.74567C62.0818 14.4612 65.4999 22.5302 65.4999 32C65.4999 41.4698 62.0818 49.4828 55.6379 55.1983C49.6982 60.4095 41.6292 63.3233 32.9999 63.3233Z" fill="url(#paint0_radial_1816_12664)" />
                                        <path d="M59.835 13.3797C62.8216 18.1987 64.3794 23.9366 64.3794 30.3189C64.3794 39.7888 60.9613 47.8017 54.5173 53.5172C48.5776 58.7284 40.5087 61.6422 31.8793 61.6422C21.7595 61.6422 11.1466 57.5237 4.8819 48.7879C10.9112 58.6836 22.2526 63.3233 33 63.3233C41.6293 63.3233 49.6983 60.4095 55.638 55.1983C62.0819 49.4827 65.5 41.4698 65.5 32C65.5 24.8612 63.5557 18.5181 59.835 13.3797Z" fill="#D84213" />
                                        <path d="M44.3972 54.5483C44.2067 54.4755 44.033 54.3578 43.8985 54.2009C38.4464 48.5358 29.4304 48.3621 23.7653 53.8143C23.6365 53.9431 23.502 54.072 23.3787 54.2009C23.2442 54.3578 23.0705 54.4755 22.88 54.5483C22.4597 54.7052 21.9834 54.6156 21.6472 54.3186C21.3166 54.0272 21.2046 53.5621 21.3727 53.153C23.0425 48.8664 28.0071 45.5043 33.6386 45.5043C39.2701 45.5043 44.2347 48.8664 45.9046 53.1474C46.0727 53.5565 45.9606 54.0216 45.63 54.313C45.2938 54.6099 44.8175 54.6996 44.3972 54.5483Z" fill="#422B0D" />
                                        <path d="M29.9403 35.3173C28.6852 33.7371 23.5132 30.7 21.6472 29.8147C20.9524 29.4953 20.2295 29.2375 19.4899 29.047C19.3554 29.0078 19.2097 28.991 19.0696 28.991C18.3244 29.0078 17.6856 29.5401 17.5343 30.2742C17.3606 31.0362 17.7528 31.8207 18.4701 32.1345C19.3498 32.566 20.8851 33.3841 22.4765 34.2862C21.1485 35.0763 20.336 36.5108 20.336 38.0574V38.8755C20.336 41.1953 22.2188 43.078 24.5386 43.078C26.8584 43.078 28.7412 41.1953 28.7412 38.8755V38.0574C28.7412 38.0237 28.7412 37.9957 28.7412 37.9621C29.3408 37.9229 29.8787 37.5643 30.1421 37.0263C30.4166 36.466 30.3382 35.7992 29.9403 35.3173Z" fill="#422B0D" />
                                        <path d="M49.7653 30.2741C49.6084 29.5345 48.9584 29.0077 48.2019 29.0021C48.0618 29.0021 47.9162 29.0189 47.7817 29.0582C47.042 29.2431 46.3192 29.5008 45.6243 29.8146C43.7584 30.6888 38.5864 33.7371 37.3312 35.306C36.9334 35.7935 36.8661 36.4659 37.1463 37.0263C37.4153 37.5698 37.9476 37.9228 38.5528 37.9621V38.0573V38.8754C38.5528 41.1952 40.4355 43.078 42.7554 43.078C45.0752 43.078 46.958 41.1952 46.958 38.8754V38.0573C46.958 36.5164 46.1455 35.0875 44.823 34.2974C46.4144 33.3952 47.9498 32.5771 48.8295 32.1457C49.5524 31.8319 49.9502 31.0418 49.7653 30.2741Z" fill="#422B0D" />
                                        <path d="M24.3259 35.1099C23.5302 34.7289 22.572 35.0651 22.1853 35.8608C21.8884 36.4828 22.0228 37.228 22.5216 37.7099C23.3172 38.0909 24.2754 37.7547 24.6621 36.9591C24.9591 36.3371 24.8246 35.5918 24.3259 35.1099Z" fill="#896024" />
                                        <path d="M42.5426 35.1099C41.7469 34.7289 40.7887 35.0651 40.4021 35.8608C40.1051 36.4828 40.2396 37.228 40.7383 37.7099C41.534 38.0909 42.4922 37.7547 42.8788 36.9591C43.1758 36.3371 43.0413 35.5918 42.5426 35.1099Z" fill="#896024" />
                                        <defs>
                                            <radialGradient id="paint0_radial_1816_12664" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.9999 31.9999) scale(31.9171)">
                                                <stop offset="0.12" stopColor="#FAC742" />
                                                <stop offset="0.32" stopColor="#FAC440" />
                                                <stop offset="0.48" stopColor="#FBBA3B" />
                                                <stop offset="0.62" stopColor="#FBA931" />
                                                <stop offset="0.76" stopColor="#FC9224" />
                                                <stop offset="0.88" stopColor="#FE7413" />
                                                <stop offset="1" stopColor="#FF5100" />
                                            </radialGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <p className="text-center Gfs-065 sans-extrabold mb-0">Frustrating</p>
                            </div>
                            <div className={`p-1 m-1 d-flex flex-column ${selectedCard === 2 ? 'G-cardBorderSelected' : 'G-cardBorder'}`} onClick={() => setSelectedCard(2)}>
                                <div className="d-flex align-items-end justify-content-center">
                                    <svg className="w-50 h-50" xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64" fill="none">
                                        <path d="M33 63.3233C17.3664 63.3233 0.5 53.5173 0.5 32C0.5 10.4828 17.3664 0.676758 33 0.676758C41.6853 0.676758 49.6983 3.53452 55.6379 8.74572C62.0819 14.4612 65.5 22.5302 65.5 32C65.5 41.4698 62.0819 49.4828 55.6379 55.1983C49.6983 60.4095 41.6293 63.3233 33 63.3233Z" fill="url(#paint0_radial_1816_12677)" />
                                        <path d="M59.8349 13.3798C62.8215 18.1987 64.3793 23.9367 64.3793 30.319C64.3793 39.7888 60.9612 47.8017 54.5172 53.5172C48.5775 58.7285 40.5086 61.6422 31.8793 61.6422C21.7594 61.6422 11.1465 57.5237 4.88187 48.7879C10.9112 58.6836 22.2526 63.3233 33 63.3233C41.6293 63.3233 49.6982 60.4095 55.6379 55.1983C62.0818 49.4828 65.5 41.4698 65.5 32C65.5 24.8612 63.5556 18.5181 59.8349 13.3798Z" fill="#EB8F00" />
                                        <path d="M22.0172 19.6949C19.6693 19.6949 17.5344 21.6785 17.5344 24.9733C17.5344 28.2682 19.6693 30.2462 22.0172 30.2462C24.3706 30.2462 26.4999 28.2626 26.4999 24.9733C26.4999 21.6841 24.393 19.6949 22.0172 19.6949Z" fill="#422B0D" />
                                        <path d="M21.8213 21.897C21.0256 21.516 20.0674 21.8522 19.6808 22.6479C19.3838 23.2699 19.5183 24.0151 20.017 24.497C20.8127 24.8781 21.7709 24.5418 22.1575 23.7462C22.4545 23.1242 22.32 22.3789 21.8213 21.897Z" fill="#896024" />
                                        <path d="M43.5345 19.6949C41.1866 19.6949 39.0517 21.6785 39.0517 24.9733C39.0517 28.2682 41.1866 30.2462 43.5345 30.2462C45.8823 30.2462 48.0172 28.2626 48.0172 24.9733C48.0172 21.6841 45.8823 19.6949 43.5345 19.6949Z" fill="#422B0D" />
                                        <path d="M43.3103 21.897C42.5146 21.516 41.5564 21.8522 41.1698 22.6479C40.8728 23.2699 41.0073 24.0151 41.506 24.497C42.3017 24.8781 43.2599 24.5418 43.6465 23.7462C43.9435 23.1242 43.809 22.3789 43.3103 21.897Z" fill="#896024" />
                                        <path d="M33.3251 40.5677C38.4074 40.5229 43.2712 42.6634 46.6725 46.4401C46.9302 46.7371 46.9919 47.1574 46.8294 47.516C46.6669 47.8802 46.3082 48.1155 45.9104 48.1211C45.7311 48.1211 45.5518 48.0707 45.3949 47.9811C42.694 46.4289 38.1776 44.5013 33.3363 44.5013H33.2578C28.422 44.5013 23.9001 46.4289 21.2048 47.9811C21.0479 48.0707 20.8686 48.1211 20.6893 48.1211C20.2914 48.1155 19.9384 47.8802 19.7759 47.516C19.6078 47.1574 19.6695 46.7371 19.9328 46.4401C23.3341 42.6634 28.1923 40.5229 33.2746 40.5677" fill="#422B0D" />
                                        <defs>
                                            <radialGradient id="paint0_radial_1816_12677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33 32) scale(31.9171 31.9171)">
                                                <stop offset="0.5" stopColor="#FDE030" />
                                                <stop offset="0.92" stopColor="#F7C02B" />
                                                <stop offset="1" stopColor="#F4A223" />
                                            </radialGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <p className="text-center Gfs-065 mb-0 sans-extrabold">Disappointing</p>
                            </div>
                            <div className={`p-1 m-1 d-flex flex-column ${selectedCard === 3 ? 'G-cardBorderSelected' : 'G-cardBorder'}`} onClick={() => setSelectedCard(3)}>
                                <div className="d-flex align-items-end justify-content-center"> <svg className="w-50 h-50" xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64" fill="none">
                                    <path d="M33 63.3233C17.3664 63.3233 0.5 53.5172 0.5 32C0.5 10.4827 17.3664 0.676697 33 0.676697C41.6853 0.676697 49.6983 3.53446 55.6379 8.74566C62.0819 14.4612 65.5 22.5301 65.5 32C65.5 41.4698 62.0819 49.4827 55.6379 55.1983C49.6983 60.4095 41.6293 63.3233 33 63.3233Z" fill="url(#paint0_radial_1816_12688)" />
                                    <path d="M59.8349 13.3797C62.8215 18.1987 64.3793 23.9366 64.3793 30.3189C64.3793 39.7888 60.9612 47.8017 54.5172 53.5172C48.5776 58.7284 40.5086 61.6422 31.8793 61.6422C21.7595 61.6422 11.1465 57.5237 4.88187 48.7879C10.9112 58.6836 22.2526 63.3232 33 63.3232C41.6293 63.3232 49.6982 60.4094 55.6379 55.1982C62.0819 49.4827 65.5 41.4698 65.5 32C65.5 24.8612 63.5556 18.5181 59.8349 13.3797Z" fill="#EB8F00" />
                                    <path d="M47.2328 46.0647H19.2155C17.9772 46.0647 16.9742 45.0617 16.9742 43.8233C16.9742 42.5849 17.9772 41.5819 19.2155 41.5819H47.2328C48.4711 41.5819 49.4742 42.5849 49.4742 43.8233C49.4742 45.0617 48.4711 46.0647 47.2328 46.0647Z" fill="#422B0D" />
                                    <path d="M22.3927 22.4965C20.0448 22.4965 17.9099 24.4801 17.9099 27.775C17.9099 31.0698 20.0448 33.0478 22.3927 33.0478C24.7405 33.0478 26.8754 31.0642 26.8754 27.775C26.8754 24.4857 24.7405 22.4965 22.3927 22.4965Z" fill="#422B0D" />
                                    <path d="M22.1741 24.6987C21.3784 24.3177 20.4202 24.6539 20.0336 25.4496C19.7366 26.0716 19.8711 26.8168 20.3698 27.2987C21.1655 27.6798 22.1237 27.3435 22.5103 26.5479C22.8073 25.9259 22.6728 25.1806 22.1741 24.6987Z" fill="#896024" />
                                    <path d="M43.8708 22.4965C41.5229 22.4965 39.388 24.4801 39.388 27.775C39.388 31.0698 41.5229 33.0478 43.8708 33.0478C46.2186 33.0478 48.3535 31.0642 48.3535 27.775C48.3535 24.4857 46.2298 22.4965 43.8708 22.4965Z" fill="#422B0D" />
                                    <path d="M43.6634 24.6987C42.8677 24.3177 41.9095 24.6539 41.5229 25.4496C41.2259 26.0716 41.3604 26.8168 41.8591 27.2987C42.6548 27.6798 43.613 27.3435 43.9996 26.5479C44.2966 25.9259 44.1621 25.1806 43.6634 24.6987Z" fill="#896024" />
                                    <defs>
                                        <radialGradient id="paint0_radial_1816_12688" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33 31.9999) scale(31.9171 31.9171)">
                                            <stop offset="0.5" stopColor="#FDE030" />
                                            <stop offset="0.92" stopColor="#F7C02B" />
                                            <stop offset="1" stopColor="#F4A223" />
                                        </radialGradient>
                                    </defs>
                                </svg></div>
                                <p className="text-center Gfs-065 sans-extrabold mb-0">Neutral</p>
                            </div>
                            <div className={`p-1 m-1 d-flex flex-column ${selectedCard === 4 ? 'G-cardBorderSelected' : 'G-cardBorder'}`} onClick={() => setSelectedCard(4)}>
                                <div className="d-flex align-items-end justify-content-center"> <svg className="w-50 h-50" xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64" fill="none">
                                    <path d="M33 63.3233C17.3664 63.3233 0.5 53.5172 0.5 32C0.5 10.4828 17.3664 0.676758 33 0.676758C41.6853 0.676758 49.6982 3.53451 55.6379 8.74572C62.0819 14.4612 65.5 22.5302 65.5 32C65.5 41.4698 62.0819 49.4828 55.6379 55.1983C49.6982 60.4095 41.6293 63.3233 33 63.3233Z" fill="url(#paint0_radial_1816_12699)" />
                                    <path d="M22.0171 19.6948C19.6693 19.6948 17.5344 21.6784 17.5344 24.9733C17.5344 28.2681 19.6693 30.2461 22.0171 30.2461C24.3706 30.2461 26.4999 28.2625 26.4999 24.9733C26.4999 21.684 24.393 19.6948 22.0171 19.6948Z" fill="#422B0D" />
                                    <path d="M21.8212 21.897C21.0255 21.5159 20.0673 21.8521 19.6807 22.6478C19.3837 23.2698 19.5182 24.0151 20.0169 24.497C20.8126 24.878 21.7708 24.5418 22.1574 23.7461C22.4544 23.1241 22.3199 22.3789 21.8212 21.897Z" fill="#896024" />
                                    <path d="M43.5345 19.6948C41.1866 19.6948 39.0517 21.6784 39.0517 24.9733C39.0517 28.2681 41.1866 30.2461 43.5345 30.2461C45.8823 30.2461 48.0172 28.2625 48.0172 24.9733C48.0172 21.684 45.8823 19.6948 43.5345 19.6948Z" fill="#422B0D" />
                                    <path d="M43.3103 21.897C42.5146 21.5159 41.5564 21.8521 41.1698 22.6478C40.8728 23.2698 41.0073 24.0151 41.506 24.497C42.3017 24.878 43.2599 24.5418 43.6465 23.7461C43.9435 23.1241 43.809 22.3789 43.3103 21.897Z" fill="#896024" />
                                    <path d="M32.6638 48.0875C27.5814 48.1267 22.7289 45.9918 19.3276 42.2151C19.0698 41.9181 19.0082 41.4979 19.1707 41.1392C19.3332 40.775 19.6918 40.5397 20.0896 40.5341C20.269 40.5341 20.4483 40.5845 20.6052 40.6741C23.306 42.2263 27.8224 44.1539 32.6638 44.1539H32.7422C37.578 44.1539 42.1 42.2263 44.7952 40.6741C44.9521 40.5845 45.1314 40.5341 45.3107 40.5341C45.7086 40.5397 46.0672 40.775 46.2297 41.1392C46.3978 41.4979 46.3362 41.9181 46.0728 42.2151C42.6715 45.9918 37.8077 48.1323 32.7254 48.0875" fill="#422B0D" />
                                    <path d="M59.8349 13.3798C62.8216 18.1987 64.3793 23.9367 64.3793 30.319C64.3793 39.7888 60.9612 47.8017 54.5173 53.5172C48.5776 58.7284 40.5086 61.6422 31.8793 61.6422C21.7595 61.6422 11.1466 57.5237 4.88194 48.7879C10.9113 58.6836 22.2526 63.3233 33 63.3233C41.6293 63.3233 49.6983 60.4095 55.638 55.1983C62.0819 49.4828 65.5 41.4698 65.5 32C65.5 24.8612 63.5556 18.5181 59.8349 13.3798Z" fill="#EB8F00" />
                                    <defs>
                                        <radialGradient id="paint0_radial_1816_12699" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33 32) scale(31.917 31.9171)">
                                            <stop offset="0.5" stopColor="#FDE030" />
                                            <stop offset="0.92" stopColor="#F7C02B" />
                                            <stop offset="1" stopColor="#F4A223" />
                                        </radialGradient>
                                    </defs>
                                </svg>
                                </div>
                                <p className="text-center Gfs-065 sans-extrabold mb-0">Happy</p>
                            </div>
                            <div className={`p-1 m-1 d-flex flex-column ${selectedCard === 5 ? 'G-cardBorderSelected' : 'G-cardBorder'}`} onClick={() => setSelectedCard(5)}>
                                <div className="d-flex align-items-end justify-content-center">
                                    <svg className="w-50 h-50" xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64" fill="none">
                                        <path d="M33 63.3232C17.3664 63.3232 0.5 53.5172 0.5 32C0.5 10.4828 17.3664 0.676758 33 0.676758C41.6853 0.676758 49.6982 3.53451 55.6379 8.74572C62.0818 14.4612 65.4999 22.5302 65.4999 32C65.4999 41.4698 62.0818 49.4827 55.6379 55.1983C49.6982 60.4095 41.6293 63.3232 33 63.3232Z" fill="url(#paint0_radial_1816_12710)" />
                                        <path d="M59.835 13.3798C62.8216 18.1987 64.3794 23.9366 64.3794 30.319C64.3794 39.7888 60.9613 47.8017 54.5173 53.5172C48.5777 58.7284 40.5087 61.6422 31.8794 61.6422C21.7596 61.6422 11.1467 57.5237 4.88202 48.7879C10.9113 58.6836 22.2527 63.3232 33.0001 63.3232C41.6294 63.3232 49.6984 60.4095 55.638 55.1983C62.082 49.4827 65.5001 41.4698 65.5001 32C65.5001 24.8612 63.5557 18.5181 59.835 13.3798Z" fill="#EB8F00" />
                                        <path d="M33.4482 43.431C31.4421 43.431 29.4529 43.0948 27.5645 42.4224C25.2391 41.6211 22.712 42.8595 21.9107 45.1793C21.4568 46.4905 21.6417 47.9362 22.4094 49.0905C24.8189 52.7888 28.6292 54.806 33.4482 54.806C38.2671 54.806 42.0775 52.7888 44.4869 49.0905C45.8486 47.0453 45.2882 44.2828 43.243 42.9211C42.0887 42.1535 40.643 41.9685 39.3318 42.4224C37.4434 43.0948 35.4542 43.431 33.4482 43.431Z" fill="#422B0D" />
                                        <path opacity="0.8" d="M16.526 46.737C21.9417 46.737 26.332 42.5976 26.332 37.4913C26.332 32.3851 21.9417 28.2457 16.526 28.2457C11.1102 28.2457 6.71994 32.3851 6.71994 37.4913C6.71994 42.5976 11.1102 46.737 16.526 46.737Z" fill="url(#paint1_radial_1816_12710)" />
                                        <path opacity="0.8" d="M50.3706 46.737C55.7863 46.737 60.1766 42.5976 60.1766 37.4913C60.1766 32.3851 55.7863 28.2457 50.3706 28.2457C44.9549 28.2457 40.5645 32.3851 40.5645 37.4913C40.5645 42.5976 44.9549 46.737 50.3706 46.737Z" fill="url(#paint2_radial_1816_12710)" />
                                        <path d="M57.6494 16.0134C53.049 14.719 49.6701 18.4789 49.6701 18.4789C49.6701 18.4789 49.2779 14.2147 45.3442 12.8755C40.6261 11.2561 35.6447 13.8337 35.3477 20.5186C35.0115 28.1336 45.0865 38.1302 45.0865 38.1302C45.0865 38.1302 57.5934 34.7681 61.6895 27.6405C65.0235 21.841 61.1291 16.9884 57.6494 16.0134Z" fill="#F44336" />
                                        <path d="M57.6496 16.0134C57.0836 15.8621 56.5009 15.7949 55.9181 15.8061C57.5655 16.9099 58.8711 18.4509 59.6836 20.2552C60.4737 22.1548 60.681 24.6091 59.1793 27.5397C55.9237 33.9108 45.8992 37.8556 45.1707 38.1302C46.0841 37.8724 57.7729 34.5104 61.7121 27.6574C65.0237 21.841 61.1293 16.9884 57.6496 16.0134Z" fill="#C62828" />
                                        <path d="M49.6646 19.1682C49.631 18.5854 49.5413 18.0026 49.4068 17.4311C49.0818 15.7837 48.062 14.3548 46.6051 13.5087C45.6077 12.9259 44.5038 12.5561 43.3607 12.416C43.3607 12.416 45.0418 12.9259 46.2409 15.2514C46.8517 16.4729 47.2159 17.8065 47.3055 19.1682C47.2999 19.6669 47.3896 20.1544 47.5689 20.6195C47.7706 21.0845 48.2637 21.3591 48.768 21.2807C49.5357 21.0845 49.7038 20.1264 49.6646 19.1682Z" fill="#C62828" />
                                        <path d="M38.7716 16.4953C39.8194 15.1953 41.5397 13.9569 43.1871 15.1C44.0612 15.7108 44.0724 17.4423 43.0022 18.2211C41.1979 19.5436 41.1138 20.4121 40.9121 21.1405C40.6655 22.0091 40.5086 23.0457 39.7185 23.4828C38.9285 23.9198 38.2336 23.4828 37.819 22.2332C37.1129 20.2832 37.4716 18.1091 38.7716 16.4953Z" fill="#FF847A" />
                                        <path d="M20.1793 12.7634C15.6349 14.2371 14.9065 19.2409 14.9065 19.2409C14.9065 19.2409 12.2168 15.8789 8.18235 16.9659C3.36899 18.2323 0.65132 23.1409 4.08623 28.8621C8.06467 35.4181 21.9836 38.1358 21.9836 38.1358C21.9836 38.1358 30.5289 28.4082 29.9909 20.2047C29.5483 13.5254 23.6142 11.6483 20.1793 12.7634Z" fill="#F44336" />
                                        <path d="M20.1793 12.7634C19.6245 12.9427 19.0922 13.2005 18.6047 13.5255C20.5883 13.5311 22.5271 14.0914 24.2081 15.1449C25.9228 16.2655 27.4525 18.2155 27.8336 21.4936C28.6573 28.6043 22.4935 37.441 22.0172 38.0854C22.6392 37.3681 30.5008 28.0888 29.9853 20.1992C29.5482 13.5255 23.6142 11.6483 20.1793 12.7634Z" fill="#C62828" />
                                        <path d="M15.293 19.8293C14.9456 19.353 14.5533 18.9159 14.1163 18.5237C12.9395 17.3245 11.3089 16.6858 9.63352 16.7754C8.48481 16.8426 7.35852 17.1396 6.32748 17.6608C6.32748 17.6608 8.00852 17.1508 10.2947 18.4284C11.4883 19.1176 12.5249 20.0422 13.3542 21.1405C13.6232 21.5607 13.9706 21.9194 14.3796 22.2051C14.8055 22.4797 15.3658 22.4293 15.7413 22.0875C16.24 21.4823 15.8533 20.597 15.293 19.8293Z" fill="#C62828" />
                                        <path d="M4.75866 23.6509C4.78107 23.1802 4.86513 22.7151 5.00521 22.2668C5.23495 21.3254 5.90176 20.5466 6.79271 20.1711C7.65004 19.9022 8.83797 20.0927 9.29745 20.95C9.7065 21.7121 9.40952 22.6311 9.18538 23.4716C8.98366 24.4354 8.87159 25.4216 8.84917 26.4078C8.85478 27.2147 8.21038 27.8703 7.40349 27.8815C6.64142 27.8815 6.04745 27.2203 5.62159 26.5871C5.02763 25.7242 4.72504 24.6987 4.75866 23.6509Z" fill="#FF847A" />
                                        <defs>
                                            <radialGradient id="paint0_radial_1816_12710" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33 32) scale(31.917)">
                                                <stop offset="0.5" stopColor="#FDE030" />
                                                <stop offset="0.92" stopColor="#F7C02B" />
                                                <stop offset="1" stopColor="#F4A223" />
                                            </radialGradient>
                                            <radialGradient id="paint1_radial_1816_12710" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.526 33.2386) scale(10.6868 9.59632)">
                                                <stop stopColor="#ED7770" />
                                                <stop offset="0.9" stopColor="#ED7770" stopOpacity="0" />
                                            </radialGradient>
                                            <radialGradient id="paint2_radial_1816_12710" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(66.3516 1413.07) scale(10.9043 10.9043)">
                                                <stop stopColor="#ED7770" />
                                                <stop offset="0.9" stopColor="#ED7770" stopOpacity="0" />
                                            </radialGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <p className="text-center Gfs-065 sans-extrabold mb-0">Love it!</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 Gfs-075 sans-bold">What is the reason behind your rating ?</p>
                            <textarea className="w-100 G-feedbackTextArea p-2 sans-semibold G-no-outline" rows="2" placeholder="Please explain in a few words..." onInput={(e) => setFeedbackNotes(e.target.value)} />
                        </div>
                        <div className="d-flex justify-content-end px-2">
                            <button className="G-orange-button mob-separate-v6 py-2 px-4 Gfs-065" onClick={submitFeedback}>
                                Submit
                            </button>
                        </div>
                    </div>

                </div>
            )}

        </>

    );
}

const mapStateToProps = (state) => ({
    satisfactionFeedbackStatus: state.advisor.satisfactionFeedback
});

export default connect(mapStateToProps, { satisfactionFeedback })(FeedbackModal);