/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { responseMessage } from '../../utils/alert';
import SimpleReactValidator from "simple-react-validator";
import Header from '../Includes/Header/Header';
import Footer from '../Includes/Footer/Footer';
import TuitionReimbursementOne from './TuitionReimbursementOne';
import TuitionReimbursementTwo from './TuitionReimbursementTwo';
import TuitionReimbursementThree from './TuitionReimbursementThree';
import TuitionReimbursementConfirmation from './TuitionReimbursementConfirmation';
import moment from 'moment';
import {
  saveTRDetails,
  fetchAgeRange,
  fetchIncomeRange,
  fetchEducationExpenses,
  fetchTuitionById,
  fetchTuitions,
  fetchAddress,
  fetchServicerAccount,
} from '../../actions/tuitionReimbursementActions';
import { fetchCustomer } from '../../actions/customerActions';
import { checkFileFormat } from './../shared/Drag&DropBox/DragAndDropBox';
import GeneralAgreement from '../TermsAndConditions/GeneralAgreement/GeneralAgreement';
import EsignDisclosure from '../TermsAndConditions/EsignDisclosure/EsignDisclosure';

class TuitionReimbursement extends Component {
  constructor(props) {
    super(props);
    this.receiptFileRef = React.createRef();
    this.completionFileRef = React.createRef();
    this.state = {
      tuitionId: 0,
      customerData: null,
      termsData: null,
      tRData: null,
      ageRangeData: null,
      incomeRangeData: null,
      educationExpensesData: null,
      show: false,
      disabled: true,
      errors: null,
      customErrors: {},
      showHide1: true,
      showHide2: false,
      showHide3: false,
      showHide4: false,
      first_name: '',
      last_name: '',
      age_range_id: '',
      income_range_id: '',
      title: '',
      department: '',
      education_expense_id: '',
      education_expense: '',
      educationExpenseName: '',
      notes_manager: '',
      school_name: '',
      course_name: '',
      description: '',
      amount: '',
      start_date: '',
      completion_date: '',
      receiptFile: [],
      completionFile: [],
      previousReceiptImages: [],
      previousCompletionImages: [],
      connect_bank_name: '',
      bank_user_name: '',
      bank_user_password: '',
      bank_name: '',
      account_number: '',
      confirm_account_number: '',
      routing_number: '',
      payment_address: '',
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip_code: '',
      payment_user_name: '',
      payment_user_password: '',
      redirect: false,
      show_bank_acc_info: false,
      validationError: {
        first_name: false,
        last_name: false,
        title: false,
        department: false,
        education_expense_id: false,
        school_name: false,
        course_name: false,
        description: false,
        amount: false,
        start_date: false,
        completion_date: false,
        bank_name: false,
        account_number: false,
        confirm_account_number: false,
        routing_number: false,
        street_1: false,
        city: false,
        state: false,
        zip_code: false,
      },
      validateOneData: {
        first_name: '',
        last_name: '',
        title: '',
        department: '',
        education_expense_id: '',
        age_range_id: '',
        income_range_id: ''
      },
      collapseOne: '',
      collapseTwo: true,
      collapseThree: '',
      showGeneralTerms: true,
      showEsignTerms: false,
    };
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = 'Vault | Tuition Reimbursement'
    const { fetchEducationExpenses, fetchTuitionById} = this.props;
    // fetchGeneralAgreement();
    fetchEducationExpenses();
    if (this.props.match.params.tuitionId !== undefined) {
      fetchTuitionById(this.props.match.params.tuitionId);
      this.setShowAndHide(false, true);
    } else {
      const { tuitions } = this.props?.location?.state || { tuitions: null };
      if (tuitions !== null && tuitions?.data?.length > 0) {
        const tuitionData = tuitions.data;
        const servicerAccountDetails = tuitions.included.filter(o => o.type === 'servicer_accounts');
        const { fetchAddress } = this.props;
        fetchAddress(servicerAccountDetails[0]?.relationships?.address?.data?.id);
        this.setState({
          title: tuitionData[0]?.attributes?.title,
          department: tuitionData[0]?.attributes?.department,
          notes_manager: tuitionData[0]?.attributes?.notes_manager,
          educationExpenseName: tuitionData[0]?.attributes?.education_expense_name,
          bank_name: servicerAccountDetails[0]?.attributes?.bank_name,
          account_number: servicerAccountDetails[0]?.attributes?.account_number,
          confirm_account_number: servicerAccountDetails[0]?.attributes?.account_number,
          routing_number: servicerAccountDetails[0]?.attributes?.routing_number,
        })
      }
    }
  }


  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tuitionsData !== this.props.tuitionsData) {
      nextProps.tuitionsData.data.sort((a, b) => { return Date.parse(b.attributes.created_at) - Date.parse(a.attributes.created_at) });
      this.setState({
        tuitionsData: nextProps.tuitionsData,
      })
    }
    if (this.props.addressData !== nextProps.addressData) {
      const addressData = nextProps.addressData;
      this.setState({
        street_1: addressData.attributes.street_1,
        street_2: addressData.attributes.street_2,
        city: addressData.attributes.city,
        state: addressData.attributes.state,
        zip_code: addressData.attributes.zip_code,
      })
    }
    if (this.props.servicerAccountData !== nextProps.servicerAccountData) {
      const servicerAccountData = nextProps.servicerAccountData;
      this.setState({
        bank_name: servicerAccountData?.attributes?.bank_name,
        account_number: servicerAccountData?.attributes?.account_number,
        confirm_account_number: servicerAccountData?.attributes?.account_number,
        routing_number: servicerAccountData?.attributes?.routing_number,
      })
      const addressId = servicerAccountData?.relationships?.address?.data?.id;
      this.props.fetchAddress(addressId);
    }
 

    if (nextProps.customerData !== this.props.customerData) {
      const full_name_str = nextProps.customerData.attributes.full_name
      const full_name_arr = full_name_str.split(' ');
      const first_name = full_name_arr[0].replace(/^\"(.+)\"$/, "$1");
      const last_name = full_name_arr[1].replace(/^\"(.+)\"$/, "$1");

      this.setState({
        customerData: nextProps.customerData,
        first_name: first_name,
        last_name: last_name
      })
    
    }
 
    if (this.props.incomeRangeData !== nextProps.incomeRangeData) {
      this.setState({
        incomeRangeData: nextProps.incomeRangeData.income_ranges
      })
    }
    if (this.props.ageRangeData !== nextProps.ageRangeData) {
      this.setState({
        ageRangeData: nextProps.ageRangeData.age_ranges
      })
    }
    if (this.props.educationExpensesData !== nextProps.educationExpensesData) {
      const filteredEducationExpenseData = nextProps.educationExpensesData.filter(item => item.attributes.name === this.state.educationExpenseName);
      this.setState({
        educationExpensesData: nextProps.educationExpensesData,
      })
      if (filteredEducationExpenseData.length > 0) {
        this.setState({
          education_expense_id: filteredEducationExpenseData[0].id,
          education_expense_name: filteredEducationExpenseData[0].attributes.name
        })
      }
    }
    if (this.props.tRData !== nextProps.tRData) {
      this.setState({
        tuitionId: nextProps.tRData.tuiton_id
      })
      responseMessage('success', 'Success', '');
      setTimeout(() => this.setState({ redirect: true }), 5000)
    }
    if (this.props.tuitionByIdData !== nextProps.tuitionByIdData && this.props.match.params.tuitionId !== undefined) {
      const tuitionData = nextProps.tuitionByIdData.data;
      let receiptImages = nextProps.tuitionByIdData.included.filter(item => item.type === 'receipts');
      let proofOfCompletionImages = nextProps.tuitionByIdData.included.filter(item => item.type === 'proof_of_completions');
      this.setState({
        ...this.state,
        title: tuitionData?.attributes?.title,
        department: tuitionData?.attributes?.department,
        notes_manager: tuitionData?.attributes?.notes_manager,
        school_name: tuitionData?.attributes?.school_name,
        course_name: tuitionData?.attributes?.course_name,
        description: tuitionData?.attributes?.description,
        educationExpenseName: tuitionData?.attributes?.education_expense_name,
        education_expense_id: tuitionData?.attributes?.education_expense_id,
        start_date: tuitionData?.attributes?.start_date,
        completion_date: tuitionData?.attributes?.completion_date,
        amount: '$' + tuitionData?.attributes?.amount_cents / 100,
        previousReceiptImages: receiptImages,
        previousCompletionImages: proofOfCompletionImages,
      })
      const servicerAccountId = tuitionData?.relationships?.servicer_account?.data?.id;
      this.props.fetchServicerAccount(servicerAccountId);
    }
    if (this.props.errors !== nextProps.errors) {
      responseMessage('error', nextProps.errors.errors, '');
    }
  }

  handleAddOne = (e) => {
    if (this.validator.fieldValid('first_name') && this.validator.fieldValid('last_name') && this.validator.fieldValid('title') && this.validator.fieldValid('department') && this.validator.fieldValid('education_expense_id')) {
      this.setShowAndHide(false, true);
    } else {
      this.validator.showMessages();
      let validationArray = { ...this.state.validationError };
      for (let key in validationArray) {
        if (validationArray[key] === false && this.state.validateOneData[key] === '')
          validationArray[key] = true;

      }
      this.setState({
        validationError: validationArray
      });
    }
  }
  handleResetOne = (e) => {
    this.setState({
      age_range_id: '',
      income_range_id: '',
      title: '',
      department: '',
      education_expense: '',
      notes_manager: ''
    })
  }
  handleBackOne = (e) => {
    this.setShowAndHide(true);
  }
  handleAddTwo = (e) => {
    if (this.validator.fieldValid('school_name') && this.validator.fieldValid('course_name') && this.validator.fieldValid('description') && this.validator.fieldValid('amount') && this.validator.fieldValid('start_date') && this.validator.fieldValid('completion_date')) {
      if (moment(this.state.completion_date) < moment(this.state.start_date)) {
        let errors = {};
        errors['completion_date'] = 'Completion date should be greater than or equal to start date.';
        this.setState({ customErrors: errors });
        this.setShowAndHide(false, true);

      } else {
        this.setState({ customErrors: {} });
        this.setShowAndHide(false, false, true);
      }

    } else {
      this.validator.showMessages();
      let validationArray = { ...this.state.validationError };
      for (let key in validationArray) {
        if (validationArray[key] === false && this.state.validateOneData[key] === '')
          validationArray[key] = true;
      }
      this.setState({
        validationError: validationArray
      });
    }
  }
  handleBackTwo = (e) => {
    this.setShowAndHide(false, true);
  }
  showBankAccountInfo = (e) => {
    setTimeout(function () {
      let isExpanded = document.getElementById('myCollapsible').getAttribute('aria-expanded')
      if (isExpanded === 'true') {
        this.setState({
          show_bank_acc_info: true
        })
      } else {
        this.setState({
          show_bank_acc_info: false
        })
      }
    }.bind(this), 1000);
  }
  handleAddThree = (e) => {
    if (this.state.show_bank_acc_info === true || this.state.collapseTwo === true) {
      if (this.validator.fieldValid('bank_name') && this.validator.fieldValid('account_number') && this.validator.fieldValid('confirm_account_number') && this.validator.fieldValid('routing_number') && this.validator.fieldValid('street_1') && this.validator.fieldValid('city') && this.validator.fieldValid('state')) {
        this.setShowAndHide(false, false, false, true);

      } else {
        this.validator.showMessages();
        let validationArray = { ...this.state.validationError };
        for (let key in validationArray) {
          if (validationArray[key] === false && this.state.validateOneData[key] === '')
            validationArray[key] = true;
        }
        this.setState({
          validationError: validationArray
        });
      }
    } else {
      this.setShowAndHide(false, false, false, true);
    }
  }
  handleResetThree = (e) => {
    this.setState({
      bank_name: '',
      account_number: '',
      confirm_account_number: '',
      routing_number: ''
    })
  }
  handleBackThree = (e) => {
    this.setShowAndHide(false, false, true);
    if (this.state.connect_bank_name !== '') {
      this.setState({
        collapseOne: 'show'
      })
    }
    if (this.state.bank_name !== '') {
      this.setState({
        collapseTwo: 'show'
      })
    }
    if (this.state.payment_user_name !== '') {
      this.setState({
        collapseThree: 'show'
      })
    }
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  showCompletionDateValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {

      if (moment(this.state.completion_date) < moment(this.state.start_date)) {
        let errors = {};
        errors['completion_date'] = 'Completion date should be greater than or equal to start date.';
        this.setState({ customErrors: errors });
      }
      else {
        this.setState({ customErrors: {} });
        this.validator.hideMessageFor(event.target.name);
        this.setState({
          validationError: {
            ...this.state.validationError,
            [event.target.name]: false
          }
        });
      }
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleInputChangeAmount = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  receiptFileChange = (file) => {

    const validFileFormat = checkFileFormat(file);
    if (!validFileFormat) this.receiptFileRef.current.value = '';
    if (validFileFormat) {
      let { receiptFile } = this.state;

      receiptFile.push(file);
      this.setState({
        ...this.state,
        receiptFile: receiptFile
      });
    } else {
      responseMessage('error', 'Please choose a pdf, png or jpeg/jpg file!', '');
    }
  }
  removeReceiptFile = (e, file) => {
    e.preventDefault();
    const index = this.state.receiptFile.indexOf(file);
    let { receiptFile } = this.state;
    receiptFile.splice(index, 1);
    this.setState({
      ...this.state,
      receiptFile: receiptFile
    });
  }

  completionFileChange = (file) => {

    const validFileFormat = checkFileFormat(file);
    if (!validFileFormat) this.completionFileRef.current.value = '';

    if (validFileFormat) {
      let { completionFile } = this.state;

      completionFile.push(file);
      this.setState({
        ...this.state,
        completionFile: completionFile
      });
    } else {
      responseMessage('error', 'Please choose a pdf, png or jpeg/jpg file!', '');
    }
  }
  removeCompletionFile = (e, file) => {
    e.preventDefault();
    const index = this.state.completionFile.indexOf(file);
    let { completionFile } = this.state;
    completionFile.splice(index, 1);
    this.setState({
      ...this.state,
      completionFile: completionFile
    });
  }

  setShowAndHide = (show1 = false, show2 = false, show3 = false, show4 = false) => {
    this.setState({
      showHide1: show1,
      showHide2: show2,
      showHide3: show3,
      showHide4: show4
    })
  }
  handleSaveTR = (e) => {
    let $amount = this.state.amount
    $amount = parseFloat($amount.replace(/\$|,/g, ''))

    const formData = new FormData();
    if (this.props.match.params.tuitionId !== undefined) {
      formData.append("data[attributes][tuition][tuition_id]", this.props.match.params.tuitionId);
    }
    formData.append("data[attributes][first_name]", this.state.first_name);
    formData.append("data[attributes][last_name]", this.state.last_name);
    formData.append("data[attributes][tuition][title]", this.state.title);
    formData.append("data[attributes][tuition][department]", this.state.department);
    if (this.state.education_expense_id === 'Other') {
      formData.append("data[attributes][tuition][name]", this.state.education_expense);
    } else {
      formData.append("data[attributes][tuition][education_expense_id]", this.state.education_expense_id);
    }
    formData.append("data[attributes][tuition][notes_manager]", this.state.notes_manager);
    formData.append("data[attributes][tuition][school_name]", this.state.school_name);
    formData.append("data[attributes][tuition][course_name]", this.state.course_name);
    formData.append("data[attributes][tuition][description]", this.state.description);
    formData.append("data[attributes][tuition][amount_cents]", Math.round($amount * 100));
    formData.append("data[attributes][tuition][start_date]", this.state.start_date);
    formData.append("data[attributes][tuition][completion_date]", this.state.completion_date);
    formData.append("data[attributes][servicer_account][bank_name]", this.state.bank_name);
    formData.append("data[attributes][servicer_account][account_number]", this.state.account_number);
    formData.append("data[attributes][servicer_account][routing_number]", this.state.routing_number);
    formData.append("data[attributes][servicer_account][address_attributes][street_1]", this.state.street_1);
    formData.append("data[attributes][servicer_account][address_attributes][street_2]", this.state.street_2);
    formData.append("data[attributes][servicer_account][address_attributes][city]", this.state.city);
    formData.append("data[attributes][servicer_account][address_attributes][state]", this.state.state);
    formData.append("data[attributes][servicer_account][address_attributes][zip_code]", this.state.zip_code);

    if (this.state.receiptFile.length > 0) {
      for (let i = 0; i < this.state.receiptFile.length; i++) {
        formData.append("data[attributes][receipt][file][]", this.state.receiptFile[i]);
      }
    }
    if (this.state.completionFile.length > 0) {
      for (let i = 0; i < this.state.completionFile.length; i++) {
        formData.append("data[attributes][proof_of_completion][file][]", this.state.completionFile[i]);
      }
    }
    this.props.saveTRDetails(formData);
  }
  handleValidationOne() {
    let errors = {};
    let formIsValid = true;

    if (this.state.first_name === '') {
      formIsValid = false;
      errors['first_name'] = 'First name is required';
    }
    if (this.state.first_name !== '') {
      if (!this.state.first_name.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["first_name"] = 'Only letters';
      }
    }
    if (this.state.last_name === '') {
      formIsValid = false;
      errors['last_name'] = 'Last name is required';
    }
    if (this.state.last_name !== '') {
      if (!this.state.last_name.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["last_name"] = 'Only letters';
      }
    }
    if (this.state.age_range_id === '') {
      formIsValid = false;
      errors['age_range_id'] = 'Age range required';
    }
    if (this.state.income_range_id === '') {
      formIsValid = false;
      errors['income_range_id'] = 'Income range is required';
    }
    this.setState({ customErrors: errors });
    return formIsValid;
  }
  handleValidationTwo() {
    let errors = {};
    let formIsValid = true;

    if (this.state.school_name === '') {
      formIsValid = false;
      errors['school_name'] = "School name is required"
    }
    if (this.state.course_name === '') {
      formIsValid = false;
      errors['course_name'] = "Course name is required"
    }
    if (this.state.description === '') {
      formIsValid = false;
      errors['description'] = "Description is required"
    }
    if (this.state.amount === '') {
      formIsValid = false;
      errors['amount'] = "Amount is required"
    }
    if (this.state.start_date === '') {
      formIsValid = false;
      errors['start_date'] = "Start date is required"
    }
    if (this.state.completion_date === '') {
      formIsValid = false;
      errors['completion_date'] = "Completion is required"
    }
    this.setState({ customErrors: errors });
    return formIsValid;
  }
  handleValidationThree() {
    let errors = {};
    let formIsValid = true;

    let patt_account_number = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;

    if (this.state.bank_name === '') {
      formIsValid = false;
      errors['bank_name'] = "Bank name is required"
    }
    if (this.state.account_number === '') {
      formIsValid = false;
      errors['account_number'] = "Account number is required"
    }
    else if (this.state.account_number.length < 11) {
      formIsValid = false;
      errors['account_number'] = "Account number length must be at least 11 characters"
    }
    else if (this.state.account_number !== '' && !patt_account_number.test(this.state.account_number)) {
      formIsValid = false;
      errors['account_number'] = "You have entered an invalid account number"
    }
    if (this.state.confirm_account_number === '') {
      formIsValid = false;
      errors['confirm_account_number'] = "Confirm account number is required"
    }
    else if (this.state.account_number !== this.state.confirm_account_number) {
      formIsValid = false;
      errors['confirm_account_number'] = "Account number did not match"
    }
    if (this.state.routing_number === '') {
      formIsValid = false;
      errors['routing_number'] = "Routing number is required"
    }
    else if (this.state.routing_number.length < 11) {
      formIsValid = false;
      errors['routing_number'] = "Routing number length must be at least 11 characters"
    }

    this.setState({ customErrors: errors });
    return formIsValid;
  }
  render() {
    const { showHide1, showHide2, showHide3, showHide4, showGeneralTerms, showEsignTerms } = this.state;
    const redirectURL = '/tuition-reimbursement-status/' + this.state.tuitionId;
    return (
      this.state.redirect
        ? <Redirect to={redirectURL} />
        :
        <>
          <Header hideNotificationAndProfileDropdown={true} />
          <div className="G-page-background-color">
            <div className="G-page-main-container my-5">
              {showGeneralTerms &&
                <GeneralAgreement showCloseButton={false} onAcceptButtonClickSuccess={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} alreadyAcceptedRoute={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} />
              }
              {showEsignTerms &&
                <EsignDisclosure showCloseButton={false} />
              }
              {showHide1 && <TuitionReimbursementOne data={this.state} customErrors={this.state.customErrors} onClickHandleAddOne={this.handleAddOne.bind(this)} handleResetOne={this.handleResetOne.bind(this)} handleInputChange={this.handleInputChange.bind(this)} showValidationMessage={this.showValidationMessage.bind(this)} validator={this.validator} />}
              {showHide2 && <TuitionReimbursementTwo data={this.state} customErrors={this.state.customErrors} onClickHandleAddTwo={this.handleAddTwo.bind(this)} onClickHandleBackOne={this.handleBackOne.bind(this)} handleInputChange={this.handleInputChange.bind(this)} receiptFile={this.state.receiptFile} receiptFileRef={this.receiptFileRef} completionFile={this.state.completionFile} completionFileRef={this.completionFileRef} receiptFileChange={this.receiptFileChange.bind(this)} removeReceiptFile={this.removeReceiptFile.bind(this)} completionFileChange={this.completionFileChange.bind(this)} removeCompletionFile={this.removeCompletionFile.bind(this)} showValidationMessage={this.showValidationMessage.bind(this)} validator={this.validator} handleInputChangeAmount={this.handleInputChangeAmount.bind(this)} showCompletionDateValidationMessage={this.showCompletionDateValidationMessage.bind(this)} />}
              {showHide3 && <TuitionReimbursementThree data={this.state} customErrors={this.state.customErrors} onClickHandleAddThree={this.handleAddThree.bind(this)} onClickHandleBackTwo={this.handleBackTwo.bind(this)} handleResetThree={this.handleResetThree.bind(this)} handleInputChange={this.handleInputChange.bind(this)} showValidationMessage={this.showValidationMessage.bind(this)} validator={this.validator} showBankAccountInfo={this.showBankAccountInfo.bind(this)} />}
              {showHide4 && <TuitionReimbursementConfirmation data={this.state} onClickhandleSaveTR={this.handleSaveTR.bind(this)} onClickHandleBackThree={this.handleBackThree.bind(this)} />}
            </div>
          </div>
          <Footer />
        </>
    )
  }
}
TuitionReimbursement.propTypes = {
  fetchCustomer: PropTypes.func.isRequired,
  saveTRDetails: PropTypes.func.isRequired,
  fetchAgeRange: PropTypes.func.isRequired,
  fetchIncomeRange: PropTypes.func.isRequired,
  fetchEducationExpenses: PropTypes.func.isRequired,
  fetchTuitionById: PropTypes.func.isRequired,
  fetchTuitions: PropTypes.func.isRequired,
  fetchAddress: PropTypes.func.isRequired,
  fetchServicerAccount: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  customerData: state.customer_array.customerData,
  tRData: state.tuition_reimbursement.saveTRDetails,
  ageRangeData: state.tuition_reimbursement.fetchAgeRange,
  incomeRangeData: state.tuition_reimbursement.fetchIncomeRange,
  educationExpensesData: state.tuition_reimbursement.educationExpensesData,
  tuitionByIdData: state.tuition_reimbursement.tuitionByIdData,
  servicerAccountData: state.tuition_reimbursement.servicerAccountData,
  addressData: state.tuition_reimbursement.addressData,
  errors: state.errors
});
export default connect(mapStateToProps, { fetchCustomer, saveTRDetails, fetchAgeRange, fetchIncomeRange, fetchEducationExpenses, fetchTuitionById, fetchTuitions, fetchAddress, fetchServicerAccount })(TuitionReimbursement);
