
export const paymentStepperData = (one = StepperStage.default, two = StepperStage.default, three = StepperStage.default) => {

  return [{
    index: '01',
    title: 'Select Loan Servicer',
    status: one,
    last: false
  },
  {
    index: '02',
    title: "Confirm Company's Contributions",
    status: three,
    last: true
  }]
}

export const StepperStage = {
  active: 'active',
  default: 'default',
  complete: 'complete'
}

export const paymentBreadcrumbData = (onlyTwo = true) => {

  let defaultOptions =
    [{
      title: 'Dashboard',
      active: false,
      url: '/pay/overview'
    }, {
      title: 'Select Loan Servicer',
      active: true,
      url: '#'
    }];

  let thirdOption = {
    title: 'Add an account',
    active: true,
    url: '#'
  }

  if (onlyTwo) {
    return defaultOptions;
  }

  defaultOptions[1].active = false;
  defaultOptions[1].url = '/pay/accounts';
  defaultOptions.push(thirdOption);
  return defaultOptions;

}

export const paymentEditAccountFromAccounts = () => {
  return [{
    title: 'Dashboard',
    active: false,
    url: '/pay/overview'
  },
  {
    title: 'Select Loan Servicer',
    active: false,
    url: '/pay/accounts'
  },
  {
    title: 'Edit Account',
    active: true,
    url: '#'
  }]
}

export const paymentHistoryBreadcrumb = () => {
  return [{
    title: 'Dashboard',
    active: false,
    url: '/pay/overview'
  }, {
    title: 'Payment History',
    active: true,
    url: '#'
  }]
}

export const LoanParameters = {
  accountNumber: 'account_number',
  accountBalance: 'account_balance',
  monthlyPayment: 'monthly_payment',
  interestRate: 'interest_rate'
}

export const pauseOptions = [
  { value: '', label: 'choose an option' },
  { value: "1", label: 'Loan paid in full' },
  { value: "2", label: 'Changing jobs/Leaving employer' },
  { value: "3", label: 'No longer want this benefit' },
  { value: "4", label: 'Other' }
];

export const selectStyles = {
  menu: base => ({
    ...base,
    zIndex: 100
  })
};


export const VaultSvg = () => {
  return (
    <svg className="" width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4996 20.039C26.9586 20.8295 27.6184 21.4858 28.4128 21.942C29.2072 22.3983 30.1083 22.6385 31.0255 22.6385C31.9427 22.6385 32.8437 22.3983 33.6382 21.942C34.4326 21.4858 35.0924 20.8295 35.5514 20.039L39.0994 13.9166L42.6475 7.8014C43.1075 7.01121 43.3502 6.11426 43.3511 5.20097C43.3519 4.28768 43.1109 3.3903 42.6523 2.59925C42.1938 1.80819 41.5339 1.15142 40.7391 0.695095C39.9443 0.238769 39.0427 -0.00097951 38.1252 3.00773e-06C37.2077 -0.000246826 36.3065 0.240497 35.5124 0.697944C34.7184 1.15539 34.0597 1.81332 33.6029 2.60528L26.4996 14.8719C26.0421 15.662 25.8013 16.558 25.8013 17.4699C25.8013 18.3819 26.0421 19.2778 26.4996 20.068" fill="white" />
      <path d="M22.9515 13.9166L23.3514 14.6041L31.7997 3.00773e-06H23.9258C23.0082 -0.00097951 22.1066 0.238769 21.3118 0.695095C20.517 1.15142 19.8572 1.80819 19.3986 2.59925C18.9401 3.3903 18.699 4.28768 18.6999 5.20097C18.7007 6.11426 18.9434 7.01121 19.4035 7.8014L22.9515 13.9166Z" fill="white" />
      <path d="M15.2229 26.6029C15.6812 27.393 16.3405 28.0491 17.1343 28.5052C17.9282 28.9613 18.8286 29.2012 19.7452 29.201H19.9342C20.8518 29.2019 21.7534 28.9622 22.5482 28.5059C23.343 28.0496 24.0029 27.3928 24.4615 26.6017C24.92 25.8107 25.161 24.9133 25.1601 24C25.1593 23.0867 24.9166 22.1898 24.4565 21.3996L20.8212 15.06L17.1424 8.72048C16.6834 7.93006 16.0236 7.27374 15.2292 6.81745C14.4347 6.36117 13.5337 6.12097 12.6165 6.12097C11.6993 6.12097 10.7982 6.36117 10.0038 6.81745C9.20936 7.27374 8.54946 7.93006 8.09048 8.72048L7.98868 8.9014C7.52994 9.69375 7.28851 10.5922 7.28851 11.5067C7.28851 12.4212 7.52994 13.3196 7.98868 14.112L15.2229 26.6029Z" fill="white" />
      <path d="M5.2259 29.201H13.3907L4.77513 14.387L4.38249 15.0601L0.703628 21.3996C0.243581 22.1898 0.000863201 23.0868 2.29879e-06 24C-0.000858603 24.9133 0.240102 25.8107 0.698658 26.6018C1.15721 27.3928 1.81718 28.0496 2.61197 28.5059C3.40677 28.9623 4.30836 29.202 5.2259 29.201Z" fill="white" />
      <path d="M19.847 42.0971C20.7633 42.0959 21.6632 41.8553 22.4568 41.3994C23.2504 40.9435 23.9098 40.2882 24.3692 39.4991L24.4638 39.3326C24.9218 38.5415 25.1626 37.6444 25.1621 36.7314C25.1615 35.8183 24.9195 34.9215 24.4606 34.131C24.0016 33.3404 23.3417 32.6841 22.5472 32.2277C21.7526 31.7714 20.8515 31.5312 19.9342 31.5313H5.23316C4.31587 31.5312 3.41472 31.7714 2.62021 32.2277C1.8257 32.6841 1.16579 33.3404 0.706807 34.131C0.24782 34.9215 0.0058759 35.8183 0.00531105 36.7314C0.0047462 37.6444 0.245603 38.5415 0.703612 39.3326L0.812599 39.5136C1.27059 40.3061 1.93061 40.9643 2.72591 41.4217C3.52121 41.8792 4.4237 42.1197 5.34223 42.1189L19.847 42.0971Z" fill="white" />
      <path d="M21.163 44.9774H3.96082L4.35337 45.6577L8.03231 51.9972C8.4903 52.7878 9.14936 53.4444 9.94323 53.9009C10.7371 54.3574 11.6378 54.5977 12.5546 54.5977C13.4714 54.5977 14.3721 54.3574 15.1659 53.9009C15.9598 53.4444 16.6189 52.7878 17.0768 51.9972L20.7558 45.6577L21.163 44.9774Z" fill="white" />
      <path d="M35.595 40.686L35.5005 40.5268C35.0415 39.7364 34.3817 39.08 33.5873 38.6237C32.7929 38.1674 31.8918 37.9272 30.9746 37.9272C30.0574 37.9272 29.1564 38.1674 28.3619 38.6237C27.5675 39.08 26.9076 39.7364 26.4486 40.5268L22.7552 46.8591L19.1199 53.1986C18.6599 53.9888 18.4172 54.8858 18.4163 55.7991C18.4154 56.7123 18.6565 57.6097 19.115 58.4007C19.5736 59.1918 20.2335 59.8486 21.0283 60.3049C21.8231 60.7613 22.7247 61.001 23.6422 61H23.8531C24.7714 61.0001 25.6736 60.7593 26.4688 60.3019C27.2639 59.8446 27.9241 59.1868 28.3826 58.3947L35.6532 45.8821C36.1106 45.0919 36.3515 44.196 36.3515 43.284C36.3515 42.3721 36.1106 41.4761 35.6532 40.686" fill="white" />
      <path d="M39.1721 46.8591L38.7723 46.1354L30.1857 60.9711H38.3288C39.2463 60.972 40.1479 60.7323 40.9427 60.276C41.7375 59.8196 42.3974 59.1629 42.8559 58.3718C43.3145 57.5807 43.5554 56.6833 43.5546 55.7701C43.5537 54.8568 43.3111 53.9598 42.8511 53.1696L39.1721 46.8591Z" fill="white" />
      <path d="M46.7481 34.1727C46.2894 33.3834 45.6299 32.7284 44.836 32.2736C44.0421 31.8188 43.1418 31.5802 42.2258 31.5819H42.0368C41.1203 31.5817 40.2199 31.8216 39.4261 32.2775C38.6323 32.7335 37.9732 33.3894 37.5148 34.1793C37.0565 34.9693 36.8151 35.8654 36.815 36.7777C36.815 37.6899 37.0563 38.586 37.5145 39.3761L41.1861 45.7156L44.865 52.0552C45.324 52.8456 45.9838 53.5019 46.7782 53.9582C47.5727 54.4144 48.4737 54.6547 49.3909 54.6547C50.3081 54.6547 51.2092 54.4144 52.0036 53.9582C52.798 53.5019 53.4578 52.8456 53.9168 52.0552L54.0186 51.8742C54.4774 51.0832 54.719 50.1859 54.719 49.2725C54.719 48.3592 54.4774 47.4619 54.0186 46.6709L46.7481 34.1727Z" fill="white" />
      <path d="M56.7742 31.5819H48.6094L57.225 46.3959L57.6175 45.7156L61.2965 39.3833C61.7565 38.5931 61.9991 37.6962 62 36.7829C62.0009 35.8696 61.7599 34.9722 61.3014 34.1811C60.8428 33.3901 60.1829 32.7333 59.3881 32.277C58.5933 31.8207 57.6917 31.5809 56.7742 31.5819Z" fill="white" />
      <path d="M61.2165 21.2621C60.757 20.4714 60.0965 19.815 59.3014 19.359C58.5063 18.9029 57.6046 18.6632 56.687 18.664H42.1458C41.2291 18.6674 40.3292 18.9097 39.5358 19.3668C38.7424 19.8239 38.0831 20.4798 37.6235 21.2693L37.529 21.4285C37.071 22.2196 36.8302 23.1167 36.8308 24.0298C36.8313 24.9428 37.0733 25.8397 37.5322 26.6302C37.9912 27.4207 38.6511 28.0771 39.4456 28.5334C40.2401 28.9897 41.1413 29.23 42.0586 29.2299H56.7597C57.6769 29.23 58.5781 28.9897 59.3726 28.5334C60.1671 28.0771 60.827 27.4207 61.286 26.6302C61.745 25.8397 61.9869 24.9428 61.9875 24.0298C61.9881 23.1167 61.7472 22.2196 61.2892 21.4285L61.2165 21.2621Z" fill="white" />
      <path d="M40.8371 15.8054H58.0393L57.6466 15.1324L53.9678 8.79286C53.5098 8.0023 52.8506 7.34572 52.0568 6.88922C51.2629 6.43271 50.3622 6.19238 49.4454 6.19238C48.5286 6.19238 47.6279 6.43271 46.8341 6.88922C46.0402 7.34572 45.3811 8.0023 44.9231 8.79286L41.2443 15.1324L40.8371 15.8054Z" fill="white" />
    </svg>
  )
}

export const VaultDollarSvg = () => {
  return (
    <svg className="w-100" width="386" height="389" viewBox="0 0 386 389" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_398_4808)">
        <path d="M0.610759 302.907V277.329H385.389V302.907H0.610759Z" stroke="white" strokeWidth="1.22152" />
        <path d="M309.279 192.748L384.625 276.108H1.4434L82.3086 192.748H309.279Z" stroke="white" strokeWidth="1.22152" />
        <path d="M256.675 238.305H129.392L140.503 226.853H246.31L256.675 238.305Z" stroke="white" strokeWidth="1.22152" />
      </g>
      <g clipPath="url(#clip1_398_4808)">
        <path d="M249.859 124.919C249.859 156.809 224.406 182.646 193.029 182.646C161.652 182.646 136.199 156.809 136.199 124.919C136.199 93.0277 161.652 67.1913 193.029 67.1913C224.406 67.1913 249.859 93.0277 249.859 124.919Z" stroke="white" strokeWidth="1.22152" />
        <path d="M241.168 124.919C241.168 151.934 219.606 173.819 193.029 173.819C166.452 173.819 144.89 151.934 144.89 124.919C144.89 97.9027 166.452 76.0183 193.029 76.0183C219.606 76.0183 241.168 97.9027 241.168 124.919Z" stroke="white" strokeWidth="1.22152" />
        <path d="M197.053 92.9622V99.2701C199.546 99.678 201.585 100.514 203.168 101.78C204.752 103.046 206.261 104.988 207.7 107.605L201.521 111.112C199.661 107.68 197.232 105.964 194.234 105.964C192.374 105.964 190.833 106.522 189.608 107.638C188.383 108.753 187.77 110.148 187.77 111.821C187.77 113.345 188.266 114.611 189.259 115.619C190.23 116.606 192.195 117.775 195.153 119.127C197.709 120.307 199.821 121.418 201.491 122.458C203.159 123.498 204.416 124.501 205.261 125.468C207.647 128.086 208.841 131.25 208.841 134.962C208.841 138.673 207.765 141.828 205.61 144.616C203.475 147.383 200.624 149.25 197.054 150.216V156.877H191.509V150.409C187.368 149.722 184.211 148.274 182.035 146.065C179.859 143.855 178.254 140.445 177.219 135.831L184.19 134.35C185.139 137.611 186.301 139.939 187.676 141.334C189.112 142.664 191.023 143.329 193.411 143.329C195.799 143.329 197.677 142.569 199.242 141.044C200.783 139.543 201.556 137.6 201.556 135.219C201.556 133.095 200.912 131.39 199.623 130.102C198.969 129.481 198.002 128.777 196.723 127.994C195.444 127.212 193.812 126.359 191.828 125.437C187.73 123.57 185.036 121.854 183.748 120.288C181.698 117.949 180.674 115.151 180.674 111.889C180.674 110.344 180.917 108.907 181.404 107.577C181.89 106.247 182.592 105.04 183.511 103.957C184.43 102.875 185.566 101.936 186.918 101.142C188.269 100.349 189.802 99.7258 191.511 99.2756V92.9677H197.056L197.053 92.9622Z" fill="white" />
      </g>
      <path d="M246.692 72.8529L264.84 57.8275" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M251.547 81.8266L266.797 79.1136" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M238.063 68.0458L243.107 53.9563" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M138.99 72.0808L120.842 57.0553" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M134.135 81.0544L118.885 78.3414" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M147.619 67.2736L142.575 53.1841" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <clipPath id="clip0_398_4808">
          <rect width="386" height="130.282" fill="white" transform="translate(0 192.137)" />
        </clipPath>
        <clipPath id="clip1_398_4808">
          <rect width="114.881" height="116.676" fill="white" transform="translate(135.589 66.5806)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PublicServiceLogo = () => (
  <svg className="w-100" width="480" height="168" viewBox="0 0 480 168" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="480" height="168" fill="var(--icon-background-color)" />
    <path d="M210.531 146.139H199.362C197.342 146.139 195.703 144.418 195.703 142.297L192.623 120.215C192.623 118.094 194.262 116.374 196.281 116.374H213.614C215.634 116.374 217.273 118.094 217.273 120.215L214.193 142.297C214.193 144.418 212.554 146.139 210.534 146.139H210.531Z" fill="#C05599" />
    <path d="M285.868 146.139H274.699C272.679 146.139 271.04 144.418 271.04 142.297L267.96 120.215C267.96 118.094 269.599 116.374 271.618 116.374H288.951C290.971 116.374 292.61 118.094 292.61 120.215L289.53 142.297C289.53 144.418 287.891 146.139 285.871 146.139H285.868Z" fill="#C05599" />
    <path d="M192.62 113.451L182.345 113.403C173.451 113.839 169.522 111.271 167.81 104.672L162.183 82.951C161.035 78.5262 163.545 73.9208 167.759 72.7154L180.52 69.0696C186.804 67.2748 193.342 71.202 195.055 77.8006L200.278 96.8651C201.988 103.464 198.908 111.656 192.623 113.451H192.62Z" fill="#C05599" />
    <path d="M214.057 33.5734C213.879 33.4164 196.002 18.0245 175.769 25.7989C175.549 25.8848 175.394 26.0922 175.369 26.335C175.343 26.5779 173.045 50.7246 195.249 62.8912C195.339 62.9416 195.438 62.9652 195.534 62.9652C195.734 62.9652 195.929 62.8645 196.044 62.6809L214.17 34.4323C214.347 34.1568 214.297 33.7866 214.054 33.5734H214.057Z" fill="#C05599" />
    <path d="M315.886 54.1071C314.484 53.6481 313.032 54.2285 312.273 55.5465C308.369 62.3229 301.413 63.6349 298.443 64.1947C297.346 64.402 296.844 64.4967 296.356 64.9262C295.769 65.4415 295.546 66.2826 295.794 67.0438L300.68 82.0063C300.843 82.5098 301.199 82.9214 301.661 83.1436C301.904 83.2591 302.166 83.3183 302.429 83.3183C302.663 83.3183 302.9 83.2709 303.123 83.1761C315.172 78.0524 317.672 64.864 318.089 57.4568C318.176 55.9138 317.288 54.5721 315.883 54.1101L315.886 54.1071Z" fill="#C05599" />
    <path fillRule="evenodd" clipRule="evenodd" d="M307.951 83.0457C307.951 115.923 278.083 142.576 241.24 142.576C204.396 142.576 174.531 115.923 174.531 83.0457C174.531 50.1679 197.333 21.5728 241.243 21.5728C285.152 21.5728 307.954 50.1679 307.954 83.0457H307.951ZM198.493 73.2839C200.716 73.2839 202.518 71.3917 202.518 69.0576C202.518 66.7234 200.716 64.8313 198.493 64.8313C196.27 64.8313 194.468 66.7234 194.468 69.0576C194.468 71.3917 196.27 73.2839 198.493 73.2839ZM222.653 38.5344C222.831 38.4633 240.751 31.5389 260.535 38.567V38.5699L262.312 33.0523C240.669 25.3637 221.5 32.765 220.696 33.0849L222.653 38.5344Z" fill="#C05599" />
  </svg>

)

export const StudentLoanForgivenessLogo = () => (
  <svg className="w-100" width="504" height="168" viewBox="0 0 504 168" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2825_13488)">
      <rect width="504" height="168" fill="#DCF3D1" />
      <path d="M274.885 15.1955C259.222 4.6632 249.617 21.9794 247.503 26.3678C245.077 22.1461 234.236 5.57572 219.381 17.2203C199.43 32.8612 230.646 71.8396 249.58 83.4638L249.592 83.4641C267.63 70.4873 295.92 29.3392 274.885 15.1955Z" fill="#F75553" />
      <path d="M229.347 154.412L229.36 110.499L236.218 110.501L236.22 105.209H236.236V105.016H239.199L239.201 99.7208L236.236 99.7188H236.22L236.222 94.2282H236.238L256.714 94.2341L280.887 94.2419L280.885 99.5391H283.864V99.9278L283.862 105.223H280.883V105.416L280.881 110.52H286.476V110.909L286.474 116.204H280.879V116.398L280.878 121.5L284.616 121.502V121.695L284.614 127.186L279.436 127.184V127.373V132.475V132.664L284.612 132.666L284.61 138.157V138.35L280.874 138.348L280.872 143.452L285.368 143.454V143.841L285.366 149.138L274.789 149.134L274.787 154.426L229.347 154.412Z" fill="var(--icon-secondary-color)" />
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.3">
        <path d="M236.308 105.199L283.872 105.216L283.874 99.5354L236.31 99.5188L236.308 105.199Z" fill="#F97923" />
      </g>
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.3">
        <path d="M238.919 116.177L286.483 116.194L286.485 110.514L238.921 110.497L238.919 116.177Z" fill="#F97923" />
      </g>
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.3">
        <path d="M237.845 126.967L285.409 126.984L285.411 121.493L237.847 121.477L237.845 126.967Z" fill="#F97923" />
      </g>
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.3">
        <path d="M237.361 137.939L284.924 137.956L284.926 132.475L237.363 132.458L237.361 137.939Z" fill="#F97923" />
      </g>
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.3">
        <path d="M237.839 148.914L285.403 148.931L285.405 143.446L237.841 143.43L237.839 148.914Z" fill="#F97923" />
      </g>
      <path d="M204.989 154.404L249.652 154.419L249.654 148.929L204.991 148.913L204.989 154.404Z" fill="#FAAD14" />
      <path d="M200.495 148.911L245.158 148.926L245.16 143.436L200.497 143.42L200.495 148.911Z" fill="#FAAD14" />
      <path d="M204.233 143.423L248.896 143.438L248.898 137.948L204.235 137.932L204.233 143.423Z" fill="#FAAD14" />
      <path d="M199.057 137.936L243.72 137.951L243.722 132.461L199.059 132.445L199.057 137.936Z" fill="#FAAD14" />
      <path d="M204.237 132.451L248.9 132.467L248.902 126.976L204.239 126.961L204.237 132.451Z" fill="#FAAD14" />
      <path d="M200.499 126.96L245.162 126.976L245.164 121.485L200.501 121.469L200.499 126.96Z" fill="#FAAD14" />
      <path d="M206.095 121.469L250.758 121.485L250.76 115.994L206.097 115.979L206.095 121.469Z" fill="#FAAD14" />
      <path d="M200.503 115.979L245.166 115.994L245.168 110.504L200.505 110.488L200.503 115.979Z" fill="#FAAD14" />
      <path d="M203.481 110.489L248.145 110.505L248.146 105.014L203.483 104.999L203.481 110.489Z" fill="#FAAD14" />
      <path d="M200.505 104.998L245.168 105.013L245.17 99.5226L200.507 99.507L200.505 104.998Z" fill="#FAAD14" />
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.5">
        <path d="M199.063 137.938V132.448H204.243V126.963H200.507V121.472H206.103V115.982H200.511V110.491H203.491V105H200.515V99.5098L220.991 99.5156C221.813 99.5156 222.479 100.223 222.479 101.094V103.428C222.479 104.299 223.145 105.006 223.967 105.006C224.79 105.006 225.456 105.713 225.456 106.584V108.919C225.456 109.79 224.788 110.497 223.965 110.497C223.143 110.497 222.475 111.204 222.475 112.075V114.409C222.475 115.28 223.141 115.987 223.963 115.987H226.579C227.401 115.987 228.069 116.695 228.067 117.568V119.902C228.067 120.773 227.399 121.48 226.577 121.48H223.962C223.139 121.48 222.471 122.185 222.471 123.056V125.39C222.471 126.262 223.137 126.969 223.96 126.969H224.717C225.54 126.969 226.208 127.676 226.206 128.547V130.875C226.206 131.746 225.538 132.453 224.715 132.453H222.518C221.696 132.453 221.028 133.159 221.028 134.03V136.364C221.028 137.235 221.694 137.942 222.516 137.942H224.714C225.536 137.942 226.202 138.649 226.202 139.522V141.851C226.202 142.722 225.534 143.429 224.712 143.429H223.954C223.131 143.429 222.463 144.134 222.463 145.007V147.341C222.463 148.212 223.129 148.919 223.952 148.919H225.468C226.29 148.919 226.958 149.626 226.956 150.498V151.043V152.832C226.956 153.703 226.288 154.41 225.466 154.41L204.991 154.404V151.037V148.914H200.495V143.421H204.232V137.936H199.054L199.063 137.938Z" fill="var(--icon-secondary-color)" />
      </g>
      <path d="M248.139 104.815H203.491V105.202H248.139V104.815Z" fill="black" />
      <path d="M245.158 110.304H200.511V110.69H245.158V110.304Z" fill="black" />
      <path d="M250.75 115.796H206.103V116.183H250.75V115.796Z" fill="black" />
      <path d="M245.154 121.285H200.507V121.671H245.154V121.285Z" fill="black" />
      <path d="M248.891 126.775H204.243V127.162H248.891V126.775Z" fill="black" />
      <path d="M243.711 132.26H199.063V132.647H243.711V132.26Z" fill="black" />
      <path d="M243.711 137.751H199.063V138.137H243.711V137.751Z" fill="black" />
      <path d="M248.885 143.237H204.238V143.624H248.885V143.237Z" fill="black" />
      <path d="M249.643 148.728H204.995V149.115H249.643V148.728Z" fill="black" />
      <path d="M258.706 154.416L303.881 154.432L303.883 148.941L258.708 148.925L258.706 154.416Z" fill="#FAAD14" />
      <path d="M254.16 148.923L299.335 148.939L299.337 143.448L254.162 143.432L254.16 148.923Z" fill="#FAAD14" />
      <path d="M257.942 143.433L303.117 143.449L303.119 137.958L257.944 137.943L257.942 143.433Z" fill="#FAAD14" />
      <path d="M252.707 137.948L297.882 137.964L297.884 132.473L252.709 132.458L252.707 137.948Z" fill="#FAAD14" />
      <path d="M257.944 132.464L303.119 132.479L303.121 126.989L257.946 126.973L257.944 132.464Z" fill="#FAAD14" />
      <path d="M254.166 126.972L299.341 126.988L299.343 121.498L254.168 121.482L254.166 126.972Z" fill="#FAAD14" />
      <path d="M259.823 121.484L304.998 121.499L305 116.009L259.825 115.993L259.823 121.484Z" fill="#FAAD14" />
      <g style={{ "mixBlendMode": "multiply" }} opacity="0.5">
        <path d="M280.544 116.005L259.833 115.999V121.49H254.175V126.98H257.954V132.465H252.716V137.956H257.95V143.441H254.169V148.931H258.714V151.054V154.422L279.422 154.428C280.255 154.428 280.928 153.721 280.93 152.849V151.06V150.515C280.93 149.644 280.257 148.937 279.424 148.937H277.891C277.059 148.937 276.385 148.23 276.385 147.359V145.025C276.385 144.153 277.061 143.446 277.893 143.446H278.661C279.493 143.446 280.167 142.741 280.169 141.868V139.54C280.169 138.669 279.495 137.962 278.663 137.962H276.44C275.608 137.962 274.934 137.255 274.934 136.381V134.047C274.934 133.176 275.61 132.469 276.442 132.469H278.665C279.497 132.469 280.171 131.764 280.173 130.893V128.565C280.173 127.693 279.499 126.986 278.667 126.986H277.899C277.067 126.986 276.393 126.279 276.393 125.408V123.074C276.393 122.203 277.069 121.496 277.901 121.496H280.546C281.378 121.496 282.052 120.791 282.054 119.919V117.585C282.054 116.714 281.38 116.007 280.55 116.007L280.544 116.005Z" fill="var(--icon-secondary-color)" />
      </g>
      <path d="M304.992 115.812H259.833V116.198H304.992V115.812Z" fill="#2E2E2E" />
      <path d="M299.334 121.3H254.175V121.687H299.334V121.3Z" fill="#2E2E2E" />
      <path d="M303.111 126.793H257.952V127.18H303.111V126.793Z" fill="#2E2E2E" />
      <path d="M297.873 132.276H252.714V132.662H297.873V132.276Z" fill="#2E2E2E" />
      <path d="M297.873 137.766H252.714V138.153H297.873V137.766Z" fill="#2E2E2E" />
      <path d="M303.108 143.253H257.948V143.64H303.108V143.253Z" fill="#2E2E2E" />
      <path d="M303.873 148.744H258.714V149.13H303.873V148.744Z" fill="#2E2E2E" />
      <path d="M299.332 123.611C299.592 126.025 301.693 126.674 301.693 126.674C301.693 126.674 299.59 127.32 299.33 129.735C299.07 127.32 296.969 126.672 296.969 126.672C296.969 126.672 299.072 126.025 299.332 123.611Z" fill="white" />
      <path d="M267.384 113.034C267.644 115.448 269.746 116.097 269.746 116.097C269.746 116.097 267.642 116.743 267.382 119.158C267.123 116.743 265.021 116.095 265.021 116.095C265.021 116.095 267.125 115.448 267.384 113.034Z" fill="white" />
      <path d="M276.684 96.8729C276.944 99.2872 279.045 99.9356 279.045 99.9356C279.045 99.9356 276.942 100.582 276.682 102.996C276.422 100.582 274.321 99.9337 274.321 99.9337C274.321 99.9337 276.424 99.2872 276.684 96.8729Z" fill="white" />
      <path d="M255.792 107.036C255.889 107.944 256.679 108.186 256.679 108.186C256.679 108.186 255.888 108.428 255.79 109.337C255.692 108.428 254.903 108.186 254.903 108.186C254.903 108.186 255.694 107.944 255.792 107.036Z" fill="white" />
      <path d="M205.206 91.7827C205.388 93.4723 206.859 93.9255 206.859 93.9255C206.859 93.9255 205.388 94.3786 205.206 96.0662C205.025 94.3767 203.554 93.9235 203.554 93.9235C203.554 93.9235 205.025 93.4704 205.206 91.7827Z" fill="white" />
      <path d="M220.932 129.807C221.157 131.895 222.973 132.455 222.973 132.455C222.973 132.455 221.155 133.014 220.93 135.102C220.706 133.014 218.889 132.453 218.889 132.453C218.889 132.453 220.708 131.895 220.932 129.807Z" fill="white" />
      <path d="M283.854 133.477C284.013 134.956 285.3 135.352 285.3 135.352C285.3 135.352 284.013 135.749 283.852 137.225C283.694 135.747 282.407 135.35 282.407 135.35C282.407 135.35 283.694 134.954 283.854 133.477Z" fill="white" />
      <path d="M233.079 106.667C233.196 107.751 234.14 108.042 234.14 108.042C234.14 108.042 233.195 108.333 233.079 109.417C232.962 108.333 232.019 108.042 232.019 108.042C232.019 108.042 232.964 107.751 233.079 106.667Z" fill="white" />
      <path d="M234.46 139.116C234.624 140.64 235.951 141.048 235.951 141.048C235.951 141.048 234.624 141.456 234.46 142.98C234.296 141.456 232.97 141.048 232.97 141.048C232.97 141.048 234.296 140.64 234.46 139.116Z" fill="white" />
      <path d="M246.943 43.6203C247.164 43.9998 247.481 44.3149 247.862 44.5339C248.244 44.753 248.677 44.8683 249.117 44.8683C249.558 44.8683 249.991 44.753 250.372 44.5339C250.754 44.3149 251.071 43.9998 251.291 43.6203L252.996 40.681L254.7 37.7452C254.921 37.3659 255.038 36.9352 255.038 36.4968C255.038 36.0583 254.923 35.6275 254.702 35.2477C254.482 34.868 254.165 34.5527 253.783 34.3336C253.401 34.1145 252.968 33.9994 252.528 33.9999C252.087 33.9998 251.654 34.1153 251.273 34.335C250.891 34.5546 250.575 34.8704 250.355 35.2506L246.943 41.1396C246.724 41.519 246.608 41.9491 246.608 42.3869C246.608 42.8248 246.724 43.2549 246.943 43.6342" fill="white" />
      <path d="M245.239 40.681L245.431 41.0111L249.489 33.9999H245.707C245.266 33.9994 244.833 34.1145 244.451 34.3336C244.07 34.5527 243.753 34.868 243.532 35.2477C243.312 35.6275 243.196 36.0583 243.197 36.4968C243.197 36.9352 243.314 37.3659 243.535 37.7452L245.239 40.681Z" fill="white" />
      <path d="M241.527 46.7715C241.747 47.1509 242.063 47.4658 242.445 47.6848C242.826 47.9037 243.259 48.0189 243.699 48.0188H243.79C244.23 48.0193 244.663 47.9042 245.045 47.6851C245.427 47.466 245.744 47.1507 245.964 46.771C246.185 46.3912 246.3 45.9604 246.3 45.5219C246.3 45.0834 246.183 44.6528 245.962 44.2735L244.216 41.23L242.449 38.1864C242.228 37.807 241.911 37.4919 241.53 37.2728C241.148 37.0538 240.715 36.9384 240.275 36.9384C239.834 36.9384 239.401 37.0538 239.02 37.2728C238.638 37.4919 238.321 37.807 238.101 38.1864L238.052 38.2733C237.831 38.6537 237.715 39.085 237.715 39.5241C237.715 39.9631 237.831 40.3944 238.052 40.7748L241.527 46.7715Z" fill="white" />
      <path d="M236.725 48.0188H240.647L236.508 40.9069L236.319 41.23L234.552 44.2735C234.331 44.6529 234.215 45.0835 234.214 45.5219C234.214 45.9604 234.33 46.3912 234.55 46.771C234.77 47.1508 235.087 47.4661 235.469 47.6851C235.851 47.9042 236.284 48.0193 236.725 48.0188Z" fill="white" />
      <path d="M243.748 54.2101C244.188 54.2095 244.62 54.094 245.001 53.8752C245.383 53.6563 245.699 53.3417 245.92 52.9629L245.965 52.8829C246.185 52.5031 246.301 52.0724 246.301 51.6341C246.301 51.1958 246.184 50.7652 245.964 50.3857C245.743 50.0062 245.426 49.6911 245.045 49.472C244.663 49.2529 244.23 49.1376 243.79 49.1376H236.728C236.287 49.1376 235.855 49.2529 235.473 49.472C235.091 49.6911 234.774 50.0062 234.554 50.3857C234.333 50.7652 234.217 51.1958 234.217 51.6341C234.217 52.0724 234.332 52.5031 234.552 52.8829L234.605 52.9698C234.825 53.3503 235.142 53.6663 235.524 53.8859C235.906 54.1055 236.339 54.2209 236.78 54.2206L243.748 54.2101Z" fill="white" />
      <path d="M244.38 55.5929H236.117L236.306 55.9195L238.073 58.963C238.293 59.3425 238.609 59.6577 238.991 59.8769C239.372 60.0961 239.805 60.2114 240.245 60.2114C240.685 60.2114 241.118 60.0961 241.499 59.8769C241.881 59.6577 242.197 59.3425 242.417 58.963L244.184 55.9195L244.38 55.5929Z" fill="white" />
      <path d="M251.312 53.5326L251.267 53.4562C251.046 53.0767 250.73 52.7616 250.348 52.5426C249.966 52.3235 249.533 52.2082 249.093 52.2082C248.652 52.2082 248.22 52.3235 247.838 52.5426C247.456 52.7616 247.139 53.0767 246.919 53.4562L245.145 56.4962L243.399 59.5398C243.178 59.9191 243.061 60.3497 243.061 60.7882C243.06 61.2266 243.176 61.6574 243.396 62.0372C243.616 62.417 243.933 62.7323 244.315 62.9514C244.697 63.1705 245.13 63.2856 245.571 63.2851H245.672C246.113 63.2851 246.547 63.1695 246.929 62.95C247.31 62.7304 247.628 62.4146 247.848 62.0343L251.34 56.0272C251.56 55.6478 251.676 55.2177 251.676 54.7799C251.676 54.3421 251.56 53.912 251.34 53.5326" fill="white" />
      <path d="M253.031 56.4962L252.839 56.1488L248.714 63.2712H252.626C253.066 63.2717 253.499 63.1566 253.881 62.9375C254.263 62.7184 254.58 62.4031 254.8 62.0233C255.02 61.6436 255.136 61.2127 255.136 60.7743C255.135 60.3358 255.019 59.9052 254.798 59.5259L253.031 56.4962Z" fill="white" />
      <path d="M256.67 50.4057C256.449 50.0268 256.133 49.7123 255.751 49.494C255.37 49.2756 254.937 49.1611 254.497 49.1619H254.407C253.966 49.1618 253.534 49.277 253.153 49.4958C252.771 49.7147 252.455 50.0296 252.235 50.4089C252.014 50.7881 251.898 51.2184 251.898 51.6563C251.898 52.0943 252.014 52.5245 252.234 52.9038L253.998 55.9473L255.765 58.9908C255.986 59.3703 256.303 59.6853 256.684 59.9044C257.066 60.1235 257.499 60.2388 257.939 60.2388C258.38 60.2388 258.813 60.1235 259.194 59.9044C259.576 59.6853 259.893 59.3703 260.113 58.9908L260.162 58.9039C260.383 58.5242 260.499 58.0934 260.499 57.6549C260.499 57.2164 260.383 56.7856 260.162 56.4059L256.67 50.4057Z" fill="white" />
      <path d="M261.486 49.1619H257.564L261.702 56.2739L261.891 55.9473L263.658 52.9072C263.879 52.5279 263.996 52.0973 263.996 51.6588C263.996 51.2203 263.881 50.7895 263.66 50.4097C263.44 50.03 263.123 49.7147 262.741 49.4956C262.36 49.2765 261.927 49.1614 261.486 49.1619Z" fill="white" />
      <path d="M263.62 44.2075C263.399 43.8279 263.082 43.5128 262.7 43.2938C262.318 43.0749 261.885 42.9598 261.444 42.9602H254.459C254.019 42.9618 253.586 43.0781 253.205 43.2976C252.824 43.517 252.508 43.8319 252.287 44.211L252.241 44.2874C252.021 44.6672 251.906 45.0979 251.906 45.5362C251.906 45.9745 252.022 46.4051 252.243 46.7846C252.463 47.1641 252.78 47.4793 253.162 47.6983C253.544 47.9174 253.977 48.0327 254.417 48.0327H261.479C261.919 48.0327 262.352 47.9174 262.734 47.6983C263.116 47.4793 263.433 47.1641 263.653 46.7846C263.874 46.4051 263.99 45.9745 263.99 45.5362C263.99 45.0979 263.875 44.6672 263.655 44.2874L263.62 44.2075Z" fill="white" />
      <path d="M253.83 41.5878H262.093L261.905 41.2647L260.138 38.2212C259.918 37.8417 259.601 37.5265 259.22 37.3073C258.838 37.0881 258.406 36.9727 257.965 36.9727C257.525 36.9727 257.092 37.0881 256.711 37.3073C256.33 37.5265 256.013 37.8417 255.793 38.2212L254.026 41.2647L253.83 41.5878Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2825_13488">
        <rect width="504" height="168" fill="white" />
      </clipPath>
    </defs>
  </svg>

)

export const PayDashboardAdvisorSvg = () => (
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="256" height="256" rx="128" fill="url(#paint0_linear_1927_27367)" />
    <rect width="256" height="256" rx="128" fill="url(#paint1_radial_1927_27367)" fillOpacity="0.7" />
    <g filter="url(#filter0_f_1927_27367)">
      <circle cx="128" cy="128" r="74.5933" stroke="white" />
    </g>
    <g filter="url(#filter1_f_1927_27367)">
      <circle cx="128" cy="128" r="100.193" stroke="white" />
    </g>
    <path d="M118.707 106.064C119.655 107.683 121.017 109.028 122.657 109.963C124.297 110.898 126.158 111.39 128.051 111.39C129.945 111.39 131.805 110.898 133.445 109.963C135.085 109.028 136.447 107.683 137.395 106.064L144.72 93.5176L152.045 80.9865C152.995 79.3672 153.496 77.5292 153.498 75.6577C153.499 73.7862 153.002 71.9473 152.055 70.3263C151.108 68.7053 149.746 67.3595 148.105 66.4244C146.464 65.4893 144.603 64.998 142.709 65C140.814 64.9995 138.954 65.4928 137.314 66.4302C135.675 67.3676 134.315 68.7158 133.372 70.3387L118.707 95.4751C117.763 97.0943 117.266 98.9303 117.266 100.799C117.266 102.668 117.763 104.504 118.707 106.123" fill="white" />
    <path d="M111.385 93.5176L112.211 94.9264L129.652 65H113.396C111.502 64.998 109.641 65.4893 108 66.4244C106.359 67.3595 104.997 68.7053 104.05 70.3263C103.103 71.9473 102.606 73.7862 102.607 75.6577C102.609 77.5292 103.11 79.3672 104.06 80.9865L111.385 93.5176Z" fill="white" />
    <path d="M95.4276 119.514C96.3738 121.133 97.7348 122.477 99.3737 123.412C101.013 124.346 102.872 124.838 104.764 124.838H105.154C107.048 124.84 108.91 124.348 110.551 123.413C112.192 122.478 113.554 121.132 114.501 119.511C115.447 117.89 115.945 116.051 115.943 114.18C115.941 112.308 115.44 110.47 114.491 108.851L106.985 95.8602L99.3903 82.8693C98.4428 81.2496 97.0806 79.9047 95.4405 78.9697C93.8003 78.0347 91.9401 77.5425 90.0465 77.5425C88.153 77.5425 86.2927 78.0347 84.6526 78.9697C83.0125 79.9047 81.6501 81.2496 80.7025 82.8693L80.4924 83.2401C79.5453 84.8638 79.0469 86.7048 79.0469 88.5788C79.0469 90.4528 79.5453 92.2939 80.4924 93.9175L95.4276 119.514Z" fill="white" />
    <path d="M74.7889 124.837H91.6454L73.8583 94.481L73.0477 95.8602L65.4527 108.851C64.5029 110.47 64.0018 112.308 64 114.18C63.9982 116.051 64.4957 117.89 65.4424 119.511C66.3891 121.132 67.7516 122.478 69.3925 123.413C71.0333 124.348 72.8947 124.839 74.7889 124.837Z" fill="white" />
    <path d="M104.974 151.265C106.866 151.262 108.724 150.769 110.362 149.835C112.001 148.901 113.362 147.558 114.31 145.941L114.506 145.6C115.451 143.979 115.948 142.14 115.947 140.269C115.946 138.398 115.447 136.56 114.499 134.941C113.551 133.321 112.189 131.976 110.549 131.041C108.908 130.105 107.048 129.613 105.154 129.613H74.8037C72.91 129.613 71.0495 130.105 69.4092 131.041C67.769 131.976 66.4066 133.321 65.459 134.941C64.5114 136.56 64.0119 138.398 64.0107 140.269C64.0096 142.14 64.5068 143.979 65.4524 145.6L65.6774 145.97C66.6229 147.594 67.9856 148.943 69.6275 149.881C71.2694 150.818 73.1326 151.311 75.0289 151.309L104.974 151.265Z" fill="white" />
    <path d="M107.69 157.167H72.1758L72.9862 158.561L80.5815 171.552C81.527 173.172 82.8876 174.517 84.5266 175.453C86.1655 176.388 88.0249 176.881 89.9177 176.881C91.8105 176.881 93.67 176.388 95.3089 175.453C96.9479 174.517 98.3085 173.172 99.254 171.552L106.849 158.561L107.69 157.167Z" fill="white" />
    <path d="M137.487 148.373L137.292 148.047C136.345 146.427 134.982 145.082 133.342 144.147C131.702 143.212 129.842 142.72 127.948 142.72C126.055 142.72 124.195 143.212 122.554 144.147C120.914 145.082 119.552 146.427 118.604 148.047L110.979 161.023L103.474 174.014C102.524 175.633 102.023 177.471 102.021 179.342C102.02 181.214 102.517 183.053 103.464 184.674C104.411 186.295 105.773 187.641 107.414 188.576C109.055 189.511 110.916 190.002 112.81 190H113.246C115.142 190 117.004 189.507 118.646 188.57C120.288 187.632 121.65 186.284 122.597 184.661L137.607 159.021C138.552 157.401 139.049 155.566 139.049 153.697C139.049 151.828 138.552 149.992 137.607 148.373" fill="white" />
    <path d="M144.872 161.022L144.046 159.539L126.319 189.94H143.131C145.025 189.942 146.886 189.451 148.527 188.516C150.168 187.581 151.531 186.235 152.477 184.614C153.424 182.993 153.921 181.154 153.92 179.282C153.918 177.411 153.417 175.573 152.467 173.954L144.872 161.022Z" fill="white" />
    <path d="M160.512 135.026C159.565 133.409 158.203 132.067 156.564 131.135C154.925 130.203 153.067 129.714 151.175 129.717H150.785C148.893 129.717 147.034 130.208 145.396 131.143C143.757 132.077 142.396 133.421 141.45 135.04C140.503 136.659 140.005 138.495 140.005 140.364C140.005 142.234 140.503 144.07 141.449 145.689L149.029 158.68L156.624 171.671C157.572 173.29 158.934 174.635 160.574 175.57C162.214 176.505 164.074 176.998 165.968 176.998C167.862 176.998 169.722 176.505 171.362 175.57C173.002 174.635 174.364 173.29 175.312 171.671L175.522 171.3C176.469 169.679 176.968 167.84 176.968 165.969C176.968 164.097 176.469 162.258 175.522 160.637L160.512 135.026Z" fill="white" />
    <path d="M181.211 129.717H164.354L182.142 160.074L182.952 158.68L190.547 145.704C191.497 144.084 191.998 142.246 192 140.375C192.001 138.504 191.504 136.665 190.557 135.044C189.611 133.423 188.248 132.077 186.607 131.142C184.967 130.207 183.105 129.715 181.211 129.717Z" fill="white" />
    <path d="M190.382 108.57C189.433 106.95 188.07 105.605 186.428 104.67C184.787 103.736 182.925 103.244 181.031 103.246H151.01C149.118 103.253 147.26 103.75 145.622 104.686C143.984 105.623 142.623 106.967 141.674 108.585L141.479 108.911C140.533 110.532 140.036 112.371 140.037 114.242C140.038 116.113 140.538 117.95 141.485 119.57C142.433 121.19 143.795 122.535 145.435 123.47C147.076 124.405 148.936 124.898 150.83 124.898H181.181C183.074 124.898 184.935 124.405 186.575 123.47C188.215 122.535 189.578 121.19 190.525 119.57C191.473 117.95 191.972 116.113 191.974 114.242C191.975 112.371 191.478 110.532 190.532 108.911L190.382 108.57Z" fill="white" />
    <path d="M148.31 97.3879H183.824L183.013 96.0087L175.418 83.0178C174.473 81.3978 173.112 80.0524 171.473 79.1169C169.834 78.1814 167.974 77.689 166.082 77.689C164.189 77.689 162.329 78.1814 160.69 79.1169C159.051 80.0524 157.691 81.3978 156.745 83.0178L149.15 96.0087L148.31 97.3879Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M181.267 21.8442C169.265 21.8442 159.539 31.5707 159.539 43.5727C159.539 47.6678 160.672 51.4928 162.642 54.76C162.124 57.5004 160.659 61.7284 156.479 63.4638C156.479 63.4638 164.386 64.3138 169.526 61.8479C172.913 64.0305 176.942 65.3011 181.272 65.3011C193.273 65.3011 203 55.5746 203 43.5727C203 31.5707 193.265 21.8442 181.267 21.8442ZM166.273 39.1131C166.273 38.0475 167.137 37.1837 168.203 37.1837H195.016C196.081 37.1837 196.945 38.0475 196.945 39.1131V40.4627C196.945 41.5283 196.081 42.3921 195.016 42.3921H168.203C167.137 42.3921 166.273 41.5283 166.273 40.4627V39.1131ZM168.203 44.7066C167.137 44.7066 166.273 45.5704 166.273 46.636V47.9857C166.273 49.0512 167.137 49.915 168.203 49.915H184.599C185.664 49.915 186.528 49.0512 186.528 47.9857V46.636C186.528 45.5704 185.664 44.7066 184.599 44.7066H168.203Z" fill="white" />
    <defs>
      <filter id="filter0_f_1927_27367" x="33.9062" y="33.9067" width="188.187" height="188.187" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="9.5" result="effect1_foregroundBlur_1927_27367" />
      </filter>
      <filter id="filter1_f_1927_27367" x="8.30664" y="8.30664" width="239.387" height="239.387" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="9.5" result="effect1_foregroundBlur_1927_27367" />
      </filter>
      <linearGradient id="paint0_linear_1927_27367" x1="8.57132e-08" y1="133.283" x2="256" y2="130.56" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--icon-color)" />
        <stop offset="1" stopColor="var(--icon-secondary-color)" />
      </linearGradient>
      <radialGradient id="paint1_radial_1927_27367" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(128 128) rotate(90) scale(128)">
        <stop offset="0.880208" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.53" />
      </radialGradient>
    </defs>
  </svg>
)