import moment from "moment";


export const dateMonthYearSlash = 'DD/MM/YYYY';
export const monthDateYearSlash = 'MM/DD/YYYY';
export const datePickerMonthDateYearSlash = 'MM/dd/yyyy';


export const formatDateUsingMoment = (date, format) => {
    return moment(date).format(
        format
      )
}