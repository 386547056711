import React, { useEffect, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchCustomer } from '../../../actions/customerActions';
import { fetchEsignDisclosureAgreement, acceptEsignDisclosureAgreement } from "../../../actions/esignDisclosureActions";
import { responseMessage } from '../../../utils/alert';

const EsignDisclosure = ({ fetchCustomer, fetchEsignDisclosureAgreement, acceptEsignDisclosureAgreement, customerData, acceptEsignDisclosureResponse, esignDisclosureAgreementData, errors, onCloseButtonClick, onAcceptButtonClickSuccess, alreadyAcceptedRoute, showCloseButton }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [agreementDocument, setAgreementDocument] = useState(null);
    const [termsData, setTermsData] = useState(null);
    const [disableAcceptButton, setDisableAcceptButton] = useState(true);

    const closeModal = () => {
        setIsOpen(false)
    }
    useEffect(() => {
        fetchCustomer();
    }, [])

    useEffect(() => {
        if (customerData?.attributes?.any_esign_disclosure === false) { //* checking to show modal or not
            fetchEsignDisclosureAgreement();
            setIsOpen(true)
        } else if (customerData?.attributes?.any_esign_disclosure === true) {
            if (alreadyAcceptedRoute) {
                alreadyAcceptedRoute();
            }
        }
    }, [customerData])

    useEffect(() => {
        if (esignDisclosureAgreementData === undefined) {
            setAgreementDocument(null);
            setTermsData(null)
            responseMessage('error', 'Esign Terms and Conditions not found.');
        } else {
            setAgreementDocument(esignDisclosureAgreementData?.attributes?.document);
            setTermsData(esignDisclosureAgreementData)
        }
    }, [esignDisclosureAgreementData])

    useEffect(() => {
        //* success callback of accept button
        closeModal();
        if (onAcceptButtonClickSuccess && Object.keys(acceptEsignDisclosureResponse).length !== 0) {
            onAcceptButtonClickSuccess();
        }
    }, [acceptEsignDisclosureResponse])

    const handleScroll = (e) => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop < element.clientHeight + 300) {
            // do something at end of scroll
            setDisableAcceptButton(false)
        }
    }

    const acceptAgreement = () => {
        const jsonData = {
            data: {
                relationships: {
                    legal_agreement: {
                        data: {
                            type: termsData.type,
                            id: termsData.id,
                        },
                    },
                    customer: {
                        data: null,
                    },
                },
                type: "user-agreements",
            },
        };
        acceptEsignDisclosureAgreement(jsonData);
    }

    return (
        <Modal
            size="xl"
            show={isOpen}
            onHide={() => { closeModal(); if (onCloseButtonClick) onCloseButtonClick() }}
            animation={false}
            backdrop="static"
            keyboard={false}
            scrollable={true}
        >
            <Modal.Header className="border-0" closeButton={showCloseButton ?? true}>
                <Modal.Title className=''>
                    <div className="d-flex align-items-center">
                        <div className='pinkCircularDiv'>
                            <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 18H15V20.5H5V18ZM5 13H15V15.5H5V13ZM12.5 0.5H2.5C1.125 0.5 0 1.625 0 3V23C0 24.375 1.1125 25.5 2.4875 25.5H17.5C18.875 25.5 20 24.375 20 23V8L12.5 0.5ZM17.5 23H2.5V3H11.25V9.25H17.5V23Z" fill="var(--icon-color)" />
                            </svg>

                        </div>
                        <h2 className='Gfs-225 Gt-slate-5 ml-3 mb-0'>Esign Disclosure Agreement</h2>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='custom-modal-body' onScroll={(e) => handleScroll(e)}>
                <div className="max-height-custom p-4" dangerouslySetInnerHTML={{ __html: agreementDocument }} />
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button className="G-orange-button" variant="primary" disabled={disableAcceptButton} onClick={acceptAgreement}>
                    I Accept the terms
                </Button>
            </Modal.Footer>
        </Modal >
    )
}


EsignDisclosure.propTypes = {
    fetchEsignDisclosureAgreement: PropTypes.func.isRequired,
    acceptEsignDisclosureAgreement: PropTypes.func.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    esignDisclosureAgreementData: state.esignDisclosure_terms.esignDisclosureAgreementData,
    acceptEsignDisclosureResponse: state.esignDisclosure_terms.acceptEsignDisclosureResponse,
    customerData: state.customer_array.customerData,
    errors: state.errors,
});

export default connect(mapStateToProps, { fetchEsignDisclosureAgreement, acceptEsignDisclosureAgreement, fetchCustomer })(EsignDisclosure);
