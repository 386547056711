import {
  VERIFY_TOKEN,
  RESET_PASSWORD,
  AUTHENTICATION_PASSWORD,
  RESET_PASSWORD_EMAIL,
  REGISTRATE_USER,
  LOGIN_USER, 
  FETCH_INVITATION_DATA,
  FETCH_IMPERSONATION,
  POST_IMPERSONATION,
  GET_COMPANY_LANDING_DATA,
  POST_COMPANY_LANDING_DATA,
  FETCH_EMPLOYER_METADATA,
  FETCH_PARTNER_DETAILS,
  CONFIRM_SAML_EMAIL,
} from '../actions/types';

const initialState = {
  fetchInvitation: {},
  loginData:{},
  registrateData:null,
  requestResetRes:null,
  authenticationPasswordRes:null,
  resetPasswordRes:null,
  verifyTokenResponse:null,
  impersonationData:null,
  companyLandingData:null,
  postCompanyLandingData: null,
  employerMetadata: null,
  partnerData:null,
  samlUserData:null
}

export default function authenticationReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_INVITATION_DATA:
      return {
        ...state,
        fetchInvitation: action.payload
      }
    case LOGIN_USER:
      return {
        ...state,
        loginData: action.payload
      }
    case REGISTRATE_USER:
      return {
        ...state,
        registrateData: action.payload
      }
    case RESET_PASSWORD_EMAIL:
      return {
        ...state,
        requestResetRes: action.payload
      }
    case AUTHENTICATION_PASSWORD:
      return {
        ...state,
        authenticationPasswordRes: action.payload
      }  
    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordRes: action.payload
      }
    case VERIFY_TOKEN:
      return {
        ...state,
        verifyTokenResponse: action.payload
      }        
    case FETCH_IMPERSONATION:
      return {
        ...state,
        impersonationData: action.payload
      } 
    case POST_IMPERSONATION:
      return {
        ...state,
        impersonatedUserData: action.payload
      } 
    case GET_COMPANY_LANDING_DATA:
      return {
        ...state,
        companyLandingData: action.payload
      } 
    case POST_COMPANY_LANDING_DATA:
      return {
        ...state,
        postCompanyLandingData: action.payload
      } 
    case FETCH_EMPLOYER_METADATA:
      return {
        ...state,
        employerMetadata: action.payload
      } 
    case FETCH_PARTNER_DETAILS:
      return {
        ...state,
        partnerData: action.payload
      } 
    case CONFIRM_SAML_EMAIL:
      return {
        ...state,
        samlUserData: action.payload
      } 
    default:
      return state;
  }
}