import moment from 'moment';

/**
 * Match Graph function helpers
 */

/**
 * This creates an array of integers - first index is for Jan, second Feb, etc. It's the sum of transactions
 * amounts for any given month. Notice that the second month is the aggregate of the previous months transaction
 * amount plus the current month (to create an ascending line graph).
 *
 * NOTE: Assumes transactions are ordered by `date`!
 *
 * @param {Array[DS.Model]} Array of transaction models (filtered by the current year).
 * @param {Object} startDate - contains matchInitialStartDate, matchRecurringStartDate
 *
 * @return {Array[Integer|null]} The returned array will contain integers AND/OR null values for future months.
 */
export function plotTransactionData(transactions, startDates) {
  const { startingMonthNumber } = getMonthsForPeriod(startDates);
  const initialArray = makePlotInitialArray(transactions, { startingMonthNumber });

  // for each transaction, add the amount to the index of initialArray where it belongs to. E.g, for a transaction
  // in Jaunary, add its amount to the `0` index of the array (January == 0, Feb == 1, etc).
  const plotArray = transactions.reduce((collection, transaction) => {
    // we substrant startingMonthNumber since we may have truncated the array of the plot
    const month = moment(transaction.date).month() - startingMonthNumber;
    collection[month] += transaction.amount/100;
    return collection;
  }, initialArray);

  // Add each previous month amount to the next month in order to make the graph as commulative each month.
  for (let i = 0; i < plotArray.length; i++) {
    if (plotArray[i] !== null && i !== 0) {
      plotArray[i] += plotArray[i - 1];
    }
  }
  return plotArray;
}

/**
 * Makes initial plot array by filling out with zeros each month/index in the array, up to the last month where
 * we have a transaction, then leaves the rest as `null`s so we know which ones are for the projection.
 *
 * @param {Array} transactions
 * @param {Object} numberOfMonthsInPeriod, startingMonthNumber
 *
 * @return {Array}
 */
export function makePlotInitialArray(transactions, { startingMonthNumber }) {
  const latestMonthIndex = transactions.reduce((latestMonthIndex, transaction) => {
    const month = moment(transaction.date).month();
    if (latestMonthIndex === null || latestMonthIndex < month) {
      return month;
    }
    return latestMonthIndex;
  }, null);

  const initialArray = Array.from({ length: 12 }).fill(null);

  for (let i = 0; i < initialArray.length; i++) {
    if (latestMonthIndex !== null && latestMonthIndex >= i) {
      initialArray[i] = 0;
    }
  }
  initialArray.splice(0, startingMonthNumber);
  return initialArray;
}

/**
 * Generates the plot dataset for the projected payments for each month after the current month.
 *
 * @param {Array[Integer|null]} plot data from real transactions as calculated by the `plotTransactionData` function
 *
 * @return {Array[Integer]} plot projection data set.
 */
export function plotProjectedDataset(plotedDataset) {
  const monthlyContributions = plotedDataset.reduce((col, datum, index, plot) => {
    if (!datum) return col;

    if (index === 0) {
      col.push(datum);
    } else {
      col.push(datum - Number(plot[index - 1]));
    }
    return col;
  }, []);

  const averageMonthlyPayment = monthlyContributions.reduce((total, datum) => datum + total, 0) / monthlyContributions.length;

  return plotedDataset.reduce((collection, datum, index, plotData) => {
    if (datum && plotData[index + 1]) {
      return collection;
    } else if (datum) {
      collection[index] = datum;
      return collection;
    }

    collection[index] = (collection[index - 1] || 0) + averageMonthlyPayment;
    return collection;

  }, Array.from({ length: 12 }).fill(null));
}

/**
 * Return month name based in month index
 *
 * @param {Integer} index
 * @return {String} month name
 */
export function calcMonthName(index) {
  switch (index) {
  case 0:
    return 'January';
  case 1:
    return 'February';
  case 2:
    return 'March';
  case 3:
    return 'April';
  case 4:
    return 'May';
  case 5:
    return 'June';
  case 6:
    return 'July';
  case 7:
    return 'August';
  case 8:
    return 'September';
  case 9:
    return 'October';
  case 10:
    return 'November';
  case 11:
    return 'December';
  default:
    throw new Error('Wrong month index');
  }
}

/**
 * @param {Object} startDates contains matchInitialStartDate and matchRecurringStartDate
 *
 * @return {Object} object with `numberOfMonthsInPeriod` and `startingMonthNumber`.
 */
export function getMonthsForPeriod(startDates) {
  const initialStartDate = moment(startDates.matchInitialStartDate);
  const recurringStartDate = moment(startDates.matchRecurringStartDate);

  if (initialStartDate.year() === new Date().getFullYear() &&
    initialStartDate.isBefore(recurringStartDate)) {
    // this block means we are in the period between initial start date and recurring. Option A.

    const rawMonthDiff = initialStartDate.diff(recurringStartDate, 'months', true);
    const diffInMonthsBetweenDates = Math.round(Math.abs(rawMonthDiff));

    return {
      numberOfMonthsInPeriod: diffInMonthsBetweenDates,
      startingMonthNumber: initialStartDate.month(),
    };
  } else {
    // Use the recurringStart Date until the next year. We assume the schedule is for a full
    // year (12 months). Option B.

    return {
      numberOfMonthsInPeriod: 12,
      startingMonthNumber: recurringStartDate.month(),
    };
  }
}
