const initialState = (type) => {
  const tokenTypeOrNot = type === 'token' ?
    {
      currentCustomerData: null,
      status_url: "",
      errors: '',
      anyGeneralAgreement: false,
    }
    :
    {
      email: "",
      password: "",
      loginData: null,
      statusUrl: "",
      errors: null,
    };

  const commonObj = {
    customerData: null,
    status: "",
    hasProfile: false,
    hasAdvisor: false,
    hasSavingsPlan: false,
    hasTuitionPlan: false,
    hasAdvisorOnly: false,
    hasSavingsPlanOnly: false,
    hasTuitionPlanOnly: false,
    hasAdvisorAndSavingsPlan: false,
    selectedService: false,
    advisorEnabled: false,
    savingsPlanEnabled: false,
    pruSavingsPlanEnabled: false,
    showProfile: false,
    numberOfPayActiveServicerAccounts: 0,
    userVerify: false,
    anyFiveTwoNineTerms: false,
    advisorFirst: false,
    includeKnowledgeBank: false,
    showFiveToNineOrAdvisorSwitcher: false,
  };

  const onlyInLogin = type === 'login' ?
    {
      ssoDomainEnabled: false,
      employerMetadata: "",
      loading: false,
    } : {};

  const onlyInRegister = type === 'register' ?
    {
      name: "",
      password_confirmation: "",
      characterValid: false,
      numberValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      specialValid: false,
      fetchInvitation: null,
      redirect: false,
      passwordComplexity: false,
      passwordValidation: false,
    } : {};

  return {
    ...commonObj,
    ...onlyInLogin,
    ...onlyInRegister,
    ...tokenTypeOrNot,
  };
}

export default initialState;