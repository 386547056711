import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { uploadNslds} from '../../../actions/advisorActions';
import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { Modal} from 'react-bootstrap';
import DragAndDropBox from '../../shared/Drag&DropBox/DragAndDropBox';
import FileList from '../../shared/FileList/FileList';
import { BoxArrowUpRight, Check, XLg } from 'react-bootstrap-icons';
import CustomModal from '../../shared/CustomModal/CustomModal';
import { AddLoanWithFSABreadcrumb } from '../CommonBreadcrumb/Breadcrumb.constant';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb'

class Nslds extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modalProp: {
        show: false,
        title: '',
        bodyText: '',
        footerButton: true,
        footerButtonName: '',
        svgType: '', //check, info
        footerButtonOnclick: '',
        footerButtonHref: '',
        footerButtonOnclickArgs: ''
      }
    }
    this.fileRef = React.createRef();

    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor | Add Loans with FSA"
    intercomTrackEvent(eventNames.visitedAdvisorNsldsImport);
  }

  componentDidUpdate(prevProps) {

    if (this.props.uploadNsldsResponse !== prevProps.uploadNsldsResponse) {

      this.setState({
        ...this.state,
        modalProp: {
          ...this.state.modalProp,
          show: true,
          title: 'Upload successfully',
          bodyText: "You've successfully uploaded your NSLDS file. Redirecting you to loans dashboard in 5 seconds...",
          footerButton: true,
          footerButtonName: "Continue",
          footerButtonOnclick: '',
          footerButtonHref: '/advice-and-tools/dashboard',
          footerButtonOnclickArgs: ''
        }
      });
      intercomTrackEvent(eventNames.successfulAdvisorNsldsFileUpload);
      setTimeout(() =>
        this.props.history.push({
          pathname: '/advice-and-tools/dashboard'
        }), 5000
      )
    }
  }
  handleBack = (e) => {
    intercomTrackEvent(eventNames.visitedAdvisorAboutMe);
    this.props.history.push({
      pathname: '/advice-and-tools/dashboard'
    })
  }

  removeFile = () => {
    this.setState({
      fileSelected: null
    })
  }

  setFileData = (file) => {
    this.setState({
      fileSelected: file
    })
  }

  sendAsFormData = () => {
    const { uploadNslds } = this.props;
    const formData = new FormData();
    formData.append("file", this.state.fileSelected);
    uploadNslds(formData);
  }

  formatFileSize = (value) => {
    if (value > 100000) {
      return (Number(value / (1024 * 1024))).toFixed(2) + ' MB'
    } else {
      return (Number(value / (1024 * 1024)) * 1000).toFixed(2) + ' KB'
    }
  }

  toLoans = (e) => {
    this.props.history.push({
      pathname: '/dashboard/loans'
    })
  }
  openModal = () => this.setState({
    ...this.state, isOpen: true,
  });
  closeModal = () => this.setState({ ...this.state, isOpen: false });
  setAddManuallyButton = (value) => {
    this.setState({ ...this.state, addManually: value });
  }
  removeFile = () => {
    this.setState({
      fileSelected: null
    })
  }

  setFileData = (file) => {
    this.setState({
      fileSelected: file
    })
  }

  sendAsFormData = () => {
    const { uploadNslds } = this.props;
    const formData = new FormData();
    formData.append("file", this.state.fileSelected);
    uploadNslds(formData);

  }

  formatFileSize = (value) => {
    if (value > 100000) {
      return (Number(value / (1024 * 1024))).toFixed(2) + ' MB'
    } else {
      return (Number(value / (1024 * 1024)) * 1000).toFixed(2) + ' KB'
    }
  }
  render() {
    return (

      <>
        <Header {...this.props} />
        <div className="G-page-color-background">
          <div className='G-page-main-container my-5'>
            <Breadcrumb list={AddLoanWithFSABreadcrumb} />
            <div className='p-4 Gborder-05 bg-white p-4 G-grid11-2 my-3 rounded-lg'>
              <div>
                <div className='pinkCircularDiv mb-4'>
                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.125 11.5H3.625V20.25H6.125V11.5ZM13.625 11.5H11.125V20.25H13.625V11.5ZM24.25 22.75H0.5V25.25H24.25V22.75ZM21.125 11.5H18.625V20.25H21.125V11.5ZM12.375 3.075L18.8875 6.5H5.8625L12.375 3.075ZM12.375 0.25L0.5 6.5V9H24.25V6.5L12.375 0.25Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h3 className='Gfs-175 Gt-slate-5 mb-4'>Add Loans with FSA</h3>
                <p className='sans-medium'>
                  Please provide the following details to manually add your loan
                  details.
                </p>

              </div>

                <div>
                  <div>
                    <div>

                      <h4 className='Gfs-125'>You can upload your FSA Loan File here</h4>
                      <label className='mb2'>
                        <button className='Gt-pink-8 text-decoration-none cancelLink G-cursor-pointer my-2' onClick={this.openModal}>How do I download my loan details? <BoxArrowUpRight size={18} className='ml-1' /></button>
                      </label>
                      <DragAndDropBox setFileData={(file) => this.setFileData(file)} ref={this.fileRef} />
                    </div>
                    <div>
                      {this.state.fileSelected ?
                        <FileList fileList={new Array(this.state.fileSelected)} removeFile={this.removeFile} />
                        : null}
                    </div>
                  </div>
                  <div className='d-flex mob-btn-div-column'>
                    <button className={this.state.fileSelected ? 'G-orange-button' : 'G-disabled-button'} disabled={!this.state.fileSelected} onClick={this.sendAsFormData}> Upload FSA File</button>
                  </div>

                  <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                    <div className='mr-3'>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                      </svg>
                    </div>
                    <div>
                      <p className='sans-medium'>Have only private loans? Skip NSLDS file upload and we can help
                        you enter them manually! </p>
                      <p className='sans-medium'> Have federal and private loans? You can add both types!
                        We suggest starting with the federal ones and then adding private ones </p>
                    </div>
                  </div>
                </div>


            </div>
            <div className="d-flex justify-content-end p-3 mob-btn-div-column">
              <button type="button" className="G-cancel-button ml-3 mt-2" onClick={this.handleBack.bind(this)}>
                Go Back
              </button>
            </div>
          </div>
        </div>

        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Body>
            <div className="d-flex">
              <div className="pinkCircularDiv mb-3 mr-3">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M27.5 38.75H31.25V42.5H27.5V38.75ZM30 17.5C36.6875 17.775 39.6 24.525 35.625 29.5875C34.5875 30.8375 32.9125 31.6625 32.0875 32.7125C31.25 33.75 31.25 35 31.25 36.25H27.5C27.5 34.1625 27.5 32.4 28.3375 31.15C29.1625 29.9 30.8375 29.1625 31.875 28.3375C34.9 25.5375 34.15 21.575 30 21.25C29.0054 21.25 28.0516 21.6451 27.3483 22.3483C26.6451 23.0516 26.25 24.0054 26.25 25H22.5C22.5 23.0109 23.2902 21.1032 24.6967 19.6967C26.1032 18.2902 28.0109 17.5 30 17.5Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <div>
                <div className="d-flex">
                  <h4 className='py-3'>How do I download my loan details?</h4>
                  <div> <button onClick={this.closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                </div>
                <p className="sans-medium">
                  <ol className='pl-3'>
                    <li className='sans-medium'>Sign into your account at <a className='G-link' target="_blank" href="https://studentaid.gov/fsa-id/sign-in/landing" rel="noopener noreferrer"> Federal Student Aid.</a></li>
                    <li className='sans-medium'>In the upper right hand corner of the “My Aid” section, click on “View Details”.</li>
                    <li className='sans-medium'>In the upper right hand corner of the “My Aid Summary” page, click on “Download My Aid Data” (If you’re using a mobile device, rotate it horizontally to see this option.)</li>
                  </ol>
                  <p className='sans-medium'>After you click Continue to finish the download, a file called MyStudentData.txt will download to your computer. You can upload that file to the right.</p>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Footer />
        <CustomModal svgComponent={
          <div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div>
        }
          modalProp={{ ...this.state.modalProp }} />
      </>
    )
  }
}
Nslds.propTypes = {
  uploadNslds: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  uploadNsldsResponse: state.advisor.uploadNsldsResponse,
  errors: state.errors
});
export default connect(mapStateToProps, { uploadNslds })(Nslds);
