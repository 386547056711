import React, { useEffect, useState } from 'react';
import { updateStatusUrl } from '../../../../actions/customerActions';
import { clearLocalStorageItems } from '../../../../helpers/clearLocalStorage';
import { invalidateSession } from '../../../../actions/authenticationActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const Logout = (props) => {
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (Object.keys(props.updateStatusUrlResponse).length) {
      if (localStorage.getItem('saml_logout_page') !== null && localStorage.getItem('saml_logout_page') !== "") {
        window.location.href = localStorage.getItem('saml_logout_page');
        clearLocalStorageItems();
      } else {
        if (logout === true) {
          props.invalidateSession();
          clearLocalStorageItems();
          window.location.href = "/login";
        }
      }
    }
  }, [props.updateStatusUrlResponse])

  function handleLogout() {
    const { updateStatusUrl } = props;
    const pathname = window.location.pathname;
    const requestData = {
      "data": {
        "attributes": {
          "status_url": pathname
        }
      }
    }
    updateStatusUrl(requestData);
    setLogout(true);
  }

  return (
    <Nav.Link className='header-logOutBtn' href="#" onClick={handleLogout}>
      LOG OUT
    </Nav.Link>
  )
}

// class Logout extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       logout: false
//     }
//   }
//   componentWillReceiveProps = (nextProps) => {
//     if (this.props.updateStatusUrlResponse !== nextProps.updateStatusUrlResponse) {
//       if (localStorage.getItem('saml_logout_page') !== null && localStorage.getItem('saml_logout_page') !== "") {
//         window.location.href = localStorage.getItem('saml_logout_page');
//         clearLocalStorageItems();
//       } else {
//         if (this.state.logout === true) {
//           this.props.invalidateSession();

//           this.props.history.push('/login')
//           clearLocalStorageItems();
//         }
//       }
//     }
//   }

//   handleLogout() {
//     const { updateStatusUrl } = this.props;
//     const pathname = window.location.pathname;
//     this.setState({
//       'logout': true
//     })
//     const requestData = {
//       "data": {
//         "attributes": {
//           "status_url": pathname
//         }
//       }
//     }
//     updateStatusUrl(requestData);
//   }

//   render() {
//     return (
//       // <li>
//       //   <NavLink className="btn-logout nav-link mt-0" to="#" onClick={this.handleLogout.bind(this)}>Log Out</NavLink>
//       // </li>
//       <Nav.Link className='header-logOutBtn' href="#" onClick={this.handleLogout.bind(this)}>
//         LOG OUT
//       </Nav.Link>
//     )
//   }
// }

const mapStateToProps = state => ({
  updateStatusUrlResponse: state.customer_array.updateStatusUrlResponse,
});

const mapDispatchToProps = {
  updateStatusUrl,
  invalidateSession
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));