//import reduce from 'lodash/reduce';
import map from 'lodash/map';
import { partners } from '../../common/js/constants';
const creditScoreOptions = [
  {
    name: '< 660',
    value: 659
  },
  {
    name: '660 - 719',
    value: 719   
  },
  {
    name: '720 - 779',
    value: 779
  },
  {
    name: '780+',
    value: 780
  }
];
const terms = {
  '659': {
    '5y': {
      floor: 5.26,
      ceiling: 9.39,
      months: 60,
    },
    '10y': {
      floor: 5.33,
      ceiling: 9.43,
      months: 120,
    },
    '15y': {
      floor: 5.43,
      ceiling: 9.50,
      months: 180
    },
    '20y': {
      floor: 5.59,
      ceiling: 9.65,
      months: 240
    },
  },
  '719': {
    '5y': {
      floor: 5.26,
      ceiling: 9.39,
      months: 60,
    },
    '10y': {
      floor: 5.33,
      ceiling: 9.43,
      months: 120,
    },
    '15y': {
      floor: 5.43,
      ceiling: 9.50,
      months: 180
    },
    '20y': {
      floor: 5.59,
      ceiling: 9.65,
      months: 240
    },
  },
  '779': {
    '5y': {
      floor: 4.73,
      ceiling: 7.43,
      months: 60,
    },
    '10y': {
      floor: 4.80,
      ceiling: 7.46,
      months: 120,
    },
    '15y': {
      floor: 4.95,
      ceiling: 7.45,
      months: 180
    },
    '20y': {
      floor: 5.15,
      ceiling: 7.65,
      months: 240
    },
  },
  '780': {
    '5y': {
      floor: 3.89,
      ceiling: 6.61,
      months: 60,
    },
    '10y': {
      floor: 4.75,
      ceiling: 6.63,
      months: 120,
    },
    '15y': {
      floor: 4.90,
      ceiling: 6.69,
      months: 180
    },
    '20y': {
      floor: 5.10,
      ceiling: 6.76,
      months: 240
    },
  }
};
export function getNearestCreditScore(creditScore) {
  const termsArray =  map(Object.keys(terms), Number).sort();
  // Finds closest credit score index
  const termIndex = termsArray.reduce((res, val, i) => {
    return creditScore > val ? i + 1 : res;
  }, 0);
  return termsArray[termIndex];
};
export function getCreditTerms(creditScore) {
  const nearestScore = getNearestCreditScore(creditScore);
  /*if (isPresent(this.get('dynamicTerms'))) {
    return this.get('dynamicTerms');
  }*/
  return terms[nearestScore];
};
export function creditScoreOptionsByPartner(partnerName) {
  /**
   * Citizens doesn't want the lowest credit score used.
   * See - GENIUS-3815
   */

  if (partnerName === partners.CITIZENS) {
    // Removes the lowest credit score (659). 
    return creditScoreOptions.reduce((terms, currentTerm) => {
      switch (currentTerm.value) {
        case 659:
          return terms;
        case 719:
          currentTerm.set('name', '680 - 719');
          break;
        default:  
        }
        return terms.concat([currentTerm]);
    },[])
  }
  return creditScoreOptions;
}

