import React from 'react';
import { Route } from "react-router-dom";

import PlanSavings from "./Intro/PlanSavings";
import about from "./Welcome/About";
import AdvisorPlanSavings from "./AdvisorAndPlanSavings/AdvisorPlanSavings";
import Dashboard from "./Dashboard/Dashboard";

import TRDtiCalculator from "../../Components/TRAdviceAndTools/TRDtiCalculator";
import CollegePlanning from "../../Components/TRAdviceAndTools/CollegePlanning";
import Maps from "./AdviceAndTools/Maps";
import Faqs from "./AdviceAndTools/Faqs";

import Contribution from "./Pay/Contribution";
import EditContribution from "./Pay/EditContribution";
import ProfilesDeductions from "./Pay/ProfileDeductions";
import AccountSummary from "./Pay/AccountSummary";
import History from "./Pay/History";
import ContributionList from '../FiveToNine/FriendsFamily/ContributionList/ContributionList';
import PayrollDeduction from "./PayrollDeduction/PayrollDeduction";

import SwitchToAdvisor from '../SwitchBetweenServices/SwitchToAdvisor';
import ManageFriendsFamily from './FriendsFamily/ManageFriendsFamily';
import CreateFriendsFamily from './FriendsFamily/CreateFriendsFamily';
import Esign from './Welcome/Esign';

import AdvisorLearn from '../Advisor/Intro/Learn'
import BenefitsOfFiveTwoNinePlan from './BenefitsOfFiveTwoNinePlan/BenefitsOfFiveTwoNinePlan';
import ChoosePlanBestForMe from './ChoosePlanBestForMe/ChoosePlanBestForMe';

const fiveTwoNineRoutes = [
  <Route exact path="/intro/learn" component={AdvisorLearn} key={1} />,
  <Route exact path="/intro/plan-savings" component={PlanSavings} key={1} />,
  <Route exact path="/welcome/about" component={about} key={1} />,
  <Route exact path={["/welcome/esign"]} component={Esign} key={1} />,
  <Route exact path="/advisor-and-plan-savings" component={AdvisorPlanSavings} key={1} />,
  <Route exact path={["/dashboard", '/master-dashboard']} component={Dashboard} key={1} />,

  <Route exact path="/dashboard/dti-calculator" component={TRDtiCalculator} key={1} />,
  <Route exact path="/dashboard/collegeplanning" component={CollegePlanning} key={1} />,
  <Route exact path="/dashboard/map" component={Maps} key={1} />,
  <Route exact path="/dashboard/faqs" component={Faqs} key={1} />,

  <Route exact path={["/dashboard/manage-friends-and-family", "/dashboard/manage-friends-and-family/confirm"]} component={ManageFriendsFamily} key={1} />,
  <Route exact path="/dashboard/:servicerAccountId/create-friends-and-family" component={CreateFriendsFamily} key={1} />,
  <Route exact path={["/dashboard/add-account"]} component={Contribution} key={1} />,
  <Route exact path={["/dashboard/:servicer_account_id/edit-account", "/dashboard/:servicer_account_id/edit"]} component={EditContribution} key={1} />,
  <Route exact path="/dashboard/profiles/deductions" component={ProfilesDeductions} key={1} />,
  <Route exact path="/dashboard/profiles/deductions/:saId" component={ProfilesDeductions} key={1} />,
  <Route exact path={["/dashboard/account-summary"]} component={AccountSummary} key={1} />,
  <Route exact path="/dashboard/history" component={History} key={1} />,
  <Route exact path="/dashboard/:servicerAccountId/contribution-list" component={ContributionList} key={1} />,
  <Route exact path="/dashboard/payroll-deduction" component={PayrollDeduction} key={1} />,
  <Route exact path="/dashboard/choose-plan-best-for-me" component={ChoosePlanBestForMe} key={1} />,
  <Route exact path="/dashboard/benefits-of-fiveTwoNine-plan" component={BenefitsOfFiveTwoNinePlan} key={1} />,

  <Route exact path="/advice-and-tools/dashboard" component={SwitchToAdvisor} key={1} />,
]

export default fiveTwoNineRoutes;