import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import './animate.css'
import { Provider } from "react-redux";
import './i18n/config';
import store from "./store";
import Loader from "./Components/Includes/Loader/Loader";
import requiredAuth from "./hoc/requiredAuth";

import Samlauth from "./Components/Samlauth";
import Token from "./Components/Token/Token"

import Landing from "./Components/Landing/Index/Index";
import Login from "./Components/Login/Login";
import Registration from "./Components/Registration/Registration";
import ResetPasswordRequest from "./Components/ResetPassword/ResetPasswordRequest";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Impersonate from "./Components/Impersonate/Impersonate";

import Index from "./Components/Index/Index";
import Error from "./Components/Error/Error";

import PublicServiceLoanForgiveness from "./Components/PublicServiceLoanForgiveness/PublicServiceLoanForgiveness";
import ConfirmContribution from './Components/FiveToNine/FriendsFamily/ConfirmContribution';
import ContributionSetUp from './Components/FiveToNine/FriendsFamily/ContributionSetUp';
import ContributionWelcome from './Components/FiveToNine/FriendsFamily/ContributionWelcome';
import CompanyLanding from "./Components/CompanyLanding/CompanyLanding";
import PartnerThemeData from "./Components/PartnerThemeData";
import ConfirmEmail from "./Components/ConfirmEmail/ConfirmEmail";
import ScrollToTop from "./utils/ScrollToTop";
import FloatingButton from './Components/shared/FloatingButton/FloatingButton';
import ReactGA from 'react-ga4';


ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA_ID || '',
  }
])

let partnerName = localStorage.getItem('partnerName');
let employerName = localStorage.getItem('employerName');
ReactGA._gtag("set", "user_properties", {
  partner_name: partnerName,
  employer_name: employerName,
});

class App extends Component {
  componentDidUpdate() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.hostname });
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Loader />
          <PartnerThemeData />
          <ScrollToTop />
          <FloatingButton />
          <Switch>
            <Route exact path="/samlauth" component={Samlauth} />
            <Route exact path="/confirm_email" component={ConfirmEmail} />
            <Route exact path="/token/:token" component={Token} />

            <Route exact path="/hello" component={Landing} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/password/reset/request" component={ResetPasswordRequest} />
            <Route exact path="/password/reset/:token" component={ResetPassword} />
            <Route exact path="/dashboard/public-service-loan-forgiveness" component={requiredAuth(PublicServiceLoanForgiveness)} />
            <Route exact path="/impersonate/:token" component={Impersonate} />

            <Route exact path="/:servicerAccountId/contribution" component={ContributionWelcome} />
            <Route exact path="/:servicerAccountId/contribution-setup" component={ContributionSetUp} />
            <Route exact path="/:servicer_account_id/confirm-contribution" component={ConfirmContribution} />

            <Route exact path="/hello/:token" component={CompanyLanding} />

            <Route exact path="/error" component={requiredAuth(Error)} />
            <Route path="/" component={requiredAuth(Index)} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
