import {
  GET_TEST_LOANS,
  GET_INDIVIDUAL_CONTRIBUTION,
  SUBMIT_CONTRIBUTION,
  GET_BENEFICIARY_PROFILES,
  ADD_FNF_CONTRIBUTION,
  DELETE_INVITE,
  SEND_INVITE,
  GET_INVITATIONS,
  GET_INVITEQUOTA,
  UPDATE_SELECTED_SERVICE,
  GET_ELIGIBILITYRESULTS,
  GET_LOANS,
  GET_DISBURSEMENTS,
  POST_SERVICER,
  POST_SERVICER_ACCOUNTS,
  GET_ADDRESSES,
  GET_SERVICERS,
  GET_SERVICER_ACCOUNTS,
  ACCEPT_FIVETWONINE_TERMS,
  FETCH_FIVETWONINE_TERMS,
  GET_ERRORS,
  POST_BENEFICIARY_PROFILE,
  EDIT_BENEFICIARY_PROFILE,
  GET_BENEFICIARY_PROFILE,
  SEND_INVITE_TO_FNF_CONTRIBUTOR,
  GET_CONTRIBUTIONS,
  SET_SELECTED_CONTRIBUTION_ID_TO_THANK,
  POST_THANK_YOU_TO_CONTRIBUTOR,
  RESET_LOANS_REDUCER,
  GET_BENEFICIARY_PROFILE_AVATARS,
  POST_TRACKABLE_EVENTS,
  POST_FIVETWONINE_ADDRESS
} from "./types";

import { apiAction } from "./apiActions";
import config from '../config';

export const fetchFivetwonineTerms = () => {
  return apiAction({
    url: config.apiBaseUrl + "legal_agreements/latest_fivetwonine_terms",
    onSuccess: setfetchFivetwonineTerms,
    callback: "",
    onFailure: errorfetchFivetwonineTerms,
    label: FETCH_FIVETWONINE_TERMS,
  });
};

function setfetchFivetwonineTerms(data) {
  return {
    type: FETCH_FIVETWONINE_TERMS,
    payload: data.data,
  };
}
function errorfetchFivetwonineTerms(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data,
    };
  }
}
export const acceptFivetwonineTerms = (user_agreements) => {
  return apiAction({
    url: config.apiBaseUrl + "user_agreements",
    method: "POST",
    data: user_agreements,
    onSuccess: setupdateFivetwonineTerms,
    callback: "",
    onFailure: errorupdateFivetwonineTerms,
    label: ACCEPT_FIVETWONINE_TERMS,
  });
};
function setupdateFivetwonineTerms(data) {
  return {
    type: ACCEPT_FIVETWONINE_TERMS,
    payload: data.data,
  };
}
function errorupdateFivetwonineTerms(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getServicerAccounts = () => {
  return apiAction({
    url: config.apiBaseUrl + "servicer_accounts",
    onSuccess: setGetServicerAccounts,
    callback: "",
    onFailure: errorGetServicerAccounts,
    label: GET_SERVICER_ACCOUNTS,
  });
};
function setGetServicerAccounts(response) {
  return {
    type: GET_SERVICER_ACCOUNTS,
    payload: response,
  };
}
function errorGetServicerAccounts(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getServicers = () => {
  return apiAction({
    url: config.apiBaseUrl + "servicers?ftn=1",
    onSuccess: setGetServicers,
    callback: "",
    onFailure: errorGetServicers,
    label: GET_SERVICERS,
  });
};
function setGetServicers(response) {
  return {
    type: GET_SERVICERS,
    payload: response,
  };
}
function errorGetServicers(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getAddresses = () => {
  return apiAction({
    url: config.apiBaseUrl + "addresses",
    onSuccess: setAddresses,
    callback: "",
    onFailure: errorAddresses,
    label: GET_ADDRESSES,
  });
};
function setAddresses(response) {
  return {
    type: GET_ADDRESSES,
    payload: response,
  };
}
function errorAddresses(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const postServicerAccounts = (servicer_accounts) => {
  return apiAction({
    url: config.apiBaseUrl + "servicer_accounts",
    method: "POST",
    data: servicer_accounts,
    onSuccess: setPostServicerAccounts,
    callback: "",
    onFailure: errorPostServicerAccounts,
    label: POST_SERVICER_ACCOUNTS,
  });
};
function setPostServicerAccounts(response) {
  return {
    type: POST_SERVICER_ACCOUNTS,
    payload: response,
  };
}
function errorPostServicerAccounts(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const postEditServicerAccounts = (servicer_accounts) => {
  return apiAction({
    url: config.apiBaseUrl + "servicer_accounts/" + servicer_accounts.data.id,
    method: "PATCH",
    data: servicer_accounts,
    onSuccess: setPostEditServicerAccounts,
    callback: "",
    onFailure: errorPostEditServicerAccounts,
    label: POST_SERVICER_ACCOUNTS,
  });
};
function setPostEditServicerAccounts(response) {
  return {
    type: POST_SERVICER_ACCOUNTS,
    payload: response,
  };
}
function errorPostEditServicerAccounts(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const postServicer = (servicer) => {
  return apiAction({
    url: config.apiBaseUrl + "servicers?ftn=1",
    method: "POST",
    data: servicer,
    onSuccess: setPostServicer,
    callback: "",
    onFailure: errorPostServicer,
    label: POST_SERVICER,
  });
};
function setPostServicer(response) {
  return {
    type: POST_SERVICER,
    payload: response,
  };
}
function errorPostServicer(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getDisbursements = () => {
  return apiAction({
    url: config.apiBaseUrl + "disbursements",
    onSuccess: setGetDisbursements,
    callback: "",
    onFailure: errorGetDisbursements,
    label: GET_DISBURSEMENTS,
  });
};
function setGetDisbursements(response) {
  return {
    type: GET_DISBURSEMENTS,
    payload: response.data,
  };
}
function errorGetDisbursements(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export function getLoans (){
  return apiAction({
    url: config.apiBaseUrl + "loans",
    onSuccess: setGetLoans,
    callback: "",
    onFailure: errorGetLoans,
    label: GET_LOANS,
  });
};
function setGetLoans(response) {
  return {
    type: GET_LOANS,
    payload: response.data,
  };
}
function errorGetLoans(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export function resetLoansReducer() {
  return {
    type: RESET_LOANS_REDUCER
  };
}
export const getEligibilityResults = () => {
  return apiAction({
    url: config.apiBaseUrl + "eligibility_results",
    onSuccess: setGetEligibilityResults,
    callback: "",
    onFailure: errorGetEligibilityResults,
    label: GET_ELIGIBILITYRESULTS,
  });
};
function setGetEligibilityResults(response) {
  return {
    type: GET_ELIGIBILITYRESULTS,
    payload: response,
  };
}
function errorGetEligibilityResults(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const updateSelectedService = (requestData) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "customers/" +
      localStorage.getItem("customerId") +
      "/update_selected_service",
    method: "POST",
    data: requestData,
    onSuccess: setUpdateSelectedService,
    callback: "",
    onFailure: errorUpdateSelectedService,
    label: UPDATE_SELECTED_SERVICE,
  });
};
function setUpdateSelectedService(response) {
  return {
    type: UPDATE_SELECTED_SERVICE,
    payload: response,
  };
}
function errorUpdateSelectedService(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getInviteQuota = () => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/registrations/invite_quota",
    onSuccess: setGetInviteQuota,
    callback: "",
    onFailure: errorGetInviteQuota,
    label: GET_INVITEQUOTA,
  });
};
function setGetInviteQuota(response) {
  return {
    type: GET_INVITEQUOTA,
    payload: response.data.invite_quota,
  };
}
function errorGetInviteQuota(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getInvitations = () => {
  return apiAction({
    url: config.apiBaseUrl + "invitations",
    onSuccess: setGetInvitations,
    callback: "",
    onFailure: errorGetInvitations,
    label: GET_INVITATIONS,
  });
};
function setGetInvitations(response) {
  return {
    type: GET_INVITATIONS,
    payload: response.data,
  };
}
function errorGetInvitations(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const sendInviteFamilyMember = (requestData) => {
  return apiAction({
    url:
      config.apiBaseUrl + "authentication/registrations/invite_family_member",
    method: "POST",
    data: requestData,
    onSuccess: setSendInviteFamilyMember,
    callback: "",
    onFailure: errorSendInviteFamilyMember,
    label: SEND_INVITE,
  });
};
function setSendInviteFamilyMember(response) {
  return {
    type: SEND_INVITE,
    payload: response.data,
  };
}
function errorSendInviteFamilyMember(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const deleteInviteFamilyMember = (invitationId) => {
  return apiAction({
    url: config.apiBaseUrl + "users/" + invitationId,
    method: "DELETE",
    data: "",
    onSuccess: setDeleteInviteFamilyMember,
    callback: "",
    onFailure: errorDeleteInviteFamilyMember,
    label: DELETE_INVITE,
  });
};
function setDeleteInviteFamilyMember(response) {
  return {
    type: DELETE_INVITE,
    payload: response,
  };
}
function errorDeleteInviteFamilyMember(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const saveBeneficiaryProfile = (
  servicerAccountId,
  beneficiaryProfileData
) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/beneficiary_profiles",
    method: "POST",
    data: beneficiaryProfileData,
    onSuccess: setPostBeneficiaryProfile,
    callback: "",
    onFailure: errorPostBeneficiaryProfile,
    label: POST_BENEFICIARY_PROFILE,
  });
};

function setPostBeneficiaryProfile(response) {
  return {
    type: POST_BENEFICIARY_PROFILE,
    payload: response.data,
  };
}
function errorPostBeneficiaryProfile(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export function getTestLoans (){
  return apiAction({
    url: config.apiBaseUrl + "loans",
    onSuccess: setGetTestLoans,
    callback : '',
    onFailure: errorGetTestLoans,
    label: GET_TEST_LOANS
  });
}
function setGetTestLoans(response) {
  return {
    type: GET_TEST_LOANS,
    payload: response.data
  };
}
function errorGetTestLoans(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}

export const editBeneficiaryProfile = (
  servicerAccountId,
  beneficiaryProfileId,
  beneficiaryProfileData
) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/beneficiary_profiles/" +
      beneficiaryProfileId,
    method: "PUT",
    data: beneficiaryProfileData,
    onSuccess: setEditBeneficiaryProfile,
    callback: "",
    onFailure: errorEditBeneficiaryProfile,
    label: EDIT_BENEFICIARY_PROFILE,
  });
};
function setEditBeneficiaryProfile(response) {
  return {
    type: EDIT_BENEFICIARY_PROFILE,
    payload: response.data,
  };
}
function errorEditBeneficiaryProfile(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getBeneficiaryProfiles = (servicerAccountId) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/beneficiary_profiles",
    onSuccess: setGetBeneficiaryProfiles,
    callback: "",
    onFailure: errorGetBeneficiaryProfiles,
    label: GET_BENEFICIARY_PROFILE,
  });
};
function setGetBeneficiaryProfiles(response) {
  return {
    type: GET_BENEFICIARY_PROFILE,
    payload: response.data,
  };
}
function errorGetBeneficiaryProfiles(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}

export const addFnFContribution = (servicerAccountId, requestData) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/contributions",
    method: "POST",
    data: requestData,
    onSuccess: setAddFnFContribution,
    callback: "",
    onFailure: errorAddFnFContribution,
    label: ADD_FNF_CONTRIBUTION,
  });
};
function setAddFnFContribution(response) {
  return {
    type: ADD_FNF_CONTRIBUTION,
    payload: response.data,
  };
}
function errorAddFnFContribution(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const sendInviteToFnfContributor = (servicerAccountId, requestData) => {
  return apiAction({
    url: `${config.apiBaseUrl}servicer_accounts/${servicerAccountId}/invite_contributor`,
    method: "POST",
    data: requestData,
    onSuccess: setSendInviteToFnfContributor,
    callback: "",
    onFailure: errorSendInviteToFnfContributor,
    label: SEND_INVITE_TO_FNF_CONTRIBUTOR,
  });
};
function setSendInviteToFnfContributor(response) {
  return {
    type: SEND_INVITE_TO_FNF_CONTRIBUTOR,
    payload: response.data,
  };
}
function errorSendInviteToFnfContributor(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}

export const fetchBeneficiaryProfiles = (servicerAccountId) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/beneficiary_profiles",
    onSuccess: setFetchBeneficiaryProfiles,
    callback: "",
    onFailure: errorFetchBeneficiaryProfiles,
    label: GET_BENEFICIARY_PROFILES,
  });
};

function setFetchBeneficiaryProfiles(data) {
  return {
    type: GET_BENEFICIARY_PROFILES,
    payload: data.data,
  };
}
function errorFetchBeneficiaryProfiles(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data,
    };
  }
}

export const getContributions = (servicerAccountId) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/contributions",
    onSuccess: setGetContributions,
    callback: "",
    onFailure: errorGetContributions,
    label: GET_CONTRIBUTIONS,
  });
};
function setGetContributions(response) {
  return {
    type: GET_CONTRIBUTIONS,
    payload: response.data,
  };
}
function errorGetContributions(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const setSelectedContributionIdToThank = (contributionId) => {
  return {
    type: SET_SELECTED_CONTRIBUTION_ID_TO_THANK,
    payload: contributionId,
  };
};
export const postThankYouToContributor = (
  servicerAccountId,
  contributionId,
  requestData
) => {
  return apiAction({
    url: `${config.apiBaseUrl}servicer_accounts/${servicerAccountId}/contributions/${contributionId}/thank_you`,
    method: "POST",
    data: requestData,
    onSuccess: setPostThankYouToContributor,
    callback: "",
    onFailure: errorPostThankYouToContributor,
    label: POST_THANK_YOU_TO_CONTRIBUTOR,
  });
};
function setPostThankYouToContributor(response) {
  return {
    type: POST_THANK_YOU_TO_CONTRIBUTOR,
    payload: response.data,
  };
}
function errorPostThankYouToContributor(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const submitContribution = (servicerAccountId, contributionId) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/contributions/" +
      contributionId +
      "/submit",
    method: "POST",
    data: "",
    onSuccess: setSubmitContribution,
    callback: "",
    onFailure: errorSubmitContribution,
    label: SUBMIT_CONTRIBUTION,
  });
};
function setSubmitContribution(response) {
  return {
    type: SUBMIT_CONTRIBUTION,
    payload: response,
  };
}
function errorSubmitContribution(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const getIndividualContribution = (
  servicerAccountId,
  contributionId
) => {
  return apiAction({
    url:
      config.apiBaseUrl +
      "servicer_accounts/" +
      servicerAccountId +
      "/contributions/" +
      contributionId,
    onSuccess: setGetIndividualContribution,
    callback: "",
    onFailure: errorGetIndividualContribution,
    label: GET_INDIVIDUAL_CONTRIBUTION,
  });
};
function setGetIndividualContribution(response) {
  return {
    type: GET_INDIVIDUAL_CONTRIBUTION,
    payload: response.data,
  };
}
function errorGetIndividualContribution(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}

export const fetchBeneficiaryProfileAvatars = () => {
  return apiAction({
    url: config.apiBaseUrl + "beneficiary_avatar_list",
    onSuccess: setFetchBeneficiaryProfileAvatars,
    callback: "",
    onFailure: errorFetchBeneficiaryProfileAvatars,
    label: GET_BENEFICIARY_PROFILE_AVATARS,
  });
};
function setFetchBeneficiaryProfileAvatars(response) {
  return {
    type: GET_BENEFICIARY_PROFILE_AVATARS,
    payload: response.data,
  };
}
function errorFetchBeneficiaryProfileAvatars(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const postTrackableEvents = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "trackable_events",
    method: "POST",
    data: requestData,
    onSuccess: setPostTrackableEvents,
    callback: "",
    onFailure: errorPostTrackableEvents,
    label: POST_TRACKABLE_EVENTS,
  });
};
function setPostTrackableEvents(response) {
  return {
    type: POST_TRACKABLE_EVENTS,
    payload: response,
  };
}
function errorPostTrackableEvents(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
export const postFiveTwoNinePlanAddress = (addresses) => {
  return apiAction({
    url: config.apiBaseUrl + "addresses",
    method: "POST",
    data: addresses,
    onSuccess: setPostFiveTwoNinePlanAddress,
    callback: "",
    onFailure: errorPostFiveTwoNinePlanAddress,
    label: POST_FIVETWONINE_ADDRESS,
  });
};
function setPostFiveTwoNinePlanAddress(response) {
  return {
    type: POST_FIVETWONINE_ADDRESS,
    payload: response,
  };
}
function errorPostFiveTwoNinePlanAddress(errors) {
  return {
    type: GET_ERRORS,
    payload: errors,
  };
}