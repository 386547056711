import React, { useState, useEffect } from 'react';

import { isSyncingCheck, sumOfApprovedTransactionAmounts } from "../match";
import { numberFormat } from '../../../common/js/jsFunctions';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { intercomTrackEvent } from '../../customerStatus';
import eventNames from "../../event-names-map";

const TrackedPayments = (props) => {
  const subText = false;
  const [showSubtext, setSubtext] = useState(subText);
  useEffect(() => { setSubtext(subText) }, [subText]);
  const servicerAccounts = props.servicerAccounts;
  const transactions = props.transactions;

  useEffect(() => {
    const isSyncing = isSyncingCheck(servicerAccounts, transactions);
    const transactionsAmounts = sumOfApprovedTransactionAmounts(transactions);
    if (!(isSyncing || !transactionsAmounts > 0)) {
      props.trackedPaymentsUpdateContainer()
    }
  }, [props])

  const isSyncing = isSyncingCheck(servicerAccounts, transactions);
  const transactionsAmounts = sumOfApprovedTransactionAmounts(transactions);

  return (
    <>
      {
        isSyncing || !transactionsAmounts > 0 ?
          <>
            {/* <h4> Approving Accounts  <img src={refresh} alt="icon_linkout" /></h4> */}
            {/* {props.trackedPaymentsUpdateContainer()} */}
            <div className={`p-4 Gborder-r05-responsive Gborder-yellow-6 Gb-yellow-1`}>
              <label className='G-badge-purple'>
                total verified student loan payments
              </label>
              <br />
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                $0.00
              </label>
              <p className='sans-medium'>
                This is the sum of verified payments for the current reporting period.
              </p>
              <div className='d-flex'>
                <div className='mr-2'>
                  <ExclamationTriangleFill size={20} className='Gt-yellow-6' />
                </div>
                <label className='mb-0'>
                  Enter your student loans
                  {' '}
                  <Link onClick={(e) => { intercomTrackEvent(eventNames.clickedAddAccountFromMatch); }} to="/advice-and-tools/loans/new?fromMatch=true" className='G-link text-decoration-none cancelLink'>here</Link>
                  {' '}
                  to display the overall total on the dashboard.
                </label>
              </div>
            </div>
          </>
          :
          <>
            <div className='p-4 Gborder-r05-responsive'>
              <label className='G-badge-purple'>
                total verified student loan payments
              </label>
              <br />
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                {transactionsAmounts > 0 ? numberFormat(transactionsAmounts) : '$0.00'}
              </label>
              <p className='sans-medium'>
                This is the sum of verified payments for the current reporting period.
              </p>
            </div>
            {/* <h3 className="g-secondary-color g-flex-centered g-normal-font font-weight-bold"> Total Verified Student Loan Payments
              <span className="tooltip-new" data-tooltip="This is the sum of verified payments for the current reporting period.">
                <span className="tooltip-text">i</span>
              </span>
            </h3>
            <span className="match-accounts__total g-flex-centered mt-4"> {transactionsAmounts > 0 ? numberFormat(transactionsAmounts) : '$0'} </span>
            {showSubtext ?
              <>
                <span>For plan year 2018 (Jan 1, 2018 - Nov 28, 2018)</span>
                <span>On average, we see you pay $100 per month</span>
              </>
              : null} */}
          </>
      }
    </>
  )
}

export default TrackedPayments;