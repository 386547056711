import { useState } from 'react';
import {formatFileSize} from '../Drag&DropBox/DragAndDropBox'
import  XCircle  from 'react-bootstrap-icons/dist/icons/x-circle';
import Modal from 'react-bootstrap/Modal';
import S from './FileList.module.css';
import Eye from 'react-bootstrap-icons/dist/icons/eye';
import Spinner from 'react-bootstrap/Spinner';

const FileList = ({fileList, removeFile, downloadMode = false}) => {
    const [show, setShow] = useState(false);
    const [imgData,setImgSrc] = useState(null);
    const [loader,setLoader] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (fileData) => {
        setImgSrc(fileData);
        setShow(true); 
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        },1000)
    }

    const checkFileFormat = (fileName) => {
        if(fileName) {
        let type = fileName.split('.');
        return type[type.length-1];
        }
    }
    return ( 
        <>
          <ul>
            {fileList.map((file,index) => {
                const MAX_FILENAME_LENGTH = 45;
                const lengthOfName = file.name.length < MAX_FILENAME_LENGTH ? file.name.length : MAX_FILENAME_LENGTH;

                const url = downloadMode ? setBlob(file): '';
                file.url = url;
                return (
                    <li key={index} className='p-2 my-2 d-flex justify-content-between Gborder-05 rounded-lg align-items-center'>
                    <div className='whiteCircularDiv'>
                    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 14.9789H12V16.9789H4V14.9789ZM4 10.9789H12V12.9789H4V10.9789ZM10 0.978943H2C0.9 0.978943 0 1.87894 0 2.97894V18.9789C0 20.0789 0.89 20.9789 1.99 20.9789H14C15.1 20.9789 16 20.0789 16 18.9789V6.97894L10 0.978943ZM14 18.9789H2V2.97894H9V7.97894H14V18.9789Z" fill="#2E2E2E"/>
                    </svg>
                    </div>
                    <div className='pr-3 w-75'><p className='mb-0' title={file.name}>{file.name.slice(0, lengthOfName) + (lengthOfName < MAX_FILENAME_LENGTH ? "" : "...") + (lengthOfName < MAX_FILENAME_LENGTH ? "" : file.name.slice(-3))}</p><span className='Gt-slate-4'>{formatFileSize(file.size)}</span></div>
                    <div className='p-3'>
                        {downloadMode ? <Eye role='button' onClick={() => handleShow(file)} size={24} />
                        : <XCircle onClick={(e) => removeFile(e,file)} role='button' size={20} /> }
                    </div>
                </li>
                )
            })}
           
        </ul>
        <Modal show={show} onHide={handleClose} dialogClassName={`${S.Modal90w}`} backdrop='static' centered>
            <Modal.Header className='border-0 p-1' closeButton>
            <Modal.Title>{imgData?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${S.imgContainer} p-0`}>
                {loader ? <div className='d-flex justify-content-center'> <Spinner animation="grow" /></div> : <>
                {'pdf' === checkFileFormat(imgData?.name) ? <embed src={imgData?.url} type="application/pdf" width="100%" height="600px" /> :
                <img src={imgData?.url} alt="" />      
                }</>}
            </Modal.Body>
        </Modal>
        </>
     );
}

const setBlob = (file) => {
    let blob = new Blob([file], {type:file.type});
     return window.URL.createObjectURL(blob);
}
 
export default FileList;