import React, { Component } from "react";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { DepositData } from './DirectDepositeData';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import './PayrollDeduction.css';
import { PayrollDeductionBreadcrumb } from "../CommonBreadcrumb/Breadcrumb.constant";


const PinIcon = () => {
  return (
    <div className="mb-4">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.25" y="0.25" width="59.5" height="59.5" rx="29.75" fill="white" />
        <path d="M30 20C33.5 20 36.25 22.75 36.25 26.25C36.25 29.875 32.625 35.25 30 38.625C27.375 35.25 23.75 29.875 23.75 26.25C23.75 22.75 26.5 20 30 20ZM30 17.5C25.125 17.5 21.25 21.375 21.25 26.25C21.25 32.75 30 42.5 30 42.5C30 42.5 38.75 32.75 38.75 26.25C38.75 21.375 34.875 17.5 30 17.5ZM29.125 32.5L35.25 26.25L33.5 24.5L29.125 29L27.125 27L25.375 28.75L29.125 32.5Z" fill="var(--icon-color)" />
        <rect x="0.25" y="0.25" width="59.5" height="59.5" rx="29.75" stroke="#D5D5D5" strokeWidth="0.5" />
      </svg>
    </div>
  )
}

class PayrollDeduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    }
  }

  componentDidMount() {
    document.title = "Vault | Payroll Deduction"
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container  my-5 ">
            <Breadcrumb list={PayrollDeductionBreadcrumb} />
            <div className="bg-white Gborder-1 rounded-lg p-4">
              <div className="G-grid11-2">
                <div >
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M38.125 19.375L19.375 38.125L21.875 40.625L40.625 21.875M23.75 20C22.7554 20 21.8016 20.3951 21.0983 21.0983C20.3951 21.8016 20 22.7554 20 23.75C20 24.7446 20.3951 25.6984 21.0983 26.4017C21.8016 27.1049 22.7554 27.5 23.75 27.5C24.7446 27.5 25.6984 27.1049 26.4017 26.4017C27.1049 25.6984 27.5 24.7446 27.5 23.75C27.5 22.7554 27.1049 21.8016 26.4017 21.0983C25.6984 20.3951 24.7446 20 23.75 20ZM36.25 32.5C35.2554 32.5 34.3016 32.8951 33.5983 33.5983C32.8951 34.3016 32.5 35.2554 32.5 36.25C32.5 37.2446 32.8951 38.1984 33.5983 38.9017C34.3016 39.6049 35.2554 40 36.25 40C37.2446 40 38.1984 39.6049 38.9017 38.9017C39.6049 38.1984 40 37.2446 40 36.25C40 35.2554 39.6049 34.3016 38.9017 33.5983C38.1984 32.8951 37.2446 32.5 36.25 32.5Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h4>529 Payroll Deductions</h4>
                  <p className="sans-medium mb-lg-5">
                    Setting up payroll deductions is the simplest way to consistently
                    contribute to your 529 account(s). Some plans offer online setup,
                    while others require a form to be filled out a sent to the payroll provider.
                  </p>
                  <h5 className="my-4">Where should I start</h5>
                  <PinIcon />
                  <span className="sans-semibold Gfs-125">State Plans</span>
                  <p className="sans-medium mt-2">
                    If your 529 is a direct-sold state plan, the links below will deliver you to the corresponding online signup or pdf forms.
                  </p>
                </div>
              </div>
              <div className="G-grid-row-6 mx-4 my-4">
                {DepositData.map((data) =>
                  <div key={data.state}>
                    <a href={data.link} className='Gt-orange-8 text-decoration-none cancelLink G-cursor-pointer' target="_blank" rel="noopener noreferrer">{data.state}</a>
                  </div>
                )}
              </div>
              <div className="G-grid11-2 mt-3">
                <div>
                  <PinIcon />
                  <span className="sans-semibold Gfs-125">Advisor Sold Plans</span>
                  <p className="sans-medium mt-2">
                    If you previously purchased a 529 plan through an advisor
                    (Fidelity, Vanguard, etc) you may be able to log into your account
                    with that advisor and complete the same payroll deduction
                    signup process described above.
                  </p>
                  <div className='py-3 Gb-blue-50 pl-3 rounded-lg sans-semibold w-100 d-flex pr-2'>
                    <div className="my-auto mr-3">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 9H11V7H13M13 17H11V11H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2Z" fill="#1890FF" />
                      </svg>
                    </div>
                    <div>
                      Don't have a 529 plan yet? Visit <a href="/dashboard/map" className="G-link text-decoration-none cancelLink G-cursor-pointer">Learn About 529 Plans</a> to begin your research and open a new 529 account.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PayrollDeduction;