import dashboardData from '../data/dashboardData.json'
import adviceAndToolsData from '../data/adviceAndToolsData.json'
import thirdTabData from '../data/thirdTabData.json'
import { hideHeadersLinks, subNavBarLinks } from './navBarFeatures'

export const headerSelector = () => {
  // selects which data to use for header
  let headerName = "";
  // if (localStorage.getItem('advisorEnabled') === 'true' || localStorage.getItem("newAdvisorEnabledPruAccount") === 'true') headerName = "advisor"
  // if (localStorage.getItem('savingsPlanEnabled') === 'true') headerName = "fiveTwoNine"
  // if (localStorage.getItem('hasTuitionPlanOnly') === 'true') headerName = "tuitionReimbursement"
  // if (localStorage.getItem('pruSavingsPlanEnabled') === 'true') headerName = "pruFiveTwoNine"
  // console.log("abc headerName",  headerName );

  // let hasAdvisor = localStorage.getItem('hasAdvisor');
  let hasPay = localStorage.getItem('hasPay');
  let hasMatch = localStorage.getItem('hasMatch');
  let hasSavingsPlan = localStorage.getItem('hasSavingsPlan');
  let hasTuitionPlan = localStorage.getItem('hasTuitionPlan');

  if (hasPay === 'true' && hasMatch === 'false' && hasSavingsPlan === 'false' && hasTuitionPlan === 'false') headerName = 'pay'
  else if (hasPay === 'false' && hasMatch === 'true' && hasSavingsPlan === 'false' && hasTuitionPlan === 'false') headerName = 'match'
  else if (hasPay === 'false' && hasMatch === 'false' && hasSavingsPlan === 'true' && hasTuitionPlan === 'false') headerName = 'fiveTwoNine'
  else if (hasPay === 'false' && hasMatch === 'false' && hasSavingsPlan === 'false' && hasTuitionPlan === 'true') headerName = 'tuition'
  else if (hasPay === 'false' && hasMatch === 'false' && hasSavingsPlan === 'false' && hasTuitionPlan === 'false') headerName = 'advisorOnly'

  return headerName
}

export const navbarData = () => {
  let headerName = headerSelector();

  if (headerName === 'fiveTwoNine' && localStorage.getItem('hasAdvisor') === 'true' && (localStorage.getItem('selectedService') === 'advisor' || (localStorage.getItem('selectedService') === 'null' && localStorage.getItem('advisorFirst') === 'true'))) {
    headerName += 'Advisor'
  }
  let headerData = []
  if (headerName !== 'advisorOnly') {
    headerData = dashboardData.filter(e => {
      return headerName === e.service
    })[0]?.data
    headerData = headerData ?? [];
    // headerData = dashboardData[0].data
  }
  let hasAdvisor = localStorage.getItem('hasAdvisor');

  let advisorData = [];

  if (hasAdvisor === 'true') {
    advisorData = adviceAndToolsData.filter(e => {
      return headerName === e.service
    })[0]?.data
    advisorData = advisorData ?? []
    // advisorData = adviceAndToolsData[0].data
  }

  let dataForThirdTab = []

  if (headerName === 'match') {
    dataForThirdTab = thirdTabData.filter(e => {
      return headerName === e.service
    })[0]?.data
  }

  let collectiveData = [...headerData, ...advisorData, ...dataForThirdTab]
  // const calculatedHeadersData = headerVariationsCalculator(collectiveData);
  const dataAfterFilter = hideHeadersLinks(collectiveData);
  return dataAfterFilter;
}

export const subHeaderSelector = (headerData, employer) => {
  // selects which subheader data to use corresponding to current route
  let headerName = headerSelector();
  let path = window.location.pathname.split("/")[1];

  if (headerName === 'advisorOnly') {
    path = 'advice-and-tools'
  }
  
  const subHeaderData = headerData.filter(e => {
    return path === e.id
  })[0]
  // console.log(subHeaderData)
  const data = subNavBarLinks(subHeaderData, employer);
  return data;
}