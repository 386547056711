import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  calculateWeightedAverage,
  totalProjectedInterestCalculate,
  weightedAverageDiff,
  totalProjectedIntDiff
} from '../../../refi-vendor';

import { eligibilityLoansJoins, currency_format } from '../../../eligibility-result';

import { payoffTimeInWords, splitUpperCase } from '../../../../../common/js/jsFunctions';
import { getEligibilityResults } from '../../../../../actions/fiveTwoNineActions';
import { fetchEmployer } from '../../../../../actions/employerActions';
import { trackEvent } from '../../../../../actions/advisorActions';

import eventNames from "../../../../event-names-map";
import { Col, Row, Table } from 'react-bootstrap';
import { DashCircle, PlusCircle } from 'react-bootstrap-icons';


class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeBreakdown: false,
      termsByCredit: {},
      termLength: "",
      creditScore: "",
      customerRate: 0,
      selectedTermFloor: "",
      selectedTermCeiling: "",
      rangeMinVal: 0,
      rangeMaxVal: 0,
      rangeValue: 0,
      rangeValueAsPercentage: 0,
      loans: [],
      selectedTermInMonths: "",
      calculatorResults: null,
      sliderVal: "",
      amortLoans: [],
      activeHideToggle: ""
    }
    this.timer = null;

  }
  componentDidMount() {
    const { fetchEmployer } = this.props;
    fetchEmployer();
  }
  componentWillUnmount() {
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.eligibilityResults !== this.props.eligibilityResults) {
      const eligibilityResults = eligibilityLoansJoins(nextProps.eligibilityResults);
      this.setState({
        eligibilityResults: eligibilityResults
      })
    }
    if (nextProps.sliderVal !== this.props.sliderVal) {
      this.setState({
        sliderVal: nextProps.sliderVal
      })
    }

  }
  handleChooseTerm(term) {
    this.props.chooseTerm(term);
    this.props.trackEvent({
      data: {
        event_key: eventNames.setRefinanceInterestRate,
        params: { 'term_length': term}
      }
    })
  }
  handeRangeValue(e) {
    this.props.setRangeValue(e)
    //this.getRange();
  }
  handelRangeTracker(e) {
    const value = e.target.value;
    const data = {
      "data": {
        "event_key": eventNames.setRefinanceInterestRate,
        "params": { rate: value / 100 }
      }
    }
    
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.props.trackEvent(data);
    }, 300)
    // this.props.trackEvent(data);
    
  }

  toggleBreakdown(e) {
    const { seeBreakdown } = this.state;
    const breakDown = seeBreakdown ? false : true;
    const rotate = breakDown === true ? 'rotate' : '';
    this.setState({
      seeBreakdown: breakDown,
      activeHideToggle: rotate
    })
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    const { seeBreakdown } = this.state;

    const {
      customer_array,
      alternatePlan,
      termsByCredit,
      termLength,
      selectedTermFloor,
      selectedTermCeiling,
      rangeMinVal,
      rangeMaxVal,
      rangeValue,
      rangeValueAsPercentage,
      calculatorResults,
      amortLoans,
      employer,
      isCreditScoreLessThan660,
    } = this.props;

    let allowOneOnOne = employer?.attributes?.allow_one_on_one;

    delete customer_array['loans'];

    let weightedAverageInt = 0;
    let totalProjectedInterest = 0;

    let projectedLoanPayOff = "";
    let initialMinimumPayment = 0;

    let payoffTimeImpact = "";
    let weightedAverageDiffImpact = "";
    let monthlyPaymentDiffImpact = "";
    let totalProjectedIntDiffImpact = "";
    let weightedAverageIntRounded = "";
    let showCalculatorValues = false;

    if (calculatorResults) {
      projectedLoanPayOff = calculatorResults.payoffTime;
      initialMinimumPayment = calculatorResults.initialMinimumPayment;

      totalProjectedInterest = totalProjectedInterestCalculate(calculatorResults, amortLoans);

    }
    if (amortLoans) {
      weightedAverageInt = calculateWeightedAverage(amortLoans);
      weightedAverageIntRounded = Math.round(weightedAverageInt * 10) / 10;
    }

    if (alternatePlan.result && calculatorResults) {
      const alternatePlanData = alternatePlan.result;
      const loanData = calculatorResults;

      payoffTimeImpact = alternatePlanData.payoffTime - loanData.payoffTime;
      weightedAverageDiffImpact = weightedAverageDiff(weightedAverageInt, this.props.loans_array)

      monthlyPaymentDiffImpact = Math.round(loanData.initialMinimumPayment - alternatePlanData.initialMinimumPayment);
      totalProjectedIntDiffImpact = totalProjectedIntDiff(totalProjectedInterest, alternatePlanData, this.props.loans_array);
      if (totalProjectedIntDiffImpact !== 0) showCalculatorValues = true
    }
    return (

      <>
        <div className='G-grid11-2 mt-3'>
          <div>
            <div className="pinkCircularDiv mb-3">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                <path d="M43.75 25C43.75 26.375 42.625 27.5 41.25 27.5C41.025 27.5 40.8125 27.475 40.6125 27.4125L36.1625 31.85C36.225 32.05 36.25 32.275 36.25 32.5C36.25 33.875 35.125 35 33.75 35C32.375 35 31.25 33.875 31.25 32.5C31.25 32.275 31.275 32.05 31.3375 31.85L28.15 28.6625C27.95 28.725 27.725 28.75 27.5 28.75C27.275 28.75 27.05 28.725 26.85 28.6625L21.1625 34.3625C21.225 34.5625 21.25 34.775 21.25 35C21.25 36.375 20.125 37.5 18.75 37.5C17.375 37.5 16.25 36.375 16.25 35C16.25 33.625 17.375 32.5 18.75 32.5C18.975 32.5 19.1875 32.525 19.3875 32.5875L25.0875 26.9C25.025 26.7 25 26.475 25 26.25C25 24.875 26.125 23.75 27.5 23.75C28.875 23.75 30 24.875 30 26.25C30 26.475 29.975 26.7 29.9125 26.9L33.1 30.0875C33.3 30.025 33.525 30 33.75 30C33.975 30 34.2 30.025 34.4 30.0875L38.8375 25.6375C38.775 25.4375 38.75 25.225 38.75 25C38.75 23.625 39.875 22.5 41.25 22.5C42.625 22.5 43.75 23.625 43.75 25Z" fill="var(--icon-color)" />
              </svg>
            </div>
            <h4>Loan Payoff Projection After Refinance</h4>
            <p className="sans-medium">
              Due to the <a href="https://vault.co/covid/" target="_blank" rel="noopener noreferrer" className='G-link text-decoration-none cancelLink sans-semibold'>CARES Act</a>, we suggest that if you’re interested in refinancing,
              you update your <a href='/advice-and-tools/dashboard' className='G-link text-decoration-none cancelLink sans-semibold'>federal loans</a> with the interest rates
              you had prior to administrative forbearance.{
                allowOneOnOne && <>&nbsp;For additional assistance, please reach out to us for a <a className='G-link text-decoration-none cancelLink sans-semibold' href="http://vault.co/ask-genius" target="_blank" rel="noopener noreferrer">1:1 counseling session</a>.</>
              }
            </p>
          </div>
        </div>
        <div className='G-grid11-2 mt-3'>
          <div>
            {isCreditScoreLessThan660 &&
              <div >
                <label className='Gt-slate-1 sans-medium'>
                  Results displayed below assume a credit score of at least 660
                </label>
              </div>
            }
            <label className='G-badge-purple mb-4'>Select Term</label>
            <div className="G-custom-select-term G-mb-15">
              {
                termsByCredit ?
                  Object.keys(termsByCredit).map((item, index) => {
                    let classActive = "";
                    if (termLength === item) {
                      classActive = 'active';
                    }
                    const floor = Math.abs(termsByCredit[item]['floor']);
                    const ceiling = Math.abs(termsByCredit[item]['ceiling']);

                    return (
                      <div key={index} onClick={(e) => this.handleChooseTerm(item)} className={'rounded-lg Gborder-05 p-2 d-flex flex-column ' + classActive}>
                        <label className='Gfs-125 mb-4'>{item} Term</label>
                        <label className='Gfs-075'>
                          INTEREST RANGE
                        </label>
                        <label className='Gfs-087 mb-0'>
                          {floor.toFixed(1)}% - {ceiling.toFixed(1)}%
                        </label>
                      </div>
                    )
                  })
                  : null
              }
            </div>

            <div className="my-5 position-relative">
              <div id="refinance-payoff-slider__bubble" className='G-slider-popup Gbottom-6 position-absolute'>
                <div className="p-3 py-4 ">
                  <Row>
                    <Col lg={6}>
                      <Row><Col className='text-center Gt-pink-8 Gfs-125 sans-semibold'>{rangeValueAsPercentage}%</Col></Row>
                      <Row><Col className='text-center'><label className='Gfs-087 '>
                        INTEREST RATE</label></Col></Row>
                    </Col>
                    <Col lg={6}>
                      <Row><Col className='text-center Gt-pink-8 Gfs-125 sans-semibold'> {
                        calculatorResults?.initialPayment && showCalculatorValues ?
                          currency_format(calculatorResults.initialPayment)
                          : "---"
                      }</Col></Row>
                      <Row><Col className='text-center'><label className='Gfs-087 '>
                        MONTHLY PAY
                      </label></Col></Row>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className=''>
                <input
                  className="G-pink-slider"
                  type="range"
                  id="refinance-payoff-slider__range-input" // this id is used in refinance to calculate position
                  min={rangeMinVal}
                  max={rangeMaxVal}
                  step="1"
                  value={rangeValue}
                  onInput={this.handeRangeValue.bind(this)}
                  onChange={this.handelRangeTracker.bind(this)}
                  data-test-id="pay-extra-widget-range-button"
                  data-thumbwidth="25"  // width of the circle
                />
              </div>
              <div className="align-content-center align-items-center d-flex justify-content-between mb-5 w-100 mb-5">
                <label className='Gfs-125 sans-semibold'>{selectedTermFloor}%</label>
                <label className='Gfs-125 sans-semibold'>{selectedTermCeiling}%</label>
              </div>
            </div>


            <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
              <div className='mr-3 my-auto'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#1890FF" />
                </svg>
              </div>
              <div>
                <p className='sans-medium mb-0'>
                  Pricing is based on fixed rate loan options assuming at least a bachelor’s degree
                </p>
              </div>
            </div>
          </div>

          <div className="Gborder-05 d-flex flex-column justify-content-between p-4 rounded-lg">
            <div className='mb-4'>
              <label className="G-badge-purple ">
                Projected Loan Payoff
              </label>
              <div className="">
                <label className="Gfs-125 Gt-pink-8">
                  {projectedLoanPayOff && showCalculatorValues ? payoffTimeInWords('%ys%ms', projectedLoanPayOff) : '---'}
                </label>  <br />
                {payoffTimeImpact ?
                  <label className={` ${payoffTimeImpact > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{showCalculatorValues && payoffTimeInWords('%ys%ms', Math.abs(payoffTimeImpact))} {showCalculatorValues && (payoffTimeImpact > 0 ? "faster!" : "slower")}</label>
                  : null}
              </div>
            </div>
            <div className='mb-4'>
              <label className="G-badge-purple ">
                Weighted Avg of interest
              </label>
              <div className="">
                <label className="Gfs-125 Gt-pink-8">
                  {weightedAverageIntRounded && showCalculatorValues ? weightedAverageIntRounded + '%' : '---'}
                </label> <br />
                {weightedAverageDiffImpact !== "0.0" &&
                  <label className={` ${weightedAverageDiffImpact > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{showCalculatorValues && weightedAverageDiffImpact + "%"} {showCalculatorValues && (weightedAverageDiffImpact > 0 ? "decrease" : "increase")}</label>
                }
              </div>
            </div>
            <div className='mb-4'>
              <label className="G-badge-purple ">
                Monthly min payment
              </label>
              <div className="">
                <label className="Gfs-125 Gt-pink-8">
                  {initialMinimumPayment && showCalculatorValues ? currency_format(initialMinimumPayment) : '---'}
                </label> <br />
                {monthlyPaymentDiffImpact ?
                  <label className={`${monthlyPaymentDiffImpact > 0 ? "Gt-yellow-6" : "Gt-green-6"} `}>{showCalculatorValues && currency_format(monthlyPaymentDiffImpact)} {showCalculatorValues && (monthlyPaymentDiffImpact > 0 ? "extra" : "saved")}</label>
                  : null}
              </div>
            </div>
            <div>
              <label className="G-badge-purple ">
                Total projected interest
              </label>
              <div className="">
                <label className="Gfs-125 Gt-pink-8">
                  {totalProjectedInterest && showCalculatorValues ? currency_format(totalProjectedInterest) : '---'}
                </label> <br />
                {totalProjectedIntDiffImpact ?
                  <label className={` ${totalProjectedIntDiffImpact > 0 ? "Gt-yellow-6" : "Gt-green-6"} `}>{showCalculatorValues && currency_format(Math.abs(totalProjectedIntDiffImpact))} {showCalculatorValues && (totalProjectedIntDiffImpact > 0 ? "extra" : "saved")}</label>
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="Gborder-05 rounded-lg p-4 mt-3">
          <div className="mt-2 d-flex G-cursor-pointer" onClick={this.toggleBreakdown.bind(this)}>
            <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
              See the plan breakdown
            </label>
            {
              seeBreakdown === false ?
                <div className="ml-auto my-auto G-cursor-pointer" >
                  <PlusCircle size={22} />
                </div> :
                <div className="ml-auto my-auto G-cursor-pointer">
                  <DashCircle size={22} />
                </div>
            }
          </div>
          {seeBreakdown ?
            <div>
              <div className="Gtable-div mt-4">
                <Table borderless className="Gborder-05 G-table-theme-grey">
                  <thead className="Gt-head">
                    <tr>
                      <th className="Gfs-075 p-3">Plan Type</th>
                      <th className="Gfs-075 p-3">Interest rate</th>
                      <th className="Gfs-075 p-3">Balance</th>
                      <th className="Gfs-075 p-3">Monthly</th>
                    </tr>
                  </thead>
                  <tbody className="Gt-body">
                    {
                      this.props.loans_array.length > 0 ? this.props.loans_array.map(function (data, i) {
                        const interestRate = data.interestRate;
                        const balance = currency_format((data.balance / 100).toFixed(1));
                        const monthlyPayment = currency_format(data.monthlyPayment / 100);
                        return (
                          <tr key={i} className={`${rangeValueAsPercentage > interestRate && "Gt-slate-1"}`}>
                            <td>
                              {splitUpperCase(data.loanType)}
                            </td>
                            <td>
                              {interestRate + "%"}
                            </td>
                            <td>
                              {balance}
                            </td>
                            <td>
                              {monthlyPayment}
                            </td>
                          </tr>
                        )
                      }) : null}
                  </tbody>
                </Table>
              </div>
              <div className='d-flex Gb-blue-50 my-3 rounded-lg p-3'>
                <div className='mr-3 my-auto'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#1890FF" />
                  </svg>
                </div>
                <div>
                  <p className='sans-medium mb-0'>
                    Your interest rates may differ if you are enrolled in auto-pay with your servicer.
                  </p>
                </div>
              </div>
            </div>
            : null}

        </div>

      </>
    )
  }
}

Terms.propTypes = {
  getEligibilityResults: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  eligibilityResults: state.five_two_nine.eligibilityResults,
  employer: state.employer_array.employerData,
  trackEventResponse: state.advisor.trackEventResponse,
  errors: state.errors
});
export default connect(mapStateToProps, { getEligibilityResults, fetchEmployer, trackEvent })(Terms);
