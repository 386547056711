import React, { Component } from 'react';
import moment from 'moment';
import { getMonthsForPeriod, plotTransactionData, plotProjectedDataset } from '../match-graph';
import S from './Match.module.css'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

class EstimatedMatchGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: this.props.customer
    }
  }
  componentDidMount() {
    this.timerID = setTimeout(() => {
      this.setupChart();
    }, 4000);
  }
  componentWillUnmount() {
    clearTimeout(this.timerID);
  };
  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer
      })
    }
  }

  lineAtIndex(matchAmount, calculatedTransactionsArray, projectionDataset) {
    let index = null;
    // Check if we have our goal reached on the transactions dataset. If null value, check on the projected
    // dataset.
    for (let i = 0; i < calculatedTransactionsArray.length; i++) {
      const datasetIndexValue = calculatedTransactionsArray[i] !== null ? calculatedTransactionsArray[i] : projectionDataset[i];
      if (datasetIndexValue >= matchAmount) {
        index = i;
        break;
      }
    }
    /*
     * NOTE: Disabling this block of code for the time being until product defines a more appropriate
     * message.
     *
     *  if (index) {
     *    if (calculatedTransactionsArray[index]) {
     *      component.setProjectedNotification(`Goal reached in ${calcMonthName(index)}. You are all set!`);
     *    } else if (projectionDataset[index]) {
     *      component.setProjectedNotification(`Projected goal to be reached in ${calcMonthName(index)} at current rate`);
     *    } else {
     *      component.setProjectedNotification(`You'll need to contribute more each month in order to reach your max contribution`);
     *    }
     *  }
     */
    return index;
  }
  getNextMonth(date, monthNumber) {
    const nextMonth = moment(date).add(monthNumber, 'months').format('MMM');
    return nextMonth;
  }
  loadDashboardLineSeriesChart(matchAmount, transactions, calculatedTransactionsArray, projectionDataset, startDates) {

    let indexOfStraightLine = this.lineAtIndex(matchAmount, calculatedTransactionsArray, projectionDataset)
    // console.log(indexOfStraightLine)
    let chartData = [];

    calculatedTransactionsArray.forEach((amt, index) => {
      const _obj = {
        'month': this.getNextMonth(startDates.matchInitialStartDate, index),
        'matchAmountLine': matchAmount
      };
      if (calculatedTransactionsArray[index]) {
        _obj['calculatedAmount'] = calculatedTransactionsArray[index]
      }
      if (projectionDataset[index]) {
        _obj['projectedAmount'] = projectionDataset[index]
      }

      chartData.push(_obj)
    })

    // console.log(chartData)

    const highestAmount = chartData[chartData.length - 1]['calculatedAmount'] ? chartData[chartData.length - 1]['calculatedAmount'] : chartData[chartData.length - 1]['projectedAmount']

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.addLicense("ch-custom-attribution");
    var chart = am4core.create("lineSeriesChartContainer", am4charts.XYChart);

    chart.data = chartData;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.renderer.grid.template.location = 0;
    // categoryAxis.renderer.ticks.template.disabled = true;
    // categoryAxis.renderer.line.opacity = 0.2;
    // categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = "month";
    categoryAxis.startLocation = 0.4;
    categoryAxis.endLocation = 0.6;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.line.opacity = 0;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = Math.ceil((highestAmount) / 100) * 100 + 500;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = '$#,###.##';

    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.categoryX = "month";
    lineSeries.dataFields.valueY = "calculatedAmount";
    lineSeries.tooltipText = "Total paid at month: ${valueY.value}";
    lineSeries.fillOpacity = 0.5;
    lineSeries.strokeWidth = 3;
    lineSeries.stroke = am4core.color('var(--icon-color)');
    lineSeries.fill = am4core.color('var(--icon-color)');
    lineSeries.tooltip.getFillFromObject = false;
    lineSeries.tooltip.background.fill = am4core.color("#000");
    lineSeries.tooltip.label.fill = am4core.color("#fff");



    var lineSeries1 = chart.series.push(new am4charts.LineSeries());
    lineSeries1.dataFields.categoryX = "month";
    lineSeries1.dataFields.valueY = "projectedAmount";
    lineSeries1.tooltipText = "Projected total paid at month: ${valueY.value}";
    lineSeries1.fillOpacity = 0.5;
    lineSeries1.strokeWidth = 3;
    lineSeries1.stroke = am4core.color("#fab421");
    lineSeries1.fill = am4core.color("#fab421");
    lineSeries1.tooltip.getFillFromObject = false;
    lineSeries1.tooltip.background.fill = am4core.color("#000"); //CEB1BE
    lineSeries1.tooltip.label.fill = am4core.color("#fff");


    var lineSeries2 = chart.series.push(new am4charts.LineSeries());
    lineSeries2.dataFields.categoryX = "month";
    lineSeries2.dataFields.valueY = "matchAmountLine";
    lineSeries2.fillOpacity = 0.5;
    lineSeries2.strokeWidth = 8;
    lineSeries2.stroke = am4core.color('var(--icon-color)');
    lineSeries2.fill = am4core.color("#00000000");

    if (indexOfStraightLine) {
      var range = categoryAxis.axisRanges.create();
      range.category = chartData[indexOfStraightLine].month;
      range.grid.stroke = am4core.color('var(--icon-color)');
      range.grid.strokeWidth = 2;
      range.grid.strokeOpacity = 1;
      range.locations.category = 0;
      range.label.disabled = true;
      range.grid.strokeDasharray = "3,3"
    }


    var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 6;
    bullet.circle.fill = am4core.color("#fff");
    bullet.stroke = am4core.color('var(--icon-color)');
    // bullet.fill = am4core.color("#ffbb00");
    bullet.circle.strokeWidth = 3;

    var bullet1 = lineSeries1.bullets.push(new am4charts.CircleBullet());
    bullet1.circle.radius = 6;
    bullet1.circle.fill = am4core.color("#fff");
    bullet1.stroke = am4core.color("#fab421");
    // bullet.fill = am4core.color("#ffbb00");
    bullet1.circle.strokeWidth = 3;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panX";
    chart.cursor.lineX.opacity = 0;
    chart.cursor.lineY.opacity = 0;
  }
  setupChart() {
    const { customer } = this.state;
    if (customer) {
      const startDates = {
        'matchInitialStartDate': customer.attributes.match_initial_start_date,
        'matchRecurringStartDate': customer.attributes.match_recurring_start_date
      }
      // this._handleCustomIntersectionDraw();

      const matchAmount = this.calcMatchAmount(customer)
      const transactions = this.props.transactionsForThisYear;
      const calculatedTransactionsArray = plotTransactionData(transactions, startDates);
      const projectionDataset = plotProjectedDataset(calculatedTransactionsArray);


      // console.log('matchAmount', matchAmount)
      // console.log('transactions', transactions)
      // console.log('calculatedTransactionsArray', calculatedTransactionsArray)
      // console.log('projectionDataset', projectionDataset)


      this.loadDashboardLineSeriesChart(matchAmount, transactions, calculatedTransactionsArray, projectionDataset, startDates);

      // this.chart = new Chart(this._getCanvasElement(), {
      //   type: 'line',
      //   data: {
      //     labels: this._buildLabels(startDates),
      //     beginsAtZero: true,
      //     datasets: [
      //       /**
      //        * First dataset is limit to reach (e.g 6000) for a 5% contrib of a 120,000 salary.
      //        */
      //       {
      //         data: Array.from({ length: 12 }).fill(matchAmount),
      //         borderWidth: 6,
      //         borderColor: '#a00067',
      //         fill: false,
      //         pointRadius: 0,
      //         hoverRadius: 0,
      //         showLine: true,
      //         borderCapStyle: 'round',
      //       },
      //       /**
      //        * this data set is for actual transactions line ploting.
      //        */
      //       {
      //         data: calculatedTransactionsArray,
      //         borderWidth: 3,
      //         borderColor: '#a00067',
      //         fill: true,
      //         backgroundColor: '#a00067',
      //         pointRadius: 4,
      //         hoverRadius: 6,
      //         pointBackgroundColor: '#fff',
      //         pointHoverBackgroundColor: '#fff',
      //         showLine: true,
      //         lineTension: .2,
      //         borderCapStyle: 'round',
      //       },
      //       /**
      //        * this data set will need to be a projection continuing where the real progression left off.
      //        */
      //       {
      //         data: projectionDataset,
      //         borderWidth: 2,
      //         borderColor: '#ff7843',
      //         borderDash: [6, 5],
      //         fill: true,
      //         backgroundColor: '#ff7843',
      //         pointRadius: 4,
      //         hoverRadius: 6,
      //         pointBackgroundColor: '#fff',
      //         pointHoverBackgroundColor: '#fff',
      //         showLine: true,
      //         lineTension: .2,
      //         borderCapStyle: 'round'
      //       }
      //     ],
      //     /**
      //     * custom attr for drawing line at intersection of datasets. Calculate at what number does the transaction
      //     * dataset reach the match target.
      //     */
      //     lineAtIndex() {
      //       let index = null;
      //       // Check if we have our goal reached on the transactions dataset. If null value, check on the projected
      //       // dataset.
      //       for (let i = 0; i < calculatedTransactionsArray.length; i++) {
      //         const datasetIndexValue = calculatedTransactionsArray[i] !== null ? calculatedTransactionsArray[i] : projectionDataset[i];
      //         if (datasetIndexValue >= matchAmount) {
      //           index = i;
      //           break;
      //         }
      //       }
      //       /*
      //        * NOTE: Disabling this block of code for the time being until product defines a more appropriate
      //        * message.
      //        *
      //        *  if (index) {
      //        *    if (calculatedTransactionsArray[index]) {
      //        *      component.setProjectedNotification(`Goal reached in ${calcMonthName(index)}. You are all set!`);
      //        *    } else if (projectionDataset[index]) {
      //        *      component.setProjectedNotification(`Projected goal to be reached in ${calcMonthName(index)} at current rate`);
      //        *    } else {
      //        *      component.setProjectedNotification(`You'll need to contribute more each month in order to reach your max contribution`);
      //        *    }
      //        *  }
      //        */
      //       return index;
      //     }
      //   },
      //   options: {
      //     legend: {
      //       display: false
      //     },
      //     tooltips: {
      //       displayColors: false,
      //       callbacks: {
      //         label: (tooltipItem) => {
      //           // Base dataset, or goal post dataset.
      //           if (tooltipItem.datasetIndex === 0) {
      //             return `Goal: $${tooltipItem.yLabel.toFixed(2)}`;
      //           }
      //           // dataset from transactions
      //           if (tooltipItem.datasetIndex === 1) {
      //             return `Total paid at month: $${tooltipItem.yLabel.toFixed(2)}`;
      //           }
      //           // last dataset, projection
      //           return `Projected total paid at month: $${tooltipItem.yLabel.toFixed(2)}`;
      //         },
      //         title: ([tooltipItem]) => {
      //           // goal graph line
      //           if (tooltipItem.datasetIndex === 0) {
      //             return '';
      //           }
      //           // title of label override
      //           switch (tooltipItem.xLabel) {
      //             case 'Jan':
      //               return 'January';
      //             case 'Feb':
      //               return 'February';
      //             case 'Mar':
      //               return 'March';
      //             case 'Apr':
      //               return 'April';
      //             case 'Jun':
      //               return 'June';
      //             case 'Jul':
      //               return 'July';
      //             case 'Aug':
      //               return 'August';
      //             case 'Sep':
      //               return 'September';
      //             case 'Oct':
      //               return 'October';
      //             case 'Nov':
      //               return 'November';
      //             case 'Dec':
      //               return 'December';
      //             default:
      //               return tooltipItem.xLabel;
      //           }
      //         }
      //       }
      //     },
      //     scales: {
      //       xAxes: [{
      //         gridLines: {
      //           // display: false
      //           borderDash: [6, 3]
      //         }
      //       }],
      //       yAxes: [{
      //         ticks: {
      //           // Calculate this number to add padding to the top of the graph
      //           max: matchAmount + 10000, // todo changed from 1k to 10k
      //           min: 0, // todo 0 
      //           // Format to currency Y column values
      //           callback: (value/*, index, values*/) => {
      //             return `$${Number(value).toLocaleString('en-US')}`;
      //           },
      //         }
      //       }]
      //     }
      //   },
      // });
    }
  }
  calcMatchAmount(customer) {
    const startDates = {
      'matchInitialStartDate': customer.attributes.match_initial_start_date,
      'matchRecurringStartDate': customer.attributes.match_recurring_start_date
    }
    const { numberOfMonthsInPeriod } = getMonthsForPeriod(startDates);
    const salaryForPeriod = (customer.attributes.salary_cents / 100 * numberOfMonthsInPeriod) / 12;

    return (Number(this.props.matchPercentage) / 100) * salaryForPeriod;
  }
  _getCanvasElement() {
    // return document.getElementById('estimated-match-graph__canvas').getContext('2d');
  }
  /**
   * Returns an array of strings with the abbreviated months we'll be using for the x cord labels
   *
   * @param {Object} matchInitialStartDate, matchRecurringStartDate
   * @return {Array}
   *
   */
  _buildLabels({ matchInitialStartDate, matchRecurringStartDate }) {
    if (matchInitialStartDate === "" || matchRecurringStartDate === "") {
      return this._defaultMonths();
    }

    const initialStartDate = moment(matchInitialStartDate);
    const recurringStartDate = moment(matchRecurringStartDate);
    // use match-graph

    // if the initial start date is for the curent year, then we start the month for that date, and finish a month
    // before the matchRecurringStartDate
    const labelArr = [];
    if (initialStartDate.year() === new Date().getFullYear() &&
      initialStartDate.isBefore(recurringStartDate)) {
      const rawMonthDiff = initialStartDate.diff(recurringStartDate, 'months', true);
      const diffInMonthsBetweenDates = Math.round(Math.abs(rawMonthDiff));

      labelArr.push(initialStartDate.format('MMM'));
      for (let i = 1; i < diffInMonthsBetweenDates; i++) {
        labelArr.push(initialStartDate.add(1, 'month').format('MMM'));
      }
    } else {
      // Use the recurringStart Date until the next year. We assume the schedule is for a full
      // year (12 months).
      labelArr.push(recurringStartDate.format('MMM'));
      for (let i = 1; i < 12; i++) {
        labelArr.push(recurringStartDate.add(1, 'month').format('MMM'));
      }
    }
    return labelArr;
  }
  // _handleCustomIntersectionDraw() {
  //   const originalLineDraw = Chart.controllers.line.prototype.draw;
  //   Chart.helpers.extend(Chart.controllers.line.prototype, {
  //     draw: function () {
  //       originalLineDraw.apply(this, arguments);

  //       const chart = this.chart;
  //       const ctx = chart.chart.ctx;

  //       const index = chart.data.lineAtIndex();
  //       if (index) {
  //         const xaxis = chart.scales['x-axis-0'];
  //         const yaxis = chart.scales['y-axis-0'];

  //         ctx.save();
  //         ctx.beginPath();
  //         ctx.moveTo(xaxis.getPixelForValue(undefined, index), yaxis.top);
  //         ctx.strokeStyle = '#a00067';
  //         ctx.setLineDash([5, 4]);
  //         ctx.lineWidth = 1;
  //         ctx.lineTo(xaxis.getPixelForValue(undefined, index), yaxis.bottom);
  //         ctx.stroke();
  //         ctx.restore();
  //       }
  //     }
  //   });
  // }
  render() {
    //console.log(this.props.customer)
    const servicerAccountsMatch = this.props.servicerAccounts.filter((servicerAccount) => servicerAccount.match_active === true)
    return (
      <>
        {/* <div className="estimated-match-graph__graph-container">
          <canvas id="estimated-match-graph__canvas">
          </canvas>
        </div> */}
        <div className={'rounded-lg p-3 Gfs-125 G-cursor-pointer sans-bold ' + S.overChartDiv + ' ' + (servicerAccountsMatch.length === 0 ? S.overChartDiv1 : '')}>
          <a href='/advice-and-tools/loans/new?fromMatch=true'
            className="G-link text-decoration-none cancelLink">ADD LOANS </a>
          &nbsp;
          <label className='mb-0 text-center'>TO SEE YOUR PROJECTIONS</label>
        </div>
        <div id='lineSeriesChartContainer' className='match-dashboard-line-series'>

        </div>
      </>
    )
  }
}
export default (EstimatedMatchGraph);