export const includeNoSpecialChar = "|not_regex:<|not_regex:>|not_regex:/|not_regex:=|not_regex:%|not_regex:&|not_regex:{|not_regex:}";
export const includeNoSpecialCharMessage = "No special characters allowed";

export const doesNotHaveSatisfactionDataForThisType = (currentType) => {
    const satisfactionData = JSON.parse(localStorage.getItem('satisfactionData'));
    if(!satisfactionData) return true;

    if (!!satisfactionData) {
        const data = satisfactionData.filter((item) => item.attributes.key === currentType);
        if(data.length) {
            return false;
        } else {
            return true;
        }
    }
}

export const userCanLookAtFeedbackModal = () => {
    const closed = localStorage.getItem('feedbackClosed');
    if (closed === null) return true;
    if (Number(closed) >= 2) {
        return false;
    } else {
        return true;
    }
}