import camelcase from 'camelcase';

export function convertCamelCaseCustomer(customerObj){
  let customerData = customerObj.attributes;
  let customer     = toCamelCustomer(customerData);
  let customerRename  = renameKeysCustomer(customer, [['extraPaymentCents', 'extraPayment'], ['salaryCents', 'salary']]);
  customerRename.lowBalanceFactor = Number(customerRename.lowBalanceFactor);
  customerRename.agiGrowthRate = Number(customerRename.agiGrowthRate);
  return customerRename;
}

export function toCamelCustomer(o) {
  var newO, origKey, newKey, value
  newO = {}
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      //console.log(origKey+"=="+camelcase(origKey)+"=="+o[origKey])
      newKey = camelcase(origKey);
      value = o[origKey]
      newO[newKey] = value
    }
  }
  return newO
}
export function renameKeysCustomer(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
    delete obj[item[0]];
  });
  return obj;
}
export function renameKeysLoans(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object'){
      Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
      delete obj[item[0]];
    }
  });
  return obj;
}
export function toCamelLoan(o) {
  var newO, origKey, newKey, value
  newO = {}
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = camelcase(origKey);
      value = o[origKey]
      newO[newKey] = value
    }
  }
  return newO
}