import Image1 from './images/Image1.png';
import Image2 from './images/Image2.png';
import Image3 from './images/Image3.png';
import Image4 from './images/Image4.png';
import Image5 from './images/Image5.png';
import Image6 from './images/Image6.png';
import Image7 from './images/Image7.png';
import Image8 from './images/Image8.png';
import Image9 from './images/Image9.png';
import Image10 from './images/Image10.png';
import Image11 from './images/Image11.png';
import Image12 from './images/Image12.png';
import Image13 from './images/Image13.png';

const imageData = [
    {
        id: 1,
        url: Image1,
        title: 'Tax Perks Galore',
        description: 'One of the most significant advantages of 529 plans is the tax benefits they provide. While contributions are not deductible on your federal income tax return, some states offer tax deductions or credits for contributions to their in-state 529 plans. Additionally, any earnings on the investments within the plan grow tax-free, and withdrawals used for qualified education expenses are also tax-free at the federal level.'
    },
    {
        id: 2,
        url: Image2,
        title: 'Flexibility of Use',
        description: "Life's full of surprises, right? 529 plans get it! You can switch beneficiaries or use the funds for various qualified expenses, like tuition, books, and certain room and board expenses at eligible educational institutions, such as colleges, universities, and vocational schools. As of 2019, 529 plans can also be used for K-12 tuition expenses, up to $10,000 per year per beneficiary."
    },
    {
        id: 3,
        url: Image3,
        title: 'High Contribution Limits',
        description: "Ready to save big? Most 529 plans have high contribution limits, often exceeding $300,000 per beneficiary. This allows families to save significant amounts for education expenses over time."
    },
    {
        id: 4,
        url: Image4,
        title: 'No Income Restrictions',
        description: 'Unlike some other education savings options, 529 plans do not have income restrictions. No need to stress about exclusivity – 529 plans welcome everyone! There are no income restrictions, so the more, the merrier.'
    },
    {
        id: 5,
        url: Image5,
        title: 'Control and Ownership',
        description: 'The account owner retains control over the 529 plan account, even after the beneficiary reaches college age. This provides the flexibility to change the beneficiary to another qualifying family member if needed.'
    },
    {
        id: 6,
        url: Image6,
        title: 'Easy Peasy Gifting',
        description: "Contributions to a 529 plan are considered gifts for tax purposes. As of 2021, individuals can contribute up to $15,000 annually per beneficiary without incurring gift tax. Additionally, it's possible to contribute a lump sum of up to $75,000 per beneficiary (or $150,000 for married couples) and elect to treat the contribution as if it were made over five years for gift tax purposes."
    },
    {
        id: 7,
        url: Image7,
        title: 'Performance Check',
        description: "While past performance is not a guarantee of future results, it's essential to evaluate how well the plan's investments have performed historically. Compare the plan's performance against relevant benchmarks and other plans."
    },
    {
        id: 8,
        url: Image8,
        title: 'Smart Investment Moves',
        description: "Don't know much about investing? No worries! 529 plans offer professionally managed investment options to help your money grow like a champ. These options may include age-based portfolios that automatically adjust the asset allocation based on the beneficiary's age, making it easier to manage the investments effectively."
    },
    {
        id: 9,
        url: Image9,
        title: 'Minimal Impact on Financial Aid',
        description: "While 529 plan assets are considered when calculating financial aid eligibility, they have a relatively low impact compared to other assets, such as those held in a parent's name."
    },
    {
        id: 10,
        url: Image10,
        title: 'Estate Planning Tool',
        description: "529 plans can be utilized as part of estate planning strategies, allowing account owners to remove assets from their taxable estate while still maintaining control and benefiting the designated beneficiary. As the account owner, you call the shots, even when the beneficiary grows up."
    },
    {
        id: 11,
        url: Image11,
        title: 'Tons of School Options',
        description: "Consider the reputation and stability of the plan's management team or the financial institution offering the plan. A well-established and reputable provider may give you more confidence in the plan's management."
    },
    {
        id: 12,
        url: Image12,
        title: 'Review Historical Performance',
        description: "Past performance doesn't guarantee future success, but it's still good to peek at how a plan has done. Compare the performance against relevant benchmarks and other plans."
    },
    {
        id: 13,
        url: Image13,
        title: 'Ease of Use',
        description: "From colleges to trade schools, 529 plans cover a wide range of eligible institutions. You've got options!"
    },
];

export default imageData;