import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  friendlyName,
  longName,
  currency_format
} from '../../../eligibility-result';
import {
  payoffTimeInWords,
  numberFormat,
  monthsToHumanize
} from '../../../../../common/js/jsFunctions';
import { calculatorTranslatorCustomer, convertHyphensCamelCaseCustomerCustom, convertHyphensCamelCaseLoansCustom } from '../../../../../common/js/jsCustomFunctions';
import {
  getLoans,
  getEligibilityResults,
} from "../../../../../actions/fiveTwoNineActions";
import { fetchCustomer } from "../../../../../actions/customerActions";
import { calculateAllLoans } from '../../../../../common/js/calculateAllLoans';
import {
  getLoanType
} from "../../../../../actions/advisorActions";
import { Table } from 'react-bootstrap';

class EligibilityLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const {
      fetchCustomer,
      getLoans,
      getEligibilityResults,
      getLoanType,
    } = this.props;
    fetchCustomer();
    getLoans();
    getEligibilityResults();
    getLoanType();
    this.timerID = setTimeout(() => {
      this.calculateIcrAndRepayeLoans();
    }, 2000);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let loans_array = [];
    let customer_array = {};
    let eligibilityResults = {};
    let loanTypeData = [];

    if (
      nextProps.loans !== prevState.loans &&
      Object.keys(nextProps.loans).length > 0
    ) {
      loans_array = convertHyphensCamelCaseLoansCustom(nextProps.loans);
    }
    if (
      nextProps.customer !== prevState.customer &&
      Object.keys(nextProps.customer).length > 0
    ) {
      let customerData = convertHyphensCamelCaseCustomerCustom(
        nextProps.customer
      );
      delete customerData["statusUrl"];
      delete customerData["trRequested"];
      delete customerData["anyTuition"];
      delete customerData["balanceSystemType"];

      customerData.agiGrowthRate = Number(customerData.agiGrowthRate);
      customer_array = calculatorTranslatorCustomer(customerData);
    }
    if (nextProps.eligibilityResults !== prevState.eligibilityResults) {
      eligibilityResults = nextProps.eligibilityResults;
    }
    if (nextProps.loanTypeData !== prevState.loanType) {
      loanTypeData = nextProps.loanTypeData;
    }
    if (
      loans_array.length > 0 &&
      Object.keys(customer_array).length > 0 &&
      Object.keys(eligibilityResults).length > 0 &&
      loanTypeData.length > 0
    ) {
      return {
        loans_array: loans_array,
        customer_array: customer_array,
        eligibilityResults: eligibilityResults,
        loanTypeData: loanTypeData
      };
    }
    return null;
  }

  calculateIcrAndRepayeLoans() {
    // Calculating loans for ICR and repaye seperately since the default calculated loans does it the wrong way
    const { loans_array, customer_array, eligibilityResults, loanTypeData } = this.state;
    const loans = calculateAllLoans(loans_array, customer_array, eligibilityResults, loanTypeData);

    const icrAndRepayeData = loans.filter((item) => {
      return item.planName === "icr" || item.planName === "save";
    })
    this.setState({
      icrAndRepayeData: icrAndRepayeData
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  render() {
    const { program } = this.props;
    const { icrAndRepayeData } = this.state;
    const considerMatch = program.considerMatch;
    const matchEnabled = program.matchEnabled;

    let programName = '';
    let programlongName = '';
    let existingLoansAdvisable = false;
    let resultInitialPayment = "";
    let resultWithMatchInitialPayment = "";
    let resultMaxPayment = "";
    let resultWithMatchMaxPayment = "";
    let resultPayoffTime = "";
    let resultWithMatchPayoffTime = "";

    let resultAccruedInterest = "";
    let resultWithMatchAccruedInterest = "";

    let resultTotalPaid = "";
    let resultWithMatchTotalPaid = "";

    let payoffDifference = "";
    let payoff = "";
    let payoffFaster = "";
    let interestSaved = "";
    let impactAmountDiff = "";

    if (Object.keys(program).length > 0 && program.result !== null) {
      let loansData = ""
      programName = friendlyName(program.name);
      programlongName = longName(program.name);
      existingLoansAdvisable = program.existingLoansAdvisable;

      if (program.name === 'Icr' || program.name === 'Save') {
        const icrOrRepayeData = icrAndRepayeData?.filter((item) => {
          return item.name === program.name
        })[0];
        loansData = icrOrRepayeData;

      } else {
        loansData = program;
      }

      resultInitialPayment = loansData?.result.initialPayment;

      resultWithMatchInitialPayment = loansData?.resultWithMatch.initialPayment;
      resultWithMatchPayoffTime = loansData?.resultWithMatch.payoffTime

      resultAccruedInterest = loansData?.result.accruedInterest;
      resultWithMatchAccruedInterest = loansData?.resultWithMatch.accruedInterest;

      resultTotalPaid = loansData?.result.totalPaid;
      resultWithMatchTotalPaid = loansData?.resultWithMatch.totalPaid;

      resultMaxPayment = loansData?.result.maxPayment;
      resultWithMatchMaxPayment = loansData?.resultWithMatch.maxPayment;

      resultPayoffTime = loansData?.result.payoffTime

      payoffDifference = loansData?.result.payoffTime - loansData?.resultWithMatch.payoffTime;
      payoff = monthsToHumanize(payoffDifference);
      payoffFaster = payoffDifference > 0 ? true : false;
      interestSaved = loansData?.result.accruedInterest - loansData?.resultWithMatch.accruedInterest;
      impactAmountDiff = loansData?.result.totalPaid - loansData?.resultWithMatch.totalPaid;
    }

    return (

      <>
        <div className="mt-4 Gtable-div">
          <Table borderless className="Gborder-05 G-table-theme-grey">
            <thead className="Gt-head">
              <tr>
                <th className="Gfs-075 p-3">Plan Type</th>
                <th className="Gfs-075 p-3">Monthly Payment</th>
                <th className="Gfs-075 p-3">Time Left to Pay</th>
                <th className="Gfs-075 p-3">Remaining interest</th>
                <th className="Gfs-075 p-3">Projected Pay</th>
              </tr>
            </thead>
            <tbody className="Gt-body">
              <tr>
                <td>{programlongName ? programlongName : programName}</td>
                <td>{considerMatch && matchEnabled ?
                  <span>{numberFormat(Math.round(resultWithMatchInitialPayment))} {resultWithMatchInitialPayment !== resultWithMatchMaxPayment ? '- ' + numberFormat(Math.round(resultWithMatchMaxPayment)) : ''}</span>
                  : programName === 'Existing' ?
                    <span>{currency_format(resultInitialPayment)} {resultInitialPayment !== resultMaxPayment ? '- ' + numberFormat(Math.round(resultMaxPayment)) : ''} {!existingLoansAdvisable ? '*' : ''}</span>
                    :
                    <span>{currency_format(resultInitialPayment)} {resultInitialPayment !== resultMaxPayment ? '- ' + numberFormat(Math.round(resultMaxPayment)) : ''}</span>
                }</td>
                <td>
                  {
                    considerMatch && matchEnabled ?
                      <>
                        <span>{payoffTimeInWords('%ys%ms', resultWithMatchPayoffTime)}</span>
                        {
                          payoff ?
                            <div className={`sublabel ${payoffFaster ? "color-success" : "color-warning"} `}>{payoff} {payoffFaster ? 'faster!' : 'slower'}</div>
                            : null
                        }
                      </>
                      :
                      <span>{payoffTimeInWords('%ys%ms', resultPayoffTime)}</span>
                  }
                </td>
                <td>
                  {considerMatch && matchEnabled ?
                    <>
                      <span>{currency_format(resultWithMatchAccruedInterest)}</span>
                      <div className={`sublabel ${interestSaved > 0 ? "color-success" : "color-warning"} `}>{currency_format(interestSaved)} {(interestSaved !== 0 && interestSaved > 0) ? 'saved!' : 'more'}</div>
                    </>
                    : <span>{currency_format(resultAccruedInterest)}</span>
                  }
                </td>
                <td>
                  {
                    considerMatch && matchEnabled ?
                      <>
                        <span>{currency_format(resultWithMatchTotalPaid)}</span>
                        <div className={`sublabel ${impactAmountDiff > 0 ? "color-success" : "color-warning"} `}>{currency_format(impactAmountDiff)} {(impactAmountDiff !== 0 && impactAmountDiff > 0) ? 'impact!' : 'more'}</div>
                      </>
                      : <span>{currency_format(resultTotalPaid)}</span>
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer_array.customerData,
    loans: state.five_two_nine.loans,
    eligibilityResults: state.five_two_nine.eligibilityResults,
    loanTypeData: state.advisor.loanTypeData
  };
};

const mapDispatchToProps = {

  fetchCustomer,
  getLoans,
  getEligibilityResults,
  getLoanType
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityLoan);