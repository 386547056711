const updateState = (props, type) => {
  let status = props.customerData.attributes.status;
  if (status !== null) {
    status = "/" + status.split('.').join("/");
  }

  const status_url = props.customerData.attributes.status_url;
  const state = props.customerData.attributes.state;
  const married = props.customerData.attributes.married;
  const agiCents = props.customerData.attributes.agi_cents;
  const canInvite = props.customerData.attributes.can_invite;
  const selectedService = props.customerData.attributes.selected_service;
  const anyFiveTwoNineTerms = props.customerData.attributes.any_fivetwonine_terms;
  const anyGeneralTerms = props.customerData.attributes.any_general_agreement;
  const anyEsignTerms = props.customerData.attributes.any_esign_disclosure;

  let anyGeneralAgreementObj = {};
  if (type === 'token') {
    anyGeneralAgreementObj = { anyGeneralAgreement: props.customerData.attributes.any_general_agreement }
  }


  const hasProfile = (state !== null && married !== null && agiCents !== null) ? true : false;

  const hasPay = props.customerData.attributes.has_contribution;
  const hasMatch = props.customerData.attributes.has_match;
  const hasAdvisor = props.customerData.attributes.has_advisor;
  const hasSavingsPlan = props.customerData.attributes.has_savings_plan;
  const hasTuitionPlan = props.customerData.attributes.has_tuition_plan;
  const partnerName = props.customerData.attributes.partner_name;

  const hasPayOrMatch = (hasPay === true || hasMatch === true) ? true : false;
  const hasAdvisorAndPayOrMatch = (hasAdvisor === true && hasPayOrMatch === true) ? true : false;

  const hasAdvisorOnly = (hasAdvisor === true && hasSavingsPlan === false) ? true : false;
  const hasSavingsPlanOnly = (hasSavingsPlan === true && hasAdvisor === false && hasTuitionPlan === false && hasPayOrMatch === false) ? true : false;
  const hasAdvisorAndSavingsPlan = (hasAdvisor === true && hasSavingsPlan === true && hasTuitionPlan === false) ? true : false;

  const advisorEnabled = (hasAdvisorOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'advisor') || hasAdvisorAndPayOrMatch === true) ? true : false;
  const savingsPlanEnabled = ((hasSavingsPlanOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'savings_plan')) && partnerName === 'Vault')
  const pruSavingsPlanEnabled = ((hasSavingsPlanOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'savings_plan')) && (!!partnerName.includes('Prudential')));

  const newAdvisorEnabledPruAccount = (hasAdvisorOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === null)) ? true : false; // for new accounts that have the selected-service as null
  const hasTuitionPlanOnly = (hasTuitionPlan === true && hasAdvisor === false && hasSavingsPlan === false) ? true : false;

  const showProfile = (hasProfile === true && savingsPlanEnabled === false) ? true : false;

  const numberOfPayActiveServicerAccounts = props.customerData.attributes.number_of_servicer_accounts;

  let showFiveToNineOrAdvisorSwitcher;
  if (type === 'login') {
    showFiveToNineOrAdvisorSwitcher = hasAdvisorAndSavingsPlan === true &&
      (!!partnerName.includes('Prudential')); // show/hide 529 Planning card in the intro/learn page, and dont show intro/learn page when 529 enabled
  }
  else if (type === 'register' || type === 'token') {
    showFiveToNineOrAdvisorSwitcher = hasAdvisorAndSavingsPlan === true && selectedService !== 'savings_plan' &&
      (!!partnerName.includes('Prudential')); // show/hide 529 Planning card in the intro/learn page, and dont show intro/learn page when 529 enabled
  }

  localStorage.setItem("currentCustomer", JSON.stringify(props.customerData.attributes));
  localStorage.setItem("customerId", props.customerData.id);
  localStorage.setItem("customerFullName", props.customerData.attributes.full_name);
  localStorage.setItem("employerName", props.customerData.attributes.employer_name);
  localStorage.setItem("hasAdvisor", hasAdvisor);
  localStorage.setItem("hasSavingsPlan", hasSavingsPlan);
  localStorage.setItem("hasTuitionPlan", hasTuitionPlan);
  localStorage.setItem("hasAdvisorOnly", hasAdvisorOnly);
  localStorage.setItem("hasSavingsPlanOnly", hasSavingsPlanOnly);
  localStorage.setItem("hasTuitionPlanOnly", hasTuitionPlanOnly);
  localStorage.setItem("hasAdvisorAndSavingsPlan", hasAdvisorAndSavingsPlan);
  localStorage.setItem("selectedService", selectedService);
  localStorage.setItem("advisorEnabled", advisorEnabled);
  localStorage.setItem("savingsPlanEnabled", savingsPlanEnabled);
  localStorage.setItem("pruSavingsPlanEnabled", pruSavingsPlanEnabled);
  localStorage.setItem("hasAdvisorAndPayOrMatch", hasAdvisorAndPayOrMatch);
  localStorage.setItem("hasPay", hasPay);
  localStorage.setItem("hasMatch", hasMatch);
  localStorage.setItem("showProfile", showProfile);
  localStorage.setItem("partnerName", partnerName);
  localStorage.setItem("canInvite", canInvite);
  localStorage.setItem("newAdvisorEnabledPruAccount", newAdvisorEnabledPruAccount);
  localStorage.setItem("hasProfile", hasProfile);
  localStorage.setItem("showFiveToNineOrAdvisorSwitcher", showFiveToNineOrAdvisorSwitcher);
  if (type === 'token') localStorage.setItem("userToken", props.match.params.token);

  return {
    customerData: props.customerData,
    status: status !== null ? status : "",
    statusUrl: status_url !== null ? status_url : "",
    hasProfile: hasProfile,
    hasAdvisor: hasAdvisor,
    hasSavingsPlan: hasSavingsPlan,
    hasTuitionPlan: hasTuitionPlan,
    hasAdvisorOnly: hasAdvisorOnly,
    hasSavingsPlanOnly: hasSavingsPlanOnly,
    hasTuitionPlanOnly: hasTuitionPlanOnly,
    hasAdvisorAndSavingsPlan: hasAdvisorAndSavingsPlan,
    selectedService: selectedService,
    advisorEnabled: advisorEnabled,
    savingsPlanEnabled: savingsPlanEnabled,
    pruSavingsPlanEnabled: pruSavingsPlanEnabled,
    hasMatch: hasMatch,
    hasPay: hasPay,
    partnerName: partnerName,
    showProfile: showProfile,
    numberOfPayActiveServicerAccounts: numberOfPayActiveServicerAccounts,
    userVerify: true,
    anyFiveTwoNineTerms: anyFiveTwoNineTerms,
    anyGeneralTerms: anyGeneralTerms,
    anyEsignTerms: anyEsignTerms,
    showFiveToNineOrAdvisorSwitcher: showFiveToNineOrAdvisorSwitcher,
    ...anyGeneralAgreementObj, // for type token only
  };

}

export default updateState;