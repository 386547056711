import React, { useEffect } from "react";
import Header from "../../Includes/Header/Header";
import matchFaqList from '../../../../src/helpers/matchFAQ'
import Footer from "../../Includes/Footer/Footer";
import styles from './Match.module.css'
import parse from 'html-react-parser';
import MatchFAQContent from "./MatchFAQContent";


const MatchFAQ = () => {
  useEffect(() => {
    document.title = "Vault | FAQ";
  }, [])

  return (
    <>
      <Header />
      <div className="G-page-background-color">
        <div className="G-page-main-container  my-5">
          <div className="p-4 bg-white Gborder-1 rounded-lg">
            <div className="mb-4">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                <path d="M30 17.5C23.1 17.5 17.5 23.1 17.5 30C17.5 36.9 23.1 42.5 30 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 30 17.5ZM31.25 38.75H28.75V36.25H31.25V38.75ZM33.8375 29.0625L32.7125 30.2125C31.8125 31.125 31.25 31.875 31.25 33.75H28.75V33.125C28.75 31.75 29.3125 30.5 30.2125 29.5875L31.7625 28.0125C32.225 27.5625 32.5 26.9375 32.5 26.25C32.5 24.875 31.375 23.75 30 23.75C28.625 23.75 27.5 24.875 27.5 26.25H25C25 23.4875 27.2375 21.25 30 21.25C32.7625 21.25 35 23.4875 35 26.25C35 27.35 34.55 28.35 33.8375 29.0625Z" fill="var(--icon-color)" />
              </svg>
            </div>
            <h4 className="mb-5">
              Match FAQ's
            </h4>
            {matchFaqList.map((ele) => {
              return (
                <div className={styles.matchFaqContainer}>
                  <MatchFAQContent title={ele.question} content={parse(ele.answer)} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


// class MatchFAQ extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     }
//   }
//   componentDidMount() {
//     document.title = "Vault | FAQ";
//   }
//   render() {
//     return (
//       <>
//         <Header {...this.props} />
//         <div className="G-page-background-color">
//           <div className="G-page-main-container  my-5">
//             {/* <Breadcrumb list={AdviceAndToolPayFasterBreadcrumb} /> */}
//             <div className="p-4 bg-white Gborder-1 rounded-lg">
//               <div className="mb-4">
//                 <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <rect width="60" height="60" rx="30" fill="#ECCCE0" />
//                   <path d="M30 17.5C23.1 17.5 17.5 23.1 17.5 30C17.5 36.9 23.1 42.5 30 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 30 17.5ZM31.25 38.75H28.75V36.25H31.25V38.75ZM33.8375 29.0625L32.7125 30.2125C31.8125 31.125 31.25 31.875 31.25 33.75H28.75V33.125C28.75 31.75 29.3125 30.5 30.2125 29.5875L31.7625 28.0125C32.225 27.5625 32.5 26.9375 32.5 26.25C32.5 24.875 31.375 23.75 30 23.75C28.625 23.75 27.5 24.875 27.5 26.25H25C25 23.4875 27.2375 21.25 30 21.25C32.7625 21.25 35 23.4875 35 26.25C35 27.35 34.55 28.35 33.8375 29.0625Z" fill="var(--icon-color)" />
//                 </svg>
//               </div>
//               <h4 className="mb-5">
//                 Match FAQ's
//               </h4>
//               {matchFaqList.map((ele) => {
//                 return (
//                   <div className={styles.matchFaqContainer}>
//                     <MatchFAQContent title={ele.question} content={parse(ele.answer)} />
//                   </div>
//                 )
//               })}
//             </div>
//           </div>
//         </div>



//         {/* <div className={`${styles.containerMatchBackground} mb-5`}>
//           <div className={styles.containerWidth}>
//             <div className={styles.headingWelcome}>
//               <h4 className={`${styles.welcomeText} ${styles.labelTextDarkTeal} g-min-width-20 mt-5 mb-5`}>Match FAQs</h4>
//             </div>
//             <Row className={`ml-0`}>
//               <Col xs={8} className={`${styles.columnContainer} ${styles.columnBorderRightBottom}`}>
//                 <div className={`${styles.containerPaleOrangeBackground} padding-16 pl-4`}>
//                   <h6 className={`g-font-size-1-25rem mt-2`}>
//                     Vault Match FAQ's
//                   </h6>
//                   <p className={`g-faq-match-list mt-4`}>
//                     {matchFaqList.map((ele) => {
//                       return (
//                         <Accordion title={ele.question} content={parse(ele.answer)} />
//                       )
//                     })}
//                   </p>
//                 </div>
//               </Col>
//               <Col className={`${styles.columnContainer} ${styles.columnBorderRightBottom} ${styles.heightRow200}`}>
//                 <div className={`${styles.containerPaleOrangeBackground} padding-16 pl-4`}>
//                   <MatchGetHelp />
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         </div> */}
//         <Footer />
//       </>
//     );
//   }
// }
export default MatchFAQ;
