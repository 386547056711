export const DepositData = [
    {
        state: "Alabama",
        link: "https://www.collegecounts529.com/pdf/form_payroll_deduction.pdf",
    },
    {
        state: "Alaska",
        link: "https://www.alaska529plan.com/content/dam/alaska529/forms/Direct_Deposit_Form.pdf",
    },
    {
        state: "Arizona",
        link: "https://az529.gov/wp-content/uploads/2021/08/direct-deposit-guide-new.pdf",
    },
    {
        state: "Arkansas",
        link: "https://cdn.unite529.com/jcdn/files/ARD/pdfs/payroll.pdf",
    },
    {
        state: "California",
        link: "https://www.scholarshare529.com/manage/#contributions-withdrawals",
    },
    {
        state: "Colorado",
        link: "https://cdn.unite529.com/jcdn/files/PDF/pdfs/colorado/payrDeduction.pdf",
    },
    {
        state: "Connecticut",
        link: "https://login.fidelity.com/ftgw/Fidelity/RtlCust/Login/Init?AuthRedUrl=https://digital.fidelity.com/ftgw/digital/transfer/",
    },
    {
        state: "Delaware",
        link: "https://www.fidelity.com/529-plans/delaware",
    },
    {
        state: "District",
        link: "https://cdn.unite529.com/jcdn/files/DCD/pdfs/payroll.pdf",
    },
    {
        state: "Florida",
        link: "http://www.itppv.com/documents/pdf/payroll/payroll-investment-plan-state-of-florida-employee-payroll-deduction-authorization-form.pdf",
    },
    {
        state: "Georgia",
        link: "https://www.path2college529.com/",
    },
    {
        state: "Hawaii",
        link: "https://www.hi529.com/hitpl/uii529enroll/gettingStarted.do",
    },
    {
        state: "Idaho",
        link: "https://cdn.unite529.com/jcdn/files/IDD/pdfs/payrdeduction.pdf",
    },
    {
        state: "Illinois",
        link: "https://www.brightstart.com/wp-content/uploads/2017/07/9753_Bright_Start_Direct_Payroll_Deduction_Form_0707.pdf",
    },
    {
        state: "Indiana",
        link: "https://cdn.unite529.com/jcdn/files/IND/pdfs/ee_procedures.pdf",
    },
    {
        state: "Iowa",
        link: "https://cdn.unite529.com/jcdn/files/IAD/pdfs/payrdeduction.pdf",
    },
    {
        state: "Kansas",
        link: "https://www.lqd.learningquest.com/lqdtpl/auth/ll.cs",
    },
    {
        state: "Kentucky",
        link: "https://cdn.unite529.com/jcdn/files/KYD/pdfs/payroll.pdf",
    },
    {
        state: "Louisiana",
        link: "https://www.startsaving.la.gov/Common/Docs/Payroll_Deduction.pdf",
    },
    {
        state: "Maine",
        link: "https://www.nextgenforme.com/wp-content/uploads/2016/11/Direct-Payroll-Deduction-Form.pdf",
    },
    {
        state: "Maryland",
        link: "https://maryland529.com/",
    },
    {
        state: "Massachusetts",
        link: "https://login.fidelity.com/ftgw/Fidelity/RtlCust/Login/Init?AuthRedUrl=https://digital.fidelity.com/ftgw/digital/transfer",
    },
    {
        state: "Michigan",
        link: "https://www.misaves.com/manage/#contributions-withdrawals",
    },
    {
        state: "Minnesota",
        link: "https://www.mnsaves.org/manage/#contributions-withdrawals",
    },
    {
        state: "Mississippi",
        link: "https://treasury.ms.gov/for-citizens/college-savings-mississippi/",
    },
    {
        state: "Missouri",
        link: "https://www.missourimost.org/motpl/auth/ll.cs",
    },
    {
        state: "Montana",
        link: "https://cdn.unite529.com/jcdn/files/MTD/pdfs/payrolldeduction.pdf",
    },
    {
        state: "Nebraska",
        link: "https://nest529.com/wp-content/uploads/2021/08/123692_NEST_Direct_Payroll_Direct_Deposit_Form_Fillable.pdf",
    },
    {
        state: "Nevada",
        link: "https://cdn.unite529.com/jcdn/files/SSGAv2/pdfs/payroll.pdf",
    },
    {
        state: "New Hampshire",
        link: "https://www.fidelity.com/bin-public/060_www_fidelity_com/documents/529_NH_fact-kit.pdf",
    },
    {
        state: "New Jersey",
        link: "https://www.njbest.com/content-common/miscellaneous/njbest/529NJ_FPD.pdf",
    },
    {
        state: "New Mexico",
        link: "https://cdn.unite529.com/jcdn/files/NMD/pdfs/payroll.pdf",
    },
    {
        state: "New York",
        link: "https://cdn.unite529.com/jcdn/files/NYD/pdfs/payrDeduction.pdf",
    },
    {
        state: "North Carolina",
        link: "https://www.cfnc.org/media/gddpip1w/c426.pdf",
    },
    {
        state: "North Dakota",
        link: "https://cdn.unite529.com/jcdn/files/NDD/pdfs/form_payroll.pdf",
    },
    {
        state: "Ohio",
        link: "https://www.collegeadvantage.com/docs/default-source/direct-plan-forms/payroll-deduction-form.pdf?sfvrsn=1d30af97_10",
    },
    {
        state: "Oklahoma",
        link: "https://www.ok4saving.org/manage/#contributions-withdrawals",
    },
    {
        state: "Oregon",
        link: "https://www.oregoncollegesavings.com/assets/docs/oregon-college-payroll-deduction-form.pdf",
    },
    {
        state: "Pennsylvania",
        link: "https://www.pa529.com/pdf/gsp/GSP-Payroll-Deduction-Form.pdf",
    },
    {
        state: "Rhode Island",
        link: "https://cdn.unite529.com/jcdn/files/RID/pdfs/payroll.pdf",
    },
    {
        state: "South Carolina",
        link: "https://futurescholar.com/media/1450/acp-ebt-form.pdf",
    },
    {
        state: "South Dakota",
        link: "https://www.collegeaccess529.com/assets/files/1b/collegeaccess529-payroll-deduction-form-for-employees.pdf",
    },
    {
        state: "Tennessee",
        link: "https://cdn.unite529.com/jcdn/files/PDF/pdfs/tndirect/aip.pdf",
    },
    {
        state: "Texas",
        link: "https://www.texascollegesavings.com/wp-content/uploads/2016/03/TCSPEmployerAuthorizationPayrollDeductionFIF.pdf",
    },
    {
        state: "Utah",
        link: "https://my529.org/wp-content/uploads/2016/12/205.pdf",
    },
    {
        state: "Vermont",
        link: "https://vheip.org/wp-content/uploads/2015/07/Payroll-Deduction-Form.pdf",
    },
    {
        state: "Virginia",
        link: "https://www.lcps.org/cms/lib/VA01000195/Centricity/Domain/74/VA529%20Payroll%20Deduction%20Form.pdf",
    },
    {
        state: "Washington",
        link: "https://wastate529.wa.gov/sites/default/files/2020-10/dreamahead-payroll-deduction-form.pdf",
    },
    {
        state: "West Virginia",
        link: "https://www.smart529.com/content/dam/smart529/en/documents/pdf/payroll.pdf",
    },
    {
        state: "Wisconsin",
        link: "https://www.edvest.com/manage/#contributions-withdrawals",
    },
    {
        state: "Wyoming",
        link: "https://cdn.unite529.com/jcdn/files/PDF/pdfs/colorado/payrDeduction.pdf",
    },
]
