import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table,
} from 'react-bootstrap';

import { fetchCustomer } from '../../../actions/customerActions';
import { getLoans, getEligibilityResults } from '../../../actions/fiveTwoNineActions';
import { monthsToHumanize, numberFormat, splitUpperCase, convertHyphensCamelCaseCustomer, convertHyphensCamelCaseLoans, calculateProgramResults, loanStatusMessage } from '../../../common/js/jsFunctions';
import { Pencil } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { fetchEmployer } from './../../../actions/employerActions';

class StudentLoan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            isExpanded: true,
            customer: {},
            loans: [],
            eligibilityResults: {},
            loans_array: [],
            customer_array: [],
            loansData: []
        }
    }

    componentDidMount() {
        const { fetchCustomer, getLoans, getEligibilityResults, fetchEmployer } = this.props;
        fetchCustomer();
        fetchEmployer();
        getLoans();
        getEligibilityResults();
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        const update = {}
        if (prevState.loans !== nextProps.loans) {
            let loans_array = convertHyphensCamelCaseLoans(nextProps.loans);
            update.loans_array = loans_array
        }

        if (prevState.eligibilityResults !== nextProps.eligibilityResults) {
            update.eligibilityResults = nextProps.eligibilityResults
        }
        return update;
    }
    componentDidUpdate(prevProps) {

        if ((this.props.customer !== prevProps.customer) && Object.keys(prevProps.customer).length !== 0) {
            let customer_array = convertHyphensCamelCaseCustomer(prevProps.customer);
            this.setState({
                customer_array: customer_array
            });
        }
    }
    handleExpand = () => {
        this.setState({
            isExpanded: !this.state.isExpanded,
        })
    }
    onRedirect = (e) => {
        const { dataProps } = this.props;
        dataProps.history.push({
            pathname: '/dashboard/loans'
        })
    }
    render() {
        const { loans_array, customer_array, eligibilityResults } = this.state;
        const { employer } = this.props;
        const allowOneOnOne = employer?.attributes?.allow_one_on_one;

        let data = [];
        if (loans_array.length > 0 && Object.keys(customer_array).length > 0 && Object.keys(eligibilityResults).length > 0) {

            let eligibilityResultsData = eligibilityResults.data;
            let eligibility_results = eligibilityResults;
            let eligibility_results_included = eligibility_results.included;


            for (var i = 0; i < loans_array.length; i++) {
                const checksum_identifier = loans_array[i].checksumIdentifier
                var item = eligibility_results_included.find(item => item.attributes.checksum_identifier === checksum_identifier);
                delete loans_array[i]['eligibility_result_id'];
                loans_array[i]['eligible'] = item.attributes.eligible;
                loans_array[i]['program'] = item.attributes.eligibility_result_id;
                loans_array[i]['advisable'] = false;
            }
            for (var j = 0; j < eligibilityResultsData.length; j++) {
                if (j === 0) {
                    let loanResults = calculateProgramResults(customer_array, loans_array, eligibilityResultsData[j]);
                    let loansData1 = loanResults.borrower.loans;
                    data = loansData1
                }
            }
        }

        return (
            <div className="mt-5 Gtable-div">
                <Table borderless className="Gborder-05 G-table-theme-grey">
                    <thead className="Gt-head">
                        <tr>
                            <th className="Gfs-075 p-3">Loan Type</th>
                            <th className="Gfs-075 p-3">Monthly Payment</th>
                            <th className="Gfs-075 p-3">Time Left to Pay</th>
                            <th className="Gfs-075 p-3">Remaining interest</th>
                            <th className="Gfs-075 p-3">Projected Pay</th>
                            <th className="Gfs-075 p-3">Status</th>
                            <th className="Gfs-075 p-3">Edit</th>
                        </tr>
                    </thead>
                    <tbody className="Gt-body">
                        {data.length > 0
                            ? data.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{splitUpperCase(row.type)} </td>
                                        <td >{numberFormat(row.initialPayment)}</td>
                                        <td>{monthsToHumanize(row.payoffTime)}</td>
                                        <td>{numberFormat(Math.abs(row.accruedInterest))}</td>
                                        <td>{numberFormat(Math.abs(row.totalPaid))}</td>
                                        <td>{loanStatusMessage(row.status, allowOneOnOne)}</td>
                                        <td >
                                            <Link to={{ pathname: '/welcome/loans/edit/' + row.id }}>
                                                <Pencil className="Gt-pink-8 G-cursor-pointer" />
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })
                            : (<tr><td colSpan={6}>You currently do not have any loans.</td></tr>)}
                    </tbody>
                </Table>
            </div>
        );
    }
}

StudentLoan.propTypes = {
    fetchCustomer: PropTypes.func.isRequired,
    getLoans: PropTypes.func.isRequired,
    getEligibilityResults: PropTypes.func.isRequired,
    fetchEmployer: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    customer: state.customer_array.customerData,
    loans: state.five_two_nine.loans,
    eligibilityResults: state.five_two_nine.eligibilityResults,
    employer: state.employer_array.employerData,
    errors: state.errors
});
export default connect(mapStateToProps, { fetchCustomer, fetchEmployer, getLoans, getEligibilityResults })(StudentLoan);
