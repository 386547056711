import PlusCircle from 'react-bootstrap-icons/dist/icons/plus-circle';
import DashCircle from 'react-bootstrap-icons/dist/icons/dash-circle';

const BasicDetailsAccordion = ({basicData}) => {
    return ( <>
    {basicData ? 
          <div className='rounded-lg Gborder-1 p-3'>
          <div className='d-flex justify-content-between align-items-baseline G-pointer' title='Toggle Expansion' aria-expanded="true"  data-toggle="collapse"  href="#collapseBasicInfo">
              <p className='Gfs-125 mb-0'>Basic Information</p>
              <div >
                <span role='button' className='chevron-circle-up Gfs-175'><PlusCircle size={20} /></span> <span role='button' className='chevron-circle-down Gfs-175'><DashCircle size={20}/></span>
              </div>
          </div>
          <div id="collapseBasicInfo" className='collapse show'>
              <div className='my-3'>
                <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>First Name</p>
                <p className='Gfs-125 sans-medium'>{basicData.first_name}</p>
              </div>
              <div className='my-3'>
                <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Last Name</p>
                <p className='Gfs-125 sans-medium'>{basicData.last_name}</p>
              </div>
              <div className='my-3'>
                <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Title</p>
                <p className='Gfs-125 sans-medium'>{basicData.title}</p>
              </div>
              <div className='my-3'>
                <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Department</p>
                <p className='Gfs-125 sans-medium'>{basicData.department}</p>
              </div>
              <div className='my-3'>
                <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Type of Education Expense</p>
                <p className='Gfs-125 sans-medium'>{basicData.education_expense}</p>
              </div>
              <div className='my-3'>
                <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Notes for Manager</p>
                <p className='Gfs-125 sans-medium'>{basicData.notes_manager ? basicData.notes_manager : '-'}</p>
              </div>

          </div>
        </div>
    : ''}
    </> );
}
 
export default BasicDetailsAccordion;