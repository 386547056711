import find from 'lodash/find'

import {
  toCamelLoan,
  renameKeysLoans
} from '../../common/js/jsCustomer';
import {
  payoffTimeInWords
} from '../../common/js/jsFunctions';

export function eligibilityLoansJoins(eligibilityResults) {

  const eligibilityResultsData = eligibilityResults.data;
  const eligibilityResultsIncluded = eligibilityResults.included;

  let eligibility = [];
  eligibilityResultsData.forEach((item, i) => {
    const advisorEligibilityProgramLoans = item.relationships.loans.data;

    let eligibleProgramsArray = []
    for (var index = 0; index < advisorEligibilityProgramLoans.length; index++) {
      const ids = advisorEligibilityProgramLoans[index]['id'];

      const newArray = eligibilityResultsIncluded.filter(function (value) {
        return value.id === ids;
      });
      let attributes = newArray[0]['attributes'];
      attributes['origination_balance_cents'] = attributes['origination_balance_cents'] ? attributes['origination_balance_cents'] : 0;
      delete attributes['checksum_identifier'];
      delete attributes['customer_id'];
      delete attributes['servicer_name'];
      delete attributes['repayment_program'];
      delete attributes['other_servicer_name'];
      delete attributes['data_source'];

      attributes = renameKeysLoans(attributes, [
        ['current_balance_cents', 'balance'],
        ['origination_balance_cents', 'originationBalance'],
        ['monthly_payment_cents', 'monthlyPayment'],
        ['eligibility_result_id', 'program'],
      ]);
      attributes = toCamelLoan(attributes);
      attributes['interestRate'] = Number(attributes['interestRate']);
      attributes['advisable'] = false;
      attributes['customer'] = null;

      eligibleProgramsArray.push(attributes)
    }

    let eligibilityJson = {
      'name': item.attributes.name,
      'eligible': item.attributes.eligible,
      'consolidating': item.attributes.consolidating,
      'loans': eligibleProgramsArray
    };
    eligibility.push(eligibilityJson);
  });
  return eligibility;
}
export function friendlyName(name) {

  let friendlyName = name;
  switch (name) {
    case 'Existing':
      friendlyName = 'Your Current Plan';
      break;
    case 'Ibr':
    case 'Repaye':
    case 'Save':
    case 'Paye':
    case 'Icr':
      friendlyName = name.toUpperCase();
      break;
    default:
      friendlyName = name.split(/(?=[A-Z])/).join(' ');
  }
  return friendlyName;
}
export function longName(name) {
  let longName = null;
  switch (name) {
    case 'Ibr':
      longName = 'Income Based Repayment';
      break;
    case 'Repaye':
      longName = 'Revised Pay As You Earn';
      break;
    case 'Paye':
      longName = 'Pay As You Earn';
      break;
    case 'Icr':
      longName = 'Income Contingent Repayment';
      break;
    case 'Save':
      longName = 'Saving on A Valuable Education';
      break;
    default:
  }
  return longName;
}
export function isIncomeBased(name) {

  switch (name) {
    case 'Ibr':
    case 'Repaye':
    case 'Save':
    case 'Paye':
    case 'Icr':
      return true;
    default:
      return false;
  }
}
export function isForgivenessEligible(name) {
  switch (name) {
    case 'Ibr':
    case 'Repaye':
    case 'Save':
    case 'Paye':
    case 'Icr':
      return true;
    default:
      return false;
  }
}
export function isVariable(name) {
  switch (name) {
    case 'Ibr':
    case 'Icr':
    case 'Repaye':
    case 'Save':
    case 'Paye':
    case 'ExtendedGraduated':
    case 'Graduated':
      return true;
    default:
      return false;
  }
}
export function pdfName(planName, consolidating) {
  const name = planName;
  const cons = consolidating ? '_with_Consolidation' : '';
  switch (name) {
    case 'Ibr':
    case 'Icr':
    case 'Repaye':
    case 'Save':
    case 'Paye':
      return `Vault_IDR${cons}_Walkthrough`;
    case 'ExtendedGraduated':
    case 'Extended':
    case 'Graduated':
    case 'Standard':
      return 'Vault_Consolidation_Walkthrough';
    default:
  }
}
export function showDownloadStep(pdfName, iscitizens) {
  return !iscitizens && pdfName;
}
export function pdfLink(pdfName) {
  return `https://slbappfiles.s3.amazonaws.com/guides/${pdfName}.pdf`;
}
export function interest_rate(rate, skipMultiply = false, toFixed = 2) {
  const multiplyBy = skipMultiply ? 1 : 100;
  const results = parseFloat(rate * multiplyBy);
  const decimalPlaces = toFixed ? toFixed : 2;
  return `${results.toFixed(decimalPlaces)}%`;
}
export function minimum_payment(calcData) {
  const initialPayment = Math.round(parseFloat(calcData.initialPayment));
  const maxPayment = Math.round(parseFloat(calcData.maxPayment));
  const fixedPayment = initialPayment === maxPayment;
  const maxPaymentStr = fixedPayment === false ? ' - $' + maxPayment.toString() : '';
  //console.log(initialPayment+"=="+maxPayment+"=="+fixedPayment)
  const data = '$' + initialPayment + maxPaymentStr
  return data;
}
export function currency_format(value, absoluteValue = false) {

  if (value !== "") {
    if (!isNaN(value)) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(Math.abs(value));
    } else {
      return "";
    }
  } else {
    return value
  }
}
export function humanized_name(name) {
  return name.split(/(?=[A-Z])/).join(' ');
}
export function payoff_time_in_words(plan) {
  if (plan) {
    if (plan.payoffTime && !plan.executionTimeout) {
      return payoffTimeInWords('%ys%ms', plan.payoffTime)
    } else if (plan.executionTimeout) {
      return 'never';
    } else {
      return '0yr 0m';
    }
  }
}
export function calcData(program, matchEnabled) {
  if (matchEnabled) {
    return program.resultWithMatch.borrower.loans
  } else {
    return program.result.borrower.loans;
  }
}
export function getConsolidatedLoan(program, matchEnabled) {
  const loans = calcData(program, matchEnabled);
  const consolidatedLoan = find(loans, ['type', 'DirectConsolidatedBySLGUnsubsidized'])
  return consolidatedLoan;
}
export function getNonConsolidatedLoans(program, matchEnabled) {
  const loans = calcData(program, matchEnabled);
  return loans.filter(function (loan) {
    return loan.type !== 'DirectConsolidatedBySLGUnsubsidized';
  });
}
export function getPrivateLoans(existingLoans) {
  return existingLoans.filter(function (loan) {
    return loan.loanType === 'Private';
  });
}
export function getFederalLoans(existingLoans) {
  return existingLoans.filter(function (loan) {
    return loan.loanType !== 'Private';
  });
}
export function getPaymentSchedule(program) {
  const results = _getPaymentSchedule(program.result)
  return results;
}
export function getMatchPaymentSchedule(program) {
  const results = _getPaymentSchedule(program.resultWithMatch)
  return results;
}
export function _getPaymentSchedule(calcData) {
  return (calcData.paymentTiers.reduce(function (tiers, tier) {
    tiers.push({ year: tier.date.getFullYear(), amount: tier.totalCurrentPayment });
    return tiers;
  }, []));
}
// snakelize a string formed in dash
export function _snakelize(key) {
  let separator = '-';
  let split = /(?=[A-Z])/;

  return key.split(split).join(separator).toLowerCase();
}
// camelize a string formed in dash
export function _camelize(key) {

  key = key.replace(/[-\s]+(.)?/g, function (match, ch) {
    return ch ? ch.toUpperCase() : '';
  });
  // Ensure 1st char is always lowercase
  return key.substr(0, 1).toUpperCase() + key.substr(1);
}
