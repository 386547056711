import { partners } from '../../common/js/constants';
/**
 * Get's partner name from domain
 *
 * @return {string} partnerName
 */
const getPartnerFromDomain = () => {
  // All domain to partner mappings -- If domain not present, will default to Vault
  const partnerMap = {
    'pru': partners.PRUDENTIAL
  };
  const domain = window.location.host;
  const match = domain.match(/\b(?:citizens|vault|pru|prudential)\b/);

  return match ? partnerMap[match[0]] || match[0] : 'vault';
};


export default getPartnerFromDomain;
