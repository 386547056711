/**
 * Customer is 'enrolled' in genius match if:
 * - ANY one servicer account isFinicityLink
 * - OR ANY servicer account has more than zero statements.
 * - OR we have more than zero transactions
 *
 * NOTE: We only work with `matchActive == true` servicer accounts.
 */
 export function  enrolledInMatch(servicerAccounts){

    if (servicerAccounts) {
      const matchActiveServicerAccounts = servicerAccounts.filter(function(value) {
        return value.match_active === true
      });
      const anyIsFinicityLinked = matchActiveServicerAccounts.some(sa => sa.is_finicity_linked);
      const anyHasStatement = matchActiveServicerAccounts.some(sa => sa.statements.length > 0);
      return anyIsFinicityLinked || anyHasStatement;
    }
    return false;
  }

  /**
   * Returns an array with 0 or 1 servicer account
   * @param {Object} employer
   * @param {Object[]} servicerAccounts
   * @returns {Object[]}
   */
export function getFinicityLinkableServicerAccount(employer, servicerAccounts) {
  
  if (employer && servicerAccounts) {
   
    if (!employer.attributes.finicityEnabled) {
      return [];
    }
    return servicerAccounts.filter(function(value) {
      return value.attributes.pay_active && value.servicer.attributes.pay_active &&  value.attributes.finicity_active && (value.attributes.user_pause_payments===true || value.attributes.admin_pause_payments===true) && !value.attributes.is_finicity_linked
    });
    //return servicerAccounts.toArray().filterBy('isFinicityLinkable', true);
  }
  return [];
}
export function fetchFinicityLinkableServicerAccount(employer, servicerAccounts) {
  if (employer && servicerAccounts) {
    if (employer.attributes!==undefined && !employer.attributes.finicity_enabled) {
      return [];
    }
    return servicerAccounts.filter(function(value) {
      return value.attributes.is_finicity_linked===true; 
    });
  }
  return [];
}
 export function fetchEnrolledInMatch(servicerAccounts){
  if (servicerAccounts) {
    const matchActiveServicerAccounts = servicerAccounts.filter(function(value) {
      return value.attributes.match_active === true
    });
    const anyIsFinicityLinked = matchActiveServicerAccounts.some(sa => sa.attributes.is_finicity_linked);
    const anyHasStatement = matchActiveServicerAccounts.some(sa => sa.relationships.statements.data.length > 0);
    return anyIsFinicityLinked || anyHasStatement;
  }
  return false;
}
