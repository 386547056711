export const translations = {
  "loginErrors": {
    "inactive": "It looks like your account is no longer active. Please contact us at genius@vault.co for any questions.",
    "expired_subscription": "It looks like your employer's subscription may have expired. Please contact us at genius@vault.co for any questions.",
    "locked": "Account locked for 2 hours.",
    "sso_enabled": "The direct login is not available. Please use the SSO login or contact us at genius@vault.co for any questions."
  }
}

export const FeedbackRouteName = {
  onboarding: "onboarding",
  loanManagement: "loan_management",
  lowerPayments: "lower_payments",
  refinancing: "refinancing",
  payoffFaster: "payoff_faster",
}

export const FeedbackModalData = [
  {
    name: FeedbackRouteName.onboarding,
    header: "How was your onboarding experience with Vault Advisor?",
    text: "Your feedback is important to us! Please take a moment to rate your onboarding experience with Vault Advisor. Your input helps us improve our services and support.",
  },
  {
    name: FeedbackRouteName.loanManagement,
    header: "How was your experience with importing the student loans?",
    text: "Your feedback is important to us! Please take a moment to rate your experience with importing your student loan data. Your input helps us improve our services and support.",
  },
  {
    name: FeedbackRouteName.lowerPayments,
    header:"How was your experience with our walkthrough on lowering payments?",
    text: "Your feedback is important to us! Please take a moment to rate your experience with the walkthrough on lowering your payments. Your input helps us improve our services and support."
  },
  {
    name: FeedbackRouteName.refinancing,
    header: "How was your experience with our student loan refinancing guide and process?",
    text: " Your feedback is important to us! Please take a moment to rate how helpful and satisfying you found our guide and process for refinancing your student loans. Your input helps us improve our services and support.",
  },
  {
    name: FeedbackRouteName.payoffFaster,
    header: "How was your experience with our debt payoff advice?",
    text: "Your feedback is important to us! Please take a moment to rate how helpful and satisfying you found our advice on paying off your debt faster. Your input helps us improve our services and support.",
  }
];


