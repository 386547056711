import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import { TuitionDetailSvg, requestFormField, requestFormStepperData, requestFormBreadcrumb } from './TuitionReimbursement.constant';
import { FormGroup } from 'react-bootstrap';
import TextInput from '../shared/TextInput/TextInput';
import ValidatorMessage from '../shared/ValidatorMessage/ValidatorMessage';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { customEventObject } from '../../utils/eventType';
import DragAndDropBox from './../shared/Drag&DropBox/DragAndDropBox';
import Stepper, { StepperStage } from '../shared/Stepper/Stepper';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import ReactTooltip from 'react-tooltip';
import FileList from '../shared/FileList/FileList';
import PreviousFileList from '../shared/PreviousFileList/PreviousFileList';
import InfoCircleFill from 'react-bootstrap-icons/dist/icons/info-circle-fill';
import { monthDateYearSlash, datePickerMonthDateYearSlash } from './../../utils/dateUtil';


class TuitionReimbursementTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptFiles: [{
        type: 'file'
      }],
      completionFile: [{
        type: 'file'
      }]
    }
  }

  componentDidMount() {
    document.title = 'Vault | Tuition Reimbursement Step 2'
  }
  handleAddTwo = (e) => {
    this.props.onClickHandleAddTwo(e)
  }
  handleBackOne = (e) => {
    this.props.onClickHandleBackOne(e)
  }
  handleChange = (e) => {
    this.props.handleInputChangeAmount(e);
    this.props.showValidationMessage(e)
  }
  addReceiptFile = (e) => {
    let receiptFiles = this.state.receiptFiles;
    receiptFiles.push({
      'type': 'file'
    })
    this.setState({
      receiptFiles: receiptFiles
    })
  }

  addCompletionFile = (e) => {
    let completionFile = this.state.completionFile;
    completionFile.push({
      'type': 'file'
    })
    this.setState({
      completionFile: completionFile
    })
  }
  removeCompletionFile = (e, index) => {
    const completionFile = this.state.completionFile;
    completionFile.splice(index, 1);
    this.setState({
      completionFile: completionFile
    })
  }

  handleDateChange(e, fieldName) {
    let evt = customEventObject(fieldName, e);
    this.props.handleInputChange(evt);

  }

  handleDateBlur(e) {
    let evt = customEventObject(e);
    this.props.showValidationMessage(evt);
  }

  render() {
    let { amount, previousReceiptImages, previousCompletionImages } = this.props.data;
    return (
      <>
        <Breadcrumb list={requestFormBreadcrumb()} />
        <Stepper options={requestFormStepperData(StepperStage.complete, StepperStage.active)} />
        <div className='p-4 bg-white my-5 rounded-lg Gborder-1'>
          <h2 className='Gfs-225 mb-5'>Tuition Reimbursement Request Form</h2>
          <div className='G-grid11-2'>
            <div>
              <h3 className='Gfs-175 Gt-slate-5'>Tuition Details</h3>
              <p className='sans-medium'>
                Your employer will use the following information to confirm the request is eligible for reimbursement. </p>
              <div className='Gb-yellow-2 rounded-lg px-4 py-5'>
                <TuitionDetailSvg />
              </div>
            </div>
            <div>
              <FormGroup className='mb-4'>
                <TextInput
                  label='School/Institution Name'
                  name={requestFormField.schoolName}
                  value={this.props.data.school_name}
                  placeholder='School Name'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.schoolName} fieldValue={this.props.data.school_name} message='School name is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Course Name'
                  name={requestFormField.courseName}
                  value={this.props.data.course_name}
                  placeholder='Course Name'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors[`${requestFormField.courseName}`]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.courseName} fieldValue={this.props.data.course_name} message='Course name is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <Form.Label htmlFor='descriptionInp'>Description*</Form.Label>
                <Form.Control
                  name={requestFormField.description}
                  rows={5}
                  value={this.props.data.description}
                  as="textarea"
                  id='descriptionInp'
                  placeholder='Enter a course description or summary here..'
                  onChange={(e) => this.props.handleInputChange(e)}
                  onBlur={(e) => this.props.showValidationMessage(e)}
                  aria-label="description"
                  className='placeholder-slate-50 Gbr-5' />
                <span className="customError">{this.props.customErrors[`${requestFormField.description}`]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.description} fieldValue={this.props.data.description} message='Description is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                {/* TODO: using inbuilt number component */}
                <Form.Label className='Gfs-1'>Reimbursement Amount*</Form.Label>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  value={amount}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  className="d-block p-2 w-100 Gborder-05 rounded-lg placeholder-slate-50"
                  id="amount"
                  name="amount"
                  placeholder="Type here"
                  onChange={(e) => this.props.handleInputChangeAmount(e)}
                  onBlur={(e) => this.props.showValidationMessage(e)}
                />
                <ValidatorMessage validator={this.props.validator} fieldName='amount' fieldValue={this.props.data.amount} message='Amount is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <Form.Label className='Gfs-1'>Course Start Date*</Form.Label>
                <DatePicker dateFormat={datePickerMonthDateYearSlash} className='w-100 Gborder-05 p-2 rounded-lg' placeholderText={monthDateYearSlash} selected={this.props.data.start_date ? new Date(this.props.data.start_date) : null} onChange={(date) => this.handleDateChange(date, requestFormField.startDate)} onBlur={(e) => this.handleDateBlur(requestFormField.startDate)} />
                <span className="customError">{this.props.customErrors[`${requestFormField.startDate}`]}</span>
                <ValidatorMessage validator={this.props.validator} fieldName={requestFormField.startDate} fieldValue={this.props.data.start_date} message='Start date is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <Form.Label className='Gfs-1'>Course Completion Date*</Form.Label>
                <DatePicker dateFormat={datePickerMonthDateYearSlash} className='w-100 Gborder-05 p-2 rounded-lg' placeholderText={monthDateYearSlash} selected={this.props.data.completion_date ? new Date(this.props.data.completion_date) : null} onChange={(date) => this.handleDateChange(date, requestFormField.completionDate)} onBlur={(e) => this.handleDateBlur(requestFormField.completionDate)} />
                <span className="customError">{this.props.customErrors[`${requestFormField.completionDate}`]}</span>
                <ValidatorMessage validator={this.props.validator} fieldName={requestFormField.completionDate} fieldValue={this.props.data.completion_date} message='Completion date is required' />
              </FormGroup>

              <FormGroup className=''>
                <Form.Label className='Gfs-1'>Receipts <InfoCircleFill data-for='receipts' data-tip size={20} /></Form.Label>
                <ReactTooltip id="receipts" place="top" effect="solid">
                  Attachments must be in PNG, PDF or JPEG format.
                </ReactTooltip>

                <DragAndDropBox setFileData={(file) => this.props.receiptFileChange(file)} ref={this.props.receiptFileRef} id='receiptFileUpload' />
                {previousReceiptImages?.length > 0 ?
                  <PreviousFileList fileList={previousReceiptImages} />
                  : ''}
                {
                  this.props.receiptFile?.length > 0 ?
                    (
                      <FileList fileList={this.props.receiptFile} removeFile={(e, file) => this.props.removeReceiptFile(e, file)} />
                    ) : ''
                }
              </FormGroup>

              <FormGroup className=''>
                <Form.Label className='Gfs-1'>Proof of Completion <InfoCircleFill data-for='proof' data-tip size={20} /></Form.Label>
                <ReactTooltip id="proof" place="top" effect="solid">
                  Attachments must be in PNG, PDF or JPEG format.
                </ReactTooltip>
                <DragAndDropBox setFileData={(file) => this.props.completionFileChange(file)} ref={this.props.completionFileRef} id='completionFileUpload' />
                {previousCompletionImages?.length > 0 ?
                  <PreviousFileList fileList={previousCompletionImages} />
                  : ''}
                {
                  this.props.completionFile?.length > 0 ?
                    (
                      <FileList fileList={this.props.completionFile} removeFile={(e, file) => this.props.removeCompletionFile(e, file)} />
                    ) : ''
                }
              </FormGroup>

              <div className='d-flex justify-content-end mt-2 mob-btn-div-column'>
                <button type="button" className="G-cancel-button mob-separate-v6 mx-3" onClick={this.handleBackOne.bind(this)}>Back</button>
                <button type="button" className="G-orange-button mob-separate-v6" onClick={this.handleAddTwo.bind(this)}>Continue</button>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TuitionReimbursementTwo;
