import React from 'react';
import { Link } from "react-router-dom";
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import { setTuitionDetailsAccordionData, setBasicDetailsAccordionData, setBankDetailsAccordionData, tuitionReimburseBreadcrumbData } from './TuitionReimbursementStatus.constant';
import BankDetailsAccordion from './BankDetailsAccordion';
import TuitionDetailsAccordion from './TuitionDetailsAccordion';
import BasicDetailsAccordion from './BasicDetailsAccordion';

const TuitionReimbursementStatus = ({ tuitionId, fetchIndServiceAccountData, addressData, servicerAccountData }) => {
  const editUrl = "/tuition-reimbursement/" + tuitionId;
  const redirectUrl = "/tuition-reimbursement-history";

  return (
    <>
      <Breadcrumb list={tuitionReimburseBreadcrumbData()} />
      <div className='p-4 Gborder-05 rounded-lg G-grid11-2 bg-white mb-5 mt-2'>
        <div >
          <div className='pinkCircularDiv mb-3'>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9875 0.5C6.0875 0.5 0.5 6.1 0.5 13C0.5 19.9 6.0875 25.5 12.9875 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 12.9875 0.5ZM13 23C7.475 23 3 18.525 3 13C3 7.475 7.475 3 13 3C18.525 3 23 7.475 23 13C23 18.525 18.525 23 13 23ZM13.625 6.75H11.75V14.25L18.3125 18.1875L19.25 16.65L13.625 13.3125V6.75Z" fill="var(--icon-color)" />
            </svg>

          </div>
          <div className='my-2'>
            <span className='G-yellow-badge'>Pending</span>
          </div>
          <h3 className='Gt-slate-5 Gfs-175 my-2'>Employer Approval Status</h3>
          <p className='sans-medium'> Your employer is reviewing your tuition reimbursement request. If you have any questions, please contact your Human Resources department representative. You will receive an email when your reimbursement request is approved.</p>
          <div className='mt-4 mob-btn-div-column d-flex flex-wrap'>
            <Link to={redirectUrl} className="G-cancel-button cancelLink text-decoration-none Gt-pink-8 mr-3 mob-separate-v6 my-2">Go Back</Link>
            <Link to={editUrl} className="G-orange-button mob-separate-v6 text-decoration-none text-white successLink my-2">Submit Additional Documents</Link>
          </div>
        </div>
        <div>
          <h3 className='Gt-slate-5 Gfs-15 mb-3'>Submitted Information</h3>
          <BasicDetailsAccordion basicData={setBasicDetailsAccordionData(fetchIndServiceAccountData.data)} />
          <TuitionDetailsAccordion tuitionData={setTuitionDetailsAccordionData(fetchIndServiceAccountData)} />
          <BankDetailsAccordion bankData={setBankDetailsAccordionData(servicerAccountData, addressData)} />
        </div>
      </div>
    </>
  )
}

export default TuitionReimbursementStatus;
