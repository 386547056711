import React, { Component } from 'react';
import { Link } from "react-router-dom";

import {
  minimum_payment,
  payoff_time_in_words,
  currency_format
} from '../../eligibility-result';
import { mailToSupport } from '../../mail-to-support';
import { monthsToHumanize } from '../../../../common/js/jsFunctions';
import { fetchEmployer } from "../../../../actions/employerActions";
import { connect } from 'react-redux';
import { fetchCustomer } from "../../../../actions/customerActions";
import { Table } from 'react-bootstrap';

class PayFasterPaperWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  handleFeedback = (e) => {
    this.props.focusOnFeedBack(e);
  }
  handleTrackOneOnOneEvent = (e) => {
    this.props.trackOneOnOneEvent(e);
  }
  componentDidMount() {
    const { fetchEmployer, fetchCustomer } = this.props;
    fetchEmployer();
    fetchCustomer();
  }

  render() {
    const { originalPlan, newPlan, matchEnabled, customer, extraAmountValue, customerData } = this.props;

    let compareTo = "";
    let comparedPlan = "";
    let payoff = "";
    let payoffFaster = "";
    let payoffMessage = "";
    let accruedInterest = 0;
    let interest = 0;
    let totalPaid = 0;
    let total = 0;

    const { employer } = this.props;
    const allowOneOnOne = employer?.attributes?.allow_one_on_one;

    if (Object.keys(originalPlan).length > 0 && originalPlan.result) {
      compareTo = originalPlan.result;
    }
    if (matchEnabled) {
      if (newPlan.resultWithMatchAndExtra) {
        comparedPlan = newPlan.resultWithMatchAndExtra;

        const payoffDifference = Number(compareTo.payoffTime) - Number(comparedPlan.payoffTime);
        payoffFaster = payoffDifference > 0;
        payoffMessage = monthsToHumanize(payoffDifference) || null
        payoff = payoffMessage;

        accruedInterest = comparedPlan.accruedInterest;
        interest = Number(compareTo.accruedInterest) - Number(comparedPlan.accruedInterest);

        totalPaid = comparedPlan.totalPaid;
        total = Number(compareTo.totalPaid) - Number(comparedPlan.totalPaid);

      }
    } else {
      if (newPlan.resultWithExtra) {
        comparedPlan = newPlan.resultWithExtra

        const payoffDifference = Number(compareTo.payoffTime) - Number(comparedPlan.payoffTime);
        payoffFaster = payoffDifference > 0;
        payoffMessage = monthsToHumanize(payoffDifference) || null
        payoff = payoffMessage;

        accruedInterest = comparedPlan.accruedInterest;
        interest = Number(compareTo.accruedInterest) - Number(comparedPlan.accruedInterest)

        totalPaid = comparedPlan.totalPaid;
        total = Number(compareTo.totalPaid) - Number(comparedPlan.totalPaid);
      }
    }
    let mailtosupport = "#";
    if (customer) {
      mailtosupport = mailToSupport("Support Request", customer);
    }
    return (
      <>

        <p className="sans-medium Gfs-1 my-3">Payoff loans faster with
          <span className="Gt-green-6"> {extraAmountValue >= 1 ? currency_format(newPlan.extraPayment) : currency_format(0)} extra </span>every month
          {
            customerData.attributes?.employer_match ?
              <>
                &nbsp; with a
                <span className="Gt-green-6">
                  &nbsp; ${customerData.attributes?.employer_match} contribution &nbsp;
                </span>
                from your employer.
              </>
              : '.'
          }
        </p>


        <div className="Gtable-div">
          <Table borderless className="Gborder-05 G-table-theme-grey">
            <thead className="Gt-head">
              <tr>
                <th className="Gfs-075 p-3">Plan Type</th>
                <th className="Gfs-075 p-3">Monthly Payment</th>
                <th className="Gfs-075 p-3">Time Left to Pay</th>
                <th className="Gfs-075 p-3">Remaining interest</th>
                <th className="Gfs-075 p-3">Projected Pay</th>
              </tr>
            </thead>
            <tbody className="Gt-body">
              <tr>
                <td>Current Plan</td>
                <td>
                  {compareTo ? currency_format(compareTo.initialPayment) : 0}
                </td>
                <td>
                  {compareTo ? monthsToHumanize(compareTo.payoffTime) : '0yr 0m'}
                </td>
                <td>
                  {
                    compareTo ?
                      compareTo.accruedInterest > 0 ?
                        currency_format(compareTo.accruedInterest)
                        : '$0'
                      : null
                  }
                </td>
                <td>
                  {
                    compareTo ?
                      currency_format(compareTo.totalPaid)
                      : null
                  }
                </td>
              </tr>
              <tr>
                <td>Selected Plan</td>
                <td>
                  {comparedPlan ? minimum_payment(comparedPlan) : ""}
                </td>
                <td>
                  {comparedPlan ? payoff_time_in_words(comparedPlan) : ""}
                  <br />
                  {
                    payoff ?
                      <span className={`Gfs-075 ${extraAmountValue !== 0 ? "Gt-green-6" : ""} ${payoffFaster ? "Gt-green-6" : "Gt-yellow-6"} `}>{payoff} {payoffFaster ? 'faster!' : 'slower'}</span>
                      : null
                  }
                </td>
                <td>
                  {accruedInterest > 0 ? currency_format(accruedInterest) : '$0'}
                  <br />
                  {
                    interest ?
                      <span className={`Gfs-075 ${extraAmountValue !== 0 ? "Gt-green-6" : ""} ${accruedInterest > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{currency_format(interest)} {interest > 0 ? 'saved!' : "more"}</span>
                      : null
                  }
                </td>
                <td>
                  {totalPaid > 0 ? currency_format(totalPaid) : '$0'}
                  <br />
                  {
                    total !== 0 ?
                      <span className={`Gfs-075 ${extraAmountValue !== 0 ? "Gt-green-6" : ""} ${total > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{currency_format(total)} {total > 0 ? 'saved!' : "more"}</span>
                      : null
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div>
          <p className='Gfs-125 mt-4'>Start Paying Extra </p>

          {extraAmountValue >= 1 &&
            <p className='Gfs-1 sans-medium'>Apply an extra ${newPlan.extraPayment} towards your student loans. You'll have the most impact if you designate that the extra payment should apply to your highest interest loan.</p>
          }

          <span className='Gfs-1 sans-medium'>
            Want to learn more about loan payoffs or have questions?
            <p className="sans-medium">
              Email us at
              {' '}
              <Link to='#' onClick={() => window.location = mailtosupport} className="sans-semibold Gt-pink-8 text-decoration-none cancelLink">{customer.advisorSupportEmail}</Link>.&nbsp;
              {
                allowOneOnOne &&
                <>
                  &nbsp; Have a question that requires a longer conversation? 1:1 advisory sessions are available by&nbsp;
                  <Link to="#" onClick={this.handleTrackOneOnOneEvent.bind(this)} className="sans-semibold Gt-pink-8 text-decoration-none cancelLink">scheduling time with a resident genius.</Link>
                </>
              }
            </p>
          </span>
        </div>

      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    employer: state.employer_array.employerData,
    customerData: state.customer_array.customerData
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployer: () => dispatch(fetchEmployer()),
    fetchCustomer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PayFasterPaperWork);