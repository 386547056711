import { responseMessage } from "../utils/alert";
import * as Sentry from "@sentry/browser";

export function onFinish(clearFinicityState) {
    window?.finicityConnect?.destroy();
    clearFinicityState();
}
export function onError(err) {
    if (err.reason !== 'timeout' && err.code !== 201) {
        this.flashMessages.flashError('There was an error syncing your account. Please try again later');
        Sentry.captureException(new Error(err));
    }
}
export function onSuccess() {
    responseMessage("success", "You have successfully linked your account!");
}