import {
  API_START,
  API_END,
  ACCESS_DENIED,
  API_ERROR,
  API_SUCCESS,
} from "../actions/types/api";
import { APIAlert, responseMessage } from "../utils/alert";
import * as Sentry from "@sentry/browser";

const initialState = {
  isLoadingData: false,
  stack: [],
  data: {},
};

let counter = 0;

export default function apiReducer(state = initialState, action) {
  switch (action.type) {
    case API_START:
      counter++;
      return {
        ...state,
        isLoadingData:
          action.payload !== "GET_INDIVIDUAL_CONTRIBUTION" &&  action.payload !== "NSLDS_ATTEMPT_ID" ? true : false,
      };
    case API_END:
      counter--;
      return {
        ...state,
        isLoadingData: counter === 0 ? false : true,
      };

    case ACCESS_DENIED:
      APIAlert(action.payload.url).error();
      return {
        ...state,
      };

    case API_ERROR:
      let { response, methodOfRequest, label } = action.payload;
      //let errMsg = message +" - "+ pathname;
      if (label !== "uploadFiles") {
        if (methodOfRequest === "POST" || methodOfRequest === "post") {
          if (response) {
            responseMessage("error", response.data.message);
            Sentry.captureException(response.data.message);
          }
        }
      }
      // APIAlert(errMsg).error();
      return {
        ...state,
      };

    case API_SUCCESS:
      APIAlert(action.payload).success();
      return {
        ...state,
      };

    case "API_START_LOADER":
      return {
        ...state,
        isLoadingData: true,
      };

    case "API_STOP_LOADER":
      return {
        ...state,
        isLoadingData: false,
      };

    default:
      return state;
  }
}
