import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { getServicerAccounts, saveBeneficiaryProfile, editBeneficiaryProfile, getBeneficiaryProfiles, fetchBeneficiaryProfileAvatars, postEditServicerAccounts } from "../../../actions/fiveTwoNineActions";
import { fetchEmployer } from "../../../actions/employerActions";
import Form from 'react-bootstrap/Form';
import ValidatorMessage from "../../shared/ValidatorMessage/ValidatorMessage";
import SimpleReactValidator from "simple-react-validator";
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { CreateFriendsFamilyBreadcrumb, EditFriendsFamilyBreadcrumb } from "../CommonBreadcrumb/Breadcrumb.constant";
class CreateFriendsFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedServicerAccountData: [],
      beneficiaryName: "",
      employerData: null,
      contribution: {
        welcome_message: "",
        avatar_id: null,
        educational_goals: "",
        picture_thumb_url: "",
        ugift_code: ""
      },
      servicerAccountId: this.props.match.params.servicerAccountId,
      beneficiaryProfilePresent: false,
      beneficiaryProfileAvatars: [],
      isEdit: false,
    };
    this.saveProfile = this.saveProfile.bind(this);
    this.timer = this.timerToDelayTheRouting.bind(this);
    this.changeProfileAvatar = this.changeProfileAvatar.bind(this);
    this.validator = new SimpleReactValidator({
      element: (message) => {
        return <p className="text-danger mb-0">{message}</p>;
      },
      autoForceUpdate: this,
    });
  }

  componentDidMount() {
    document.title = "Vault | Create/Edit Friends and Family Profile";
    this.props.getServicerAccounts();
    this.props.fetchBeneficiaryProfileAvatars();
    this.props.getBeneficiaryProfiles(this.state.servicerAccountId);

    if (this.props.beneficiaryProfile.length > 0) {
      this.setState({
        ...this.state,
        beneficiaryProfilePresent: true,
      });
    }
  }

  componentWillUpdate(prevProps) {
    if (Object.keys(this.props.servicerAccounts).length === 0) {
      this.props.getServicerAccounts();
      this.props.getBeneficiaryProfiles(this.state.servicerAccountId);
    }
    if (prevProps.beneficiaryProfileAvatars !== this.props.beneficiaryProfileAvatars) {
      this.setState({
        ...this.state,
        beneficiaryProfileAvatars: this.props.beneficiaryProfileAvatars,
      });
    }

    if (this.props.servicerAccounts !== prevProps.servicerAccounts && this.props?.servicerAccounts?.data?.length > 0) {
      const { servicerAccountId } = this.props.match.params;
      if (servicerAccountId) {
        const selectedServicerAccountData = this.props.servicerAccounts.data.filter((servicerAcc) => servicerAcc.id === servicerAccountId);
        // const beneficiaryName = selectedServicerAccountData[0]?.attributes?.beneficiary_name;
        const ugift_code = selectedServicerAccountData[0]?.attributes?.ugift_code;
        this.setState({
          ...this.state,
          selectedServicerAccountData: selectedServicerAccountData,
          contribution: {
            ...this.state.contribution,
            ugift_code
          },
        });
      }
    }

    if (this.props.beneficiaryProfile !== prevProps.beneficiaryProfile && this.props.beneficiaryProfile.length > 0) {
      const { beneficiaryProfile } = this.props;
      const { welcome_message, educational_goals, ugift_code, picture_thumb_url, beneficiary_name: beneficiaryName } = beneficiaryProfile[0]?.attributes;
      this.setState({
        ...this.state,
        beneficiaryProfilePresent: true,
        isEdit: true,
        beneficiaryName,
        contribution: {
          ...this.state.contribution,
          welcome_message,
          educational_goals,
          picture_thumb_url,
          ugift_code
        },
      });
    }

    const receivedCreateProfileResponse = this.props.postBeneficiaryProfileResponse !== prevProps.postBeneficiaryProfileResponse;
    const receivededitProfileResponse = this.props.editBeneficiaryProfileResponse !== prevProps.editBeneficiaryProfileResponse;

    if (receivedCreateProfileResponse) {
      this.props.history.push({ pathname: `/dashboard/manage-friends-and-family/confirm` })
    }
    if (receivededitProfileResponse) {
      this.props.history.push(`/dashboard/manage-friends-and-family/confirm`)
    }
  }

  timerToDelayTheRouting = () => {
    setTimeout(() => {
      localStorage.getItem('pruSavingsPlanEnabled') === "true" ? this.props.history.push({ pathname: `/pru-pay/${this.state.servicerAccountId}/profile-summary` }) : this.props.history.push({ pathname: `/pay/${this.state.servicerAccountId}/profile-summary` });
    }, 5000);
  };

  componentWillUnmount() {
    clearTimeout(this.timerToDelayTheRouting);
  }

  change(e) {
    this.setState({
      ...this.state,
      contribution: {
        ...this.state.contribution,
        [e.target.name]: e.target.type === "file" ? e.target.files[0] : e.target.value,
      },
    });
  }
  saveProfile() {
    if (this.validator.allValid()) {
      const { contribution } = this.state;
      const { servicerAccountId, beneficiaryProfilePresent } = this.state;
      const { beneficiaryProfile } = this.props;

      const fd = new FormData();

      fd.append("data[type]", "beneficiary_profiles");
      fd.append("data[attributes][welcome_message]", contribution.welcome_message);
      fd.append("data[attributes][educational_goals]", contribution.educational_goals);
      fd.append("data[attributes][avatar_id]", contribution.avatar_id);

      if (this.state.contribution.ugift_code !== this.state.selectedServicerAccountData[0]?.attributes?.ugift_code) {
        let requestData = {};
        requestData['data'] = this.state.selectedServicerAccountData[0];
        requestData['data'].attributes.ugift_code = this.state.contribution.ugift_code;
        this.props.postEditServicerAccounts(requestData);
      }

      if (beneficiaryProfilePresent) {
        const beneficiaryId = beneficiaryProfile[0]?.id;
        this.props.editBeneficiaryProfile(servicerAccountId, beneficiaryId, fd);
      } else {
        this.props.saveBeneficiaryProfile(servicerAccountId, fd);
      }
    } else {
      this.validator.showMessages();
    }
  }

  changeProfileAvatar = (id) => {
    this.setState({
      ...this.state,
      contribution: {
        ...this.state.contribution,
        avatar_id: id,
      },
    });
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false,
        },
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true,
        },
      });
    }
  }
  render() {
    const { selectedServicerAccountData, beneficiaryProfilePresent, contribution } = this.state;
    const { beneficiaryProfileAvatars } = this.props;
    const beneficiaryName = selectedServicerAccountData[0]?.attributes?.beneficiary_name || this.state.beneficiaryName;
    const { welcome_message, educational_goals, picture_thumb_url, ugift_code } = contribution;

    return (
      <>
        <Header {...this.props} />
        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={this.state.isEdit ? EditFriendsFamilyBreadcrumb : CreateFriendsFamilyBreadcrumb} />
            <div className="G-grid11-2-1200 bg-white Gborder-05 rounded-lg p-4 my-3">
              <div>
                <div className="pinkCircularDiv">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.1371 9.88759C27.6996 8.32509 26.9871 6.50009 26.1371 5.61259L22.3871 1.86259C20.8121 0.300094 18.9996 1.01259 18.1121 1.86259L15.9871 4.00009H12.7496C10.3746 4.00009 8.99961 5.25009 8.29961 6.68759L2.74961 12.2376V17.2376L1.86211 18.1126C0.299606 19.6876 1.01211 21.5001 1.86211 22.3876L5.61211 26.1376C6.28711 26.8126 7.01211 27.0626 7.69961 27.0626C8.58711 27.0626 9.39961 26.6251 9.88711 26.1376L13.2621 22.7501H17.7496C19.8746 22.7501 20.9496 21.4251 21.3371 20.1251C22.7496 19.7501 23.5246 18.6751 23.8371 17.6251C25.7746 17.1251 26.4996 15.2876 26.4996 14.0001V10.2501H25.7621L26.1371 9.88759ZM23.9996 14.0001C23.9996 14.5626 23.7621 15.2501 22.7496 15.2501H21.4996V16.5001C21.4996 17.0626 21.2621 17.7501 20.2496 17.7501H18.9996V19.0001C18.9996 19.5626 18.7621 20.2501 17.7496 20.2501H12.2371L8.13711 24.3501C7.74961 24.7126 7.52461 24.5001 7.38711 24.3626L3.64961 20.6376C3.28711 20.2501 3.49961 20.0251 3.63711 19.8876L5.24961 18.2626V13.2626L7.74961 10.7626V12.7501C7.74961 14.2626 8.74961 16.5001 11.4996 16.5001C14.2496 16.5001 15.2496 14.2626 15.2496 12.7501H23.9996V14.0001ZM24.3621 8.11259L22.2371 10.2501H12.7496V12.7501C12.7496 13.3126 12.5121 14.0001 11.4996 14.0001C10.4871 14.0001 10.2496 13.3126 10.2496 12.7501V9.00009C10.2496 8.42509 10.4621 6.50009 12.7496 6.50009H17.0121L19.8621 3.65009C20.2496 3.28759 20.4746 3.50009 20.6121 3.63759L24.3496 7.36259C24.7121 7.75009 24.4996 7.97509 24.3621 8.11259Z" fill="var(--icon-color)" />
                  </svg>

                </div>
                <h2 className="Gt-slate-5 sans-semibold Gfs-175 my-3">{this.state.isEdit ? 'Edit' : 'Create'} Friends and Family Profile</h2>
                <p className="sans-medium">Your friends and family profile can be emailed to anyone you’d like to invite to your existing 529 plans. Fill in the fields below and we’ll take care of the rest!</p>
              </div>
              <div>
                <h3 className="Gfs-125 sans-semibold Gt-pink-8">{`${beneficiaryName ? beneficiaryName.split(" ")[0] + "'s" : ""} 529 Friends and Family Page`}</h3>
                <Form.Group>
                  <Form.Label>Welcome Message</Form.Label>
                  <Form.Control as="textarea" name="welcome_message" aria-label="With textarea" value={welcome_message} onChange={(e) => this.change(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='welcome_message' fieldValue={welcome_message} message='Welcome Message is required' />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Educational Goals</Form.Label>
                  <Form.Control type="text" name="educational_goals" value={educational_goals} onChange={(e) => this.change(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='educational_goals' fieldValue={educational_goals} message='Educational Goals is required' />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0">UGIFT Code</Form.Label>
                  <Form.Text className="text-muted sans-medium mb-2">
                    Required for Friends & Family contributions: A Ugift code allows friends and family to
                    contribute to a specific beneficiary's 529 account. Account owners are given a unique
                    Ugift code for each beneficiary that can be shared with others. To find your beneficiary's
                    Ugift code, log into your 529 account and enable the Ugift feature within it to get your code.
                  </Form.Text>
                  <Form.Control type="text" name="ugift_code" value={ugift_code} onChange={(e) => this.change(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='ugift_code' fieldValue={ugift_code} message='Ugift code is required' />
                </Form.Group>

                <Form.Group>
                  <Form.Label>{beneficiaryProfilePresent ? `Change` : `Choose`} Avatar:</Form.Label>
                </Form.Group>

                {beneficiaryProfilePresent && (
                  <div className="">

                    <div className="">
                      <div className="">
                        {picture_thumb_url ? <img src={picture_thumb_url} alt="" style={{ height: "100px", width: "100px", borderRadius: "50%" }} /> : "No Avatar Selected"}
                      </div>
                    </div>
                  </div>
                )}
                {
                  beneficiaryProfileAvatars.length > 0 && (
                    <div className="">

                      <div className="G-avatar-container">
                        {
                          beneficiaryProfileAvatars.map(({ id, attributes: { url: imageUrl } }) => {
                            return (
                              <div className={this.state.contribution.avatar_id === id ? 'G-avatar selected' : 'G-avatar'} key={id} onClick={() => this.changeProfileAvatar(id)} >
                                <img src={imageUrl} alt="icon" />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <a className='Gt-pink-8 text-decoration-none cancelLink G-cursor-pointer' href="/dashboard/manage-friends-and-family">
                <button className="G-cancel-button mx-3 mob-separate-v6">
                  Cancel
                </button>
              </a>
              <button className="G-orange-button mob-separate-v6" onClick={() => this.saveProfile()}>
                Confirm and Save
              </button>
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

CreateFriendsFamily.propTypes = {
  getServicerAccounts: PropTypes.func.isRequired,
  saveBeneficiaryProfile: PropTypes.func.isRequired,
  editBeneficiaryProfile: PropTypes.func.isRequired,
  getBeneficiaryProfiles: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  fetchBeneficiaryProfileAvatars: PropTypes.func.isRequired,
  postEditServicerAccounts: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  servicerAccounts: state.five_two_nine.getServicerAccountsData,
  beneficiaryProfile: state.five_two_nine?.friendsAndFamilyData?.beneficiaryProfile,
  postBeneficiaryProfileResponse: state.five_two_nine?.friendsAndFamilyData?.postBeneficiaryProfileResponse,
  editBeneficiaryProfileResponse: state.five_two_nine?.friendsAndFamilyData?.editBeneficiaryProfileResponse,
  beneficiaryProfileAvatars: state.five_two_nine?.beneficiaryProfileAvatars,
  employerData: state.employer_array.employerData,
  errors: state.errors,
});

export default connect(mapStateToProps,
  {
    getServicerAccounts,
    saveBeneficiaryProfile,
    editBeneficiaryProfile,
    getBeneficiaryProfiles,
    fetchEmployer,
    fetchBeneficiaryProfileAvatars,
    postEditServicerAccounts
  })(
    CreateFriendsFamily
  );
