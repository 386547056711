import React from 'react'
import { useState } from 'react'
import { DashCircle, PlusCircle } from 'react-bootstrap-icons'

const MatchFAQContent = ({ title, content }) => {
    const [show, setShow] = useState(false)
    return (
        <div className="my-3 p-4 Gborder-05 rounded-lg">
            <div className="mt-2 d-flex G-cursor-pointer" onClick={() => setShow(!show)}>
                <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer mr-3">
                    {title}
                </label>
                <div className="ml-auto my-auto">
                    {
                        show ?
                            <div className="ml-auto my-auto G-cursor-pointer">
                                <DashCircle size={22} />
                            </div>
                            :
                            <div className="ml-auto my-auto G-cursor-pointer" >
                                <PlusCircle size={22} />
                            </div>
                    }
                </div>
            </div>
            {show &&
                <div>
                    <p className='sans-medium pt-4'>
                        {content}
                    </p>
                </div>
            }
        </div>
    )
}

export default MatchFAQContent