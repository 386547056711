import React, { useEffect, useState } from "react";

export default function Timer({ seconds = 30, callback }) {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          if (callback) callback();
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  return (
    <>
      {`${Math.floor(time / 60)}`.padStart(2, 0)}:
      {`${time % 60}`.padStart(2, 0)}
    </>
  );
}
