import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

import apiMiddleware from "./utils/apiMiddleware";

const initialState = {};

const middleware = [thunk, apiMiddleware];

//.. Connected with redux dev-tool and thunk middleware
const store = createStore(
	rootReducer,
	initialState, 
	composeWithDevTools(applyMiddleware(...middleware)))

export default store;