import React, { Component } from 'react';
import { connect } from 'react-redux'
import SimpleReactValidator from "simple-react-validator";
import S from './ConfirmEmail.module.css'
import { confirmSamlEmail, resendSamlEmail } from '../../actions/authenticationActions';

import Check from "../../common/img/check@3x.png";
import VaultLogo from '../shared/VaultLogo/VaultLogo';
import queryString from 'query-string'
import ValidatorMessage from '../shared/ValidatorMessage/ValidatorMessage';

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSent: false,
      expiredToken: false
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }

  componentDidMount() {
    let queries = queryString.parse(this.props.location.search)
    if (queries.token !== undefined) {
      const token = queries.token;
      this.props.confirmSamlEmail({ token: token });
    } else {
      window.location.href = "/login"
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.samlUserData !== this.props.samlUserData && this.props.samlUserData.token !== undefined) {
      const token = this.props.samlUserData.token;
      window.location.href = "/token/" + token;
    }

    if (this.props.errors.errors !== prevProps.errors.errors) {
      const errors = this.props.errors;

      if(errors.errors.response.status === 422) {
        this.setState({ expiredToken: true })
      } else {
        window.location.href = "/login";
      }
    }
  }

  handleChangeEmail = (e) => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value.trim()
    })
  }

  submitEmail = () => {
    if (this.validator.allValid()) {
      const { resendSamlEmail } = this.props;
      const jsonData = {
        email: this.state.email
      }
      resendSamlEmail(jsonData);
      this.setState({ emailSent: true })
    } else {
      this.validator.showMessages();
    }
  }

  render() {
    const { emailSent, expiredToken } = this.state;
    
    return (
      expiredToken ?
      <>
        <div className={`${S.container} container-content`}>
          <div className="container-custom pt-5">
            <div className={`${S.paddingBox} registration-box text-center`}>
              <VaultLogo x={-38} height={260} />
              {
                emailSent ?
                  <>
                    <img src={Check} className="img-fluid lock-img mt-3" alt="password" />
                    <br />
                    <p>
                      Check your inbox in a couple minutes for instructions.
                      Note that for security purposes, this link is valid
                      <br />
                      only for the next 24 hours.
                    </p>
                  </>
                  :
                  <>
                    <p>Looks like your confirmation link has expired. Let's try sending another one to your email.</p>
                    <form>
                      <div className="input-group">
                        <label className="">Email</label>
                        <input
                          required=""
                          id="email"
                          className="input-login"
                          type="text"
                          name="email"
                          defaultValue={this.state.email}
                          onChange={this.handleChangeEmail.bind(this)}
                        />
                         <ValidatorMessage validator={this.validator} validationType='required|email' fieldName='email' fieldValue={this.state.email} message='* Email is required' />
                      </div>
                    </form>
                    <button className={`${S.btn} Gt-orange-5 mt-4`} onClick={this.submitEmail}>
                      SEND EMAIL
                    </button>
                  </>
              }
            </div>
          </div>
        </div>
      </> : null
    )
  }
}

const mapStateToProps = (state) => {
  return {
    samlUserData: state.authentication.samlUserData,
    errors: state.errors
  };
};
const mapDispatchToProps = {
  confirmSamlEmail,
  resendSamlEmail
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);