import {
  UPDATE_CUSTOMER,
  UPDATE_STATUS_URL,
  FETCH_CUSTOMER,
  GET_ERRORS
} from './types';

import { apiAction } from './apiActions';
import config from '../config';


export const fetchCustomer = () => {
  return apiAction({
    url: config.apiBaseUrl + "customer",
    onSuccess: setfetchCustomer,
    callback: 'fetchCustomer',
    onFailure: errorfetchCustomer,
    label: FETCH_CUSTOMER
  });
}

function setfetchCustomer(data) {
  return {
    type: FETCH_CUSTOMER,
    payload: data.data
  };
}
function errorfetchCustomer(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}
export const updateStatusUrl = (requestData) => {

  return apiAction({
    url: config.apiBaseUrl + "customers/" + localStorage.getItem('customerId') + "/update_status_url",
    method: "POST",
    data: requestData,
    onSuccess: setupdateStatusUrl,
    callback: '',
    onFailure: errorupdateStatusUrl,
    label: UPDATE_STATUS_URL
  });
}
function setupdateStatusUrl(data) {
  return {
    type: UPDATE_STATUS_URL,
    payload: data.data
  };
}
function errorupdateStatusUrl(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const updateCustomer = (customerRequest) => {
  delete customerRequest.data.attributes.plan_details;
  return apiAction({
    url: config.apiBaseUrl + "customers/" + localStorage.getItem('customerId'),
    method: "PATCH",
    data: customerRequest,
    onSuccess: setUpdateCustomer,
    callback: '',
    onFailure: errorUpdateCustomer,
    label: UPDATE_CUSTOMER
  });
}

function setUpdateCustomer(data) {
  return {
    type: UPDATE_CUSTOMER,
    payload: data.data
  };
}
function errorUpdateCustomer(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}