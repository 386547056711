import { headerSelector } from './../../Includes/Header/Helpers/serviceSelector';
import dashboardData from './../../Includes/Header/data/dashboardData.json'

const headerName = headerSelector();

let headerSelectedData = [];
let dashboardUrl = '#';
headerSelectedData = dashboardData.filter((x) => x.service === headerName)
if (headerSelectedData.length > 0) {
    dashboardUrl = headerSelectedData[0].data[0].headerPath
}

export const getLoansNewBreadcrumb = (fromMatch) => {
    let addLoanBreadcrumb;
    if (fromMatch) {
        addLoanBreadcrumb = [
            {
                title: 'Dashboard',
                active: false,
                url: '/match/accounts'
            }, {
                title: 'Add a loan',
                active: true,
                url: '#'
            }
        ]
    } else {
        addLoanBreadcrumb = [
            {
                title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
                active: false,
                url: '/advice-and-tools/dashboard'
            }, {
                title: 'Add a loan',
                active: true,
                url: '#'
            }
        ]
    }
    return addLoanBreadcrumb;
}

export const getEditLoanBreadcrumb = (fromForgivenessEligibility) => {
    let EditLoanBreadcrumb;
    if (fromForgivenessEligibility) {
        EditLoanBreadcrumb = [
            {
                title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
                active: false,
                url: '/advice-and-tools/dashboard'
            }, {
                title: 'Forgiveness Eligibility',
                active: false,
                url: '/advice-and-tools/forgiveness-eligibility'
            }, {
                title: 'Edit Loan Details',
                active: true,
                url: '#'
            }
        ]
    } else {
        EditLoanBreadcrumb = [
            {
                title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
                active: false,
                url: '/advice-and-tools/dashboard'
            }, {
                title: 'Edit Loan Details',
                active: true,
                url: '#'
            }
        ]
    }
    return EditLoanBreadcrumb
}

export const AddLoanWithFSABreadcrumb = [
    {
        title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
        active: false,
        url: '/advice-and-tools/dashboard'
    }, {
        title: 'Add Loans with FSA',
        active: true,
        url: '#'
    }
]

export const AdviceAndToolLowerPaymentsBreadcrumb = [
    {
        title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
        active: false,
        url: '/advice-and-tools/dashboard'
    }, {
        title: 'Lower Payments',
        active: true,
        url: '#'
    }
]
export const AdviceAndToolRefinanceBreadcrumb = [
    {
        title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
        active: false,
        url: '/advice-and-tools/dashboard'
    }, {
        title: 'Refinance',
        active: true,
        url: '#'
    }
]
export const AdviceAndToolForgivenessEligibilityBreadcrumb = [
    {
        title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
        active: false,
        url: '/advice-and-tools/dashboard'
    }, {
        title: 'Forgiveness Eligibility',
        active: true,
        url: '#'
    }
]

export const getBreadcrumbForLowerPaymentPlans = (title) => {
    const AdviceAndToolLowerPaymentsPlanBreadcrumb = [
        {
            title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
            active: false,
            url: '/advice-and-tools/dashboard'
        }, {
            title: 'Lower Payments',
            active: false,
            url: '/advice-and-tools/lower-payment'
        }, {
            title,
            active: true,
            url: '#'
        }
    ]
    return AdviceAndToolLowerPaymentsPlanBreadcrumb;
}

export const AdviceAndToolPayFasterBreadcrumb = [
    {
        title: headerName === 'advisorOnly' ? 'Dashboard' : 'Advice and Tools',
        active: false,
        url: '/advice-and-tools/dashboard'
    }, {
        title: 'Payoff Faster',
        active: true,
        url: '#'
    }
]

export const AdviceAndToolPublicServiceForgivenessBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: dashboardUrl
    }, {
        title: 'Public Service Forgiveness',
        active: true,
        url: '#'
    }
]