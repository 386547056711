import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { responseMessage } from '../../../utils/alert';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { fetchCustomer } from '../../../actions/customerActions';
import { getIndividualServicerAccount, validateAccountNumber, sendEmailFeedback, getFinicityConnectLink, finicityConnectSuccess } from '../../../actions/advisorActions';
import { getServicerAccounts, getAddresses } from '../../../actions/fiveTwoNineActions';
import { checkForAccount } from "../address-select";
import { validateServicerAccountNumber } from "../validate-servicer-account-number";
import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import Form from 'react-bootstrap/Form'
import NumberFormatInput from './../../shared/NumberFormatInput/NumberFormatInput';
import S from './Payments.module.css';
import Stepper from '../../shared/Stepper/Stepper';
import { paymentBreadcrumbData, paymentStepperData, StepperStage, LoanParameters, paymentEditAccountFromAccounts } from './Payment.constant';
import Breadcrumb from "../../shared/Breadcrumb/Breadcrumb";
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import Pencil from 'react-bootstrap-icons/dist/icons/pencil';
import { postEditServicerAccounts } from './../../../actions/fiveTwoNineActions';
import { getNeedsFinicityLink } from '../pay';
import finicityConnect from "../../../helpers/FinicityConnect";
import { fetchEmployer } from "../../../actions/employerActions";
import { Modal } from 'react-bootstrap';

class EditContribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: null,
      account_number: "",
      account_balance: "",
      monthly_payment: "",
      interest_rate: "",
      addressId: "",
      servicer: "",
      addressData: [],
      showInstructions: false,
      saId: this.props.match.params.saId,
      requestData: {
        "data": {
          "attributes": {
          }
        }
      },
      valid: false,
      feedback: "",
      nelnetSuggestedAddress: null,
      servicerAccountNumberErrors: [],
      fauxFinicityModalSelectedServicerAccountId: null,
      finicityConnected: false,
      addressSentModal: false,
      accountNumberWarningModal: false,
      accountNumberWarningModalV2: false,
      paymentAddressWarningModal: false,
    };
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });

  }
  componentDidMount() {
    document.title = "Vault Advisor"
    const saId = this.props.match.params.saId;
    const { getIndividualServicerAccount, getServicerAccounts, getAddresses, fetchCustomer, fetchEmployer } = this.props;
    getIndividualServicerAccount(saId);
    getServicerAccounts();
    getAddresses();
    fetchCustomer();
    fetchEmployer();
    intercomTrackEvent(eventNames.visitedPayLoanServicerSetup);
    const missingData = !!this.props?.location?.missingData;
    if (missingData) {
      responseMessage("error", "Please fill your servicer account details", "");
      this.validator.showMessages();
    }
  }
  componentDidUpdate(prevProps) {

    if (prevProps.employer !== this.props.employer) {
      this.setState({
        employer: this.props.employer,
      });
    }
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer
      })
    }
    if (prevProps.servicer_accounts !== this.props.servicer_accounts) {
      const saId = this.state.saId;
      const servicer_accounts_data = this.props.servicer_accounts.data;
      const servicer_accounts_included = this.props.servicer_accounts.included;

      let servicer_accounts = servicer_accounts_data.find(function (value) {
        return value.id === saId
      });

      const servicerId = servicer_accounts?.relationships['servicer']['data']['id'];
      const addressId = servicer_accounts?.relationships['address']['data'] ? servicer_accounts?.relationships['address']['data']['id'] : "";
      let servicer = servicer_accounts_included.find(function (value) {
        return value.id === servicerId && value.type === 'servicers'
      });

      const addresses_array = servicer?.relationships.addresses.data;

      let addressData = [];
      if (this.props.addresses.data) {
        const addresses = this.props.addresses.data

        for (let i = 0; i < addresses_array.length; i++) {
          const Ids = addresses_array[i]['id'];
          let address_array = addresses.find(item => item.id === Ids);
          addressData.push(address_array)
        }
      }

      const servicerJson = {
        "servicer": {
          "data": {
            "type": "servicers",
            "id": servicerId,
            "attributes": servicer.attributes
          }
        }
      }
      const addressJson = {
        "data": {
          "type": "addresses",
          "id": addressId
        }
      }
      const { requestData } = this.state;
      requestData.data['id'] = servicer_accounts.id;
      requestData.data['servicer_name'] = servicer.attributes.name;
      requestData.data.attributes = servicer_accounts.attributes;
      requestData.data['relationships'] = servicerJson;
      requestData.data.relationships['address'] = addressJson;

      this.setState({
        requestData: requestData,
        servicer: servicer,
        addressData: addressData,
        addressId: addressId,
        account_number: servicer_accounts.attributes.account_number ? servicer_accounts.attributes.account_number : "",
        account_balance: servicer_accounts.attributes.account_balance ? servicer_accounts.attributes.account_balance : 0,
        monthly_payment: servicer_accounts.attributes.monthly_payment ? servicer_accounts.attributes.monthly_payment : 0,
        interest_rate: servicer_accounts.attributes.interest_rate ? servicer_accounts.attributes.interest_rate + '%' : ''
      }, () => {
        this.checkAccountNumberAddressRules();
        if (getNeedsFinicityLink(servicer_accounts)) {
          this.handleSelectAccount(servicer_accounts.id)
        }
      })

    }
    if (prevProps.validateAccountNumberResponse !== this.props.validateAccountNumberResponse) {
      const resp = this.props.validateAccountNumberResponse
      if (resp.data.valid === true) {
        this.setState({
          valid: true
        })
      } else {
        // window.$('#accountNumberWarningModalLong').modal('show');
        this.hideShowAccountNumberWarningModal(true)
      }
    }
    if (prevProps.emailFeedbackResponse !== this.props.emailFeedbackResponse) {
      responseMessage("success", "Your question/feedback has been submitted and our team will get back to you very soon!", "");
      this.setState({
        feedback: ""
      });
      // window.$('#exampleModalLong').modal('hide');
      this.hideShowAddressSentModal(false);
    }
  }
  toggleShowInstructions = (e) => {
    if (this.state.showInstructions === true) {
      this.setState({
        showInstructions: false
      })
    } else {
      this.setState({
        showInstructions: true
      })
    }
  }

  clearFinicityState = () => {
    this.setState({
      loadingFinicityLink: false,
      initiatingServicerId: null
    })
  }

  onSubmitFinicity = (id) => {
    const { finicityConnectSuccess, getServicerAccounts } = this.props;
    const jsonRequest = {
      'servicer_account_id': id
    }

    finicityConnectSuccess(jsonRequest);
    this.setState({
      finicityConnected: true
    })
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  }

  handleSelectAccount = (saId) => {
    const { employer } = this.state;
    if (employer?.attributes?.faux_finicity_enabled) {
      this.timerID = setTimeout(() => {
        this.setState({
          fauxFinicityModalSelectedServicerAccountId: saId,
          fauxFinicityModalEnabled: true,
        });
      }, 1000);
    } else {
      saId && this.props.getFinicityConnectLink(saId);
      this.timerID = setTimeout(() => {
        this.props.finicityConnectLink && finicityConnect(this.props.finicityConnectLink, this.clearFinicityState, this.onSubmitFinicity, saId);
      }, 2000);
    }
  };

  selectAddress(addressValue) {
    let requestData = this.state.requestData;
    const addressJson = {
      "data": {
        "type": addressValue.type,
        "id": addressValue.id,
        servicerId: addressValue.id
      }
    }
    requestData.data.relationships['address'] = addressJson;
    this.setState({
      requestData: requestData,
      addressId: addressValue.id
    })
  }
  handleInputChange(e) {
    const { requestData } = this.state;
    requestData.data.attributes[e.target.name] = e.target.value
    this.setState({
      requestData: requestData,
      [e.target.name]: e.target.value
    })
  }
  handleAccountBalanceChange = (values) => {
    const { requestData } = this.state;
    const floatValue = values.floatValue;

    requestData.data.attributes.account_balance = floatValue;

    this.setState({
      requestData: requestData,
      account_balance: floatValue
    })
  }
  handleMonthlyPaymentChange = (values) => {
    const { requestData } = this.state;
    const floatValue = values.floatValue;

    requestData.data.attributes.monthly_payment = floatValue;

    this.setState({
      requestData: requestData,
      monthly_payment: floatValue
    })
  }
  handleInterestRateChange = (values) => {
    const { requestData } = this.state;
    const floatValue = values.floatValue;

    requestData.data.attributes.interest_rate = floatValue;

    this.setState({
      requestData: requestData,
      interest_rate: floatValue
    })
  }

  onCancel(fromAccounts) {
    if (fromAccounts) {
      this.props.history.push({
        pathname: '/pay/accounts'
      })
    } else {
      this.props.history.push({
        pathname: '/pay/overview'
      })
    }
  }
  handleContinue(e) {
    this.setState({
      valid: true,
      accountNumberWarningModal: false,
      paymentAddressWarningModal: false,
      accountNumberWarningModalV2: false,
    }, () => {
      this.saveChanges()
    })

    // window.$('#accountNumberWarningModalLong').modal('hide');
    // window.$('#paymentAddressWarningModalLong').modal('hide');
    // window.$('#accountNumberWarningModal').modal('hide');
  }
  handleServerSideValidation = (e) => {
    const { requestData } = this.state;
    const finicityActive = requestData.data.relationships.data?.attributes.finicity_active

    if (finicityActive === true) {
      if (this.needsNelnetAddressValidation(requestData)) {
        // window.$('#paymentAddressWarningModalLong').modal('show');
        this.hideShowPaymentAddressWarningModal(true)
      } else {
        this.checkValidateServicerAccountNumber(requestData);
      }
    } else {
      this.checkValidateServicerAccountNumber(requestData);
    }
  }
  checkAccountNumberAddressRules() {
    const { requestData, addressData } = this.state;
    const addressToSet = checkForAccount(requestData, addressData)

    if (addressToSet && requestData.data.attributes.account_number.length > 0 && requestData.data.relationships.address.data.id === addressToSet.id) {
      requestData.data['relationships']['address']['data'] = addressToSet
      if (this.isNelnet(requestData)) {
        this.setState({
          requestData: requestData,
          nelnetSuggestedAddress: addressToSet
        })
      }
    }
  }
  needsNelnetAddressValidation(servicerAccount) {
    if (!this.nelnetSuggestedAddress) {
      return false;
    }
    return this.isNelnet(servicerAccount) &&
      this.state.nelnetSuggestedAddress && this.state.nelnetSuggestedAddress.id !== servicerAccount.data.relationships.address.data.id
  }
  isNelnet(servicerAccount) {
    const servicerName = servicerAccount.data.servicer_name;
    return servicerName.toLowerCase() === 'nelnet';
  }
  checkValidateServicerAccountNumber(servicerAccount) {
    const servicerName = servicerAccount.data.servicer_name;
    const accountNumber = servicerAccount.data.attributes.account_number;

    const servicerAccountNumberErrors = validateServicerAccountNumber(servicerName, accountNumber);

    if (servicerAccountNumberErrors.length > 0) {
      this.setState({
        servicerAccountNumberErrors: servicerAccountNumberErrors
      })
      /*Open Account Number Warning Modal*/
      // window.$('#accountNumberWarningModal').modal('show');
      this.hideShowAccountNumberWarningModalV2(true);
    } else {
      this.setState({
        valid: true
      }, () => {
        this.saveChanges()
      })
    }
  }
  saveChanges() {
    if (this.validator.fieldValid('account_number') && this.validator.fieldValid('account_balance') && this.validator.fieldValid('monthly_payment') && this.validator.fieldValid('interest_rate') && this.validator.fieldValid('addressId')) {
      const { validateAccountNumber } = this.props;
      const { requestData, saId, valid } = this.state

      if (valid) {
        this.props.postEditServicerAccounts(requestData)
        this.props.history.push({

          pathname: '/pay/accounts',
          requestData: requestData,
        });
      } else {
        const jsonData = {
          "data": {
            account_number: this.state.account_number
          }
        }
        validateAccountNumber(saId, jsonData);
      }
    } else {
      this.validator.showMessages();
    }
  }
  handleChangeFeedback = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  submitFeedback = (e) => {
    if (this.validator.allValid()) {
      const { feedback, customer } = this.state;
      const { sendEmailFeedback } = this.props;
      const source = "source:Advisor\nabout:Feedback form submission\n\n" + feedback;

      const feedbackJson = {
        "mail_to": customer.advisorSupportEmail,
        "feedback": source
      }
      sendEmailFeedback(feedbackJson);
      intercomTrackEvent(eventNames.submittedFeedbackFormAdvisorDashboard);
    } else {
      this.validator.showMessages();
    }
  }

  hideShowAddressSentModal = (status) => {
    this.setState({
      addressSentModal: status,
    })
  }
  hideShowAccountNumberWarningModal = status => {
    this.setState({
      accountNumberWarningModal: status
    })
  }
  hideShowPaymentAddressWarningModal = status => {
    this.setState({
      paymentAddressWarningModal: status
    })
  }
  hideShowAccountNumberWarningModalV2 = status => {
    this.setState({
      accountNumberWarningModalV2: status
    })
  }

  render() {
    const {
      servicer,
      addressData,
      account_number,
      account_balance,
      monthly_payment,
      interest_rate,
      addressId,
      servicerAccountNumberErrors
    } = this.state;
    const servicer_name = this.state.requestData.data.servicer_name ? this.state.requestData.data.servicer_name : "";
    const fromAccounts = !!this.props?.location?.fromAccounts;
    return (
      <>
        <Header {...this.props} />

        <div className="G-page-background-color">

          <div className='G-page-main-container py-5'>
            {fromAccounts ?
              <Breadcrumb list={paymentEditAccountFromAccounts()} />
              :
              <Breadcrumb list={paymentBreadcrumbData()} />
            }
            <Stepper options={paymentStepperData(StepperStage.complete, StepperStage.active)} />
            <div className='G-grid11 p-4  bg-white rounded-lg Gborder-05 mt-5'>
              <div>
                <div className={`${S.circularDiv}`} >
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27.2626 22.7375L15.9126 11.3875C17.0751 8.46249 16.4751 5.01249 14.1126 2.63749C11.2376 -0.237505 6.76263 -0.500006 3.57513 1.82499L8.37513 6.63749L6.60013 8.39999L1.81263 3.61249C-0.512365 6.78749 -0.249865 11.275 2.62513 14.1375C4.95013 16.4625 8.33764 17.075 11.2376 15.9875L22.6251 27.375C23.1126 27.8625 23.9001 27.8625 24.3876 27.375L27.2626 24.5C27.7626 24.025 27.7626 23.2375 27.2626 22.7375ZM23.5126 24.7375L11.6876 12.9125C10.9251 13.475 10.0751 13.8125 9.18763 13.9375C7.48763 14.1875 5.70013 13.675 4.40014 12.375C3.21264 11.2 2.66263 9.625 2.75013 8.075L6.61264 11.9375L11.9126 6.63749L8.05013 2.77499C9.60013 2.68749 11.1626 3.23749 12.3501 4.41249C13.7001 5.76249 14.2126 7.62499 13.9001 9.36249C13.7501 10.25 13.3751 11.075 12.8001 11.8125L24.6126 23.625L23.5126 24.7375Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <div className="my-2 Gt-slate-5">
                  <h4 className="Gfs-175 sans-semibold">Loan Servicer Setup</h4>
                  <p className="sans-medium">
                    Loan details such as your balance, interest rate and monthly <br />
                    payments are not shared with your employer.
                  </p>
                  <div className='mt-4'>
                    <p className='sans-semibold'>Where can you find your account information?</p>
                    <ul className={`${S.instructionList} pl-4`}>
                      <li className='py-1'>Check your mail: Servicers may periodically send a physical payment coupon</li>
                      <li className='py-1'>Check Online: Login to your servicer's website</li>
                      <li className='py-1'>Make a phone call: Call your servicer</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <Form.Group className='my-4'>
                  <Form.Label className='sans-medium'>Send Contributions to</Form.Label>
                  <Form.Control className='text-black mb-2' type="text" disabled value={servicer ? servicer.attributes.name : ""} />
                  <Form.Text className='Gt-pink-8 sans-semibold'>
                    <Pencil size={18} className='mb-1' />
                    <Link className='Ga mx-2 Gfs-1' to="/pay/accounts">Change Servicer</Link>
                  </Form.Text>
                </Form.Group>
                <Form.Group className='my-4'>
                  <Form.Label className='sans-medium'>Loan Account Number</Form.Label>
                  <Form.Control className='text-black' type="text" onChange={(e) => this.handleInputChange(e)} placeholder="Enter account number" value={account_number} name={LoanParameters.accountNumber} />
                  <Form.Text className="text-muted">
                    <ValidatorMessage validator={this.validator} fieldName={LoanParameters.accountNumber} fieldValue={this.state.account_number} message='Account number is required' />
                  </Form.Text>
                </Form.Group>

                <Form.Group className='my-4'>
                  < NumberFormatInput label='Current Loan Balance' name={LoanParameters.accountBalance} value={account_balance} placeholder='Type Here' handleValueChange={(data) => this.handleAccountBalanceChange(data)} />

                  <ValidatorMessage validationType='required|numeric|min:1,num' validator={this.validator} fieldName={LoanParameters.accountBalance} fieldValue={this.state.account_balance} message='Account balance is required' customMessage={{ 'min': 'Account balance must be greater than 0' }} />

                </Form.Group>

                <Form.Group className='my-4'>
                  <NumberFormatInput label='Min. Monthly Payment' name={LoanParameters.monthlyPayment} value={monthly_payment} placeholder='Type here' handleValueChange={(data) => this.handleMonthlyPaymentChange(data)} />
                  <ValidatorMessage validator={this.validator} fieldName={LoanParameters.monthlyPayment} fieldValue={this.state.monthly_payment} message='Monthly payment is required' />

                </Form.Group>

                <Form.Group className='my-4'>
                  <NumberFormatInput label='Weighted Average Interest' prefix='' suffix='%' name={LoanParameters.interestRate} value={interest_rate} placeholder='Type here' handleValueChange={(data) => this.handleInterestRateChange(data)} />
                  <ValidatorMessage validator={this.validator} fieldName={LoanParameters.interestRate} fieldValue={this.state.interest_rate} message='Interest rate is required' />

                </Form.Group>

                <div className='mt-5'>
                  <h3 className='Gfs-125 Gt-slate-5'>Select your loan servicer's payment Address</h3>
                  <p className='sans-medium'> Don't see loan servicer's payment or billing address? <span className='Gt-pink-8 sans-semibold cursor-pointer' data-toggle="modal" data-target="#exampleModalLong">Get in touch</span></p>
                  <div>
                    {addressData ? addressData.map((item, index) => {
                      const selected = item.id === addressId ? `${S.selectedAddressBox}` : "";
                      return (
                        <div key={index}>
                          <div className={`${S.loanAddressBox} ${selected} rounded-lg my-2`} onClick={() => this.selectAddress(item)}>
                            <p className='mb-1'>{item.attributes.mail_to}</p>
                            <p className='mb-1'>{item.attributes.address}</p>
                          </div>
                        </div>)
                    }) : null}
                    <ValidatorMessage validator={this.validator} fieldName='addressId' fieldValue={this.state.addressId} message='Please select at least one address.' />
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end my-3'>
              <button className='G-cancel-button mx-3' onClick={this.onCancel.bind(this, fromAccounts)}>Go Back</button>
              <button className='G-orange-button' onClick={this.handleServerSideValidation.bind(this)}>Confirm & Save</button>
            </div>
          </div>


        </div>

        <Modal size="lg" show={this.state.addressSentModal} onHide={() => this.hideShowAddressSentModal(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'>Tell us the address you send payments to.</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className="sans-medium">If the address you send your payments to is not listed, please let us know. A representative will get in contact with you shortly once it is added to our system. </p>
              <textarea
                type="text"
                rows="4"
                className={`form-control Gborder-1 ${S.placeholderText} `}
                placeholder="You can find this information on the physicial payment stub for your servicer."
                name="feedback"
                value={this.state.feedback}
                onChange={this.handleChangeFeedback.bind(this)}
              />
              <ValidatorMessage validator={this.validator} fieldName='feedback' fieldValue={this.state.feedback} message='You must submit something!' />
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={() => this.hideShowAddressSentModal(false)}>Cancel</button>
              <button onClick={this.submitFeedback.bind(this)} type="button" className="G-orange-button mob-separate-v6">Submit</button>
            </div>
          </Modal.Body>
        </Modal>

        {/* <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title sans-semibold Gfs-175" id="exampleModalLongTitle">Tell us the address you send payments to.</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div>
                    <p className="sans-medium">If the address you send your payments to is not listed, please let us know. A representative will get in contact with you shortly once it is added to our system. </p>
                    <textarea
                      type="text"
                      className={`form-control Gborder-1 ${S.placeholderText} Gh-10vh`}
                      placeholder="You can find this information on the physicial payment stub for your servicer."
                      name="feedback"
                      value={this.state.feedback}
                      onChange={this.handleChangeFeedback.bind(this)}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='feedback' fieldValue={this.state.feedback} message='You must submit something!' />
                  </div>

                </div>
              </div>
              <div className="d-flex justify-content-end p-3">
                <button type="button" className="G-cancel-button mx-3" data-dismiss="modal">Cancel</button>
                <button onClick={this.submitFeedback.bind(this)} type="button" className="G-orange-button">Submit</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* Account Number Warning Modal*/}
        <Modal size="lg" show={this.state.accountNumberWarningModal} onHide={() => this.hideShowAccountNumberWarningModal(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'> Account Number Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className='sans-medium'>The account number you entered doesn’t match the account number found with your loan servicer. Please confirm your account number.</p>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={() => this.hideShowAccountNumberWarningModal(false)}> Revise Now</button>
              <button onClick={this.handleContinue.bind(this)} type="button" className="G-orange-button mob-separate-v6">Continue</button>
            </div>
          </Modal.Body>
        </Modal >
        {/* <div className="modal fade" id="accountNumberWarningModalLong" tabIndex="-1" role="dialog" aria-labelledby="accountNumberWarningModalLongTitle" aria-hidden="true">
          <div className="modal-dialog contribution-modal" role="document">
            <div className="modal-content p-3">
              <div className="border-0 d-flex justify-content-between align-items-baseline">
                <h5 className="Gfs-15" id="accountNumberWarningModalLongTitle">
                  Account Number Warning
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pl-0">
                <div>
                  <p className='sans-medium'>The account number you entered doesn’t match the account number found with your loan servicer. Please confirm your account number.</p>
                </div>
              </div>
              <div className="d-flex justify-content-end py-2">
                <button type="button" className="G-cancel-button mx-3" data-dismiss="modal">Revise Now</button>
                <button onClick={this.handleContinue.bind(this)} type="button" className="G-orange-button">Continue</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* Payment Address Warning Modal*/}
        <Modal size="lg" show={this.state.paymentAddressWarningModal} onHide={() => this.hideShowPaymentAddressWarningModal(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'> Payment Address Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className='sans-semibold' >According to your account number, the Nelnet Payment Address should be:</p>
              <p className='sans-medium'>{this.state.nelnetSuggestedAddress ? this.state.nelnetSuggestedAddress.attributes.mail_to : ''}</p>
              <p className='sans-medium'>{this.state.nelnetSuggestedAddress ? this.state.nelnetSuggestedAddress.attributes.address : ''}</p>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={(e) => { intercomTrackEvent(eventNames.skippedPaySetupNelnetAddressValidation); this.hideShowPaymentAddressWarningModal(false) }}>Revise Now</button>
              <button onClick={(e) => { intercomTrackEvent(eventNames.clickedGoBackOnNelnetAddressValidation); this.handleContinue(e) }} type="button" className="G-orange-button mob-separate-v6">Continue</button>
            </div>
          </Modal.Body>
        </Modal >
        {/* <div className="modal fade" id="paymentAddressWarningModalLong" tabIndex="-1" role="dialog" aria-labelledby="paymentAddressWarningModalLongTitle" aria-hidden="true">
          <div className="modal-dialog contribution-modal" role="document">
            <div className="modal-content p-3">
              <div className="border-0 d-flex justify-content-between align-items-baseline">
                <h5 className="Gfs-15" id="paymentAddressWarningModalLongTitle">
                  Payment Address Warning
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pl-0">
                <div>
                  <p className='sans-semibold'>According to your account number, the Nelnet Payment Address should be:</p>
                  <p className='sans-medium'>{this.state.nelnetSuggestedAddress ? this.state.nelnetSuggestedAddress.attributes.mail_to : ''}</p>
                  <p className='sans-medium'>{this.state.nelnetSuggestedAddress ? this.state.nelnetSuggestedAddress.attributes.address : ''}</p>
                </div>
              </div>
              <div className="d-flex justify-content-end py-2">
                <button type="button" className="G-cancel-button mx-3" data-dismiss="modal" onClick={(e) => { intercomTrackEvent(eventNames.skippedPaySetupNelnetAddressValidation); }}>Revise Now</button>
                <button onClick={(e) => { intercomTrackEvent(eventNames.clickedGoBackOnNelnetAddressValidation); this.handleContinue(e) }} type="button" className="G-orange-button">Continue</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* Account Number Warning Modal*/}
        <Modal size="lg" show={this.state.accountNumberWarningModalV2} onHide={() => this.hideShowAccountNumberWarningModalV2(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'> Account Number Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className="sans-semibold">{servicer_name} Account numbers typically:</p>
              <ul className="bullets">
                {servicerAccountNumberErrors ? servicerAccountNumberErrors.map((item, index) => {
                  return (<li className='sans-medium' key={index}>{item}</li>)
                }) : null}
              </ul>
              <p className='sans-medium'>If you're sure this is correct click Continue, otherwise click Revise Now to re-enter the correct account number.</p>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={(e) => { intercomTrackEvent(eventNames.clickedGoBackOnAccountNumberValidation); this.hideShowAccountNumberWarningModalV2(false) }}>Revise Now</button>
              <button onClick={(e) => { intercomTrackEvent(eventNames.skippedPaySetupAccountNumberValidation); this.handleContinue(e) }} type="button" className="G-orange-button mob-separate-v6">Continue</button>
            </div>
          </Modal.Body>
        </Modal >

        {/* <div className="modal fade" id="accountNumberWarningModal" tabIndex="-1" role="dialog" aria-labelledby="accountNumberWarningModalTitle" aria-hidden="true">
          <div className="modal-dialog contribution-modal" role="document">
            <div className="modal-content p-3">
              <div className="border-0 d-flex justify-content-between align-items-baseline">
                <h5 className="Gfs-15" id="accountNumberWarningModalTitle">
                  Account Number Warning
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pl-0">
                <div>
                  <p className='sans-semibold'>{servicer_name} account numbers typically:</p>
                  <ul className="bullets">
                    {servicerAccountNumberErrors ? servicerAccountNumberErrors.map((item, index) => {
                      return (<li className='sans-medium' key={index}>{item}</li>)
                    }) : null}
                  </ul>
                  <p className='sans-medium'>If you're sure this is correct click Continue, otherwise click Revise Now to re-enter the correct account number.</p>
                </div>
              </div>
              <div className="d-flex justify-content-end py-2">
                <button type="button" className="G-cancel-button mx-3" data-dismiss="modal" onClick={(e) => { intercomTrackEvent(eventNames.clickedGoBackOnAccountNumberValidation); }}>Revise Now</button>
                <button onClick={(e) => { intercomTrackEvent(eventNames.skippedPaySetupAccountNumberValidation); this.handleContinue(e) }} type="button" className="G-orange-button">Continue</button>
              </div>
            </div>
          </div>
        </div> */}

        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    individualServicerAccount: state.advisor.individualServicerAccount,
    servicer_accounts: state.five_two_nine.getServicerAccountsData,
    addresses: state.five_two_nine.getAddressData,
    validateAccountNumberResponse: state.advisor.validateAccountNumberResponse,
    emailFeedbackResponse: state.advisor.emailFeedbackResponse,
    customer: state.customer_array.customerData,
    finicityConnectLink: state.advisor.finicityConnectLink?.data?.attributes?.link,
    employer: state.employer_array.employerData
  };
}
const mapDispatchToProps = {
  getIndividualServicerAccount,
  getServicerAccounts,
  getAddresses,
  validateAccountNumber,
  sendEmailFeedback,
  fetchCustomer,
  fetchEmployer,
  getFinicityConnectLink,
  finicityConnectSuccess,
  postEditServicerAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(EditContribution);