import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { responseMessage } from '../../utils/alert';
import config from '../../config';
import { fetchCustomer } from '../../actions/customerActions';
import { fetchEmployer } from '../../actions/employerActions';
import { verifyToken } from '../../actions/authenticationActions';
import initialState from '../shared/SignIn/initialState';
import updateState from '../shared/SignIn/updateState';
import getUrlForLogin from '../../helpers/getUrlForLogin';

const initialStateObj = initialState('token');

const Token = (props) => {
  const [state, setState] = useState({
    ...initialStateObj,
  })

  useEffect(() => {
    const { verifyToken } = props;

    if (props.match.params.token !== undefined) {
      let token = props.match.params.token;
      localStorage.setItem('userToken', token);
      const tokenRequest = {
        "token": token
      }
      verifyToken(tokenRequest);
    }
  }, [])

  useEffect(() => {
    if (props.verifyTokenResponse) {
      const { fetchCustomer, fetchEmployer } = props
      const token = localStorage.getItem('userToken');
      const parsedToken = JSON.parse(atob(token.split('.')[1]));
      let intercom = "";
      if (parsedToken.intercom) intercom = Object.assign(parsedToken.intercom, { email: parsedToken.email, user_id: parsedToken.user_id });

      localStorage.setItem('intercom', JSON.stringify(intercom));
      localStorage.setItem('saml_logout_page', parsedToken.saml_logout_page ? parsedToken.saml_logout_page : "");
      localStorage.setItem('samlKeepaliveUrl', parsedToken.saml_keepalive_url);
      localStorage.setItem('advisorTimeout', parsedToken.advisor_timeout);

      fetchCustomer();
      fetchEmployer();
    }
  }, [props.verifyTokenResponse])

  useEffect(() => {
    if (Object.keys(props.employer).length) {
      const advisorFirst = props.employer.attributes.advisor_first;
      const includeKnowledgeBank = props.employer.attributes.include_knowledge_bank;
      localStorage.setItem("advisorFirst", advisorFirst);
      setState({
        ...state,
        advisorFirst: advisorFirst,
        includeKnowledgeBank: includeKnowledgeBank
      })
    }
  }, [props.employer])

  useEffect(() => {
    if (Object.keys(props.customerData).length) {
      const updatedStateObj = updateState(props, 'token');

      setState({
        ...state,
        ...updatedStateObj,
      });
    }
  }, [props.customerData])

  useEffect(() => {
    if (props.errors.error) {
      responseMessage('error', props.errors.error, '');
      localStorage.removeItem('userToken');
      window.location.href = config.mainSiteUrl;
    }
  }, [props.errors.error])

  if (state.customerData) {
    const {
      userVerify
    } = state

    if (userVerify === true) {
      const url = getUrlForLogin(state);
      return <Redirect to={url} />
    } else {
      window.location.href = config.mainSiteUrl;
      return <div></div>;
    }
  } else {
    return <div></div>;
  }

}
Token.propTypes = {
  verifyToken: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  verifyTokenResponse: state.authentication.verifyTokenResponse,
  customerData: state.customer_array.customerData,
  employer: state.employer_array.employerData,
  errors: state.errors
});
export default connect(mapStateToProps, { verifyToken, fetchCustomer, fetchEmployer })(Token);
