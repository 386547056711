import kebabCase from 'lodash/kebabCase';
import { partners } from '../../common/js/constants';
import getPartnerFromDomain from './get-partner-from-domain';

/**
 * Tells us if the passed in partner name is Citizens. For templating checking, refer to the
 * `app/helpers/is-citizens.js` helper.
 *
 * @param {string} partner
 * @return {Boolean}
 */
const isCitizens = partner => {
  const partnerName = partner || getPartnerFromDomain();
  return kebabCase(partnerName) === kebabCase(partners.CITIZENS);
};
export default isCitizens;
