import { Component } from 'react';
import queryString from 'query-string'

class Samlauth extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let queries = queryString.parse(this.props.location.search)
    if (queries.token !== undefined) {
      let token = queries.token;
      window.location.href = "/token/" + token;
    } else {
      window.location.href = "/login"
    }
  }

  render() {
    return null
  }
}

export default Samlauth;
