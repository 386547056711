import React, { useEffect, useRef, useState } from 'react'
import { Question, } from 'react-bootstrap-icons';
import S from './FloatingButton.module.css'
import urlsToHide from './urlsToHide.json'
import getButtonLinksByAccountType from './getButtonLinksByAccountType';
import CustomerSupportModal from './CustomerSupportModal';

const FloatingButton = () => {
  const actions = getButtonLinksByAccountType();
  const [open, setOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const updateShowHideModal = () => {
    setShowModal(!showModal)
  }

  const actionDiv = useRef(null)

  const closeOpenMenus = (e) => {
    if (actionDiv.current && open && !actionDiv.current.contains(e.target)) {
      setOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', closeOpenMenus)
    return () => document.removeEventListener("mousedown", closeOpenMenus)
  }, [open])

  let pathname = window.location.pathname;
  let path = window.location.pathname.split("/")[1];
  let hideButton = urlsToHide.includes(pathname) ? true : urlsToHide.includes(path) ? true : false;
  if (hideButton) {
    return null
  }
  return (
    <>
      <div ref={actionDiv}>
        <div
          className={S.fabContainer}
          onClick={() => setOpen(!open)}
        >
          <div >
            <div className="fab-button">
              <Question />
            </div>
          </div>
          <ul className={`${S.fabDivInner} ${open ? 'open' : ''}`}>
            {actions.map((action, index) => (
              <li
                style={{ transitionDelay: `${index * 25}ms` }}
                className={`fabAction d-flex ${open ? 'open' : ''}`}
                key={index}
                onClick={action.onClick === null ? updateShowHideModal : action.onClick}
              >
                {action.icon}
                {action.title}
                {index === actions.length - 1 ?
                  <div className={S.arrow}></div>
                  : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <CustomerSupportModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}

export default FloatingButton