import { calculatorProgramResult, calculatorProgramResultWithMatch, convertHyphensCamelCaseLoansEligibilityCustom } from './jsCustomFunctions';
import { 
  monthsToHumanize
} from './jsFunctions';

export const calculateAllLoans = (loans_array, customer_array, eligibilityResults, loanTypeData) => {
  let eligiblePrograms = [];

  if (
    loans_array?.length > 0 &&
    Object.keys(customer_array).length > 0 &&
    Object.keys(eligibilityResults).length > 0 &&
    loanTypeData.length > 0
  ) {

    let eligibility_results = eligibilityResults;
    let eligibilityResultsData = eligibilityResults.data;
    let eligibility_results_included = eligibility_results.included;

    const employerMatch =
      customer_array.match.length > 0 ? customer_array.match[0].amount : 0;

    /* Calculate Eligible Loans */
    const eligibleProgramsArray = eligibilityResultsData.filter(function (
      value
    ) {
      return value.attributes.eligible === true;
    });


    let eligibleArray = [];

    for (var index = 0; index < eligibleProgramsArray.length; index++) {
      let relationshipsLoans =
        eligibleProgramsArray[index]["relationships"]["loans"]["data"];
      let elData = [];
      for (var indexL = 0; indexL < relationshipsLoans.length; indexL++) {
        let programLoanId = relationshipsLoans[indexL].id;

        eligibility_results_included.forEach((element, i) => {
          if (element.id === programLoanId) {
            element.attributes.advisable = false;
            element.attributes.interest_rate = Number(
              element.attributes.interest_rate
            );

            delete element.attributes["checksum_identifier"];
            delete element.attributes["data_source"];
            delete element.attributes["repayment_program"];
            delete element.attributes["servicer_name"];
            delete element.attributes["other_servicer_name"];
            elData[i] = element.attributes;
          }
        });
      }

      if (elData.length > 0) {
        const name = eligibleProgramsArray[index]["attributes"]["name"];
        const loans = convertHyphensCamelCaseLoansEligibilityCustom(elData);

        const obb = {
          name: name,
          loans: loans,
        };
        eligibleArray[index] = obb;

        const result = calculatorProgramResult(
          customer_array,
          loans,
          name,
          0
        );
        const resultWithMatch = calculatorProgramResultWithMatch(
          customer_array,
          loans,
          name,
          employerMatch
        );

        if (result && resultWithMatch) {
          const considerMatch = name === "Existing" ? false : true;
          const payoffDifference =
            result.payoffTime - resultWithMatch.payoffTime;
          const payoff = monthsToHumanize(payoffDifference);
          const interestSaved =
            result.accruedInterest - resultWithMatch.accruedInterest;
          const impactAmountDiff =
            result.totalPaid - resultWithMatch.totalPaid;

          let finalData = {
            result: result,
            resultWithMatch: resultWithMatch,
            name: eligibleProgramsArray[index]["attributes"]["name"],
            planName: eligibleProgramsArray[index]["id"],
            considerMatch: considerMatch,
            payoff: payoff,
            payoffFaster: payoffDifference > 0 ? true : false,
            interest: interestSaved,
            total: impactAmountDiff,
          };
          eligiblePrograms.push(finalData);
        }
      }
    }
  }
  return eligiblePrograms;
}