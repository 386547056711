import S from './Stepper.module.css';
// import { StepperStage } from '../../Advisor/Pay/Payment.constant';
import CheckLg from 'react-bootstrap-icons/dist/icons/check-lg';

export const StepperStage = {
    active: 'active',
    default: 'default',
    complete: 'complete'
}

const Stepper = ({ options }) => {
    return (
        <div className='w-100 bg-white rounded-lg Gborder-05 my-3'>
            <ul className={`mb-0 ` + (options.length === 2 ? S.stepper2 : S.stepper)}>
                {options.map((opt, i) => {
                    return <li key={i} className={opt.status === StepperStage.active ? `${S.activeState} w-100 pl-4` : `${S.defaultState} w-100 pl-4`}>
                        <div className='d-flex'>
                            {opt.status === StepperStage.complete ? <span className={`${S.indexSpan} ${S.completeState} mr-3`}><CheckLg size={24} className='text-white' /></span> : <span className={`${S.indexSpan} mr-3`}>{opt.index}</span>}
                            <span className='mt-2'>{opt.title}</span>
                        </div>

                        <span className={` ${S.rightIcon}`}>
                            {!opt.last ?
                                <svg width="18" height="92" viewBox="0 0 18 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.666626 0L17.6666 46.3172L0.666626 92" stroke="#D5D5D5" strokeWidth="0.5" />
                                </svg>
                                : ''}
                        </span>

                    </li>
                })}
            </ul>
        </div>
    );
}

export default Stepper;