import React, { Component } from 'react';
import './Loading.css';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state ={

    } 
  }
  render() {
    return (
      <div className="loader-small"></div>
    );
  }
}

export default (Loading);
