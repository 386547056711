import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { responseMessage } from '../../../utils/alert';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { Modal, Button } from 'react-bootstrap';
import { fetchCustomer } from '../../../actions/customerActions';
import { getAddresses, getServicerAccounts, postServicerAccounts, postEditServicerAccounts } from '../../../actions/fiveTwoNineActions';
import { fetchEmployer } from "../../../actions/employerActions";
import { getAdvisorServicers, validateAccountNumber, sendEmailFeedback, getFinicityConnectLink, finicityConnectSuccess } from '../../../actions/advisorActions';
import { sortAdvisorServicers } from '../dashboardCommon';

import { checkForAccount } from "../address-select";
import { validateServicerAccountNumber } from "../validate-servicer-account-number";

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import finicityConnect from '../../../helpers/FinicityConnect';
import { getNeedsFinicityLink, isAccountBalanceBelowThreshold } from '../pay';

import S from './Payments.module.css';
import { Form } from 'react-bootstrap';
import NumberFormatInput from './../../shared/NumberFormatInput/NumberFormatInput';
import { paymentBreadcrumbData, LoanParameters, selectStyles } from './Payment.constant';
import Breadcrumb from "../../shared/Breadcrumb/Breadcrumb";
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import Select from 'react-select';


class AddContribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_number: "",
      account_balance: "",
      monthly_payment: "",
      interest_rate: "",
      employer: null,
      servicerId: "",
      addressId: "",
      advisorServicers: [],
      addressData: [],
      requestData: {
        "data": {
          "attributes": {
          }
        }
      },
      selected: "",
      valid: false,
      showAddresDiv: false,
      nelnetSuggestedAddress: null,
      servicerAccountNumberErrors: [],
      feedback: "",
      servicer: {},
      fauxFinicityModalSelectedServicerAccountId: null,
      servicer_accounts: {},
      finicityConnected: false,
      addressSentModal: false,
      accountNumberWarningModal: false,
      accountNumberWarningModalV2: false,
      paymentAddressWarningModal: false,
    };
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor"
    const { getAdvisorServicers, getAddresses, getServicerAccounts, fetchEmployer } = this.props;
    getAdvisorServicers();
    getAddresses();
    getServicerAccounts();
    fetchEmployer();
    intercomTrackEvent(eventNames.visitedPayLoanServicerSetup);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer
      })
    }
    if (prevProps.advisorServicers !== this.props.advisorServicers) {
      const advisorServicers = this.props.advisorServicers.sort(sortAdvisorServicers)
      this.setState({
        advisorServicers: advisorServicers
      })
    }
    if (prevProps.employer !== this.props.employer) {
      this.setState({
        employer: this.props.employer,
      });
    }
    if (prevProps.addresses !== this.props.addresses) {
      this.setState({
        addresses: this.props.addresses.data
      })
    }
    if (prevProps.validateAccountNumberResponse !== this.props.validateAccountNumberResponse) {
      const resp = this.props.validateAccountNumberResponse
      if (resp.data.valid === true) {
        this.setState({
          valid: true
        })
      } else {
        this.hideShowAccountNumberWarningModal(true)
      }
    }
    if (prevProps.emailFeedbackResponse !== this.props.emailFeedbackResponse) {
      responseMessage("success", "Your question/feedback has been submitted and our team will get back to you very soon!", "");
      this.setState({
        feedback: ""
      });
      this.hideShowAddressSentModal(false);
    }
    if (prevProps.servicer_accounts !== this.props.servicer_accounts) {
      this.calculateServicerAccData()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  calculateServicerAccData = () => {
    const { servicerId, servicer, customer, finicityConnected } = this.state;
    const saId = servicerId;

    if (saId) {
      const servicer_accounts_data = this.props.servicer_accounts.data;
      const servicer_accounts_included = this.props.servicer_accounts.included;

      //filter already existing accounts with servicer_accounts data and show data for them
      let servicer_accounts = servicer_accounts_data.find(function (value) {
        return value.relationships.servicer.data.id === saId
      });
      this.setState({
        servicer_accounts: servicer_accounts
      })

      //if we find the data corresponding to the selected servicer
      if (servicer_accounts) {
        const servicerId = servicer_accounts['relationships']['servicer']['data']['id'];
        const addressId = servicer_accounts['relationships']['address']['data'] ? servicer_accounts['relationships']['address']['data']['id'] : "";

        let servicer = servicer_accounts_included.find(function (value) {
          return value.id === servicerId && value.type === 'servicers'
        });

        const addresses_array = servicer.relationships.addresses.data;

        let addressData = [];
        if (this.props.addresses.data) {
          const addresses = this.props.addresses.data

          for (let i = 0; i < addresses_array.length; i++) {
            const Ids = addresses_array[i]['id'];
            let address_array = addresses.find(item => item.id === Ids);
            addressData.push(address_array)
          }
        }

        const servicerJson = {
          "servicer": {
            "data": {
              "type": "servicers",
              "id": servicerId,
              "attributes": servicer.attributes
            }
          }
        }
        const addressJson = {
          "data": {
            "type": "addresses",
            "id": addressId
          }
        }
        const { requestData } = this.state;
        requestData.data['id'] = servicer_accounts.id;
        requestData.data['servicer_name'] = servicer.attributes.name;
        requestData.data.attributes = servicer_accounts.attributes;
        requestData.data['relationships'] = servicerJson;
        requestData.data.relationships['address'] = addressJson;

        this.setState({
          requestData: requestData,
          servicer: servicer,
          addressData: addressData,
          addressId: addressId,
          account_number: servicer_accounts.attributes.account_number ? servicer_accounts.attributes.account_number : "",
          account_balance: servicer_accounts.attributes.account_balance ? servicer_accounts.attributes.account_balance : 0,
          monthly_payment: servicer_accounts.attributes.monthly_payment ? servicer_accounts.attributes.monthly_payment : 0,
          interest_rate: servicer_accounts.attributes.interest_rate ? servicer_accounts.attributes.interest_rate + '%' : ''
        }, () => {
          this.checkAccountNumberAddressRules()
        })

        const accountBalanceBelowThreshold = isAccountBalanceBelowThreshold(servicer_accounts?.attributes?.is_finicity_linked, servicer_accounts?.attributes, customer?.attributes);
        if (accountBalanceBelowThreshold && !finicityConnected) {
          return this.setState({
            accountBalanceBelowThresholdModal: true,
            finicityConnected: false
          })
        }
        if (getNeedsFinicityLink(servicer_accounts)) {
          this.handleSelectAccount(servicer_accounts?.id)
        }
      } else {
        this.setState({
          account_number: "",
          account_balance: "",
          monthly_payment: "",
          interest_rate: "",
        })
        this.postServicerData(servicer);
      }
    }
  }

  postServicerData = (servicer) => {
    const { postServicerAccounts, getServicerAccounts } = this.props;
    const servicerJson = {
      "data": {
        "attributes": servicer.attributes,
        "relationships": {
          "servicer": {
            "data": {
              "type": "servicers",
              "id": servicer.id
            }
          }
        },
        "type": "servicer-accounts"
      }
    }
    postServicerAccounts(servicerJson);
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  }

  clearFinicityState = () => {
    this.setState({
      loadingFinicityLink: false,
      initiatingServicerId: null
    })
  }

  onSubmitFinicity = (id) => {
    const { finicityConnectSuccess, getServicerAccounts } = this.props;
    const jsonRequest = {
      'servicer_account_id': id
    }

    finicityConnectSuccess(jsonRequest);
    this.setState({
      finicityConnected: true
    })
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  }

  handleSelectAccount = (saId) => {
    const { employer } = this.state;
    if (employer.attributes.faux_finicity_enabled) {
      this.timerID = setTimeout(() => {
        this.setState({
          fauxFinicityModalSelectedServicerAccountId: saId,
          fauxFinicityModalEnabled: true,
        });
      }, 1000);
    } else {
      saId && this.props.getFinicityConnectLink(saId);
      this.timerID = setTimeout(() => {
        this.props.finicityConnectLink && finicityConnect(this.props.finicityConnectLink, this.clearFinicityState, this.onSubmitFinicity, saId);
      }, 2000);
    }
  };

  onSubmitFauxFinicity = (e) => {
    const servicerAccountId = this.state.fauxFinicityModalSelectedServicerAccountId;
    const { finicityConnectSuccess, getServicerAccounts } = this.props;
    const jsonRequest = {
      'servicer_account_id': servicerAccountId
    }
    finicityConnectSuccess(jsonRequest);
    this.setState({
      fauxFinicityModalEnabled: false,
      finicityConnected: true
    });
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  }
  onCancelFauxFinicity = (e) => {
    this.setState({
      fauxFinicityModalSelectedServicerAccountId: null,
      fauxFinicityModalEnabled: null
    })
  }

  balanceAlertModal = (resp) => {
    if (resp === "yes") {
      return this.setState({
        accountBalanceBelowThresholdModal: false,
        addressData: [],
        showAddresDiv: false,
        requestData: {
          "data": {
            "attributes": {
            }
          }
        },
        servicerId: "",
        servicer: "",
      })
    }
    this.props.history.push({
      pathname: '/pay/overview'
    })
  }

  changeLoanServicer(e) {
    if (!!e) {
      const { addresses, advisorServicers, requestData } = this.state;
      const servicerId = e.value;

      let servicer = advisorServicers.find(function (value) {
        return value.id === servicerId
      });
      let address = servicer.relationships.addresses.data;
      let address_array = [];
      for (var i = 0; i < address.length; i++) {
        let addressId = address[i].id;
        let itemAddress = addresses.find(item => item.id === addressId);
        address_array.push(itemAddress)
      }
      const servicerJson = {
        "servicer": {
          "data": {
            "type": servicer.type,
            "id": servicer.id,
            "attributes": servicer.attributes
          }
        }
      }
      requestData.data.attributes['match_active'] = servicer.attributes.match_active;
      requestData.data.attributes['pay_active'] = servicer.attributes.pay_active;
      requestData.data['servicer_name'] = servicer.attributes.name;
      requestData.data['relationships'] = servicerJson;


      this.setState({
        addressData: address_array,
        showAddresDiv: true,
        requestData: requestData,
        servicerId: servicerId,
        servicer: servicer
      }, () => {
        this.checkAccountNumberAddressRules();
        this.calculateServicerAccData();
      })
    } else {
      this.setState({
        servicerId: null,
        addressId: "",
        addressData: [],
        showAddresDiv: false,
        selected: ""
      }, () => {
        this.checkAccountNumberAddressRules();
      })
    }
  }
  selectAddress(addressValue) {
    let requestData = this.state.requestData;
    const addressJson = {
      "data": {
        "type": addressValue.type,
        "id": addressValue.id
      }
    }
    requestData.data.relationships['address'] = addressJson;
    this.setState({
      requestData: requestData,
      selected: 'selected',
      addressId: addressValue.id
    }, () => {
      this.checkAccountNumberAddressRules()
    })
  }
  handleInputChange(e) {
    const { requestData } = this.state;
    requestData.data.attributes[e.target.name] = e.target.value
    this.setState({
      requestData: requestData,
      [e.target.name]: e.target.value
    })
  }
  handleAccountBalanceChange = (values) => {
    const { requestData } = this.state;
    const floatValue = values.floatValue;

    requestData.data.attributes.account_balance = floatValue;

    this.setState({
      requestData: requestData,
      account_balance: floatValue
    })
  }
  handleMonthlyPaymentChange = (values) => {
    const { requestData } = this.state;
    const floatValue = values.floatValue;

    requestData.data.attributes.monthly_payment = floatValue;

    this.setState({
      requestData: requestData,
      monthly_payment: floatValue
    })
  }
  handleInterestRateChange = (values) => {
    const { requestData } = this.state;
    const floatValue = values.floatValue;

    requestData.data.attributes.interest_rate = floatValue;

    this.setState({
      requestData: requestData,
      interest_rate: floatValue
    })
  }

  onCancel(e) {
    this.props.history.push({
      pathname: '/pay/accounts'
    })
  }
  handleContinue(e) {
    this.setState({
      valid: true,
      accountNumberWarningModal: false,
      paymentAddressWarningModal: false,
      accountNumberWarningModalV2: false,
    }, () => {
      this.saveChanges()
    })
  }
  handleServerSideValidation = () => {
    if (this.validator.fieldValid('servicerId') && this.validator.fieldValid('account_number') && this.validator.fieldValid('account_balance') && this.validator.fieldValid('monthly_payment') && this.validator.fieldValid('interest_rate') && this.validator.fieldValid('addressId')) {
      const { requestData } = this.state;
      const finicityActive = requestData.data.relationships.data?.attributes.finicity_active;

      if (finicityActive === true) {
        if (this.needsNelnetAddressValidation(requestData)) {
          this.hideShowPaymentAddressWarningModal(true)
        } else {
          this.checkValidateServicerAccountNumber(requestData);
        }
      } else {
        this.checkValidateServicerAccountNumber(requestData);
      }
    } else {
      this.validator.showMessages();
    }
  }
  checkAccountNumberAddressRules() {
    const { requestData, addressData } = this.state;
    const addressToSet = checkForAccount(requestData, addressData);

    if (addressToSet && requestData.data.attributes.account_number.length > 0) {
      if (this.isNelnet(requestData)) {
        this.setState({
          //requestData:requestData,
          nelnetSuggestedAddress: addressToSet
        })
      }
    }

  }
  needsNelnetAddressValidation(servicerAccount) {
    if (this.nelnetSuggestedAddress === null) {
      return this._isNelnet(servicerAccount) && this.nelnetSuggestedAddress === null;
    }
    if (this.state.nelnetSuggestedAddress !== null) {
      return this.isNelnet(servicerAccount) && this.state.nelnetSuggestedAddress.id !== servicerAccount.data.relationships.address.data.id
    }
  }
  isNelnet(servicerAccount) {
    const servicerName = servicerAccount.data.servicer_name;
    return servicerName.toLowerCase() === 'nelnet';
  }
  checkValidateServicerAccountNumber(servicerAccount) {
    const servicerName = servicerAccount.data.servicer_name;
    const accountNumber = servicerAccount.data.attributes.account_number;

    const servicerAccountNumberErrors = validateServicerAccountNumber(servicerName, accountNumber);

    if (servicerAccountNumberErrors.length > 0) {
      this.setState({
        servicerAccountNumberErrors: servicerAccountNumberErrors
      })
      /*Open Account Number Warning Modal*/
      this.hideShowAccountNumberWarningModalV2(true);
    } else {
      this.setState({
        valid: true
      }, () => {
        this.saveChanges()
      })
    }
  }
  saveChanges(e) {
    if (this.validator.fieldValid('servicerId') && this.validator.fieldValid('account_number') && this.validator.fieldValid('account_balance') && this.validator.fieldValid('monthly_payment') && this.validator.fieldValid('interest_rate') && this.validator.fieldValid('addressId')) {
      const { requestData, valid } = this.state

      if (valid) {
        this.props.postEditServicerAccounts(requestData)

        this.props.history.push({

          pathname: '/pay/accounts',
          requestData: requestData,
          isNew: true
        });
      }
    } else {
      this.validator.showMessages();
    }
  }
  handleChangeFeedback = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  submitFeedback = (e) => {
    if (this.validator.fieldValid('feedback')) {
      const { feedback, customer } = this.state;
      const { sendEmailFeedback } = this.props;
      const source = "source:Advisor\nabout:Feedback form submission\n\n" + feedback;

      const feedbackJson = {
        "mail_to": customer.attributes.advisorSupportEmail,
        "feedback": source
      }
      sendEmailFeedback(feedbackJson);
      intercomTrackEvent(eventNames.submittedPaySetupMissingServicerAddressForm);
    } else {
      this.validator.showMessages();
    }
  }
  hideShowAddressSentModal = (status) => {
    this.setState({
      addressSentModal: status,
    })
  }
  hideShowAccountNumberWarningModal = status => {
    this.setState({
      accountNumberWarningModal: status
    })
  }
  hideShowPaymentAddressWarningModal = status => {
    this.setState({
      paymentAddressWarningModal: status
    })
  }
  hideShowAccountNumberWarningModalV2 = status => {
    this.setState({
      accountNumberWarningModalV2: status
    })
  }
  render() {
    const {
      advisorServicers,
      addressData,
      showAddresDiv,
      account_number,
      account_balance,
      monthly_payment,
      interest_rate,
      addressId,
      servicerAccountNumberErrors,
    } = this.state;
    const servicer_name = this.state.requestData.data.servicer_name ? this.state.requestData.data.servicer_name : "";
    return (
      <>
        <Header {...this.props} />

        <div className="G-page-background-color">

          <div className='G-page-main-container py-5'>
            <Breadcrumb list={paymentBreadcrumbData(false)} />
            <div className='G-grid11 p-4  bg-white rounded-lg Gborder-05 mt-4'>
              <div>
                <div className={`${S.circularDiv}`} >
                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.125 11.5H3.625V20.25H6.125V11.5ZM13.625 11.5H11.125V20.25H13.625V11.5ZM24.25 22.75H0.5V25.25H24.25V22.75ZM21.125 11.5H18.625V20.25H21.125V11.5ZM12.375 3.075L18.8875 6.5H5.8625L12.375 3.075ZM12.375 0.25L0.5 6.5V9H24.25V6.5L12.375 0.25Z" fill="var(--icon-color)" />
                  </svg>

                </div>
                <div className="my-2 Gt-slate-5">
                  <h4 className="Gfs-175 sans-semibold">Add an account</h4>
                  <p className="sans-medium">
                    Loan details such as your balance, interest rate and monthly <br />
                    payments are not shared with your employer.
                  </p>
                  <div className='mt-4'>
                    <p className='sans-semibold'>Where can you find your account information?</p>
                    <ul className={`${S.instructionList} pl-4`}>
                      <li className='py-1'>Check your mail: Servicers may periodically send a physical payment coupon</li>
                      <li className='py-1'>Check Online: Login to your servicer's website</li>
                      <li className='py-1'>Make a phone call: Call your servicer</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <Form.Group>
                  <Form.Label className='sans-medium'>Send Contributions to</Form.Label>

                  <Select
                    styles={selectStyles}
                    className="basic-single"
                    classNamePrefix="reactSelect"

                    isClearable={true}
                    onChange={this.changeLoanServicer.bind(this)}
                    isSearchable={true}
                    name="servicerId"
                    options={advisorServicers.map(item => {
                      return {
                        label: item.attributes?.friendly_name,
                        value: item.id
                      }
                    })}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='servicerId' fieldValue={this.state.servicerId} message='Servicer is required' />

                </Form.Group>
                {showAddresDiv ?
                  <>

                    <Form.Group className='my-4'>
                      <Form.Label className='sans-medium'>Loan Account Number</Form.Label>
                      <Form.Control className='text-black' type="text" onChange={(e) => this.handleInputChange(e)} placeholder="Enter account number" value={account_number} name={LoanParameters.accountNumber} />
                      <Form.Text className="text-muted">
                        <ValidatorMessage validator={this.validator} fieldName={LoanParameters.accountNumber} fieldValue={this.state.account_number} message='Account number is required' />

                      </Form.Text>
                    </Form.Group>

                    <Form.Group className='my-4'>
                      < NumberFormatInput label='Current Loan Balance' name={LoanParameters.accountBalance} value={account_balance} placeholder='Type Here' handleValueChange={(data) => this.handleAccountBalanceChange(data)} />

                      <ValidatorMessage validationType='required|numeric|min:1,num' validator={this.validator} fieldName={LoanParameters.accountBalance} fieldValue={this.state.account_balance} message='Account balance is required' customMessage={{ 'min': 'Account balance must be greater than 0' }} />

                    </Form.Group>

                    <Form.Group className='my-4'>
                      <NumberFormatInput label='Min. Monthly Payment' name={LoanParameters.monthlyPayment} value={monthly_payment} placeholder='Type here' handleValueChange={(data) => this.handleMonthlyPaymentChange(data)} />

                      <ValidatorMessage validator={this.validator} fieldName={LoanParameters.monthlyPayment} fieldValue={this.state.monthly_payment} message='Monthly payment is required' />

                    </Form.Group>
                    <Form.Group className='my-4'>
                      <NumberFormatInput label='Weighted Average Interest' suffix='%' prefix='' name={LoanParameters.interestRate} value={interest_rate} placeholder='Type here' handleValueChange={(data) => this.handleInterestRateChange(data)} />
                      <ValidatorMessage validator={this.validator} fieldName={LoanParameters.interestRate} fieldValue={interest_rate} message='Interest rate is required' />

                    </Form.Group>

                    <div className='mt-5'>
                      <h3 className='Gfs-125 Gt-slate-5'>Select your loan servicer's payment Address</h3>
                      <p className='sans-medium'> Don't see loan servicer's payment or billing address? <span className='Gt-pink-8 sans-semibold cursor-pointer' type='button' onClick={() => this.hideShowAddressSentModal(true)}>Get in touch</span></p>
                      <div>
                        {addressData ? addressData.map((item, index) => {
                          const selected = item.id === addressId ? `${S.selectedAddressBox}` : "";
                          return (
                            <div key={index}>
                              <div className={`${S.loanAddressBox} ${selected} rounded-lg my-2`} onClick={() => this.selectAddress(item)}>
                                <p className='mb-1'>{item.attributes.mail_to}</p>
                                <p className='mb-1'>{item.attributes.address}</p>
                              </div>
                            </div>)
                        }) : null}
                        <ValidatorMessage validator={this.validator} fieldName='addressId' fieldValue={this.state.addressId} message='Please select at least one address.' />

                      </div>
                    </div>

                  </> : null}

              </div>
            </div>

            <div className='d-flex justify-content-end my-3'>
              <button className='G-cancel-button mx-3' onClick={this.onCancel.bind(this)}>Go Back </button>
              <button className='G-orange-button' onClick={this.handleServerSideValidation.bind(this)}>Confirm & Save</button>
            </div>
          </div>
        </div>
        <Footer />

        <Modal size="lg" show={this.state.addressSentModal} onHide={() => this.hideShowAddressSentModal(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'>Tell us the address you send payments to.</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className="sans-medium">If the address you send your payments to is not listed, please let us know. A representative will get in contact with you shortly once it is added to our system. </p>
              <textarea
                type="text"
                rows="4"
                className={`form-control Gborder-1 ${S.placeholderText} `}
                placeholder="You can find this information on the physicial payment stub for your servicer."
                name="feedback"
                value={this.state.feedback}
                onChange={this.handleChangeFeedback.bind(this)}
              />
              <ValidatorMessage validator={this.validator} fieldName='feedback' fieldValue={this.state.feedback} message='You must submit something!' />
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={() => this.hideShowAddressSentModal(false)}>Cancel</button>
              <button onClick={this.submitFeedback.bind(this)} type="button" className="G-orange-button mob-separate-v6">Submit</button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Account Number Warning Modal*/}

        <Modal size="lg" show={this.state.accountNumberWarningModal} onHide={() => this.hideShowAccountNumberWarningModal(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'> Account Number Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className='sans-medium'>The account number you entered doesn’t match the account number found with your loan servicer. Please confirm your account number.</p>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={() => this.hideShowAccountNumberWarningModal(false)}> Revise Now</button>
              <button onClick={this.handleContinue.bind(this)} type="button" className="G-orange-button mob-separate-v6">Continue</button>
            </div>
          </Modal.Body>
        </Modal >

        {/* Payment Address Warning Modal*/}
        <Modal size="lg" show={this.state.paymentAddressWarningModal} onHide={() => this.hideShowPaymentAddressWarningModal(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'> Payment Address Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className='sans-semibold' >According to your account number, the Nelnet Payment Address should be:</p>
              <p className='sans-medium'>{this.state.nelnetSuggestedAddress ? this.state.nelnetSuggestedAddress.attributes.mail_to : ''}</p>
              <p className='sans-medium'>{this.state.nelnetSuggestedAddress ? this.state.nelnetSuggestedAddress.attributes.address : ''}</p>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={(e) => { intercomTrackEvent(eventNames.skippedPaySetupNelnetAddressValidation); this.hideShowPaymentAddressWarningModal(false) }}>Revise Now</button>
              <button onClick={(e) => { intercomTrackEvent(eventNames.clickedGoBackOnNelnetAddressValidation); this.handleContinue(e) }} type="button" className="G-orange-button mob-separate-v6">Continue</button>
            </div>
          </Modal.Body>
        </Modal >


        {/* Account Number Warning Modal*/}
        <Modal size="lg" show={this.state.accountNumberWarningModalV2} onHide={() => this.hideShowAccountNumberWarningModalV2(false)} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'> Account Number Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <div className='mb-4'>
              <p className="sans-semibold">{servicer_name} Account numbers typically:</p>
              <ul className="bullets">
                {servicerAccountNumberErrors ? servicerAccountNumberErrors.map((item, index) => {
                  return (<li className='sans-medium' key={index}>{item}</li>)
                }) : null}
              </ul>
              <p className='sans-medium'>If you're sure this is correct click Continue, otherwise click Revise Now to re-enter the correct account number.</p>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={(e) => { intercomTrackEvent(eventNames.clickedGoBackOnAccountNumberValidation); this.hideShowAccountNumberWarningModalV2(false) }}>Revise Now</button>
              <button onClick={(e) => { intercomTrackEvent(eventNames.skippedPaySetupAccountNumberValidation); this.handleContinue(e) }} type="button" className="G-orange-button mob-separate-v6">Continue</button>
            </div>
          </Modal.Body>
        </Modal >

        <Modal
          show={this.state.accountBalanceBelowThresholdModal}
          size="md"
          dialogClassName=""
          centered
        >
          <Modal.Body className="fincity-modal p-0">
            <div className="fincity-modal-title-bg d-flex flex-column justify-content-center pt-4 pb-3 pl-3">
              <h4 className="color-white text-left">Low Account Balance Alert</h4>
            </div>
            <form className="modal-form pt-4 pb-3 pl-3">
              <div className="modal-input-form ">
                Congrats, it looks like your account is nearly paid off! <br />
                To avoid overpayment, Vault will not send contributions to this account. <br />
                Do you have another servicer you would like to send your contributions to? <br />
              </div>

              <div className="faux-finicity-buttons">
                <Button
                  variant="light"
                  onClick={() => { this.balanceAlertModal("no") }}
                  className="c-button c-button-primary c-button-hollow-yellow"
                >
                  NO
                </Button>
                <Button variant="light" className="c-button c-button-gradient" onClick={() => { this.balanceAlertModal("yes") }} data-theme="vault" data-ember-action="" data-ember-action-340="340">
                  YES
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.fauxFinicityModalEnabled}
          size="lg"
          dialogClassName=""
          centered
        >
          <Modal.Body className="p-4">
            <div>

              <div className={`${S.circularDiv} mb-3`}>
                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.125 11.5H3.625V20.25H6.125V11.5ZM13.625 11.5H11.125V20.25H13.625V11.5ZM24.25 22.75H0.5V25.25H24.25V22.75ZM21.125 11.5H18.625V20.25H21.125V11.5ZM12.375 3.075L18.8875 6.5H5.8625L12.375 3.075ZM12.375 0.25L0.5 6.5V9H24.25V6.5L12.375 0.25Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <h3 className='Gfs-175 Gt-slate-5'>Bank Of Vault</h3>
            </div>

            <div className="sans-medium">
              Enter your Bank of Vault account information.
            </div>
            <div>
              <div className="form-group">
                <label for="usr">Username:</label>
                <input type="text" className="form-control" name="username" id="usr" placeholder='Enter username' onChange={this.handleInputChange.bind(this)} />
              </div>
              <div className="form-group">
                <label for="pwd">Password:</label>
                <input type="password" className="form-control" name="password" id="pwd" placeholder='Enter password' onChange={this.handleInputChange.bind(this)} />
              </div>

            </div>
            <div className="d-flex justify-content-end">
              <button
                type='button'
                onClick={this.onCancelFauxFinicity.bind(this)}
                className="G-cancel-button mx-3"
              >
                Cancel
              </button>
              <button className="G-orange-button" onClick={this.onSubmitFauxFinicity.bind(this)} >
                Next
              </button>
            </div>

          </Modal.Body>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    advisorServicers: state.advisor.advisorServicers,
    addresses: state.five_two_nine.getAddressData,
    validateAccountNumberResponse: state.advisor.validateAccountNumberResponse,
    emailFeedbackResponse: state.advisor.emailFeedbackResponse,
    customer: state.customer_array.customerData,
    servicer_accounts: state.five_two_nine.getServicerAccountsData,
    finicityConnectLink: state.advisor.finicityConnectLink?.data?.attributes?.link,
    employer: state.employer_array.employerData
  };
}
const mapDispatchToProps = {
  getAdvisorServicers,
  getServicerAccounts,
  getAddresses,
  validateAccountNumber,
  sendEmailFeedback,
  fetchCustomer,
  getFinicityConnectLink,
  fetchEmployer,
  postServicerAccounts,
  finicityConnectSuccess,
  postEditServicerAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(AddContribution);