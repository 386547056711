export function sortAdvisorServicers(a,b){  
  var nameA = a.attributes.friendly_name;
  var nameB = b.attributes.friendly_name;
  return nameA > nameB ? 1 : -1;  
};
export function sortLoanType(a,b){
  return a > b ? 1 : -1;
}
export function getServicerById(servicerId, servicers){
  return servicers.find(function(value) {
    return value.id===servicerId; 
  });
}