import React, { Component } from 'react';
import Header from "../Includes/Header/Header";
import Footer from "../Includes/Footer/Footer";


class CollegePlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null
    }
  }
  componentDidMount() {
    document.title = "Vault | College Planning"

    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = "quatromoney-application-script";
    script.src = "https://quatromoney-embed.pixeledge.io/widget.js";
    document.body.appendChild(script);
  }
  componentWillUnmount() {
    let script = document.getElementById("quatromoney-application-script");
    script.remove();
  }
  render() {

    return (
      <>
        <Header  {...this.props}/>
        <div className='G-page-background-color'>
          <div className="G-page-main-container my-5 Gborder-1 rounded-lg p-4 bg-white">
            <div id="quatromoney-widget"></div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default (CollegePlanning);