import Form from 'react-bootstrap/Form';
import S from './TextInput.module.css';

function TextInput({size='', noLabel, required=true, placeholder='', label='',id='', name='', value='', handleInputChange, handleBlur}) {
  return (
    <>
      {
        !noLabel ?
          <Form.Label className='Gfs-1' htmlFor={id}>{label}{required ? '*' : ''}</Form.Label> : null
      }
      <Form.Control
        size={size}
        type="text"
        id={id}
        aria-describedby="textInput"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleInputChange(e)}
        onBlur={(e) => handleBlur(e)}
        className={`${S.textInput}`}
      />
      <Form.Text id={id} muted>
      </Form.Text>
    </>
  );
}

export default TextInput;