import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Includes/Header/Header";
import Footer from "../Includes/Footer/Footer";
import "./PublicServiceLoanForgiveness.css";
import { fetchEmployer } from "../../actions/employerActions";
import { intercomTrackEvent } from "../customerStatus";
import eventNames from "../event-names-map";
import { getServicerAccounts } from '../../../src/actions/fiveTwoNineActions';
import Breadcrumb from "../shared/Breadcrumb/Breadcrumb";
import { AdviceAndToolPublicServiceForgivenessBreadcrumb } from "../Advisor/CommonBreadcrumb/Breadcrumb.constant";


class PublicServiceLoanForgiveness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: "",
      errors: null,
      servicerAccounts: []
    }
  }
  componentDidMount() {
    document.title = "Vault Advisor | Public Service Loan Forgiveness"
    const { getServicerAccounts } = this.props;
    intercomTrackEvent(eventNames.visitedPslfInfo);
    fetchEmployer();
    getServicerAccounts();
  }
  componentDidUpdate(prevProps) {
    if (this.props.employer !== prevProps.employer) {
      this.setState({
        employer: this.props.employer,
      });
    }
    if (prevProps.servicerAccounts !== this.props.servicerAccounts) {
      const servicerAccounts = this.props.servicerAccounts.data
      const servicerAccountsIncluded = this.props.servicerAccounts.included;
      let servicer_accounts = [];
      servicerAccounts.forEach((element, i) => {
        const servicerId = element.relationships.servicer.data?.id;

        element.attributes.servicer_account_id = element.id
        servicer_accounts[i] = element.attributes;
        servicer_accounts[i]['servicer'] = {};
        servicer_accounts[i]['statements'] = [];
        servicer_accounts[i]['transactions'] = [];
        if (element.relationships.servicer.data) {
          const servicer = servicerAccountsIncluded.find(item => item.id === servicerId);
          servicer_accounts[i]['servicer'] = servicer.attributes;
        }
        const relationshipsStatements = element.relationships.statements.data;
        relationshipsStatements.forEach((statement, index) => {
          const statementId = statement.id;

          const statements = servicerAccountsIncluded.find(item => item.id === statementId && item.type === 'statements');
          statements.attributes.statement_id = statements.id;
          servicer_accounts[i]['statements'].push(statements.attributes)
        })
      })
      this.setState({
        servicerAccounts: servicer_accounts
      })
    }

  }
  clickedDeniedPSLF = (e) => {
    intercomTrackEvent(eventNames.clickedDeniedPslf);
    window.open(
      "https://studentaid.ed.gov/sa/repay-loans/forgiveness-cancellation/public-service#denied",
      "_newtab"
    );
  };
  clickedClosedSchoolDischarge = (e) => {
    intercomTrackEvent(eventNames.clickedClosedSchoolDischarge);
    window.open(
      "https://studentaid.ed.gov/sa/repay-loans/forgiveness-cancellation/closed-school",
      "_newtab"
    );
  };
  clickedBorrowerDefense = (e) => {
    intercomTrackEvent(eventNames.clickedBorrowerDefense);
    window.open(
      "https://studentaid.ed.gov/sa/repay-loans/forgiveness-cancellation/borrower-defense",
      "_newtab"
    );
  };
  clickedUnderstandPslfApplication = (e) => {
    intercomTrackEvent(eventNames.clickedUnderstandPslfApplication);
    window.open(
      "https://studentaid.ed.gov/sa/repay-loans/forgiveness-cancellation/public-service#apply",
      "_newtab"
    );
  };
  clickedPslfEmployerAssessmentTool = (e) => {
    intercomTrackEvent(eventNames.clickedPslfEmployerAssessmentTool);
    window.open(
      "https://studentaid.gov/myDirectLoan/pslfFlow.action?&_ga=2.226367258.1454035474.1574792238-1458071911.1544741492#!/pslf/launch",
      "_newtab"
    );
  };
  render() {
    return (
     
      <>
        <Header {...this.props} />
        <div className="G-page-color-background">
          <div className='G-page-main-container my-5'>
            <Breadcrumb list={AdviceAndToolPublicServiceForgivenessBreadcrumb} />
            <div className=" p-4 Gborder-05 bg-white  my-3 rounded-lg">
              <div className='G-grid11-2'>
                <div>
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M30 41.6875L28.1875 40.0375C21.75 34.2 17.5 30.35 17.5 25.625C17.5 21.775 20.525 18.75 24.375 18.75C26.55 18.75 28.6375 19.7625 30 21.3625C31.3625 19.7625 33.45 18.75 35.625 18.75C39.475 18.75 42.5 21.775 42.5 25.625C42.5 30.35 38.25 34.2 31.8125 40.05L30 41.6875Z" fill="var(--icon-color)" />
                    </svg>

                  </div>
                  <h4>Public Service Loan Forgiveness</h4>
                  <p className="sans-medium">
                    The Public Service Loan Forgiveness (PSLF) Program forgives the remaining balance on your direct or consolidated federal loans after you have made 120 (10 years) payments under a qualifying repayment plan while working full-time for a qualifying employer. Your loan will only be forgiven if you meet all PSLF Program eligibility conditions.
                  </p>
                </div>
              </div>
              <div className='G-grid11-2 mt-5'>
                <div>
                  <label className='Gt-pink-8'>UPDATED 10/2021</label>
                  <h4>
                    How does the CARES Act and automatic administrative forbearance of my payments affect my PSLF?
                  </h4>
                  <p className="sans-medium">
                    As long as you continue to meet all other PSLF requirements* during the administrative forbearance period from *March 13, 2020-January 31, 2022*, you will not have to make payments to your federal student loans. During this automatic payment suspension for federal loans, your $0 payments will count as qualifying payments towards your PSLF progress.
                  </p>
                  <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                    <div className='mr-3'>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                      </svg>
                    </div>
                    <div>
                      <p className='sans-semibold mb-0'>
                        PSLF requirements: You must have a Direct Loan, be on a qualifying repayment plan prior to March 13,2020 and work full-time for a qualifying employer.
                      </p>
                    </div>
                  </div>
                  <label className='Gfs-15 Gt-pink-8'>See if you qualify</label>
                  <p className="sans-medium">
                    To qualify for PSLF, you must...
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Work full-time for a government agency or for certain types of nonprofit organizations
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Have Direct Loans (or consolidate other federal loans)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Repay your loans on an income-driven repayment plan
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Make 120 qualifying payments (10 years)
                    </li>
                  </ul>
                  <p className="sans-medium">
                    Do you qualify under your current employer?
                  </p>
                  <p className="sans-medium">
                    Full-time Employment with the following types of organizations qualify for PSLF:
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      All government organizations (federal, state, local, or tribal)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Non profit organizations that are tax-exempt under Section 501(c)(3)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Other non profit organizations that are not tax-exempt
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Serving as a full-time AmeriCorps or Peace Corps volunteer
                    </li>
                  </ul>
                  <p className="sans-medium">
                    Do you qualify under your current repayment plan?
                  </p>
                  <p className="sans-medium">
                    PSLF Qualifying Repayment Plans:
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Saving on A Valuable Education (SAVE Plan)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Pay As You Earn Plan (PAYE Plan)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Income-Based Repayment Plan (IBR Plan)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Income-Contingent Repayment Plan (ICR Plan)
                    </li>
                  </ul>
                  <p className="sans-medium">
                    Do your loans qualify for PSLF?
                  </p>
                  <p className="sans-medium">
                    Only Direct Loans are eligible for PSLF.
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Payments that you make on a new Direct Consolidation Loan can be counted toward the 120 payments. (Payments made before you consolidated don’t count.)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      If you borrowed before July 1, 2010, some or all of your loans may have been made under an older federal student loan program called the Federal Family Education Loan (FFEL) Program. These are only eligible if you consolidate those loans.
                    </li>
                  </ul>
                </div>

                <div>
                  <div className='Gborder-05 p-4 rounded-lg'>
                    <label className='Gfs-15'>Related Topics</label>
                    <ul className='G-ul-bullets'>
                      <li className="sans-semibold ml-4 mb-2 Gt-pink-8 G-cursor-pointer" onClick={this.clickedDeniedPSLF.bind(this)}>
                        Denied PSLF, now what?
                      </li>
                      <li className="sans-semibold ml-4 mb-2 Gt-pink-8 G-cursor-pointer" onClick={this.clickedClosedSchoolDischarge.bind(this)}>
                        Closed school discharge
                      </li>
                      <li className="sans-semibold ml-4 mb-2 Gt-pink-8 G-cursor-pointer" onClick={this.clickedBorrowerDefense.bind(this)}>
                        Borrower defence to repayment
                      </li>
                      <li className="sans-semibold ml-4 mb-2 Gt-pink-8 G-cursor-pointer" onClick={this.clickedUnderstandPslfApplication.bind(
                        this
                      )}>
                        Understand the PSLF application
                      </li>
                    </ul>
                    <label className='Gfs-15'>Helpful Tools</label><br />
                    <label className="sans-semibold mb-2 Gt-pink-8 G-cursor-pointer" onClick={this.clickedPslfEmployerAssessmentTool.bind(
                      this
                    )}>
                      PSLF Employer Assessment Tool
                    </label>
                  </div>
                </div>
              </div>
              <div className='G-grid11-2 mt-5'>
                <div>
                  <h4>
                    Temporary Expanded PSLF (TEPSLF)
                  </h4>
                  <p className="sans-medium">
                    The Consolidated Appropriations Act expanded limited opportunities in which an individual may become eligible for Public Service Loan Forgiveness (PSLF). This expansion is called Temporary Expanded PSLF or TEPSLF.
                  </p>
                  <p className="sans-medium">
                    TEPSLF is designed to help student loan borrowers who have made 120 on-time, monthly student loan payments while working for an eligible employer, but were previously rejected for PSLF because some or all of their payments were made under a non-qualifying repayment plan.
                  </p>
                  <p className="sans-medium">
                    This program has limited funding and individuals qualify on a first come, first served basis. Once all the funds have been allocated, this program will end.
                  </p>
                  <p className="sans-medium">
                    If you believe you qualify for TEPSLF based on the below requirements, we advise you to apply as soon as possible.
                  </p>
                  <p className="sans-medium">
                    See if you qualify
                  </p>
                  <p className="sans-medium">
                    To qualify for TEPSLF, you must...
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Have already applied for PSLF and been denied because some or all of your payments were not made under a qualifying repayment plan for PSLF.*
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Have worked full-time for a government agency or for certain types of nonprofit organizations for at least 10 years.
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Have made 120 on-time, monthly payments whilst working for an eligible employer. These payments have to have been made after October 7, 2007.
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Have Direct Loans. FFEL Loans, Perkins Loans, Defaulted direct loans, Parent PLUS loans and private loans do not qualify for forgiveness under TEPSLF.
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Have recent payments that paid at least as much as you would have what you would have paid under an income-driven repayment plan. This means that the amount you paid 12 months before applying for TEPSLF and the final payment you made prior to applying must have been at least as much as you would have paid had you been enrolled in an income-driven repayment plan eligible for PSLF.
                    </li>
                  </ul>
                  <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                    <div className='mr-3'>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                      </svg>
                    </div>
                    <div>
                      <p className='sans-semibold mb-0'>
                        If you believe you may qualify for TEPSLF but have not yet applied for PSLF, and know you will be denied, you need to do so anyway in order to begin your TEPSLF application process.
                      </p>
                    </div>
                  </div>
                  <p className="sans-medium">
                    If you meet all the above requirements and have been enrolled in one of the following repayment plans, you are now eligible for TEPSLF:
                  </p>
                  <p className="sans-medium">
                    TEPSLF Qualifying Repayment Programs
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Graduated Repayment Plan
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Extended Repayment Plan
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Consolidation Standard Repayment Plan
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Consolidated Graduated Repayment Plan
                    </li>
                  </ul>
                  <p className="sans-medium">
                    How to apply for TEPSLF
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Completely fill out the <a className="text-black text-decoration-none cancelLink" rel="noreferrer" target="_blank" href="https://studentaid.gov/sites/default/files/public-service-application-for-forgiveness.pdf"><u>PSLF and TEPSLF form</u></a> (keep a copy for your records!)
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Submit the form online or by mail to:
                    </li>
                  </ul>
                  <p className="sans-medium">
                    U.S. Department of Education<br />
                    FedLoan Servicing<br />
                    P.O. Box 69184<br />
                    Harrisburg, PA 17106-9184
                  </p>
                  <p className="sans-medium">
                    Once you have applied, you will need to wait to hear back from FedLoan Servicing. This may take some time. It is possible that they may request more information on your application. If this is the case you have 21 days to provide them that information or your application request will be cancelled.
                  </p>
                  <p className="sans-medium">
                    If you are approved your remaining loan balance and any outstanding interest will be forgiven.
                  </p>
                  <p className="sans-medium">
                    If you are denied, they should provide you with a reason for denial. If you believe you should be eligible after denial, you may want to follow up with the <a className="text-black text-decoration-none cancelLink" rel="noreferrer" target="_blank" href="https://studentaid.gov/feedback-ombudsman/disputes/prepare"><u>FSA Ombudsman</u></a>.
                  </p>
                  <h4>
                    Updates
                  </h4>
                  <p className="sans-medium">
                    On Oct. 6, 2021, the U.S. Department of Education (ED) announced a limited waiver for PSLF.
                  </p>
                  <p className="sans-medium">
                    For a limited time, borrowers may receive credit towards PSLF for past payments made on loans that would otherwise not qualify for the program. These include FFEL Loans, Perkins loans and any other federal loans that are not Direct Loans. In order to qualify under these conditions, you must consolidate these types of loans into a Direct Consolidation Loan.
                  </p>
                  <p className="sans-medium">
                    This change will apply to student loan borrowers with Direct Loans, those who have already consolidated into the Direct Loan Program, and those who consolidate into the Direct Loan Program by Oct. 31, 2022.
                  </p>
                  <p className="sans-medium">
                    Parent PLUS loans are not eligible under this limited PSLF waiver.
                  </p>
                  <p className="sans-medium">
                    Types of Loans that now qualify under the limited waiver:
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Federal Family Education Loan (FFEL) Program loans
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Federal Perkins Loans
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Any other federal loans that are not Direct Loans
                    </li>
                  </ul>
                  <p className="sans-medium">
                    See if you qualify
                  </p>
                  <p className="sans-medium">
                    In order to qualify under the waiver you must...
                  </p>
                  <ul className='G-ul-bullets'>
                    <li className="sans-medium ml-4 mb-2">
                      Have worked full-time for a qualifying employer when prior payments were made.
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      Consolidate FFEL loans, Perkins loans or any other federal loans that are not direct into a Direct Consolidation Loan by October 31, 2022.
                    </li>
                    <li className="sans-medium ml-4 mb-2">
                      File a <a className="text-black text-decoration-none cancelLink" rel="noreferrer" target="_blank" href="https://studentaid.gov/pslf"><u>Public Service Loan Forgiveness (PSLF) & Temporary Expanded PSLF (TEPSLF) Certification & Application (PSLF form)</u></a> for any period for which you may receive additional qualifying payments.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div >
        <Footer />
      </>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    employer: state.employer_array.employerData,
    errors: state.errors,
    servicerAccounts: state.five_two_nine.getServicerAccountsData,
  };
};
const mapDispatchToProps = {
  fetchEmployer,
  getServicerAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicServiceLoanForgiveness);
