import React, { useEffect, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchFivetwonineTerms, acceptFivetwonineTerms } from '../../../actions/fiveTwoNineActions';
import { fetchCustomer } from '../../../actions/customerActions';
import { responseMessage } from '../../../utils/alert';

const FiveTwoNineAgreement = ({ fetchCustomer, fetchFivetwonineTerms, acceptFivetwonineTerms, customerData, acceptFiveTwoNineAgreementResponse, fiveTwoNineAgreementData, errors, onCloseButtonClick, onAcceptButtonClickSuccess, alreadyAcceptedRoute }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [agreementDocument, setAgreementDocument] = useState(null);
    const [termsData, setTermsData] = useState(null);
    const [disableAcceptButton, setDisableAcceptButton] = useState(true);

    const closeModal = () => {
        setIsOpen(false)
    }
    useEffect(() => {
        fetchCustomer();
    }, [])

    useEffect(() => {
        if (customerData?.attributes?.any_fivetwonine_terms === false) { //* checking to show modal or not
            fetchFivetwonineTerms();
            setIsOpen(true)
        } else if (customerData?.attributes?.any_fivetwonine_terms === true) {
            if (alreadyAcceptedRoute) {
                alreadyAcceptedRoute();
            }
        }
    }, [customerData])

    useEffect(() => {
        if (fiveTwoNineAgreementData === undefined) {
            setAgreementDocument(null);
            setTermsData(null)
            responseMessage('error', 'Five Two Nine Terms and Conditions not found.');
        } else {
            setAgreementDocument(fiveTwoNineAgreementData?.attributes?.document);
            setTermsData(fiveTwoNineAgreementData)
        }
    }, [fiveTwoNineAgreementData])

    useEffect(() => {
        //* success callback of accept button
        closeModal();
        if (onAcceptButtonClickSuccess && Object.keys(acceptFiveTwoNineAgreementResponse).length !== 0) {
            onAcceptButtonClickSuccess();
        }
    }, [acceptFiveTwoNineAgreementResponse])

    const handleScroll = (e) => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop < element.clientHeight + 300) {
            // do something at end of scroll
            setDisableAcceptButton(false)
        }
    }

    const acceptAgreement = () => {
        const jsonData = {
            data: {
                relationships: {
                    legal_agreement: {
                        data: {
                            type: termsData.type,
                            id: termsData.id,
                        },
                    },
                    customer: {
                        data: null,
                    },
                },
                type: "user-agreements",
            },
        };
        acceptFivetwonineTerms(jsonData);
    }

    return (
        <Modal
            size="xl"
            show={isOpen}
            onHide={() => { closeModal(); if (onCloseButtonClick) onCloseButtonClick() }}
            animation={false}
            backdrop="static"
            keyboard={false}
            scrollable={true}
        >
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className=''>
                    <div className="d-flex align-items-center">
                        <div className='pinkCircularDiv'>
                            <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 18H15V20.5H5V18ZM5 13H15V15.5H5V13ZM12.5 0.5H2.5C1.125 0.5 0 1.625 0 3V23C0 24.375 1.1125 25.5 2.4875 25.5H17.5C18.875 25.5 20 24.375 20 23V8L12.5 0.5ZM17.5 23H2.5V3H11.25V9.25H17.5V23Z" fill="var(--icon-color)" />
                            </svg>

                        </div>
                        <h2 className='Gfs-225 Gt-slate-5 ml-3 mb-0'>Terms of Service</h2>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='custom-modal-body' onScroll={(e) => handleScroll(e)}>
                <div className="max-height-custom" dangerouslySetInnerHTML={{ __html: agreementDocument }} />
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button className="G-orange-button" variant="primary" disabled={disableAcceptButton} onClick={acceptAgreement}>
                    I Accept the terms
                </Button>
            </Modal.Footer>
        </Modal >
    )
}


FiveTwoNineAgreement.propTypes = {
    fetchFivetwonineTerms: PropTypes.func.isRequired,
    acceptFivetwonineTerms: PropTypes.func.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    fiveTwoNineAgreementData: state.five_two_nine.fiveTwoNineTermsData,
    acceptFiveTwoNineAgreementResponse: state.five_two_nine.acceptFivetwonineTerms,
    customerData: state.customer_array.customerData,
    errors: state.errors,
});

export default connect(mapStateToProps, { fetchFivetwonineTerms, acceptFivetwonineTerms, fetchCustomer })(FiveTwoNineAgreement);

