import { headerSelector } from './../../Includes/Header/Helpers/serviceSelector';
import { Question, } from 'react-bootstrap-icons';

const getButtonLinksByAccountType = () => {
  const headerName = headerSelector();

  function redirectToFaq() {
    const faqLink = headerName === 'match' ? '/match/faq' : '/dashboard/faqs';
    window.location = faqLink;
  }

  let buttonProperties = [];
  if (headerName === 'fiveTwoNine' || headerName === 'match') {
    buttonProperties.push({
      title: 'FAQ', icon: <Question className='mr-3 mb-1' size={26} />, onClick: redirectToFaq
    })
  }
  buttonProperties.push({ title: 'CUSTOMER SUPPORT', icon: <SupportSvg />, onClick: null })
  return buttonProperties;
}

const SupportSvg = () => (
  <svg className='mr-3' width="25" height="25" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.33398 15.7265V13.3265C2.99398 13.1532 0.333984 10.3798 0.333984 6.99984C0.333984 3.5065 3.17398 0.666504 6.66732 0.666504C10.1607 0.666504 13.0007 3.5065 13.0007 6.99984C13.0007 10.2998 10.7073 13.6198 7.28732 15.2665L6.33398 15.7265ZM6.66732 1.99984C3.90732 1.99984 1.66732 4.23984 1.66732 6.99984C1.66732 9.75984 3.90732 11.9998 6.66732 11.9998H7.66732V13.5332C10.094 11.9998 11.6673 9.47984 11.6673 6.99984C11.6673 4.23984 9.42732 1.99984 6.66732 1.99984ZM6.00065 9.6665H7.33398V10.9998H6.00065V9.6665ZM7.33398 8.6665H6.00065C6.00065 6.49984 8.00065 6.6665 8.00065 5.33317C8.00065 4.59984 7.40065 3.99984 6.66732 3.99984C5.93398 3.99984 5.33398 4.59984 5.33398 5.33317H4.00065C4.00065 3.85984 5.19398 2.6665 6.66732 2.6665C8.14065 2.6665 9.33398 3.85984 9.33398 5.33317C9.33398 6.99984 7.33398 7.1665 7.33398 8.6665Z" fill="#2E2E2E" />
  </svg>
)


export default getButtonLinksByAccountType;