import React, { useEffect } from 'react';
import Header from '../Includes/Header/Header';
import dashboardData from '../Includes/Header/data/dashboardData.json'
import { headerSelector } from '../Includes/Header/Helpers/serviceSelector';
import Error404Page from './Error404Page';
import ErrorPage from './ErrorPage';

const Error = () => {
  useEffect(() => {
    document.title = "Vault | Error"
  }, [])

  let $url = "#";
  const headerName = headerSelector();
  let headerSelectedData = [];
  headerSelectedData = dashboardData.filter((x) => x.service === headerName)
  if (headerSelectedData.length > 0) {
    $url = headerSelectedData[0].data[0].headerPath
  }
  if (headerName === 'advisorOnly') {
    $url = '/advice-and-tools/dashboard'
  }
  if (headerName === 'fiveTwoNine' && localStorage.getItem('hasAdvisor') === 'true' && (localStorage.getItem('selectedService') === 'advisor' || (localStorage.getItem('selectedService') === 'null' && localStorage.getItem('advisorFirst') === 'true'))) {
    $url = '/advice-and-tools/dashboard'
  }
  const errorTypeBoundary = window.location.href.includes("fromErrorBoundary=true");
  return (
    <>
      <Header />
      {errorTypeBoundary ? <ErrorPage url={$url} /> : <Error404Page url={$url} />}
    </>
  )
}

export default Error;