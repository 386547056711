import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";

import ValidatorMessage from '../shared/ValidatorMessage/ValidatorMessage';
import style from './Login.module.css'
import LoginCarousel from '../shared/LoginCarousel/LoginCarousel';
import Header from '../Includes/Header/Header';
import Footer from "../Includes/Footer/Footer";
import SignInVaultLogo from './../shared/VaultLogo/SignInVaultLogo';
import PartnerLogo from '../shared/PartnerLogo/PartnerLogo';

const LoginPage = ({ state, setState, loginUser, partnerLogo }) => {

  const validator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();


  function showValidationMessage(event) {
    if (validator.current.fieldValid(event.target.name)) {
      validator.current.hideMessageFor(event.target.name);
      forceUpdate(1)
      setState({
        ...state,
        validationError: {
          ...state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      validator.current.showMessageFor(event.target.name);
      forceUpdate(1)
      setState({
        ...state,
        validationError: {
          ...state.validationError,
          [event.target.name]: true
        }
      });
    }
  }

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleLogin = (e) => {
    if (validator.current.allValid()) {
      const jsonRequest = {
        email: state.email,
        password: state.password
      }
      loginUser(jsonRequest);
    } else {
      validator.current.showMessages();
    }
  }

  return (
    <>
      <Header />
      <div className="G-page-background-color">
        <div className="bg-white w-100 m-0">
          <div className='G-grid115'>
            <LoginCarousel />
            <div className={'d-flex flex-column justify-content-center mx-auto ' + style.loginFormContainer}>
              <div className='mb-4'>
                {partnerLogo ? 
                <PartnerLogo src={partnerLogo} width="70px" /> 
                :  <SignInVaultLogo />}
              </div>
              <Form.Group className='mb-0'>
                <Form.Label className="">Email</Form.Label>
                <Form.Control type="text" name='email' placeholder="Email" onChange={(e) => handleInputChange(e)} onBlur={(e) => showValidationMessage(e)} />
                <ValidatorMessage validator={validator.current} fieldName='email' fieldValue={state.email} message='* Email is required' />
              </Form.Group>
              <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                <div className='mr-3'>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                  </svg>
                </div>
                <div>
                  <p className='sans-semibold mb-0'>
                    Your User Login is the email address where you received your invitation — remember to accept your invitation
                  </p>
                </div>
              </div>
              <Form.Group className='mb-4'>
                <Form.Label className="">Password</Form.Label>
                <Form.Control type="password" name='password' placeholder="Password" onChange={(e) => handleInputChange(e)} onBlur={(e) => showValidationMessage(e)} />
                <ValidatorMessage validator={validator.current} fieldName='password' fieldValue={state.password} message='* Password is required' />
              </Form.Group>
              <Link className='mb-2 G-link cancelLink text-decoration-none G-cursor-pointer' to="password/reset/request">I forgot my Password</Link>
              <div className='my-2'>
                <button onClick={handleLogin} className="bg-transparent border-0 G-orange-button ">
                  Sign in
                </button>
                <p className="sans-medium my-4">Need to set up your account? If your employer offers Vault, check your email for an invitation from genius@vault.co or email <a href="mailto:genius@vault.co" className='G-link cancelLink text-decoration-none G-cursor-pointer sans-semibold'>genius@vault.co</a> for help.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer removeMarginTop={true} />
    </>
  )
}

export default LoginPage;