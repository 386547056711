import React from 'react';
import S from './NewYorkLifeLoginForm.module.css'
import nyl from '../../../src/common/img/nyl-sso2.png'
import config from '../../config'

const loginUrl = config.sso.nylSamlEndpoint;
const NewYorkLifeLoginForm =
  <div className={S.login}>
    <img
      className={S.logo}
      width="300px"
      src={nyl}
      alt="New York Life"
    />

    <h2>in partnership with Vault</h2>

    <div className={S.loginLinks}>
      <button
        className={S.loginButton}
        onClick={()=>{ window.open(loginUrl) }}
      >
        Login with New York Life
      </button>
      <hr />
      <a
        href="https://app.vault.co"
        target="_blank"
        rel="noopener noreferrer"
        className={S.vaultLoginBtn}
      >
        Not an employee of New York Life?  Log into the Vault Platform.
      </a>
    </div>
  </div>


export default NewYorkLifeLoginForm;