import {
  GET_TEST_LOANS,
  GET_INDIVIDUAL_CONTRIBUTION,
  SUBMIT_CONTRIBUTION,
  GET_BENEFICIARY_PROFILES,
  ADD_FNF_CONTRIBUTION,
  DELETE_INVITE,
  SEND_INVITE,
  GET_INVITATIONS,
  GET_INVITEQUOTA,
  UPDATE_SELECTED_SERVICE,
  GET_ELIGIBILITYRESULTS,
  GET_LOANS,
  GET_DISBURSEMENTS,
  POST_SERVICER,
  POST_SERVICER_ACCOUNTS,
  GET_ADDRESSES,
  GET_SERVICERS,
  GET_SERVICER_ACCOUNTS,
  ACCEPT_FIVETWONINE_TERMS,
  FETCH_FIVETWONINE_TERMS,
  POST_BENEFICIARY_PROFILE,
  GET_BENEFICIARY_PROFILE,
  SEND_INVITE_TO_FNF_CONTRIBUTOR,
  GET_CONTRIBUTIONS,
  SET_SELECTED_CONTRIBUTION_ID_TO_THANK,
  POST_THANK_YOU_TO_CONTRIBUTOR,
  EDIT_BENEFICIARY_PROFILE,
  RESET_LOANS_REDUCER,
  GET_BENEFICIARY_PROFILE_AVATARS,
  POST_TRACKABLE_EVENTS,
  POST_FIVETWONINE_ADDRESS
} from "../actions/types";

const initialState = {
  fiveTwoNineTermsData: {},
  acceptFivetwonineTerms:{},
  getServicerAccountsData:[],
  getAddressData:[],
  servicerAccountsData:[],
  postServicerData:{},
  disbursements:[],
  loans:[],
  eligibilityResults:[],
  updateServiceResponse:[],
  inviteQuota:[],
  invitations:[],
  sendInvite:[],
  deleteInvite:{},
  testloans:[],
  friendsAndFamilyData: {
    postBeneficiaryProfileResponse: {},
    editBeneficiaryProfileResponse: {},
    beneficiaryProfile: {},
    sendInviteToFnfContributor: {},
    contributions: [],
    selectedContributionIdToThank: "",
    postThankYouToContributorResponse: {},
  },
  addFnFContributionResponse: null,
  beneficiaryProfiles: null,
  submitContributionResponse: null,
  individualContributionResponse: null,
  beneficiaryProfileAvatars: [],
  getFiveTwoNinePlanAddress: [],
};

export default function fiveTwoNineReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FIVETWONINE_TERMS:
      return {
        ...state,
        fiveTwoNineTermsData: action.payload,
      };
    case ACCEPT_FIVETWONINE_TERMS:
      return {
        ...state,
        acceptFivetwonineTerms: action.payload,
      };
    case GET_SERVICER_ACCOUNTS:
      return {
        ...state,
        getServicerAccountsData: action.payload,
      };
    case GET_SERVICERS:
      return {
        ...state,
        getServicersData: action.payload,
      };
    case GET_ADDRESSES:
      return {
        ...state,
        getAddressData: action.payload,
      };
    case POST_SERVICER_ACCOUNTS:
      return {
        ...state,
        servicerAccountsData: action.payload,
      };
    case POST_SERVICER:
      return {
        ...state,
        postServicerData: action.payload,
      };
    case GET_DISBURSEMENTS:
      return {
        ...state,
        disbursements: action.payload,
      };
    case GET_LOANS:
      return {
        ...state,
        loans: action.payload,
      };
    case GET_ELIGIBILITYRESULTS:
      return {
        ...state,
        eligibilityResults: action.payload,
      };
    case UPDATE_SELECTED_SERVICE:
      return {
        ...state,
        updateServiceResponse: action.payload,
      };
    case GET_INVITEQUOTA:
      return {
        ...state,
        inviteQuota: action.payload,
      };
    case GET_INVITATIONS:
      return {
        ...state,
        invitations: action.payload,
      };
    case SEND_INVITE:
      return {
        ...state,
        sendInvite: action.payload,
      };
    case DELETE_INVITE:
      return {
        ...state,
        deleteInvite: action.payload
      };
    case GET_TEST_LOANS:
      return {
        ...state,
        testloans: action.payload
      };                              
    case POST_BENEFICIARY_PROFILE:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          postBeneficiaryProfileResponse: action.payload,
        },
      };
    case EDIT_BENEFICIARY_PROFILE:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          editBeneficiaryProfileResponse: action.payload,
        },
      };
    case GET_BENEFICIARY_PROFILE:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          beneficiaryProfile: action.payload,
        },
      };
    case SEND_INVITE_TO_FNF_CONTRIBUTOR:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          sendInviteToFnfContributor: action.payload,
        },
      };
    case GET_CONTRIBUTIONS:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          contributions: action.payload,
        },
      };
    case SET_SELECTED_CONTRIBUTION_ID_TO_THANK:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          selectedContributionIdToThank: action.payload,
        },
      };
    case ADD_FNF_CONTRIBUTION:
      return {
        ...state,
        addFnFContributionResponse: action.payload,
      };
    case GET_BENEFICIARY_PROFILES:
      return {
        ...state,
        beneficiaryProfiles: action.payload,
      };
    case POST_THANK_YOU_TO_CONTRIBUTOR:
      return {
        ...state,
        friendsAndFamilyData: {
          ...state.friendsAndFamilyData,
          postThankYouToContributorResponse: action.payload,
        },
      };
    case SUBMIT_CONTRIBUTION:
      return {
        ...state,
        submitContributionResponse: action.payload,
      };
    case GET_INDIVIDUAL_CONTRIBUTION:
      return {
        ...state,
        individualContributionResponse: action.payload,
      };
    case RESET_LOANS_REDUCER:
      return {
        ...state,
        loans: [],
      };    
    case GET_BENEFICIARY_PROFILE_AVATARS:
      return {
        ...state,
        beneficiaryProfileAvatars: action.payload,
      };
    case POST_TRACKABLE_EVENTS:
      return {
        ...state,
        trackableEvents: action.payload,
      };
    case POST_FIVETWONINE_ADDRESS:
      return {
        ...state,
        getFiveTwoNinePlanAddress: action.payload,
      };
    default:
      return state;
  }
}
