import React, { Component } from 'react';
import { formatDateUsingMoment, monthDateYearSlash } from './../../utils/dateUtil';

import FileList from '../shared/FileList/FileList';
import PreviousFileList from '../shared/PreviousFileList/PreviousFileList';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import { requestFormBreadcrumb } from './TuitionReimbursement.constant';
import PlusCircle from 'react-bootstrap-icons/dist/icons/plus-circle';
import DashCircle from 'react-bootstrap-icons/dist/icons/dash-circle';

class TuitionReimbursementConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPass: false
    }
  }
  componentDidMount() {
    document.title = "Vault | Tuition Reimbursement Confirmation"
  }
  handleBackThree = (e) => {
    this.props.onClickHandleBackThree(e)
  }
  handleSaveTR = (e) => {
    this.props.onClickhandleSaveTR(e)
  }
  showHidePass() {
    this.setState({
      showPass: true
    })
  }
  render() {
    let { previousReceiptImages, previousCompletionImages, receiptFile, completionFile } = this.props.data;
    const receiptFilesPresent = receiptFile.length > 0 || null;
    const previousReceiptFilesPresent = previousReceiptImages?.length > 0 || null;
    const completionFilesPresent = completionFile.length > 0 || null;
    const previousCompletionFilesPresent = previousCompletionImages?.length > 0 || null;
    return (
      <>
        <Breadcrumb list={requestFormBreadcrumb()} />
        <div className='p-4 bg-white rounded-lg Gborder-1'>
          <h2 className='Gfs-225 mb-5'>Tuition Reimbursement Request Form</h2>

          <div className='G-grid11-2'>
            <div>
              <div className='pinkCircularDiv mb-3'>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9875 0.5C6.0875 0.5 0.5 6.1 0.5 13C0.5 19.9 6.0875 25.5 12.9875 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 12.9875 0.5ZM13 23C7.475 23 3 18.525 3 13C3 7.475 7.475 3 13 3C18.525 3 23 7.475 23 13C23 18.525 18.525 23 13 23ZM13.625 6.75H11.75V14.25L18.3125 18.1875L19.25 16.65L13.625 13.3125V6.75Z" fill="var(--icon-color)" />
                </svg>

              </div>
              <h3 className='Gfs-175 Gt-slate-5 my-2'>Review your details</h3>
              <p className='sans-medium'> Your employer will use the following information to confirm the request is eligible for reimbursement. </p>
            </div>
            <div>
              <div id='accordion'>
                <div className='rounded-lg Gborder-1 p-3'>
                  <div className='d-flex justify-content-between align-items-baseline G-pointer' title='Toggle Expansion' data-toggle="collapse" aria-expanded="true" href="#collapseBasicInfo">
                    <p className='Gfs-125 mb-0'>Basic Information</p>
                    <div >
                      <span role='button' className='chevron-circle-up Gfs-175'><PlusCircle size={20} /></span> <span role='button' className='chevron-circle-down Gfs-175'><DashCircle size={20} /></span>
                    </div>
                  </div>
                  <div id="collapseBasicInfo" className='collapse show'>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>First Name</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.first_name}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Last Name</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.last_name}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Title</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.title}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Department</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.department}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Type of Education Expense</p>
                      {this.props.data.educationExpensesData.filter(item => item.attributes.id === this.props.data.education_expense_id).map(item =>
                        <p className='Gfs-125 sans-medium' key={item.attributes.id}>{item.attributes.name}</p>)}
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Notes for Manager</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.notes_manager ? this.props.data.notes_manager : '-'}</p>
                    </div>

                  </div>
                </div>
                <div className='rounded-lg Gborder-1 p-3 my-4'>
                  <div className='d-flex justify-content-between align-items-baseline G-pointer' title='Toggle Expansion' data-toggle='collapse' aria-expanded="true" href="#collapseTuitionDetails">
                    <p className='Gfs-125 sans-semibold mb-0'>Tuition Details</p>
                    <div>
                      <span role='button' className='chevron-circle-up  Gfs-175'><PlusCircle size={20} /></span> <span role='button' className='chevron-circle-down Gfs-175'><DashCircle size={20} /></span>
                    </div>
                  </div>
                  <div id='collapseTuitionDetails' className='collapse show' >
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>School/Institution Name</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.school_name}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Course Name</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.course_name}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Description</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.description}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Reimbursement Amount</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.amount}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Course Start Date</p>
                      <p className='Gfs-125 sans-medium'>{formatDateUsingMoment(this.props.data.start_date, monthDateYearSlash)}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Course Completion Date</p>
                      <p className='Gfs-125 sans-medium'>{formatDateUsingMoment(this.props.data.completion_date, monthDateYearSlash)}</p>
                    </div>

                    {previousReceiptFilesPresent || receiptFilesPresent ?
                      <div className='my-3'>
                        <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Receipts</p>
                        {previousReceiptFilesPresent ?
                          <PreviousFileList fileList={this.props.data.previousReceiptImages} />
                          : ''}
                        {receiptFilesPresent ?
                          <FileList fileList={this.props.data.receiptFile} downloadMode={true} />
                          : ''}
                      </div> : ''}

                    {completionFilesPresent || previousCompletionFilesPresent ?
                      <div className='my-3'>
                        <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Proof of Completion</p>
                        {previousCompletionFilesPresent ?
                          <PreviousFileList fileList={this.props.data.previousCompletionImages} />
                          : ''}
                        {completionFilesPresent ?
                          <FileList fileList={this.props.data.completionFile} downloadMode={true} />

                          : ''}
                      </div> : ''}

                  </div>
                </div>
                <div className='rounded-lg Gborder-1 p-3 my-4'>
                  <div className='d-flex justify-content-between align-items-baseline G-pointer' title='Toggle Expansion' data-toggle='collapse' aria-expanded="true" href="#collapseBankDetails">
                    <p className='sans-semibold Gfs-125 mb-0'>Bank Details</p>
                    <div>
                      <span role='button' className='chevron-circle-up Gfs-175'><PlusCircle size={20} /></span> <span role='button' className='chevron-circle-down Gfs-175'><DashCircle size={20} /></span>
                    </div>
                  </div>
                  <div id='collapseBankDetails' className='collapse show' >
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Bank Name</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.bank_name}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Account Number</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.account_number}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Routing Number</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.routing_number}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Bank Address Line 1</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.street_1}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Bank Address Line 2</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.street_2 ? this.props.data.street_2 : '-'}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>City</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.city}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>State</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.state}</p>
                    </div>
                    <div className='my-3'>
                      <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Zip code</p>
                      <p className='Gfs-125 sans-medium'>{this.props.data.zip_code}</p>
                    </div>

                  </div>
                </div>

                <div className='d-flex justify-content-end mob-btn-div-column'>
                  <button type="button" className="G-cancel-button mob-separate-v6 mx-3" onClick={this.handleBackThree.bind(this)}>Back</button>
                  <button type="button" className="G-orange-button mob-separate-v6" onClick={this.handleSaveTR.bind(this)}>Continue</button>

                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}

export default TuitionReimbursementConfirmation;