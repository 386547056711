import replace from 'lodash/replace';

export function mailToSupport(mailRequestType, customer) {
  // default in case we don't have an advisorSupportEmail
  const mailTo = customer['advisorSupportEmail'] || 'ops@vault.co';

  // hello page only has token data, not the full customer model
  const fullName = customer['fullName'];
  const email = customer['email'];

  // just figured this would be easier to read broken up
  const subject = `${mailRequestType} from ${fullName}`;
  const bodyHeader = '%0D%0A%0D%0A%0D%0A%0D%0A-------- Below Information is used by Support for troubleshooting ------';
  const nameLine = `Name: ${fullName}`;
  const emailLine = `Email: ${email}`;
  const employerLine = `Employer: ${customer['employerName']}`;
  const partnerLine = `Partner: ${customer['partnerName']}`;
  const body = `${bodyHeader}%0D%0A${nameLine}%0D%0A${emailLine}%0D%0A${employerLine}%0D%0A${partnerLine}`;

  return replace(`mailto:${mailTo}?subject=${subject}&body=${body}`, ' ', '%20');
}

