import { combineReducers } from 'redux';
import apiReducer from './apiReducer';
import customerReducer from './customerReducer';
import employerReducer from './employerReducer';
import tuitionReimbursementReducer from './tuitionReimbursementReducer';
import fiveTwoNineReducer from './fiveTwoNineReducer';
import authenticationReducer from './authenticationReducer';
import advisorReducer from './advisorReducer';
import notificationsReducer from './notificationsReducer';
import errorReducer from './errorReducer';
import generalTermsReducer from "./generalTermsReducer";
import esignDisclosureTermsReducer from "./esignDisclosureTermsReducer";
//import { reducer as form } from "redux-form";
export default combineReducers({
  customer_array: customerReducer,
  employer_array: employerReducer,
  tuition_reimbursement: tuitionReimbursementReducer,
  five_two_nine: fiveTwoNineReducer,
  authentication: authenticationReducer,
  advisor: advisorReducer,
  notifications: notificationsReducer,
  general_terms: generalTermsReducer,
  esignDisclosure_terms: esignDisclosureTermsReducer,
  errors: errorReducer,
  api: apiReducer,
});