import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import eventNames from "../event-names-map";

import { fetchEmployerMetadata, loginUser } from '../../actions/authenticationActions';
import { fetchCustomer } from '../../actions/customerActions';
import { fetchEmployer } from '../../actions/employerActions';
import { responseMessage } from '../../utils/alert';
import { intercomBoot, intercomTrackEvent } from "../customerStatus";
import { translations } from '../../helpers/translations';
import { customLoginForm } from './customLogin';
import config from '../../config';
import LoginPage from './LoginPage';
import updateState from '../shared/SignIn/updateState';
import initialState from '../shared/SignIn/initialState';
import getUrlForLogin from '../../helpers/getUrlForLogin';
import { getSatisfactionFeedback } from '../../actions/advisorActions';

const initialStateObj = initialState('login');

const Login = (props) => {
  const [state, setState] = useState({
    ...initialStateObj
  })
  const [partnerLogo, setPartnerLogo] = useState(null);

  useEffect(() => {
    document.title = "Vault | Login";
    const subDomain = window.location.host.split('.')[0];

    if (isSSODomain(subDomain)) {
      setState({ ...state, ssoDomainEnabled: true, loading: true })
      props.fetchEmployerMetadata({ subdomain: subDomain });
    }
  }, []);

  useEffect(() => {
    if (state.ssoDomainEnabled) {
      setState({ ...state, employerMetadata: props.employerMetadata, loading: false })
    }
  }, [props.employerMetadata]);

  useEffect(() => {
    if (Object.keys(props.loginData).length) {
      const { fetchCustomer, fetchEmployer, getSatisfactionFeedback } = props
      const res = props.loginData;
      const intercom = Object.assign(res.intercom, { email: res.email, user_id: res.user_id });
      const token = props.loginData.token;

      localStorage.setItem('intercom', JSON.stringify(intercom));
      localStorage.setItem('userToken', token);
      fetchCustomer()
      fetchEmployer();
      getSatisfactionFeedback();
    }
  }, [props.loginData]);

  useEffect(() => {
    if(props.satisfactionData.length) localStorage.setItem('satisfactionData', JSON.stringify(props.satisfactionData));
  },[props.satisfactionData])

  useEffect(() => {
    if (Object.keys(props.customerData).length) {
      const updatedStateObj = updateState(props, 'login');

      setState({
        ...state,
        ...updatedStateObj
      });

      intercomBoot();
      intercomTrackEvent(eventNames.login);
    }
  }, [props.customerData]);

  useEffect(() => {
    if (Object.keys(props.employer).length) {
      const advisorFirst = props.employer.attributes.advisor_first;
      const includeKnowledgeBank = props.employer.attributes.include_knowledge_bank;
      localStorage.setItem("advisorFirst", advisorFirst);
      setState({
        ...state,
        advisorFirst: advisorFirst,
        includeKnowledgeBank: includeKnowledgeBank
      })
    }
  }, [props.employer]);

  useEffect(() => {
    if (Object.keys(props.errors.errors).length) {
      const errors = props.errors.errors.errors ?? null;
      if (errors) {
        if (props.errors.errors?.error) {
          responseMessage("error", props.errors.errors?.error, "");
        } else if (translations.loginErrors[errors[0].detail]) {
          return responseMessage("error", translations.loginErrors[errors[0].detail], "");
        }
        responseMessage("error", errors[0]['detail'], "");
      }
    }
  }, [props.errors.errors]);

  useEffect(() => {
    if(!!props.partnerData?.data?.attributes){
    setPartnerLogo(props.partnerData?.data?.attributes?.logo);
    }
  },[props.partnerData])

  const isSSODomain = (subDomain) => {
    const containsSubDomain = window.location.host.split('.').length > 2;
    const sso = config.sso;
    // Compares current subdomain to blacklisted subdomain
    return containsSubDomain && sso.blacklistedSubDomains.filter(val => subDomain !== val).length > 0;
  }

  const {
    userVerify,
    employerMetadata,
    loading,
  } = state

  if (userVerify === true) {
    const url = getUrlForLogin(state);
    return <Redirect to={url} />
  } else {
    return (
      <>
        {loading ?
          ""
          :
          <>
            {(employerMetadata && customLoginForm(employerMetadata)) ? customLoginForm(employerMetadata) :
              <LoginPage state={state} partnerLogo={partnerLogo} setState={setState} loginUser={props.loginUser} />
            }
          </>
        }
      </>
    )
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getSatisfactionFeedback: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  loginData: state.authentication.loginData,
  customerData: state.customer_array.customerData,
  employer: state.employer_array.employerData,
  employerMetadata: state.authentication.employerMetadata,
  partnerData: state.authentication.partnerData,
  satisfactionData: state.advisor.satisfactionData,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser, fetchCustomer, fetchEmployer, fetchEmployerMetadata, getSatisfactionFeedback })(Login);
