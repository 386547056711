import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import TextInput from '../shared/TextInput/TextInput';
import ValidatorMessage from '../shared/ValidatorMessage/ValidatorMessage';
import { BasicInfoSvg, requestFormBreadcrumb, requestFormStepperData } from './TuitionReimbursement.constant';
import Form from 'react-bootstrap/Form';
import Stepper, { StepperStage } from '../shared/Stepper/Stepper';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';


class TuitionReimbursementOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEducationExpense: false
    };
  }
  componentDidMount() {
    document.title = "Vault | Tuition Reimbursement Step 1"
  }
  handleAddOne = (e) => {
    this.props.onClickHandleAddOne(e)
  }
  handleChangeEducationExpense = (e) => {
    let showEducationExpense = false;
    if (e.target.value === 'Other') {
      showEducationExpense = true;
    }
    this.setState({
      showEducationExpense: showEducationExpense
    })
    this.props.handleInputChange(e);
  }
  render() {
    const { showEducationExpense } = this.state;
    return (
      <>
        <Breadcrumb list={requestFormBreadcrumb()} />
        <Stepper options={requestFormStepperData(StepperStage.active)} />
        <div className='bg-white p-4 my-5 rounded-lg Gborder-1'>
          <h2 className='Gfs-225 mb-5'>Tuition Reimbursement Request Form</h2>
          <div className='G-grid11-2'>
            <div>
              <h3 className='Gfs-175 Gt-slate-5'>Basic information</h3>
              <p className='sans-medium'>
                The information collected on this page is needed to start your tuition reimbursement request. It will be shared with your employer when they review the submission.
              </p>
              <div className='Gb-pink-2 rounded-lg px-4 py-5'>
                <BasicInfoSvg />
              </div>
            </div>
            <div>
              <FormGroup className='mb-4'>
                <TextInput
                  label='First Name'
                  name='first_name'
                  value={this.props.data.first_name}
                  placeholder='First Name'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors["first_name"]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName='first_name' fieldValue={this.props.data.first_name} message='First name is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Last Name'
                  name='last_name'
                  value={this.props.data.last_name}
                  placeholder='Last Name'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors["last_name"]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName='last_name' fieldValue={this.props.data.last_name} message='Last name is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Title'
                  name='title'
                  value={this.props.data.title}
                  placeholder='Title'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors["title"]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName='title' fieldValue={this.props.data.title} message='Title is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Department'
                  name='department'
                  value={this.props.data.department}
                  placeholder='Department'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors["department"]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName='department' fieldValue={this.props.data.department} message='Department is required' />
              </FormGroup>
              <FormGroup>
                {/* select */}
                <Form.Label className='Gfs-1'>Type of Education Expense*</Form.Label>
                <select
                  className="form-control classNameic"
                  id="sel1"
                  name="education_expense_id"
                  value={this.props.data.education_expense_id}
                  onChange={(e) => this.handleChangeEducationExpense(e)}
                  onBlur={(e) => this.props.showValidationMessage(e)}
                >
                  <option value="">Select</option>
                  {this.props.data.educationExpensesData ? this.props.data.educationExpensesData.map(item => {
                    if (item.attributes.master === true) {
                      return (
                        <option key={item.attributes.id} value={item.attributes.id}>{item.attributes.name}</option>
                      )
                    } else {
                      return null
                    }
                  }) : ""}
                  <option value="Other">Other</option>
                </select>
                {showEducationExpense ?
                  <TextInput
                    label=''
                    name='education_expense'
                    value={this.props.data.education_expense}
                    placeholder='Education Expense'
                    handleInputChange={(e) => this.props.handleInputChange(e)}
                    handleBlur={(e) => { return }}
                    required={false}

                  />
                  : ""}
                <span className="customError">{this.props.customErrors["education_expense_id"]}</span>
                <ValidatorMessage validator={this.props.validator} fieldName='education_expense_id' fieldValue={this.props.data.education_expense_id} message='Education Expense is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <Form.Label htmlFor='notesForManager'>Notes for Manager</Form.Label>
                <Form.Control
                  name='notes_manager'
                  rows={5}
                  value={this.props.data.notes_manager}
                  as="textarea"
                  id='notesForManager'
                  onChange={(e) => this.props.handleInputChange(e)}
                  onBlur={(e) => this.props.showValidationMessage(e)}
                  aria-label="Notes for manager"
                  className='Gbr-5 placeholder-slate-50'
                  placeholder='Notes for Manager' />
              </FormGroup>

              <div className='d-flex justify-content-end mob-btn-div-column'>
                {/* <button type="button" className="G-cancel-button mob-separate-v6  mx-3" onClick={this.props.handleResetOne}>Reset</button> */}
                <a href='/tuition-reimbursement-history'>
                  <button type="button" className="G-cancel-button mob-separate-v6  mx-3">Back</button>
                </a>
                <button type="button" className="G-orange-button mob-separate-v6 " onClick={this.handleAddOne.bind(this)}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TuitionReimbursementOne;
