import { headerSelector } from "./serviceSelector"

const filterSubNavLinks = (data, linkName) => {
  return data?.filter((header) => {
    return linkName !== header.name
  })
}

const filterNavLinks = (data, linkName) => {
  return data?.filter((header) => {
    return linkName !== header.id
  })
}

const hideAdvisorLinks = (data, employer) => {
  if (data?.subHeader) {
    let advisorLinks = [...data.subHeader];
    if (employer && employer.attributes) {

      if (!employer.attributes.quatro_widget) {
        advisorLinks = filterSubNavLinks(advisorLinks, "COLLEGE PLANNING");
      }

      if (!employer.attributes.include_knowledge_bank) {
        advisorLinks = filterSubNavLinks(advisorLinks, "PUBLIC SERVICE LOAN FORGIVENESS");
      }
    }
    return advisorLinks;
  }
}

// show/hide subnavbar links
export const subNavBarLinks = (data, employer) => {
  const blacklist = hideAdvisorLinks(data, employer);
  return blacklist

}

//hide main nav bar tab
export const hideHeadersLinks = (data) => {
  let navLinks = [...data];
  if (!(localStorage.getItem('savingsPlanEnabled') === 'true' || localStorage.getItem('advisorEnabled') === 'true' && localStorage.getItem('showProfile') === 'true' && localStorage.getItem('includeKnowledgeBank') === 'true' && localStorage.getItem('showFiveToNineOrAdvisorSwitcher') === 'true')) {
    navLinks = filterNavLinks(navLinks, 'vault529')
  }
  return navLinks
}

const notificationAndProfileLogic = () => {
  const headerName = headerSelector();
  if ((headerName === 'fiveTwoNine' && localStorage.getItem('hasAdvisor') === 'true' && (localStorage.getItem('selectedService') === 'advisor' || (localStorage.getItem('selectedService') === 'null' && localStorage.getItem('advisorFirst') === 'true'))) ||
    (headerName === 'tuition' && localStorage.getItem('hasAdvisor') === 'true')) {
    return true
  } else if ((headerName === 'pay' || headerName === 'match' || headerName === 'advisorOnly') && localStorage.getItem('hasAdvisor') === 'true') {
    return true
  } else {
    return false
  }
}

// show/hide navbar links
export const showNotification = () => {
  return notificationAndProfileLogic();
}

export const showProfileDropdown = () => {
  return notificationAndProfileLogic();
}