import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { getInviteQuota, getInvitations, sendInviteFamilyMember, deleteInviteFamilyMember } from '../../../actions/fiveTwoNineActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { Form } from 'react-bootstrap';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import Select from 'react-select'

class PRINVITE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteQuota: 0,
      invitations: [],
      availableInvites: 0,
      newInvitationsAvailable: [],
      show: false,
      showInvite: false,
      full_name: "",
      email: "",
      relationship: "",
      invitationId: 0,
      sendInvite: {},
      deleteInvite: {},
      validationError: {
        'full_name': false,
        'email': false,
        'relationship': false
      },
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault | Invitation"
    const { getInviteQuota, getInvitations } = this.props;
    getInviteQuota();
    getInvitations();

    intercomTrackEvent(eventNames.visitedFamilyInvitePage);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.inviteQuota !== nextProps.inviteQuota) {
      this.setState({
        inviteQuota: nextProps.inviteQuota
      });
    }
    if (this.props.invitations !== nextProps.invitations) {
      const invitationLength = nextProps.invitations.length;
      const count = nextProps.inviteQuota - invitationLength;
      let collection = [];
      for (let i = 1; i <= count; i++) {
        collection.push(i);
      }
      this.setState({
        inviteQuota: nextProps.inviteQuota,
        invitations: nextProps.invitations,
        availableInvites: collection.length,
        newInvitationsAvailable: collection
      })
    }
    if (this.props.sendInvite !== nextProps.sendInvite) {
      const { getInviteQuota, getInvitations } = this.props;
      getInviteQuota();
      getInvitations();
      this.setState({
        show: false
      })
      intercomTrackEvent(eventNames.sentFamilyInvite);
    }
    if (this.props.deleteInvite !== nextProps.deleteInvite) {
      const { getInviteQuota, getInvitations } = this.props;
      getInviteQuota();
      getInvitations();
      this.setState({
        showInvite: false
      })
      intercomTrackEvent(eventNames.deletedFamilyInvite);
    }

  }
  handleShow = (e) => {
    this.setState({
      full_name: "",
      email: "",
      relationship: "",
      show: true,
      showInvite: false
    })
  }
  handleHide = (e) => {
    this.setState({
      show: false
    })
  }
  handleShowInviteData = (inviteData, e) => {
    const full_name = inviteData.attributes.full_name;
    const email = inviteData.attributes.email;
    const relationship = inviteData.attributes.relationship;
    const invitationId = inviteData.id;

    this.setState({
      full_name: full_name,
      email: email,
      relationship: relationship,
      invitationId: invitationId,
      show: false,
      showInvite: true
    })
  }
  handleHideInvite = (e) => {
    this.setState({
      showInvite: false
    })
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleRelationshipInputChange = (e) => {
    this.setState({
      relationship: e.value
    })
  }
  handleSendInvite = (e) => {
    if (this.validator.allValid()) {
      const { sendInviteFamilyMember } = this.props;
      const requestData = {
        "data": {
          "attributes": {
            "full_name": this.state.full_name,
            "email": this.state.email,
            "relationship": this.state.relationship
          }
        }
      }
      sendInviteFamilyMember(requestData);
    } else {
      this.validator.showMessages();
    }
  }
  handleDeleteInvite = (invitationId, e) => {
    const { deleteInviteFamilyMember } = this.props;
    deleteInviteFamilyMember(invitationId);
  }
  render() {
    const { availableInvites, invitations, show, relationship } = this.state;
    const relationshipOptions = [
      { label: 'Spouse', value: "spouse" },
      { label: 'Child', value: "child" },
      { label: 'Parent', value: "parent" },
    ]
    const defaultRelationValue = relationshipOptions.filter((x) => x.value === relationship)
    return (
    
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container  my-5">
            <div className="p-4 bg-white Gborder-1 rounded-lg">
              <div className='G-grid11-2'>
                <div>
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M26.25 32.1875C23.325 32.1875 17.5 33.65 17.5 36.5625V38.75H35V36.5625C35 33.65 29.175 32.1875 26.25 32.1875ZM20.425 36.25C21.475 35.525 24.0125 34.6875 26.25 34.6875C28.4875 34.6875 31.025 35.525 32.075 36.25H20.425ZM26.25 30C28.6625 30 30.625 28.0375 30.625 25.625C30.625 23.2125 28.6625 21.25 26.25 21.25C23.8375 21.25 21.875 23.2125 21.875 25.625C21.875 28.0375 23.8375 30 26.25 30ZM26.25 23.75C27.2875 23.75 28.125 24.5875 28.125 25.625C28.125 26.6625 27.2875 27.5 26.25 27.5C25.2125 27.5 24.375 26.6625 24.375 25.625C24.375 24.5875 25.2125 23.75 26.25 23.75ZM35.05 32.2625C36.5 33.3125 37.5 34.7125 37.5 36.5625V38.75H42.5V36.5625C42.5 34.0375 38.125 32.6 35.05 32.2625ZM33.75 30C36.1625 30 38.125 28.0375 38.125 25.625C38.125 23.2125 36.1625 21.25 33.75 21.25C33.075 21.25 32.45 21.4125 31.875 21.6875C32.6625 22.8 33.125 24.1625 33.125 25.625C33.125 27.0875 32.6625 28.45 31.875 29.5625C32.45 29.8375 33.075 30 33.75 30Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h2>Add A Family Member</h2>
                </div>

                <div>
                  <div className={'p-4 rounded-lg d-flex mb-4 ' + (availableInvites > 0 ? 'Gb-pink-2 G-cursor-pointer Gborder-1-pink' : 'Gb-white-5 G-pointer-events-none Gborder-1 ')} onClick={this.handleShow.bind(this)}>
                    {availableInvites > 0 ?
                      <div className='my-auto'>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="40" rx="20" fill="var(--icon-color)" />
                          <path d="M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z" fill="white" />
                        </svg>
                      </div>
                      :
                      <div className='my-auto'>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="40" rx="20" fill="#A1A1A1" />
                          <path d="M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z" fill="white" />
                        </svg>
                      </div>
                    }
                    <div className='mx-3'>
                      <label className={'Gfs-125 ' + (
                        availableInvites > 0 ? 'Gt-pink-8 G-cursor-pointer' : 'Gt-slate-1 G-pointer-events-none'
                      )}>
                        Send Invite
                      </label>
                      <p className={'mb-0 ' + (
                        availableInvites > 0 ? '' : 'Gt-slate-1'
                      )}>{availableInvites} INVITES REMAINING</p>
                    </div>
                  </div>
                  {show &&
                    <div className='Gborder-1 rounded-lg p-4 mb-4'>
                      <label className='Gfs-125 mb-4'>
                        Fill in their details
                      </label>
                      <Form.Group className='mb-4'>
                        <Form.Label>
                          Full Name
                        </Form.Label>
                        <Form.Control type="text" name='full_name' placeholder="Full Name" onChange={this.handleInputChange.bind(this)} value={this.state.full_name} />
                        <ValidatorMessage customValidationType='includeNoSpecialChar' validator={this.validator} fieldName='full_name' fieldValue={this.state.full_name} message="Full name is required" />
                      </Form.Group>
                      <Form.Group className='mb-4'>
                        <Form.Label>
                          Email
                        </Form.Label>
                        <Form.Control type="text" name='email' placeholder="Email" value={this.state.email} onChange={this.handleInputChange.bind(this)} />
                        <ValidatorMessage validationType="required|email" validator={this.validator} fieldName='email' fieldValue={this.state.email} message="Email is required" />
                      </Form.Group>
                      <Form.Group className='mb-4'>
                        <Form.Label >Relationship</Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="reactSelect"
                          name="relationship"
                          placeholder='Select Relationship'
                          isSearchable={true}
                          value={defaultRelationValue}
                          onChange={this.handleRelationshipInputChange.bind(this)}
                          options={relationshipOptions}

                        />
                        <ValidatorMessage validator={this.validator} fieldName='relationship' fieldValue={this.state.relationship} message="Relationship is required" />
                      </Form.Group>

                      <div className="d-flex justify-content-end p-3 mob-btn-div-column">
                        <button type="button" className="G-cancel-button ml-3 mt-2" onClick={this.handleHide.bind(this)} >
                          Cancel
                        </button>
                        <button type="button" className="G-orange-button ml-3 mt-2" onClick={this.handleSendInvite.bind(this)}>
                          Send Invite
                        </button>
                      </div>
                    </div>
                  }

                  {invitations ? invitations.map((data, i) => {
                    const full_name = data.attributes.full_name;
                    const relationship = data.attributes.relationship;
                    const email = data.attributes.email;
                    return (
                      <div className='Gborder-1 rounded-lg p-4 mb-4'>
                        <div>
                          <label className={data.attributes.status !== 'accepted' ? 'G-yellow-badge' : 'G-green-badge'}>Invite {data.attributes.status !== 'accepted' ? 'Pending' : 'Accepted'}</label>
                          <br />
                          <label className='Gfs-125'>{full_name}</label>
                          <br />
                          <label className='Gfs-1'>{email} - {relationship.charAt(0).toUpperCase() + relationship.slice(1)}</label>
                          <br />
                          <div className="d-flex py-2 justify-content-end mob-btn-div-column">
                            <button onClick={this.handleDeleteInvite.bind(this, data.id)} className="sans-semibold Gfs-1 G-link text-decoration-none cancelLink G-cursor-pointer">
                              Delete Invitation
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                    : null
                  }
                </div>

              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    )
  }
}
PRINVITE.propTypes = {
  getInviteQuota: PropTypes.func.isRequired,
  getInvitations: PropTypes.func.isRequired,
  sendInviteFamilyMember: PropTypes.func.isRequired,
  deleteInviteFamilyMember: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  inviteQuota: state.five_two_nine.inviteQuota,
  invitations: state.five_two_nine.invitations,
  sendInvite: state.five_two_nine.sendInvite,
  deleteInvite: state.five_two_nine.deleteInvite,
  errors: state.errors
});
export default connect(mapStateToProps, { getInviteQuota, getInvitations, sendInviteFamilyMember, deleteInviteFamilyMember })(PRINVITE);
