const matchFaqList = [
  {
    question: "What is Vault Match and how does it work?",
    id: 0,
    answer:
      "Vault Match enables your employer to make a matching contribution to your 401(k) based on" +
      "your student loan payments. Vault Match tracks and verifies your student loan payments. These" +
      "payments are then reported to your employer and your 401(k) match amount is calculated. This" +
      "allows you to save for retirement as you pay down your student loans.",
  },
  {
    question: "Who is eligible for Vault Match?",
    id: 1,
    answer:
      "If you are eligible for your employer's 401(k), you are eligible for Vault Match.",
  },
  {
    question: "What are the tax benefits of Vault Match?",
    id: 2,
    answer:
      "The tax benefits of your Vault Match contributions are equivalent to those offered as" +
      "part of your employer's 401(k) match contributions. A 401(k) employer match is not taxable." +
      "Employees receiving Vault Match contributions effectively receive a student loan debt payment" +
      "subsidy without having to pay tax on the benefit.",
  },
  {
    question: "Will I see a deduction on my paycheck for using Vault Match?",
    id: 3,
    answer:
      "No, your Vault Match contribution is not deducted from your paycheck nor income in any way.",
  },
  {
    question:
      "Do I need to make the minimum payment toward my student loans to qualify for Vault Match?",
    id: 4,
    answer:
      "You do not have to make your minimum monthly payment to qualify for your employer's 401(k)" +
      "matching contribution. Vault strongly recommends that you keep up with your minimum" +
      "payments to assure you remain in good standing with your loan provider. You may not qualify for" +
      "the benefit if you default on your student loans.",
  },
  {
    question:
      "Where can I find my employer's Match information for timing and matching contribution percentages?",
    id: 5,
    answer:
      'You are able to find the specific plan details on your Vault Match dashboard. Just click "See Plan Details" under the payment progress bar.',
  },
  {
    question:
      "Can I make direct contributions to my 401(k) and simultaneously receive matching contributions based on my student loan payments?",
    id: 6,
    answer:
      "Yes, but your employer's 401(k) plan percentage match plus your Vault contribution match will not exceed the maximum percent in your employer's 401(k) plan description. Please see your employer's 401(k) plan details. You can find these details on your Vault Match dashboard under your payment progress. If you have any additional questions regarding your employer’s specific plan, contact your HR department",
  },
  {
    question:
      "Will payments I made this year before activating my Vault account be included in matching contributions?",
    id: 7,
    answer:
      "Yes, Vault Match can track payments up to 180 days before the activation of your Vault account." +
      "Depending on when your plan year begins, when your employment began, and when you" +
      "created your Vault account, you may not see these matching contributions in your 401(k)" +
      "account immediately. Based on your 401(k) plan details, employers make contributions on an" +
      "annual, quarterly, or monthly basis. Check your 401(k) plan documents or reach out to your HR" +
      "representative with timing-specific questions.",
  },
  {
    question: "When does Vault Match start tracking my student loan payments?",
    id: 8,
    answer:
      "Vault begins tracking your payments on the first day of the plan year determined by your" +
      "employer. If you do not see payments on your Vault Match Dashboard dating back to the plan" +
      'start date, please reach out to <a className="linkColour" href="mailto:genius@vault.co">genius@vault.co</a> to get assistance.',
  },
  {
    question:
      "What if I don't upload or link my transactions before my employer calculates my Vault Match amount?",
    id: 9,
    answer:
      "A true-up contribution allows you to receive your employer matches that you would have" +
      "otherwise missed out on because of 401(k) front-loading or because your employer spread their" +
      "401(k) contributions out unevenly throughout the year. A true-up contribution will be made to" +
      "your 401(k) after the end of the plan year to assure you've maximized your match potential."+
      "<div className='sub-faq'><h6 className='linkColour'>What is 401(k) frontloading?</h6><p className=\"faq-answer fade-in\">If you have the financial flexibility, you might choose to front-load your 401(k)" +
      "contributions. That is, rather than spacing out your annual contributions over the year," +
      "you instead max out your 401(k) contributions within the first few months of the year.</p></div>",
  },
  {
    question:
      "How often does Vault report my student loan payments to my employer?",
    id: 10,
    answer:
      "To find out how often Vault reports student loan payments to your employer, see your" +
      "employer's 401(k) plan details. You can find these details on your Vault Match dashboard under your payment progress. If you have any additional questions regarding your employer's specific" +
      "401(k) plan, please contact your HR department.",
  },
  {
    question: "What happens if I leave my job before the end of the year?",
    id: 11,
    answer:
      "Your employer will send true-up match contributions to your 401(k) after the plan year ends." +
      "Employers are required to follow all plan documentation details even if you are not employed at" +
      "the end of the plan year. Any student loan payments after your termination will not be" +
      "considered eligible for any 401(k) matching contributions.",
  },
  {
    question:
      "What if I accidentally miss a loan payment? Will that affect my employer's 401(k) matching contribution?",
    id: 12,
    answer:
      "Yes, if you miss a loan payment and do not compensate for this missed payment by the end of" +
      "your plan year, it may affect (i.e., lower) your employer's 401(k) matching contribution. Please" +
      "note: this is only the case if you haven't already maximized your matching contribution with" +
      "other existing verified payments or direct match contributions.",
  },
  {
    question:
      "I've made payments to my student loans but my employer's matching contributions aren't showing up in my retirement account.",
    id: 13,
    answer:
      'If the payments on your Vault Match dashboard do not parallel your student loan statement or the payments you see on your online account, please contact us at <a className="linkColour" href="mailto:genius@vault.co">genius@vault.co.</a>',
  },
  {
    question: "Why can't I see all my student loan payments in the dashboard?",
    id: 14,
    answer:
      "If you don't see all your student loan payments in the dashboard, check to see if your account is" +
      "linked to track payments. If you never linked your account or if your account has become" +
      "unlinked, you may not see all transactions. If you are manually uploading statements because" +
      "you cannot link your account, those payments will remain under review until they have been" +
      "approved by Vault. In order to be approved, payments must be made to student loans in your" +
      "name and within the plan time constraints.",
  },
  {
    question:
      "I see that I have access to an Advisor tool. What is Vault Advisor?",
    id: 15,
    answer:
      "Vault Advisor simplifies all your available repayment scenarios to enable you to find the best" +
      "repayment plan for your individual financial goals. The tool allows you to model customized" +
      "payoff plans based on your actual loans. As you progress through the tool, you'll be able to see" +
      "all of your loans in one simple dashboard and explore the impact of lowering payments, paying" +
      "off loans faster, or refinancing.",
  },
  {
    question:
      "Does Vault share my personal or loan information with my employer?",
    id: 16,
    answer:
      "We will never share personal and/or financial loan information with your employer*.<br>" +
      "Vault takes your personal information protection very seriously. The only information we " +
      "share with your employer is anonymized aggregate data.<br><br>*The term “employer” only applies to users who are provided access to Vault through<br>" +
      "their employer.",
  },
  {
    question: "How do I get more help?",
    id: 17,
    answer:
      'If you didn’t see the answer to your question, reach out to us at <a className="linkColour" href="mailto:genius@vault.co">genius@vault.co</a>.',
  },
];

export default matchFaqList;