import { 
  UPDATE_CUSTOMER,
  UPDATE_STATUS_URL, 
  FETCH_CUSTOMER 
} from '../actions/types';

const initialState = {
  customerData: {},
  updateStatusUrlResponse:{},
  updateCustomerResponse:{}
}

export default function customerReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_CUSTOMER:
      return {
        ...state,
        customerData: action.payload
      }
    case UPDATE_STATUS_URL:
      return {
        ...state,
        updateStatusUrlResponse: action.payload
      }
    case UPDATE_CUSTOMER:
      return {
        ...state,
        updateCustomerResponse: action.payload
      }    
    default:
      return state;
  }
}