import React from 'react';
import { Carousel } from 'react-bootstrap';
import { FiveTwoNineSvg1, FiveTwoNineSvg2, FiveTwoNineSvg3, } from '../RegistrationCarousel.constant';
import style from '../RegistrationCarousel.module.css'

const CarouselFiveTwoNine = () => {
	return (
		<div className={'G-carousel-dark ' + style.containerMain}>
			<Carousel fade>
				<Carousel.Item interval={5000}>
					<div className={'flex flex-column align-items-center Gb-pink-1 setHeight_RegistrationPage ' + style.carouselContainer}>
						<div className={style.heightFitContentMob}>
							<FiveTwoNineSvg1 />
						</div>
						<div className={'px-5 mt-2 ' + style.heightFitContent}>
							<label className='Gfs-15 Gt-pink-8 sans-bold'>
								The Ultimate College Savings Tool.
							</label>
						</div>
						<div className='px-5 '>
							<label>
								Simplify the process of selecting 529 plans and contributing to your education savings with Vault 529. With direct payments, retention and recruitment tools, and the latest 529 qualified expense rules, you can ensure that your children's future is secure at all life stages.
							</label>
						</div>
					</div>
				</Carousel.Item>
				<Carousel.Item interval={5000}>
					<div className={'flex flex-column align-items-center Gb-orange-2 setHeight_RegistrationPage ' + style.carouselContainer}>
						<div className={style.heightFitContentMob}>
							<FiveTwoNineSvg2 />
						</div>
						<div className={'px-5 mt-2 ' + style.heightFitContent}>
							<label className='Gfs-15 Gt-pink-8 sans-bold'>
								Secure Your Children's Education
							</label>
						</div>
						<div className='px-5 '>
							<label>
								Get a comprehensive solution for education savings with Vault 529, which builds your education funds, tracks and manages expenses, and invests in your children's future. With an easy-to-use system, you can focus on your work while we help secure your children's education.
							</label>
						</div>
					</div>
				</Carousel.Item>
				<Carousel.Item interval={5000}>
					<div className={'flex flex-column align-items-center Gb-green-1 setHeight_RegistrationPage ' + style.carouselContainer}>
						<div className={style.heightFitContentMob}>
							<FiveTwoNineSvg3 />
						</div>
						<div className={'px-5 mt-2 ' + style.heightFitContent}>
							<label className='Gfs-15 Gt-pink-8 sans-bold'>
								Empower Yourself with Vault 529
							</label>
						</div>
						<div className='px-5 '>
							<label>
								Achieve your education savings goals with Vault 529, the ultimate tool that offers tax-deferred college savings, scenario modeling tools, and employer contributions. Empower yourself both personally and professionally with the support you need to save for your children's future.
							</label>
						</div>
					</div>
				</Carousel.Item>
			</Carousel>
		</div>
	)
}

export default CarouselFiveTwoNine