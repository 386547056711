import {
  FETCH_EDUCATION_EXPENSES,
  FETCH_TUITION_BY_ID,
  FETCH_TUITIONS,
  FETCH_SERVICER_ACCOUNT,
  FETCH_ADDRESS,
  FETCH_INCOME_RANGE,
  FETCH_AGE_RANGE,
  SAVE_TR_DETAILS,
  FETCH_TERMSCONDITIONS,
  ACCEPT_TERMSCONDITIONS,
  GET_ERRORS
} from './types';
import { apiAction } from './apiActions';

import config from '../config';

export const fetchTermsConditions = () => {
  return apiAction({
     url: config.apiBaseUrl + 'legal_agreements/latest_tuition',
     onSuccess: setfetchTermsConditions,
     callback : 'fetchTermsConditions',
     onFailure: errorfetchTermsConditions,
     label: FETCH_TERMSCONDITIONS
   });
}
function setfetchTermsConditions(data) {
   return {
   type: FETCH_TERMSCONDITIONS,
   payload: data.data
   };
}
function errorfetchTermsConditions(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}
export const acceptTermsConditions = (user_agreements) => {
    return apiAction({
     url: config.apiBaseUrl + 'user_agreements',
     method: 'POST',
     data: user_agreements,
     onSuccess: setupdateTermsConditions,
     callback : '',
     onFailure: errorupdateTermsConditions,
     label: ACCEPT_TERMSCONDITIONS
   });
}
function setupdateTermsConditions(data) {
   return {
     type: ACCEPT_TERMSCONDITIONS,
     payload: data.data
   };
}
function errorupdateTermsConditions(errors) {
   return {
     type: GET_ERRORS,
     payload: errors.message
   };
}
export const saveTRDetails = (formData) => {
  return apiAction({
    url: config.apiBaseUrl + 'customers/' + localStorage.getItem('customerId'),
    method: 'PUT',
    data: formData,
    onSuccess: setSaveTRDetails,
    callback : '',
    onFailure: errorSaveTRDetails,
    label: SAVE_TR_DETAILS
  });
}
function setSaveTRDetails(data) {
  return {
    type: SAVE_TR_DETAILS,
    payload: data
  };
}
function errorSaveTRDetails(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchAgeRange = () => {
  return apiAction({
     url: config.apiBaseUrl + 'age_ranges',
     onSuccess: setfetchAgeRange,
     callback : '',
     onFailure: errorfetchAgeRange,
     label: FETCH_AGE_RANGE
   });
}
function setfetchAgeRange(data) {
  return {
    type: FETCH_AGE_RANGE,
    payload: data
  };
}
function errorfetchAgeRange(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchIncomeRange = () => {
  return apiAction({
     url: config.apiBaseUrl + 'income_ranges',
     onSuccess: setfetchIncomeRange,
     callback : '',
     onFailure: errorfetchIncomeRange,
     label: FETCH_INCOME_RANGE
   });
}
function setfetchIncomeRange(data) {
  return {
    type: FETCH_INCOME_RANGE,
    payload: data
  };
}
function errorfetchIncomeRange(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchTuitions = () => {
  return apiAction({
    url: config.apiBaseUrl + 'tuitions',
    onSuccess: setFetchTuitions,
     callback : '',
    onFailure: errorFetchTuitions,
    label: FETCH_TUITIONS
   });
}
function setFetchTuitions(data) {
  return {
    type: FETCH_TUITIONS,
    payload: data
  };
}
function errorFetchTuitions(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchTuitionById = (tuitionId) => {
  return apiAction({
     url: config.apiBaseUrl + 'tuitions/' + tuitionId,
     onSuccess: setFetchTuitionById,
     callback : '',
     onFailure: errorFetchTuitionById,
    label: FETCH_TUITION_BY_ID
   });
}
function setFetchTuitionById(data) {
  return {
    type: FETCH_TUITION_BY_ID,
    payload: data
  };
}
function errorFetchTuitionById(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchEducationExpenses = () => {
  return apiAction({
    url: config.apiBaseUrl + 'education_expenses',
    onSuccess: setfetchEducationExpenses,
    callback : '',
    onFailure: errorfetchEducationExpenses,
    label: FETCH_EDUCATION_EXPENSES
  });
}
function setfetchEducationExpenses(data) {
  return {
    type: FETCH_EDUCATION_EXPENSES,
    payload: data.data
  };
}
function errorfetchEducationExpenses(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchServicerAccount = (servicerAccountId) => {
  return apiAction({
    url: config.apiBaseUrl + 'accounts/' + servicerAccountId,
    onSuccess: setFetchServicerAccount,
    callback : '',
    onFailure: errorFetchServicerAccount,
    label: FETCH_SERVICER_ACCOUNT
  });
}
function setFetchServicerAccount(data) {
  return {
    type: FETCH_SERVICER_ACCOUNT,
    payload: data.data
  };
}
function errorFetchServicerAccount(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const fetchAddress = (addressId) => {
  return apiAction({
    url: config.apiBaseUrl + 'addresses/' + addressId,
    onSuccess: setFetchAddress,
    callback : '',
    onFailure: errorFetchAddress,
    label: FETCH_ADDRESS
  });
}
function setFetchAddress(data) {
  return {
    type: FETCH_ADDRESS,
    payload: data.data
  };
}
function errorFetchAddress(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}