import PlusCircle from 'react-bootstrap-icons/dist/icons/plus-circle';
import DashCircle from 'react-bootstrap-icons/dist/icons/dash-circle';

const BankDetailsAccordion = ({bankData}) => {
    return (  <> {bankData ? 
        <div className='rounded-lg Gborder-1 p-3 my-4'>
        <div className='d-flex justify-content-between align-items-baseline G-pointer'  aria-expanded="false"  title='Toggle Expansion' data-toggle='collapse' href="#collapseBankDetails">
          <p className='sans-semibold Gfs-125 mb-0'>Bank Details</p>
          <div>
            <span role='button' className='chevron-circle-up Gfs-175'><PlusCircle size={20} /></span> <span role='button' className='chevron-circle-down Gfs-175'><DashCircle size={20} /></span>
          </div>
        </div>
        <div id='collapseBankDetails' className='collapse' >
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Bank Name</p>
          <p className='Gfs-125 sans-medium'>{bankData.bank_name}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Account Number</p>
          <p className='Gfs-125 sans-medium'>{bankData.account_number}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Routing Number</p>
          <p className='Gfs-125 sans-medium'>{bankData.routing_number}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Bank Address Line 1</p>
          <p className='Gfs-125 sans-medium'>{bankData.street_1}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Bank Address Line 2</p>
          <p className='Gfs-125 sans-medium'>{bankData.street_2 ? bankData.street_2 : '-'}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>City</p>
          <p className='Gfs-125 sans-medium'>{bankData.city}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>State</p>
          <p className='Gfs-125 sans-medium'>{bankData.state}</p>
        </div>
        <div className='my-3'>
          <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Zip code</p>
          <p className='Gfs-125 sans-medium'>{bankData.zip_code}</p>
        </div>

        </div>
        </div> : ''}
        </>
     );
}
 
export default BankDetailsAccordion;