import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from 'react-select';
import { sortAdvisorServicers } from '../dashboardCommon';
import { sortLoanType } from '../dashboardCommon';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';


import { getAdvisorServicers, getLoanType, addLoan, addMissingServicer } from '../../../actions/advisorActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { Form } from 'react-bootstrap';
import CustomModal from '../../shared/CustomModal/CustomModal';
import { Check } from 'react-bootstrap-icons';

class AddLoanManually extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advisorServicers: [],
            loanTypeData: [],
            servicerId: "",
            otherServicerName: "",
            federal: "",
            showOtherServicer: false,
            currentBalance: "",
            originationBalance: "",
            monthlyPayment: "",
            interestRate: "",
            originationDate: "",
            addLoanData: {},
            addMissingServicer: {},
            fromMatch: false,
            validationError: {
                origination_balance_cents: false,
                current_balance_cents: false,
                monthly_payment_cents: false,
            },
            loan: {
                "data": {
                    "attributes": {
                    },
                    "type": "loans"
                }
            },
            modalProp: {
                show: false,
                title: '',
                bodyText: '',
                footerButton: true,
                footerButtonName: '',
                svgType: '', //check, info
                footerButtonOnclick: '',
                footerButtonHref: '',
                footerButtonOnclickArgs: ''
            }
        }
        this.validator = new SimpleReactValidator({
            element: message => {
                return <p className="text-danger">{message}</p>;
            },
            autoForceUpdate: this
        });
        this.showValidationMessage = this.showValidationMessage.bind(this);
    }
    componentDidMount() {
        document.title = "Vault Advisor"
        const { getAdvisorServicers, getLoanType } = this.props;
        getAdvisorServicers();
        getLoanType();
        intercomTrackEvent(eventNames.visitedAdvisorNewLoan);
        this.setState({
            fromMatch: window.location.href.includes("fromMatch=true")
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.advisorServicers !== this.props.advisorServicers) {
            const advisorServicers = this.props.advisorServicers.sort(sortAdvisorServicers)
            this.setState({
                advisorServicers: advisorServicers
            })
        }
        if (prevProps.loanTypeData !== this.props.loanTypeData) {
            const loanTypeData = this.props.loanTypeData.sort(sortLoanType);
            this.setState({
                loanTypeData: loanTypeData
            })
        }
        if (prevProps.addLoanData !== this.props.addLoanData) {

            this.setState({
                ...this.state,
                modalProp: {
                    ...this.state.modalProp,
                    show: true,
                    title: 'Loan added successfully',
                    bodyText: 'Your loan has been added successfully. Redirecting you to loans dashboard in 5 seconds...',
                    footerButton: true,
                    footerButtonName: "Continue",
                    footerButtonOnclick: '',
                    footerButtonHref: '/welcome/loans',
                    footerButtonOnclickArgs: ''
                }
            });
            localStorage.setItem("didChange", true)

            setTimeout(() =>
                this.props.history.push({

                    pathname: '/welcome/loans'
                }), 5000
            )
            if (this.state.fromMatch) { //redirects to match when using from match
                setTimeout(() =>
                    this.props.history.push({
                        pathname: '/match/accounts'
                    }), 5000
                )
            }
        }
        if (prevProps.saveManualAddedLoan !== this.props.saveManualAddedLoan) {
            this.handleSubmit();
        }
    }
    changeLoanServicer = (e) => {
        const { loan, advisorServicers } = this.state;
        const servicerId = e.value;
        let servicer = advisorServicers.filter(function (value) {
            return value.id === servicerId;
        });
        let label = servicer[0].attributes.name;

        let showOtherServicer = false;
        if (servicerId) {
            const servicerJson = {
                "servicer": {
                    "data": {
                        "type": 'servicers',
                        "id": servicerId
                    }
                }
            }
            loan.data['relationships'] = servicerJson;
            if (label === 'Other') {
                showOtherServicer = true;
            } else {
                loan.data.attributes.other_servicer_name = null;
            }
        }

        this.setState({
            servicerId: servicerId,
            showOtherServicer: showOtherServicer,
            loan: loan
        })
    }
    handleInputChange = (e) => {
        const { loan } = this.state;
        loan.data.attributes.other_servicer_name = e.target.value;

        this.setState({
            otherServicerName: e.target.value,
            loan: loan
        })
    }
    makeLoanFederal = (value, event) => {
        const { loan } = this.state;
        let loanType = '';
        if (value === false) {
            loanType = 'Private';
        }
        loan.data.attributes.loan_type = loanType;
        this.setState({
            federal: value,
            loanType: loanType,
            loan: loan
        })
    }
    changeLoanType = (e) => {
        const { loan } = this.state;
        const loanType = e.value
        loan.data.attributes.loan_type = loanType;

        this.setState({
            loanType: loanType,
            loan: loan
        })
    }
    handleOriginationDateChange = (date) => {
        const { loan } = this.state;
        const originationDate = date;
        loan.data.attributes.origination_date = moment(originationDate).format('Y-MM-DD');
        this.setState({
            originationDate: originationDate,
            loan: loan
        })
    }
    handleOriginationBalanceChange = (values) => {
        const { loan } = this.state;
        const value = values.value;
        const floatValue = values.floatValue;
        const originationBalance = floatValue * 100;
        loan.data.attributes.origination_balance_cents = originationBalance;

        this.setState({
            originationBalance: value,
            loan: loan
        })

    }
    handleCurrentBalanceChange = (values) => {
        const { loan } = this.state;
        const value = values.value;
        const floatValue = values.floatValue;
        const currentBalance = floatValue * 100;
        loan.data.attributes.current_balance_cents = currentBalance;

        this.setState({
            currentBalance: value,
            loan: loan
        })
    }
    handleInterestRateChange = (values) => {
        const { loan } = this.state;
        const value = values.value;
        const floatValue = values.floatValue;
        loan.data.attributes.interest_rate = floatValue;

        this.setState({
            interestRate: value,
            loan: loan
        })
    }
    handleMonthlyPaymentChange = (values) => {
        const { loan } = this.state;
        const value = values.value;
        const floatValue = values.floatValue;
        const monthlyPayment = floatValue * 100;
        loan.data.attributes.monthly_payment_cents = monthlyPayment;

        this.setState({
            monthlyPayment: value,
            loan: loan
        })
    }
    handleSubmit = (e) => {
        if (this.validator.allValid()) {
            const { loan, otherServicerName } = this.state;
            const loanRequest = loan
            if (otherServicerName) {
                const missingServicer = { "servicer_name": otherServicerName }
                this.props.addMissingServicer(missingServicer);
            }
            this.props.addLoan(loanRequest);
            intercomTrackEvent(eventNames.submittedAdvisorNewLoan);
        } else {
            this.validator.showMessages();
        }
    }
    showValidationMessage(event) {
        if (this.validator.fieldValid(event.target.name)) {
            this.validator.hideMessageFor(event.target.name);
            this.setState({
                validationError: {
                    ...this.state.validationError,
                    [event.target.name]: false
                }
            });
        } else {
            this.validator.showMessageFor(event.target.name);
            this.setState({
                validationError: {
                    ...this.state.validationError,
                    [event.target.name]: true
                }
            });
        }
    }
    handleCancel = (e) => {
        this.props.history.push({
            pathname: '/dashboard/loans'
        })
        if (this.state.fromMatch) { //redirects to match when using from match
            this.props.history.push({
                pathname: '/match/accounts'
            })
        }
    }
    render() {
        const {
            advisorServicers,
            loanTypeData,
            federal,
            otherServicerName,
            showOtherServicer,
            currentBalance,
            originationBalance,
            monthlyPayment,
            interestRate,
            originationDate
        } = this.state;


        return (

            <>
                <Form.Group className='mb-4'>
                    <Form.Label >Who is the loan service provider?</Form.Label>
                    <Select
                        className="basic-single"
                        classNamePrefix="reactSelect"
                        onChange={this.changeLoanServicer.bind(this)}
                        onBlur={(e) => this.showValidationMessage(e)}
                        name="servicerId"
                        placeholder='Select Servicer Account'
                        isSearchable={true}
                        options={
                            advisorServicers.map(item => {
                                return {
                                    label: item.attributes.name,
                                    value: item.id
                                }
                            })
                        }
                    />
                    <ValidatorMessage validator={this.validator} fieldName='account_number' fieldValue={this.state.servicerId} message="* Servicer can' t be blank" />
                </Form.Group>
                {showOtherServicer ?
                    <Form.Group className='mb-4'>
                        <Form.Label>What is the name of your servicer?</Form.Label>
                        <Form.Control type="text" name='other_servicer_name' placeholder="Servicer Name" value={otherServicerName} onChange={this.handleInputChange.bind(this)} onBlur={(e) => this.showValidationMessage(e)} />
                        <ValidatorMessage validator={this.validator} fieldName='other_servicer_name' fieldValue={this.state.otherServicerName} message='* Other servicer name required' />
                    </Form.Group>
                    : null}

                <div className="my-4">
                    <div>
                        <h4 className="Gt-slate-5 Gfs-1">Is it a Private or Federal Loan?</h4>
                    </div>
                    <div className='d-flex flex-wrap'>
                        <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (federal === false ? ' Gb-pink-8' : '')} onClick={this.makeLoanFederal.bind(this, false)}>
                            <p className={"mb-0" + (federal === false ? ' text-white' : ' Gt-pink-8')}>
                                PRIVATE
                            </p>
                        </div>

                        <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (federal === true ? ' Gb-pink-8' : '')} onClick={this.makeLoanFederal.bind(this, true)}>
                            <p className={"mb-0" + (federal === true ? ' text-white' : ' Gt-pink-8')}>
                                FEDERAL
                            </p>
                        </div>
                    </div>
                    <div>
                        {
                            this.validator.message(
                                "federal",
                                this.state.federal,
                                "required", {
                                messages: {
                                    required: "* Select an option"
                                }
                            }
                            )
                        }
                    </div>
                </div>
                {federal ?
                    <Form.Group className='mb-4'>
                        <Form.Label >What is the Federal Loan Type?</Form.Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="reactSelect"
                            onChange={this.changeLoanType.bind(this)}
                            onBlur={(e) => this.showValidationMessage(e)}
                            name="loan_type"
                            placeholder='Select Federal Loan Type'
                            isSearchable={true}
                            options={
                                loanTypeData &&
                                loanTypeData.filter((item) => item !== 'Private')
                                    .map((item) => ({
                                        label: item,
                                        value: item
                                    })
                                    )
                            }
                        />
                    </Form.Group>
                    : null}

                <Form.Group className='mb-4'>
                    <Form.Label>On what date did the loan originate?</Form.Label>
                    <DatePicker
                        onKeyDown={event => {
                            event.preventDefault();
                            return false;
                        }}
                        className="form-control"
                        onChange={this.handleOriginationDateChange.bind(this)}
                        dateFormat="MM/dd/yyyy"
                        selected={originationDate}
                        name='originationDate'
                    />
                    <ValidatorMessage validator={this.validator} fieldName='originationDate' fieldValue={this.state.originationDate} message="* Origination date can' t be blank" />
                </Form.Group>

                <Form.Group className='mb-4'>
                    <Form.Label className='mb-0'>What was the original borrowed amount?</Form.Label>
                    <Form.Text className="text-muted sans-medium">If your access to Vault was provided by your employer, this information will not be shared with them</Form.Text>
                    <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={originationBalance}
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        className="form-control"
                        id="origination_balance_cents"
                        name="origination_balance_cents"
                        onBlur={e => this.showValidationMessage(e)}
                        onValueChange={(values) => { this.handleOriginationBalanceChange(values) }}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='origination_balance_cents' fieldValue={this.state.originationBalance} message="* Borrowed Amount must be greater than zero" />
                </Form.Group>

                <Form.Group className='mb-4'>
                    <Form.Label className='mb-0'>What is the current balance?</Form.Label>
                    <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={currentBalance}
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        className="form-control"
                        id="current_balance_cents"
                        name="current_balance_cents"
                        onBlur={e => this.showValidationMessage(e)}
                        onValueChange={(values) => { this.handleCurrentBalanceChange(values) }}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='current_balance_cents' fieldValue={this.state.currentBalance} message="* Current Balance can't be blank" />
                </Form.Group>

                <Form.Group className='mb-4'>
                    <Form.Label className='mb-0'>What is the interest rate?</Form.Label>
                    <Form.Text className="text-muted sans-medium">Don't know the exact rate? Include an estimate.</Form.Text>
                    <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={interestRate}
                        suffix="%"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        type="text"
                        className="form-control"
                        id="interest_rate"
                        name="interest_rate"
                        onBlur={e => this.showValidationMessage(e)}
                        onValueChange={(values) => { this.handleInterestRateChange(values) }}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='interest_rate' fieldValue={this.state.interestRate} message="* Interest Rate must be greater than zero" />
                </Form.Group>

                <Form.Group className='mb-4'>
                    <Form.Label className='mb-0'>What is the minimum monthly payment?</Form.Label>
                    <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={monthlyPayment}
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        className="form-control"
                        id="monthly_payment_cents"
                        name="monthly_payment_cents"
                        onBlur={e => this.showValidationMessage(e)}
                        onValueChange={(values) => { this.handleMonthlyPaymentChange(values) }}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='monthly_payment_cents' fieldValue={this.state.monthlyPayment} message="* Monthly Payment must be greater than zero" />
                </Form.Group>
                <CustomModal svgComponent={
                    <div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div>
                }
                    modalProp={{ ...this.state.modalProp }} />

            </>
        )
    }
}
AddLoanManually.propTypes = {
    getAdvisorServicers: PropTypes.func.isRequired,
    getLoanType: PropTypes.func.isRequired,
    addLoan: PropTypes.func.isRequired,
    addMissingServicer: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    advisorServicers: state.advisor.advisorServicers,
    loanTypeData: state.advisor.loanTypeData,
    addLoanData: state.advisor.addLoanData,
    addMissingServicerResponse: state.advisor.addMissingServicerResponse,
    errors: state.errors
});
export default connect(mapStateToProps, { getAdvisorServicers, getLoanType, addLoan, addMissingServicer })(AddLoanManually);