import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { responseMessage } from "../../utils/alert";
import Header from "../Includes/Header/Header";
import Footer from "../Includes/Footer/Footer";
import {
  fetchTuitions,
  fetchTuitionById,
} from "../../actions/tuitionReimbursementActions";
import { numberFormat } from "../../common/js/jsFunctions";
import { tuitionReimburseStatus } from "../TuitionReimbursementStatus/TuitionReimbursementStatus.constant";
import { setTuitionReimburseStatus, getCustomerNameFromStorage } from './../TuitionReimbursementStatus/TuitionReimbursementStatus.constant';
import BoxArrowUpRight from "react-bootstrap-icons/dist/icons/box-arrow-up-right";
import ReactTooltip from "react-tooltip";
import Clock from "react-bootstrap-icons/dist/icons/clock";
import Check2 from "react-bootstrap-icons/dist/icons/check2";
import ExclamationCircle from "react-bootstrap-icons/dist/icons/exclamation-circle";
import XCircle from "react-bootstrap-icons/dist/icons/x-circle";
import PlusLg from "react-bootstrap-icons/dist/icons/plus-lg";
import { formatDateUsingMoment, monthDateYearSlash } from "../../utils/dateUtil";
import TRAdviceAndTools from "../TRAdviceAndTools/TRAdviceAndTools";

class TuitionReimbursementHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      tuitionsData: {},
      errors: null,
    };
    this.handleShow = this.handleShow.bind(this);
  }
  componentDidMount() {
    document.title = "Vault | Tuition Reimbursement";
    const { fetchTuitions } = this.props;
    fetchTuitions();
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tuitionsData !== this.props.tuitionsData) {
      nextProps.tuitionsData.data.sort((a, b) => {
        return (
          Date.parse(b.attributes.created_at) -
          Date.parse(a.attributes.created_at)
        );
      });
      this.setState({
        tuitionsData: nextProps.tuitionsData,
      });
    }
    if (this.props.errors !== nextProps.errors) {
      responseMessage("error", nextProps.errors.errors, "");
    }
  };

  handleShow(tuitionId, e) {
    const { fetchTuitionById } = this.props;
    fetchTuitionById(tuitionId);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  exportToCsv(e) {
    const { tuitionsData } = this.state;
    // Headers for each column
    let headers = ["Date,Account,Amount,Status"];

    let servicerAccountData = tuitionsData.included.filter(
      (row) => row.type === "servicer_accounts"
    );

    // Convert service account data to a csv
    let serviceAccountCsv = tuitionsData.data.reduce((ServiceAccount, item) => {
      let amount = "N/A";
      if (item.attributes.amount_cents !== 0) {
        amount = item.attributes.amount_cents;
      }
      let status = setTuitionReimburseStatus(item.attributes.status);

      const created_at = formatDateUsingMoment(item.attributes.created_at, monthDateYearSlash);
      const account_number_arr = this.filterAccountNumber(servicerAccountData, item);
      const account_number = account_number_arr.length > 0 ? account_number_arr[0] : '-'

      ServiceAccount.push([created_at, account_number, amount, status]);
      return ServiceAccount;
    }, []);

    const serviceAccountData = [...headers, ...serviceAccountCsv].join("\n");
    const fileName = "tuition-reimbursement-history.csv";
    const fileType = "text/csv";
    this.downloadFile(serviceAccountData, fileName, fileType);
  }

  filterAccountNumber(accountArray, tuitionData) {
    return accountArray.filter(data => data.id === tuitionData.relationships.servicer_account.data.id).map(item => item.attributes.account_number);
  }
  downloadFile(data, fileName, fileType) {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }
  render() {
    const { tuitionsData } = this.state;
    let servicerAccountData = {};

    if (tuitionsData?.data?.length > 0) {
      servicerAccountData = tuitionsData.included.filter(
        (item) => item.type === "servicer_accounts"
      );
    }

    const customerName = getCustomerNameFromStorage();
    return (
      <>
        <Header hideNotificationAndProfileDropdown={true} />

        <div className="G-page-background-color">
          <div className="G-page-main-container p-4 bg-white Gborder-05 rounded-lg my-5">
            <h1>Hey <span className="G-text-grad Gfs-225 sans-semibold">{customerName}!</span> </h1>
            <p className="sans-medium">Welcome back! Here’s an overview of your dashboard</p>

            <div className="pinkCircularDiv mt-4">
              <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 0.75L0.25 8.25L5.25 10.975V18.475L14 23.25L22.75 18.475V10.975L25.25 9.6125V18.25H27.75V8.25L14 0.75ZM22.525 8.25L14 12.9L5.475 8.25L14 3.6L22.525 8.25ZM20.25 16.9875L14 20.4L7.75 16.9875V12.3375L14 15.75L20.25 12.3375V16.9875Z" fill="var(--icon-color)" />
              </svg>

            </div>
            <h2 className="my-3">Tuition Reimbursement History</h2>
            <p className="sans-medium w-50 mob-w100-900p">View and download your reimbursement requests here. Don’t forget to check your employee handbook or employment contract to review the terms of your employer’s educational expenses policy.</p>

            <div className="Gtable-div">
              <Table borderless className="Gborder-05">
                <thead className="Gt-head">
                  <tr>
                    <th className="Gfs-075 p-3">Date</th>
                    <th className="Gfs-075 p-3">Account</th>
                    <th className="Gfs-075 p-3">Amount</th>
                    <th className="Gfs-075 p-3">Status</th>
                    <th className="Gfs-075 p-3"></th>
                  </tr>
                </thead>
                <tbody className="Gt-body">
                  {tuitionsData?.data?.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="text-center font-weight-bold">
                        You currently do not have any requests.
                      </td>
                    </tr>
                  ) : (
                    tuitionsData?.data?.map((row, i) => {
                      let linkUrl = "/tuition-reimbursement-status/" + row.id;
                      let status = setTuitionReimburseStatus(row.attributes.status);
                      let amount = "N/A";
                      if (row.attributes.amount_cents !== 0) {
                        amount = numberFormat(
                          row.attributes.amount_cents / 100
                        );
                      }

                      return (
                        <tr key={i}>
                          <td>
                            {formatDateUsingMoment(row.attributes.created_at, monthDateYearSlash)}
                          </td>
                          <td>
                            {this.filterAccountNumber(servicerAccountData, row)}
                          </td>
                          <td>{amount}</td>
                          <td className="stats">
                            {status === tuitionReimburseStatus.pending ? <span className="Gt-orange-3"> <Clock className="mr-1" /> {status} </span> : ''}
                            {status === tuitionReimburseStatus.approved ? <span className="Gt-green-6"> <Check2 className="mr-1" /> {status} </span> : ''}
                            {status === tuitionReimburseStatus.rejected ? <span className="Gt-orange-5"> <ExclamationCircle className="mr-1" /> {status} </span> : ''}
                            {status === tuitionReimburseStatus.needMoreInfo ? <span className="Gt-red-5"> <XCircle className="mr-1" /> {status} </span> : ''}

                          </td>
                          <td className="d-flex justify-content-around">

                            <Link
                              className="Gt-pink-8"
                              to={linkUrl}
                            >
                              <BoxArrowUpRight className='G-no-outline' size={20} data-tip data-for='viewRequest' />
                              <ReactTooltip id="viewRequest" place="top" effect="solid" >View details</ReactTooltip>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>

            <div className="d-flex justify-content-end mob-btn-div-column my-2">
              {tuitionsData?.data?.length > 0 && (
                <button
                  className="G-cancel-button mx-3 mob-separate-v6"
                  onClick={this.exportToCsv.bind(this)}
                >
                  {/*DOWNLOAD HISTORY*/}
                  Download request history
                </button>
              )}
              <Link
                className="G-orange-button Gb-transparent mob-separate-v6"
                to={{
                  pathname: "/tuition-reimbursement",
                  state: { tuitions: this.state.tuitionsData },
                }}
              ><button className="Ga border-0 text-white Gb-orange-5"> <PlusLg /> Request Tuition Reimbursement</button>

              </Link>
            </div>
            {/* add resource */}
            <TRAdviceAndTools />
          </div>

        </div>

        <Footer />
      </>
    );
  }
}

TuitionReimbursementHistory.propTypes = {
  fetchTuitions: PropTypes.func.isRequired,
  fetchTuitionById: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  tuitionsData: state.tuition_reimbursement.tuitionsData,
  tuitionByIdData: state.tuition_reimbursement.tuitionByIdData,
  errors: state.errors,
});
export default connect(mapStateToProps, { fetchTuitions, fetchTuitionById })(
  TuitionReimbursementHistory
);
