import React, { Component } from "react";
import $ from 'jquery';

import {
  payoff_time_in_words,
  currency_format,
} from "../../eligibility-result";
import {
  monthsToHumanize,
  numberFormat,
} from "../../../../common/js/jsFunctions";
import { ArrowRight, } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";

class PayFasterExtraWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handelRangeTracker = (e) => {
    this.props.handelRangeTracker(e);
    this.props.trackExtraPayment(e);
    this.rangeTrackerCallback();
  };
  handleRangeTrackerMouseUp = (e) => {
    this.props.trackExtraPayment(e);
  };
  rangeTrackerCallback() {
    const control = $('#myRange');

    const controlMin = control.attr('min');
    const controlMax = control.attr('max');
    const controlVal = control.val();
    const controlThumbWidth = control.data('thumbwidth');
    const popupDivWidth = 140;  // 140 is half width of total popup div

    const range = controlMax - controlMin;
    const position = ((controlVal - controlMin) / range) * 100;
    const positionOffset = Math.round((controlThumbWidth * position) / 100) - controlThumbWidth / 2 + popupDivWidth;

    const output = $('#payfaster-payoff-slider__bubble');
    let finalPosition = 0

    if (window.outerWidth < 450) {

      finalPosition = '0%'
    }
    else {
      finalPosition = position > 25 ? position < 75 ? `calc(${position}% - ${positionOffset}px)` : 'right' : '0%'
    }
    if (finalPosition === 'right') {
      output.css({ 'right': '0px', 'left': 'unset' });
    } else {
      output.css('left', finalPosition);
    }
    let arrowPosition = 50;
    if (position < 25) {
      let currentPos = position + 1 < 13 ? position + 2 : position + 1
      arrowPosition = (Math.round(currentPos) * 2) < 5 ? 5 : (Math.round(currentPos) * 2)
    } else if (position > 75) {
      let currentPos = position - 2
      arrowPosition = (Math.round(currentPos) * 2 - 100) > 95 ? 95 : (Math.round(currentPos) * 2 - 100)
    }
    document.documentElement.style.setProperty('--slider-popup-left-value', `${arrowPosition}%`);
  }

  render() {
    const { program, existingLoansAdvisable, matchEnabled, extraAmountValue } =
      this.props;
    let baseResult = "";
    let modeledResult = "";

    let payoffDifference = "";
    let payoff = "";
    let payoffFaster = "";
    let payoffMessage = "";
    let accruedInterest = 0;
    let interest = "";
    let interestSaved = "";
    let totalPaid = 0;
    let extraAmountInteger = 0;
    let total = 0;

    if (Object.keys(program).length > 0 && program.result) {
      baseResult = program.result;
      modeledResult = matchEnabled
        ? program.resultWithMatchAndExtra
        : program.resultWithExtra;

      extraAmountInteger = extraAmountValue;

      // includeMatch = extraAmountInteger >= 0 && existingLoansAdvisable;

      payoffDifference =
        Number(baseResult.payoffTime) - Number(modeledResult.payoffTime);
      payoffFaster = payoffDifference > 0;
      payoffMessage = monthsToHumanize(payoffDifference) || null;
      payoff = payoffMessage;

      accruedInterest = modeledResult.accruedInterest;

      interest =
        baseResult.accruedInterest > 0
          ? numberFormat(Math.round(baseResult.accruedInterest))
          : "$0";
      interestSaved =
        Number(baseResult.accruedInterest) -
        Number(modeledResult.accruedInterest);

      totalPaid = modeledResult.totalPaid
        ? currency_format(modeledResult.totalPaid)
        : "$" + 0;

      const impactAmountDiff =
        Number(baseResult.totalPaid) - Number(modeledResult.totalPaid);
      total = impactAmountDiff;
      this.rangeTrackerCallback();
    }

    return (

      <>
        <div className='G-grid11-2'>
          <div className="my-5 position-relative">
            <div id="payfaster-payoff-slider__bubble" className='G-slider-popup Gbottom-5 position-absolute'>
              <div className="p-3 py-4 ">
                <Row>
                  <Col lg={12}>
                    <Row><Col className='text-center'><label className='G-badge-purple'>
                      MONTHLY PAYMENT</label></Col></Row>
                    <Row><Col className='text-center'>
                      <span className='Gfs-1 sans-semibold'>
                        {baseResult ? currency_format(baseResult.initialMinimumPayment) : ''}
                      </span>
                      &nbsp;
                      {program?.resultWithMatch?.paymentProgram?.currentExtraPayment ?
                        <>
                          <br />
                          <span className='Gfs-15 sans-semibold'>+</span>
                          <br />
                          <span className="Gt-green-6">
                            {currency_format(
                              program?.resultWithMatch?.paymentProgram?.currentExtraPayment
                            )}
                            &nbsp; Contribution&nbsp;
                          </span>
                        </> : null}
                      <br /> <span className='Gfs-15 sans-semibold'>+</span>  <br />
                      <span className='Gfs-125 sans-bold Gt-pink-8'>
                        {existingLoansAdvisable === false && program.name === 'Existing' ? '*' : ''}
                        {program ? ' $' + program.extraPayment + ' Extra' : ''}
                      </span>
                    </Col></Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className='G-mt-18'>
              <input
                className="G-pink-slider"
                type="range"
                min="0"
                max="1000"
                step="10"
                id="myRange"
                value={extraAmountInteger}
                onChange={this.handelRangeTracker.bind(this)}
                data-thumbwidth="25"  // width of the circle
              />
            </div>

          </div>
          <div className="Gborder-05 d-flex flex-column justify-content-between p-4 rounded-lg">
            <div className='mb-4'>
              <label className="G-badge-purple ">
                Time Left to pay
              </label>
              <div className="d-flex">
                <label className="Gfs-125">
                  {modeledResult ? payoff_time_in_words(modeledResult) : ''}
                </label>
                {payoffFaster ?
                  <>
                    <label>
                      <ArrowRight className='mx-3 mt-1' size={24} />
                    </label>
                    <label className={`Gfs-125 ${extraAmountInteger !== 0 ? "Gt-green-6" : ""} ${payoffFaster ? "Gt-green-6" : "Gt-yellow-6"} `}>{payoff ? payoff : ''} faster!</label>
                  </>
                  : null}
              </div>
            </div>
            <div className='mb-4'>
              <label className="G-badge-purple ">
                REMAINING Interest
              </label>
              <div className="d-flex">
                <label className="Gfs-125">
                  {
                    accruedInterest > 0 ?
                      currency_format(accruedInterest)
                      : '$' + 0
                  }
                </label>
                {interestSaved > 0 ?
                  <><label>
                    <ArrowRight className='mx-3 mt-1' size={24} />
                  </label>
                    <label className={`Gfs-125 ${extraAmountInteger !== 0 ? "Gt-green-6" : ""} ${interestSaved > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{interest ? currency_format(interestSaved) : ''} {interestSaved > 0 ? 'saved!' : 'more'}</label></>
                  : null}
              </div>
            </div>
            <div className='mb-4'>
              <label className="G-badge-purple ">
                Projected Pay
              </label>
              <div className="d-flex">
                <label className="Gfs-125 ">
                  {totalPaid}
                </label>
                {total > 0 ?
                  <><label>
                    <ArrowRight className='mx-3 mt-1' size={24} />
                  </label>
                    <label className={`Gfs-125 ${extraAmountInteger !== 0 ? "Gt-green-6" : ""} ${total > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{currency_format(total)} {total > 0 ? 'saved!' : 'more'}</label></>
                  : null}
              </div>
            </div>
          </div>
        </div>
        <label className="mt-5 Gfs-15 sans-semibold">
          Two common methods for approaching repayment
        </label>
        <div className="G-grid11-2 mt-4">
          <div>
            <div className="d-flex">
              <div className="my-auto">
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                  <g clipPath="url(#clip0_2807_5107)">
                    <path d="M14.1414 15.7657L12.1414 13.099H8.14136V22.4324H21.4747L17.4747 17.099L14.1414 15.7657ZM15.1614 17.6124L10.808 19.0657L9.47469 18.619V17.359L10.808 17.8057L13.4414 16.9257L15.1614 17.6124ZM11.4747 14.4324L12.5214 15.8257L10.808 16.399L9.47469 15.9524V14.4324H11.4747ZM9.47469 21.099V20.0257L10.808 20.4724L16.828 18.4657L18.808 21.099H9.47469ZM18.1414 11.7657V8.43235L14.808 7.76569L12.808 9.09902V11.7657L14.808 13.099L18.1414 11.7657ZM14.1414 9.81235L15.088 9.17902L16.808 9.52569V10.8657L14.9614 11.6057L14.1414 11.0524V9.81235ZM19.1414 12.4324L17.4747 13.7657V15.7657L19.1414 17.099L22.1414 15.7657V13.099L19.1414 12.4324ZM20.808 14.899L19.3414 15.5524L18.808 15.1257V14.4057L19.4747 13.8724L20.808 14.1657V14.899Z" fill="var(--icon-color)" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2807_5107">
                      <rect width="16" height="16" fill="white" transform="translate(7 7.5)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <label className="mb-0 Gfs-125 ml-2">
                Avalanche Method
              </label>
            </div>
            <div className="rounded-lg Gborder-05 d-flex p-2 mt-4 G-w-fit-content">
              <div className="d-flex">
                <div className="my-auto">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="var(--icon-color)" />
                  </svg>
                </div>
                <label className="my-auto Gfs-125 ml-2">
                  AVALANCHE
                </label>
              </div>
              <div className="d-flex ml-4">
                <div className="my-auto">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#B9B9B9" />
                  </svg>
                </div>
                <label className="my-auto Gfs-125 ml-2">
                  CURRENT PLAN
                </label>
              </div>
            </div>
            <div id='avalancheAmCharts' className='mt-5'>
              Chart
            </div>
          </div>


          <div>
            <div className="d-flex">
              <div className="my-auto">
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                  <path d="M14.9999 10.1667C17.9399 10.1667 20.3333 12.56 20.3333 15.5C20.3333 18.44 17.9399 20.8333 14.9999 20.8333C12.0599 20.8333 9.66659 18.44 9.66659 15.5C9.66659 12.56 12.0599 10.1667 14.9999 10.1667ZM14.9999 8.83334C11.3199 8.83334 8.33325 11.82 8.33325 15.5C8.33325 19.18 11.3199 22.1667 14.9999 22.1667C18.6799 22.1667 21.6666 19.18 21.6666 15.5C21.6666 11.82 18.6799 8.83334 14.9999 8.83334Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <label className="mb-0 Gfs-125 ml-2">
                Snowball Method
              </label>
            </div>
            <div className="rounded-lg Gborder-05 d-flex p-2 mt-4 G-w-fit-content">
              <div className="d-flex">
                <div className="my-auto">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="var(--icon-secondary-color)" />
                  </svg>
                </div>
                <label className="my-auto Gfs-125 ml-2">
                  SNOWBALL
                </label>
              </div>
              <div className="d-flex ml-4">
                <div className="my-auto">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#B9B9B9" />
                  </svg>
                </div>
                <label className="my-auto Gfs-125 ml-2">
                  CURRENT PLAN
                </label>
              </div>

            </div>
            <div id='snowballAmCharts' className='mt-5'>
              Chart
            </div>
          </div>
        </div >

      </>
    );
  }
}
export default PayFasterExtraWidget;
