//import reduce from 'lodash/reduce';
import * as Sentry from '@sentry/browser';
import repayment_calculator  from 'program_calculator';

export function calculatorExisting(customer_array,eligibilityResults){
  
  if(eligibilityResults){
    let eligibility_results         = eligibilityResults;
    let eligibilityResultsData      = eligibilityResults.data;
    let eligibility_results_included= eligibility_results.included;
    
    

    /* Start Calculate Existing Plan Loans */ 
    //let hasQualifyingLoan = false; 
    const existingPlanData = eligibilityResultsData?.find(item => item.id === 'existing');

    const existingPlanLoans = existingPlanData?.relationships.loans.data;
    
    let existingPlanArray = [];
    for(var indexEL=0; indexEL<existingPlanLoans?.length;indexEL++){
      const existingPlanLoanId = existingPlanLoans[indexEL].id;

      eligibility_results_included.forEach((element,i) => {
        if (element.id===existingPlanLoanId) {
          element.attributes.advisable = false;
          element.attributes.customer = null;

          element.attributes.interestRate = Number(element.attributes.interest_rate);
          element.attributes.loanType     = element.attributes.loan_type;
          element.attributes.originationDate= element.attributes.origination_date;
            
          delete element.attributes['checksum_identifier'];
          delete element.attributes['data_source'];
          delete element.attributes['repayment_program'];
          delete element.attributes['servicer_name'];
          delete element.attributes['other_servicer_name'];
          delete element.attributes['interest_rate'];
          delete element.attributes['loan_type'];
          delete element.attributes['origination_date'];

          existingPlanArray[i] = element.attributes;        
        }
      });
    }
    let existingPlanObj = {
      'name':existingPlanData['attributes']['name'],
      'loans':existingPlanArray
    };
    /*const convertedCustomer = convertCamelCaseCustomer(customer_array);
    
    convertedCustomer.lowBalanceFactor = Number(convertedCustomer.lowBalanceFactor);
    convertedCustomer.agiGrowthRate = Number(convertedCustomer.agiGrowthRate);

    const customerArray   = calculatorTranslatorCustomer(convertedCustomer);
    console.log(customerArray);*/
    return existingPlanObj;
    //console.log(JSON.stringify(existingPlanObj));
    //console.log(JSON.stringify(customer_array));
    //const existingPlan = calculatorProgramResultExisting(customer_array, existingPlanObj['loan'],existingPlanObj['name'],0);
    /*let existingPlanObj = {
      'name':existingPlanData['attributes']['name'],
      'loan':convertHyphensCamelCaseLoansEligibilityCustom(existingPlanArray)
    };
    const existingPlan = calculatorProgramResultExisting(customer_array, existingPlanObj['loan'],existingPlanObj['name'],0);
    const existingPlanBorrower = existingPlan.borrower.loans;
    let existingLoansAdvisable = existingPlanBorrower.every(function (e) {
      return e.advisable === true
    });
    let finalData = {
      existingPlan:existingPlan,
      existingLoansAdvisable:existingLoansAdvisable
    }
    return finalData;*/
  }else{
    return {};
  }
}
export function calculateForLoans(loansToConsolidate,otherLoans,customer,rangeValueAsPercentage,selectedTermInMonths){
 
  const loans     = loansToConsolidate;
  const interestRate = rangeValueAsPercentage;
  const termMonths = selectedTermInMonths;

  const consolidatedLoan = consolidate(loans, interestRate, termMonths);
  // unqualifiedLoans + consolidatedLoan
  const amortLoans         = [].concat(otherLoans, [consolidatedLoan]);
  const serializedCustomer = calculatorTranslator(customer, amortLoans);
  const calculatorResults  = repayment_calculator(serializedCustomer, { program: 'Existing' });
  
  const results = {
    'calculatorResults':calculatorResults,
    'amortLoans':amortLoans
  };

  return results;
}
export function consolidate(loans,interestRate,termMonths){
  const balance = loans.reduce((total, loan) => total + loan.balance, 0) / 100;
  const monthlyPayment = calcMonthlyPayment(balance, termMonths, interestRate);
  
  const loanJson = {
    balance:balance,
    interestRate:interestRate,
    monthlyPayment:monthlyPayment,
    status:'RP',
    originationDate:new Date().toString(),
    loanType:'Private'
  }

  return DummyLoanModel(loanJson);
}
export function calcMonthlyPayment(balance, termMonths, interestRate) {
  const compoundedInt = (interestRate / 12) / 100;
  return (compoundedInt * balance) / (1 - Math.pow((1 + compoundedInt), -termMonths));
}
export function DummyLoanModel(loanJson){
  Object.keys(loanJson).forEach(key => {
    if (key === 'balance' || key === 'monthlyPayment') {
      loanJson[key] = +(loanJson[key] * 100);
    }
  })
  return loanJson;
}
export function calculatorTranslator(customer, loans_array) {

  let jsCustomer = customer;
  //let jsCustomer = convertCentsToDollars(customer);
  //jsCustomer     = renameKeys(jsCustomer, [['agiCents', 'agi'], ['spouseAgiCents', 'spouseAgi'], ['spouseFederalLoanDebtCents', 'spouseDebt']]);

  const defArray = loans_array.toArray ? loans_array.toArray() : loans_array;
  let jsLoans = convertCentsToDollars(defArray.map(loan => loan));
  jsLoans = jsLoans.map(loan => {
    return renameKeys(loan, [['monthlyPayment', 'minimumPayment'], ['interestRate', 'rate'], ['loanType', 'type'], ['originationDate', 'startDate']]);
  });
  jsCustomer.loans = jsLoans;
  
  return jsCustomer;
}
export function renameKeys(obj, oldNewKeyArray) {

  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object' && obj[item[0]]!==undefined){
      Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
      delete obj[item[0]];
    }
  });
  return obj;
}
export function convertCentsToDollars(objOrArray) {
  if (objOrArray.length !== undefined) {
    return objOrArray.map(item => _convertToCents(item));
  } else {
    return _convertToCents(objOrArray);
  }
}
export function _convertToCents(obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'number' && key !== 'familySize' && key !== 'lowBalanceFactor') {
      obj[key] = +(obj[key] / 100).toFixed(5);
    }
  });
  return obj;
}
export function _convertToCentsPlanDeatils(obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'number' && key !== 'familySize') {
      obj[key] = +(obj[key] / 100).toFixed(5);
    }
  });
  return obj;
}
/*export function calculatorResult(customer, loans,programName, extraPayment = 0){
  const customerData = calculatorTranslatorData(customer, loans);
  console.log(JSON.stringify(customerData));
  const response = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return response;
}
export function calculatorResultWithMatch(customer, loans,programName, extraPayment = 0){
  const customerData = calculatorTranslatorData(customer, loans);
  console.log(JSON.stringify(customerData));
  const response = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return response;
}*/
export function calculatorResult(customerData,programName, extraPayment = 0){
  const response = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return response;
}
export function calculatorResultWithMatch(customerData,programName, extraPayment){
  const response = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return response;
}
export function calculatorResultWithExtra(customerData,programName, extraPayment){
  const response = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return response;
}
export function calculatorResultWithMatchAndExtra(customerData,programName, extraPayment){
  const response = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return response;
}
export function calculatorTranslatorData(shouldConvertCentsToDollars, customer, loans){
  let jsCustomer = _convertToCentsPlanDeatils(customer);
  jsCustomer     = renameKeys(jsCustomer, [['agiCents', 'agi'], ['spouseAgiCents', 'spouseAgi'], ['spouseFederalLoanDebtCents', 'spouseDebt']]);
  
  const defArray = loans.toArray ? loans.toArray() : loans;
  let jsLoans = shouldConvertCentsToDollars ? convertCentsToDollars(defArray.map(loan => loan)) : defArray;
  jsLoans = jsLoans.map(loan => {
    return renameKeys(loan, [['monthlyPayment', 'minimumPayment'], ['interestRate', 'rate'], ['loanType', 'type'], ['originationDate', 'startDate']]);
  });
  jsCustomer.loans = jsLoans;
  return jsCustomer;
}
export function calculate(customerData, options = {}){
  const opts = Object.assign({ program: 'Existing'}, options);
  let result = null;
  try {
    Sentry.configureScope(scope => {
      result = scope.setExtra({ customer: customerData, options });
    })
    //const dt = repayment_calculator(customerData, opts);
    //return dt
    return repayment_calculator(customerData, opts);
  } catch (error) {
    //console.log(error)
    // do nothing
  }
  return result;
}



