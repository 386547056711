import React, { Component } from "react";
import { connect } from "react-redux";

import { responseMessage } from '../../../utils/alert';
import Footer from "../../Includes/Footer/Footer";

import { addFnFContribution, submitContribution, getIndividualContribution } from "../../../actions/fiveTwoNineActions";
import Header from "../../Includes/Header/Header";


class ConfirmContribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestData: this.props.location.requestData,
      servicer_account_id: this.props.match.params.servicer_account_id,
      contributionId: 0,
      contribution: null
    }
  }
  componentDidMount() {
    const { requestData } = this.state;
    if (requestData === undefined) {
      this.props.history.push({
        pathname: '/' + this.state.servicer_account_id + '/contribution-setup'
      })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.interval) clearInterval(this.interval);
    if (this.timerID) clearTimeout(this.timerID);
    if (prevProps.addFnFContributionResponse !== this.props.addFnFContributionResponse) {
      const contributionId = this.props.addFnFContributionResponse.id;
      this.setState({
        contributionId: contributionId
      })
      this.props.submitContribution(this.state.servicer_account_id, contributionId);
    }
    if (prevProps.submitContributionResponse !== this.props.submitContributionResponse) {
      this.props.getIndividualContribution(this.state.servicer_account_id, this.state.contributionId);
    }
    if (prevProps.individualContributionResponse !== this.props.individualContributionResponse) {
      const servicer_account_id = this.state.servicer_account_id;
      const contributionId = this.state.contributionId;

      const contribution = this.props.individualContributionResponse
      const status = contribution.attributes.status;

      if (status === 'in_progress' || status === null) {
        this.interval = setInterval(() =>
          this.checkContributionStatus(servicer_account_id, contributionId),
          5000);
      }

      if (status === "success") {
        responseMessage("success", "Thank you for your contribution!", "");

        this.timerID = setTimeout(() => {
          this.props.history.push({
            pathname: '/' + this.state.servicer_account_id + '/contribution-setup',
            requestData: this.state.requestData
          })
        }, 5000)
      }
      if (status === "fail" || status === "failed") {
        const failure_message = contribution.attributes.failure_message;
        responseMessage("error", failure_message, "");
        this.timerID = setTimeout(() => {
          this.props.history.push({
            pathname: '/' + this.state.servicer_account_id + '/contribution-setup',
            requestData: this.state.requestData
          })
        }, 5000)
      }
    }
    if (prevProps.errors !== this.props.errors && typeof this.props.errors.errors === 'string') {
      responseMessage("error", this.props.errors.errors, "");
    }
  }
  checkContributionStatus(servicer_account_id, contributionId) {
    this.props.getIndividualContribution(servicer_account_id, contributionId)
  }
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }
  onBack(e) {
    this.props.history.push({
      pathname: '/' + this.state.servicer_account_id + '/contribution-setup',
      requestData: this.state.requestData
    })
  }
  onCancel(e) {
    this.props.history.push({
      pathname: '/' + this.state.servicer_account_id + '/contribution-setup',
      requestData: this.state.requestData
    })
  }
  onSubmit(e) {
    const { requestData } = this.state;
    if (requestData) {
      const requestJson = {
        'data': {
          'type': 'contributions',
          'attributes': {
            'dollar_amount': requestData.dollar_amount,
            'bank_name': requestData.bank_name,
            'bank_account_number': requestData.bank_account_number,
            'bank_routing_number': requestData.bank_routing_number,
            'bank_account_type': requestData.bank_account_type,
            'message': requestData.message,
            'servicer_account_id': requestData.servicer_account_id,
            'beneficiary_name': requestData.beneficiary_name,
            'account_owner_name': requestData.account_owner_name,
            'ugift_code': requestData.ugift_code,
            'first_name': requestData.first_name,
            'last_name': requestData.last_name,
            'email': requestData.email,
            'phone_number': requestData.phone_number,
            'sent_at': new Date()
          }
        }
      }
      this.props.addFnFContribution(this.state.servicer_account_id, requestJson);
    } else {
      this.props.history.push({
        pathname: '/' + this.state.servicer_account_id + '/contribution-setup',
      })
    }
  }
  render() {
    let bankAccountType = '';
    if (this.state.requestData) {
      const bank_account_type = this.state.requestData.bank_account_type;
      bankAccountType = bank_account_type[0].toUpperCase() + bank_account_type.substring(1)
    }

    return (
      <>
        {/* <MainHeader {...this.props} /> */}
        <Header showLogoOnly={true} />
        {this.props?.addFnFContributionResponse?.id ?
          (
            <div className="G-page-background-color">
              <div className="G-page-main-container p-4 bg-white Gborder-1 rounded-lg my-5">
                <div>
                  <div className="d-flex justify-content-center">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="#DCF3D1" />
                      <path d="M26.2502 35.2133L21.0377 30.0008L19.2627 31.7633L26.2502 38.7508L41.2502 23.7508L39.4877 21.9883L26.2502 35.2133Z" fill="#52C41A" />
                    </svg>
                  </div>
                  <div className="d-flex flex-column justify-content-center text-center mt-4">
                    <h2>529 Contribution successful</h2>
                    <p className="sans-medium">
                      Congratulations on making a contribution to  {this.state.requestData ? this.state.requestData.beneficiary_name + "'s" : 'N/A'}
                      <br />
                      educational goals! Your generosity is greatly appreciated.
                      <br />
                      A confirmation email has been sent to you with the details of your contribution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
          :
          (<div className="G-page-background-color">
            <div className="G-page-main-container p-4 bg-white Gborder-1 rounded-lg my-5">
              <div className=" p-4 Gborder-05 bg-white G-grid11-2 my-3">
                <div>
                  <div className="">
                    <div className="pinkCircularDiv mb-3">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M18.75 41.25H41.25V38.75H18.75V41.25ZM18.75 36.25H41.25V33.75H18.75V36.25ZM18.75 31.25H41.25V28.75H18.75V31.25ZM18.75 26.25H41.25V23.75H18.75V26.25ZM18.75 18.75V21.25H41.25V18.75H18.75Z" fill="var(--icon-color)" />
                      </svg>
                    </div>
                    <h4>Confirm One Time Contribution</h4>
                    <p className="sans-medium">
                      Thank you for your contribution! Please review the following details before submitting.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-column my-3">
                    <label className="Gt-pink-8">CONTRIBUTION AMOUNT</label>
                    <label> {this.state.requestData ? '$' + this.state.requestData.dollar_amount : 'N/A'}</label>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="Gt-pink-8">BENEFICIARY NAME</label>
                    <label> {this.state.requestData ? this.state.requestData.beneficiary_name : 'N/A'}</label>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="Gt-pink-8">529 ACCOUNT OWNER</label>
                    <label> {this.state.requestData ? this.state.requestData.account_owner_name : 'N/A'}</label>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="Gt-pink-8">UGIFT CODE</label>
                    <label> {this.state.requestData ? this.state.requestData.ugift_code : 'N/A'}</label>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="Gt-pink-8">YOUR NAME</label>
                    <label> {this.state.requestData ? this.state.requestData.first_name + ' ' + this.state.requestData.last_name : 'N/A'}</label>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="Gt-pink-8">YOUR EMAIL</label>
                    <label> {this.state.requestData ? this.state.requestData.email : 'N/A'}</label>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="Gt-pink-8">ACCOUNT TYPE</label>
                    <label> {bankAccountType}</label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mob-btn-div-column">
                <button className="G-cancel-button mx-3 mob-separate-v6" onClick={this.onBack.bind(this)}>
                  Back
                </button>
                <button className="G-orange-button mob-separate-v6" onClick={this.onSubmit.bind(this)}>
                  Continue
                </button>
              </div>
            </div>
          </div>)


        }
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    addFnFContributionResponse: state.five_two_nine.addFnFContributionResponse,
    submitContributionResponse: state.five_two_nine.submitContributionResponse,
    individualContributionResponse: state.five_two_nine.individualContributionResponse,
    errors: state.errors
  };
}
const mapDispatchToProps = {
  addFnFContribution,
  submitContribution,
  getIndividualContribution
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmContribution);

