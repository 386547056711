import { FETCH_GENERAL_TERMS, ACCEPT_GENERAL_TERMS, GET_ERRORS } from "./types";

import { apiAction } from "./apiActions";
import config from '../config';

export const fetchGeneralAgreement = () => {
  return apiAction({
    url: config.apiBaseUrl + "legal_agreements/latest_general_agreement",
    onSuccess: setfetchGeneralAgreement,
    callback: "",
    onFailure: errorfetchGeneralAgreement,
    label: FETCH_GENERAL_TERMS,
  });
};
function setfetchGeneralAgreement(response) {
  return {
    type: FETCH_GENERAL_TERMS,
    payload: response.data,
  };
}
function errorfetchGeneralAgreement(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data,
  };
}
export const acceptGeneralAgreement = (user_agreements) => {
  return apiAction({
    url: config.apiBaseUrl + "user_agreements",
    method: "POST",
    data: user_agreements,
    onSuccess: setUpdateGeneralTerms,
    callback: "",
    onFailure: errorUpdateGeneralTerms,
    label: ACCEPT_GENERAL_TERMS,
  });
};
function setUpdateGeneralTerms(response) {
  return {
    type: ACCEPT_GENERAL_TERMS,
    payload: response.data,
  };
}
function errorUpdateGeneralTerms(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message,
  };
}
