import React from 'react';

const NonRepaymentDisclaimer = () => {
  return (
    <>
      <div className='d-flex Gb-yellow-2 my-4 rounded-lg p-3'>
        <div className='mr-3 '>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 9H11V7H13M13 17H11V11H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#FAAD14" />
          </svg>
        </div>
        <div>
          <p className='sans-medium mb-0 mb-2'>
            You have some loans not in repayment. Feel free to continue, just note we’ve made a few assumptions.
          </p>
          <ul className='G-ul-bullets ml-3'>
            <li className='sans-medium mb-2'>
              If a loan is not in repayment, then a minimum payment is calculated based on a 10 year payment term.
            </li>
            <li className='sans-medium mb-2'>
              The given interest rate and balance of the loan is used in the minimum payment calculation.
            </li>
            <li className='sans-medium mb-2'>
              All other figures (e.g remaining time, remaining interest, etc) are based on the assumed monthly payment
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
export default NonRepaymentDisclaimer;