import React, { useEffect } from 'react';
import Advisor from "../Advisor/Index";
import FiveToNine from "../FiveToNine/Index";
import Tuition from "../Tuition/Index";
import { intercomBoot } from "../customerStatus";
import AutoLogout from '../AutoLogout/AutoLogout'
import { Redirect, Switch } from 'react-router-dom';

const Index = () => {
  useEffect(() => {
    intercomBoot();
  }, [])

  return (
    <AutoLogout>
      <Switch>
        {/* {(localStorage.getItem("advisorEnabled") === 'true' || localStorage.getItem("newAdvisorEnabledPruAccount") === 'true') && Advisor()} */}
        {Advisor()}
        {(localStorage.getItem("savingsPlanEnabled") === 'true' || localStorage.getItem("pruSavingsPlanEnabled") === 'true') && FiveToNine}
        {localStorage.getItem("hasTuitionPlan") === 'true' && Tuition}

        <Redirect to="/error" />
      </Switch>
    </AutoLogout>
  )
}
export default (Index);