import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Header from "../../../Includes/Header/Header";
import Footer from "../../../Includes/Footer/Footer";
import { getDisbursements } from '../../../../actions/fiveTwoNineActions';
import { numberFormat, statusText } from '../../../../common/js/jsFunctions';
import { intercomTrackEvent } from "../../../customerStatus";
import eventNames from "../../../event-names-map";
import Table from 'react-bootstrap/Table';
import S from '../Payments.module.css';
import { paymentHistoryBreadcrumb } from '../Payment.constant';
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import Check2 from 'react-bootstrap-icons/dist/icons/check2';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disbursements: []
    }
  }
  componentDidMount() {
    document.title = "Vault | History";
    const { getDisbursements } = this.props;
    getDisbursements();
    intercomTrackEvent(eventNames.visitedPayHistory);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.disbursements !== this.props.disbursements) {

      this.setState({
        disbursements: nextProps.disbursements
      })
    }
  }
  exportToCsv(e) {
    const { disbursements } = this.state;
    // Headers for each column
    let headers = ['Created,Servicer,Amount,Status'];

    // Convert disbursements data to a csv
    let disbursementsCsv = disbursements.reduce((disbursement, item) => {
      const created_at = moment(item.attributes.created_at).format('MMMM D YYYY');
      const servicer_name = item.attributes.servicer_name;
      const amount = item.attributes.amount;
      const status = statusText(item.attributes.status, item.attributes.settled_at);
      disbursement.push([created_at, servicer_name, amount, status].join(','))
      return disbursement;
    }, []);

    const disbursementData = [...headers, ...disbursementsCsv].join('\n');
    const fileName = 'pay_history.csv';
    const fileType = 'text/csv';
    this.downloadFile(disbursementData, fileName, fileType);
    intercomTrackEvent(eventNames.downloadedPayContribHistory);
  }
  downloadFile(data, fileName, fileType) {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  render() {
    const { disbursements } = this.state;
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color pb-3 Gmin-60vh">

          <div className='G-page-main-container my-5'>
            <Breadcrumb list={paymentHistoryBreadcrumb()} />
            <div className='p-4 Gborder-1 rounded-lg my-3 bg-white'>
              <div className='pinkCircularDiv'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.75 7V13.25L16.0625 16.4L17.025 14.8L12.625 12.1875V7H10.75ZM23.25 9.5V0.75L19.95 4.05C17.925 2.0125 15.1125 0.75 12 0.75C5.7875 0.75 0.75 5.7875 0.75 12C0.75 18.2125 5.7875 23.25 12 23.25C18.2125 23.25 23.25 18.2125 23.25 12H20.75C20.75 16.825 16.825 20.75 12 20.75C7.175 20.75 3.25 16.825 3.25 12C3.25 7.175 7.175 3.25 12 3.25C14.4125 3.25 16.6 4.2375 18.1875 5.8125L14.5 9.5H23.25Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <h2 className='Gfs-175 my-4'>Payment History</h2>
              <div className='Gtable-div'>
                <Table size="sm" className='Gborder-05'>
                  <thead>
                    <tr className={`${S.tableHeader}`}>
                      <th className='text-left'>Date</th>
                      <th className='text-left'>Account</th>
                      <th className='text-left'>Amount</th>
                      <th className='text-left'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disbursements.length > 0 ?
                      disbursements.map((row, i) => {
                        return (<tr key={i} className={`${S.tableBody} Gfs-1 bg-white`}>
                          <td>{moment(row.attributes.created_at).format('MMMM D, YYYY')}</td>
                          <td>{row.attributes.servicer_name}</td>
                          <td>{numberFormat(row.attributes.amount)}</td>
                          <td className='Gt-green-6'> <Check2 /> {statusText(row.attributes.status, row.attributes.settled_at)}</td>
                        </tr>)
                      })
                      : <tr>
                        <td colSpan={4} className='text-center sans-semibold py-3'>
                          <h3 className='Gt-slate-5 Gfs-15 text-center pb-0'>There are no previous transactions. </h3>
                          <p className='mb-0'>Soon you’ll be able to find all your transaction history here.</p>
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>

            </div>
            {disbursements.length > 0 ?
              <div className="text-right">
                <button className="G-cancel-button" onClick={this.exportToCsv.bind(this)}>Download History</button>
              </div>
              : ""}
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

History.propTypes = {
  getDisbursements: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  disbursements: state.five_two_nine.disbursements,
  errors: state.errors
});
export default connect(mapStateToProps, { getDisbursements })(History);
