import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import styles from './Impersonate.module.css'
import { connect } from "react-redux";
import { responseMessage } from '../../utils/alert';

import { fetchImpersonation, postImpersonation } from '../../actions/authenticationActions'
import VaultLogo from "../shared/VaultLogo/VaultLogo";

class Impersonate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      validationError: "",
      errors: null,
    };
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault | Impersonate";
    const { fetchImpersonation } = this.props;
    const token = this.props.match.params.token;
    fetchImpersonation(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.impersonatedUserData !== undefined && this.props.impersonatedUserData !== prevProps.impersonatedUserData) {
      window.location = "/token/" + this.props.impersonatedUserData.token;
      localStorage.setItem("impersonationEnabled", "true");
    }
    if (this.props.errors.errors !== prevProps.errors.errors) {
      const errors = this.props.errors.errors.errors;
      errors && responseMessage("error", errors[0]['detail'], "");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.impersonationData !== prevState.impersonationData) {
      return {
        impersonationData: nextProps.impersonationData
      }
    }
    return null;
  }

  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitPassword = (e) => {
    if (this.validator.allValid()) {
      const { postImpersonation } = this.props;
      const { impersonationData, password } = this.state;

      const reqData = {
        email: impersonationData.email,
        password: password,
        user_id: impersonationData.user_id
      }
      postImpersonation(reqData)
    } else {
      this.validator.showMessages();
    }
  }

  render() {
    const { impersonationData } = this.state;
    return (
      <>
        <div className={`${styles.wrapper} container-content align-center`}>
          <div className="container-custom">
          <VaultLogo /> 
            <h4>Welcome to Genius Pay!</h4>
            <h5 className={`${styles.emailText} mt-4`}>{impersonationData?.email}</h5>
            <div className="registration-box">
              <form>
                <div className="input-group">
                  <label className="">Enter Your Password</label>
                  <input className="input-login" type="password" id="password" name="password" onChange={(e) => this.handleInputChange(e)} />
                  {
                    this.validator.message(
                      "password",
                      this.state.password,
                      "required", {
                      messages: {
                        required: "* Password is required"
                      }
                    }
                    )
                  }
                </div>
                <button
                  className={`${styles.btnImpersonate} c-button c-button-wide mt-4`}
                  data-test-id="feedback-submit"
                  type="button"
                  onClick={this.submitPassword}
                >
                  IMPERSONATE
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    impersonationData: state.authentication.impersonationData,
    impersonatedUserData: state.authentication.impersonatedUserData,
    errors: state.errors
  };
};
const mapDispatchToProps = {
  fetchImpersonation,
  postImpersonation
};
export default connect(mapStateToProps, mapDispatchToProps)(Impersonate);