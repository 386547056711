import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { timeInWords } from '../../../../common/js/jsFunctions';
import { fetchFinicityLinkableServicerAccount } from '../../../Advisor/servicer-account-service';
import {
  convertCamelCaseNotification,
  createFinicityLinkableServicerAccountNotification,
  createNotPaidPdrNotification,
  createAdvisorPayMatchNotifications,
  createGlobalCovid19Notification,
  setReadCovid19Notification,
  setReadNotification
} from '../../../notification-service';
import { getServicerAccounts } from '../../../../actions/fiveTwoNineActions';
import { fetchNotifications } from '../../../../actions/notificationsActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { intercomTrackEvent } from '../../../customerStatus';
import eventNames from "../../../event-names-map";
import { Bell } from 'react-bootstrap-icons';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],

    }
  }

  componentDidMount() {
    const { fetchNotifications, getServicerAccounts } = this.props;
    fetchNotifications();
    getServicerAccounts();
    this.timer = setTimeout(() => {
      this.getNotifications();
    }, 2000);

    const customerData = this.props.dataProps.customerData;

    if (Object.keys(customerData).length > 0) {

      const state = customerData.attributes.state;
      const married = customerData.attributes.married;
      const agiCents = customerData.attributes.agi_cents;
      const selectedService = customerData.attributes.selected_service;

      const hasProfile = (state !== null && married !== null && agiCents !== null) ? true : false;

      const hasPay = customerData.attributes.has_contribution;
      const hasMatch = customerData.attributes.has_match;
      const hasAdvisor = customerData.attributes.has_advisor;
      const hasSavingsPlan = customerData.attributes.has_savings_plan;
      const hasTuitionPlan = customerData.attributes.has_tuition_plan;
      const partnerName = customerData.attributes.partner_name;

      const hasPayOrMatch = (hasPay === true || hasMatch === true) ? true : false;
      const hasAdvisorAndPayOrMatch = (hasAdvisor === true && hasPayOrMatch === true) ? true : false;

      const hasSavingsPlanOnly = (hasSavingsPlan === true && hasAdvisor === false && hasTuitionPlan === false && hasPayOrMatch === false) ? true : false;
      const hasAdvisorAndSavingsPlan = (hasAdvisor === true && hasSavingsPlan === true && hasTuitionPlan === false) ? true : false;

      const savingsPlanEnabled = ((hasSavingsPlanOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'savings_plan')) && partnerName === 'Vault')

      const showProfile = (hasProfile === true && savingsPlanEnabled === false) ? true : false;

      localStorage.setItem("hasAdvisorAndSavingsPlan", hasAdvisorAndSavingsPlan);
      localStorage.setItem("hasAdvisorAndPayOrMatch", hasAdvisorAndPayOrMatch);
      localStorage.setItem("hasPay", hasPay);
      localStorage.setItem("hasMatch", hasMatch);
      localStorage.setItem("showProfile", showProfile);
    }
  }

  componentDidUpdate(prevProps) {
    this.notify();
    if (Object.keys(this.props.notifications).length !== Object.keys(prevProps.notifications).length) {
      const note = convertCamelCaseNotification(this.props.notifications);
      this.setState({
        notifications: note
      })
    }
    if (prevProps.updateServiceResponse !== this.props.updateServiceResponse) {
      window.location.href = '/token/' + localStorage.getItem('userToken');
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getNotifications() {
    const { customerData, employer } = this.props.dataProps;
    const { notifications, servicerAccounts } = this.state;
    const servicerAccount = null;

    if (customerData && employer) {
      const finicityLinkableServicerAccounts = fetchFinicityLinkableServicerAccount(employer, servicerAccounts);

      if (finicityLinkableServicerAccounts.length === 1) {
        createFinicityLinkableServicerAccountNotification(notifications, customerData);
      }
      createNotPaidPdrNotification(notifications, employer);
      createAdvisorPayMatchNotifications(notifications, customerData, servicerAccount)
      createGlobalCovid19Notification(notifications, customerData);
    }
    this.setState({
      notifications: notifications
    });
  }

  notify = () => {
    const customerData = this.props?.dataProps?.customerData;
    const matchAmount = customerData?.attributes?.match[0]?.amount;
    const employerName = customerData?.attributes?.employer_name;
    const hasPay = customerData?.attributes?.has_contribution;
    const payServicerAccount = customerData?.attributes?.number_of_pay_active_servicer_accounts === 0;
    const showPayToast = hasPay && payServicerAccount && !localStorage.getItem("notificationPopupShown");

    const matchPercentage = customerData?.attributes?.match_percentage;
    const showMatchToast = customerData?.attributes?.has_match && this.state.servicerAccounts?.length > 0 && !localStorage.getItem("notificationPopupShown");

    const state = customerData?.attributes?.state;
    const married = customerData?.attributes?.married;
    const agiCents = customerData?.attributes?.agi_cents;
    const hasProfile = (state !== null && married !== null && agiCents !== null) ? true : false;
    const hasAdvisor = customerData?.attributes?.has_advisor;
    const showAdvicerToast = hasAdvisor && !hasProfile && !localStorage.getItem("notificationPopupShown");

    showPayToast && toast(
      <div className="toast-message" onClick={() => localStorage.setItem("notificationPopupShown", true)}>
        <button type="button" className="toast-close-button" onClick={() => localStorage.setItem("notificationPopupShown", true)}>×</button>
        You have a ${matchAmount} student loan contribution from {employerName} waiting for you!
        <br />
        Click here <a href="pay/overview"> here </a> to start receiving your funds!
      </div>
      , {
        toastId: "notificationToast",
      }
    )
    showMatchToast && toast(
      <div className="toast-message" onClick={() => localStorage.setItem("notificationPopupShown", true)}>
        <button type="button" className="toast-close-button" onClick={() => localStorage.setItem("notificationPopupShown", true)}>×</button>
        You have a student loan matching offer from {employerName} of up to {matchPercentage}%.
        <br />
        Click <a href="match/overview"> here </a> to start tracking your loan payments!
      </div>
      , {
        toastId: "notificationToast",
      }
    )
    showAdvicerToast && toast(
      <div className="toast-message" onClick={() => localStorage.setItem("notificationPopupShown", true)}>
        <button type="button" className="toast-close-button" onClick={() => localStorage.setItem("notificationPopupShown", true)}>×</button>
        Explore ways to optimize your student loan repayment to reach your financial goals!
        <br />
        Click here <a href="intro/advisor"> here </a> to get started!
      </div>
      , {
        toastId: "notificationToast",
      }
    )
  }

  handleNotificationClick = (notification, e) => {
    intercomTrackEvent(eventNames.clickedOnNotification);
    switch (notification.name) {
      case 'covid19':
        setReadCovid19Notification(notification);
        window.open(
          'https://get.vault.co/cares-act',
          '_newtab'
        );

        break;
      case 'advisor-setup':
        //this.get('router').transitionTo('intro.advisor', { queryParams: { bypass: true }});
        setReadNotification(notification);
        window.location.href = '/intro/advisor';
        break;
      case 'pay-setup':
        setReadNotification(notification);
        window.location.href = '/pay/overview';
        break;
      case 'match-setup':
        setReadNotification(notification);
        window.location.href = '/match/overview';
        break;
      case 'finicity-linkable':
        setReadNotification(notification);
        window.location.href = '/pay/overview';
        break;
      default:
        window.location.href = '/intro';
        break;
    }
  }

  render() {
    const { notifications } = this.state;
    return (
      <>
        <div className={`nav-link position-relative my-2 mr-2  ` + (this.props.mainIcon ? 'notification-main-container' : 'notification-main-container-v2')}>
          {/* <span className={`${s.counter} cart-counter`}>2</span> */}
          {notifications.length > 0 ?
            <span className={`cart-counter`}>{notifications.length}</span>
            : null}
          <div className='notification_alert'>
            <Bell size={20} className='color-white' />

            <div className='notification_pop'>
              <h3>Notification</h3>

              {notifications.length > 0 ?
                notifications.map((note, i) => {
                  return (<div key={i} className='notification_txt'>
                    <span >
                      <Bell />
                    </span>
                    <div onClick={(e) => this.handleNotificationClick(note, e)} className='notification_right_text'>
                      <h6>{note.description}</h6>
                      <span className="time">{timeInWords(note.createdAt)}</span>
                    </div>
                  </div>)
                })
                :
                <div className='notification_txt'>
                  <div className='notification_right_text'>
                    <h6>You have 0 notifications</h6>
                  </div>
                </div>
              }
              {notifications.length > 0 ?
                <span>  <NavLink className='notification' to="/notifications">
                  View all notifications
                </NavLink>
                </span>
                : null}
            </div>
          </div>
        </div>
      </>


    )
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
    updateServiceResponse: state.five_two_nine.updateServiceResponse,
    updateStatusUrlResponse: state.customer_array.updateStatusUrlResponse,
    servicerAccounts: state.five_two_nine.getServicerAccountsData,
    errors: state.errors
  };
};
const mapDispatchToProps = {
  fetchNotifications,
  getServicerAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
