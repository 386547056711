import { partners } from '../../common/js/constants';

export function sortedRefiVendors(refiVendors){
  const refiVendorsSort = refiVendors.sort((a, b) => (a.attributes.name > b.attributes.name) ? 1 : -1)
  return refiVendorsSort;
}
export function refiURL(refiVendor, customer){
  const refiVendorUrl = refiVendor.attributes.url;
  const customerId = customer.id;
  return `${refiVendorUrl}?customerID=${customerId}`;
}
export function refiURLIsPresent(refiVendor){
  const url = refiVendor.attributes.url;
  const refiVendorUrl = url ? url : "";
  return refiVendorUrl; 
}
export function hasVaultPartner (partnerName) {
  return partnerName === partners.VAULT;
}
export function getRangeValue(rangeMinVal, rangeMaxVal,customerRate){
  //console.log(rangeMinVal+"=="+rangeMaxVal+"=="+customerRate)
  const defaultRate = (rangeMinVal + rangeMaxVal) / 2;
  if(customerRate===""){
    return defaultRate;
  }
  // range uses int rate * 100
  const realRate = customerRate * 100;
  if (rangeMinVal <= realRate && realRate <= rangeMaxVal) {
    //console.log("----")
    return realRate;
  }
  return defaultRate;
}
export function getRangeValueAsPercentage(rangeValue){
  return Math.round((rangeValue / 100.0) * 1e2) / 1e2;
}
export function moveRangeValueToMiddle(rangeMinVal, rangeMaxVal) { 
  const newRangeValue =(rangeMaxVal + rangeMinVal) / 2;
  return newRangeValue;
}
export function getQalifyingLoans(loans_array, rangeValueAsPercentage){
  const loans = loans_array.filter(function(value) {
    return value.interestRate > rangeValueAsPercentage
  });
  return loans;
}

export function getUnqualifiedLoans(qualifyingLoans, eligibilityLoans){
  let intersection = eligibilityLoans.filter(x => !qualifyingLoans.includes(x));
  return intersection;
 
}
export function getEligibilityLoans(loans_array, eligibilityResults){
  
  if(eligibilityResults){
   for(var i=0; i< loans_array.length; i++){
  
      delete loans_array[i]['loanId'];
      delete loans_array[i]['checksumIdentifier'];
      delete loans_array[i]['customerId'];
      delete loans_array[i]['servicerName'];

      loans_array[i]['customer'] = null;
      loans_array[i]['eligibilityResult'] = null;
      loans_array[i]['interestRate'] = Number(loans_array[i]['interestRate']);
      loans_array[i]['eligible'] = false;
      //loans_array[i]['program']  = item.attributes.eligibility_result_id;
    }
    /*console.log(JSON.stringify(loans_array));*/
    /*let eligibleProgramsArray = eligibilityResultsData;    

    for(var index=0; index < eligibleProgramsArray.length;index++){
      
      let relationshipsLoans = eligibleProgramsArray[index]['relationships']['loans']['data'];
      let elData = [];
      for(var indexL=0; indexL < relationshipsLoans.length;indexL++){
        let programLoanId = relationshipsLoans[indexL].id;
        
        eligibility_results_included.forEach((element,i) => {
          if (element.id===programLoanId) {
            element.attributes.advisable = false;
            element.attributes.interest_rate = Number(element.attributes.interest_rate);
            
            delete element.attributes['checksum_identifier'];
            delete element.attributes['data_source'];
            delete element.attributes['repayment_program'];
            delete element.attributes['servicer_name'];
            delete element.attributes['other_servicer_name'];
            elData[i] = element.attributes;
          }
        })

      }
      if(elData.length >0){
        const loans = convertHyphensCamelCaseLoansEligibilityCustom(elData);
        eligibleArray[index] = loans;
      }
    }*/
  }
  //return eligibleArray
  return loans_array;
}
export function calculateWeightedAverage(loans) {
  let result =0;
  if(loans){
    const loansCopy = loans.map(loan => {
      return {
        balance:      loan.balance,
        interestRate: (loan.rate*100)
      };
    });
    // identify weights based on balance.
    const totalBalance = loansCopy.reduce((total, loan) => total + loan.balance, 0);
   
    // assign a weight for each loan 
    loansCopy.forEach((loan,index) => {
      loan.weightedValue = (loan.balance / totalBalance) * (loan.interestRate / 100);
    });
    
    // should we round to one decimal spot? yes/no? I don't know!
    result = (loansCopy.reduce((sum, loan) => sum + loan.weightedValue, 0) * 100).toFixed(2);
  }
  return Math.abs(result).toFixed(2);
}
export function weightedAverageDiff(weightedAverageInt, loans){
  let result =0;
  if(loans){
    const loansCopy = loans.map(loan => {
      return {
        balance:      loan.balance,
        interestRate: (loan.interestRate).toFixed(2)
      };
    });
    // identify weights based on balance.
    const totalBalance = loansCopy.reduce((total, loan) => total + loan.balance, 0);
   
    // assign a weight for each loan 
    loansCopy.forEach((loan,index) => {
      loan.weightedValue = (loan.balance / totalBalance) * (loan.interestRate / 100);
    });
    
    // should we round to one decimal spot? yes/no? I don't know!
    result = (loansCopy.reduce((sum, loan) => sum + loan.weightedValue, 0) * 100).toFixed(2);
  }
  const average = weightedAverageInt - result;
  return Math.abs(average).toFixed(1)
}
export function totalProjectedInterestCalculate(loanData,loans){
  
  if(loanData!=='' && loanData!==null){
    return loanData.totalPaid - (loans.reduce((total, loan) => total + loan.balance, 0));
  }else{
    return
  }
}
export function totalProjectedIntDiff(projectedInt, alternatePlanData, loans_array){

  const altProjectedInt = alternatePlanData.totalPaid - (loans_array.reduce((total, loan) => total + loan.balance, 0)/100);
  const result = projectedInt - altProjectedInt;
  return result;
}