import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import InfoCircleFill from 'react-bootstrap-icons/dist/icons/info-circle-fill';
import { VaultDollarSvg } from "./Payment.constant";


const CheckLogo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#52C41A" />
    </svg>
  )
}

class PaymentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlanDetailsModal: false,
    };
  }
  showPlanDetails = (status) => {
    this.setState({
      showPlanDetailsModal: status,
    });
  };
  render() {
    const { customer, employer } = this.props;
    const { showPlanDetailsModal } = this.state;

    let sendPdrDate = "";
    let planDetails = "";
    if (customer) {
      planDetails = customer.attributes.plan_details;
    }
    if (employer) {
      sendPdrDate =
        employer.attributes.listed_in_current_invoice === false
          ? employer.attributes.subsequent_send_pdr_by_date
          : employer.attributes.send_pdr_by_date;
      sendPdrDate = moment(sendPdrDate).subtract(1, "day").format("MMMM Do");
    }

    return (
      <>

        <Modal
          size="lg"
          show={showPlanDetailsModal}
          onHide={() => this.showPlanDetails(false)}
          animation={false}
          centered
          backdrop="static"
          keyboard={false}
          scrollable={true}
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="Gfs-175">Plan Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="match__plan-details-container"
              dangerouslySetInnerHTML={{ __html: planDetails }}
            ></div>
          </Modal.Body>
        </Modal>

        <div>
          <div className="bg-white p-4 Gborder-05 rounded-lg my-5">
            <h2 className="Gfs-225 mb-4">Welcome to Pay,  <span className="G-text-grad">{localStorage.getItem("customerFullName").split(' ')[0]}!</span></h2>
            <div className="G-grid11-2 mob-reverse-1200">
              <div>
                <p className="sans-medium">
                  Setting up your Vault Pay account takes just a few minutes
                  once you have the information you need.&nbsp;
                  {planDetails ? (
                    <>
                      <Link
                        className="Gt-pink-8 text-decoration-none cancelLink"
                        to="#"
                        onClick={() => {
                          intercomTrackEvent(
                            eventNames.clickedSeePlanDetailsPayOverview
                          );
                          this.showPlanDetails(true);
                        }}
                      >
                        Plan Details
                      </Link>
                      .
                    </>
                  ) : null}
                </p>
                <p className="py-3 Gb-yellow-2 pl-3 rounded-lg sans-semibold">
                  <InfoCircleFill size={20} className="Gt-orange-3" />
                  &nbsp; Enroll by {sendPdrDate} to receive your contribution
                </p>
                <p className="sans-medium">
                  You'll need the following information to get started
                </p>
                <div>
                  <p className="sans-medium">
                    <span className='mr-2'><CheckLogo /></span>
                    Your loan provider login information
                  </p>
                  <p className="sans-medium">
                    <span className='mr-2'><CheckLogo /></span>
                    Loan provider name and address
                  </p>
                  <p className="sans-medium d-flex">
                    <span className='mr-2 my-auto'><CheckLogo /></span>
                    Loan account number
                  </p>
                  <p className="sans-medium">
                    <span className='mr-2'><CheckLogo /></span>
                    Estimated balance on loan
                  </p>
                  <p className="sans-medium">
                    <span className='mr-2'><CheckLogo /></span>
                    Estimated interest rate
                  </p>
                  <p className="sans-medium">
                    <span className='mr-2'><CheckLogo /></span>
                    Minimum monthly payment
                  </p>
                  <div className='d-flex mob-btn-div-column'>
                    <button onClick={(e) => this.props.getStarted(e)} className='G-orange-button'>Set Up Contribution</button>
                  </div>
                </div>

              </div>
              <div className="introAdvisorBannerHeight tuitionIntroBannerBackground d-flex justify-content-center align-items-center rounded-lg">
                <VaultDollarSvg />
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
export default PaymentOverview;
