import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { scroller } from "react-scroll";
import SimpleReactValidator from "simple-react-validator";
import { responseMessage } from '../../../../utils/alert';

import Header from "../../../Includes/Header/Header";
import Footer from "../../../Includes/Footer/Footer";

import NonRepaymentDisclaimer from "../NonRepaymentDisclaimer";

import Terms from "./Terms/Terms";
import Paperwork from "./PaperWork/Paperwork";
import Select from 'react-select'

import { fetchCustomer, updateCustomer } from '../../../../actions/customerActions';
import { saveReturnTrip, getLoanType, getRefiVendors, sendEmailFeedback, trackEvent, getSatisfactionFeedback } from '../../../../actions/advisorActions';
import { getLoans, getEligibilityResults } from '../../../../actions/fiveTwoNineActions';

import {
  creditScoreOptionsByPartner,
  getNearestCreditScore,
  getCreditTerms
} from '../../credit-terms';

import {
  calculatorExisting,
  calculatorResult,
  calculatorTranslator,
  calculateForLoans
} from '../../calculator-result';

import {
  convertHyphensCamelCaseLoansCustom,
  convertHyphensCamelCaseCustomerCustom,
  calculatorTranslatorCustomer,
} from '../../../../common/js/jsCustomFunctions';

import { convertCamelCaseCustomer } from '../../../../common/js/jsCustomer';

import {
  getRangeValue,
  moveRangeValueToMiddle,
  getRangeValueAsPercentage,
  getQalifyingLoans,
  getUnqualifiedLoans,
  sortedRefiVendors
} from '../../refi-vendor'
import isCitizens from '../../is-citizens';

import { intercomTrackEvent } from "../../../customerStatus";
import eventNames from "../../../event-names-map";

import { Form } from 'react-bootstrap';
import { Check, DashCircle, PlusCircle, XLg } from 'react-bootstrap-icons';
import { AdviceAndToolRefinanceBreadcrumb } from '../../CommonBreadcrumb/Breadcrumb.constant';
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import LendersList from './LendersList';
import FeedbackModal from './../../../shared/FeedbackModal/FeedbackModal';
import { FeedbackRouteName } from '../../../../helpers/translations';
import { doesNotHaveSatisfactionDataForThisType, userCanLookAtFeedbackModal } from '../../../../helpers/validations';


class Refinance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      loans_array: [],
      customer: "",
      customer_array: {},
      customerData: {},
      eligibilityResults: {},
      loanTypeData: [],
      matchAmount: "",
      matchEnabled: false,
      existingLoansAdvisable: true,
      creditScoreOptions: [],
      selectedScoreRange: 0,
      creditScoreChanged: null,
      passCriteria: {
        scorePass: false,
        incomePass: false,
        loansPass: false
      },
      refiVendors: [],
      termsByCredit: {},
      termLength: "",
      selectedTermJson: {},
      choose: false,
      sliderVal: "",
      existingPlan: {},
      showPaperWork: false,
      feedback: "",
      creditScore: 0,
      customerRate: 0,
      selectedTermFloor: 0,
      selectedTermCeiling: 0,
      rangeMinVal: 0,
      rangeMaxVal: 0,
      rangeValue: 0,
      rangeValueAsPercentage: 0,
      previousRoute: null,
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-light">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor | Refinance"
    const { getRefiVendors, saveReturnTrip, fetchCustomer, getLoans, getEligibilityResults, getLoanType, getSatisfactionFeedback } = this.props;
    saveReturnTrip();
    fetchCustomer();
    getLoans();
    getEligibilityResults();
    getLoanType();
    getRefiVendors();
    this.creditScoreOptions();
    getSatisfactionFeedback();
    this.timer = setTimeout(() => {
      this.rangeTrackerCallback();
    }, 2000);
    this.timerID = setTimeout(() => {
      this.calculatorExistingPlan();
    }, 5000);
    this.props.trackEvent({data: {
      event_key: eventNames.setRefinanceInterestRate,
      params: { 'visited_refinance': true}
    }})

    const getLastUserRoute = localStorage.getItem('USERROUTE');
    if (!!getLastUserRoute) {
      this.setState({
        previousRoute: getLastUserRoute,
      });
      localStorage.setItem('USERROUTE',FeedbackRouteName.refinancing);
    } else {
      localStorage.setItem('USERROUTE',FeedbackRouteName.refinancing);

    }


  }

  componentDidUpdate(prevProps) {
    if (prevProps.refiVendors !== this.props.refiVendors) {
      const refiVendorsSort = sortedRefiVendors(this.props.refiVendors);
      this.setState({
        refiVendors: refiVendorsSort
      })
    }
    if (this.props.emailFeedbackResponse !== prevProps.emailFeedbackResponse) {
      responseMessage("success", "Your question/feedback has been submitted and our team will get back to you very soon!", "");
      this.setState({
        feedback: ""
      });
    }

    if(prevProps.satisfactionData?.length) localStorage.setItem('satisfactionData', JSON.stringify(prevProps.satisfactionData));

  }
  componentWillUnmount() {
    clearTimeout(this.timerID);
    clearTimeout(this.timer);
    this.getRange();
  }
  componentWillReceiveProps = (nextProps) => {

    if (this.props.loans !== nextProps.loans) {
      const loansArray = convertHyphensCamelCaseLoansCustom(nextProps.loans);
      for (var i = 0; i < loansArray.length; i++) {
        loansArray[i]['eligible'] = loansArray[i]['eligible'] ? loansArray[i]['eligible'] : false;
        loansArray[i]['interestRate'] = Number(loansArray[i]['interestRate']);
        delete loansArray[i]['checksumIdentifier'];
        delete loansArray[i]['loanId']
      }
      this.setState({
        loans_array: loansArray,
        loans: nextProps.loans
      })
    }
    if (this.props.customer !== nextProps.customer) {

      let customerData = convertHyphensCamelCaseCustomerCustom(nextProps.customer);

      delete customerData['statusUrl'];
      delete customerData['trRequested'];
      delete customerData['anyTuition'];
      delete customerData['balanceSystemType'];

      customerData.agiGrowthRate = Number(customerData.agiGrowthRate);
      const customerArray = calculatorTranslatorCustomer(customerData);

      this.setState({
        customer_array: customerArray,
        partnerName: customerData.partnerName,
        customer: nextProps.customer,
        customerData: customerData
      }, () => {
        this.selectedScoreRange();
        this.getTermsByCredit();
      })
      // this.creditScoreOptions();
    }
    if (this.props.eligibilityResults !== nextProps.eligibilityResults) {

      const eligibilityArray = nextProps.eligibilityResults;
      this.setState({
        eligibilityResults: eligibilityArray
      })
    }
    if (this.props.loanTypeData !== nextProps.loanType) {
      const loanTypeArray = nextProps.loanTypeData;
      this.setState({
        loanTypeData: loanTypeArray
      })
    }

  }
  creditScoreOptions() {
    const partnerName = localStorage.getItem('partnerName');
    const creditScoreOptions = creditScoreOptionsByPartner(partnerName);

    this.setState({
      creditScoreOptions: creditScoreOptions
    })
  }
  selectedScoreRange() {
    const { customer_array } = this.state;
    if (Object.keys(customer_array).length > 0) {
      const customerScore = customer_array.creditScore;
      const partnerName = customer_array.partnerName;
      const creditScoreOptions = creditScoreOptionsByPartner(partnerName);
      const nearestScore = getNearestCreditScore(customerScore);
      const selectedScore = creditScoreOptions.find((e) => e.value === nearestScore);

      this.setState({
        selectedScoreRange: selectedScore.value
      })
      this._calculateReqs();
    }
  }
  selectCreditScore(e) {

    const { customer } = this.state;
    const { updateCustomer } = this.props;

    const selectedScore = e.value;
    customer.attributes.credit_score = selectedScore; //selectedScore*100;

    const jsonRequest = {
      "data": customer
    }

    updateCustomer(jsonRequest);

    this.setState({
      selectedScoreRange: selectedScore,
      creditScoreChanged: selectedScore
    }, () => {
      this._calculateReqs();
    });
    intercomTrackEvent(eventNames.selectedAdvisorCreditScore);
  }
  async _calculateReqs() {
    const { selectedScoreRange, passCriteria, customer, loans_array } = this.state;
    const score = selectedScoreRange;

    const scorePass = await this._getPassingScoreGrade(score);
    passCriteria.scorePass = scorePass;

    // Citizens wants income pass criteria.
    const income = await this._agiAsDollars(Number(customer.attributes.agi_cents) +
      Number(customer.attributes.spouse_agi_cents)
    );
    const incomePass = await this._incomePass(income);
    const loansPass = await this._loanBalanceOverTenThousand(loans_array);

    passCriteria.scorePass = scorePass;
    passCriteria.incomePass = incomePass;
    passCriteria.loansPass = loansPass;

    this.setState({
      'passCriteria': passCriteria
    })
  }
  _getPassingScoreGrade(score) {
    const scoreStatus = score > 659 ? true : false;
    return scoreStatus;
  }
  _agiAsDollars(agiCents) {
    if (typeof agiCents !== 'number') {
      agiCents = 0;
    }
    return agiCents / 100;
  }
  _loanBalanceOverTenThousand(loans = []) {

    if (loans.length === 0) { return false; }
    const totalBalance = loans.reduce((amount, loan) => amount + loan.balance, 0);
    const balance = (totalBalance / 100).toFixed(2);
    const minLoanBalance = isCitizens('customer_array.partnerName') ? 10000.00 : 5000.00;
    return Number(balance) > minLoanBalance;
  }
  _incomePass(income) {
    return income > 23999;
  }
  candidateStatusClassName() {
    const { passCriteria, customer_array } = this.state;
    let criteriaPassed = passCriteria.scorePass && passCriteria.loansPass;

    if (isCitizens(customer_array.partnerName)) {
      criteriaPassed = criteriaPassed && passCriteria.incomePass;
    }
    return (criteriaPassed ? 'pass' : 'fail');
  }
  getTermsByCredit() {
    const { customer } = this.state;
    let termsCredit = {};
    if (customer) {
      const creditScore = customer.attributes.credit_score;
      termsCredit = getCreditTerms(creditScore);
      this.selectedTerm(customer, termsCredit);
    }
    this.setState({
      termsByCredit: termsCredit
    })
  }
  selectedTerm(customer, termsCredit) {

    let refiTerm = customer.attributes.refi_term;
    let termLength = "10y";
    if (refiTerm) {
      termLength = `${Number(refiTerm) / 12}y`;
    }

    let creditScore = this.state.customerData.creditScore;
    let customerRate = this.state.customerData.refiRate;

    let selectedTermFloor = termsCredit[termLength].floor;
    let selectedTermCeiling = termsCredit[termLength].ceiling;
    let selectedTermInMonths = termsCredit[termLength].months

    let rangeMinVal = selectedTermFloor * 100;
    let rangeMaxVal = selectedTermCeiling * 100;


    let selectedTermJson = {
      rates: termsCredit[termLength],
      years: termLength
    }

    this.setState({
      termLength: termLength,
      selectedTerm: selectedTermJson,
      creditScore: creditScore,
      customerRate: customerRate,
      selectedTermFloor: selectedTermFloor,
      selectedTermCeiling: selectedTermCeiling,
      selectedTermInMonths: selectedTermInMonths,
      rangeMinVal: rangeMinVal,
      rangeMaxVal: rangeMaxVal
    }, () => {
      this.getRange();
    })

  }
  getRange() {
    const {
      rangeMinVal,
      rangeMaxVal,
      customerRate,
      selectedTermInMonths,
      sliderVal,
      choose,
      customer
    } = this.state;

    if (Object.keys(this.state.customer).length > 0 && this.state.loans.length > 0) {
      let rangeValue = 0;
      let rangeValueAsPercentage = 0;

      if (sliderVal === "") {
        if (choose === false) {
          rangeValue = getRangeValue(rangeMinVal, rangeMaxVal, customerRate);
        }
        else {
          rangeValue = moveRangeValueToMiddle(rangeMinVal, rangeMaxVal);
        }
      } else {
        rangeValue = sliderVal;
      }

      rangeValueAsPercentage = getRangeValueAsPercentage(rangeValue);

      const customerData = convertCamelCaseCustomer(customer);

      delete customerData['statusUrl'];
      delete customerData['trRequested'];
      delete customerData['anyTuition'];
      delete customerData['balanceSystemType'];

      const loansData = convertHyphensCamelCaseLoansCustom(this.state.loans);
      for (var i = 0; i < loansData.length; i++) {
        loansData[i]['eligible'] = loansData[i]['eligible'] ? loansData[i]['eligible'] : false;
        loansData[i]['interestRate'] = Number(loansData[i]['interestRate']);
        delete loansData[i]['checksumIdentifier'];
        delete loansData[i]['loanId']
      }

      let qualifyingLoans = getQalifyingLoans(loansData, rangeValueAsPercentage);
      let unqualifiedLoans = getUnqualifiedLoans(qualifyingLoans, loansData);


      let loanCalculate = calculateForLoans(qualifyingLoans, unqualifiedLoans, customerData, rangeValueAsPercentage, selectedTermInMonths);

      this.setState({
        rangeValue: rangeValue,
        rangeValueAsPercentage: rangeValueAsPercentage,
        calculatorResults: loanCalculate.calculatorResults,
        amortLoans: loanCalculate.amortLoans
      }, () => {
        this.rangeTrackerCallback();
      })
    }
  }
  chooseTerm(termLength) {
    const { termsByCredit } = this.state;

    let creditScore = this.state.customerData.creditScore;
    let customerRate = this.state.customerData.refiRate;

    let selectedTermFloor = termsByCredit[termLength].floor;
    let selectedTermCeiling = termsByCredit[termLength].ceiling;
    let selectedTermInMonths = termsByCredit[termLength].months

    let rangeMinVal = selectedTermFloor * 100;
    let rangeMaxVal = selectedTermCeiling * 100;

    let selectedTermJson = {
      rates: termsByCredit[termLength],
      years: termLength
    }
    this.setState({
      termLength: termLength,
      selectedTerm: selectedTermJson,
      creditScore: creditScore,
      customerRate: customerRate,
      selectedTermFloor: selectedTermFloor,
      selectedTermCeiling: selectedTermCeiling,
      selectedTermInMonths: selectedTermInMonths,
      rangeMinVal: rangeMinVal,
      rangeMaxVal: rangeMaxVal,
      'choose': true,
      sliderVal: ""
    }, () => {
      this.getRange();
      //rangeTrackerCallback();
    })
    intercomTrackEvent(eventNames.clickedAdvisorRefiTerm);
  }
  setRangeValue(e) {
    const currentVal = e.target.value;
    this.setState({
      sliderVal: currentVal,
    }, () => {
      this.getRange();
    })
  }
  rangeTrackerCallback() {
    const control = $('#refinance-payoff-slider__range-input');

    const controlMin = control.attr('min');
    const controlMax = control.attr('max');
    const controlVal = control.val();
    const controlThumbWidth = control.data('thumbwidth');
    const popupDivWidth = 140;  // 140 is half width of total popup div

    const range = controlMax - controlMin;
    const position = ((controlVal - controlMin) / range) * 100;
    const positionOffset = Math.round((controlThumbWidth * position) / 100) - controlThumbWidth / 2 + popupDivWidth;

    const output = $('#refinance-payoff-slider__bubble');
    let finalPosition = 0

    if (window.outerWidth < 450) {

      finalPosition = '0%'
    }
    else {
      finalPosition = position > 25 ? position < 75 ? `calc(${position}% - ${positionOffset}px)` : 'right' : '0%'
    }
    if (finalPosition === 'right') {
      output.css({ 'right': '0px', 'left': 'unset' });
    } else {
      output.css('left', finalPosition);
    }
    let arrowPosition = 50;
    if (position < 25) {
      let currentPos = position + 1 < 13 ? position + 2 : position + 1
      arrowPosition = (Math.round(currentPos) * 2) < 5 ? 5 : (Math.round(currentPos) * 2)
    } else if (position > 75) {
      let currentPos = position - 2
      arrowPosition = (Math.round(currentPos) * 2 - 100) > 95 ? 95 : (Math.round(currentPos) * 2 - 100)
    }
    document.documentElement.style.setProperty('--slider-popup-left-value', `${arrowPosition}%`);
  }

  calculatorExistingPlan() {
    const { customer, customer_array, eligibilityResults } = this.state;

    if (Object.keys(customer_array).length > 0 && eligibilityResults.data) {

      let existingPlan = calculatorExisting(customer, eligibilityResults)

      const name = existingPlan.name;
      const eligibilityLoans = existingPlan.loans;

      let result = null;

      const customerData = calculatorTranslator(customer_array, eligibilityLoans)

      result = calculatorResult(customerData, name, 0);

      if (result !== null) {
        existingPlan['result'] = result;
      }
      const loansArray = existingPlan.result.borrower.loans;
      const existingLoansAdvisable = loansArray.every(function (e) {
        return e.advisable === true
      });

      this.setState({
        existingPlan: existingPlan,
        existingLoansAdvisable: existingLoansAdvisable
      })
    }
  }

  pursueRefi = (e) => {
    intercomTrackEvent(eventNames.clickedAdvisorRefiLearnMore);
    this.setState({
      showPaperWork: !this.state.showPaperWork
    })
  }
  focusOnFeedBack = (e) => {
    scroller.scrollTo("form_box_area", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  handleChangeFeedback = (e) => {
    this.setState({
      feedback: e.target.value
    })
  }
  submitFeedback = (e) => {
    if (this.validator.allValid()) {
      const { feedback, customer } = this.state;
      const { sendEmailFeedback } = this.props;
      const source = "source:Advisor\nabout:Feedback form submission\n\n" + feedback;

      const feedbackJson = {
        "mail_to": customer.advisorSupportEmail,
        "feedback": source
      }
      sendEmailFeedback(feedbackJson);
      intercomTrackEvent(eventNames.submittedFeedbackFormAdvisorDashboard);
    } else {
      this.validator.showMessages();
    }
  }
  render() {
    const {
      creditScoreOptions,
      selectedScoreRange,
      passCriteria,
      refiVendors,
      termsByCredit,
      termLength,
      selectedTerm,
      customer,
      customer_array,
      customerData,
      choose,
      sliderVal,
      existingPlan,
      existingLoansAdvisable,
    } = this.state;

    const candidateStatus = this.candidateStatusClassName();
    let selectedValue = creditScoreOptions.filter((item) => item.value === selectedScoreRange)
    let selectedCreditScoreValue = selectedValue.length > 0 ? { label: selectedValue[0].name, value: selectedValue[0].value } : {}
    return (

      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={AdviceAndToolRefinanceBreadcrumb} />
            <div className='p-4 bg-white Gborder-1 rounded-lg'>
              <div className='G-grid11-2 mb-4'>
                <div>
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M23.75 33.75H26.25C26.25 35.1 27.9625 36.25 30 36.25C32.0375 36.25 33.75 35.1 33.75 33.75C33.75 32.375 32.45 31.875 29.7 31.2125C27.05 30.55 23.75 29.725 23.75 26.25C23.75 24.0125 25.5875 22.1125 28.125 21.475V18.75H31.875V21.475C34.4125 22.1125 36.25 24.0125 36.25 26.25H33.75C33.75 24.9 32.0375 23.75 30 23.75C27.9625 23.75 26.25 24.9 26.25 26.25C26.25 27.625 27.55 28.125 30.3 28.7875C32.95 29.45 36.25 30.275 36.25 33.75C36.25 35.9875 34.4125 37.8875 31.875 38.525V41.25H28.125V38.525C25.5875 37.8875 23.75 35.9875 23.75 33.75Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h4>Refinance</h4>
                  <p className="sans-medium">
                    Results displayed include refinanced and non-refinanced loans. Note that refinancing your federal student loan to a private student loan may eliminate some benefits and protections, such as loan forgiveness for borrowers working as teachers or in certain public service jobs, or certain loan repayment programs available to borrowers who are having trouble making repayments.
                  </p>

                </div>
                <div>
                  <label className='Gfs-125'>
                    Am I a good candidate for private refinancing?
                  </label>
                  <Form.Group className='mb-4'>
                    <Form.Label >My current credit score is</Form.Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="reactSelect"
                      onChange={this.selectCreditScore.bind(this)}
                      name=""
                      placeholder='Select credit score'
                      isSearchable={true}
                      value={selectedCreditScoreValue}
                      options={
                        creditScoreOptions ? creditScoreOptions.map(item => {
                          return {
                            label: item.name,
                            value: item.value
                          }
                        })
                          : null
                      }
                    />
                  </Form.Group>
                  {
                    selectedScoreRange ?
                      <div>
                        {
                          candidateStatus === "pass" ?
                            <div className='p-3 Gb-green-2 rounded-lg d-flex '>
                              <div className='my-auto'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#52C41A" />
                                </svg>
                              </div>
                              <label className='mb-0 my-auto mx-2' >
                                Hurray! You may be a good candidate for refinancing
                              </label>
                            </div>
                            :
                            <div className='p-3 Gb-red-1 rounded-lg d-flex '>
                              <div className='my-auto'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 20.5C7.59 20.5 4 16.91 4 12.5C4 8.09 7.59 4.5 12 4.5C16.41 4.5 20 8.09 20 12.5C20 16.91 16.41 20.5 12 20.5ZM12 2.5C6.47 2.5 2 6.97 2 12.5C2 18.03 6.47 22.5 12 22.5C17.53 22.5 22 18.03 22 12.5C22 6.97 17.53 2.5 12 2.5ZM14.59 8.5L12 11.09L9.41 8.5L8 9.91L10.59 12.5L8 15.09L9.41 16.5L12 13.91L14.59 16.5L16 15.09L13.41 12.5L16 9.91L14.59 8.5Z" fill="#F5222D" />
                                </svg>
                              </div>
                              <label className='mb-0 my-auto mx-2' >
                                Unfortunately, you may not be a good candidate for refinancing.
                              </label>
                            </div>
                        }
                        <div >
                          <div className="G-grid11-2 my-3">
                            {
                              candidateStatus === "fail" ?
                                (<>
                                  {isCitizens(customer_array.partnerName) && !passCriteria.incomePass ?
                                    <div className='d-flex'>
                                      <div className=' mr-2'>
                                        <XLg className='Gt-red-5' size={16} />
                                      </div>
                                      <label className='mb-0'>
                                        Income is less than $24,000
                                      </label>
                                    </div>
                                    : null}
                                  {!passCriteria.loansPass ?
                                    <div className='d-flex'>
                                      <div className=' mr-2'>
                                        <XLg className='Gt-red-5' size={16} />
                                      </div>
                                      <label className='mb-0'>Less than {isCitizens(customer_array.partnerName) ? "$10,000" : "$5,000"} of student loans</label>
                                    </div>
                                    : null}
                                  {!passCriteria.scorePass ?
                                    <div className='d-flex'>
                                      <div className=' mr-2'>
                                        <XLg className='Gt-red-5' size={16} />
                                      </div>
                                      <label className='mb-0'>Credit score is less than {isCitizens(customer_array.partnerName) ? "680" : "660"}</label>
                                    </div>
                                    : null}
                                </>)
                                : (<>
                                  {isCitizens(customer_array.partnerName) && passCriteria.incomePass ?
                                    <div className='d-flex'>
                                      <div className='mr-2'>
                                        <Check className='Gt-green-6' size={26} />
                                      </div>
                                      <label className='mb-0'>Income is more than $24,000</label>
                                    </div>
                                    : null
                                  }
                                  {passCriteria.loansPass ?
                                    <div className='d-flex'>
                                      <div className=' mr-2'>
                                        <Check className='Gt-green-6' size={26} />
                                      </div>
                                      <label className='mb-0'>More than {isCitizens(customer_array.partnerName) ? "$10,000" : "$5,000"} of student loans</label>
                                    </div>
                                    : null}
                                  {passCriteria.scorePass ?
                                    <div className='d-flex'>
                                      <div className=' mr-2'>
                                        <Check className='Gt-green-6' size={26} />
                                      </div>
                                      <label className='mb-0'>Credit score more than {isCitizens(customer_array.partnerName) ? "680" : "660"}</label>
                                    </div>
                                    : null}
                                </>)
                            }

                          </div>
                        </div>
                      </div>
                      : null
                  }
                </div>
              </div>

              <div className='G-grid11-2 mb-4'>
                {!existingLoansAdvisable ?
                  <NonRepaymentDisclaimer />
                  : <div></div>}
                <LendersList refiVendors={refiVendors} customer={customer}  trackEvent={this.props.trackEvent}/>
              </div>

              <Terms
                customerData={customerData}
                customer_array={customer_array}
                customer={customer}
                termsByCredit={termsByCredit}
                termLength={termLength}
                chooseTerm={this.chooseTerm.bind(this)}
                selectedTerm={selectedTerm}
                choose={choose}
                setRangeValue={this.setRangeValue.bind(this)}
                sliderVal={sliderVal}
                rangeTrackerCallback={this.rangeTrackerCallback.bind(this)}
                loans_array={this.state.loans_array}
                alternatePlan={existingPlan}
                existingLoansAdvisable={existingLoansAdvisable}
                refiVendors={refiVendors}
                creditScore={this.state.creditScore}
                customerRate={this.state.customerRate}
                selectedTermFloor={this.state.selectedTermFloor}
                selectedTermCeiling={this.state.selectedTermCeiling}
                selectedTermInMonths={this.state.selectedTermInMonths}
                rangeMinVal={this.state.rangeMinVal}
                rangeMaxVal={this.state.rangeMaxVal}
                rangeValue={this.state.rangeValue}
                rangeValueAsPercentage={this.state.rangeValueAsPercentage}
                calculatorResults={this.state.calculatorResults}
                amortLoans={this.state.amortLoans}
                isCreditScoreLessThan660={!passCriteria.scorePass}
              />
              <div className="Gborder-05 rounded-lg p-4 mt-3">
                <div className="mt-2 d-flex G-cursor-pointer" onClick={this.pursueRefi.bind(this)}>
                  <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
                    Refinance in 3 steps
                  </label>
                  {
                    this.state.showPaperWork ?
                      <div className="ml-auto my-auto G-cursor-pointer">
                        <DashCircle size={22} />
                      </div>
                      :
                      <div className="ml-auto my-auto G-cursor-pointer" >
                        <PlusCircle size={22} />
                      </div>
                  }
                </div>
                {
                  this.state.showPaperWork ?
                    <Paperwork
                      refiVendors={refiVendors}
                      customer={customer}
                      paperwork={true}
                      trackEvent={this.props.trackEvent}
                    />
                    : null
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {this.state.previousRoute && this.state.previousRoute !== FeedbackRouteName.refinancing && doesNotHaveSatisfactionDataForThisType(this.state.previousRoute) && userCanLookAtFeedbackModal() && (
          <FeedbackModal showFeedbackModal={!!this.state.previousRoute} contentType={this.state.previousRoute} />
        )}
        
      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    returnTrip: state.advisor.returnTrip,
    customer: state.customer_array.customerData,
    updateCustomerResponse: state.customer_array.updateCustomerResponse,
    loans: state.five_two_nine.loans,
    eligibilityResults: state.five_two_nine.eligibilityResults,
    loanTypeData: state.advisor.loanTypeData,
    refiVendors: state.advisor.refiVendors,
    emailFeedbackResponse: state.advisor.emailFeedbackResponse,
    errors: state.errors,
    satisfactionData: state.advisor.satisfactionData,
  };
};
const mapDispatchToProps = {
  saveReturnTrip,
  fetchCustomer,
  updateCustomer,
  getLoans,
  getEligibilityResults,
  getLoanType,
  getRefiVendors,
  sendEmailFeedback,
  trackEvent,
  getSatisfactionFeedback
}
export default connect(mapStateToProps, mapDispatchToProps)(Refinance);