import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { fetchCustomer } from '../../../actions/customerActions';
import { fetchEmployer } from '../../../actions/employerActions';
import { postServicerAccounts, postEditServicerAccounts } from '../../../actions/fiveTwoNineActions';
import { numberFormat, payrollDatePlusOneSub } from '../../../common/js/jsFunctions';
import LifeTimeCapScore from './LifeTimeCapScore';
import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import S from './Payments.module.css';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { paymentBreadcrumbData, paymentStepperData, StepperStage } from './Payment.constant';
import Stepper from '../../shared/Stepper/Stepper';
import ExclamationCircle from 'react-bootstrap-icons/dist/icons/exclamation-circle';

class ProfileDeductions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      employer: null,
      requestData: this.props.location.requestData,
      account_number: "",
      account_balance: "",
      monthly_payment: "",
      interest_rate: "",
      servicer_name: "",
      errors: null
    }
  }
  componentDidMount() {
    document.title = "Vault | Account Summary"
    const { fetchCustomer, fetchEmployer } = this.props;
    const { requestData } = this.state;

    fetchCustomer();
    fetchEmployer();
    if (requestData === undefined && this.props.match.params.saId === undefined) {
      this.props.history.push({
        pathname: '/pay/overview'
      })
    }
    if (requestData === undefined && this.props.match.params.saId !== undefined) {
      this.props.history.push({
        pathname: '/pay/profiles/payment/' + this.props.match.params.saId + '/edit'
      })
    }
    if (requestData !== undefined) {
      let account_number = requestData.data.attributes.account_number;
      let account_number_short = account_number?.slice(account_number.length - 4);
      let account_balance = requestData.data.attributes.account_balance;
      let monthly_payment = requestData.data.attributes.monthly_payment;
      let interest_rate = requestData.data.attributes.interest_rate;
      let servicer_name = requestData.data.servicer_name;

      this.setState({
        account_number: account_number_short,
        account_balance: account_balance,
        monthly_payment: monthly_payment,
        interest_rate: interest_rate,
        servicer_name: servicer_name
      })
    }
    intercomTrackEvent(eventNames.vistedPayServicerConfirmation);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer
      })
    }
    if (prevProps.employer !== this.props.employer) {
      this.setState({
        employer: this.props.employer
      })
    }
    if (prevProps.servicer_accounts !== this.props.servicer_accounts) {
      this.props.history.push({
        pathname: '/pay/overview/'
      })
    }
  }
  onCancel(e) {
    // const numberOfPayActiveServicerAccounts = this.state.customer?.attributes?.number_of_pay_active_servicer_accounts;

    this.props.history.push({
      pathname: '/pay/accounts',
      requestData: this.props?.location?.requestData,
      fromFinalStep: true,
    })
  }
  saveChanges(e) {
    const { requestData } = this.state;
    requestData.data.attributes.pay_active = true;
    this.props.postEditServicerAccounts(requestData)
    intercomTrackEvent(eventNames.clickedSubmitEnrollPay);
  }
  render() {
    const { customer, employer, account_number, account_balance, monthly_payment, interest_rate, servicer_name } = this.state;
    const fromDashboard = !!this.props?.location?.fromDashboard;
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">

          <div className='G-page-main-container py-5'>
            {fromDashboard &&
              <Breadcrumb list={paymentBreadcrumbData()} />
            }
            <Stepper options={paymentStepperData(StepperStage.complete, StepperStage.complete, StepperStage.active)} />
            <div className='p-4 bg-white rounded-lg Gborder-1 mt-5'>
              <div>
                <div className={`${S.circularDiv} Gt-slate-5`}>
                  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.75 17.75H14.5V20.25H5.75V17.75ZM5.75 12.75H18.25V15.25H5.75V12.75ZM5.75 7.75H18.25V10.25H5.75V7.75ZM20.75 2.75H15.525C15 1.3 13.625 0.25 12 0.25C10.375 0.25 9 1.3 8.475 2.75H3.25C3.075 2.75 2.9125 2.7625 2.75 2.8C2.2625 2.9 1.825 3.15 1.4875 3.4875C1.2625 3.7125 1.075 3.9875 0.95 4.2875C0.825 4.575 0.75 4.9 0.75 5.25V22.75C0.75 23.0875 0.825 23.425 0.95 23.725C1.075 24.025 1.2625 24.2875 1.4875 24.525C1.825 24.8625 2.2625 25.1125 2.75 25.2125C2.9125 25.2375 3.075 25.25 3.25 25.25H20.75C22.125 25.25 23.25 24.125 23.25 22.75V5.25C23.25 3.875 22.125 2.75 20.75 2.75ZM12 2.4375C12.5125 2.4375 12.9375 2.8625 12.9375 3.375C12.9375 3.8875 12.5125 4.3125 12 4.3125C11.4875 4.3125 11.0625 3.8875 11.0625 3.375C11.0625 2.8625 11.4875 2.4375 12 2.4375ZM20.75 22.75H3.25V5.25H20.75V22.75Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h4 className='sans-semibold Gfs-175 mt-4 mb-0'>Confirm company’s contribution</h4>
                <p className='sans-medium'>Review and confirm your company’s contribution amount.</p>
                <div className='G-grid11-g0 Gborder-05 p-4 mt-4 mb-5 rounded-lg'>
                  <div className={`${S.slateRightBorder}`}>
                    <span className='G-badge-purple Gfs-087 sans-semibold'>Account</span>
                    <p className='sans-semibold Gfs-125 mt-4 mb-1'>Contribution to {servicer_name} account ending in ...{account_number}</p>
                    <p className='sans-semibold Gfs-087 Gt-slate-3 mb-0'>Balance - {numberFormat(account_balance)} | Interest Rate - {interest_rate}%</p>
                    <p className='sans-medium Gfs-087 mt-4'>Your next contribution will be sent: {payrollDatePlusOneSub(employer)} <br />
                      (Servicer typically processes contributions within 3-5 days)
                    </p>
                  </div>
                  <div className={`${S.contributionPad}`}>
                    <span className='G-badge-purple Gfs-087 sans-semibold'>Contribution</span>
                    <h2 className='sans-extrabold Gt-pink-8 Gfs-275 my-3'>{customer ? numberFormat(customer.attributes.employer_match) : ''}</h2>
                  </div>
                </div>
                <div className='Gt-slate-5 Gborder-05 p-4 rounded-lg G-grid115'>
                  <div>
                    <div className={`${S.yellowCircularDiv}`}>
                      <ExclamationCircle size={24} />
                    </div>
                    <h4 className='sans-semibold Gfs-15 mt-4'>Important Notice</h4>
                  </div>
                  <div>
                    <p className='mb-4 sans-medium'>Our service sends the contribution as extra money toward your student loan to help you payoff your student loan debt faster. It is still your responsibility to make sure your self reported minimum monthly payment of {numberFormat(monthly_payment)} is covered by payments you make on your own outside of this benefit.</p>
                    <p className='sans-medium'>To better understand the tax implications of this benefit, please speak with your Benefits Team or read more about it in the FAQ.</p>
                  </div>
                </div>

              </div>
            </div>
            <div>
              <LifeTimeCapScore customer={customer} />
            </div>
            <div className='d-flex justify-content-end flex-wrap my-3'>
              <button className='G-cancel-button mx-3 mob-separate-v4' onClick={this.onCancel.bind(this)}>Go Back </button>
              <button className='G-orange-button mob-separate-v4' onClick={this.saveChanges.bind(this)}>Submit &amp; Enroll</button>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer_array.customerData,
    employer: state.employer_array.employerData,
    servicer_accounts: state.five_two_nine.servicerAccountsData
  };
}
const mapDispatchToProps = {
  fetchCustomer,
  fetchEmployer,
  postServicerAccounts,
  postEditServicerAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDeductions);