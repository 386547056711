import {
  FETCH_EDUCATION_EXPENSES,
  FETCH_TUITION_BY_ID,
  FETCH_TUITIONS,
  FETCH_SERVICER_ACCOUNT,
  FETCH_ADDRESS,
  FETCH_INCOME_RANGE,
  FETCH_AGE_RANGE,
  SAVE_TR_DETAILS,
  FETCH_TERMSCONDITIONS,
  ACCEPT_TERMSCONDITIONS
} from '../actions/types';

const initialState = {
  termsConditionsData: {},
  acceptTermsConditions: {},
  saveTRDetails: {},
  fetchAgeRange: [],
  fetchIncomeRange: [],
  tuitionsData: {},
  tuitionByIdData: {},
  servicerAccountData: {},
  addressData: {},
  educationExpensesData: [],
}

export default function tuitionReimbursementReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TERMSCONDITIONS:
      return {
        ...state,
        termsConditionsData: action.payload
      }
    case ACCEPT_TERMSCONDITIONS:
      return {
        ...state,
        acceptTermsConditions: action.payload
      }
    case SAVE_TR_DETAILS:
      return {
        ...state,
        saveTRDetails: action.payload
      }
    case FETCH_AGE_RANGE:
      return {
        ...state,
        fetchAgeRange: action.payload
      }
    case FETCH_INCOME_RANGE:
      return {
        ...state,
        fetchIncomeRange: action.payload
      }
    case FETCH_TUITIONS:
      return {
        ...state,
        tuitionsData: action.payload
      }
    case FETCH_TUITION_BY_ID:
      return {
        ...state,
        tuitionByIdData: action.payload
      }
    case FETCH_SERVICER_ACCOUNT:
      return {
        ...state,
        servicerAccountData: action.payload
      }
    case FETCH_ADDRESS:
      return {
        ...state,
        addressData: action.payload
      }
    case FETCH_EDUCATION_EXPENSES:
      return {
        ...state,
        educationExpensesData: action.payload
      }
    default:
      return state;
  }
}