import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { responseMessage } from '../../utils/alert';
import { fetchInvitationData, registrateUser, loginUser } from '../../actions/authenticationActions';
import { fetchCustomer } from '../../actions/customerActions';
import { fetchEmployer } from '../../actions/employerActions';
import { intercomBoot, intercomTrackEvent } from "../customerStatus";
import eventNames from "../event-names-map";
import RegistrationPage from './RegistrationPage';
import updateState from '../shared/SignIn/updateState';
import initialState from '../shared/SignIn/initialState';
import getUrlForLogin from '../../helpers/getUrlForLogin';

const initialStateObj = initialState('register');

const Registration = (props) => {
  const [state, setState] = useState({
    ...initialStateObj
  })

  useEffect(() => {
    document.title = "Vault Advisor | Registration";
    const { fetchInvitationData } = props;
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token')
    if (token === null) {
      setState({ ...state, redirect: true });
    } else {
      fetchInvitationData(token);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.fetchInvitation).length) {
      setState({
        ...state,
        fetchInvitation: props.fetchInvitation,
        name: props.fetchInvitation.full_name,
        email: props.fetchInvitation.email,
        invitation_token: props.fetchInvitation.invitation_token
      })
    }
  }, [props.fetchInvitation])

  useEffect(() => {
    if (props.registrateData) {
      const { loginUser } = props;
      const jsonRequest = {
        email: state.email,
        password: state.password
      }
      loginUser(jsonRequest);
    }
  }, [props.registrateData])

  useEffect(() => {
    if (Object.keys(props.loginData).length) {
      const { fetchCustomer, fetchEmployer } = props;

      const intercom = props.loginData.intercom;
      const token = props.loginData.token;

      localStorage.setItem('intercom', JSON.stringify(intercom));
      localStorage.setItem('userToken', token);

      fetchCustomer();
      fetchEmployer();
    }
  }, [props.loginData])

  useEffect(() => {
    if (Object.keys(props.customerData).length) {
      const updatedStateObj = updateState(props, 'register');

      setState({
        ...state,
        ...updatedStateObj
      });

      intercomBoot();
      intercomTrackEvent(eventNames.register);
    }
  }, [props.customerData])

  useEffect(() => {
    if (Object.keys(props.employer).length) {
      const advisorFirst = props.employer.attributes.advisor_first;
      const includeKnowledgeBank = props.employer.attributes.include_knowledge_bank;
      localStorage.setItem("advisorFirst", advisorFirst);
      setState({
        ...state,
        advisorFirst: advisorFirst,
        includeKnowledgeBank: includeKnowledgeBank
      })
    }
  }, [props.employer])

  useEffect(() => {
    if (Object.keys(props.errors.errors).length) {
      const errors = props.errors.errors;
      const errorDetails = errors?.errors ?
        (errors?.errors?.[0]?.['detail'] ?
          (Array.isArray(errors?.errors[0]['detail']) ?
            errors?.errors[0]['detail'][0] : errors?.errors[0]['detail']) : '') :
        (errors?.error ?? '')
      responseMessage("error", errorDetails, "");
    }
  }, [props.errors])


  const {
    userVerify,
  } = state;

  if (userVerify === true) {
    const url = getUrlForLogin(state);
    return <Redirect to={url} />
  } else {
    return (
      <RegistrationPage state={state} setState={setState} registrateUser={props.registrateUser} />
    )
  }

}

Registration.propTypes = {
  fetchInvitationData: PropTypes.func.isRequired,
  registrateUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  fetchInvitation: state.authentication.fetchInvitation,
  registrateData: state.authentication.registrateData,
  loginData: state.authentication.loginData,
  customerData: state.customer_array.customerData,
  employer: state.employer_array.employerData,
  errors: state.errors
});
export default connect(mapStateToProps, { fetchInvitationData, registrateUser, loginUser, fetchCustomer, fetchEmployer })(Registration);