/*import {
  validateLength,
  validateFormat
} from 'ember-changeset-validations/validators';*/
import concat from 'lodash/concat';
import reduce from 'lodash/reduce';
import toLower from 'lodash/toLower';

const onlyContainsNumbersAndSpaces = {
  regex: /^(?=.*\d)[\d ]+$/,
  message: 'contain only numbers and spaces'
};

const onlyContainsNumbersAndDashes = {
  regex: /^(?=.*\d)[\d-]+$/,
  message: 'contain only numbers and dashes'
};

const onlyContainsNumbersDashesSpaces = {
  regex: /^(?=.*\d)[\d- ]+$/,
  message: 'contain only numbers, dashes and spaces'
};

// first character can be D,E,J or 0-9
const firstCharDEJAndNumeric = {
  regex: /^[DEJdej0-9]/,
  message: 'have a first character of one of the following: D, E, J or 0-9'
};

const allCharAfterFirstNumeric = {
  regex: /^.[0-9]*$/,
  message: 'contain only numbers after the first character'
};

const tenNumbers = {
  regex: /^[\D]*(?:\d[\D]*){10}$/,
  message: 'contain only 10 numbers'
};

const tenToTwelveNumbers= {
  regex: /^[\D]*(?:\d[\D]*){10,12}$/,
  message: 'contain only 10-12 numbers'
};

const nineToTenNumbers = {
  regex: /^[\D]*(?:\d[\D]*){9,12}$/,
  message: 'contain only 9 to 10 characters'
};

const nineOrFifteenNumbers = {
  regex: /^[\D]*(?:\d[\D]*){9}$|^[\D]*(?:\d[\D]*){15}$/,
  message: 'are only 9 or 15 numbers'
};

const notDeptOfEduNum = {
  regex: /^[\D]*(?:\d[\D]*){0,5}$|^[\D]*(?:\d[\D]*){7,}$/,
  message: 'are not your US Dept of Education Number'
};

// 14 digits (0-9) but allow dashes in between. Will faill if next to another dash.
const fourteenDigits = {
  regex: /^\d(-?\d){13}$/,
  message: 'are only 14 numbers'
};

// matches digits (0-9) and dashes but not starting or ending with dashes.
const digitsAndDashes = {
  regex: /^\d(?:[-]*\d){4,}$/,
  message: 'do not contain letters or spaces'
};

/**
 * Pass starting characters as str to compose validateFormat function call
 *
 * @param {String} chars
 * @return {Object} from validateFormat function call
 */

const startsWith = {
  regex: /021$/,
  message: 'begin with 021'
};

// Takes an array of validation functions and returns an array of all errors
const applyValidations = (arrOfFunc, val) =>{
  const response = reduce(arrOfFunc,(res, func) => {
    const validationRes = func.regex.test(val);
    return validationRes === true ? res : concat(res, func.message);
  },[]);
  return response;
}

/**
  * @desc - validates a servicer account number and returns an array of errors.
  * @param {string} servicerName - the selected servicer name
  * @param {string} newValue - the updated account value to validate
  *
  * TODO: Refactor so servicer names are not hardcoded.
  */
export function validateServicerAccountNumber(servicerName, newValue) {
  switch (toLower(servicerName)) {
  case 'first republic bank':
    return applyValidations(
      [startsWith, digitsAndDashes, fourteenDigits],
      newValue
    );
  case 'navient':
    return applyValidations(
      [onlyContainsNumbersDashesSpaces, tenToTwelveNumbers],
      newValue
    );
  case 'nelnet':
    return applyValidations(
      [
        nineToTenNumbers,
        firstCharDEJAndNumeric,
        allCharAfterFirstNumeric
      ],
      newValue
    );
  case 'great lakes': {
    const greatLakesValidations = applyValidations(
      [
        nineOrFifteenNumbers,
        onlyContainsNumbersAndDashes,
        notDeptOfEduNum
      ],
      newValue
    );
    const defaultMsg = 'are your GREAT LAKES ID or Payment References Number';
    return greatLakesValidations.length > 0
      ? [defaultMsg, ...greatLakesValidations]
      : greatLakesValidations;
  }
  case 'fedloan servicing':
    return applyValidations(
      [onlyContainsNumbersAndSpaces, tenNumbers],
      newValue
    );
  case 'mohela':
    return applyValidations(
      [onlyContainsNumbersAndSpaces, tenNumbers],
      newValue
    );
  case 'aes':
    return applyValidations(
      [onlyContainsNumbersAndSpaces, tenToTwelveNumbers],
      newValue
    );
  default:
    return [];
  }
}
