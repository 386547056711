import React, { useState, useEffect, useRef } from "react";

import {
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";

import EstimatedMatchGraph from "./EstimatedMatchGraph";

import {
  totalToPay,
  getTotalTransactionsAmount,
  getSalaryPaidPercentage,
  getSalaryForMatchPeriod,
  getSalaryPaidPercentageForPeriod,
  getTransactionsForThisYear,
  sumOfAmountsComputedTransactions,
} from "../match";
import { numberFormat } from "../../../common/js/jsFunctions";
import { PencilFill, XLg } from "react-bootstrap-icons";


const EstimatedMatchProjection = (props) => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const showUpdateSalaryModal = props.showUpdateSalaryModal;

  const customer = props.customer;
  //const [customer,setCustomer] = useState(props.customer);
  //useEffect(() => { setCustomer(props.customer) }, [props.customer]);

  const customerSalary = customer ? customer.attributes.salary_cents / 100 : 0;
  const matchPercentage = customer ? customer.attributes.match_percentage : 0;
  // const matchPlanType = customer ? customer.attributes.match_plan_type : "";
  const transactions = props.transactions
  const servicerAccounts = props.servicerAccounts;
  const sumOfAmountsComputed =
    sumOfAmountsComputedTransactions(servicerAccounts);
  const [salary, setSalary] = useState(customerSalary);

  let totalPay = 0;
  if (customer) {
    totalPay = totalToPay(customer, salary);
  }
  useEffect(() => {
    setSalary(customerSalary);
  }, [customerSalary]);
  const totalTransactionsAmount = getTotalTransactionsAmount(servicerAccounts);

  const transactionsForThisYear = getTransactionsForThisYear(transactions);

  const salaryPaidPercentage = getSalaryPaidPercentage(
    totalTransactionsAmount,
    salary
  );
  let salaryForMatchPeriod = 0;
  if (customer) {
    salaryForMatchPeriod = getSalaryForMatchPeriod(customer, salary);
  }
  const salaryPaidPercentageForPeriod = getSalaryPaidPercentageForPeriod(
    salaryPaidPercentage,
    totalTransactionsAmount,
    salaryForMatchPeriod
  );

  // const showSalaryModal = (e, status) => {
  //   e.stopPropagation();
  //   props.setShowUpdateSalaryModal(status);
  // };
  function onSubmitSalaryModal(e) {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
    } else {
      props.onSubmitSalary(e, salary);
    }
  }
  //console.log(customer)
  return (
    <>

      <div>
        <Row>
          <Col lg={3}>
            <div className='Gborder-1 rounded-lg p-3'>
              <div>
                <label className='G-badge-purple Gfs-075'>
                  Salary for Match Calculation
                </label>
                <br />
                <div>
                  {numberFormat(customerSalary)}
                  <button type='button' className='mx-4 Gt-pink-8 text-decoration-none cancelLink'
                    onClick={() => props.setShowUpdateSalaryModal(true)}>
                    <PencilFill className='Gt-pink-8 mr-2' /> Edit
                  </button>
                </div>
              </div>
              <div className='mt-4'>
                <label className='G-badge-purple Gfs-075'>
                  % of Salary Matched
                </label>
                <br />
                <label>
                  {matchPercentage}%
                </label>
              </div>
              <div className='mt-4'>
                <label className='G-badge-purple Gfs-075'>
                  Maximum Amount Matched
                </label>
                <br />
                <label>
                  {numberFormat(totalPay)}
                </label>
              </div>
              <div className='mt-4'>
                <label className='G-badge-purple Gfs-075'>
                  % Matched To-Date
                </label>
                <br />
                <label>
                  {salaryPaidPercentageForPeriod === 'NaN%' ? '0.0%' : salaryPaidPercentageForPeriod}
                </label>
              </div>
              <div className='mt-4'>
                <label className='G-badge-purple Gfs-075'>
                  Total Paid To-Date
                </label>
                <br />
                <label>
                  {numberFormat(sumOfAmountsComputed)}
                </label>
              </div>
              <div className='mt-4'>
                <label className='G-badge-purple Gfs-075'>
                  Remaining match amount
                </label>
                <br />
                <label>
                  {numberFormat(totalPay - sumOfAmountsComputed)}
                </label>
              </div>
            </div>
          </Col>
          <Col lg={9}>
            <EstimatedMatchGraph
              customer={props.customer}
              matchPercentage={matchPercentage}
              transactionsForThisYear={transactionsForThisYear}
              servicerAccounts={servicerAccounts}
            />
            <label className="pl-3 mb-0 pt-3  Gfs-087 sans-medium">
              Values shown are for illustrative purposes only and rely
              on accuracy of salary entered by you. Vault cannot
              guarantee amounts.
            </label>
          </Col>
        </Row>
      </div >

      <Modal size="lg"
        show={showUpdateSalaryModal}
        onHide={() => props.setShowUpdateSalaryModal(false)}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <Modal.Body className='custom-modal-body'>
          <div className="d-flex">
            <>
              <div className='mr-3'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M32.575 26.275L33.725 27.425L22.4 38.75H21.25V37.6L32.575 26.275V26.275ZM37.075 18.75C36.7625 18.75 36.4375 18.875 36.2 19.1125L33.9125 21.4L38.6 26.0875L40.8875 23.8C41.375 23.3125 41.375 22.525 40.8875 22.0375L37.9625 19.1125C37.7125 18.8625 37.4 18.75 37.075 18.75V18.75ZM32.575 22.7375L18.75 36.5625V41.25H23.4375L37.2625 27.425L32.575 22.7375V22.7375Z" fill="var(--icon-color)" />
                </svg>
              </div>
            </>
            <div className='w-100'>
              <div className="d-flex">
                <h4 className='py-3'>Update your salary</h4>
                <div className="ml-3"> <button
                  onClick={() => {
                    setSalary(customerSalary)
                    props.setShowUpdateSalaryModal(false)
                  }} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
              </div>
              {/* body */}
              <p className="sans-medium">
                {customer && customer.attributes.employer_name} is offering a{" "}
                {customer ? customer.attributes.match_percentage : 0}% salary match to your  {customer ? customer.attributes.match_plan_type : ""}.
              </p>
              <Form.Group className='mb-4'>
                <Form.Label className="">
                  What is your salary?
                </Form.Label>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  value={salary}
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="text"
                  className="form-control"
                  id="salary_cents"
                  name="salary"
                  onValueChange={(values) => {
                    setSalary(values.floatValue ? values.floatValue : "");
                  }}
                  onChange={() => validator.current.showMessageFor("salary")}
                />
                <Form.Text className="text-muted sans-medium mb-1">
                  This is used for estimation purposes only.
                </Form.Text>
                {validator.current.message("salary", salary, "required")}
              </Form.Group>
              <div className="d-flex justify-content-end mob-btn-div-column">
                <button className="G-cancel-button mx-3 mob-separate-v6" onClick={() => { setSalary(customerSalary); props.setShowUpdateSalaryModal(false) }}>
                  Cancel
                </button>
                <button className="G-orange-button mob-separate-v6" onClick={(e) => onSubmitSalaryModal(e)}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

    </>
  );
};
export default EstimatedMatchProjection;
