import React, { Component } from 'react';
import $ from 'jquery';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodatausaLow from '@amcharts/amcharts4-geodata/usaLow';
import am4themesMaterial from '@amcharts/amcharts4/themes/material';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import InfoCircle from 'react-bootstrap-icons/dist/icons/info-circle';
import ReactTooltip from 'react-tooltip';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { MapBreadcrumb, MapBreadcrumbTR } from '../CommonBreadcrumb/Breadcrumb.constant';

// Themes begin
am4core.useTheme(am4themesMaterial);
am4core.useTheme(am4themesAnimated);
// Themes end

class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    document.title = "Vault | Maps"
    this.createChart();
  }
  createChart() {
    // Create map instance
    const chart = am4core.create('GMap-chart', am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodatausaLow;


    // Set projection
    chart.projection = new am4maps.projections.AlbersUsa();

    // Create map polygon series
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    //Set min/max fill color for each area
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: '#E6E6E6',//chart.colors.getIndex(1).brighten(1),
      max: '#943965'//chart.colors.getIndex(1).brighten(-0.3)
    });

    // Make map load polygon data (state shapes and names) from GeoJSON
    polygonSeries.useGeodata = true;

    // Set heatmap values for each state
    polygonSeries.data = [
      {
        id: 'US-AL',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-AK',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-AZ',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-AR',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-CA',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-CO',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-CT',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-DE',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-DC',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-FL',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-GA',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-HI',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-ID',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-IL',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-IN',
        value: '',
        taxCategory: 'Tax Credit'
      },
      {
        id: 'US-IA',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-KS',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-KY',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-LA',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-ME',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-MD',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-MA',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-MI',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-MN',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-MS',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-MO',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-MT',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-NE',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-NV',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-NH',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-NJ',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-NM',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-NY',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-NC',
        value: '',
        taxCategory: 'No Tax Benefits'
      },
      {
        id: 'US-ND',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-OH',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-OK',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-OR',
        value: '',
        taxCategory: 'Tax Credit'
      },
      {
        id: 'US-PA',
        value: '',
        taxCategory: 'Tax Parity'
      },
      {
        id: 'US-RI',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-SC',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-SD',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-TN',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-TX',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-UT',
        value: '',
        taxCategory: 'Tax Credit'
      },
      {
        id: 'US-VT',
        value: '',
        taxCategory: 'Tax Credit'
      },
      {
        id: 'US-VA',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-WA',
        value: '',
        taxCategory: 'No State Tax'
      },
      {
        id: 'US-WV',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-WI',
        value: '',
        taxCategory: 'Tax Deduction'
      },
      {
        id: 'US-WY',
        value: '',
        taxCategory: 'No State Tax'
      }
    ];

    // Set up heat legend
    const heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = 'right';
    heatLegend.valign = 'bottom';
    heatLegend.width = am4core.percent(25);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = 40000000;

    // Set up custom heat map legend labels using axis ranges
    const minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.label.horizontalCenter = 'left';
    minRange.value = heatLegend.minValue;
    minRange.label.text = 'No tax benefit';
    minRange.label.fontSize = '12px';


    const maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.label.horizontalCenter = 'right';
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = 'Tax parity';
    maxRange.label.fontSize = '12px';


    // Blank out internal heat legend value axis labels
    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function () {
      return '';
    });
    const heatColors = [
      am4core.color('#A10066'),
      am4core.color('#B12A80'),
      am4core.color('#C05599'),
      am4core.color('#A1A1A1'),
      am4core.color('#CACACA'),
    ];

    polygonSeries.mapPolygons.template.adapter.add('fill', function (
      fill,
      mapPolygon
    ) {
      if (mapPolygon.dataItem._dataContext !== undefined) {
        if (mapPolygon.dataItem._dataContext.taxCategory !== undefined) {
          if (mapPolygon.dataItem._dataContext.taxCategory === 'Tax Parity') {
            return new am4core.Color(
              heatColors[0].rgb
            );
          }
          if (mapPolygon.dataItem._dataContext.taxCategory === 'Tax Credit') {
            return new am4core.Color(
              heatColors[1].rgb
            );
          }
          if (mapPolygon.dataItem._dataContext.taxCategory === 'Tax Deduction') {
            return new am4core.Color(
              heatColors[2].rgb
            );
          }
          if (mapPolygon.dataItem._dataContext.taxCategory === 'No State Tax') {
            return new am4core.Color(
              heatColors[3].rgb
            );
          }
          if (mapPolygon.dataItem._dataContext.taxCategory === 'No Tax Benefits') {
            return new am4core.Color(
              heatColors[4].rgb
            );
          }
        }
      }
    });

    // Configure series tooltip
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {taxCategory}';

    // Create hover state and set alternative fill color
    const hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color('#ff7843');

    $('g').find('[aria-labelledby="id-82-title"]').css('display', 'none');
  }

  render() {
    return (
      <>
        <Header hideNotificationAndProfileDropdown={true} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5 ">
            {localStorage.getItem('hasTuitionPlan') === 'true' ?
              <Breadcrumb list={MapBreadcrumbTR} /> : <Breadcrumb list={MapBreadcrumb} />}
            <div className='bg-white p-4 Gborder-05 rounded-lg'>
              <section className="box-header">
                <div className='pinkCircularDiv mb-3'>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 2.5C10.1625 2.5 6.25 6.4125 6.25 11.25C6.25 17.8125 15 27.5 15 27.5C15 27.5 23.75 17.8125 23.75 11.25C23.75 6.4125 19.8375 2.5 15 2.5ZM8.75 11.25C8.75 7.8 11.55 5 15 5C18.45 5 21.25 7.8 21.25 11.25C21.25 14.85 17.65 20.2375 15 23.6C12.4 20.2625 8.75 14.8125 8.75 11.25Z" fill="var(--icon-color)" />
                    <path d="M15 14.375C16.7259 14.375 18.125 12.9759 18.125 11.25C18.125 9.52411 16.7259 8.125 15 8.125C13.2741 8.125 11.875 9.52411 11.875 11.25C11.875 12.9759 13.2741 14.375 15 14.375Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <div className="Gfs-225 sans-semibold my-3">529 Tax Benefits by State</div>
              </section>
              <div className="">
                <div className="map--div bg-white Gborder-05 rounded-lg my-4">
                  <div id="GMap-chart" className="GMap-chart"></div>
                </div>

                <div className='G-grid11-2'>
                  <div className='bg-white Gborder-05 rounded-lg'>
                    <div className='Gb-pink-8 py-2'>
                      <h3 className="heading text-white text-center mb-0">Tax Parity
                        <InfoCircle className="mx-2" data-for='taxParity' data-tip />
                        <ReactTooltip id="taxParity" place="top" effect="solid">
                          These states offer residents a tax deduction for contributing to any 529 plan, including out of-state plans.
                        </ReactTooltip>
                      </h3>
                    </div>
                    <div className='G-grid41 p-3'>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://az529.gov/">Arizona</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.arkansas529.org/">Arkansas</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.learningquest.com/home.html">Kansas</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.mnsaves.org/">Minnesota</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.missourimost.org/home.html">Missouri</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://achievemontana.com/">Montana</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.pa529.com/">Pennsylvania</a>
                      </p>
                    </div>
                  </div>
                  <div className='bg-white Gborder-05 rounded-lg'>
                    <div className='Gb-pink-4 py-2'>
                      <h3 className="heading text-white text-center mb-0">Tax Credit
                        <InfoCircle className="mx-2" data-for='taxCredit' data-tip />
                        <ReactTooltip id="taxCredit" place="top" effect="solid">
                          These states offer a tax credit that decreases state taxes owed.
                        </ReactTooltip>
                      </h3>
                    </div>
                    <div className='G-grid41 p-3'>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.collegechoicedirect.com/">Indiana</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.oregoncollegesavings.com/">Oregon</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://my529.org/">Utah</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.vheip.org/">Vermont</a>
                      </p>
                    </div>
                  </div>
                  <div className='bg-white Gborder-05 rounded-lg'>
                    <div className='Gb-pink-3 py-2'>
                      <h3 className="heading text-white text-center mb-0">Tax Deduction
                        <InfoCircle className="mx-2" data-for='taxDeduct' data-tip />
                        <ReactTooltip id="taxDeduct" place="top" effect="solid">
                          These states offer a deduction that lowers state taxable income based on 529 plan contributions.
                        </ReactTooltip>
                      </h3>
                    </div>
                    <div className='G-grid41 p-3'>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.collegecounts529.com/">Alabama</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.collegeinvest.org/529-savings-plans/direct-portfolio/">Colorado</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.aboutchet.com/">Connecticut</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.dccollegesavings.com/">District of Columbia</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.path2college529.com/">Georgia</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.idsaves.org/">Idaho</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.brightstart.com/">Illinois</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.brightstart.com/">Iowa</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.startsaving.la.gov/">Louisiana</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://maryland529.com/">Maryland</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.mefa.org/save/u-fund-college-investing-plan">Massachusetts</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.misaves.com/plan/">Michigan</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://treasury.ms.gov/for-citizens/college-savings-mississippi/">Mississippi</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://nest529.com/wp-content/uploads/2021/08/123692_NEST_Direct_Payroll_Direct_Deposit_Form_Fillable.pdf">Nebraska</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.theeducationplan.com/">New Mexico</a>
                      </p>
                      <p className="">
                        <a className="Gt-orange-5" href="https://www.nysaves.org/home.html">New York</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.collegesave4u.com/">North Dakota</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.collegeadvantage.com/">Ohio</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.ok4saving.org/">Oklahoma</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.collegeboundsaver.com/home.html">Rhode Island</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://futurescholar.com/">South Carolina</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.virginia529.com/">Virginia</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.smart529.com/home.html">West Virginia</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.edvest.com/">Wisconsin</a>
                      </p>
                    </div>
                  </div>
                  <div className='bg-white Gborder-05 rounded-lg'>
                    <div className='Gb-white-7 py-2'>
                      <h3 className="heading text-white text-center mb-0">No State Tax
                        <InfoCircle className="mx-2" data-for='noStateTax' data-tip />
                        <ReactTooltip id="noStateTax" place="top" effect="solid">
                          These states do not have state income taxes.
                        </ReactTooltip>
                      </h3>
                    </div>
                    <div className='G-grid41 p-3'>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.alaska529plan.com/">Alaska</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.myfloridaprepaid.com/savings-plan/">Florida</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="http://www.nevadatreasurer.gov/CollegeSavings/CSP_Home/">Nevada</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.nh.gov/treasury/college-savings/unique.htm">New Hampshire</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.collegeaccess529.com/">South Dakota</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://tnstars.treasury.tn.gov/">Tennessee</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.texascollegesavings.com/">Texas</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://dreamahead.wa.gov/">Washington</a>
                      </p>
                      <p className='p-2 mb-0 Gt-orange-5'>
                        Wyoming
                      </p>
                    </div>
                  </div>
                  <div className='bg-white Gborder-05 rounded-lg'>
                    <div className='Gb-white-6 py-2'>
                      <h3 className="heading text-dark text-center mb-0">No Tax Benefits
                        <InfoCircle className="mx-2" data-for='noTaxBen' data-tip />
                        <ReactTooltip id="noTaxBen" place="top" effect="solid">
                          These states do not offer any tax benefit, for residents or non-residents alike.
                        </ReactTooltip>
                      </h3>
                    </div>
                    <div className='G-grid41 p-3'>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.scholarshare529.com/">California</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.fidelity.com/delaware-529/index.shtml">Delaware</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.hi529.com/">Hawaii</a>
                      </p>
                      <p className="p-2 mb-0">
                        <a className="Gt-orange-5" href="https://www.kysaves.com/">Kentucky</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.nextgenforme.com/">Maine</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.njbest.com/">New Jersey</a>
                      </p>
                      <p className='p-2 mb-0'>
                        <a className="Gt-orange-5" href="https://www.cfnc.org/save-for-college/">North Carolina</a>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Maps;
