import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { getServicers, getAddresses, postServicer, postServicerAccounts, postFiveTwoNinePlanAddress } from "../../../actions/fiveTwoNineActions";
import { responseMessage } from "../../../utils/alert";
import Select from 'react-select';
import ValidatorMessage from './../../shared/ValidatorMessage/ValidatorMessage';
import { Form, Modal } from 'react-bootstrap';
import InfoCircleFill from "react-bootstrap-icons/dist/icons/info-circle-fill";
import { Check, XLg } from "react-bootstrap-icons";
import { returnChecked, returnSelectAddress, returnValidationMessageStatus, States } from './Pay.constant';
import CustomModal from "../../shared/CustomModal/CustomModal";
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { ContributionBreadcrumb } from "../CommonBreadcrumb/Breadcrumb.constant";
import { fetchCustomer } from "../../../actions/customerActions";
import Style from './Pay.module.css';
class Contribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alphabeticallySortedServicers: [],
      customerData: null,
      hideEmployerContribution: false,
      servicerAccountsData: {},
      servicers: [],
      servicer_accounts: [],
      address: "",
      addresses: [],
      addressData: [],
      showAddresDiv: false,
      requestData: {
        data: {
          attributes: {},
        },
      },
      selected: "",
      pay_active: false,
      postServicerData: {},
      name: "",
      street_1: "",
      street_2: "",
      state: "",
      city: "",
      zip_code: "",
      validationError: {
        account_number: false,
        beneficiary_name: false,
        servicerId: false,
        addressId: false,
      },
      account_number: "",
      beneficiary_name: "",
      servicerId: "",
      addressId: "",
      states: States,
      isOpen: false,
      selected529PlanName: '',
      modalProp: {
        show: false,
        title: '',
        bodyText: '',
        footerButton: true,
        footerButtonName: '',
        footerButtonOnclick: '',
        footerButtonHref: '',
        footerButtonOnclickArgs: ''
      }
    };
    this.validator = new SimpleReactValidator({
      element: (message) => {
        return <p className="text-danger mb-0">{message}</p>;
      },
      autoForceUpdate: this,
    });
  }

  componentDidMount() {
    document.title = "Vault | Add a 529 account";
    const { getServicers, getAddresses, fetchCustomer } = this.props;
    getServicers();
    getAddresses();
    fetchCustomer();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.servicers !== this.props.servicers) {
      let servicerAccountsData = this.props.servicers.data;
      let alphabeticallySortedServicers = servicerAccountsData.sort((a, b) => {
        return a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase());
      });
      this.setState({
        servicers: this.props.servicers.data,
        alphabeticallySortedServicers,
      });
    }

    if (prevProps.addresses !== this.props.addresses) {
      this.setState({
        addresses: this.props.addresses.data,
      });
    }
    if (prevProps.postServicerData !== this.props.postServicerData) {
      const { getServicers, getAddresses } = this.props;
      getServicers();
      getAddresses();
      this.closeModal();
      responseMessage("success", "Success", "You have successfully added servicer!");
    }
    if (prevProps.fiveTwoNinePlanAddress !== this.props.fiveTwoNinePlanAddress) {
      const { getServicers, getAddresses } = this.props;
      const responseWithNewAddress = this.props.fiveTwoNinePlanAddress?.data;
      getServicers();
      getAddresses();
      let requestData = this.state.requestData;
      const addressJson = {
        data: {
          type: responseWithNewAddress.type,
          id: responseWithNewAddress.id,
          servicerId: responseWithNewAddress.id,
        },
      };
      requestData.data.relationships["address"] = addressJson;
      this.setState({
        addressData: [...this.state.addressData, responseWithNewAddress],
        showAddresDiv: true,
        addressId: responseWithNewAddress.id,
        requestData: requestData,
        isOpen: false,
      });
      responseMessage("success", "You have successfully added address!");

    }
    if (prevProps.servicerAccountsData !== this.props.servicerAccountsData) {
      this.setState({
        ...this.state,
        modalProp: {
          ...this.state.modalProp,
          show: true,
          title: 'Your 529 account has been saved',
          bodyText: 'Redirecting you to the dashboard in 5 seconds...',
          footerButton: true,
          footerButtonName: "Back To Dashboard",
          footerButtonOnclick: '',
          footerButtonHref: '/dashboard',
          footerButtonOnclickArgs: ''
        }
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/dashboard",
        })
      }, 5000)
    }
    if (prevProps.errors !== this.props.errors) {
      let errorMsg = 'Error'
      if (this.props.errors?.errors?.response?.data?.errors[0].detail) {
        let key = Object.keys(this.props.errors?.errors?.response?.data?.errors[0].detail)
        errorMsg = this.removeUnderscoreAndApplyCaps(key[0]) + " " + this.props.errors?.errors?.response?.data?.errors[0].detail[key[0]]
      }
      responseMessage("error", errorMsg);
    }
    if (prevProps.customerData !== this.props.customerData) {
      this.setState({
        customerData: this.props.customerData,
        hideEmployerContribution: this.props.customerData.attributes.employer_match === 0
      })
    }
  };
  removeUnderscoreAndApplyCaps(text) {
    let camel = text.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
      return ($1 && ' ') + $2.toUpperCase();
    });
    return camel;
  }
  changeLoanServicer(e) {
    if (!!e) {
      const { addresses, servicers, requestData } = this.state;
      const servicerId = e.value;

      let servicer = servicers.find(function (value) {
        return value.id === servicerId;
      });

      let address = servicer.relationships.addresses.data;

      let address_array = [];
      for (var i = 0; i < address.length; i++) {
        let addressId = address[i].id;
        let itemAddress = addresses.find((item) => item.id === addressId);
        address_array.push(itemAddress);
      }
      const servicerJson = {
        servicer: {
          data: {
            type: servicer.type,
            id: servicer.id,
          },
        },
      };
      requestData.data.attributes["match_active"] = servicer.attributes.match_active;
      requestData.data.attributes["pay_active"] = servicer.attributes.pay_active;
      requestData.data["relationships"] = servicerJson;

      this.setState({
        addressData: address_array,
        showAddresDiv: true,
        requestData: requestData,
        servicerId: servicerId,
        selected529PlanName: e.label,
      });
    } else {
      this.setState({
        servicerId: null,
        addressId: "",
        addressData: [],
        showAddresDiv: false,
        selected: "",
      });
    }
  }
  selectAddress(addressValue) {
    let requestData = this.state.requestData;
    const { address, addressState } = returnSelectAddress(addressValue, requestData);

    requestData.data.relationships["address"] = address;
    this.setState(addressState);
  }
  handleInputChange(e) {
    const { requestData } = this.state;
    requestData.data.attributes[e.target.name] = e.target.value;
    this.setState({
      requestData: requestData,
      [e.target.name]: e.target.value,
    });
  }
  saveChanges(e) {
    if (this.validator.allValid()) {
      const { requestData } = this.state;
      requestData.data.attributes["is_fivetwonine"] = true;
      requestData.data.attributes["account_balance"] = null;
      requestData.data.attributes["monthly_payment"] = null;
      requestData.data.attributes["interest_rate"] = null;
      requestData.data.attributes["updated_at"] = null;
      requestData.data.attributes["contribution_to_date"] = null;
      requestData.data.attributes["minimum_payment_deduction"] = null;
      requestData.data.attributes["match_payment"] = null;
      requestData.data.attributes["is_finicity_linked"] = false;
      requestData.data.attributes["is_finicity_synced"] = false;
      requestData.data.attributes["admin_pause_payments"] = false;
      requestData.data.attributes["user_pause_payments"] = false;
      requestData.data.attributes["user_pause_payments_reason_type_id"] = 1;
      requestData.data.attributes["user_pause_payments_reason_type_name"] = 1;
      requestData.data.attributes["user_pause_payments_reason"] = "";
      requestData.data["type"] = "servicer-accounts";
      requestData.data.attributes.pay_active = this.state.pay_active;
      this.props.postServicerAccounts(requestData);
    } else {
      this.validator.showMessages();
    }
  }
  handleInputChangeServicer(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleStateChange(e) {
    if (e) {
      this.setState({
        ['state']: e.value
      })
    }
  }
  submitAddress(e) {
    const { street_1, street_2, city, state, zip_code } = this.state;
    const postJson = {
      data: {
        type: "addresses",
        attributes: {
          "addressable_id": this.state.servicerId,
          "addressable_type": "Servicer",
          street_1: street_1,
          street_2: street_2,
          city: city,
          state: state,
          zip_code: zip_code,
        },
      },
    };
    this.props.postFiveTwoNinePlanAddress(postJson);
  }
  showValidationMessage(event) {
    this.setState({
      validationError: {
        ...this.state.validationError,
        [event.target.name]: returnValidationMessageStatus(this.validator, event)
      },
    });

  }
  handleCheckbox = (e) => {
    this.setState({
      pay_active: returnChecked(e)
    })
  }
  openModal = () => this.setState({
    ...this.state, isOpen: true, modalProp: {
      ...this.state.modalProp,
      show: false,
    }
  });
  closeModal = () => this.setState({ ...this.state, isOpen: false });
  render() {
    const { addressData, showAddresDiv, states, alphabeticallySortedServicers, hideEmployerContribution } = this.state;
    const servicersList = alphabeticallySortedServicers.map((item) => {
      return {
        label: item.attributes.name, value: item.id
      };
    });

    return (
      <>
        <Header {...this.props} />
        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={ContributionBreadcrumb} />
            <div className=" p-4 Gborder-05 bg-white G-grid11-2 my-3">
              <div>
                <div className="pinkCircularDiv mb-3">
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.125 8.5625C17.5394 8.5625 17.9368 8.72712 18.2299 9.02015C18.5229 9.31317 18.6875 9.7106 18.6875 10.125C18.6875 10.5394 18.5229 10.9368 18.2299 11.2299C17.9368 11.5229 17.5394 11.6875 17.125 11.6875C16.7106 11.6875 16.3132 11.5229 16.0201 11.2299C15.7271 10.9368 15.5625 10.5394 15.5625 10.125C15.5625 9.7106 15.7271 9.31317 16.0201 9.02015C16.3132 8.72712 16.7106 8.5625 17.125 8.5625M10.875 8.5625C11.2894 8.5625 11.6868 8.72712 11.9799 9.02015C12.2729 9.31317 12.4375 9.7106 12.4375 10.125C12.4375 10.5394 12.2729 10.9368 11.9799 11.2299C11.6868 11.5229 11.2894 11.6875 10.875 11.6875C10.4606 11.6875 10.0632 11.5229 9.77015 11.2299C9.47712 10.9368 9.3125 10.5394 9.3125 10.125C9.3125 9.7106 9.47712 9.31317 9.77015 9.02015C10.0632 8.72712 10.4606 8.5625 10.875 8.5625ZM8.375 14.5H19.625C18.675 16.7125 16.5 18.25 14 18.25C11.5 18.25 9.325 16.7125 8.375 14.5ZM0.25 12C0.25 9.7375 1.75 7.825 3.825 7.2125C5.6125 3.3875 9.5 0.75 14 0.75C18.5 0.75 22.3875 3.3875 24.1875 7.2125C26.25 7.825 27.75 9.7375 27.75 12C27.75 14.2625 26.25 16.175 24.1875 16.7875C22.3875 20.6125 18.5 23.25 14 23.25C9.5 23.25 5.6125 20.6125 3.825 16.7875C1.75 16.175 0.25 14.2625 0.25 12ZM14 3.25C10.025 3.25 6.675 5.9 5.6 9.5H5.25C4.58696 9.5 3.95107 9.76339 3.48223 10.2322C3.01339 10.7011 2.75 11.337 2.75 12C2.75 12.663 3.01339 13.2989 3.48223 13.7678C3.95107 14.2366 4.58696 14.5 5.25 14.5H5.6C6.675 18.1 10.025 20.75 14 20.75C17.975 20.75 21.325 18.1 22.4 14.5H22.75C23.413 14.5 24.0489 14.2366 24.5178 13.7678C24.9866 13.2989 25.25 12.663 25.25 12C25.25 11.337 24.9866 10.7011 24.5178 10.2322C24.0489 9.76339 23.413 9.5 22.75 9.5H22.4C21.325 5.9 17.975 3.25 14 3.25Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h2>Add a 529 account</h2>
                <p className="sans-medium">Add a plan: if you already have  529 savings plan you can enter your account’s information here. You may be able to invite friends and family to make contributions to your direct sold plans and potentially advisor sold plans. </p>
                <div className="py-3 Gb-pink-2 pl-3 rounded-lg sans-semibold d-flex pr-2">
                  <div className="my-auto mr-3">
                    <InfoCircleFill className="mr-1 Gt-pink-8" size={24} />
                  </div>
                  <p className="mb-0 p-2">Don’t worry! Program details like your balance and personal contributions are never shared with your employer.</p>
                </div>
              </div>
              <div>
                <h3 className="Gt-slate-5 sans-semibold Gfs-125">Send Contributions to:</h3>
                {servicersList.length > 0 ?
                  <Form.Group>
                    <Form.Label>529 Plan Name*</Form.Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="reactSelect"
                      isClearable={true}
                      onChange={this.changeLoanServicer.bind(this)}
                      onBlur={(e) => this.showValidationMessage(e)}
                      isSearchable={true}
                      name="servicerId"
                      placeholder="Select or search your servicer"
                      options={servicersList}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='servicerId' fieldValue={this.state.servicerId} message='Servicer is required' />
                  </Form.Group>
                  : ''}

                {showAddresDiv ? (
                  <div className="my-4">

                    <div>
                      <h4 className="Gt-slate-5 Gfs-125">Select your plan provider’s payment address.</h4>
                      <p className="sans-medium Gfs-087">Can't find the address or account information? Here's where to look: Check your mail: Plan Providers may periodically send a physical payment coupon. If you don’t have a recent payment slip, your plan provider’s address will be listed on their website or you may be able to obtain the address by calling the plan provider directly.</p>
                    </div>
                    <div>
                      {addressData
                        ? addressData.map((item, index) => {
                          let selectedAddress = item?.id === this.state.addressId ? 'selected' : ''
                          return (
                            <div key={index} className={`G-addressBox ${selectedAddress} my-2`} onClick={() => this.selectAddress(item)}>
                              <p className="mb-0">
                                {item.attributes.address}
                              </p>
                            </div>
                          );
                        })
                        : ""}
                      <ValidatorMessage validator={this.validator} fieldName='addressId' fieldValue={this.state.addressId} message='Select a address' />
                      <button className="Gt-pink-8 text-decoration-none cancelLink G-cursor-pointer" onClick={this.openModal}>
                        Add a new address
                      </button>
                    </div>
                  </div>
                ) : null}

                <h3 className="Gt-slate-5 sans-semibold Gfs-125">Account Details:</h3>
                <Form.Group className='mb-4'>
                  <Form.Label className="mb-0">Account Number*</Form.Label>
                  <Form.Text className="text-muted sans-medium">Your Social Security number will not be your Account Number</Form.Text>
                  <Form.Control type="text" name='account_number' placeholder="Account Number" onChange={(e) => this.handleInputChange(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='account_number' fieldValue={this.state.account_number} message='Account number is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Beneficiary Name*</Form.Label>
                  <Form.Control type="text" name='beneficiary_name' placeholder="Beneficiary Name" onChange={(e) => this.handleInputChange(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='beneficiary_name' fieldValue={this.state.beneficiary_name} message='Beneficiary name is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Option Code</Form.Label>
                  <Form.Text className="text-muted sans-medium">Some 529 account owners receive a code that is related to the investment options that have been selected for a particular 529 plan(age based, risk based). These codes are used by 529 plan administrators</Form.Text>
                  <Form.Control type="text" name='option_code' placeholder="Option Code" onChange={(e) => this.handleInputChange(e)} />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">UGIFT Code</Form.Label>
                  <Form.Text className="text-muted sans-medium">Required for Friends & Family contributions: A Ugift code allows friends and family to contribute to a specific beneficiary’s 529 account. Account owners are given a unique Ugift code for each beneficiary that can be shared with others. To find your beneficiary’s Ugift code, log into your 529 account and enable the Ugift feature within it to get your code</Form.Text>
                  <Form.Control type="text" name='ugift_code' placeholder="UGIFT Code" onChange={(e) => this.handleInputChange(e)} />
                </Form.Group>

                {
                  !hideEmployerContribution &&
                  <Form.Group>
                    <Form.Check
                      type='switch'
                      id='defaultUnchecked'
                      checked={this.state.pay_active}
                      value={this.state.pay_active}
                      onChange={(e) => this.handleCheckbox(e)}
                      name='pay_active'
                      label="Receive Employer's contributions to this account"
                    />
                  </Form.Group>
                }
              </div>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button className="G-cancel-button mx-3 mob-separate-v6" onClick={() => localStorage.getItem('pruSavingsPlanEnabled') === 'true' ? this.props.history.push("/dashboard") : this.props.history.push("/dashboard")}>
                Cancel
              </button>
              <button className="G-orange-button mob-separate-v6" onClick={this.saveChanges.bind(this)}>
                Continue
              </button>
            </div>
          </div>
        </div>
        <Footer />
        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Body>
            <div className="d-flex">
              <div className="pinkCircularDiv mb-3 mr-3 p-3">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 5C17.4125 5 21.25 6.75 21.25 11.4375C21.25 14.1375 19.1 17.275 15 20.5875C10.9 17.275 8.75 14.125 8.75 11.4375C8.75 6.75 12.5875 5 15 5ZM15 2.5C10.9125 2.5 6.25 5.575 6.25 11.4375C6.25 15.3375 9.1625 19.45 15 23.75C20.8375 19.45 23.75 15.3375 23.75 11.4375C23.75 5.575 19.0875 2.5 15 2.5Z" fill="var(--icon-color)" />
                  <path d="M15 8.75C13.625 8.75 12.5 9.875 12.5 11.25C12.5 12.625 13.625 13.75 15 13.75C15.663 13.75 16.2989 13.4866 16.7678 13.0178C17.2366 12.5489 17.5 11.913 17.5 11.25C17.5 10.587 17.2366 9.95107 16.7678 9.48223C16.2989 9.01339 15.663 8.75 15 8.75V8.75ZM6.25 25H23.75V27.5H6.25V25Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <div className={Style.adjustModalWidth}>
                <div className="d-flex">
                  <h4 className="py-3">{this.state.selected529PlanName}</h4>
                  <div> <button onClick={this.closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                </div>
                <div>
                  <Form.Group className='my-4'>
                    <Form.Label className="mb-0">Street 1</Form.Label>
                    <Form.Control type="text" name='street_1' placeholder="Street 1" onChange={this.handleInputChangeServicer.bind(this)} />
                  </Form.Group>
                  <Form.Group className='my-4'>
                    <Form.Label className="mb-0">Street 2</Form.Label>
                    <Form.Control type="text" name='street_2' placeholder="Street 2" onChange={this.handleInputChangeServicer.bind(this)} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="reactSelect"
                      isClearable={true}
                      onChange={(e) => this.handleStateChange(e)}
                      isSearchable={true}
                      name="state"
                      placeholder="Select or Search State"
                      options={states.map(item => {
                        return {
                          label: item.name,
                          value: item.value
                        }
                      })}
                    />
                  </Form.Group>
                  <Form.Group className='my-4'>
                    <Form.Label className="mb-0">City</Form.Label>
                    <Form.Control type="text" name='city' placeholder="City" onChange={this.handleInputChangeServicer.bind(this)} />
                  </Form.Group>
                  <Form.Group className='my-4'>
                    <Form.Label className="mb-0">Zip Code</Form.Label>
                    <Form.Control type="text" name='zip_code' placeholder="Zip Code" onChange={this.handleInputChangeServicer.bind(this)} />
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-end p-3">
                  <button type="button" className="G-cancel-button mx-3" onClick={this.closeModal}>
                    Cancel
                  </button>
                  <button type="button" className="G-orange-button" onClick={this.submitAddress.bind(this)}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <CustomModal modalProp={{ ...this.state.modalProp }}
          svgComponent={<div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div>}
        />
      </>
    );
  }
}

Contribution.propTypes = {
  getServicers: PropTypes.func.isRequired,
  getAddresses: PropTypes.func.isRequired,
  postServicer: PropTypes.func.isRequired,
  postServicerAccounts: PropTypes.func.isRequired,
  postFiveTwoNinePlanAddress: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  servicers: state.five_two_nine.getServicersData,
  addresses: state.five_two_nine.getAddressData,
  postServicerData: state.five_two_nine.postServicerData,
  servicerAccountsData: state.five_two_nine.servicerAccountsData,
  fiveTwoNinePlanAddress: state.five_two_nine.getFiveTwoNinePlanAddress,
  customerData: state.customer_array.customerData,
  errors: state.errors,
});
export default connect(mapStateToProps, { getServicers, getAddresses, postServicer, postServicerAccounts, postFiveTwoNinePlanAddress, fetchCustomer })(Contribution);
