import React, { Component } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { Button, Modal } from 'react-bootstrap'
import { payrollDatePlusOneSub, payrollDatePlusOneDay } from '../../../common/js/jsFunctions';
import { getFinicityLinkableServicerAccount } from "../servicer-account-service";
import { getAtLeastOneFailedDisbursement, getPausedPayments, getShowCurrentPayrollDate, isFinicitySynced } from "../pay";
import isCitizens from '../is-citizens';
// import "./Payments/Payments.css";
import S from './Payments.module.css';
import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import Select from 'react-select';
import { PublicServiceLogo, pauseOptions } from './Payment.constant';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import Pencil from 'react-bootstrap-icons/dist/icons/pencil';
import PauseFill from 'react-bootstrap-icons/dist/icons/pause-fill';
import ArrowRepeat from 'react-bootstrap-icons/dist/icons/arrow-repeat';
import Link45deg from 'react-bootstrap-icons/dist/icons/link-45deg';
import InfoCircleFill from 'react-bootstrap-icons/dist/icons/info-circle-fill';
import LifeTimeCapScore from './LifeTimeCapScore';
import { CurrencyDollar, ExclamationTriangleFill } from 'react-bootstrap-icons';
import PayToAdvisorBanner from './PayToAdvisorBanner';



class PaymentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedServicerAccount: null,
      showPauseReasonOther: false,
      user_pause_payments_reason_type_id: "",
      user_pause_payments_reason: "",
      showPlanDetailsModal: false,
      showPauseModal: false,
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  toggleUserPausePayments = (e, data) => {
    this.setState({
      selectedServicerAccount: data,
      showPauseModal: true,
    })
    intercomTrackEvent(eventNames.clickedPausePayPayment);
  }
  handleServicerAccountPauseReasonType = (e) => {
    if (e.value === '4') {
      this.setState({
        showPauseReasonOther: true
      })
    } else {
      this.setState({
        showPauseReasonOther: false
      })
    }
    this.setState({
      ["user_pause_payments_reason_type_id"]: e.value
    })
    // TODO: after merger of main and genius-8874, replace 'e' with customEventObj
  }
  handleServicerAccountPauseReasonData = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleSubmit = (e) => {
    const { selectedServicerAccount } = this.state;

    if (this.validator.fieldValid('user_pause_payments_reason_type_id')) {
      if (this.state.user_pause_payments_reason_type_id === '4') {
        if (this.validator.fieldValid('user_pause_payments_reason')) {
          selectedServicerAccount.attributes.user_pause_payments_reason_type_id = this.state.user_pause_payments_reason_type_id;
          selectedServicerAccount.attributes.user_pause_payments_reason = this.state.user_pause_payments_reason;
          selectedServicerAccount.attributes.user_pause_payments = true
          this.props.submitServicerAccountPauseReason(selectedServicerAccount)
          this.closePauseModal();
        } else {
          this.validator.showMessages();
        }
      } else {
        selectedServicerAccount.attributes.user_pause_payments_reason_type_id = this.state.user_pause_payments_reason_type_id;
        selectedServicerAccount.attributes.user_pause_payments_reason = null;
        selectedServicerAccount.attributes.user_pause_payments = true
        this.props.submitServicerAccountPauseReason(selectedServicerAccount)
        this.closePauseModal();
      }

    } else {
      this.validator.showMessages();
    }
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  setShowPlanDetailsModal = (status) => {
    this.setState({
      showPlanDetailsModal: status,
    })
  }
  clickedFaq = (e) => {
    const { customer } = this.props;
    if (isCitizens(customer.attributes.partner_name)) {
      this.props.history.push({
        pathname: '/citizens/pay-faq'
      });
    } else {
      const url = "https://support.vault.co/kb/section/3/";
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null
    }
  }
  closePauseModal = () => {
    this.setState({
      showPauseModal: false,
      showPauseReasonOther: false,
    })
  }
  render() {
    const { showPauseReasonOther, showPlanDetailsModal } = this.state;
    const { servicerAccounts, customer, employer, fauxFinicityModalEnabled, disbursements } = this.props;

    const accts = getFinicityLinkableServicerAccount(employer, servicerAccounts)
    const finicityLinkableServicerAccount = accts.length === 1 ? accts[0] : null;


    let payrollCycle = "";
    let employerName = "";
    let payroll = "";
    let latePdr = "";
    let latestPdrDueDate = "";
    if (employer) {
      employerName = employer.attributes.name;
      payrollCycle = employer.attributes.payroll_cycle;
      latePdr = employer.attributes.late_pdr;
      latestPdrDueDate = employer.attributes.latest_pdr_due_date ? moment(employer.attributes.latest_pdr_due_date).format('MMM. Do, YYYY') : "";

      if (payrollCycle === 'quarterly') {
        payroll = "quarter";
      } else if (payrollCycle === 'monthly') {
        payroll = "month";
      } else if (payrollCycle === 'weekly') {
        payroll = "week";
      } else if (payrollCycle === 'annually') {
        payroll = "year";
      }

    }
    let customerName = "";
    let planDetails = ""
    let billPay = "";
    let employerMatch = 0;
    if (customer) {
      customerName = customer.attributes.full_name;
      planDetails = customer.attributes.plan_details;
      billPay = customer.attributes.bill_pay;
      employerMatch = customer.attributes.employer_match;
    }
    const atLeastOneFailedDisbursement = getAtLeastOneFailedDisbursement(disbursements);

    const showCurrentPayrollDate = getShowCurrentPayrollDate(disbursements);
    return (
      <>
        <div className="">
          <div className="">

            <div className='bg-white my-5 Gborder-1 p-4 rounded-lg'>
              <div >
                <h3 className="Gfs-175 my-3 pb-0 sans-semibold"><span className=" Gt-slate-5">Hey&nbsp;</span>
                  <span className="sans-bold G-text-grad">{localStorage.getItem("customerFullName") ? localStorage.getItem("customerFullName").split(' ')[0] : "User"}!</span>
                </h3>
                <p className="sans-medium">Welcome back! Here's an overview of your dashboard</p>
              </div>
              <div className='G-grid11-2'>
                <div className="mt-4">
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M35 17.5C31.55 17.5 28.75 20.3 28.75 23.75C28.75 27.2 31.55 30 35 30C38.45 30 41.25 27.2 41.25 23.75C41.25 20.3 38.45 17.5 35 17.5ZM35 27.5C32.925 27.5 31.25 25.825 31.25 23.75C31.25 21.675 32.925 20 35 20C37.075 20 38.75 21.675 38.75 23.75C38.75 25.825 37.075 27.5 35 27.5ZM38.75 35H36.25C36.25 33.5 35.3125 32.15 33.9125 31.625L26.2125 28.75H16.25V42.5H23.75V40.7L32.5 43.125L42.5 40V38.75C42.5 36.675 40.825 35 38.75 35ZM21.25 40H18.75V31.25H21.25V40ZM32.4625 40.5125L23.75 38.125V31.25H25.7625L33.0375 33.9625C33.4625 34.125 33.75 34.5375 33.75 35C33.75 35 31.25 34.9375 30.875 34.8125L27.9 33.825L27.1125 36.2L30.0875 37.1875C30.725 37.4 31.3875 37.5 32.0625 37.5H38.75C39.2375 37.5 39.675 37.8 39.875 38.2125L32.4625 40.5125Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h4>Vault Pay: Your {payrollCycle === "annually" ? 'annual' : payrollCycle} contribution is confirmed!</h4>
                </div>
              </div>
              <div className='G-grid11-2'>
                <LifeTimeCapScore customer={customer} />
              </div>
              {finicityLinkableServicerAccount ?
                <div>
                  <p>
                    {customerName}, Vault has a new feature to more accurately track your student loan!
                  </p>
                  <p>
                    Click
                    <Link to="#" onClick={(e) => this.props.requestFinModal(e, finicityLinkableServicerAccount)} className='Gt-pink-8'> here </Link>to get set up
                  </p>
                </div>
                : null}
              <div className='mt-4'>
                {servicerAccounts.length > 0 ?
                  servicerAccounts.map((data, i) => {
                    const pausedPayments = getPausedPayments(data);
                    const finicitySynced = isFinicitySynced(data);
                    let account_number = data.attributes.account_number;
                    let account_number_short = account_number?.slice(account_number.length - 4);
                    return (<div key={i}>

                      <div className='G-grid11-2'>
                        {finicitySynced ?
                          <div>
                            <p className="py-3 pl-3 Gb-red-1 rounded-lg sans-semibold pr-2">
                              <ExclamationTriangleFill size={20} className="Gt-red-5 mr-2" />
                              Servicer is no longer linked, click <Link to="#" className='Gt-pink-8' onClick={(e) => this.props.requestFinModal(e, data)}>here</Link> to resolve.
                              <br/><br/>
                              <i>{ data.attributes.finicity_user_message }</i>
                            </p>
                            <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                              <div className='mr-3'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                                </svg>
                              </div>
                              <div>
                                <p className='sans-semibold mb-0'>
                                  While linking your account is important to help us to keep track of the balance and transactions history, rest assured that your contributions are still being processed and mailed to your account as scheduled
                                </p>
                              </div>
                            </div>
                          </div>
                          : null}
                      </div>
                      <div className='G-grid11-2'>
                        {latePdr ?
                          <div className="py-3 pl-3 Gb-red-1 rounded-lg sans-semibold d-flex">
                            <div className='my-auto mr-2'>
                              <ExclamationTriangleFill size={20} className="Gt-red-5" />
                            </div>
                            <label className='mb-0'>
                              Vault has not received funds for {latestPdrDueDate} from {employerName}. Please contact your HR team with any questions.
                            </label>
                          </div>
                          : null}
                      </div>
                      <div className='G-grid31 p-2 Gborder-05 mt-4 rounded-lg'>
                        <div className={`${S.slateRightBorder} p-2`}>
                          <span className='G-badge-purple'>You'll receive</span>
                          {
                            billPay ?
                              <h3 className="sans-extrabold Gfs-175 Gt-pink-8 my-3 p-0">$ {data.attributes.match_payment}</h3>
                              :
                              <h3 className="sans-extrabold Gfs-175 Gt-pink-8 my-3 p-0">$ {employerMatch}</h3>
                          }
                          <p className='mb-0'>every {payroll} from {employerName}</p>
                          <br />
                        </div>
                        <div className={`${S.slateRightBorder} p-2`}>
                          <span className='G-badge-purple'>Next payment on</span>
                          {atLeastOneFailedDisbursement || pausedPayments ?
                            atLeastOneFailedDisbursement && pausedPayments ?
                              <p className="">
                                Your current contribution is under review and may require your attention. Future contributions also are no longer being sent to your servicer. A Vault Genius will reach out to you or you can
                                <Link className="Gt-pink-8" to="#"> Contact Us. </Link>
                              </p>
                              : atLeastOneFailedDisbursement ?
                                <p className="">
                                  Your current contribution is under review and may require your attention. A Vault Genius will reach out to you or you can
                                  <Link className="Gt-pink-8" to="#"> Contact Us. </Link>
                                </p>
                                : pausedPayments ?
                                  (<p className="mt-2">
                                    Contributions are no longer being sent to your servicer. Please
                                    <Link className="Gt-pink-8" to="#"> Contact Us </Link>
                                    if you have questions.
                                    <br />
                                    Reason:<i>{data.attributes.user_pause_payments_reason_type_name}</i>

                                    {data.attributes.user_pause_payments_reason ?
                                      <>
                                        <br />
                                        Description:<i>{data.attributes.user_pause_payments_reason}</i>
                                      </>
                                      : null}
                                  </p>)
                                  : null
                            :

                            <div className="">
                              {showCurrentPayrollDate ?
                                <>
                                  <h3 className='sans-extrabold Gfs-175 Gt-pink-8 my-3 p-0'>{payrollDatePlusOneDay(employer?.attributes?.current_payroll_date)}</h3>
                                  <p> Your next payment will be sent on {payrollDatePlusOneDay(employer?.attributes?.current_payroll_date)} </p>
                                </>
                                :
                                <>
                                  <h3 className='sans-extrabold Gfs-175 Gt-pink-8 my-3 p-0'>{payrollDatePlusOneSub(employer)}</h3>
                                  <p> Your next payment will be sent on {payrollDatePlusOneSub(employer)} to the account ending in ...{account_number_short} </p>
                                </>
                              }
                              <br />
                              {planDetails ?
                                <Link className="Gt-pink-8" to="#" onClick={() => { intercomTrackEvent(eventNames.clickedSeePlanDetailsPay); this.setShowPlanDetailsModal(true) }}>Plan details</Link>
                                : null}
                            </div>
                          }
                        </div>
                        <div className='p-2'>
                          <span className='G-badge-purple'>Payment sent to</span>
                          <h3 className='sans-extrabold Gfs-175 Gt-pink-8 my-3 p-0'>{data.servicer.attributes.friendly_name}</h3>
                          <p>Payment will be sent to {data.servicer?.attributes.friendly_name} at: {data.address?.attributes.address}</p>
                        </div>
                      </div>
                      <div className={`d-flex justify-content-center flex-wrap my-3 ${S.flexGap1}`}>
                        {finicityLinkableServicerAccount ?
                          <button className='G-cancel-button mx-3 mob-separate-v' onClick={(e) => this.props.requestFinModal(e, finicityLinkableServicerAccount)}><Link45deg size={20} className="mr-1" />Link</button>
                          : null}

                        <Link className='Ga G-cancel-button mx-3 mob-separate-v' to={"/pay/profiles/payment/" + data.id + "/edit"}> <Pencil size={20} className="mr-1 Gt-pink-8" /><button className='border-0 bg-white Gt-pink-8' onClick={(e) => { intercomTrackEvent(eventNames.clickedEditAccountPayHistory) }}> Edit </button></Link>

                        {data.attributes.admin_pause_payments ?
                          ''
                          :
                          data.attributes.user_pause_payments ?
                            <button className='G-cancel-button mx-3 mob-separate-v' onClick={(e) => { intercomTrackEvent(eventNames.clickedUnpausePayPayment); this.props.enableServicerAccountPauseReason(e, data) }}><ArrowRepeat size={20} className="mr-1" />Restart</button>
                            :
                            <button className='G-cancel-button mx-3 mob-separate-v' onClick={(e) => this.toggleUserPausePayments(e, data)}><PauseFill size={24} className="mr-1" />Pause</button>
                        }
                        <Link to="/pay/payments-history" className='G-cancel-button Gb-pink-8 mx-3 mob-separate-v' >
                          <button className='border-0 Gb-pink-8 text-white '>
                            <CurrencyDollar size={20} className='mr-1' />
                            Payment History
                          </button>
                        </Link>
                      </div>
                    </div>)
                  }) : null}
                <p>*Servicer typically processes contributions within 3-7 days.</p>
                <div className='G-grid11-2'>
                  <p className='py-3 Gb-yellow-2 pl-3 rounded-lg sans-semibold'>
                    <InfoCircleFill size={20} className="Gt-orange-3" />
                    &nbsp; Don’t forget that you need to make your {payrollCycle === "annually" ? 'annual' : payrollCycle} minimum payment.<br />
                  </p>
                </div>
                {planDetails ?
                  <span className="Gt-pink-8" onClick={() => { intercomTrackEvent(eventNames.clickedSeePlanDetailsPay); this.setShowPlanDetailsModal(true) }}>Plan details</span>
                  : null}
              </div>
              {localStorage.getItem('hasAdvisor') === 'true' &&
                <PayToAdvisorBanner />
              }
              {!!employer?.attributes?.include_knowledge_bank &&
                <>
                  <div className='G-grid11-2'>
                    <div className="mt-5">
                      <h4>Explore Vault Resources</h4>
                      <p className="sans-medium mb-0">
                        Vault is here to provide you with access to resources you can use
                        for your own financial education purposes, whether you're
                        paying down student loans, looking for repayment programs or
                        planning for college.
                      </p>
                    </div>
                  </div>
                  <div className='G-grid11-2 mt-4'>
                    <div className='Gborder-05 p-4 rounded-lg bg-white'>
                      <div className='Gb-pink-2'>
                        <PublicServiceLogo />
                      </div>
                      <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>Public Service Loan Forgiveness </h3>
                      <p className='sans-medium mb-4'>
                        The PSLF program cancels the remaining balance on your federal loans after 120 qualifying payments have been made under a repayment plan, while you work full-time for a qualifying employer. However, meeting all eligibility requirements is necessary for loan forgiveness.
                      </p>
                      <div className="d-flex">
                        <a href="/dashboard/public-service-loan-forgiveness" className='G-cancel-button'>
                          <button className='G-link bg-white border-0'>
                            Public Service Loan Forgiveness
                          </button>
                        </a>
                      </div>
                    </div>

                  </div>
                </>
              }
            </div>
          </div>

        </div>


        <Modal size="lg" show={this.state.showPauseModal} onHide={() => this.closePauseModal()} backdrop="static" >
          <Modal.Header className="border-0 mt-2 mx-3" closeButton>
            <Modal.Title className='Gfs-175'>Tell Us Why You’re Pausing Your Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mx-3 mb-3'>
            <p className='sans-medium'>Let us know why you’re pausing your payment.</p>
            <div className='mb-4'>
              <Select
                onChange={(e) => this.handleServicerAccountPauseReasonType(e)}
                options={pauseOptions}
                placeholder='Choose an option'
                defaultInputValue=''
              />
              <ValidatorMessage validator={this.validator} fieldName='user_pause_payments_reason_type_id' fieldValue={this.state.user_pause_payments_reason_type_id} message='Please select reason!' />
            </div>

            {showPauseReasonOther ?
              <div className='mb-4'>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Why are you pausing payments?"
                  name="user_pause_payments_reason"
                  onChange={(e) => this.handleServicerAccountPauseReasonData(e)}
                />
                <ValidatorMessage validator={this.validator} customValidationType='includeNoSpecialChar' fieldName='user_pause_payments_reason' validationType='required|alpha_num_dash_space' fieldValue={this.state.user_pause_payments_reason} message='Please enter reason!' />

              </div>
              : null}

            <div className="d-flex justify-content-end mob-btn-div-column">
              <button type="button" className="G-cancel-button mx-3 mob-separate-v6" onClick={() => this.closePauseModal()}>Cancel</button>
              <button
                onClick={this.handleSubmit.bind(this)}
                type="button"
                className="G-orange-button mob-separate-v6"
              >Submit</button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={fauxFinicityModalEnabled}
          size="lg"
          dialogClassName=""
          centered
        >
          <Modal.Body className="p-4">
            <div>

              <div className={`${S.circularDiv} mb-3`}>
                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.125 11.5H3.625V20.25H6.125V11.5ZM13.625 11.5H11.125V20.25H13.625V11.5ZM24.25 22.75H0.5V25.25H24.25V22.75ZM21.125 11.5H18.625V20.25H21.125V11.5ZM12.375 3.075L18.8875 6.5H5.8625L12.375 3.075ZM12.375 0.25L0.5 6.5V9H24.25V6.5L12.375 0.25Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <h3 className='Gfs-175 Gt-slate-5'>Bank Of Vault</h3>
            </div>
            <form>
              <div>
                <p className='sans-medium'>Enter your Bank of Vault account information.</p>
              </div>
              <div>
                <div className="form-group">
                  <label for="usr">Username:</label>
                  <input type="text" className="form-control" name="username" id="usr" placeholder='Enter username' onChange={this.handleInputChange.bind(this)} />
                </div>
                <div className="form-group">
                  <label for="pwd">Password:</label>
                  <input type="password" className="form-control" name="password" id="pwd" placeholder='Enter password' onChange={this.handleInputChange.bind(this)} />
                </div>

              </div>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={this.props.onCancelFauxFinicity.bind(this)}
                  className="G-cancel-button mx-3"
                  variant='sm'
                >
                  Cancel
                </Button>
                <Button variant='sm' className="G-orange-button" onClick={this.props.onSubmitFauxFinicity.bind(this)} data-theme="vault" data-ember-action="" data-ember-action-340="340">
                  Next
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Plan Details Modal*/}
        <Modal size="lg" show={showPlanDetailsModal} onHide={() => this.setShowPlanDetailsModal(false)} animation={false} backdrop="static" keyboard={false} scrollable={true}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className='Gfs-175'>Plan Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="match__plan-details-container" dangerouslySetInnerHTML={{ __html: planDetails }}>

            </div>
          </Modal.Body>
        </Modal>

      </>
    )
  }
}

export default PaymentSummary;