import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Eye from 'react-bootstrap-icons/dist/icons/eye';
import { useState } from 'react';
import S from './PreviousFileList.module.css';

const PreviousFileList = ({fileList}) => {
    const [show, setShow] = useState(false);
    const [imgData,setImgSrc] = useState(null);
    const [loader,setLoader] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (fileData) => {
        setImgSrc(fileData);
        setShow(true); 
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        },1000)
    }

    const checkFileFormat = (fileName) => {
        if(fileName) {
        let type = fileName.split('.');
        return type[type.length-1];
        }
    }
    return ( 
        <>
        <ul>
        {fileList.map((file) => {
            const MAX_FILENAME_LENGTH = 45;
            const lengthOfName = file.attributes.file_name.length < MAX_FILENAME_LENGTH ? file.attributes.file_name.length : MAX_FILENAME_LENGTH;
            return (
                <li key={file.id} className='p-2 my-2 d-flex justify-content-between Gborder-05 rounded-lg align-items-center'>
                <div className='whiteCircularDiv'>
                <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 14.9789H12V16.9789H4V14.9789ZM4 10.9789H12V12.9789H4V10.9789ZM10 0.978943H2C0.9 0.978943 0 1.87894 0 2.97894V18.9789C0 20.0789 0.89 20.9789 1.99 20.9789H14C15.1 20.9789 16 20.0789 16 18.9789V6.97894L10 0.978943ZM14 18.9789H2V2.97894H9V7.97894H14V18.9789Z" fill="#2E2E2E"/>
                </svg>
                </div>
                <div className='pr-3 w-75'><p className='mb-0' title={file.attributes.file_name}>{file.attributes.file_name.slice(0, lengthOfName) + (lengthOfName < MAX_FILENAME_LENGTH ? "" : "...") + (lengthOfName < MAX_FILENAME_LENGTH ? "" : file.attributes.file_name.slice(-3))}</p></div>
                <div className='p-3'>
                    <Eye role='button' onClick={() => handleShow(file)} size={24} />
                     {/* <a className='Ga' href={file.attributes.url} download={file.attributes.file_name} target="_blank" rel="noopener noreferrer"><Eye size={24} /></a> */}
                </div>
            </li>
            )
        })}
       
    </ul>
    <Modal show={show} onHide={handleClose} dialogClassName={`${S.Modal90w}`} backdrop='static' centered>
        <Modal.Header className='border-0 p-1' closeButton>
          <Modal.Title>{imgData?.attributes?.file_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${S.imgContainer} p-0`}>
            {loader ? <div className='d-flex justify-content-center'> <Spinner animation="grow" /></div> :
                <>
                {'pdf' === checkFileFormat(imgData?.attributes?.file_name) ? <embed src={imgData?.attributes?.url} type="application/pdf" width="100%" height="600px" /> :
                <img src={imgData?.attributes?.url} alt="" />      
                }
                </>
            }
            </Modal.Body>
      </Modal>
    </>
     );
}
 
export default PreviousFileList;