import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import SimpleReactValidator from "simple-react-validator";
import { fetchBeneficiaryProfiles } from "../../../actions/fiveTwoNineActions";
import Select from 'react-select';
import Footer from "../../Includes/Footer/Footer";
import Header from "../../Includes/Header/Header";


class ContributionSetUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerAccounts: [],
      employer: null,
      customer: null,
      beneficiaryName: '',
      selected_amount: "",
      dollar_amount: "",
      bank_name: "",
      bank_account_number: "",
      bank_routing_number: "",
      bank_account_type: "",
      message: "",
      showDollarAmount: false,
      bank_location: false,
      terms_condition: false,
      servicer_account_id: this.props.match.params.servicerAccountId,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({
      element: (message) => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this,
    });
  }
  componentDidMount() {
    document.title = "Vault | Payments & Contributions";
    const { fetchBeneficiaryProfiles } = this.props;
    fetchBeneficiaryProfiles(this.state.servicer_account_id);


    if (this.props.location.requestData !== undefined) {
      const requestData = this.props.location.requestData
      this.setState({
        'first_name': requestData.first_name,
        'last_name': requestData.last_name,
        'email': requestData.email,
        'phone_number': requestData.phone_number,
        'selected_amount': requestData.selected_amount,
        'showDollarAmount': requestData.showDollarAmount,
        'dollar_amount': requestData.dollar_amount,
        'bank_name': requestData.bank_name,
        'bank_account_number': requestData.bank_account_number,
        'bank_routing_number': requestData.bank_routing_number,
        'bank_account_type': requestData.bank_account_type,
        'message': requestData.message,
        'bank_location': requestData.bank_location,
        'terms_condition': requestData.terms_condition
      })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.beneficiaryProfiles !== this.props.beneficiaryProfiles) {
      this.setState({
        beneficiaryProfiles: this.props.beneficiaryProfiles,
        beneficiaryName: this.props.beneficiaryProfiles[0]['attributes']['beneficiary_name']
      })
    }
  }
  handleSelectedAmount = (e) => {

    const selected_amount = e.target.value;
    if (selected_amount === 'other') {
      this.setState({
        selected_amount: selected_amount,
        dollar_amount: '',
        showDollarAmount: true
      })
    } else {
      this.setState({
        selected_amount: selected_amount,
        dollar_amount: selected_amount,
        showDollarAmount: false
      })
    }
  }
  handleCheckbox = (e) => {
    const checkboxVal = e.target.checked;
    let checked = false;
    if (checkboxVal === true) {
      checked = true
    }
    this.setState({
      bank_location: checked
    })
  }
  handleTermsCondition = (e) => {
    const termsCondition = e.target.checked;

    let checked = false
    if (termsCondition === true) {
      checked = true
    }
    this.setState({
      terms_condition: checked
    })
  }
  change(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  changeSelect(e) {
    if (e) {
      this.setState({
        ['bank_account_type']: e.value
      })
    }
  }
  handleSubmit() {
    if (this.validator.allValid()) {
      const { beneficiaryProfiles, servicer_account_id } = this.state;
      const beneficiaryProfilesData = beneficiaryProfiles.find(item => item.attributes.servicer_account_id === servicer_account_id);

      const requestData = {
        'selected_amount': this.state.selected_amount,
        'showDollarAmount': this.state.showDollarAmount,
        'dollar_amount': this.state.dollar_amount,
        'bank_name': this.state.bank_name,
        'bank_account_number': this.state.bank_account_number,
        'bank_routing_number': this.state.bank_routing_number,
        'bank_account_type': this.state.bank_account_type,
        'message': this.state.message,
        'bank_location': this.state.bank_location,
        'terms_condition': this.state.terms_condition,
        'servicer_account_id': this.state.servicer_account_id,
        'beneficiary_name': beneficiaryProfilesData.attributes.beneficiary_name,
        'account_owner_name': beneficiaryProfilesData.attributes.account_owner_name,
        'ugift_code': beneficiaryProfilesData.attributes.ugift_code,
        'first_name': this.state.first_name,
        'last_name': this.state.last_name,
        'email': this.state.email,
        'phone_number': this.state.phone_number,
      }
      this.props.history.push({
        pathname: '/' + this.state.servicer_account_id + '/confirm-contribution',
        requestData: requestData
      })
    } else {
      this.validator.showMessages();
    }
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false,
        },
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true,
        },
      });
    }
  }
  render() {
    const bankAccountTypeList = [
      { label: 'Checking', value: 'checking' },
      { label: 'Savings', value: "savings" }
    ]

    return (
      <>
        {/* <MainHeader {...this.props} /> */}
        <Header showLogoOnly={true} />
        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <div className=" p-4 Gborder-05 bg-white G-grid11-2 my-3">
              <div>
                <div className="pinkCircularDiv mb-3">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M35 17.5C31.55 17.5 28.75 20.3 28.75 23.75C28.75 27.2 31.55 30 35 30C38.45 30 41.25 27.2 41.25 23.75C41.25 20.3 38.45 17.5 35 17.5ZM35 27.5C32.925 27.5 31.25 25.825 31.25 23.75C31.25 21.675 32.925 20 35 20C37.075 20 38.75 21.675 38.75 23.75C38.75 25.825 37.075 27.5 35 27.5ZM38.75 35H36.25C36.25 33.5 35.3125 32.15 33.9125 31.625L26.2125 28.75H16.25V42.5H23.75V40.7L32.5 43.125L42.5 40V38.75C42.5 36.675 40.825 35 38.75 35ZM21.25 40H18.75V31.25H21.25V40ZM32.4625 40.5125L23.75 38.125V31.25H25.7625L33.0375 33.9625C33.4625 34.125 33.75 34.5375 33.75 35C33.75 35 31.25 34.9375 30.875 34.8125L27.9 33.825L27.1125 36.2L30.0875 37.1875C30.725 37.4 31.3875 37.5 32.0625 37.5H38.75C39.2375 37.5 39.675 37.8 39.875 38.2125L32.4625 40.5125Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h2>Set up contribuion</h2>
                <p className="sans-medium">Here, you can make a secure and easy contribution to {this.state.beneficiaryName}'s educational goals. Please fill out the form below and choose your preferred payment method. Thank you for supporting and helping someone achieve their dreams! </p>
              </div>
              <div>
                <Form.Group className='mb-4'>
                  <Form.Label className="mb-0">How much would you like to contribute to {this.state.beneficiaryName}'s future college tuition and other expenses?</Form.Label>
                </Form.Group>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-grow-1 flex-wrap justify-content-between">
                    <label className={`contribution-page-choose-dollar-radio ${(this.state.selected_amount === "25") ? "checked_active" : ""}`}>
                      <span>
                        <input type="radio" value="25" name="selected_amount" checked={this.state.selected_amount === '25'} onChange={this.handleSelectedAmount.bind(this)} /> $25
                      </span>
                    </label>
                    <label className={`contribution-page-choose-dollar-radio ${(this.state.selected_amount === "50") ? "checked_active" : ""}`}>
                      <span>
                        <input type="radio" value="50" name="selected_amount" checked={this.state.selected_amount === '50'} onChange={this.handleSelectedAmount.bind(this)} /> $50
                      </span>
                    </label>
                    <label className={`contribution-page-choose-dollar-radio ${(this.state.selected_amount === "100") ? "checked_active" : ""}`}>
                      <span>
                        <input type="radio" value="100" name="selected_amount" checked={this.state.selected_amount === '100'} onChange={this.handleSelectedAmount.bind(this)} /> $100
                      </span>
                    </label>
                    <label className={`contribution-page-choose-dollar-radio ${(this.state.selected_amount === "other") ? "checked_active" : ""}`}>
                      <span>
                        <input type="radio" value="other" name="selected_amount" checked={this.state.selected_amount === 'other'} onChange={this.handleSelectedAmount.bind(this)} /> OTHER
                      </span>
                    </label>
                  </div>
                  {this.validator.message(
                    "selected_amount",
                    this.state.selected_amount,
                    "required",
                    {
                      messages: {
                        required: "Choose dollar amount.",
                      },
                    }
                  )}
                </div>


                {this.state.showDollarAmount ?
                  (
                    <Form.Group className='my-4'>
                      <Form.Label className="mb-0">Dollar amount</Form.Label>
                      <Form.Control type="text" name='dollar_amount' value={this.state.dollar_amount} placeholder="Dollar amount" onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                      <ValidatorMessage validator={this.validator} fieldName='dollar_amount' fieldValue={this.state.dollar_amount} message='Dollar amount field is required' />
                    </Form.Group>
                  )
                  : null}

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">First Name</Form.Label>
                  <Form.Control type="text" name='first_name' placeholder="First Name" value={this.state.first_name} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='first_name' fieldValue={this.state.first_name} message='First Name field is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Last Name</Form.Label>
                  <Form.Control type="text" name='last_name' placeholder="Last Name" value={this.state.last_name} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='last_name' fieldValue={this.state.last_name} message='Last Name field is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Email</Form.Label>
                  <Form.Control type="text" name='email' placeholder="Email" value={this.state.email} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='email' fieldValue={this.state.email} message='Email field is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Phone Number</Form.Label>
                  <Form.Control type="text" name='phone_number' placeholder="Phone Number" value={this.state.phone_number} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='phone_number' fieldValue={this.state.phone_number} message='Phone Number field is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Bank Name</Form.Label>
                  <Form.Control type="text" name='bank_name' placeholder="Bank Name" value={this.state.bank_name} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='bank_name' fieldValue={this.state.bank_name} message='Bank Name field is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Bank account number</Form.Label>
                  <Form.Control type="text" name='bank_account_number' placeholder="Bank account number" value={this.state.bank_account_number} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='bank_account_number' fieldValue={this.state.bank_account_number} message='Bank account number field is required' />
                </Form.Group>

                <Form.Group className='my-4'>
                  <Form.Label className="mb-0">Bank routing number</Form.Label>
                  <Form.Control type="text" name='bank_routing_number' placeholder="Bank routing number" value={this.state.bank_routing_number} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                  <ValidatorMessage validator={this.validator} fieldName='bank_routing_number' fieldValue={this.state.bank_routing_number} message='Bank routing number field is required' />
                </Form.Group>


                <Form.Group>
                  <Form.Check
                    type='checkbox'
                    id='defaultUnchecked'
                    checked={this.state.bank_location}
                    value={this.state.bank_location}
                    onChange={(e) => this.handleCheckbox(e)}
                    onBlur={(e) => this.showValidationMessage(e)}
                    name='bank_location'
                    label='By adding this account, I acknowledge that my bank or financial institution is located in the U.S and/or adheres to U.S banking regulations.'
                  />
                  {this.validator.message(
                    "bank_location",
                    this.state.bank_location,
                    "accepted", {
                    messages: {
                      required: "Bank location check box is required"
                    }
                  }
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Account type</Form.Label>
                  <Select
                    className="basic-single"
                    classNamePrefix="reactSelect"
                    isClearable={true}
                    onChange={(e) => this.changeSelect(e)}
                    onBlur={(e) => this.showValidationMessage(e)}
                    isSearchable={true}
                    name="bank_account_type"
                    placeholder="Choose an option"
                    options={bankAccountTypeList}
                    defaultValue={() => {
                      if (this.props?.location?.requestData?.bank_account_type) {
                        const item = bankAccountTypeList.filter((item) => item.value === this.props.location.requestData.bank_account_type);
                        return item[0];
                      } return '';
                    }}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='bank_account_type' fieldValue={this.state.bank_account_type} message='Bank account field is required' />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" name="message" aria-label="With textarea" value={this.state.message} onChange={(e) => this.change(e)} />
                </Form.Group>

                <div>
                  <p className="sans-medium Gfs-087">
                    I authorize the 529 Plan into which the funds debited from my bank account will be contributed (the 'Plan') and Ascensus College Savings Recordkeeping Services, LLC, or its affiliates ("ACSR"), to initiate a debit entry to my account (the "Account") at the bank identified above ("Bank"). I authorize Bank to accept such debit to the Account without responsibility for the correctness thereof. I acknowledge that the origination of ACH transactions involving the Account must comply with U.S. law. I agree that the Plan and ACSR will not incur any loss, liability, cost or expense for acting upon.
                  </p>
                </div>

                <Form.Group>
                  <Form.Check
                    type='checkbox'
                    id='termsUnchecked'
                    checked={this.state.terms_condition}
                    value={this.state.terms_condition}
                    onChange={(e) => this.handleTermsCondition(e)}
                    onBlur={(e) => this.showValidationMessage(e)}
                    name='terms_condition'
                    label='I have read, understand and agree to the above Terms and Conditions'
                  />
                  {this.validator.message(
                    "terms_condition",
                    this.state.terms_condition,
                    "accepted", {
                    messages: {
                      accepted: "The Terms and Conditions must be accepted"
                    }
                  }
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-end mob-btn-div-column">
              <button onClick={() => this.handleSubmit()} className="G-orange-button mob-separate-v6" >
                Continue
              </button>
            </div>
          </div>
        </div>
        <Footer />

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    beneficiaryProfiles: state.five_two_nine.beneficiaryProfiles
  };
}
const mapDispatchToProps = {
  fetchBeneficiaryProfiles
}
export default connect(mapStateToProps, mapDispatchToProps)(ContributionSetUp);
