import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import AccountBanner from "../../../common/img/advice-summary.png";
import {ReactComponent as Ghost1Icon } from "../../../common/img/ghost1.svg";
import {ReactComponent as Ghost2Icon } from "../../../common/img/ghost2.svg";
import {ReactComponent as Ghost3Icon } from "../../../common/img/ghost3.svg";
import { getServicerAccounts } from '../../../actions/fiveTwoNineActions';
import { fetchEmployer } from '../../../actions/employerActions';
import { payrollDatePlusOneSub } from '../../../common/js/jsFunctions';

class AccountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerAccounts: [],
      employerData: null
    }
  }
  componentDidMount(){
    document.title = "Vault | Account Summary"
    const { getServicerAccounts, fetchEmployer } = this.props;
    getServicerAccounts();
    fetchEmployer();
  }
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.servicerAccounts!== this.props.servicerAccounts){
      const servicerAccountsData = nextProps.servicerAccounts.data;
      let servicer_accounts = servicerAccountsData.filter(function(value) {
        return value.attributes.college_savings ===true && value.attributes.pay_active ===true
      });
      if(servicer_accounts.length > 0){
        const servicer_accounts_included = nextProps.servicerAccounts.included;
        let servicers_array = servicer_accounts_included.filter(function(value) {
          return value.type ==='servicers'
        });
        let servicers_addresses_array = servicer_accounts_included.filter(function(value) {
          return value.type ==='addresses'
        });

        for(var i = 0; i < servicer_accounts.length; i++){
          const servicerId = servicer_accounts[i]['relationships']['servicer']['data']['id'];
          var itemServicer = servicers_array.find(item => item.id === servicerId);
          servicer_accounts[i]['servicer'] = itemServicer;

          if(servicer_accounts[i]['relationships']['address']['data']!== null){
            const addressId = servicer_accounts[i]['relationships']['address']['data']['id'];
            var itemAddress = servicers_addresses_array.find(item => item.id === addressId);
            servicer_accounts[i]['address'] = itemAddress;
          }
        }
        this.setState({
          servicerAccounts:servicer_accounts
        })
      }
    }
    if (nextProps.employerData !== this.props.employerData) {
      this.setState({
        employerData: nextProps.employerData
      })
    }
  }
  render() {
    const { servicerAccounts, employerData } = this.state;
    return (
      <>
      <Header {...this.props} />
      <div className="container-content">
        <div className="container-custom pb-75">
          <section className="box-header">
            <div className="align-center heading">Account Summary</div>
          </section>
          <div className="background"></div>
          <div className="bg-white box-shadow">
            <div className="top-img">
              <img src={ AccountBanner } alt="" className="img-fluid" />
            </div>
            <h2 className="theme-heading">Your monthly contribution is confirmed.</h2>
            {
              servicerAccounts.length > 0 ?
              servicerAccounts.map(function(data, i){
                if (data.attributes.pay_active===true){
                  let account_number = data.attributes.account_number;
                  let account_number_short = account_number.slice(account_number.length - 4);
                  return(
                    <div key={i}>
                      <div className="contribution">
                        <div className="icon_section third_row">
                          <div className="first-30_icon">
                            <Ghost1Icon />
                            <p className="tittle-details">
                              You'll receive <span className="color_purpal bucksFont"><span id="ember56" className="text-theme">${ data.attributes.match_payment }</span></span> from { localStorage.getItem("employerName") }
                            </p>
                          </div>
                          <div className="second-30_icon">
                            <Ghost2Icon />
                            <p className="tittle-details">
                              Your next payment will be on <span className="text-theme">{ payrollDatePlusOneSub(employerData) }</span> to the account <span className="highlight-text-color">ending in ...{ account_number_short }</span>
                            </p>
                          </div>
                          <div className="third-30_icon">
                            <Ghost3Icon />
                            <p className="tittle-details">
                              Payment will be sent to
                              <br/>
                              { data.servicer.attributes.name }:
                              <br/>
                              { data.address.attributes.address }
                            </p>
                          </div>
                        </div>
                        <div className="edit-btn-div text-center">
                          <a href={"/pay/"+data.id+"/edit"}>
                            <button className="c-button c-button-hollow c-button-primary c-button-wide">
                              Edit
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })
              : null
            }
          </div>
        </div>
      </div>
      <Footer />
      </>
    )
  }
}

AccountSummary.propTypes = {
  getServicerAccounts: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  servicerAccounts: state.five_two_nine.getServicerAccountsData,
  employerData: state.employer_array.employerData,
  errors: state.errors
});
export default connect(mapStateToProps,{ getServicerAccounts, fetchEmployer } )(AccountSummary);
