import {
  VERIFY_TOKEN,
  RESET_PASSWORD, 
  AUTHENTICATION_PASSWORD,
  RESET_PASSWORD_EMAIL,
  REGISTRATE_USER,
  LOGIN_USER,
  FETCH_INVITATION_DATA, 
  GET_ERRORS,
  FETCH_IMPERSONATION,
  POST_IMPERSONATION,
  INVALIDATE_SESSION, 
  GET_COMPANY_LANDING_DATA,
  POST_COMPANY_LANDING_DATA,
  FETCH_EMPLOYER_METADATA,
  FETCH_PARTNER_DETAILS,
  CONFIRM_SAML_EMAIL,
  RESEND_SAML_EMAIL
} from './types';

import { apiAction } from './apiActions';
import config from '../config';


export const fetchInvitationData = (token) => {
  return apiAction({
   url: config.apiBaseUrl + "authentication/registrations/fetch_invitation_data?invitation_token="+token,
   onSuccess: setfetchInvitationData,
   callback : '',
   onFailure: errorfetchInvitationData,
   label: FETCH_INVITATION_DATA
   });
}
 
function setfetchInvitationData(data) {
  return {
    type: FETCH_INVITATION_DATA,
    payload: data
  };
}
function errorfetchInvitationData(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}
export const loginUser = (requestData) => {
  return apiAction({
   url: config.apiBaseUrl + "authentication/sessions",
   method: "POST",
   data: requestData,
   onSuccess: setLoginUser,
   callback : '',
   onFailure: errorLoginUser,
   label: LOGIN_USER
 });
}
function setLoginUser(data) {
  return {
   type: LOGIN_USER,
   payload: data
  };
}
function errorLoginUser(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const invalidateSession = () => {
  return apiAction({
   url: config.apiBaseUrl + "authentication/sessions",
   method: "DELETE",
   callback : '',
   label: INVALIDATE_SESSION
 });
}

export const registrateUser = (requestData) => {
  return apiAction({
   url: config.apiBaseUrl + "authentication/registrations/accept",
   method: "POST",
   data: requestData,
   onSuccess: setRegistrateUser,
   callback : '',
   onFailure: errorRegistrateUser,
   label: REGISTRATE_USER
 });
}
function setRegistrateUser(data) {
  return {
   type: REGISTRATE_USER,
   payload: data
  };
}
function errorRegistrateUser(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}
export const resetPasswordEmail = (requestData) => {
  return apiAction({
   url: config.apiBaseUrl + "authentication/passwords",
   method: "POST",
   data: requestData,
   onSuccess: setResetPasswordEmail,
   callback : '',
   onFailure: errorResetPasswordEmail,
   label: RESET_PASSWORD_EMAIL
 });
}
function setResetPasswordEmail(data) {
  return {
   type: RESET_PASSWORD_EMAIL,
   payload: data
  };
}
function errorResetPasswordEmail(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}
export const authenticationPassword = (token) => {
  return apiAction({
   url: config.apiBaseUrl + "authentication/passwords/"+token,
   onSuccess: setAuthenticationPassword,
   callback : '',
   onFailure: errorAuthenticationPassword,
   label: AUTHENTICATION_PASSWORD
   });
}
 
function setAuthenticationPassword(data) {
  return {
    type: AUTHENTICATION_PASSWORD,
    payload: data
  };
}
function errorAuthenticationPassword(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}
export const resetPassword = (requestData,token) => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/passwords/"+token,
    method: "PUT",
    data: requestData,
    onSuccess: setResetPassword,
    callback : '',
    onFailure: errorResetPassword,
    label: RESET_PASSWORD
 });
}
 
function setResetPassword(data) {
  return {
    type: RESET_PASSWORD,
    payload: data
  };
}
function errorResetPassword(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}
export const verifyToken = (tokenRequest) => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/verify",
    method: "POST",
    data: tokenRequest,
    onSuccess: setVerifyToken,
    callback : '',
    onFailure: errorVerifyToken,
    label: VERIFY_TOKEN
 });
}
 
function setVerifyToken(data) {
  return {
    type: VERIFY_TOKEN,
    payload: data.data
  };
}
function errorVerifyToken(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const fetchImpersonation = (token) => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/impersonations/" + token,
    onSuccess: setFetchImpersonation,
    callback: '',
    onFailure: errorFetchImpersonation,
    label: FETCH_IMPERSONATION
  });
}
function setFetchImpersonation(data) {
  return {
    type: FETCH_IMPERSONATION,
    payload: data
  };
}
function errorFetchImpersonation(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const postImpersonation = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/impersonations",
    method: "POST",
    data: requestData,
    onSuccess: setPostImpersonation,
    callback: '',
    onFailure: errorPostImpersonation,
    label: POST_IMPERSONATION
  });
}
function setPostImpersonation(data) {
  return {
    type: POST_IMPERSONATION,
    payload: data
  };
}
function errorPostImpersonation(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const getCompanyLandingData = (token) => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/registrations/employer_info?landing_page_slug=" + token,
    onSuccess: setCompanyLandingData,
    callback: '',
    onFailure: errorCompanyLandingData,
    label: GET_COMPANY_LANDING_DATA
  });
}
function setCompanyLandingData(data) {
  return {
    type: GET_COMPANY_LANDING_DATA,
    payload: data
  };
}
function errorCompanyLandingData(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const postCompanyLandingData = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "authentication/registrations/invite_employee",
    method: "POST",
    data: requestData,
    onSuccess: setPostCompanyLandingData,
    callback: '',
    onFailure: errorPostCompanyLandingData,
    label: POST_COMPANY_LANDING_DATA
  });
}
function setPostCompanyLandingData(data) {
  return {
    type: POST_COMPANY_LANDING_DATA,
    payload: data
  };
}
function errorPostCompanyLandingData(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const fetchEmployerMetadata = (requestData) => {
  const path = new URL(String(config.apiBaseUrl));
  
  return apiAction({
    url: "https://" + path.hostname + "/employer_info",
    data: requestData,
    onSuccess: setFetchEmployerMetadata,
    callback: '',
    onFailure: errorFetchEmployerMetadata,
    label: FETCH_EMPLOYER_METADATA
  });
}
function setFetchEmployerMetadata(data) {
  return {
    type: FETCH_EMPLOYER_METADATA,
    payload: data
  };
}
function errorFetchEmployerMetadata(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const fetchPartnerDetails = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "partner",
    data: requestData,
    onSuccess: setFetchPartnerDetails,
    callback: '',
    onFailure: errorFetchPartnerDetails,
    label: FETCH_PARTNER_DETAILS
  });
}
function setFetchPartnerDetails(data) {
  return {
    type: FETCH_PARTNER_DETAILS,
    payload: data
  };
}
function errorFetchPartnerDetails(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}

export const confirmSamlEmail = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "confirm",
    method: "POST",
    data: requestData,
    onSuccess: setConfirmSamlEmail,
    callback: '',
    onFailure: errorConfirmSamlEmail,
    label: CONFIRM_SAML_EMAIL
  });
}
function setConfirmSamlEmail(data) {
  return {
    type: CONFIRM_SAML_EMAIL,
    payload: data
  };
}
function errorConfirmSamlEmail(errors) {
  return {
    type: GET_ERRORS,
    payload: errors
  };
}

export const resendSamlEmail = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "resend_confirmation_email",
    method: "POST",
    data: requestData,
    onSuccess: setResendSamlEmail,
    callback: '',
    onFailure: errorResendSamlEmail,
    label: RESEND_SAML_EMAIL
  });
}
function setResendSamlEmail(data) {
  return {
    type: RESEND_SAML_EMAIL,
    payload: data
  };
}
function errorResendSamlEmail(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.response.data
  };
}