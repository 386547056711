import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { fetchCustomer } from '../../../actions/customerActions';
import { postServicerAccounts, postEditServicerAccounts } from '../../../actions/fiveTwoNineActions';
import { numberFormat } from '../../../common/js/jsFunctions';
class ProfileDeductions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: "",
      requestData: this.props.location.requestData,
      account_number: "",
      servicer_accounts: [],
      errors: null
    }
  }
  componentDidMount() {
    document.title = "Vault | Account Summary"
    const { fetchCustomer } = this.props;
    const { requestData } = this.state;

    fetchCustomer();
    if (requestData === undefined && this.props.match.params.saId === undefined) {
      this.props.history.push({
        pathname: '/pay/contribution'
      })
    }
    if (requestData === undefined && this.props.match.params.saId !== undefined) {
      this.props.history.push({
        pathname: '/pay/' + this.props.match.params.saId + '/edit'
      })
    }
    if (requestData !== undefined) {
      let account_number = requestData.data.attributes.account_number;
      let account_number_short = account_number.slice(account_number.length - 4);
      let beneficiary_name = requestData.data.attributes.beneficiary_name;
      this.setState({
        account_number: account_number_short,
        beneficiary_name: beneficiary_name
      })
    }

  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.customer !== this.props.customer) {
      this.setState({
        customer: nextProps.customer
      })
    }
    if (nextProps.servicer_accounts !== this.props.servicer_accounts) {
      this.props.history.push({
        pathname: '/pay/account-summary/'
      })
    }
    if (this.props.errors !== nextProps.errors) {
      //responseMessage("error", nextProps.errors.errors, "");
    }
  }
  onCancel(e) {
    if (this.props.match.params.saId === undefined) {
      this.props.history.push({
        pathname: '/pay/contribution'
      })
    } else {
      this.props.history.push({
        pathname: '/pay/' + this.props.match.params.saId + '/edit'
      })
    }
  }
  saveChanges(e) {
    const { requestData } = this.state;
    if (this.props.match.params.saId !== undefined) {
      requestData.data.attributes.pay_active = true;
      this.props.postEditServicerAccounts(requestData)
    } else {
      this.props.postServicerAccounts(requestData);
    }
  }
  render() {
    const { customer, account_number, beneficiary_name } = this.state;
    return (
      <>
        <Header {...this.props} />
        <div className="container-content">
          <div className="container-custom ">
            <div className="background"><h2 className="c-heading-4">Step 3 of 3</h2></div>
            <div className="bg-white p-2rem">
              <div>
                <h3 className="vault__content-heading">Confirm company's contribution </h3><p>Review and confirm your company's contribution amount.</p>
              </div>
              <div className="deductions-table">
                <table className="genius-table common__no-space-bottom">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th className="text-center">Contribution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="">
                        <div className="align-left">
                          <p>Contribution to <strong></strong> account ending in ...{account_number}</p>
                          <p>Beneficiary Name</p>
                        </div>
                      </td>
                      <td className="deductions-table__drag-container">
                        <div className="draggable-box ">
                          <div className="deductions-table__drag-element " >
                            <div className="text-center">
                              <div className="match-amount">
                                <span className="hide-desktop">Amount:</span>
                                <span className="align-center-mobile">  {customer ? numberFormat(customer.attributes.employer_match) : 'N/A'}</span>
                              </div>
                            </div>
                          </div>
                          <div className="deductions-table__drag-element" >
                            <div className="text-center">
                              <div className="match-amount">{beneficiary_name}</div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="justify-content-between flex mt-30">
              <button onClick={this.onCancel.bind(this)} className="c-button c-button-hollow c-button-primary c-button-wide flex-xs-100 small-margin-bottom-mobile" >
                CANCEL
              </button>
              <button onClick={this.saveChanges.bind(this)} className="c-button c-button-secondary c-button-wide flex-xs-100"  >
                SUBMIT &amp; ENROLL
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

ProfileDeductions.propTypes = {
  fetchCustomer: PropTypes.func.isRequired,
  postServicerAccounts: PropTypes.func.isRequired,
  postEditServicerAccounts: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  customer: state.customer_array.customerData,
  servicer_accounts: state.five_two_nine.servicerAccountsData,
  errors: state.errors
})
export default connect(mapStateToProps, { fetchCustomer, postServicerAccounts, postEditServicerAccounts })(ProfileDeductions);