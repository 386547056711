import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
// import capitalize from 'lodash/capitalize';

import { getTotalPaidForStatement } from "../match";
import StatementTransactionsTable from "./StatementTransactionsTable";
import { Eye, XLg } from 'react-bootstrap-icons';
import FinicityTransactionsTable from './FinicityTransactionsTable';


const StatementsDropdown = (props) => {
  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [counterExpanded, setCounterExpanded] = useState(0);

  const transactions = props.transactions;
  // const statements = getStatements(props.statements); // todo important
  const statements = props.statements
  const approvedTransaction = transactions.filter((e) => {
    return e.attributes.approved === true
  })
  return (
    <>

      {
        transactions.length === 0 && statements.length === 0 ?
          transactions.length === 0 ?
            statements.length === 0 ?
              props.servicerAccount.finicity_active ?
                props.servicerAccount.is_finicity_linked ?
                  <>Your account has been linked! We'll import your transactions shortly. Please check back later</>
                  :
                  <>Link your account to see your transactions</>
                :
                <>Upload a statement to see your transactions</>
              :
              <>We are currently processing your transactions. Please check back later</>
            :
            statements.length === 0 &&
            <>Upload a statement to see your transactions</>
          :
          (
            <>
              <span className='G-cursor-pointer' onClick={() => setShowTransactionTable(!showTransactionTable)} >
                <Eye className='Gt-pink-8 mb-1 mr-2' size={20} />
                View
              </span>
             
            </>
          
          )
     
      }

      <Modal size="xl" show={showTransactionTable} animation={false} backdrop="static" keyboard={false} scrollable={true}>
        <Modal.Body className='custom-modal-body'>
          <div className="d-flex">
            <>
              <div className='mr-3'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M32.5 36.25H20V38.75H32.5V36.25ZM40 26.25H20V28.75H40V26.25ZM20 33.75H40V31.25H20V33.75ZM20 21.25V23.75H40V21.25H20Z" fill="var(--icon-color)" />
                </svg>
              </div>
            </>
            <div className='w-100'>
              <div className="d-flex">
                <h4 className='py-3'>View Transactions</h4>
                <div className="ml-3">
                  <button
                    onClick={() => { setShowTransactionTable(false); setCounterExpanded(0) }} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg />
                  </button>
                </div>
              </div>
              {/* body */}
              <div>


                <FinicityTransactionsTable
                  servicerAccount={props.servicerAccount}
                  customer={props.customer}
                  transactions={transactions}
                  setCounterExpanded={setCounterExpanded}
                  counterExpanded={counterExpanded}
                />

                {statements.length > 0 && statements.map((statement, index) => {
                  // const status = capitalize(statement.status);

                  const statementId = statement.statement_id;
                  const totalPaidForStatement = getTotalPaidForStatement(approvedTransaction, statementId);
                  const fileName = statement.file_name;

                  return (
                    <React.Fragment key={index}>
                      <StatementTransactionsTable
                        statement={statement}
                        transactions={transactions}
                        customer={props.customer}
                        totalPaidForStatement={totalPaidForStatement}
                        fileName={fileName}
                        setShowTransactionTable={setShowTransactionTable}
                        setCounterExpanded={setCounterExpanded}
                        counterExpanded={counterExpanded}
                        uploadedStatements={props.uploadedStatements}
                      />
                    </React.Fragment>

                  )
                })}


              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  )
}
export default StatementsDropdown;