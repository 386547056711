import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { fetchCustomer, updateCustomer } from '../../../actions/customerActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import WizardSteps from './WizardSteps';
import { Form } from 'react-bootstrap';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import Select from 'react-select';
import GeneralAgreement from '../../TermsAndConditions/GeneralAgreement/GeneralAgreement';
import EsignDisclosure from '../../TermsAndConditions/EsignDisclosure/EsignDisclosure';
import ReactTooltip from 'react-tooltip';
import { QuestionCircleFill } from 'react-bootstrap-icons';


class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      state: "",
      married: "",
      filing_jointly: "",
      agi_cents: "",
      spouse_agi_cents: "",
      spouse_federal_loan_debt_cents: "",
      family_size: "",
      credit_score: "",
      pslf_eligible: "",
      tlf_eligible: "",
      showGeneralTerms: true,
      showEsignTerms: false,
      validationError: {
        agi_cents: false,
        spouse_agi_cents: false,
        spouse_federal_loan_debt_cents: false,
        family_size: false,
      },
      stepNumber: 1,
      states: [
        {
          'name': 'Alabama',
          'value': 'AL'
        },
        {
          'name': 'Alaska',
          'value': 'AK'
        },
        {
          'name': 'Arizona',
          'value': 'AZ'
        },
        {
          'name': 'Arkansas',
          'value': 'AR'
        },
        {
          'name': 'California',
          'value': 'CA'
        },
        {
          'name': 'Colorado',
          'value': 'CO'
        },
        {
          'name': 'Connecticut',
          'value': 'CT'
        },
        {
          'name': 'Delaware',
          'value': 'DE'
        },
        {
          'name': 'District Of Columbia',
          'value': 'DC'
        },
        {
          'name': 'Florida',
          'value': 'FL'
        },
        {
          'name': 'Georgia',
          'value': 'GA'
        },
        {
          'name': 'Hawaii',
          'value': 'HI'
        },
        {
          'name': 'Idaho',
          'value': 'ID'
        },
        {
          'name': 'Illinois',
          'value': 'IL'
        },
        {
          'name': 'Indiana',
          'value': 'IN'
        },
        {
          'name': 'Iowa',
          'value': 'IA'
        },
        {
          'name': 'Kansas',
          'value': 'KS'
        },
        {
          'name': 'Kentucky',
          'value': 'KY'
        },
        {
          'name': 'Louisiana',
          'value': 'LA'
        },
        {
          'name': 'Maine',
          'value': 'ME'
        },
        {
          'name': 'Maryland',
          'value': 'MD'
        },
        {
          'name': 'Massachusetts',
          'value': 'MA'
        },
        {
          'name': 'Michigan',
          'value': 'MI'
        },
        {
          'name': 'Minnesota',
          'value': 'MN'
        },
        {
          'name': 'Mississippi',
          'value': 'MS'
        },
        {
          'name': 'Missouri',
          'value': 'MO'
        },
        {
          'name': 'Montana',
          'value': 'MT'
        },
        {
          'name': 'Nebraska',
          'value': 'NE'
        },
        {
          'name': 'Nevada',
          'value': 'NV'
        },
        {
          'name': 'New Hampshire',
          'value': 'NH'
        },
        {
          'name': 'New Jersey',
          'value': 'NJ'
        },
        {
          'name': 'New Mexico',
          'value': 'NM'
        },
        {
          'name': 'New York',
          'value': 'NY'
        },
        {
          'name': 'North Carolina',
          'value': 'NC'
        },
        {
          'name': 'North Dakota',
          'value': 'ND'
        },
        {
          'name': 'Ohio',
          'value': 'OH'
        },
        {
          'name': 'Oklahoma',
          'value': 'OK'
        },
        {
          'name': 'Oregon',
          'value': 'OR'
        },
        {
          'name': 'Pennsylvania',
          'value': 'PA'
        },
        {
          'name': 'Rhode Island',
          'value': 'RI'
        },
        {
          'name': 'South Carolina',
          'value': 'SC'
        },
        {
          'name': 'South Dakota',
          'value': 'SD'
        },
        {
          'name': 'Tennessee',
          'value': 'TN'
        },
        {
          'name': 'Texas',
          'value': 'TX'
        },
        {
          'name': 'Utah',
          'value': 'UT'
        },
        {
          'name': 'Vermont',
          'value': 'VT'
        },
        {
          'name': 'Virginia',
          'value': 'VA'
        },
        {
          'name': 'Washington',
          'value': 'WA'
        },
        {
          'name': 'West Virginia',
          'value': 'WV'
        },
        {
          'name': 'Wisconsin',
          'value': 'WI'
        },
        {
          'name': 'Wyoming',
          'value': 'WY'
        }
      ],
      creditScoreData: [
        {
          name: '< 660',
          value: 659
        },
        {
          name: '660 - 719',
          value: 719
        },
        {
          name: '720 - 779',
          value: 779
        },
        {
          name: '780+',
          value: 780
        }
      ]
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
    this.showValidationMessage = this.showValidationMessage.bind(this);
  }
  componentDidMount() {
    document.title = "Vault Advisor"
    const { fetchCustomer } = this.props;
    fetchCustomer();
    intercomTrackEvent(eventNames.visitedAdvisorAboutMe);
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerData !== prevProps.customerData) {
      // const any_advisor_service_agreement = this.props.customerData.attributes.any_advisor_service_agreement;

      this.setState({
        customer: this.props.customerData,
        state: this.props.customerData.attributes.state ? this.props.customerData.attributes.state : "",
        married: !!this.props.customerData.attributes.married,
        filing_jointly: !!this.props.customerData.attributes.filing_jointly,
        agi_cents: this.props.customerData.attributes.agi_cents ? this.props.customerData.attributes.agi_cents / 100 : 0,
        spouse_agi_cents: this.props.customerData.attributes.spouse_agi_cents ? this.props.customerData.attributes.spouse_agi_cents / 100 : 0,
        spouse_federal_loan_debt_cents: this.props.customerData.attributes.spouse_agi_cents ? this.props.customerData.attributes.spouse_federal_loan_debt_cents / 100 : 0,
        family_size: this.props.customerData.attributes.family_size ? this.props.customerData.attributes.family_size : '',
        credit_score: this.props.customerData.attributes.credit_score ? this.props.customerData.attributes.credit_score : "",
        pslf_eligible: !!this.props.customerData.attributes.pslf_eligible,
        tlf_eligible: !!this.props.customerData.attributes.tlf_eligible
      })
    }

    if (this.props.updateCustomerResponse !== prevProps.updateCustomerResponse) {
      intercomTrackEvent(eventNames.submittedAdvisorAboutMe);
      localStorage.setItem("hasProfile", true);
      this.props.history.push({
        pathname: '/welcome/nslds'
      })
    }
  }

  handleStateChange = event => {
    const { customer } = this.state;
    if (customer.attributes) customer.attributes.state = event.value;

    this.setState({
      state: event.value,
      customer: customer
    })
  }
  setMarried = (marriedVal, event) => {
    const customer = this.state.customer;
    if (customer?.attributes) customer.attributes.married = marriedVal;
    if (customer?.attributes && marriedVal === false) {
      customer.attributes.spouse_agi_cents = 0;
      customer.attributes.spouse_federal_loan_debt_cents = 0;
      customer.attributes.filing_jointly = false
    }
    this.setState({
      married: marriedVal,
      customer: customer
    })
  }
  setFilingJoint = (filingJointly, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.filing_jointly = filingJointly;

    this.setState({
      filing_jointly: filingJointly,
      customer: customer
    })
  }
  handleAgiCentsChange = (values) => {
    const customer = this.state.customer;

    const value = values.value;
    const floatValue = values.floatValue;
    if (customer?.attributes && floatValue > 0) {
      const agiCents = floatValue * 100

      customer.attributes.agi_cents = agiCents;
      this.setState({
        agi_cents: value,
        customer: customer
      })
    } else {
      this.setState({
        agi_cents: ""
      })
    }
  }
  handleSpouseAgiCentsChange = (values) => {
    const customer = this.state.customer;
    const value = values.value;
    const floatValue = values.floatValue;
    const spouseAgiCents = floatValue * 100

    if (customer.attributes) customer.attributes.spouse_agi_cents = spouseAgiCents;
    this.setState({
      spouse_agi_cents: value,
      customer: customer
    })
  }
  handleSpouseFederalLoanDebtCentsChange = (values) => {
    const customer = this.state.customer;
    const value = values.value;
    const floatValue = values.floatValue;
    const spouseFederalLoanDebtCents = floatValue * 100

    if (customer.attributes) customer.attributes.spouse_federal_loan_debt_cents = spouseFederalLoanDebtCents;
    this.setState({
      spouse_federal_loan_debt_cents: value,
      customer: customer
    })
  }
  handleFamilySizeChange = (event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.family_size = event.target.value;
    if (event.target.value > 0) {
      this.setState({
        family_size: event.target.value,
        customer: customer
      })
    } else {
      this.setState({
        family_size: ""
      })
    }
  }
  handleCreditScoreChange = (event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.credit_score = event.value;
    this.setState({
      credit_score: event.value,
      customer: customer
    })
  }
  setPslfEligible = (pslfEligible, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.pslf_eligible = pslfEligible;

    this.setState({
      pslf_eligible: pslfEligible,
      customer: customer
    })
  }
  setTlfEligible = (tlfEligible, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.tlf_eligible = tlfEligible;
    this.setState({
      tlf_eligible: tlfEligible,
      customer: customer
    })
  }
  handleSubmit = (e) => {
    this.setMarried(this.state.married)
    this.setTlfEligible(this.state.tlf_eligible)
    this.setPslfEligible(this.state.pslf_eligible)
    this.setFilingJoint(this.state.filing_jointly)
    if (this.validator.allValid()) {
      const jsonRequest = {
        "data": this.state.customer
      }
      this.props.updateCustomer(jsonRequest);
    } else {
      this.validator.showMessages();
    }
  }
  handleCancel = (e) => {
    this.props.history.push({
      pathname: '/intro/advisor'
    })
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  render() {
    const {
      states,
      creditScoreData,
      married,
      filing_jointly,
      tlf_eligible,
      pslf_eligible,
      showGeneralTerms,
      showEsignTerms
    } = this.state;

    const defaultSelectedState = states.filter((x) => x.value === this.state.state)
    const defaultSelectedCreditScore = creditScoreData.filter((x) => x.value === this.state.credit_score)
    return (

      <>
        <Header hideNotificationAndProfileDropdown={true} />
        {showGeneralTerms &&
          <GeneralAgreement showCloseButton={false} onAcceptButtonClickSuccess={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} alreadyAcceptedRoute={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} />
        }
        {showEsignTerms &&
          <EsignDisclosure showCloseButton={false} />
        }

        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <WizardSteps stepNumber={this.state.stepNumber} />
            <div className=" p-4 Gborder-05 bg-white p-4 G-grid11-2 my-3 rounded-lg">
              <div>
                <div className="pinkCircularDiv mb-3">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M30 22.375C31.45 22.375 32.625 23.55 32.625 25C32.625 26.45 31.45 27.625 30 27.625C28.55 27.625 27.375 26.45 27.375 25C27.375 23.55 28.55 22.375 30 22.375ZM30 33.625C33.7125 33.625 37.625 35.45 37.625 36.25V37.625H22.375V36.25C22.375 35.45 26.2875 33.625 30 33.625ZM30 20C27.2375 20 25 22.2375 25 25C25 27.7625 27.2375 30 30 30C32.7625 30 35 27.7625 35 25C35 22.2375 32.7625 20 30 20ZM30 31.25C26.6625 31.25 20 32.925 20 36.25V40H40V36.25C40 32.925 33.3375 31.25 30 31.25Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h2>Your Profile</h2>
                <p className="sans-medium">
                  Tell us about yourself and your work to help us connect you with the best repayment option.
                </p>
              </div>
              <div>
                <h3 className="Gt-slate-5 sans-semibold Gfs-125">The Details</h3>
                <Form.Group>
                  <Form.Label>Where do you live?</Form.Label>
                  <Select
                    className="basic-single"
                    classNamePrefix="reactSelect"
                    onChange={this.handleStateChange.bind(this)}
                    onBlur={(e) => this.showValidationMessage(e)}
                    isSearchable={true}
                    name="state"
                    placeholder="Select or search state"
                    value={defaultSelectedState.length > 0 ? { label: defaultSelectedState?.[0]?.name, value: defaultSelectedState?.[0]?.value } : null}
                    options={states ? states.map(item => ({ label: item.name, value: item.value })) : null}
                  />
                  <ValidatorMessage validationType='required' validator={this.validator} fieldName='state' fieldValue={this.state.state} message="* State can't be blank" />
                </Form.Group>
                <div className="my-4">
                  <div>
                    <h4 className="Gt-slate-5 Gfs-1">Are you single or married?</h4>
                  </div>
                  <div className='d-flex flex-wrap'>
                    <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (!married ? ' Gb-pink-8' : '')} onClick={this.setMarried.bind(this, false)}>
                      <p className={"mb-0" + (!married ? ' text-white' : ' Gt-pink-8')}>
                        SINGLE
                      </p>
                    </div>

                    <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (married ? ' Gb-pink-8' : '')} onClick={this.setMarried.bind(this, true)}>
                      <p className={"mb-0" + (married ? ' text-white' : ' Gt-pink-8')}>
                        MARRIED
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  {
                    this.validator.message(
                      "married",
                      this.state.married,
                      "required", {
                      messages: {
                        required: "* Married can't be blank"
                      }
                    }
                    )
                  }
                </div>
                {married &&
                  <>
                    <div className="my-4">
                      <div>
                        <h4 className="Gt-slate-5 Gfs-1">Do you and your spouse file a joint tax return?</h4>
                      </div>
                      <div className='d-flex flex-wrap'>
                        <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (filing_jointly ? ' Gb-pink-8' : '')} onClick={this.setFilingJoint.bind(this, true)}>
                          <p className={"mb-0" + (filing_jointly ? ' text-white' : ' Gt-pink-8')}>
                            YES
                          </p>
                        </div>

                        <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (!filing_jointly ? ' Gb-pink-8' : '')} onClick={this.setFilingJoint.bind(this, false)}>
                          <p className={"mb-0" + (!filing_jointly ? ' text-white' : ' Gt-pink-8')}>
                            NO
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {
                        this.validator.message(
                          "filing_jointly",
                          this.state.filing_jointly,
                          "required", {
                          messages: {
                            required: "* Filing Jointly can't be blank"
                          }
                        }
                        )
                      }
                    </div>
                  </>
                }
                <Form.Group className='mb-4'>
                  <Form.Label>
                    How much did you make as reported on your last tax return (also known as your Adjusted Gross Income)?
                    <QuestionCircleFill className='ml-2 mb-1' size={18} data-for="tooltipForTaxReturn" data-tip role='button' />
                  </Form.Label>
                  <ReactTooltip id="tooltipForTaxReturn" place="top" effect="solid" multiline>
                    You can find your AGI by looking at a copy of your last tax return.
                    <br />
                    Depending on the form, AGI can be found on the following number:
                    <br />
                    Form 1040 - Line 11
                    <br />
                    Form 1040A - Line 21
                    <br />
                    Form 1040EZ – Line 4
                    <br />
                    Form 1040NR – Line 11
                    <br />
                    TIP: If you don’t want to go through your taxes, an estimate of your total yearly income will do!
                  </ReactTooltip>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={this.state.agi_cents}
                    prefix="$"
                    decimalSeparator="."
                    displayType="input"
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    className="form-control"
                    id="agi_cents"
                    name="agi_cents"
                    onBlur={e => this.showValidationMessage(e)}
                    onValueChange={(values) => { this.handleAgiCentsChange(values) }}
                  />
                  <ValidatorMessage validator={this.validator} fieldName='agi_cents' fieldValue={this.state.agi_cents} message="* Adjusted Gross income must be greater than zero" />
                </Form.Group>
                {married &&
                  <>
                    <Form.Group className='mb-4'>
                      <Form.Label className="">How much did your spouse make on their last tax return (also known as their Adjusted Gross Income)?</Form.Label>
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={this.state.spouse_agi_cents}
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        className="form-control"
                        id="spouse_agi_cents"
                        name="spouse_agi_cents"
                        onValueChange={(values) => { this.handleSpouseAgiCentsChange(values) }}
                      />
                      <ValidatorMessage validator={this.validator} fieldName='spouse_agi_cents' fieldValue={this.state.spouse_agi_cents} message="* Spouse AGI can't be blank" />
                    </Form.Group>

                    <Form.Group className='mb-4'>
                      <Form.Label className="">How much does your spouse owe in federal student loans?</Form.Label>
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={this.state.spouse_federal_loan_debt_cents}
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        className="form-control"
                        id="spouse_federal_loan_debt_cents"
                        name="spouse_federal_loan_debt_cents"
                        onValueChange={(values) => { this.handleSpouseFederalLoanDebtCentsChange(values) }}
                      />
                      <ValidatorMessage validator={this.validator} fieldName='spouse_federal_loan_debt_cents' fieldValue={this.state.spouse_federal_loan_debt_cents} message="* Spouse Total Student Loan Debt can't be blank" />
                    </Form.Group>
                  </>
                }
                <Form.Group className='mb-4'>
                  <Form.Label>
                    How many dependents are in your household? Include yourself, your spouse, children, and anyone else you claim as a dependent.
                  </Form.Label>
                  <Form.Control type="number" name='family_size' placeholder="Family size" onBlur={e => this.showValidationMessage(e)}
                    value={this.state.family_size}
                    onChange={this.handleFamilySizeChange.bind(this)} />
                  <ValidatorMessage validator={this.validator} fieldName='family_size' fieldValue={this.state.family_size} message="* Size of household can't be blank" />
                </Form.Group>
                <Form.Group>
                  <Form.Label> My current credit score is (optional)</Form.Label>
                  <Form.Text className="text-muted sans-medium mb-1">
                    Your credit score range will not be shared with your employer, if
                    applicable. We use your range to provide loan payoff and refinancing
                    options that you may qualify for.
                  </Form.Text>
                  <Select
                    className="basic-single"
                    classNamePrefix="reactSelect"
                    onChange={this.handleCreditScoreChange.bind(this)}
                    isSearchable={true}
                    name="credit_score"
                    placeholder="Select Credit Score"
                    value={defaultSelectedCreditScore.length > 0 ? { label: defaultSelectedCreditScore?.[0]?.name, value: defaultSelectedCreditScore?.[0]?.value } : null}
                    options={creditScoreData ? creditScoreData.map(item => ({ label: item.name, value: item.value })) : null}
                  />
                </Form.Group>
                <h3 className="Gt-slate-5 sans-semibold Gfs-125">About your work</h3>
                <Form.Group>
                  <Form.Label> Do you work for a 501(c)(3) non-profit or government organization? </Form.Label>
                  <Form.Text className="text-muted sans-medium mb-1">
                    You could be eligible for loan forgiveness. Working at a non-profit or government employer is one of the eligibility requirements for Public Service Loan Forgiveness (PSLF). PSLF offers forgiveness on the remaining balance on Direct Loans after you have made 120 qualifying monthly payments under a qualifying repayment plan while working full-time for a qualifying repayment plan while working full-time for a qualifying employer. Working at a non-profit, however, does not guarantee forgiveness.
                  </Form.Text>
                </Form.Group>
                <div className="my-1">
                  <div className='d-flex flex-wrap'>
                    <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (!pslf_eligible ? ' Gb-pink-8' : '')} onClick={this.setPslfEligible.bind(this, false)}>
                      <p className={"mb-0" + (!pslf_eligible ? ' text-white' : ' Gt-pink-8')}>
                        NO
                      </p>
                    </div>

                    <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (pslf_eligible ? ' Gb-pink-8' : '')} onClick={this.setPslfEligible.bind(this, true)}>
                      <p className={"mb-0" + (pslf_eligible ? ' text-white' : ' Gt-pink-8')}>
                        YES
                      </p>
                    </div>
                  </div>
                </div>
                {this.state.pslf_eligible ?
                  <Form.Group>
                    <Form.Text className="text-muted sans-medium mb-1">
                      Once you click update, you will receive an email with additional details about the loan
                      forgiveness program.
                    </Form.Text>
                  </Form.Group>
                  : null}


                <Form.Group className='mb-0 mt-4'>
                  <Form.Label> Do you teach at a Title I or non-profit school? </Form.Label>
                </Form.Group>
                <div className="my-1">
                  <div className='d-flex flex-wrap'>
                    <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (!tlf_eligible ? ' Gb-pink-8' : '')} onClick={this.setTlfEligible.bind(this, false)}>
                      <p className={"mb-0" + (!tlf_eligible ? ' text-white' : ' Gt-pink-8')}>
                        NO
                      </p>
                    </div>

                    <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (tlf_eligible ? ' Gb-pink-8' : '')} onClick={this.setTlfEligible.bind(this, true)}>
                      <p className={"mb-0" + (tlf_eligible ? ' text-white' : ' Gt-pink-8')}>
                        YES
                      </p>
                    </div>
                  </div>
                </div>
                {this.state.tlf_eligible ?
                  <Form.Group>
                    <Form.Text className="text-muted sans-medium mb-1">
                      Once you click update, you will receive an email with additional details about the loan
                      forgiveness program.
                    </Form.Text>
                  </Form.Group>
                  : null}
              </div>

            </div>
            <div className="d-flex justify-content-end p-3 mob-btn-div-column">
              <button type="button" className="G-cancel-button ml-3 mt-2" onClick={this.handleCancel.bind(this)}>
                Go Back
              </button>
              <button type="button" className="G-orange-button ml-3 mt-2" onClick={this.handleSubmit.bind(this)}>
                Continue
              </button>
            </div>
          </div>
        </div >

        <Footer />
      </>
    )
  }
}

About.propTypes = {
  fetchCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  customerData: state.customer_array.customerData,
  updateCustomerResponse: state.customer_array.updateCustomerResponse,
  errors: state.errors
});
export default connect(mapStateToProps, { fetchCustomer, updateCustomer, })(About);
