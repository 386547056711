import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    es: {
      translations: require('./locales/es/translations.json')
    },
    extended: {
      translations: require('./locales/extended.json')
    },
    extendedgraduated: {
      translations: require('./locales/extended-graduated.json')
    },
    graduated: {
      translations: require('./locales/graduated.json')
    },
    ibr: {
      translations: require('./locales/ibr.json')
    },
    icr: {
      translations: require('./locales/icr.json')
    },
    paye: {
      translations: require('./locales/paye.json')
    },
    repaye: {
      translations: require('./locales/repaye.json')
    },
    save: {
      translations: require('./locales/save.json')
    },
    standard: {
      translations: require('./locales/standard.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es', 'extended', 'extendedgraduated', 'graduated', 'ibr', 'icr', 'paye', 'repaye', 'save', 'standard'];

export default i18n;