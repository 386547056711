import React, { Component } from 'react';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import EsignDisclosure from '../../TermsAndConditions/EsignDisclosure/EsignDisclosure';

class Esign extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = "Vault | Esign";
    }

    render() {
        return (
            <>
                <Header {...this.props} />
                <div className="container-content">
                    <div className="container-custom">
                        <EsignDisclosure onCloseButtonClick={() => this.props.history.push("/intro/plan-savings")} onAcceptButtonClickSuccess={() => this.props.history.push("/dashboard/add-account")} alreadyAcceptedRoute={() => this.props.history.push("/dashboard/add-account")} />
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Esign;