import { ACCEPT_ESIGNDISCLOSURE_TERMS, FETCH_ESIGNDISCLOSURE_TERMS } from "../actions/types";

const initialState = {
    esignDisclosureAgreementData: {},
    acceptEsignDisclosureResponse: {},
};

export default function esignDisclosureTermsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ESIGNDISCLOSURE_TERMS:
            return {
                ...state,
                esignDisclosureAgreementData: action.payload,
            };
        case ACCEPT_ESIGNDISCLOSURE_TERMS:
            return {
                ...state,
                acceptEsignDisclosureResponse: action.payload,
            };
        default:
            return state;
    }
}
