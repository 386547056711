import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { responseMessage } from "../../../utils/alert";
import CaretDownFill from "react-bootstrap-icons/dist/icons/caret-down-fill";
import CaretUpFill from "react-bootstrap-icons/dist/icons/caret-up-fill";
import { numberFormat } from "../../../common/js/jsFunctions";
import { fetchCustomer } from "../../../actions/customerActions";
import { fetchEmployer } from "../../../actions/employerActions";
import { getServicerAccounts } from "../../../actions/fiveTwoNineActions";
import S from './Payments.module.css'
import Plus from "react-bootstrap-icons/dist/icons/plus";
import Stepper from "../../shared/Stepper/Stepper";
import { finicityConnectSuccess, getFinicityConnectLink } from "../../../actions/advisorActions";
import finicityConnect from '../../../helpers/FinicityConnect';

import {
  getNeedsFinicityLink,
  servicerAccountSortInterestRateDesc,
  servicerAccountSortInterestRateAsc,
  servicerAccountSortBalanceDesc,
  servicerAccountSortBalanceAsc,
} from "../pay";

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import Breadcrumb from "../../shared/Breadcrumb/Breadcrumb";
import { paymentBreadcrumbData, paymentStepperData, StepperStage } from './Payment.constant';
import InfoCircle from "react-bootstrap-icons/dist/icons/info-circle";
import Check2 from "react-bootstrap-icons/dist/icons/check2";
import { Pencil } from "react-bootstrap-icons";
import GeneralAgreement from './../../TermsAndConditions/GeneralAgreement/GeneralAgreement';
import EsignDisclosure from './../../TermsAndConditions/EsignDisclosure/EsignDisclosure';

class PayAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerAccounts: [],
      customer: null,
      employer: null,
      fauxFinicityModalEnabled: false,
      fauxFinicityModalSelectedServicerAccountId: null,
      username: "",
      password: "",
      sortOrder: "desc",
      sortProp: "interestRate",
      loadingFinicityLink: false,
      initiatingServicerId: null,
      selectedIdForContribution: null,
      showGeneralTerms: true,
      showEsignTerms: false
    };
  }

  componentDidMount() {
    document.title = "Vault Advisor";
    const { getServicerAccounts, fetchEmployer } = this.props;
    getServicerAccounts();
    fetchEmployer();
    intercomTrackEvent(eventNames.visitedPayServicerSelection);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer,
      });
    }
    if (prevProps.employer !== this.props.employer) {
      this.setState({
        employer: this.props.employer,
      });
    }
    if (prevProps.servicerAccounts !== this.props.servicerAccounts) {
      const servicerAccountsData = this.props.servicerAccounts.data;
      let servicer_accounts = servicerAccountsData.filter(function (value) {
        return value.attributes.account_balance !== 0;
      });

      if (servicer_accounts.length > 0) {
        const servicer_accounts_included = this.props.servicerAccounts.included;
        let servicers_array = servicer_accounts_included.filter(function (
          value
        ) {
          return value.type === "servicers";
        });
        let servicers_addresses_array = servicer_accounts_included.filter(
          function (value) {
            return value.type === "addresses";
          }
        );

        for (var i = 0; i < servicer_accounts.length; i++) {
          const servicerId =
            servicer_accounts[i]["relationships"]["servicer"]["data"]["id"];
          var itemServicer = servicers_array.find(
            (item) => item.id === servicerId
          );
          servicer_accounts[i]["servicer"] = itemServicer;

          if (
            servicer_accounts[i]["relationships"]["address"]["data"] !== null
          ) {
            const addressId =
              servicer_accounts[i]["relationships"]["address"]["data"]["id"];
            var itemAddress = servicers_addresses_array.find(
              (item) => item.id === addressId
            );
            servicer_accounts[i]["address"] = itemAddress;
          }
        }
        const sorted = servicerAccountSortInterestRateDesc(servicer_accounts);

        let active_account = servicerAccountsData.filter(function (value) {
          return value.attributes.pay_active === true
        });

        let selectedIdForContribution = active_account[0]?.id;
        if (this.props?.location?.isNew || this.props?.location?.fromFinalStep) {
          selectedIdForContribution = this.props?.location?.requestData?.data?.id ?? active_account[0]?.id;
        }
        this.setState({
          servicerAccounts: sorted,
          selectedIdForContribution: selectedIdForContribution ?? null
        });
      }
    }

    if (
      prevProps.finicityConnectResponse !== this.props.finicityConnectResponse
    ) {
      this.setState({
        fauxFinicityModalEnabled: null,
      });
      responseMessage(
        "success",
        "You have successfully linked your account!",
        ""
      );
      setTimeout(
        () =>
          this.props.history.push({
            pathname:
              "/pay/profiles/payment/" +
              this.state.fauxFinicityModalSelectedServicerAccountId +
              "/edit",
          }),
        5000
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  clearFinicityState = () => {
    this.setState({
      loadingFinicityLink: false,
      initiatingServicerId: null
    })
  }

  onSubmitFinicity = (id) => {
    const { finicityConnectSuccess, getServicerAccounts } = this.props;
    const jsonRequest = {
      'servicer_account_id': id
    }
    finicityConnectSuccess(jsonRequest);
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  }

  handleSelectAccount = (needsFinicityLink, saId, e) => {
    const { employer } = this.state;

    if (needsFinicityLink) {
      if (employer.attributes.faux_finicity_enabled) {
        this.setState({
          fauxFinicityModalSelectedServicerAccountId: saId,
          fauxFinicityModalEnabled: true,
        });
      } else {
        saId && this.props.getFinicityConnectLink(saId);
        this.timerID = setTimeout(() => {
          this.setState({
            fauxFinicityModalSelectedServicerAccountId: saId,
          });
          this.props.finicityConnectLink && finicityConnect(this.props.finicityConnectLink, this.clearFinicityState, this.onSubmitFinicity, saId);
        }, 2000);

      }

    } else {
      this.props.history.push({
        pathname: "/pay/profiles/payment/" + saId + "/edit",
        fromAccounts: true
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onCancel = (e) => {
    this.props.history.push({
      pathname: "/pay/overview",
    });
  };

  onSubmitFauxFinicity = (e) => {
    const servicerAccountId =
      this.state.fauxFinicityModalSelectedServicerAccountId;
    const { finicityConnectSuccess, getServicerAccounts } = this.props;
    const jsonRequest = {
      servicer_account_id: servicerAccountId,
    };
    finicityConnectSuccess(jsonRequest);
    this.setState({
      fauxFinicityModalEnabled: false,
    });
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  };

  onCancelFauxFinicity = (e) => {
    this.setState({
      fauxFinicityModalSelectedServicerAccountId: null,
      fauxFinicityModalEnabled: null,
    });
  };

  sortBy = (sortProp, e) => {
    const { sortOrder } = this.state;
    this.setState(
      {
        sortOrder: sortOrder === "desc" ? "asc" : "desc",
        sortProp: sortProp,
      },
      () => {
        if (sortProp === "interestRate") {
          this.sortByInterestRate();
        }
        if (sortProp === "balance") {
          this.sortByBalance();
        }
      }
    );
  };

  sortByInterestRate = () => {
    const { sortOrder, servicerAccounts } = this.state;

    let sorted = [];
    if (sortOrder === "desc") {
      sorted = servicerAccountSortInterestRateDesc(servicerAccounts);
    }
    if (sortOrder === "asc") {
      sorted = servicerAccountSortInterestRateAsc(servicerAccounts);
    }
    this.setState({
      servicerAccounts: sorted,
    });
  };

  sortByBalance = () => {
    const { sortOrder, servicerAccounts } = this.state;

    let sorted = [];
    if (sortOrder === "desc") {
      sorted = servicerAccountSortBalanceDesc(servicerAccounts);
    }
    if (sortOrder === "asc") {
      sorted = servicerAccountSortBalanceAsc(servicerAccounts);
    }
    this.setState({
      servicerAccounts: sorted,
    });
  };

  continueToConfirmContribution = () => {
    const { servicerAccounts, selectedIdForContribution } = this.state;
    const filteredData = servicerAccounts.filter((x) => x.id === selectedIdForContribution)[0]

    const servicerId = filteredData?.['relationships']?.['servicer']?.['data']?.['id'];
    const addressId = filteredData?.['relationships']?.['address']?.['data'] ? filteredData?.['relationships']?.['address']?.['data']?.['id'] : "";
    const account_number = filteredData?.attributes?.account_number ? filteredData?.attributes?.account_number : "";
    const account_balance = filteredData?.attributes?.account_balance ? filteredData?.attributes?.account_balance : 0;
    const monthly_payment = filteredData?.attributes?.monthly_payment ? filteredData?.attributes?.monthly_payment : '';
    const interest_rate = filteredData?.attributes?.interest_rate ? filteredData?.attributes?.interest_rate + '%' : '';

    if (addressId === '' || account_number === '' || account_balance === 0 || (filteredData?.attributes?.monthly_payment !== 0 && monthly_payment === '') || interest_rate === '') {
      this.props.history.push({
        pathname: "/pay/profiles/payment/" + selectedIdForContribution + "/edit",
        fromAccounts: true,
        missingData: true,
      });
    } else {
      const servicerJson = {
        "servicer": {
          "data": {
            "type": "servicers",
            "id": servicerId,
            "attributes": filteredData?.servicer?.attributes
          }
        }
      }
      const addressJson = {
        "data": {
          "type": "addresses",
          "id": addressId
        }
      }
      const requestData = { data: {} };
      requestData.data['id'] = filteredData?.id;
      requestData.data['servicer_name'] = filteredData?.servicer?.attributes?.name;
      requestData.data.attributes = filteredData?.attributes;
      requestData.data['relationships'] = servicerJson;
      requestData.data.relationships['address'] = addressJson;
      this.props.history.push({
        pathname: '/pay/profiles/deductions/' + requestData?.data.id,
        requestData: requestData,
      });
    }
  }

  render() {
    const { servicerAccounts, customer, showGeneralTerms, showEsignTerms, } = this.state;
    //let paySupportEmail = "";
    if (customer) {
      //paySupportEmail = customer.attributes.pay_support_email;
    }

    return (
      <>
        {showGeneralTerms &&
          <GeneralAgreement showCloseButton={false} onAcceptButtonClickSuccess={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} alreadyAcceptedRoute={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} />
        }
        {showEsignTerms &&
          <EsignDisclosure showCloseButton={false} />
        }
        <Header {...this.props} />
        <div className="loan-srvicer-one">
          <div className="G-page-background-color">

            <div className="G-page-main-container py-5">
              <Breadcrumb list={paymentBreadcrumbData()} />
              <Stepper options={paymentStepperData(StepperStage.active)} />

              <div className={`text-right ${S.alertMarginTop}`}>
                <p className="sans-semibold">Having trouble linking your account? <span className="Gt-pink-8 sans-bold" role='button' onClick={(e) => {
                  window.location.href = "mailto:marissa@vault.co";
                  e.preventDefault();
                }}>Contact Us</span></p>
              </div>
              <div className="bg-white p-4 rounded-lg Gborder-05">
                <div className={`${S.circularDiv}`}>
                  <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7375 14.55L13.6875 12.525C15.3 11.275 16.3375 9.325 16.3375 7.125C16.3375 3.3375 13.25 0.25 9.4625 0.25C5.675 0.25 2.5875 3.3375 2.5875 7.125C2.5875 9.7875 4.1125 12.1 6.3375 13.2375V17.3125C3.65 16.7375 3.8125 16.7625 3.5125 16.7625C2.85 16.7625 2.225 17.025 1.75 17.5L0 19.275L6.3625 25.6375C6.9 26.1875 7.65 26.5 8.425 26.5H16.3C17.525 26.5 18.5625 25.625 18.7625 24.4125L19.7625 18.525C20.0375 16.9 19.225 15.3 17.7375 14.55ZM17.3 18.1125L16.3 24H8.425C8.3125 24 8.2125 23.95 8.125 23.875L3.525 19.275L8.8375 20.3875V7.125C8.8375 6.775 9.1125 6.5 9.4625 6.5C9.8125 6.5 10.0875 6.775 10.0875 7.125V14.625H12.2875L16.6125 16.7875C17.1125 17.0375 17.3875 17.575 17.3 18.1125ZM5.0875 7.125C5.0875 4.7125 7.05 2.75 9.4625 2.75C11.875 2.75 13.8375 4.7125 13.8375 7.125C13.8375 8.3125 13.3625 9.3875 12.5875 10.175V7.125C12.5875 5.4 11.1875 4 9.4625 4C7.7375 4 6.3375 5.4 6.3375 7.125V10.175C5.5625 9.3875 5.0875 8.3125 5.0875 7.125Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <div className="my-2 Gt-slate-5">
                  <h4 className="Gfs-175 sans-semibold">Select Loan Servicer</h4>
                  <p className="sans-medium">
                    Select the student loan servicer account that you would like to <br />
                    send your contributions to (we recommend selecting the <br />
                    servicer with the highest interest rate).
                  </p>
                  <Link className="Ga" to="/pay/profiles/payment/new">
                    <button className="G-orange-button"
                      onClick={() => {
                        intercomTrackEvent(
                          eventNames.clickedAddAccountFromPaySetup
                        );
                      }}

                    >
                      <Plus size={28} />{servicerAccounts.length > 0 ? 'Add an account' : 'Add one now'} </button></Link>
                </div>
                <div className="Gtable-div mt-4">
                  <table className="Gborder-05">
                    <thead>
                      <tr className={`${S.tableHeader} Gfs-075 sans-semibold`}>
                        <th className=" Gfs-075">Loan</th>
                        <th className="Gfs-075">
                          <span
                            onClick={(e) => this.sortBy("balance", e)}
                            role='button'
                          >
                            Balance
                          </span>
                          {this.state.sortProp === "balance" ? (
                            this.state.sortOrder === "desc" ? (
                              <CaretDownFill className="Gt-pink-8" />
                            ) : (
                              <CaretUpFill className="Gt-pink-8" />
                            )
                          ) : null}
                        </th>
                        <th className="Gfs-075">
                          <span
                            onClick={(e) => this.sortBy("interestRate", e)}
                            role='button'
                          >
                            weighted interest rate*&nbsp;
                          </span>
                          {this.state.sortProp === "interestRate" ? (
                            this.state.sortOrder === "desc" ? (
                              <CaretDownFill className="Gt-pink-8" />
                            ) : (
                              <CaretUpFill className="Gt-pink-8" />
                            )
                          ) : null}
                        </th>
                        <th className="Gfs-075 ">Status </th>
                        <th className={"Gfs-075 text-center " + (servicerAccounts.length === 0 ? '' : S.thPink8)}>Select For Contribution  </th>
                        <th className="Gfs-075 text-center">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servicerAccounts.length > 0
                        ? servicerAccounts.map((data, i) => {
                          const balance = data.attributes.account_balance
                            ? data.attributes.account_balance
                            : 0;
                          const interestRate = data.attributes.interest_rate
                            ? data.attributes.interest_rate
                            : 0;
                          const servicerName = data.servicer
                            ? data.servicer.attributes.friendly_name
                            : "";
                          const isFinicityLinked =
                            data.attributes.is_finicity_linked;
                          const isFinicitySynced =
                            data.attributes.is_finicity_synced;

                          const needsFinicityLink = getNeedsFinicityLink(data);

                          return (
                            <tr className={`${S.tableBody} Gfs-1`} key={i}>
                              <td>{servicerName}</td>
                              <td>{numberFormat(balance)}</td>
                              <td>{interestRate + "%"}</td>
                              <td>
                                {isFinicityLinked ? (
                                  <span className="mr-2">
                                    {isFinicitySynced ?
                                      (<span className="Gt-green-6">
                                        <Check2 size={20} /> <span>Linked</span>
                                      </span>)
                                      : (
                                        <div
                                          className="G-pointer"
                                          onClick={(e) =>
                                            this.handleSelectAccount(
                                              needsFinicityLink,
                                              data.id,
                                              e
                                            )
                                          }
                                        >
                                          <span className="Gt-orange-3">
                                            <InfoCircle size={20} /> <span>Needs Attention</span>
                                            <ReactTooltip
                                              id="needAttention"
                                              place="top"
                                              effect="solid"
                                            >
                                              You need to re-link your account in order
                                              to keep receiving this benefit
                                            </ReactTooltip>
                                          </span>
                                        </div>
                                      )}

                                  </span>
                                ) : (
                                  '-'
                                )}

                              </td>
                              <td className={"G-radio-pink text-center " + S.tdPink}>
                                <Form.Check
                                  inline
                                  aria-label={data.id}
                                  name="select-account"
                                  type='radio'
                                  className="select-account-radio-button "
                                  checked={data.id === this.state.selectedIdForContribution}
                                  onChange={() => this.setState({ selectedIdForContribution: data.id })}
                                />
                              </td>
                              <td onClick={(e) =>
                                this.handleSelectAccount(
                                  false,
                                  data.id,
                                  e
                                )
                              } className="text-center" role='button'>
                                <Pencil className="Gt-pink-8 G-cursor-pointer" />
                              </td>
                            </tr>
                          );
                        }) : <tr>
                          <td colSpan='6' className="text-center py-3 Gfs-1 sans-semibold">You currently don't have any accounts</td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-end py-4">
                <button onClick={this.onCancel.bind(this)} className="G-cancel-button mx-3">Go Back </button>
                {this.state.selectedIdForContribution &&
                  <button type="button" className="G-orange-button animate__animated animate__headShake animate__infinite	" onClick={() => this.continueToConfirmContribution()}>Continue</button>
                }
              </div>
            </div>
          </div>


        </div>
        <Modal
          show={this.state.fauxFinicityModalEnabled}
          size="lg"
          dialogClassName=""
          centered
        >
          <Modal.Body className="p-4">
            <div>

              <div className={`${S.circularDiv} mb-3`}>
                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.125 11.5H3.625V20.25H6.125V11.5ZM13.625 11.5H11.125V20.25H13.625V11.5ZM24.25 22.75H0.5V25.25H24.25V22.75ZM21.125 11.5H18.625V20.25H21.125V11.5ZM12.375 3.075L18.8875 6.5H5.8625L12.375 3.075ZM12.375 0.25L0.5 6.5V9H24.25V6.5L12.375 0.25Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <h3 className='Gfs-175 Gt-slate-5'>Bank Of Vault</h3>
            </div>
            <form>
              <div className="sans-medium">
                Enter your Bank of Vault account information.
              </div>
              <div>
                <div className="form-group">
                  <label for="usr">Username:</label>
                  <input type="text" className="form-control" name="username" id="usr" placeholder='Enter username' onChange={this.handleInputChange.bind(this)} />
                </div>
                <div className="form-group">
                  <label for="pwd">Password:</label>
                  <input type="password" className="form-control" name="password" id="pwd" placeholder='Enter password' onChange={this.handleInputChange.bind(this)} />
                </div>

              </div>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={this.onCancelFauxFinicity.bind(this)}
                  className="G-cancel-button mx-3"
                  variant="sm"
                >
                  Cancel
                </Button>
                <Button variant='sm' className="G-orange-button" onClick={this.onSubmitFauxFinicity.bind(this)} data-theme="vault" data-ember-action="" data-ember-action-340="340">
                  Next
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    servicerAccounts: state.five_two_nine.getServicerAccountsData,
    customer: state.customer_array.customerData,
    employer: state.employer_array.employerData,
    finicityConnectResponse: state.advisor.finicityConnectResponse,
    finicityConnectLink: state.advisor.finicityConnectLink?.data?.attributes?.link,
  };
};
const mapDispatchToProps = {
  getServicerAccounts,
  fetchCustomer,
  fetchEmployer,
  finicityConnectSuccess,
  getFinicityConnectLink
};
export default connect(mapStateToProps, mapDispatchToProps)(PayAccounts);
