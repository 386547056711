import React from 'react';
import { Carousel } from 'react-bootstrap';
import { VaultTuitionSvg1, VaultTuitionSvg2, VaultTuitionSvg3 } from '../RegistrationCarousel.constant';
import style from '../RegistrationCarousel.module.css'

const CarouselTuition = () => {
  return (
    <div className={'G-carousel-dark ' + style.containerMain}>
      <Carousel fade>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-orange-2 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultTuitionSvg1 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Invest in Your Education, Invest in Your Future.
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Take control of your education and your career with Vault's simple employer portal, which allows managers to view, track, approve, and fund tuition reimbursement requests all in one place.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-pink-1 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultTuitionSvg2 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                The Ultimate Education Expense Management Solution
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Achieve your career goals and secure your future with ongoing tuition payments, one-time awards, annual education allowances, and ad-hoc education payment offerings.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-blue-100 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultTuitionSvg3 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                The Post-COVID Benefits Landscape's Leading Benefit Offering
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Secure your future and achieve financial freedom with Vault Tuition Reimbursement, the leading benefit offering for post-COVID financial wellness.
              </label>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default CarouselTuition