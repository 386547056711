import Image1 from './images/Image1.png';
import Image2 from './images/Image2.png';
import Image3 from './images/Image3.png';
import Image4 from './images/Image4.png';
import Image5 from './images/Image5.png';
import Image6 from './images/Image6.png';
import Image7 from './images/Image7.png';
import Image8 from './images/Image8.png';
import Image9 from './images/Image9.png';
import Image10 from './images/Image10.png';
import Image11 from './images/Image11.png';
import Image12 from './images/Image12.png';
import Image13 from './images/Image13.png';
import Image14 from './images/Image14.png';

const imageData = [
    {
        id: 1,
        url: Image1,
        title: 'Dig Into Your Goals',
        description: 'First things first, figure out what you want to achieve with the 529 plan. Consider the education goals you have for the beneficiary (the future student). Determine the type of institution you want to save for (e.g., college, university, trade school) and estimate the potential cost of education at those institutions. Knowing your destination will help you pick the right road.'
    },
    {
        id: 2,
        url: Image2,
        title: 'Understand the Basics',
        description: 'Look up 529 plans available in your state and others. Compare their features, investment options, and any additional perks they might offer. There are two main types: prepaid tuition plans and education savings plans. The latter is more common and allows you to invest contributions in mutual funds or similar investments to grow your savings over time.'
    },
    {
        id: 3,
        url: Image3,
        title: 'Check for State Perks',
        description: "Most states offer their own 529 plans. Some offer additional benefits, such as state income tax deductions or matching grants for in-state residents. Look into whether your state provides such benefits and compare them with other plans. Don't miss out on potential benefits! These benefits can make a significant difference in your savings strategy."
    },
    {
        id: 4,
        url: Image4,
        title: 'Compare Investment Options',
        description: 'Each 529 plan offers a selection of investment options, such as age-based portfolios or individual funds. Look for a mix that suits your risk tolerance and long-term investment goals.'
    },
    {
        id: 5,
        url: Image5,
        title: 'Consider a Direct-Sold or Advisor-Sold Plan',
        description: '529 plans are available through direct-sold options (you manage the account directly) and advisor-sold options (through a financial advisor). Compare the costs and services associated with each option to determine which aligns better with your preferences.'
    },
    {
        id: 6,
        url: Image6,
        title: 'Fees, Please',
        description: 'Keep an eye out for fees – they can eat into your savings. Compare the fees associated with each plan, including administrative fees, management fees, and underlying fund expenses. Look for plans with low costs and high potential returns. Lower fees can significantly impact your overall returns over time'
    },
    {
        id: 7,
        url: Image7,
        title: 'Performance Check',
        description: "While past performance is not a guarantee of future results, it's essential to evaluate how well the plan's investments have performed historically. Compare the plan's performance against relevant benchmarks and other plans."
    },
    {
        id: 8,
        url: Image8,
        title: 'Consider Tax Implications',
        description: "Evaluate the tax advantages of each plan. While contributions to 529 plans are not federally tax-deductible, some states offer tax benefits for in-state plan contributions. Additionally, consider the tax-free growth and withdrawals for qualified education expenses at the federal level."
    },
    {
        id: 9,
        url: Image9,
        title: 'Check Contribution Limits',
        description: "Each state's 529 plan has a maximum account balance allowed, which varies from plan to plan. Take note of the contribution limits for each plan. Ensure that they align with your savings goals and financial capacity."
    },
    {
        id: 10,
        url: Image10,
        title: 'Flexibility Matters',
        description: "Life is unpredictable, so choose a plan that allows changing beneficiaries, using funds for various qualified expenses, or transferring funds to another plan without incurring penalties. Flexibility is essential in case circumstances change."
    },
    {
        id: 11,
        url: Image11,
        title: 'Reputation and Stability',
        description: "Life is unpredictable, so choose a plan that allows changing beneficiaries, using funds for various qualified expenses, or transferring funds to another plan without incurring penalties. Flexibility is essential in case circumstances change."
    },
    {
        id: 12,
        url: Image12,
        title: 'Review Historical Performance',
        description: "Past performance doesn't guarantee future success, but it's still good to peek at how a plan has done. Compare the performance against relevant benchmarks and other plans."
    },
    {
        id: 13,
        url: Image13,
        title: 'Ease of Use',
        description: "Look into the customer service reputation of the plan provider. Consider the ease of use of the plan's website, online tools, and account management features. A user-friendly website and responsive customer support can make managing your plan a breeze."
    },
    {
        id: 14,
        url: Image14,
        title: "Don't Forget the Big Picture",
        description: "While taxes and investment are essential, remember that the main goal is to save for the future education of your loved ones. Remember that you are not limited to choosing your state's plan so it's worth exploring options from other states if they offer more attractive features and benefits. However, be sure to weigh the potential advantages against any in-state benefits you might be giving up."
    },

];

export default imageData;