import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CheckCircle, XLg } from 'react-bootstrap-icons';
import ValidatorMessage from './../ValidatorMessage/ValidatorMessage';
import SimpleReactValidator from 'simple-react-validator';
import { sendEmailFeedback } from './../../../actions/advisorActions';
import { connect } from 'react-redux';
import { headerSelector } from '../../Includes/Header/Helpers/serviceSelector';


const getSourceName = () => {
  const headerName = headerSelector();
  switch (headerName) {
    case 'pay':
      return 'Pay'
    case 'match':
      return 'Match'
    case 'fiveTwoNine':
      return 'FiveTwoNine'
    case 'tuition':
      return 'Tuition'
    case 'advisorOnly':
      return 'Advisor'
    default:
      return ''
  }
}

const CustomerSupportModal = ({ showModal, setShowModal, emailFeedbackResponse, sendEmailFeedback }) => {
  const [show, setShow] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [feedback, setFeedback] = useState('');
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  useEffect(() => {
    setShow(showModal)
  }, [showModal])

  useEffect(() => {
    if (emailFeedbackResponse) {
      setShowSuccessMsg(true);
    }
  }, [emailFeedbackResponse])

  const submitFeedback = () => {
    const formValid = validator.current.allValid()
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    } else {
      const sourceName = getSourceName();
      const source = `source:${sourceName}\nabout:Customer Support form submission \n\n` + feedback;

      const feedbackJson = {
        "mail_to": "genius@vault.co",
        "feedback": source
      }
      sendEmailFeedback(feedbackJson);
    }
  }
  const closeModal = () => {
    setFeedback('');
    setShowModal(false);
    setShowSuccessMsg(false);
    validator.current.hideMessages();
    forceUpdate(2);
  }
  return (
    <div>
      <Modal size="lg" show={show} animation={false} backdrop="static" keyboard={false} scrollable={true}>
        <Modal.Body className='custom-modal-body'>
          <div className="d-flex">
            <div className='mr-3'>
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                <path d="M28.75 44.4875V39.9875C22.4875 39.6625 17.5 34.4625 17.5 28.125C17.5 21.575 22.825 16.25 29.375 16.25C35.925 16.25 41.25 21.575 41.25 28.125C41.25 34.3125 36.95 40.5375 30.5375 43.625L28.75 44.4875ZM29.375 18.75C24.2 18.75 20 22.95 20 28.125C20 33.3 24.2 37.5 29.375 37.5H31.25V40.375C35.8 37.5 38.75 32.775 38.75 28.125C38.75 22.95 34.55 18.75 29.375 18.75ZM28.125 33.125H30.625V35.625H28.125V33.125ZM30.625 31.25H28.125C28.125 27.1875 31.875 27.5 31.875 25C31.875 23.625 30.75 22.5 29.375 22.5C28 22.5 26.875 23.625 26.875 25H24.375C24.375 22.2375 26.6125 20 29.375 20C32.1375 20 34.375 22.2375 34.375 25C34.375 28.125 30.625 28.4375 30.625 31.25Z" fill="var(--icon-color)" />
              </svg>
            </div>
            <div className='w-100'>
              <div className="d-flex">
                <div>
                  <h4 className='py-3'>Need Assistance? </h4>
                  <p className='sans-medium '>
                    Reach out to our support team and we’ll help solve your queries swiftly.
                  </p>
                </div>
                <div className="ml-3">
                  <span
                    onClick={closeModal}
                    className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg />
                  </span>
                </div>
              </div>
              {/* body */}
              {showSuccessMsg ?
                <>
                  <p className='py-3 Gb-green-2 pl-3 rounded-lg sans-semibold w-100 '>
                    <CheckCircle size={24} className="Gt-green-6" />
                    &nbsp; Perfect! We have received your submission!
                  </p>
                </>
                :
                <>
                  <p className='sans-medium'>Message</p>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Mention your queries in details."
                    onChange={(e) => { setFeedback(e.target.value); validator.current.showMessages(); }}
                    value={feedback}
                    rows={4}
                  />
                  <ValidatorMessage validator={validator.current} fieldName='feedback' fieldValue={feedback} message="* Textarea can't be blank" />
                  <div className="d-flex justify-content-end mob-btn-div-column mt-4">
                    <button className="G-orange-button mob-separate-v6" onClick={submitFeedback}>
                      Submit
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </div>
  )
}

CustomerSupportModal.propTypes = {
  sendEmailFeedback: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  emailFeedbackResponse: state.advisor.emailFeedbackResponse,
})

export default connect(mapStateToProps, { sendEmailFeedback })(CustomerSupportModal)