export const States = [
    {
        name: "Alabama",
        value: "AL",
      },
      {
        name: "Alaska",
        value: "AK",
      },
      {
        name: "Arizona",
        value: "AZ",
      },
      {
        name: "Arkansas",
        value: "AR",
      },
      {
        name: "California",
        value: "CA",
      },
      {
        name: "Colorado",
        value: "CO",
      },
      {
        name: "Connecticut",
        value: "CT",
      },
      {
        name: "Delaware",
        value: "DE",
      },
      {
        name: "District Of Columbia",
        value: "DC",
      },
      {
        name: "Florida",
        value: "FL",
      },
      {
        name: "Georgia",
        value: "GA",
      },
      {
        name: "Hawaii",
        value: "HI",
      },
      {
        name: "Idaho",
        value: "ID",
      },
      {
        name: "Illinois",
        value: "IL",
      },
      {
        name: "Indiana",
        value: "IN",
      },
      {
        name: "Iowa",
        value: "IA",
      },
      {
        name: "Kansas",
        value: "KS",
      },
      {
        name: "Kentucky",
        value: "KY",
      },
      {
        name: "Louisiana",
        value: "LA",
      },
      {
        name: "Maine",
        value: "ME",
      },
      {
        name: "Maryland",
        value: "MD",
      },
      {
        name: "Massachusetts",
        value: "MA",
      },
      {
        name: "Michigan",
        value: "MI",
      },
      {
        name: "Minnesota",
        value: "MN",
      },
      {
        name: "Mississippi",
        value: "MS",
      },
      {
        name: "Missouri",
        value: "MO",
      },
      {
        name: "Montana",
        value: "MT",
      },
      {
        name: "Nebraska",
        value: "NE",
      },
      {
        name: "Nevada",
        value: "NV",
      },
      {
        name: "New Hampshire",
        value: "NH",
      },
      {
        name: "New Jersey",
        value: "NJ",
      },
      {
        name: "New Mexico",
        value: "NM",
      },
      {
        name: "New York",
        value: "NY",
      },
      {
        name: "North Carolina",
        value: "NC",
      },
      {
        name: "North Dakota",
        value: "ND",
      },
      {
        name: "Ohio",
        value: "OH",
      },
      {
        name: "Oklahoma",
        value: "OK",
      },
      {
        name: "Oregon",
        value: "OR",
      },
      {
        name: "Pennsylvania",
        value: "PA",
      },
      {
        name: "Rhode Island",
        value: "RI",
      },
      {
        name: "South Carolina",
        value: "SC",
      },
      {
        name: "South Dakota",
        value: "SD",
      },
      {
        name: "Tennessee",
        value: "TN",
      },
      {
        name: "Texas",
        value: "TX",
      },
      {
        name: "Utah",
        value: "UT",
      },
      {
        name: "Vermont",
        value: "VT",
      },
      {
        name: "Virginia",
        value: "VA",
      },
      {
        name: "Washington",
        value: "WA",
      },
      {
        name: "West Virginia",
        value: "WV",
      },
      {
        name: "Wisconsin",
        value: "WI",
      },
      {
        name: "Wyoming",
        value: "WY",
      },
]

export const returnChecked = (evt) => {
    const checkboxVal = evt.target.checked;
    let checked = false;
    if (checkboxVal === true) {
      checked = true
    }

    return checked;
}

export const returnAddressJson = (name, street_1, street_2, city, state, zip_code) => {
    const addressJson = {
        data: {
          type: "servicers",
          attributes: {
            is_fivetwonine: true,
            name: name,
            friendly_name: name,
            addresses_attributes: [
              {
                street_1: street_1,
                street_2: street_2,
                city: city,
                state: state,
                zip_code: zip_code,
              },
            ],
          },
        },
      };
    return addressJson;
}

export const returnValidationMessageStatus = (validator, event) => {
    if (validator.fieldValid(event.target.name)) {
        validator.hideMessageFor(event.target.name);
         return false;
      } else {
        validator.showMessageFor(event.target.name);
        return true;
      }
}

export const returnSelectAddress = (addressValue, requestData) => {
    const addressJson = {
        data: {
          type: addressValue.type,
          id: addressValue.id,
        },
    };
    const state = {
        requestData: requestData,
        selected: "selected",
        addressId: addressValue.id,
    }
    return {address: addressJson, addressState: state};
}