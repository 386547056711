import React from 'react'
import style from './LoginCarousel.module.css'
import { Carousel } from 'react-bootstrap';
import { Svg1, Svg2, Svg3 } from './LoginCarousel.constant';

const LoginCarousel = () => {
  return (
    <div>
      <div className={'G-carousel-dark ' + style.containerMain}>
        <Carousel fade>
          <Carousel.Item interval={5000}>
            <div className={'flex flex-column align-items-center Gb-pink-1 ' + style.carouselContainer}>
              <div className={style.heightFitContentMob}>
                <Svg1 />
              </div>
              <div className={'px-5 mt-2 ' + style.heightFitContent}>
                <label className='Gfs-15 Gt-pink-8 sans-bold'>
                  Benefit from tax-free contributions and save together.
                </label>
              </div>
              <div className='px-5 '>
                <label>
                  Enjoy up to $5,250 in tax-free contributions from your employer until January 1, 2026. Both you and your employer can save on federal payroll taxes, creating a win-win situation for everyone involved.
                </label>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className={'flex flex-column align-items-center Gb-orange-2 ' + style.carouselContainer}>
              <div className={style.heightFitContentMob}>
                <Svg2 />
              </div>
              <div className={'px-5 mt-2 ' + style.heightFitContent}>
                <label className='Gfs-15 Gt-pink-8 sans-bold'>
                  Achieve financial freedom with our all-in-one platform.
                </label>
              </div>
              <div className='px-5 '>
                <label>
                  Discover a world of student loan benefits and financial wellness tools designed just for you. With Vault Pay, Vault 529, Vault Match, and Vault Tuition Reimbursement, managing your financial future has never been easier or more secure.
                </label>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className={'flex flex-column align-items-center Gb-blue-100 ' + style.carouselContainer}>
              <div className={style.heightFitContentMob}>
                <Svg3 />
              </div>
              <div className={'px-5 mt-2 ' + style.heightFitContent}>
                <label className='Gfs-15 Gt-pink-8 sans-bold'>
                  Feel valued and secure with tailored student loan benefits.
                </label>
              </div>
              <div className='px-5 '>
                <label>
                  Your employer cares about your financial well-being, which is why they offer our suite of products, including Vault Advisor, Vault Pay, and Vault Match. Take advantage of these top-requested employment perks, designed to help you feel supported and empowered in your financial journey.
                </label>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  )
}

export default LoginCarousel