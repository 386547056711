import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import Header from "../../../Includes/Header/Header";
import Footer from "../../../Includes/Footer/Footer";
import Styles from './ForgivenessEligibility.module.css';

import { fetchCustomer, updateCustomer } from '../../../../actions/customerActions';
import { getLoans } from '../../../../actions/fiveTwoNineActions';
import { forgivenessEligibilityCheck, getPellGrants, postPellGrants, updatePellGrants, deletePellGrants } from '../../../../actions/advisorActions';
import { getAdvisorServicers } from '../../../../actions/advisorActions';

import { intercomTrackEvent } from "../../../customerStatus";
import eventNames from "../../../event-names-map";
import { convertHyphensCamelCaseLoans, loanStatusMessage, loanTypeFriendly, numberFormat } from '../../../../common/js/jsFunctions';
import { sortAdvisorServicers } from '../../dashboardCommon';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { responseMessage } from "../../../../utils/alert";
import { Form, Table } from 'react-bootstrap';
import ValidatorMessage from '../../../shared/ValidatorMessage/ValidatorMessage';
import { Pencil, QuestionCircleFill } from 'react-bootstrap-icons';
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import { AdviceAndToolForgivenessEligibilityBreadcrumb } from '../../CommonBreadcrumb/Breadcrumb.constant';
import { fetchEmployer } from './../../../../actions/employerActions';
import ReactTooltip from 'react-tooltip';


class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      married: "",
      loans: [],
      loanCount: 0,
      loanWord: 'loan',
      federalLoansCount: 0,
      privateLoansCount: 0,
      loanBalanceTotal: 0,
      loanMonthlyPaymentTotal: 0,
      advisorServicers: [],
      filing_jointly: "",
      agi_cents: "",
      spouse_agi_cents: "",
      spouse_federal_loan_debt_cents: "",
      pslf_eligible: "",
      tlf_eligible: "",
      advisorServiceAgreement: "",
      advisorServiceAgreementDoc: "",
      show: false,
      disabled: false,
      forgivenessEligibilityMessage: "",
      hasPell: false,
      pellInfo: {
        pellId: "",
        schoolName: "",
        awardYear: "",
        awardAmount: ""
      },
      validationError: {
        agi_cents: false,
        spouse_agi_cents: false,
        spouse_federal_loan_debt_cents: false,
      }
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger mb-0">{message}</p>;
      },
      autoForceUpdate: this
    });
    this.showValidationMessage = this.showValidationMessage.bind(this);
  }
  componentDidMount() {
    document.title = "Vault Advisor | Forgiveness Eligibility"
    const { getLoans, fetchEmployer, fetchCustomer, getAdvisorServicers, forgivenessEligibilityCheck, getPellGrants } = this.props;
    fetchCustomer();
    fetchEmployer();
    getLoans();
    intercomTrackEvent(eventNames.visitedAdvisorAboutMe);
    getAdvisorServicers();
    forgivenessEligibilityCheck();
    getPellGrants();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loans !== this.props.loans) {
      let loanData = this.props.loans;
      let loans = convertHyphensCamelCaseLoans(this.props.loans);

      const loanCount = loans.length;
      const loanWord = loanCount > 0 ? 'loans' : 'loan';

      const federalLoans = loans.filter(function (value) {
        return value.loanType !== 'Private'
      });
      const federalLoansCount = federalLoans.length;
      const privateLoansCount = loanCount - federalLoansCount;

      let loanBalanceTotal = 0;
      let loanMonthlyPaymentTotal = 0;
      for (var i = 0; i < loans.length; i++) {
        loanBalanceTotal += loans[i].balance / 100;
        loanMonthlyPaymentTotal += Math.floor(loans[i].monthlyPayment / 100);
      }
      this.setState({
        loanData: loanData,
        loans: loans,
        loanCount: loanCount,
        loanWord: loanWord,
        federalLoansCount: federalLoansCount,
        privateLoansCount: privateLoansCount,
        loanBalanceTotal: numberFormat(loanBalanceTotal),
        loanMonthlyPaymentTotal: numberFormat(loanMonthlyPaymentTotal)
      })
    }
    if (prevState.forgivenessEligibilityMessage !== this.props.forgivenessEligibilityMessage) {
      const { forgivenessEligibilityMessage } = this.props;
      this.setState({
        forgivenessEligibilityMessage: forgivenessEligibilityMessage
      })
    }
    if (prevProps.advisorServicers !== this.props.advisorServicers) {
      const advisorServicers = this.props.advisorServicers.sort(sortAdvisorServicers)
      this.setState({
        advisorServicers: advisorServicers
      })
    }
    if (prevProps.pellGrants !== this.props.pellGrants) {
      this.setState({
        hasPell: this.props.pellGrants?.data && this.props.pellGrants?.data[0]?.id ? true : false,
        pellInfo: {
          pellId: this.props.pellGrants?.data && this.props.pellGrants?.data[0]?.id,
          schoolName: this.props.pellGrants?.data && this.props.pellGrants?.data[0]?.attributes.attending_school_name,
          awardYear: this.props.pellGrants?.data && this.props.pellGrants?.data[0]?.attributes.award_year,
          awardAmount: this.props.pellGrants?.data && this.props.pellGrants?.data[0]?.attributes.award_amount,
        }
      })
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (this.props.customerData !== nextProps.customerData) {
      this.setState({
        customer: nextProps.customerData,
        married: nextProps.customerData.attributes.married,
        filing_jointly: nextProps.customerData.attributes.filing_jointly,
        agi_cents: nextProps.customerData.attributes.agi_cents ? nextProps.customerData.attributes.agi_cents / 100 : 0,
        spouse_agi_cents: nextProps.customerData.attributes.spouse_agi_cents ? nextProps.customerData.attributes.spouse_agi_cents / 100 : 0,
        spouse_federal_loan_debt_cents: nextProps.customerData.attributes.spouse_agi_cents ? nextProps.customerData.attributes.spouse_federal_loan_debt_cents / 100 : 0,
        pslf_eligible: nextProps.customerData.attributes.pslf_eligible,
        tlf_eligible: nextProps.customerData.attributes.tlf_eligible,
      })
    }
  }

  setMarried = (marriedVal, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.married = marriedVal;
    if (customer.attributes && marriedVal === false) {
      customer.attributes.spouse_agi_cents = 0;
      customer.attributes.spouse_federal_loan_debt_cents = 0;
      customer.attributes.filing_jointly = false
    }
    this.setState({
      married: marriedVal,
      customer: customer
    })
  }
  setFilingJoint = (filingJointly, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.filing_jointly = filingJointly;

    this.setState({
      filing_jointly: filingJointly,
      customer: customer
    })
  }
  handleAgiCentsChange = (values) => {
    const customer = this.state.customer;

    const value = values.value;
    const floatValue = values.floatValue;
    if (customer.attributes && floatValue > 0) {
      const agiCents = floatValue * 100

      customer.attributes.agi_cents = agiCents;
      this.setState({
        agi_cents: value,
        customer: customer
      })
    } else {
      this.setState({
        agi_cents: ""
      })
    }
  }
  handleSpouseAgiCentsChange = (values) => {
    const customer = this.state.customer;
    const value = values.value;
    const floatValue = values.floatValue;
    const spouseAgiCents = floatValue * 100

    if (customer.attributes) customer.attributes.spouse_agi_cents = spouseAgiCents;
    this.setState({
      spouse_agi_cents: value,
      customer: customer
    })
  }
  handleSpouseFederalLoanDebtCentsChange = (values) => {
    const customer = this.state.customer;
    const value = values.value;
    const floatValue = values.floatValue;
    const spouseFederalLoanDebtCents = floatValue * 100

    if (customer.attributes) customer.attributes.spouse_federal_loan_debt_cents = spouseFederalLoanDebtCents;
    this.setState({
      spouse_federal_loan_debt_cents: value,
      customer: customer
    })
  }
  setPslfEligible = (pslfEligible, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.pslf_eligible = pslfEligible;

    this.setState({
      pslf_eligible: pslfEligible,
      customer: customer
    })
  }
  setTlfEligible = (tlfEligible, event) => {
    const customer = this.state.customer;
    if (customer.attributes) customer.attributes.tlf_eligible = tlfEligible;

    this.setState({
      tlf_eligible: tlfEligible,
      customer: customer
    })
  }
  handleSubmit = (e) => {
    const { forgivenessEligibilityCheck, postPellGrants, updatePellGrants, deletePellGrants } = this.props;
    const { pellInfo, hasPell } = this.state;
    const id = pellInfo.pellId;
    if (this.validator.allValid()) {
      const jsonRequest = {
        "data": this.state.customer
      }
      this.props.updateCustomer(jsonRequest);
      if (hasPell === !false) {
        const pellData = this.postPellGrantData();
        if (id) {
          updatePellGrants(id, pellData);
        }
        else {
          postPellGrants(pellData);
          this.timerID = setTimeout(() => {
            this.props.getPellGrants();
          }, 500);
        }
      } else if (hasPell === false && id) {
        deletePellGrants(id)
        this.setState({
          pellInfo: {
            pellId: null,
            schoolName: null,
            awardYear: null,
            awardAmount: null
          }
        })
      }
      this.timerID = setTimeout(() => {
        forgivenessEligibilityCheck();
      }, 700);
      responseMessage("success", "The loan forgiveness eligibility was re-calculated successfully");
    } else {
      this.validator.showMessages();
    }
  }
  postPellGrantData = () => {
    const { pellInfo } = this.state
    return {
      data: {
        attributes: {
          attending_school_name: pellInfo.schoolName,
          award_amount: pellInfo.awardAmount.replace('$', ''),
          award_year: pellInfo.awardYear
        }
      }
    }
  }
  handleCancel = (e) => {
    this.props.history.push({
      pathname: '/intro/advisor'
    })
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  setPellGrant = (pellValue) => {
    this.setState({
      hasPell: pellValue
    })
  }
  handlePellInfoChange = (event) => {
    this.setState({
      pellInfo: {
        ...this.state.pellInfo,
        [event.target.name]: event.target.value
      }
    })
  }
  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  render() {
    const { loans, forgivenessEligibilityMessage, filing_jointly, married, pslf_eligible, tlf_eligible, hasPell } = this.state;
    this.validator.purgeFields();
    const { employer } = this.props;
    const allowOneOnOne = employer?.attributes?.allow_one_on_one;

    return (

      <>
        <Header {...this.props} />
        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={AdviceAndToolForgivenessEligibilityBreadcrumb} />
            <div className=" p-4 Gborder-05 bg-white  my-3 rounded-lg">
              <div className='G-grid11-2'>
                <div>
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M30 18.75L16.25 26.25L21.25 28.975V36.475L30 41.25L38.75 36.475V28.975L41.25 27.6125V36.25H43.75V26.25L30 18.75ZM38.525 26.25L30 30.9L21.475 26.25L30 21.6L38.525 26.25ZM36.25 34.9875L30 38.4L23.75 34.9875V30.3375L30 33.75L36.25 30.3375V34.9875Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h2>Student Loan Forgiveness</h2>
                  <p className="sans-medium">
                    The Biden Administration announced $10,000 of student loan forgiveness for individuals who did not receive Pell Grants and $20,000 for users who did receive Pell Grants. This applies to borrowers who have federal loans and earn under $125,000 per year (or are a member of a household that earns under $250,000 per year).
                  </p>
                </div>
                <div>
                  <label className='sans-semibold Gfs-15'>Confirm your profile information below to see how much you are eligible for!</label>
                  <div className="my-4">
                    <div>
                      <h4 className="Gt-slate-5 Gfs-1">Are you single or married?</h4>
                    </div>
                    <div className='d-flex flex-wrap'>
                      <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (this.state.married === false ? ' Gb-pink-8' : '')} onClick={this.setMarried.bind(this, false)}>
                        <p className={"mb-0" + (this.state.married === false ? ' text-white' : ' Gt-pink-8')}>
                          SINGLE
                        </p>
                      </div>

                      <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (this.state.married === true ? ' Gb-pink-8' : '')} onClick={this.setMarried.bind(this, true)}>
                        <p className={"mb-0" + (this.state.married === true ? ' text-white' : ' Gt-pink-8')}>
                          MARRIED
                        </p>
                      </div>
                    </div>
                    <div>
                      {
                        this.validator.message(
                          "married",
                          this.state.married,
                          "required", {
                          messages: {
                            required: "* Married can't be blank"
                          }
                        }
                        )
                      }
                    </div>
                  </div>
                  {married &&
                    <>
                      <div className="my-4">
                        <div>
                          <h4 className="Gt-slate-5 Gfs-1">Do you and your spouse file a joint tax return?</h4>
                        </div>
                        <div className='d-flex flex-wrap'>
                          <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (filing_jointly ? ' Gb-pink-8' : '')} onClick={this.setFilingJoint.bind(this, true)}>
                            <p className={"mb-0" + (filing_jointly ? ' text-white' : ' Gt-pink-8')}>
                              YES
                            </p>
                          </div>

                          <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (!filing_jointly ? ' Gb-pink-8' : '')} onClick={this.setFilingJoint.bind(this, false)}>
                            <p className={"mb-0" + (!filing_jointly ? ' text-white' : ' Gt-pink-8')}>
                              NO
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        {
                          this.validator.message(
                            "filing_jointly",
                            this.state.filing_jointly,
                            "required", {
                            messages: {
                              required: "* Filing Jointly can't be blank"
                            }
                          }
                          )
                        }
                      </div>
                    </>
                  }
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      How much did you make as reported on your last tax return (also known as your Adjusted Gross Income)?
                      <QuestionCircleFill className='ml-2 mb-1' size={18} data-for="tooltipForTaxReturn" data-tip role='button' />
                    </Form.Label>
                    <ReactTooltip id="tooltipForTaxReturn" place="top" effect="solid" multiline>
                      You can find your AGI by looking at a copy of your last tax return.
                      <br />
                      Depending on the form, AGI can be found on the following number:
                      <br />
                      Form 1040 - Line 11
                      <br />
                      Form 1040A - Line 21
                      <br />
                      Form 1040EZ – Line 4
                      <br />
                      Form 1040NR – Line 11
                      <br />
                      TIP: If you don’t want to go through your taxes, an estimate of your total yearly income will do!
                    </ReactTooltip>
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={this.state.agi_cents ? this.state.agi_cents : ''}
                      prefix="$"
                      decimalSeparator="."
                      displayType="input"
                      thousandSeparator={true}
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      type="text"
                      className="form-control"
                      id="agi_cents"
                      name="agi_cents"
                      onBlur={e => this.showValidationMessage(e)}
                      onValueChange={(values) => { this.handleAgiCentsChange(values) }}
                    />
                    <ValidatorMessage validator={this.validator} fieldName='agi_cents' fieldValue={this.state.agi_cents} message="* Adjusted Gross income must be greater than zero" />
                  </Form.Group>
                  {married &&
                    <>
                      <Form.Group className='mb-4'>
                        <Form.Label className="">How much did your spouse make on their last tax return (also known as their Adjusted Gross Income)?</Form.Label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={this.state.spouse_agi_cents}
                          prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="text"
                          className="form-control"
                          id="spouse_agi_cents"
                          name="spouse_agi_cents"
                          onValueChange={(values) => { this.handleSpouseAgiCentsChange(values) }}
                        />
                        <ValidatorMessage validator={this.validator} fieldName='spouse_agi_cents' fieldValue={this.state.spouse_agi_cents} message="* Spouse AGI can't be blank" />
                      </Form.Group>

                      <Form.Group className='mb-4'>
                        <Form.Label className="">How much does your spouse owe in federal student loans?</Form.Label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={this.state.spouse_federal_loan_debt_cents}
                          prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="text"
                          className="form-control"
                          id="spouse_federal_loan_debt_cents"
                          name="spouse_federal_loan_debt_cents"
                          onValueChange={(values) => { this.handleSpouseFederalLoanDebtCentsChange(values) }}
                        />
                        <ValidatorMessage validator={this.validator} fieldName='spouse_federal_loan_debt_cents' fieldValue={this.state.spouse_federal_loan_debt_cents} message="* Spouse Total Student Loan Debt can't be blank" />
                      </Form.Group>
                    </>
                  }
                  <Form.Group>
                    <Form.Label> Do you work for a 501(c)(3) non-profit or government organization? </Form.Label>
                    <Form.Text className="text-muted sans-medium mb-1">
                      You could be eligible for loan forgiveness. Working at a non-profit or government employer is one of the eligibility requirements for Public Service Loan Forgiveness (PSLF). PSLF offers forgiveness on the remaining balance on Direct Loans after you have made 120 qualifying monthly payments under a qualifying repayment plan while working full-time for a qualifying repayment plan while working full-time for a qualifying employer. Working at a non-profit, however, does not guarantee forgiveness.
                    </Form.Text>
                  </Form.Group>
                  <div className="my-1">
                    <div className='d-flex flex-wrap'>
                      <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (!pslf_eligible ? ' Gb-pink-8' : '')} onClick={this.setPslfEligible.bind(this, false)}>
                        <p className={"mb-0" + (!pslf_eligible ? ' text-white' : ' Gt-pink-8')}>
                          NO
                        </p>
                      </div>

                      <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (pslf_eligible ? ' Gb-pink-8' : '')} onClick={this.setPslfEligible.bind(this, true)}>
                        <p className={"mb-0" + (pslf_eligible ? ' text-white' : ' Gt-pink-8')}>
                          YES
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.state.pslf_eligible ?
                    <Form.Group>
                      <Form.Text className="text-muted sans-medium mb-1">
                        Once you click update, you will receive an email with additional details about the loan
                        forgiveness program.
                      </Form.Text>
                    </Form.Group>
                    : null}


                  <Form.Group className='mb-0 mt-4'>
                    <Form.Label> Do you teach at a Title I or non-profit school? </Form.Label>
                  </Form.Group>
                  <div className="my-1">
                    <div className='d-flex flex-wrap'>
                      <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (!tlf_eligible ? ' Gb-pink-8' : '')} onClick={this.setTlfEligible.bind(this, false)}>
                        <p className={"mb-0" + (!tlf_eligible ? ' text-white' : ' Gt-pink-8')}>
                          NO
                        </p>
                      </div>

                      <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (tlf_eligible ? ' Gb-pink-8' : '')} onClick={this.setTlfEligible.bind(this, true)}>
                        <p className={"mb-0" + (tlf_eligible ? ' text-white' : ' Gt-pink-8')}>
                          YES
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.state.tlf_eligible ?
                    <Form.Group>
                      <Form.Text className="text-muted sans-medium mb-1">
                        Once you click update, you will receive an email with additional details about the loan
                        forgiveness program.
                      </Form.Text>
                    </Form.Group>
                    : null}


                  <Form.Group className='mb-0 mt-4'>
                    <Form.Label> Have you ever received a Pell Grant? </Form.Label>
                  </Form.Group>
                  <div className="my-1">
                    <div className='d-flex flex-wrap'>
                      <div className={'G-cancel-button mr-2 mb-2 cursor-pointer flex-grow-1 text-center' + (!hasPell ? ' Gb-pink-8' : '')} onClick={this.setPellGrant.bind(this, false)}>
                        <p className={"mb-0" + (!hasPell ? ' text-white' : ' Gt-pink-8')}>
                          NO
                        </p>
                      </div>

                      <div className={'G-cancel-button mb-2 cursor-pointer flex-grow-1 text-center' + (hasPell ? ' Gb-pink-8' : '')} onClick={this.setPellGrant.bind(this, true)}>
                        <p className={"mb-0" + (hasPell ? ' text-white' : ' Gt-pink-8')}>
                          YES
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.state.hasPell ?
                    <>
                      <Form.Group className='mb-4'>
                        <Form.Label>
                          School name
                        </Form.Label>
                        <Form.Control type="text" name='schoolName' placeholder="School name" onBlur={e => this.showValidationMessage(e)}
                          value={this.state.pellInfo.schoolName}
                          onChange={this.handlePellInfoChange.bind(this)} />
                        <ValidatorMessage customValidationType='includeNoSpecialChar' validator={this.validator} fieldName='schoolName' fieldValue={this.state.pellInfo.schoolName} message="* Pell Grant school name can't be blank" />
                      </Form.Group>
                      <Form.Group className='mb-4'>
                        <Form.Label>
                          Award year
                        </Form.Label>
                        <Form.Control type="text" name='awardYear' placeholder="Award year" onBlur={e => this.showValidationMessage(e)}
                          value={this.state.pellInfo.awardYear}
                          onChange={this.handlePellInfoChange.bind(this)} />
                        <ValidatorMessage validator={this.validator} fieldName='awardYear' fieldValue={this.state.pellInfo.awardYear} message="* Pell Grant award year can't be blank" />
                      </Form.Group>
                      <Form.Group className='mb-4'>
                        <Form.Label>
                          Award Amount
                        </Form.Label>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={this.state.pellInfo.awardAmount}
                          prefix="$"
                          // placeholder="$ "
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="text"
                          className="form-control"
                          id="awardAmount"
                          name="awardAmount"
                          onChange={(event) => { this.handlePellInfoChange(event) }}
                        />
                        <ValidatorMessage validator={this.validator} fieldName='awardAmount' fieldValue={this.state.pellInfo.awardAmount} message="* Pell Grant award amount can't be blank" />
                      </Form.Group>
                    </>
                    : null}
                  <div className="d-flex justify-content-end my-4 mob-btn-div-column">
                    <button onClick={this.handleSubmit.bind(this)} className="bg-transparent border-0 G-orange-button ">
                      Calculate My Loan Forgiveness
                    </button>
                  </div>
                </div>
              </div>

              <div className='mt-5'>
                <div className='my-3'>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#DCF3D1" />
                    <path d="M17.0002 24.17L12.8302 20L11.4102 21.41L17.0002 27L29.0002 15L27.5902 13.59L17.0002 24.17Z" fill="#52C41A" />
                  </svg>
                </div>
                <h4>
                  Eligibility
                </h4>
                <div >

                  {forgivenessEligibilityMessage
                    ? <p className={`sans-medium  ${Styles.link}`}>
                      {parse(forgivenessEligibilityMessage)}
                      <label className="Gt-pink-8 mt-2">
                        PRESENTLY, FFEL LOANS DO NOT QUALIFY FOR FORGIVENESS. THE HANDLING OF FFEL LOANS COULD CHANGE AS NEW DECISIONS ARE MADE REGARDING ELIGIBILITY
                      </label>
                    </p>
                    : <div> Calculating ... </div>
                  }
                </div>
              </div>

              <div className="mt-5 Gtable-div">
                <Table borderless className="Gborder-05 G-table-theme-grey">
                  <thead className="Gt-head">
                    <tr>
                      <th className="Gfs-075 p-3">Loan Type</th>
                      <th className="Gfs-075 p-3">Current Balance</th>
                      <th className="Gfs-075 p-3">Interest Rate</th>
                      <th className="Gfs-075 p-3">Monthly Payment</th>
                      <th className="Gfs-075 p-3">Status</th>
                      <th className="Gfs-075 p-3">Edit</th>
                    </tr>
                  </thead>
                  <tbody className="Gt-body">
                    {
                      loans.length > 0 ? loans.map(function (data, i) {
                        const loanId = data.loanId;
                        const loanType = loanTypeFriendly(data.loanType);
                        const balance = numberFormat((data.balance / 100).toFixed(2));
                        const interestRate = data.interestRate;
                        const monthlyPayment = numberFormat((data.monthlyPayment / 100).toFixed(2));
                        // const status = data.status;
                        const loanStatus = loanStatusMessage(data.status, allowOneOnOne);

                        // const loansArray = loanData.find(function (value) {
                        //   return value.id === loanId;
                        // });
                        // const servicerId = loansArray.relationships.servicer.data.id;
                        // const dataServicer = getServicerById(servicerId, advisorServicers);

                        // let friendly_name = '';
                        // if (dataServicer) {
                        //   // friendly_name = dataServicer.attributes.friendly_name
                        // }
                        return (<tr key={i}>
                          <td>
                            {loanType}
                          </td>
                          <td>{balance}</td>
                          <td>{interestRate + "%"}</td>
                          <td>{monthlyPayment}</td>
                          <td className='Gt-pink-8'>
                            {loanStatus}
                          </td>
                          <td >
                            <Link to={{ pathname: '/advice-and-tools/loans/edit/' + loanId, state: { fromForgivenessEligibility: true } }}>
                              <Pencil className="G-link G-cursor-pointer" />
                            </Link>
                          </td>
                        </tr>)
                      })
                        : (<tr><td colSpan={6}>You currently do not have any loans.</td></tr>)
                    }
                  </tbody>
                </Table>
              </div>

            </div>
          </div>
        </div>




        <Footer />
      </>
    )
  }
}

About.propTypes = {
  fetchCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getLoans: PropTypes.func.isRequired,
  getAdvisorServicers: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    customerData: state.customer_array.customerData,
    updateCustomerResponse: state.customer_array.updateCustomerResponse,
    loans: state.five_two_nine.loans,
    advisorServicers: state.advisor.advisorServicers,
    forgivenessEligibilityMessage: state.advisor.forgivenessEligibilityMessage?.data?.attributes?.message,
    pellGrants: state.advisor.pellGrants,
    employer: state.employer_array.employerData,
  };
}



export default connect(mapStateToProps, { getAdvisorServicers, fetchCustomer, fetchEmployer, getLoans, updateCustomer, forgivenessEligibilityCheck, getPellGrants, postPellGrants, updatePellGrants, deletePellGrants })(About);
