import Form from 'react-bootstrap/Form';
import S from './DragAndDropBox.module.css';
import React from 'react';

const DragAndDropBox = React.forwardRef(({ setFileData, id = 'uploadFile' }, ref) => {

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileData(e.dataTransfer.files[0]);
    }
  }

  const uploadFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  }
  return (
    <Form >
      <Form.Group>
        <Form.Label onDragEnter={(e) => handleDrag(e)} onDragLeave={(e) => handleDrag(e)} onDragOver={(e) => handleDrag(e)} onDrop={(e) => handleDrop(e)} className={`d-flex flex-column rounded-lg ${S.dropBox}`} htmlFor={id}>
          <svg width="54" height="67" viewBox="0 0 54 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6667 0.145615H7.00004C5.23193 0.145615 3.53624 0.847993 2.286 2.09824C1.03575 3.34848 0.333374 5.04417 0.333374 6.81228V60.1456C0.333374 61.9137 1.03575 63.6094 2.286 64.8597C3.53624 66.1099 5.23193 66.8123 7.00004 66.8123H47C48.7682 66.8123 50.4638 66.1099 51.7141 64.8597C52.9643 63.6094 53.6667 61.9137 53.6667 60.1456V20.1456L33.6667 0.145615ZM32 46.8123V56.8123H22V46.8123H13.6667L27 33.479L40.3334 46.8123H32ZM30.3334 23.4789V5.14561L48.6667 23.4789H30.3334Z" fill="var(--icon-color)" />
          </svg>

          <span className='mt-2 d-block text-uppercase'>drag & drop or browse file</span>
        </Form.Label>
        <Form.Control onChange={(e) => uploadFile(e)} className='invisible position-absolute w-25' id={id} ref={ref} type="file" placeholder="Enter email" accept="application/pdf, image/png, image/jpeg, .txt" />
      </Form.Group>
    </Form>
  );
})

export const checkFileFormat = (file, onlyPdf = false) => {
  const extPattern = /\.[0-9a-z]+$/i;
  const fileExt = file.name.match(extPattern)[0];
  const validFileFormat = onlyPdf ? (fileExt === '.pdf') : (fileExt === '.pdf' || fileExt === '.jpg' || fileExt === '.png' || fileExt === '.jpeg');
  return validFileFormat;
}

export const formatFileSize = (value) => {
  if (value > 100000) {
    return (Number(value / (1024 * 1024))).toFixed(2) + ' MB'
  } else {
    return (Number(value / (1024 * 1024)) * 1000).toFixed(2) + ' KB'
  }
}

// TODO: adding a file size check on file in future

export default DragAndDropBox;