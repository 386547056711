const environment = process.env.NODE_ENV;
const deployTarget = process.env.REACT_APP_DEPLOY_TARGET;

const ENV = {
  apiBaseUrl: process.env.REACT_APP_API_ENDPOINT,
  mainSiteUrl: process.env.REACT_APP_MAIN_SITEURL,
  cacheType: "sessionStorage",
  debug: false,
  environment: environment,
  sso: {
    blacklistedSubDomains: ['app'],
    nylSamlEndpoint: 'https://nyl.studentloangenius.com/users/saml/sign_in'
  }
};

if (deployTarget === 'pru_production') {
  ENV.sso.blacklistedSubDomains = ['pru'];
}

if (deployTarget === 'qa') {
  ENV.sso.blacklistedSubDomains = ['app-qa'];
  ENV.sso.nylSamlEndpoint = 'https://app-qa.vault.co/users/saml/sign_in?federated_idp_name=NYLSSO';
}
if (deployTarget === 'pru_qa') {
  ENV.sso.blacklistedSubDomains = ['pru'];
  ENV.sso.nylSamlEndpoint = 'https://app-qa.pru.vault.co/users/saml/sign_in?federated_idp_name=NYLSSO';
}

if (deployTarget === 'demo') {
  ENV.sso.blacklistedSubDomains = ['app-demo'];
  ENV.sso.nylSamlEndpoint = 'https://app-demo.vault.co/users/saml/sign_in?federated_idp_name=NYLSSO';
}

if (deployTarget === 'staging') {
  ENV.sso.nylSamlEndpoint = 'https://app-stg.vault.co/users/saml/sign_in?federated_idp_name=NYLSSO';
}
if (deployTarget === 'pru_staging') {
  ENV.sso.blacklistedSubDomains = ['pru'];
   ENV.sso.nylSamlEndpoint = 'https://app-stg.pru.vault.co/users/saml/sign_in?federated_idp_name=NYLSSO';
}

if (environment === 'development') {
  ENV.sso.nylSamlEndpoint = 'https://app.slgdev.net/users/saml/sign_in?federated_idp_name=NYLSSO';
}
if (environment === 'fe-test') {
  ENV.sso.nylSamlEndpoint = 'http://nyl.slgdev.net:4100/users/saml/sign_in';
}

export default ENV;

