import React, { useEffect } from "react";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { BenefitsOfFiveTwoNinePlanBreadcrumb, } from "../CommonBreadcrumb/Breadcrumb.constant";
import ImageComponent from "../../shared/GridImageComponent/ImageComponent";
import imageData from "./imageData";


const BenefitsOfFiveTwoNinePlan = () => {
  useEffect(() => {
    document.title = "Vault | What are the benefits of a 529 plan?"
  })

  return (
    <>
      <Header />
      <div className="G-page-background-color">
        <div className="G-page-main-container  my-5 ">
          <Breadcrumb list={BenefitsOfFiveTwoNinePlanBreadcrumb} />
          <div className="bg-white Gborder-1 rounded-lg p-4">
            <div className="pinkCircularDiv mb-3">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                <path d="M29.9875 17.5C23.0875 17.5 17.5 23.1 17.5 30C17.5 36.9 23.0875 42.5 29.9875 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 29.9875 17.5ZM39.3375 26.45L33.075 25.9125L30.575 20.025C34.5875 20.2625 37.9625 22.8625 39.3375 26.45ZM33 34.275L30 32.475L27.0125 34.275L27.8 30.875L25.1625 28.5875L28.6375 28.2875L30 25.075L31.3625 28.275L34.8375 28.575L32.2 30.8625L33 34.275ZM29.425 20.025L26.925 25.925L20.65 26.4625C22.025 22.8625 25.4 20.25 29.425 20.025ZM20 30C20 29.2 20.1 28.425 20.2875 27.675L25.025 31.775L23.6375 37.7125C21.4125 35.875 20 33.1 20 30ZM24.8 38.525L30 35.3875L35.2 38.5125C33.675 39.45 31.9 40 29.9875 40C28.0875 40 26.3125 39.45 24.8 38.525ZM36.3625 37.7125L34.975 31.775L39.7125 27.675C39.8875 28.4125 40 29.2 40 30C40 33.1 38.575 35.875 36.3625 37.7125Z" fill="var(--icon-color)" />
              </svg>
            </div>
            <h4>What are the benefits of a 529 plan?</h4>
            <p className="sans-medium">
              Hey, glad you asked! 529 plans offer several benefits that make them an attractive option for saving for education expenses. Here are some of the key advantages
            </p>
            {imageData.map((item, index) => (
              <ImageComponent key={item.id} index={index + 1} id={item.id} imageUrl={item.url} mainTitle={item.title} mainContext={item.description} />
            ))}
            <h4>
              So there you have it
            </h4>
            <p className="sans-medium mb-0">
              The highlights of why 529 plans are the cool kids on the block for saving for education. It's important to note that the specific benefits of 529 plans can vary depending on the state and plan you choose. Therefore, it's essential to research and compare the features of different plans to find the one that best suits your needs and financial goals. Start planning now! 🎓💸
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );

}

export default BenefitsOfFiveTwoNinePlan;