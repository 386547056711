import React from 'react';
import { Link } from "react-router-dom";
import { setTuitionDetailsAccordionData, setBasicDetailsAccordionData, setBankDetailsAccordionData, tuitionReimburseBreadcrumbData } from './TuitionReimbursementStatus.constant';
import BankDetailsAccordion from './BankDetailsAccordion';
import InfoCircleFill from 'react-bootstrap-icons/dist/icons/info-circle-fill';
import TuitionDetailsAccordion from './TuitionDetailsAccordion';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import BasicDetailsAccordion from './BasicDetailsAccordion';

const TuitionReimbursementStatusNeedMoreInfo = ({ tuitionId, fetchIndServiceAccountData, servicerAccountData, addressData }) => {
  const editUrl = "/tuition-reimbursement/" + tuitionId;
  const redirectUrl = "/tuition-reimbursement-history";

  let tuitionRejectionNote = "";
  if (fetchIndServiceAccountData) {
    tuitionRejectionNote = fetchIndServiceAccountData?.data?.attributes.tuition_rejection_note
  }
  return (
    <>
      <Breadcrumb list={tuitionReimburseBreadcrumbData()} />
      <div className='p-4 Gborder-05 rounded-lg G-grid11-2 bg-white mb-5 mt-2'>
        <div >
          <div className='pinkCircularDiv mb-3'>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.75 16.75H14.25V19.25H11.75V16.75ZM11.75 6.75H14.25V14.25H11.75V6.75ZM13 0.5C6.0875 0.5 0.5 6.125 0.5 13C0.5 16.3152 1.81696 19.4946 4.16117 21.8388C5.3219 22.9996 6.69989 23.9203 8.21646 24.5485C9.73303 25.1767 11.3585 25.5 13 25.5C16.3152 25.5 19.4946 24.183 21.8388 21.8388C24.183 19.4946 25.5 16.3152 25.5 13C25.5 11.3585 25.1767 9.73303 24.5485 8.21646C23.9203 6.69989 22.9996 5.3219 21.8388 4.16117C20.6781 3.00043 19.3001 2.07969 17.7835 1.45151C16.267 0.823322 14.6415 0.5 13 0.5M13 23C10.3478 23 7.8043 21.9464 5.92893 20.0711C4.05357 18.1957 3 15.6522 3 13C3 10.3478 4.05357 7.8043 5.92893 5.92893C7.8043 4.05357 10.3478 3 13 3C15.6522 3 18.1957 4.05357 20.0711 5.92893C21.9464 7.8043 23 10.3478 23 13C23 15.6522 21.9464 18.1957 20.0711 20.0711C18.1957 21.9464 15.6522 23 13 23V23Z" fill="var(--icon-color)" />
            </svg>
          </div>
          <div className='my-2'>
            <span className='G-orange-badge'>Need More Info</span>
          </div>
          <h3 className='Gt-slate-5 Gfs-175 my-2'>Employer Approval Status</h3>
          <p className='sans-medium'> Your employer needs additional information. Please check your email for details or contact <a className="G-link cancelLink text-decoration-none sans-semibold" href="mailto:genius@vault.co">Vault</a>.</p>
          <div className='Gb-yellow-2 my-2 px-2 py-3 rounded-lg d-flex'>
            <div className='mr-3 my-auto'>
              <InfoCircleFill size={20} className='Gt-orange-3' />
            </div>
            <div>
              <p className='mb-0'> Reason: {tuitionRejectionNote}</p>
            </div>
          </div>
          <div className='mt-4 mob-btn-div-column d-flex flex-wrap'>
            <Link to={redirectUrl} className="G-cancel-button cancelLink text-decoration-none Gt-pink-8 mr-3 mob-separate-v6 my-2">Go Back</Link>
            <Link to={editUrl} className="G-orange-button mob-separate-v6 text-decoration-none text-white successLink my-2">Submit Additional Documents</Link>
          </div>
        </div>
        <div>
          <h3 className='Gt-slate-5 Gfs-15 mb-3'>Submitted Information</h3>
          <BasicDetailsAccordion basicData={setBasicDetailsAccordionData(fetchIndServiceAccountData.data)} />
          <TuitionDetailsAccordion tuitionData={setTuitionDetailsAccordionData(fetchIndServiceAccountData)} />
          <BankDetailsAccordion bankData={setBankDetailsAccordionData(servicerAccountData, addressData)} />
        </div>
      </div>
    </>
  )
}

export default TuitionReimbursementStatusNeedMoreInfo;
