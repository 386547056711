import React, { useEffect, } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { fetchCustomer } from '../../../actions/customerActions';
import { fetchEmployer } from '../../../actions/employerActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";

import { ChevronRight } from 'react-bootstrap-icons';

const MatchLogo = () => (
  <svg className='w-100 h-100' width="449" height="290" viewBox="0 0 449 290" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_574_7036)">
      <path d="M247.909 434.187H208.85L226.394 0L228.819 0.30527L247.909 434.187Z" fill="white" />
      <path d="M380.351 61.8879H90.5842V69.1517H380.351V61.8879Z" fill="white" />
      <path d="M6.96331 153.596L4.69312 152.558L95.5266 58.4608L164.081 152.658L161.644 153.498L95.1954 62.1948L6.96331 153.596Z" fill="white" />
      <path d="M102.258 66.381C103.481 61.2036 100.272 56.0159 95.0911 54.794C89.9102 53.572 84.7189 56.7785 83.4961 61.9559C82.2733 67.1333 85.482 72.321 90.6629 73.543C95.8439 74.7649 101.035 71.5584 102.258 66.381Z" fill="white" />
      <path d="M287.885 153.596L285.964 151.665L376.601 61.6806L445.045 151.808L442.873 153.454L376.314 65.8066L287.885 153.596Z" fill="white" />
      <path d="M226.073 94.3236C241.472 94.3236 253.956 81.8482 253.956 66.4589C253.956 51.0696 241.472 38.5942 226.073 38.5942C210.673 38.5942 198.189 51.0696 198.189 66.4589C198.189 81.8482 210.673 94.3236 226.073 94.3236Z" fill="white" />
      <path d="M380.935 71.572C384.699 67.8104 384.699 61.7117 380.935 57.9502C377.171 54.1886 371.068 54.1886 367.304 57.9502C363.54 61.7117 363.54 67.8104 367.304 71.572C371.068 75.3335 377.171 75.3335 380.935 71.572Z" fill="white" />
      <path d="M399.19 159.373C417.876 140.7 417.876 110.424 399.19 91.7507C380.503 73.0771 350.207 73.0772 331.52 91.7507C312.834 110.424 312.834 140.7 331.52 159.373C350.207 178.047 380.503 178.047 399.19 159.373Z" fill="white" />
      <path d="M398.065 158.25C416.131 140.197 416.131 110.927 398.065 92.8743C380 74.8213 350.71 74.8213 332.645 92.8743C314.579 110.927 314.579 140.197 332.645 158.25C350.71 176.303 380 176.303 398.065 158.25Z" fill="white" />
      <path opacity="0.78" d="M394.057 154.225C409.347 138.946 409.347 114.172 394.057 98.8928C378.767 83.6131 353.977 83.6131 338.687 98.8928C323.397 114.172 323.397 138.946 338.687 154.225C353.977 169.505 378.767 169.505 394.057 154.225Z" fill="var(--icon-color)" />
      <path d="M360.719 116.012C361.129 116.714 361.719 117.297 362.429 117.702C363.139 118.107 363.945 118.321 364.765 118.321C365.584 118.321 366.39 118.107 367.1 117.702C367.81 117.297 368.4 116.714 368.81 116.012L371.981 110.573L375.153 105.141C375.564 104.439 375.781 103.642 375.782 102.831C375.782 102.02 375.567 101.223 375.157 100.52C374.747 99.8174 374.157 99.234 373.447 98.8287C372.737 98.4233 371.931 98.2104 371.111 98.2112V98.2112C370.291 98.211 369.485 98.4249 368.775 98.8312C368.065 99.2376 367.477 99.822 367.068 100.525L360.719 111.422C360.31 112.124 360.095 112.919 360.095 113.73C360.095 114.54 360.31 115.336 360.719 116.037" fill="var(--tertiary-color)" />
      <path d="M357.548 110.573L357.905 111.184L365.457 98.2112H358.418C357.598 98.2104 356.792 98.4233 356.082 98.8287C355.372 99.234 354.782 99.8174 354.372 100.52C353.962 101.223 353.747 102.02 353.747 102.831C353.748 103.642 353.965 104.439 354.376 105.141L357.548 110.573Z" fill="var(--tertiary-color)" />
      <path d="M350.639 121.842C351.049 122.544 351.638 123.127 352.348 123.532C353.058 123.937 353.862 124.15 354.682 124.15H354.851C355.671 124.151 356.477 123.938 357.187 123.533C357.897 123.127 358.487 122.544 358.897 121.841C359.307 121.139 359.523 120.341 359.522 119.53C359.521 118.719 359.304 117.922 358.893 117.22L355.643 111.589L352.355 105.958C351.945 105.255 351.355 104.672 350.645 104.267C349.935 103.862 349.13 103.648 348.31 103.648C347.49 103.648 346.684 103.862 345.974 104.267C345.264 104.672 344.674 105.255 344.264 105.958L344.173 106.118C343.763 106.822 343.547 107.62 343.547 108.433C343.547 109.245 343.763 110.043 344.173 110.747L350.639 121.842Z" fill="var(--tertiary-color)" />
      <path d="M341.704 124.15H349.002L341.301 110.991L340.95 111.589L337.662 117.22C337.25 117.922 337.033 118.719 337.033 119.53C337.032 120.341 337.247 121.139 337.657 121.841C338.067 122.544 338.657 123.127 339.367 123.533C340.078 123.938 340.884 124.151 341.704 124.15" fill="var(--tertiary-color)" />
      <path d="M354.773 135.606C355.592 135.605 356.396 135.391 357.105 134.986C357.815 134.581 358.404 133.999 358.815 133.298L358.899 133.15C359.309 132.447 359.524 131.65 359.523 130.839C359.523 130.028 359.307 129.232 358.896 128.529C358.486 127.827 357.896 127.244 357.186 126.839C356.476 126.433 355.671 126.22 354.851 126.22H341.71C340.89 126.22 340.085 126.433 339.375 126.839C338.664 127.244 338.075 127.827 337.664 128.529C337.254 129.232 337.038 130.028 337.037 130.839C337.037 131.65 337.252 132.447 337.662 133.15L337.759 133.311C338.168 134.015 338.758 134.599 339.469 135.006C340.18 135.412 340.987 135.626 341.808 135.625L354.773 135.606Z" fill="var(--tertiary-color)" />
      <path d="M355.949 138.164H340.573L340.924 138.768L344.212 144.4C344.622 145.102 345.211 145.685 345.92 146.091C346.63 146.496 347.435 146.71 348.254 146.71C349.074 146.71 349.879 146.496 350.589 146.091C351.298 145.685 351.887 145.102 352.297 144.4L355.585 138.768L355.949 138.164Z" fill="var(--tertiary-color)" />
      <path d="M368.849 134.352L368.764 134.211C368.354 133.509 367.764 132.926 367.054 132.52C366.344 132.115 365.539 131.902 364.719 131.902C363.899 131.902 363.094 132.115 362.384 132.52C361.674 132.926 361.084 133.509 360.674 134.211L357.372 139.836L354.123 145.467C353.712 146.169 353.495 146.966 353.494 147.777C353.493 148.588 353.709 149.385 354.118 150.088C354.528 150.791 355.118 151.374 355.829 151.779C356.539 152.185 357.345 152.398 358.165 152.397H358.354C359.174 152.397 359.981 152.183 360.692 151.777C361.402 151.37 361.992 150.786 362.402 150.083L368.901 138.968C369.31 138.266 369.525 137.47 369.525 136.66C369.525 135.85 369.31 135.054 368.901 134.352" fill="var(--tertiary-color)" />
      <path d="M372.046 139.836L371.689 139.193L364.014 152.371H371.293C372.113 152.372 372.919 152.159 373.629 151.754C374.339 151.348 374.929 150.765 375.339 150.062C375.749 149.36 375.964 148.562 375.964 147.751C375.963 146.94 375.746 146.143 375.335 145.441L372.046 139.836Z" fill="var(--tertiary-color)" />
      <path d="M378.818 128.566C378.408 127.865 377.819 127.284 377.109 126.88C376.399 126.475 375.595 126.264 374.776 126.265H374.607C373.788 126.265 372.983 126.478 372.273 126.883C371.564 127.288 370.975 127.871 370.565 128.572C370.155 129.274 369.94 130.07 369.939 130.88C369.939 131.691 370.155 132.487 370.565 133.189L373.846 138.82L377.135 144.451C377.545 145.153 378.135 145.736 378.845 146.142C379.555 146.547 380.36 146.76 381.18 146.76C382 146.76 382.806 146.547 383.516 146.142C384.226 145.736 384.815 145.153 385.226 144.451L385.317 144.291C385.727 143.588 385.943 142.791 385.943 141.979C385.943 141.168 385.727 140.371 385.317 139.668L378.818 128.566Z" fill="var(--tertiary-color)" />
      <path d="M387.78 126.265H380.482L388.183 139.424L388.534 138.82L391.822 133.195C392.233 132.493 392.45 131.696 392.451 130.885C392.452 130.074 392.236 129.277 391.826 128.574C391.416 127.871 390.827 127.288 390.116 126.883C389.406 126.477 388.6 126.264 387.78 126.265" fill="var(--tertiary-color)" />
      <path d="M391.751 117.098C391.34 116.396 390.75 115.813 390.039 115.408C389.328 115.002 388.522 114.79 387.702 114.79H374.704C373.885 114.793 373.081 115.009 372.372 115.415C371.662 115.821 371.073 116.403 370.662 117.105L370.578 117.246C370.168 117.949 369.953 118.746 369.954 119.557C369.954 120.368 370.17 121.164 370.581 121.867C370.991 122.569 371.581 123.152 372.291 123.557C373.001 123.962 373.807 124.176 374.626 124.176H387.767C388.587 124.176 389.392 123.962 390.102 123.557C390.813 123.152 391.402 122.569 391.813 121.867C392.223 121.164 392.439 120.368 392.44 119.557C392.44 118.746 392.225 117.949 391.816 117.246L391.751 117.098Z" fill="var(--tertiary-color)" />
      <path d="M373.535 112.251H388.911L388.56 111.653L385.271 106.022C384.862 105.32 384.273 104.736 383.563 104.331C382.854 103.925 382.049 103.712 381.229 103.712C380.41 103.712 379.605 103.925 378.895 104.331C378.185 104.736 377.596 105.32 377.187 106.022L373.899 111.653L373.535 112.251Z" fill="var(--tertiary-color)" />
      <path d="M364.741 189.649C408.152 189.649 443.345 173.982 443.345 154.655H286.138C286.138 173.981 321.33 189.649 364.742 189.649H364.741Z" fill="white" />
      <path d="M447.294 146.729H279.488C278.546 146.729 277.782 147.492 277.782 148.434V156.973C277.782 157.915 278.546 158.678 279.488 158.678H447.294C448.236 158.678 449 157.915 449 156.973V148.434C449 147.492 448.236 146.729 447.294 146.729Z" fill="white" />
      <path d="M121.408 158.026C140.094 139.353 140.094 109.077 121.408 90.4035C102.722 71.73 72.4252 71.73 53.7389 90.4035C35.0526 109.077 35.0526 139.353 53.7389 158.026C72.4252 176.7 102.722 176.7 121.408 158.026Z" fill="url(#paint0_linear_574_7036)" />
      <path d="M120.282 156.903C138.347 138.85 138.347 109.58 120.282 91.5271C102.217 73.4742 72.9269 73.4742 54.8616 91.5271C36.7962 109.58 36.7962 138.85 54.8616 156.903C72.9269 174.956 102.217 174.956 120.282 156.903Z" fill="white" />
      <path opacity="0.78" d="M116.277 152.879C131.567 137.599 131.567 112.826 116.277 97.5461C100.987 82.2665 76.1968 82.2665 60.9067 97.5461C45.6165 112.826 45.6165 137.599 60.9067 152.879C76.1968 168.158 100.987 168.158 116.277 152.879Z" fill="var(--icon-color)" />
      <path d="M85.0419 154.335L84.9985 150.079C80.5562 149.946 76.1541 148.943 73.0205 147.61C71.9015 147.134 71.3211 145.891 71.6298 144.714L72.6716 140.738C73.0543 139.279 74.6299 138.511 76.0271 139.08C79.1349 140.346 82.9647 141.332 87.0854 141.291C91.3621 141.247 94.5375 139.768 94.507 136.797C94.4796 134.117 91.9988 132.549 86.0388 131.014C77.5352 128.781 71.4079 125.148 71.3339 117.687C71.2664 110.95 76.1444 105.757 84.6834 104.295L84.64 100.039C84.6255 98.6701 85.7268 97.547 87.0967 97.5342L89.6 97.5085C90.9698 97.494 92.0937 98.5946 92.1065 99.9635L92.1451 103.786C96.2562 103.91 99.2532 104.581 101.636 105.391C102.856 105.805 103.535 107.108 103.208 108.355L102.25 112.031C101.88 113.448 100.375 114.239 98.9991 113.734C96.8061 112.931 93.7079 112.102 89.6916 112.142C84.6175 112.194 83.1882 114.308 83.2074 116.191C83.2316 118.509 86.2912 120.071 92.9072 122.035C102.359 124.764 106.313 128.636 106.384 135.664C106.448 142.112 101.723 147.954 92.4586 149.496L92.5069 154.26C92.5213 155.63 91.42 156.75 90.0502 156.765L87.5469 156.79C86.177 156.805 85.0532 155.704 85.0403 154.335H85.0419Z" fill="white" />
      <path d="M86.5195 189.65C129.931 189.65 165.124 173.477 165.124 153.526H7.91699C7.91699 173.476 43.1082 189.65 86.5211 189.65H86.5195Z" fill="white" />
      <path d="M169.512 145.383H1.70586C0.76374 145.383 0 146.146 0 147.087V155.627C0 156.568 0.76374 157.331 1.70586 157.331H169.512C170.454 157.331 171.218 156.568 171.218 155.627V147.087C171.218 146.146 170.454 145.383 169.512 145.383Z" fill="white" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_574_7036" x1="22.8516" y1="200.824" x2="86.2409" y2="125.698" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF928E" />
        <stop offset="1" stop-color="#FE7062" />
      </linearGradient>
      <clipPath id="clip0_574_7036">
        <rect width="449" height="438" fill="white" />
      </clipPath>
    </defs>
  </svg>

)

const Overview = (props) => {
  useEffect(() => {
    document.title = "Vault | Overview"
    intercomTrackEvent(eventNames.visitedMatchOverview);
  }, []);

  const getStarted = (e) => {
    props.history.push({
      pathname: '/match/termsAndConditions'
    })
    intercomTrackEvent(eventNames.clickedGetStartedOnMatch);
  }

  return (
    <>
      <Header hideNotificationAndProfileDropdown={true} />
      <div className="G-page-background-color">
        <div className="G-page-main-container my-5">
          <div className="G-grid11-2 bg-white p-4 Gborder-05 rounded-lg mob-reverse-1200">
            <div>
              <h3 className="Gfs-225 mb-4 Gt-slate-5">
                Hey
                {' '}
                <span className="G-text-grad">{localStorage.getItem("customerFullName").split(' ')[0]}!</span>
              </h3>
              <div>
                <p className="sans-medium Gfs-125">
                  Your employer has partnered with Vault to help you find the best path to freedom from student debt.
                </p>
                <p className="sans-semibold Gfs-125">
                  Create an account and lower your payments, reduce your interest rates, and more.
                </p>
                <p className="sans-medium Gfs-125">
                  The average Vault user reduces their loan balance by over $6,500 and their payoff time by over 6 years. Let's go!
                </p>
                <div className='d-flex flex-wrap'>
                  {/* <button onClick={(e) => this.goToWelcomeAbout(e)} className='G-orange-button'>Get Started</button> */}
                  <button onClick={getStarted} className='G-orange-button mr-3'>Yes! Let's go!</button>
                  <Link to="/login" className='my-3 G-link text-decoration-none cancelLink'>
                    I already have an account. I'll sign in <ChevronRight />
                  </Link>
                </div>
              </div>
            </div>
            <div className="introAdvisorBannerHeight my-auto rounded-lg tuitionIntroBannerBackground">
              <MatchLogo />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )

}

// class Overview extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       customer: null,
//       employer: null,
//       show: false

//     }
//   }
//   componentDidMount() {
//     document.title = "Vault | Overview"
//     const { fetchCustomer, fetchEmployer } = this.props;
//     fetchCustomer();
//     fetchEmployer();
//     intercomTrackEvent(eventNames.visitedMatchOverview);
//   }
//   componentDidUpdate(prevProps) {
//     if (prevProps.customer !== this.props.customer) {
//       this.setState({
//         customer: this.props.customer
//       })
//     }  
//     if (prevProps.employer !== this.props.employer) {
//       this.setState({
//         employer: this.props.employer
//       })
//     }
//   }
//   clickedFaq = (e) => {
//     const { customer } = this.state;
//     const partnerName = customer.attributes.partner_name;
//     if (isCitizens(partnerName)) {
//       //return this.transitionToRoute('citizens.pay-faq');
//     } else {
//       const url = 'https://support.vault.co/kb/section/20/';
//       const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
//       if (newWindow) newWindow.opener = null
//     }
//     intercomTrackEvent(eventNames.clickedMatchFaq);
//   }
//   getStarted = (e) => {
//     this.props.history.push({
//       pathname: '/match/termsAndConditions'
//     })
//     intercomTrackEvent(eventNames.clickedGetStartedOnMatch);
//   }
//   render() {
//     const { employer, customer } = this.state;
//     const planDetails = customer ? customer.attributes.plan_details : "";

//     let host = window.location.host;
//     let parts = host.split(".");
//     let subdomain = "";
//     if (parts.length >= 1) {
//       subdomain = parts[0];
//     }
//     return (
//       <>
//         <Header hideNotificationAndProfileDropdown={true} />
//         <div className="G-page-background-color">
//           <div className="G-page-main-container my-5">
//             <div className="G-grid11-2 bg-white p-4 Gborder-05 rounded-lg mob-reverse-1200">
//               <div>
//                 <h3 className="Gfs-225 mb-4 Gt-slate-5">
//                   Hey
//                   {' '}
//                   <span className="G-text-grad">{localStorage.getItem("customerFullName").split(' ')[0]}!</span>
//                 </h3>
//                 <div>
//                   <p className="sans-medium Gfs-125">
//                     Your employer has partnered with Vault to help you find the best path to freedom from student debt.
//                   </p>
//                   <p className="sans-semibold Gfs-125">
//                     Create an account and lower your payments, reduce your interest rates, and more.
//                   </p>
//                   <p className="sans-medium Gfs-125">
//                     The average Vault user reduces their loan balance by over $6,500 and their payoff time by over 6 years. Let's go!
//                   </p>
//                   <div className='d-flex flex-wrap'>
//                     {/* <button onClick={(e) => this.goToWelcomeAbout(e)} className='G-orange-button'>Get Started</button> */}
//                     <button onClick={this.getStarted.bind(this)} className='G-orange-button mr-3'>Yes! Let's go!</button>
//                     <Link to="/login" className='my-3 Gt-pink-8 text-decoration-none cancelLink'>
//                       I already have an account. I'll sign in <ChevronRight />
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div className="introAdvisorBannerHeight my-auto rounded-lg tuitionIntroBannerBackground">
//                 <MatchLogo />
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* <div className={`${styles.containerMatchBackground}`}>
//                     <div className={styles.containerWidth}>
//                         <div className={`custom-content-box ${styles.containerCustomHeight} ${styles.containerPaleOrangeBackground} ${styles.matchInnerContentBackground}`}>

//                             <div className="headingWelcome">
//                                 <h6>Great news!</h6>
//                                 <h2 className="g-bold-font">
//                                     {employer ? employer.attributes.name : ""} is going to help you save <br />
//                                     for retirement.
//                                 </h2>
//                                 <h4 className={`${styles.welcomeText} ${styles.labelTextDarkTeal} g-min-width-30`}>
//                                     Welcome to Match, {localStorage.getItem("customerFullName").split(' ')[0]}!
//                                 </h4>
//                             </div>
//                             <Row className={`ml-0 mt-5`}>
//                                 <Col xs={8} className={`${styles.columnContainer} ${styles.columnBorderRightBottom}`}>
//                                     <div className={`${styles.containerWhiteBg} padding-16`}>
//                                         <div className={styles.orangeStar}></div>
//                                         <h6 className={`${styles.labelTextDarkTeal} mt-2`}>
//                                             Let's Get Started!
//                                         </h6>
//                                         <p className={`mb-4`}>
//                                             Vault&trade; Match tracks your monthly student loan payments so that you can receive matching {customer ? customer.attributes.match_plan_type : ""} contributions from {employer ? employer.attributes.name : ""}. As you make timely student loan payments, {employer ? employer.attributes.name : ""} makes a matching {customer ? customer.attributes.match_plan_type : ''} contribution up to {customer ? customer.attributes.match_percentage : 0}% of your salary.
//                                             {customer ?
//                                                 <> See the full details of your matching plan <Link to="#" onClick={() => { intercomTrackEvent(eventNames.clickedSeePlanDetailsMatchOverview); this.setState({ show: true }) }} className="linkColour">here</Link>.</>
//                                                 : null}
//                                         </p>
//                                         <p className={`mb-4`}>
//                                             Pay down your student loans while saving for retirement.
//                                         </p>
//                                         <hr className={`sepration`} />
//                                         <p className={`mb-4`}>You'll need <strong>one</strong> of the following to get started:</p>
//                                         <ul className={`list-custom`}>
//                                             <li>Your loan provider login information</li>
//                                             <li>An account statement with your payment history</li>
//                                         </ul>
//                                         <p className={`mb-4`}>
//                                             Once your Match account is active, you'll be able to view and download your payment history from your account dashboard.
//                                         </p>
//                                         <div className={`btn-submit text-center`}>
//                                             <Link
//                                                 to="#"
//                                                 onClick={this.getStarted.bind(this)}
//                                                 className="c-button c-button-wide d-inline-b btn-orange"
//                                             >
//                                                 Get Started
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </Col>
//                                 <Col className={`${styles.columnContainer} ${styles.columnBorderRightBottom} ${styles.heightRow200}`}>
//                                     <div className={`${styles.containerWhiteBg} padding-16`}>
//                                         <MatchGetHelp />
//                                     </div>
//                                 </Col>
//                             </Row>
//                         </div>

//                         <div className={`${styles.vaultMatchLogo} navbar-brand mb-5`} >
//                             {subdomain === 'johnhancock' ?
//                                 <img src={JohnHancockLogo} alt="check" />
//                                 :
//                                 <svg viewBox="-30.529 44.829 124.233 50.455" xmlns="http://www.w3.org/2000/svg">
//                                     <polygon className="st0" points="9.461 60.001 4.946 73.857 0.396 60.001 -3.554 60.001 3.022 79.66 6.851 79.66 13.409 60.001" style={{ fill: 'rgb(236, 126, 80)' }}></polygon>
//                                     <path className="st0" d="M 22.2 67.316 C 21.291 66.354 19.899 65.701 18.114 65.701 C 15.024 65.701 11.452 67.968 11.452 72.826 C 11.452 77.651 15.144 79.952 18.046 79.952 C 19.83 79.952 21.273 79.213 22.183 78.149 L 22.183 79.677 L 25.942 79.677 L 25.942 65.977 L 22.183 65.977 L 22.183 67.316 Z M 18.801 76.483 C 16.723 76.483 15.127 74.886 15.127 72.792 C 15.127 70.68 16.723 69.117 18.801 69.117 C 20.826 69.117 22.303 70.766 22.303 72.792 C 22.303 74.819 20.826 76.483 18.801 76.483 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M 36.793 73.444 C 36.793 75.745 35.608 76.724 34.184 76.724 C 32.518 76.724 31.659 75.659 31.659 73.822 L 31.659 65.977 L 27.849 65.977 L 27.849 74.32 C 27.849 77.856 29.788 79.935 33.017 79.935 C 34.578 79.935 35.884 79.248 36.793 78.149 L 36.793 79.66 L 40.519 79.66 L 40.519 65.977 L 36.76 65.977 L 36.76 73.444 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <rect x="42.459" y="60.001" className="st0" width="3.76" height="19.658" style={{ fill: 'rgb(236, 126, 80)' }}></rect>
//                                     <path className="st0" d="M 54.151 73.685 L 54.151 69.341 L 56.709 69.341 L 56.709 65.977 L 54.151 65.977 L 54.151 60.001 L 51.524 61.444 C 50.82 61.821 50.391 62.559 50.391 63.349 L 50.391 65.977 L 48.056 65.977 L 48.056 69.341 L 50.391 69.341 L 50.391 73.719 C 50.391 78.183 51.816 79.66 56.177 79.66 L 56.726 79.66 L 56.726 76.158 C 54.427 76.158 54.151 75.831 54.151 73.685 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -18.8 65.723 L -18.8 65.723 C -18.02 67.082 -16.069 67.082 -15.274 65.723 L -13.887 63.339 L -12.499 60.953 C -11.719 59.595 -12.701 57.904 -14.263 57.904 L -14.277 57.904 C -15 57.904 -15.679 58.295 -16.04 58.916 L -18.8 63.685 C -19.161 64.322 -19.161 65.087 -18.8 65.723 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -20.174 63.325 L -20.014 63.598 L -16.733 57.889 L -17.038 57.889 L -19.797 57.889 C -21.359 57.889 -22.341 59.581 -21.56 60.939 L -20.174 63.325 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -23.179 68.296 C -22.818 68.917 -22.139 69.307 -21.416 69.307 L -21.344 69.307 C -19.783 69.307 -18.8 67.617 -19.58 66.257 L -21.025 63.771 L -22.457 61.301 C -23.237 59.942 -25.187 59.942 -25.983 61.301 L -26.026 61.373 C -26.387 62.009 -26.387 62.775 -26.026 63.411 L -23.179 68.296 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -27.066 69.307 L -24.205 69.307 L -23.888 69.307 L -27.254 63.511 L -27.413 63.771 L -28.83 66.257 C -29.61 67.617 -28.642 69.307 -27.066 69.307 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -21.402 74.336 C -20.68 74.336 -19.999 73.947 -19.638 73.324 L -19.595 73.267 C -18.815 71.909 -19.797 70.218 -21.359 70.218 L -24.219 70.218 L -27.081 70.218 C -28.642 70.218 -29.625 71.909 -28.844 73.267 L -28.801 73.339 C -28.439 73.974 -27.76 74.351 -27.037 74.351 L -21.402 74.336 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -20.881 75.464 L -27.573 75.478 L -27.413 75.738 L -25.983 78.21 C -25.201 79.568 -23.251 79.568 -22.457 78.21 L -21.025 75.738 L -20.881 75.464 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -15.26 73.787 L -15.303 73.729 C -16.083 72.371 -18.035 72.371 -18.829 73.729 L -20.259 76.2 L -21.691 78.672 C -22.471 80.03 -21.487 81.722 -19.927 81.722 L -19.84 81.722 C -19.118 81.722 -18.439 81.331 -18.078 80.71 L -15.26 75.825 C -14.898 75.189 -14.898 74.424 -15.26 73.787 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -13.873 76.2 L -14.03 75.926 L -17.369 81.736 L -17.067 81.736 L -14.204 81.736 C -12.644 81.736 -11.661 80.045 -12.441 78.687 L -13.873 76.2 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -10.909 71.244 C -11.271 70.622 -11.95 70.233 -12.672 70.233 L -12.746 70.233 C -14.306 70.233 -15.288 71.923 -14.508 73.281 L -13.077 75.753 L -11.647 78.224 C -10.867 79.582 -8.915 79.582 -8.12 78.224 L -8.077 78.152 C -7.716 77.515 -7.716 76.749 -8.077 76.114 L -10.909 71.244 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -7.022 70.233 L -9.883 70.233 L -10.202 70.233 L -6.848 76.027 L -6.689 75.767 L -5.259 73.295 C -4.479 71.923 -5.461 70.233 -7.022 70.233 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -5.288 66.2 C -5.649 65.564 -6.328 65.188 -7.051 65.188 L -12.687 65.188 C -13.409 65.188 -14.088 65.578 -14.451 66.2 L -14.494 66.257 C -15.274 67.617 -14.292 69.307 -12.73 69.307 L -9.869 69.307 L -7.008 69.307 C -5.447 69.307 -4.464 67.617 -5.244 66.257 L -5.288 66.2 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                     <path className="st0" d="M -13.221 64.06 L -6.531 64.046 L -6.689 63.786 L -8.12 61.315 C -8.901 59.956 -10.852 59.956 -11.647 61.315 L -13.077 63.786 L -13.221 64.06 Z" style={{ fill: 'rgb(236, 126, 80)' }}></path>
//                                 </svg>
//                             }
//                         </div>
//                     </div>
//                 </div>
//                 <Modal size="lg" show={this.state.show} onHide={() => this.setState({ show: false })} centered animation={false} backdrop="static" keyboard={false} scrollable={true}>
//                     <Modal.Header className="bg-Valut color-white">
//                         <Modal.Title>
//                             Plan Details
//                             <button
//                                 className={styles.closeButton}
//                                 onClick={() => {
//                                     this.setState({ show: false })
//                                 }}
//                             >
//                                 <CloseIcon />
//                             </button>
//                         </Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body className="legal-body">
//                         <div className="match__plan-details-container btn-close btn-close-white" dangerouslySetInnerHTML={{ __html: planDetails }}>
//                         </div>
//                     </Modal.Body>
//                 </Modal> */}
//         <Footer />
//       </>
//     )
//   }
// }

const mapStateToProps = (state) => {
  return {
    customer: state.customer_array.customerData,
    employer: state.employer_array.employerData
  };
}
const mapDispatchToProps = {
  fetchCustomer,
  fetchEmployer
}
export default connect(mapStateToProps, mapDispatchToProps)(Overview);