import React, { useEffect, useState } from 'react';
import S from './Footer.module.css'
import PartnerLogo from '../../shared/PartnerLogo/PartnerLogo';
import { connect, useSelector } from 'react-redux';

const links = [
  {
    link: 'https://get.vault.co/terms-of-use/',
    name: 'Terms of Use',
    slash: true,
  },
  {
    link: 'https://get.vault.co/privacy-policy/',
    name: 'Privacy Policy',
    slash: true,
  },
  {
    link: 'mailto:genius@vault.co',
    name: 'Contact Us',
    slash: false,
  }
]

const Footer = ({ removeMarginTop = false }) => {
  const { partnerData } = useSelector((state) => state.authentication)
  const [partnerLogo, setPartnerLogo] = useState('');

  useEffect(() => {
    if(!!partnerData?.data?.attributes) {
      setPartnerLogo(partnerData?.data?.attributes?.logo);
    }
  }, [partnerData])

  return (
    <>
      <footer className={`appFooter py-5 ${removeMarginTop && 'mt-0'}`}>
        <div className="container">
          <div className="d-flex justify-content-center">
          {partnerLogo ? 
            <PartnerLogo src={partnerLogo} width="50px" /> : 
            <svg width="138" height="42" viewBox="0 0 138 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M66.331 12.7291L59.4728 33.0757L52.5742 12.7291H46.594L56.5677 41.5982H62.382L72.3112 12.7291H66.331Z" fill="white" />
              <path d="M80.4884 36.9335C79.033 36.8955 77.6504 36.3093 76.6348 35.2994C75.6193 34.2896 75.0511 32.9359 75.0511 31.5266C75.0511 30.1173 75.6193 28.7637 76.6348 27.7539C77.6504 26.744 79.033 26.1577 80.4884 26.1198C83.5635 26.1198 85.805 28.5324 85.805 31.5051C85.805 34.4778 83.5635 36.9335 80.4884 36.9335ZM85.6391 23.4683C84.2675 22.0583 82.1514 21.0948 79.4526 21.0948C74.7591 21.0948 69.3575 24.4318 69.3575 31.5482C69.3575 38.6646 74.9654 41.9976 79.3676 41.9976C80.5496 42.0259 81.7237 41.8032 82.8072 41.3451C83.8907 40.887 84.8574 40.2047 85.6391 39.3461V41.5981H91.328V21.4983H85.6391V23.4683Z" fill="white" />
              <path d="M107.775 32.4725C107.775 35.8486 105.987 37.2977 103.826 37.2977C101.294 37.2977 100.007 35.7311 100.007 33.0326V21.4983H94.2331V33.7571C94.2331 38.9427 97.1827 41.9819 102.083 41.9819C103.18 41.9922 104.266 41.7623 105.259 41.3092C106.252 40.8562 107.126 40.1918 107.816 39.3657V41.5785H113.481V21.4983H107.775V32.4725Z" fill="white" />
              <path d="M122.058 12.733H116.369V41.5981H122.058V12.733Z" fill="white" />
              <path d="M134.108 32.8329V26.441H138V21.4983H134.108V12.7291L130.134 14.8323C129.615 15.1084 129.182 15.5143 128.881 16.0075C128.579 16.5008 128.419 17.0634 128.419 17.6366V21.4983H124.891V26.441H128.419V32.872C128.419 39.4284 130.579 41.5982 137.183 41.5982H137.992V36.4518C134.504 36.4518 134.087 35.97 134.087 32.8329" fill="white" />
              <path d="M18.6028 13.6416C18.9249 14.1799 19.3875 14.6268 19.9443 14.9375C20.501 15.2481 21.1323 15.4117 21.775 15.4117C22.4176 15.4117 23.0489 15.2481 23.6057 14.9375C24.1625 14.6268 24.6251 14.1799 24.9471 13.6416L27.4476 9.47049L29.936 5.29935C30.2602 4.76073 30.431 4.14853 30.4311 3.52517C30.4311 2.9018 30.2604 2.28958 29.9363 1.75092C29.6122 1.21225 29.1463 0.766417 28.5861 0.458871C28.026 0.151325 27.3916 -0.00693656 26.7477 0.000233217V0.000233217C26.1041 -0.00173957 25.4715 0.161414 24.9143 0.473071C24.3571 0.784728 23.8951 1.23376 23.5755 1.77444L18.6149 10.1167C18.2953 10.6526 18.1271 11.2604 18.1271 11.8792C18.1271 12.4979 18.2953 13.1058 18.6149 13.6416" fill="white" />
              <path d="M16.1185 9.4705L16.3977 9.93658L22.3172 0.000244141H16.7982C16.1554 0.000563184 15.524 0.164675 14.9675 0.476082C14.411 0.787488 13.949 1.23521 13.6279 1.77426C13.3068 2.3133 13.138 2.92466 13.1384 3.54688C13.1388 4.16911 13.3083 4.78027 13.6301 5.31894L16.1185 9.4705Z" fill="white" />
              <path d="M10.6967 18.1182C11.0187 18.6557 11.4807 19.1021 12.0366 19.4127C12.5925 19.7233 13.2229 19.8874 13.8648 19.8885H14.0024C14.6452 19.8882 15.2766 19.7241 15.8331 19.4127C16.3896 19.1013 16.8516 18.6535 17.1727 18.1145C17.4938 17.5755 17.6626 16.9641 17.6622 16.3419C17.6618 15.7196 17.4922 15.1085 17.1705 14.5698L14.5931 10.2616L12.0198 5.95337C11.6977 5.41508 11.2351 4.9682 10.6783 4.65754C10.1216 4.34688 9.49024 4.18335 8.84761 4.18335C8.20497 4.18335 7.57363 4.34688 7.01687 4.65754C6.46012 4.9682 5.99751 5.41508 5.67543 5.95337L5.6026 6.07478C5.28047 6.61408 5.11084 7.22603 5.11084 7.84899C5.11084 8.47194 5.28047 9.0839 5.6026 9.62319L10.6967 18.1182Z" fill="white" />
              <path d="M3.69287 19.8886H9.4141L3.37324 9.79163L3.0981 10.2538L0.520722 14.562C0.200093 15.1011 0.0315204 15.7124 0.0319221 16.3345C0.0323238 16.9566 0.201669 17.5677 0.522994 18.1064C0.844319 18.6452 1.30632 19.0927 1.86268 19.4041C2.41904 19.7155 3.0502 19.8799 3.69287 19.8807" fill="white" />
              <path d="M13.9215 28.6773C14.5632 28.6783 15.1939 28.5151 15.7495 28.2042C16.3052 27.8933 16.7661 27.4458 17.0855 26.907L17.1543 26.7934C17.4819 26.2556 17.6559 25.6432 17.6588 25.0189C17.6616 24.3945 17.4931 23.7807 17.1704 23.2401C16.8477 22.6995 16.3825 22.2515 15.8223 21.942C15.2622 21.6325 14.6271 21.4727 13.9821 21.4787H3.67667C3.0317 21.4727 2.39665 21.6325 1.83647 21.942C1.27629 22.2515 0.811079 22.6995 0.488415 23.2401C0.165752 23.7807 -0.00279094 24.3945 3.49595e-05 25.0189C0.00286086 25.6432 0.176947 26.2556 0.504492 26.7934L0.577344 26.9188C0.899727 27.4577 1.36293 27.905 1.92046 28.2158C2.47799 28.5265 3.1102 28.6897 3.75354 28.6891L13.9215 28.6773Z" fill="white" />
              <path d="M14.8602 30.6395H2.8027L3.08189 31.1017L5.65522 35.4099C5.97224 35.9542 6.43306 36.4073 6.99046 36.7225C7.54787 37.0377 8.18181 37.2038 8.82737 37.2038C9.47293 37.2038 10.1069 37.0377 10.6643 36.7225C11.2217 36.4073 11.6825 35.9542 11.9995 35.4099L14.5769 31.1017L14.8602 30.6395Z" fill="white" />
              <path d="M24.9755 27.7178L24.9107 27.6042C24.5886 27.0659 24.126 26.6191 23.5693 26.3084C23.0125 25.9978 22.3812 25.8342 21.7386 25.8342C21.0959 25.8342 20.4646 25.9978 19.9078 26.3084C19.3511 26.6191 18.8885 27.0659 18.5664 27.6042L15.989 31.9125L13.4116 36.2207C13.091 36.7598 12.9224 37.3711 12.9228 37.9932C12.9232 38.6153 13.0926 39.2264 13.4139 39.7651C13.7352 40.3039 14.1972 40.7514 14.7536 41.0628C15.31 41.3742 15.9411 41.5386 16.5838 41.5394H16.7335C17.377 41.5386 18.0088 41.3738 18.5657 41.0617C19.1225 40.7496 19.5846 40.3011 19.9057 39.7613L24.9795 31.2349C25.2991 30.6991 25.4674 30.0912 25.4674 29.4724C25.4674 28.8537 25.2991 28.2458 24.9795 27.71" fill="white" />
              <path d="M27.4881 31.9241L27.2009 31.4502L21.1883 41.5628H26.8934C27.5386 41.5685 28.1738 41.4082 28.734 41.0982C29.2942 40.7883 29.7592 40.3398 30.0815 39.7987C30.4038 39.2576 30.5718 38.6433 30.5683 38.0188C30.5648 37.3942 30.3899 36.7817 30.0615 36.2441L27.4881 31.9241Z" fill="white" />
              <path d="M32.8169 23.2764C32.4942 22.7386 32.0315 22.2921 31.4749 21.9815C30.9183 21.6708 30.2872 21.5069 29.6447 21.5061H29.5112C28.8684 21.5064 28.237 21.6705 27.6805 21.982C27.124 22.2934 26.6619 22.7411 26.3409 23.2801C26.0198 23.8192 25.8509 24.4305 25.8513 25.0528C25.8517 25.675 26.0213 26.2861 26.3431 26.8248L28.9164 31.133L31.4938 35.4413C31.8159 35.9796 32.2785 36.4264 32.8352 36.7371C33.392 37.0478 34.0233 37.2113 34.666 37.2113C35.3086 37.2113 35.9399 37.0478 36.4967 36.7371C37.0534 36.4264 37.516 35.9796 37.8381 35.4413L37.9109 35.3198C38.2351 34.7812 38.4059 34.169 38.4059 33.5457C38.4059 32.9223 38.2351 32.3101 37.9109 31.7714L32.8169 23.2764Z" fill="white" />
              <path d="M39.8207 21.5061H34.0995L40.1404 31.6069L40.4155 31.1448L42.9888 26.8366C43.3106 26.2979 43.4802 25.6867 43.4806 25.0645C43.481 24.4423 43.3121 23.8309 42.991 23.2919C42.67 22.7528 42.2079 22.3051 41.6514 21.9937C41.0949 21.6823 40.4635 21.5182 39.8207 21.5179" fill="white" />
              <path d="M42.9363 14.4915C42.6139 13.9526 42.1506 13.5053 41.5931 13.1945C41.0356 12.8838 40.4034 12.7205 39.76 12.7212H29.5921C28.9503 12.7202 28.3197 12.8834 27.764 13.1943C27.2084 13.5052 26.7475 13.9527 26.428 14.4915L26.3593 14.6051C26.0317 15.1429 25.8576 15.7553 25.8548 16.3797C25.852 17.004 26.0205 17.6179 26.3432 18.1585C26.6658 18.6991 27.1311 19.147 27.6912 19.4565C28.2514 19.766 28.8865 19.9259 29.5314 19.9198H39.8369C40.4819 19.9259 41.1169 19.766 41.6771 19.4565C42.2373 19.147 42.7025 18.6991 43.0252 18.1585C43.3478 17.6179 43.5164 17.004 43.5135 16.3797C43.5107 15.7553 43.3366 15.1429 43.0091 14.6051L42.9363 14.4915Z" fill="white" />
              <path d="M28.6615 10.7551H40.7149L40.4398 10.293L37.8624 5.98475C37.5451 5.44134 37.0844 4.98924 36.5274 4.67467C35.9704 4.3601 35.3372 4.19434 34.6923 4.19434C34.0474 4.19434 33.4141 4.3601 32.8571 4.67467C32.3002 4.98924 31.8395 5.44134 31.5221 5.98475L28.9448 10.293L28.6615 10.7551Z" fill="white" />
            </svg>  
            }
            
          </div>
          <div className="my-4">
            <ul className="nav justify-content-center links">
              {links.map((x, index) => (
                <React.Fragment key={index}>
                  <li className="nav-item">
                    <a className={`Gt-orange-5 text-decoration-none ${S.footerLink}`} href={x.link}>
                      {x.name}
                    </a>
                  </li>
                  {x.slash &&
                    <span className="slash">/</span>
                  }
                </React.Fragment>
              ))}
            </ul>
          </div>
          <p className="mt-4 text-white Gfs-087 text-center mb-0">
            This website is made available for general and educational purposes only and is not intended to constitute legal, tax, accounting or investment advice. Investing in securities involves risk of loss, and past performance is not indicative of or a guarantee of future returns. You should consult with your financial, tax or other advisor before making any investment decision. Vault expressly disclaims any liability or loss incurred by any person who relies on any of the information contained on this website. © Vault {(new Date()).getFullYear()}
          </p>
        </div>
      </footer>
    </>
  )
}

const mapStateToProps = (state) =>({
  partnerData: state.authentication.partnerData,
})

export default connect(mapStateToProps)(Footer);