import React, { useEffect } from "react";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { ChoosePlanBestForMeBreadcrumb, } from "../CommonBreadcrumb/Breadcrumb.constant";
import ImageComponent from "../../shared/GridImageComponent/ImageComponent";
import imageData from "./imageData";


const ChoosePlanBestForMe = () => {
  useEffect(() => {
    document.title = "Vault | How do I choose the 529 Plan that is best for me?"
  })

  return (
    <>
      <Header />
      <div className="G-page-background-color">
        <div className="G-page-main-container  my-5 ">
          <Breadcrumb list={ChoosePlanBestForMeBreadcrumb} />
          <div className="bg-white Gborder-1 rounded-lg p-4">
            <div className="pinkCircularDiv mb-3">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                <path d="M30 17.5C23.1 17.5 17.5 23.1 17.5 30C17.5 36.9 23.1 42.5 30 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 30 17.5ZM30 40C24.4875 40 20 35.5125 20 30C20 24.4875 24.4875 20 30 20C35.5125 20 40 24.4875 40 30C40 35.5125 35.5125 40 30 40ZM35.7375 24.475L27.5 32.7125L24.2625 29.4875L22.5 31.25L27.5 36.25L37.5 26.25L35.7375 24.475Z" fill="var(--icon-color)" />
              </svg>
            </div>
            <h4>How do I choose the 529 Plan that is best for me?</h4>
            <p className="sans-medium">
              Hey there, no worries! A 529 plan is a tax-advantaged savings plan designed to encourage saving for future education expenses. 529 plans are a smart move to save for education expenses with some fantastic tax benefits. Choosing the right 529 plan requires careful consideration of your individual financial situation, education goals, risk tolerance, and preferences. Here's a step-by-step guide to help you determine the 529 plan that best fits your needs:
            </p>
            {imageData.map((item, index) => (
              <ImageComponent key={item.id} index={index + 1} id={item.id} imageUrl={item.url} mainTitle={item.title} mainContext={item.description} />
            ))}
            <h4>
              Now you're ready to go!
            </h4>
            <p className="sans-medium mb-0">
              Take your time, weigh your options, and find a 529 plan that aligns with your aspirations. Happy saving! 🌟
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );

}

export default ChoosePlanBestForMe;