import PreviousFileList from '../shared/PreviousFileList/PreviousFileList';
import PlusCircle from 'react-bootstrap-icons/dist/icons/plus-circle';
import DashCircle from 'react-bootstrap-icons/dist/icons/dash-circle';
import { formatDateUsingMoment, monthDateYearSlash } from './../../utils/dateUtil';
import { numberFormat } from "../../common/js/jsFunctions";


const TuitionDetailsAccordion = ({tuitionData}) => {
    return ( 
        <>
        {tuitionData ? 
         <div className='rounded-lg Gborder-1 p-3 my-4'>
            <div className='d-flex justify-content-between align-items-baseline G-pointer' title='Toggle Expansion'  aria-expanded="false"  data-toggle='collapse' href="#collapseTuitionDetails">
            <p className='Gfs-125 sans-semibold mb-0'>Tuition Details</p>
            <div>
                <span role='button' className='chevron-circle-up  Gfs-175'><PlusCircle size={20}/></span> <span role='button' className='chevron-circle-down Gfs-175'><DashCircle size={20} /></span>
            </div>
            </div>
            <div id='collapseTuitionDetails' className='collapse' >
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>School/Institution Name</p>
            <p className='Gfs-125 sans-medium'>{tuitionData.school_name}</p>
            </div>
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Course Name</p>
            <p className='Gfs-125 sans-medium'>{tuitionData.course_name}</p>
            </div>
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Description</p>
            <p className='Gfs-125 sans-medium'>{tuitionData.description}</p>
            </div>
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Reimbursement Amount</p>
            <p className='Gfs-125 sans-medium'>{numberFormat(tuitionData.reimburse_amount/100)}</p>
            </div>
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Course Start Date</p>
            <p className='Gfs-125 sans-medium'>{formatDateUsingMoment(tuitionData.start_date, monthDateYearSlash)}</p>
            </div>
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Course Completion Date</p>
            <p className='Gfs-125 sans-medium'>{formatDateUsingMoment(tuitionData.completion_date, monthDateYearSlash)}</p>
            </div>

            {tuitionData?.receipts ? 
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Receipts</p>
                <PreviousFileList fileList={tuitionData.receipts} />
            </div> : ''}
            

            {tuitionData?.proofs?
            <div className='my-3'>
            <p className='Gt-pink-8 text-uppercase mb-0 sans-semibold Gfs-087 G-ls-05'>Proof of Completion</p>
                <PreviousFileList fileList={tuitionData.proofs} />
            </div> : '' }

           
            </div>
         </div> : ''}
        </>
     );
}
 
export default TuitionDetailsAccordion;