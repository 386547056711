import React from 'react';
import { numberFormat } from '../../../common/js/jsFunctions';
import InfoCircleFill from 'react-bootstrap-icons/dist/icons/info-circle-fill';

const LifeTimeCapScore = (props) => {
  return (
    <>
      {props.customer ?
        props.customer.attributes.lifetime_cap ?
          <div className=" mt-4 py-3 Gb-pink-2 pl-3 rounded-lg sans-semibold">
            <InfoCircleFill size={20} className="Gt-pink-8 mr-2" />
            Lifetime Contributions: {numberFormat(props.customer?.attributes?.lifetime_cap?.customer)} / {numberFormat(props.customer?.attributes?.lifetime_cap?.employer)} received.
          </div>
          : null
        : null}
    </>
  )
}
export default LifeTimeCapScore;
