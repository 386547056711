export const tuitionReimburseBreadcrumbData = () => {
    return[{
        title: 'Dashboard',
        active: false,
        url:'/tuition-reimbursement-history'
      },{
        title: 'Reimbursement Request Status',
        active: true,
        url:'#'
      }]
}


export const tuitionReimburseStatus = {
  pending:'Pending',
  approved:'Approved',
  rejected:'Rejected',
  needMoreInfo:'Need More Info',
  needMoreInfoSnake:'need_more_info'
}

export const tuitionConstants = {
  receipts:"receipts",
  proofOfCompletion:"proof_of_completions",
  servicerAccounts:"servicer_accounts"
}

export const setTuitionReimburseStatus = (status) => {
  if (status.toUpperCase() === tuitionReimburseStatus.pending.toUpperCase()) {
    return tuitionReimburseStatus.pending;
  }
  if (status.toUpperCase() === tuitionReimburseStatus.approved.toUpperCase()) {
    return tuitionReimburseStatus.approved;
  }
  if (status.toUpperCase() === tuitionReimburseStatus.rejected.toUpperCase()) {
    return tuitionReimburseStatus.rejected;
  }
  if (status.toUpperCase() === tuitionReimburseStatus.needMoreInfoSnake.toUpperCase()) {
    return tuitionReimburseStatus.needMoreInfo;
  }
  return '-';
}

export const setTuitionDetailsAccordionData = (tuitionData) => {
  return {
    school_name:tuitionData.data.attributes.school_name,
    course_name:tuitionData.data.attributes.course_name,
    description:tuitionData.data.attributes.description,
    reimburse_amount:tuitionData.data.attributes.amount_cents,
    start_date:tuitionData.data.attributes.start_date,
    completion_date:tuitionData.data.attributes.completion_date,
    receipts:tuitionData.included.length > 0 ? filterDocumentByType(tuitionData.included,tuitionConstants.receipts) : null,
    proofs:tuitionData.included.length > 0 ? filterDocumentByType(tuitionData.included,tuitionConstants.proofOfCompletion): null
  }
}

export const setBasicDetailsAccordionData = (basicData) => {
    return {
      first_name:getCustomerNameFromStorage(),
      last_name:getCustomerNameFromStorage(false),
      title:basicData.attributes.title,
      department:basicData.attributes.department,
      education_expense:basicData.attributes.education_expense_name,
      notes_manager:basicData.attributes.notes_manager
    }
}

export const setBankDetailsAccordionData = (bankData, addressData) => {
  if(bankData && addressData) {
    return {
      bank_name: bankData.attributes.bank_name,
      account_number:bankData.attributes.account_number,
      routing_number:bankData.attributes.routing_number,
      street_1: addressData.attributes.street_1,
      street_2: addressData.attributes.street_2,
      city:addressData.attributes.city,
      state:addressData.attributes.state,
      zip_code:addressData.attributes.zip_code
      
    }
  }
  return null
}

export const filterDocumentByType = (docArray, type) => {
  return docArray.filter(
    (doc) => doc.type === type
  );
}

export const getCustomerNameFromStorage = (firstName = true) => {
  let customerName = localStorage.getItem('customerFullName');
  if(customerName) {
    let nameArr = customerName.split(' ');
    return firstName ? nameArr[0] : nameArr[nameArr.length-1]
  } 
  return '';
}