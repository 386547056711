import NumberFormat from "react-number-format";
const NumberFormatInput = ({label, value='', name='', placeholder='', handleValueChange, suffix='', prefix='$', decimalScale = 2, defaultValue='', max='', min=''}) => {
    return ( 
        <div>
        <label className="sans-medium">{label}</label>
        <NumberFormat
            thousandsGroupStyle="thousand"
            value={value}
            prefix={`${prefix} `}
            suffix={` ${suffix}`}
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={decimalScale}
            fixedDecimalScale={true} 
            className="rounded-lg text-left w-100 p-2 Gborder-1"
            name={name}
            minLength={min}
            maxLength={max}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onValueChange={(values) =>{ handleValueChange(values) }}  
            />
        </div>
     );
}
export default NumberFormatInput;