import { onFinish, onError, onSuccess } from './finicityHandlers'

export default function finicityConnect(finicityConnectSrc, clearFinicityState, onSubmitFinicity, id) {
  if (finicityConnectSrc !== null) {
    window.finicityConnect.destroy();
    window.finicityConnect.launch(finicityConnectSrc, {
      selector: '#connect-container',
      overlay: 'rgba(255,255,255, 0)',
      success: (event) => {
        onFinish(clearFinicityState);
        onSuccess();
        onSubmitFinicity(id); //calls API on success
      },
      cancel: () => {
        onFinish(clearFinicityState);
      },
      error: (error) => {
        onFinish(clearFinicityState);
        onError(error);
      },
      loaded: () => {
        // This gets called only once after the iframe has finished loading
      },
      route: (/*event*/) => {
        // This is called as the user navigates through Connect
      },
      user: (/*event*/) => {
        // This is called as the user interacts with Connect
        clearFinicityState(); //stops the spinner when iFrame openes
      }
    });
  }
}
