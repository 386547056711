import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateSelectedService } from '../../actions/fiveTwoNineActions';
import { updateStatusUrl } from '../../actions/customerActions';
import { Redirect } from 'react-router';

class SwitchToAdvisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            setRedirect: false,
        }
    }
    componentDidMount() {
        document.title = "Vault"
        this.updateSelectedService('savings_plan')
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.updateServiceResponse !== this.props.updateServiceResponse) {
            // window.location.href = '/token/' + localStorage.getItem('userToken');

            const selectedService = 'savings_plan';
            const partnerName = localStorage.getItem('partnerName')
            const hasAdvisorAndSavingsPlan = JSON.parse(localStorage.getItem('hasAdvisorAndSavingsPlan'))
            const hasAdvisorAndPayOrMatch = JSON.parse(localStorage.getItem('hasAdvisorAndPayOrMatch'))
            const hasAdvisor = JSON.parse(localStorage.getItem('hasAdvisor'))
            const hasSavingsPlan = JSON.parse(localStorage.getItem('hasSavingsPlan'))
            const hasTuitionPlan = JSON.parse(localStorage.getItem('hasTuitionPlan'))
            const hasPayOrMatch = JSON.parse(localStorage.getItem('hasPayOrMatch'))

            const hasSavingsPlanOnly = (hasSavingsPlan === true && hasAdvisor === false && hasTuitionPlan === false && hasPayOrMatch === false) ? true : false;
            const hasAdvisorOnly = (hasAdvisor === true && hasSavingsPlan === false) ? true : false;

            const newAdvisorEnabledPruAccount = (hasAdvisorOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === null))  ? true : false; // for new pru accounts that have the selected-service as null
            const advisorEnabled = (hasAdvisorOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'advisor') || hasAdvisorAndPayOrMatch === true) ? true : false;
            const savingsPlanEnabled = ((hasSavingsPlanOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'savings_plan')) && partnerName === 'Vault');
            const pruSavingsPlanEnabled = ((hasSavingsPlanOnly === true || (hasAdvisorAndSavingsPlan === true && selectedService === 'savings_plan')) && (!!partnerName.includes('Prudential')));

            localStorage.setItem("selectedService", selectedService);
            localStorage.setItem("newAdvisorEnabledPruAccount", newAdvisorEnabledPruAccount);
            localStorage.setItem("advisorEnabled", advisorEnabled);
            localStorage.setItem("savingsPlanEnabled", savingsPlanEnabled);
            localStorage.setItem("pruSavingsPlanEnabled", pruSavingsPlanEnabled);

            this.setState({ setRedirect: true })
        }
    }
    updateSelectedService(selectedService) {
        const { updateStatusUrl, updateSelectedService } = this.props;
        const requestData = {
            "data": {
                "attributes": {
                    "status_url": '/master-dashboard'
                }
            }
        }
        updateStatusUrl(requestData);
        const request = {
            "data": {
                "attributes": {
                    "selected_service": selectedService
                }
            }
        }
        updateSelectedService(request);
    }
    render() {
        const { setRedirect } = this.state;
        if (setRedirect) {
            return (
                <Redirect to='/master-dashboard' />
            )
        }
        return (
            <>

            </>
        )
    }
}
SwitchToAdvisor.propTypes = {
    updateSelectedService: PropTypes.func.isRequired,
    updateStatusUrl: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    updateServiceResponse: state.five_two_nine.updateServiceResponse,
    updateStatusUrlResponse: state.customer_array.updateStatusUrlResponse,
    errors: state.errors
});
export default connect(mapStateToProps, { updateSelectedService, updateStatusUrl })(SwitchToAdvisor);
