import React, { Component } from 'react';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import FiveTwoNineAgreement from '../../TermsAndConditions/FiveTwoNineAgreement/FiveTwoNineAgreement';

class About extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.title = "Vault | About";
   
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="container-content">
          <div className="container-custom">
            <FiveTwoNineAgreement onCloseButtonClick={() => this.props.history.push("/intro/plan-savings")} onAcceptButtonClickSuccess={() => this.props.history.push("/welcome/esign")} alreadyAcceptedRoute={() => this.props.history.push("/welcome/esign")} />
           
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default About;