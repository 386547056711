import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import ValidatorMessage from '../shared/ValidatorMessage/ValidatorMessage'
import { CheckCircle, ChevronRight, ExclamationCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import Header from '../Includes/Header/Header';
import Footer from '../Includes/Footer/Footer';
import style from './Registration.module.css'
import SimpleReactValidator from 'simple-react-validator';
import SignInVaultLogo from '../shared/VaultLogo/SignInVaultLogo';
import { useEffect } from 'react';
import RegistrationCarousel from './../shared/RegistrationCarousel/RegistrationCarousel';
import PartnerLogo from '../shared/PartnerLogo/PartnerLogo';
import { useSelector } from 'react-redux';

const RegistrationPage = ({ state, setState, registrateUser }) => {
  const validator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [accountType, setAccountType] = useState(null);
  const [partnerLogo, setPartnerLogo] = useState(null);
  const { partnerData} = useSelector(state => state.authentication)

  useEffect(() => {
    if (state.fetchInvitation) {
      const hasPay = state.fetchInvitation.has_contribution;
      const hasMatch = state.fetchInvitation.has_match;
      const hasAdvisor = state.fetchInvitation.has_advisor;
      const hasSavingsPlan = state.fetchInvitation.has_savings_plan;
      const hasTuitionPlan = state.fetchInvitation.has_tuition_plan;
      if (hasPay) setAccountType('pay');
      else if (hasMatch) setAccountType('match');
      else if (hasSavingsPlan) setAccountType('fiveTwoNine')
      else if (hasTuitionPlan) setAccountType('tuition')
      else if (hasAdvisor) setAccountType('advisor')
    }
  }, [state.fetchInvitation])

  useEffect(() => {
    if(partnerData && partnerData.data){
    setPartnerLogo(partnerData.data?.attributes?.logo);
    }
  },[partnerData])

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  function _onFocus() {
    setState({
      ...state,
      passwordComplexity: true,
    });
  }

  const handlePasswordChange = (e) => {
    var format = /[-_\\.\][?/<~#`!@$%^&*()+=}|:";',>{]/;

    const password = e.target.value;
    let character = false;
    let number = false;
    let uppercase = false;
    let lowercase = false;
    let special = false;
    let passwordValidation = false;

    if (password.length >= 10) {
      character = true;
    }
    if (password.search(/\d/) !== -1) {
      number = true;
    }
    if (password.search(/[A-Z]/) !== -1) {
      uppercase = true;
    }
    if (password.search(/[a-z]/) !== -1) {
      lowercase = true;
    }
    if (password.search(format) !== -1) {
      special = true;
    }

    if (character === true && number === true && uppercase === true && lowercase === true && special === true) {
      passwordValidation = true;
    }

    setState({
      ...state,
      password,
      characterValid: character,
      numberValid: number,
      uppercaseValid: uppercase,
      lowercaseValid: lowercase,
      specialValid: special,
      passwordValidation: passwordValidation
    })
  }

  function showValidationMessage(event) {
    if (validator.current.fieldValid(event.target.name)) {
      validator.current.hideMessageFor(event.target.name);
      forceUpdate(1)
      setState({
        ...state,
        validationError: {
          ...state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      validator.current.showMessageFor(event.target.name);
      forceUpdate(1)
      setState({
        ...state,
        validationError: {
          ...state.validationError,
          [event.target.name]: true
        }
      });
    }
  }

  const handleRegistrate = (e) => {
    if (validator.current.allValid() && state.passwordValidation === true) {
      const { name, password, password_confirmation, invitation_token } = state;
      const jsonRequest = {
        name: name,
        password: password,
        password_confirmation: password_confirmation,
        invitation_token: invitation_token,
        metadata: null
      }
      registrateUser(jsonRequest);
    } else {
      validator.current.showMessages();
    }
  }

  const {
    passwordComplexity,
    characterValid,
    numberValid,
    uppercaseValid,
    lowercaseValid,
    specialValid,
  } = state;

  return (
    <>
      <Header />
      <div className="G-page-background-color">
        <div className="bg-white w-100 m-0">
          <div className='G-grid115'>
            <RegistrationCarousel accountType={accountType} />
            <div id='registrationSignUpPageFormContainer' className={'d-flex flex-column justify-content-center mx-auto ' + style.loginFormContainer}>
              <div className='my-3 mb-4'>
                {partnerLogo ? 
                <PartnerLogo src={partnerLogo} width="70px" /> :
                 <SignInVaultLogo /> }
              </div>

              <>
                <h4 className='mb-4'>Let's create your account!</h4>
                <Form.Group className='mb-4'>
                  <Form.Label className="">Full Name</Form.Label>
                  <Form.Control type="text" name='name' placeholder="Full Name" onChange={handleInputChange}
                    onBlur={(e) => showValidationMessage(e)} value={state.name} />
                  <ValidatorMessage validator={validator.current} fieldName='name' fieldValue={state.name} message="* Name can't be blank" />
                </Form.Group>
                <Form.Group className='mb-4'>
                  <Form.Label className="">Email</Form.Label>
                  <Form.Control type="text" name='email' placeholder="Email" onChange={handleInputChange}
                    onBlur={(e) => showValidationMessage(e)} value={state.email} />
                  <ValidatorMessage validator={validator.current} fieldName='email' fieldValue={state.email} message='* Email is required' />
                </Form.Group>
                <Form.Group className='mb-4'>
                  <Form.Label className="">Password</Form.Label>
                  <Form.Control type="password" name='password' placeholder="Password" onFocus={_onFocus}
                    onChange={handlePasswordChange}
                    onBlur={(e) => showValidationMessage(e)} value={state.password} />
                  <ValidatorMessage validator={validator.current} fieldName='password' fieldValue={state.password} message='* You must enter a valid password' />
                </Form.Group>
                {
                  passwordComplexity ?
                    <>
                      <p>Use these requirements to help keep your account safe</p>
                      <div className='d-flex'>
                        <div className={style.iconTopAdjust}>
                          {characterValid ?
                            <CheckCircle className='Gt-green-6' />
                            :
                            <ExclamationCircle className='Gt-yellow-6' />
                          }
                        </div>
                        <p className='mb-0 mx-2'>10 Characters</p>
                      </div>
                      <div className='d-flex'>
                        <div className={style.iconTopAdjust}>
                          {numberValid ?
                            <CheckCircle className='Gt-green-6' />
                            :
                            <ExclamationCircle className='Gt-yellow-6' />
                          }
                        </div>
                        <p className='mb-0 mx-2'>1 Number (0-9)</p>
                      </div>
                      <div className='d-flex'>
                        <div className={style.iconTopAdjust}>
                          {uppercaseValid ?
                            <CheckCircle className='Gt-green-6' />
                            :
                            <ExclamationCircle className='Gt-yellow-6' />
                          }
                        </div>
                        <p className='mb-0 mx-2'>1 Uppercase Letter (A-Z)</p>
                      </div>
                      <div className='d-flex'>
                        <div className={style.iconTopAdjust}>
                          {lowercaseValid ?
                            <CheckCircle className='Gt-green-6' />
                            :
                            <ExclamationCircle className='Gt-yellow-6' />
                          }
                        </div>
                        <p className='mb-0 mx-2'>1 Lowercase Letter (a-z)</p>
                      </div>
                      <div className='d-flex mb-4'>
                        <div className={style.iconTopAdjust}>
                          {specialValid ?
                            <CheckCircle className='Gt-green-6' />
                            :
                            <ExclamationCircle className='Gt-yellow-6' />
                          }
                        </div>
                        <p className='mb-0 mx-2'>1 Special Character (!, @, #, $, %, &, etc.)</p>
                      </div>
                    </> : null}

                <Form.Group className='mb-4'>
                  <Form.Label className="">Confirm Password</Form.Label>
                  <Form.Control type="password" name='password_confirmation' placeholder="Password" onChange={handleInputChange}
                    onBlur={(e) => showValidationMessage(e)} value={state.password_confirmation} />
                  {
                    validator.current.message('password_confirmation',
                      state.password_confirmation,
                      `required|in:${state.password}`,
                      {
                        messages: {
                          required: "* Confirm password can't be blank",
                          in: "* Password confirmation doesn't match Password"
                        }
                      })
                  }
                </Form.Group>


                <div className='my-2'>
                  <button onClick={handleRegistrate} className="bg-transparent border-0 G-orange-button ">
                    Create Account
                  </button>
                  <div className='my-3'>
                    <Link to="/login" className='G-link text-decoration-none cancelLink'>
                      I already have an account. I'll sign in <ChevronRight />
                    </Link>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      <Footer removeMarginTop={true} />
    </>
  )
};

export default RegistrationPage;