import React, { Component } from 'react';
import $ from 'jquery';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

import Header from "../Includes/Header/Header";
import Footer from "../Includes/Footer/Footer";
import showDtiRatioMessage from '../../utils/dtiCalcMessage'
import NumberFormatInput from '../shared/NumberFormatInput/NumberFormatInput';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import { dtiCalculatorBreadcrumb } from '../FiveToNine/CommonBreadcrumb/Breadcrumb.constant';
import { dtiCalculatorBreadcrumbTR } from './../FiveToNine/CommonBreadcrumb/Breadcrumb.constant';
am4core.useTheme(am4themesAnimated);

class TRDtiCalculator extends Component {
  chart = null;
  constructor(props) {
    super(props);
    this.state = {
      min: 0,
      // max:10000000000,
      annualIncomeValue: 0,
      rentMortgageValue: 0,
      creditCardValue: 0,
      carLoansValue: 0,
      studentLoansValue: 0,
      otherDeptValue: 0,
      monthlyIncomePercentage: 0,
      rentMortgagePercentage: 0,
      creditCardPercentage: 0,
      carLoansPercentage: 0,
      studentLoansPercentage: 0,
      otherDeptPercentage: 0,
      errors: null
    }
  }
  componentDidMount() {
    document.title = "Vault | DTI Calculator"
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    this.createChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }

  annualIncomeUpdate(e) {
    let value = e.floatValue || 0;
    if (value >= 0) {
      //value = (value > this.state.max ? this.state.max : value);
      //const percentage =  (value - this.state.min) / (this.state.max - this.state.min) * 100;

      const annualIncome = value;
      const totalMonthlyPayments = (parseInt(this.state.rentMortgageValue) + parseInt(this.state.creditCardValue) + parseInt(this.state.carLoansValue) + parseInt(this.state.studentLoansValue) + parseInt(this.state.otherDeptValue)) * 12;
      const monthlyIncomePercentage = Math.round((totalMonthlyPayments / annualIncome) * 100);

      const rentMortgagePercentage = Math.round(((parseInt(this.state.rentMortgageValue) * 12) / annualIncome) * 100);

      const creditCardPercentage = Math.round(((parseInt(this.state.creditCardValue) * 12) / annualIncome) * 100);

      const carLoansPercentage = Math.round(((parseInt(this.state.carLoansValue) * 12) / annualIncome) * 100);

      const studentLoansPercentage = Math.round(((parseInt(this.state.studentLoansValue) * 12) / annualIncome) * 100);

      const otherDeptPercentage = Math.round(((parseInt(this.state.otherDeptValue) * 12) / annualIncome) * 100);

      this.setState({
        annualIncomeValue: annualIncome,
        monthlyIncomePercentage: monthlyIncomePercentage,
        rentMortgagePercentage: rentMortgagePercentage,
        creditCardPercentage: creditCardPercentage,
        carLoansPercentage: carLoansPercentage,
        studentLoansPercentage: studentLoansPercentage,
        otherDeptPercentage: otherDeptPercentage
      }, () => {
        this.createChart();
      })
    }
  }

  rentMortgageUpdate(e) {
    let rentMortgageValue = e.floatValue || 0;
    if (rentMortgageValue >= 0) {
      const totalMonthlyPayments = (parseInt(rentMortgageValue) + parseInt(this.state.creditCardValue) + parseInt(this.state.carLoansValue) + parseInt(this.state.studentLoansValue) + parseInt(this.state.otherDeptValue)) * 12;
      const { monthlyIncomePercentage, currentResourcePercentage } = this.calculateMonthlyStats(rentMortgageValue, totalMonthlyPayments);

      this.setState({
        rentMortgageValue: rentMortgageValue,
        monthlyIncomePercentage: monthlyIncomePercentage,
        rentMortgagePercentage: currentResourcePercentage
      }, () => {
        this.createChart();
      })
    }
  }

  creditCardUpdate(e) {
    let creditCardValue = e.floatValue || 0;
    if (creditCardValue >= 0) {
      const totalMonthlyPayments = (parseInt(this.state.rentMortgageValue) + parseInt(creditCardValue) + parseInt(this.state.carLoansValue) + parseInt(this.state.studentLoansValue) + parseInt(this.state.otherDeptValue)) * 12;
      const { monthlyIncomePercentage, currentResourcePercentage } = this.calculateMonthlyStats(creditCardValue, totalMonthlyPayments);

      this.setState({
        creditCardValue: creditCardValue,
        monthlyIncomePercentage: monthlyIncomePercentage,
        creditCardPercentage: currentResourcePercentage
      }, () => {
        this.createChart();
      })
    }
  }

  carLoansUpdate(e) {
    let carLoansValue = e.floatValue || 0;;
    if (carLoansValue >= 0) {
      const totalMonthlyPayments = (parseInt(this.state.rentMortgageValue) + parseInt(this.state.creditCardValue) + parseInt(carLoansValue) + parseInt(this.state.studentLoansValue) + parseInt(this.state.otherDeptValue)) * 12;
      const { monthlyIncomePercentage, currentResourcePercentage } = this.calculateMonthlyStats(carLoansValue, totalMonthlyPayments);

      this.setState({
        carLoansValue: carLoansValue,
        monthlyIncomePercentage: monthlyIncomePercentage,
        carLoansPercentage: currentResourcePercentage
      }, () => {
        this.createChart();
      })
    }
  }

  studentLoansUpdate(e) {
    let studentLoansValue = e.floatValue || 0;;
    if (studentLoansValue >= 0) {
      const totalMonthlyPayments = (parseInt(this.state.rentMortgageValue) + parseInt(this.state.creditCardValue) + parseInt(this.state.carLoansValue) + parseInt(studentLoansValue) + parseInt(this.state.otherDeptValue)) * 12;
      const { monthlyIncomePercentage, currentResourcePercentage } = this.calculateMonthlyStats(studentLoansValue, totalMonthlyPayments);

      this.setState({
        studentLoansValue: studentLoansValue,
        monthlyIncomePercentage: monthlyIncomePercentage,
        studentLoansPercentage: currentResourcePercentage
      }, () => {
        this.createChart();
      })
    }
  }

  otherDeptUpdate(e) {
    let otherDeptValue = e.floatValue || 0;
    if (otherDeptValue >= 0) {
      const totalMonthlyPayments = (parseInt(this.state.rentMortgageValue) + parseInt(this.state.creditCardValue) + parseInt(this.state.carLoansValue) + parseInt(this.state.studentLoansValue) + parseInt(otherDeptValue)) * 12;
      const { monthlyIncomePercentage, currentResourcePercentage } = this.calculateMonthlyStats(otherDeptValue, totalMonthlyPayments);

      this.setState({
        otherDeptValue: otherDeptValue,
        monthlyIncomePercentage: monthlyIncomePercentage,
        otherDeptPercentage: currentResourcePercentage
      }, () => {
        this.createChart();
      })
    }
  }

  calculateMonthlyStats(currentValue, monthlyPayments) {

    const annualIncome = this.state.annualIncomeValue;
    const monthlyIncomePercentage = Math.round((monthlyPayments / annualIncome) * 100);
    const currentResourcePercentage = Math.round(((parseInt(currentValue) * 12) / annualIncome) * 100);

    return { monthlyIncomePercentage, currentResourcePercentage }
  }
  /**
  Grading Lookup
   */
  lookUpGrade(lookupScore, grades) {
    // Only change code below this line
    for (var i = 0; i < grades.length; i++) {
      if (
        grades[i].lowScore < lookupScore &&
        grades[i].highScore >= lookupScore
      ) {
        return grades[i];
      }
    }
    return null;
  }
  createChart() {
    const monthlyIncomePercentageText = (this.state.monthlyIncomePercentage > 100 ? 100 : this.state.monthlyIncomePercentage);

    // dispose the previous chart if exist
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }

    // create chart
    this.chart = am4core.create('chartDiv', am4charts.PieChart);

    this.chart.innerRadius = am4core.percent(40);
    this.chart.resizable = true;

    if (monthlyIncomePercentageText === 0) {
      this.chart.data = [
        {
          "finance": 'No Debt',
          'percentage': 100,
          'color': am4core.color('#eee')
        }
      ]
    } else {
      this.chart.data = [
        {
          "finance": 'Annual Income',
          "percentage": parseInt(this.state.monthlyIncomePercentage),
          "color": am4core.color('#A10066')
        }, {
          "finance": "Rent Mortgage",
          "percentage": parseInt(this.state.rentMortgagePercentage),
          "color": am4core.color('#6FCE40')
        }, {
          "finance": "Credit Card",
          "percentage": parseInt(this.state.creditCardPercentage),
          "color": am4core.color('#FF7842')
        }, {
          "finance": "Car Loans",
          "percentage": parseInt(this.state.carLoansPercentage),
          "color": am4core.color('#1890FF')
        }, {
          "finance": "Student Loans",
          "percentage": parseInt(this.state.studentLoansPercentage),
          "color": am4core.color('#FAAD14')
        }, {
          "finance": "Only Debts",
          "percentage": parseInt(this.state.otherDeptPercentage),
          'color': am4core.color("#F74750")
        },
      ];
    }
    let pieSeries = this.chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'percentage';
    pieSeries.dataFields.category = 'finance';
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.labels.template.disabled = true;
    if (monthlyIncomePercentageText > 0) {
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.useDefaultMarker = true;
      let marker = this.chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 1;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");
    } else {
      pieSeries.slices.template.tooltipText = "";

    }

    $('g:has(> g[stroke="#3cabff"])').hide();
  }
  render() {
    const { annualIncomeValue, rentMortgageValue, creditCardValue, carLoansValue, studentLoansValue, otherDeptValue, monthlyIncomePercentage } = this.state;
    return (
      <>
        <Header hideNotificationAndProfileDropdown={true} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5">
            {localStorage.getItem('hasTuitionPlan') === 'true' ?
              <Breadcrumb list={dtiCalculatorBreadcrumbTR} /> : <Breadcrumb list={dtiCalculatorBreadcrumb} />}
            <div className=" p-4 Gborder-05 bg-white my-3">
              <div className='G-grid11-2'>
                <div>
                  <div className='pinkCircularDiv mb-3'>
                    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 17C12.0054 17 11.0516 16.6049 10.3483 15.9017C9.64509 15.1984 9.25 14.2446 9.25 13.25C9.25 11.85 10.0125 10.625 11.125 9.9875L23.2625 2.9625L16.35 14.9375C15.725 16.1625 14.4625 17 13 17ZM13 0.75C15.2625 0.75 17.375 1.375 19.2125 2.4L16.5875 3.9125C15.5 3.4875 14.25 3.25 13 3.25C10.3478 3.25 7.8043 4.30357 5.92893 6.17893C4.05357 8.0543 3 10.5978 3 13.25C3 16.0125 4.1125 18.5125 5.925 20.3125H5.9375C6.425 20.8 6.425 21.5875 5.9375 22.075C5.45 22.5625 4.65 22.5625 4.1625 22.0875C1.9 19.825 0.5 16.7 0.5 13.25C0.5 9.93479 1.81696 6.75537 4.16117 4.41117C6.50537 2.06696 9.68479 0.75 13 0.75M25.5 13.25C25.5 16.7 24.1 19.825 21.8375 22.0875C21.35 22.5625 20.5625 22.5625 20.075 22.075C19.5875 21.5875 19.5875 20.8 20.075 20.3125C21.8875 18.5 23 16.0125 23 13.25C23 12 22.7625 10.75 22.325 9.625L23.8375 7C24.875 8.875 25.5 10.975 25.5 13.25Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h2 className='Gfs-225'>Debt-to-Income (DTI) Ratio Calculator</h2>
                  <p className='sans-medium'>Check the health of your monthly cashflow.</p>
                </div>
                <div>
                  <div className='my-3'>
                    <p className='sans-semibold mb-1'>Annual Income</p>
                    <NumberFormatInput label='Pre-Tax Annual Income' value={annualIncomeValue} defaultValue={annualIncomeValue} decimalScale={0} handleValueChange={(data) => this.annualIncomeUpdate(data)} />
                  </div>
                  <div className='my-4'>
                    <p className='sans-semibold mb-1'>Monthly Payments</p>
                    <NumberFormatInput label='Rent or Mortgage' value={rentMortgageValue} defaultValue={rentMortgageValue} decimalScale={0} handleValueChange={(data) => this.rentMortgageUpdate(data)} />
                  </div>
                  <div className='my-4'>
                    <p className='sans-semibold mb-1'>Monthly Payments</p>
                    <NumberFormatInput label='Credit Card' value={creditCardValue} defaultValue={creditCardValue} decimalScale={0} handleValueChange={(data) => this.creditCardUpdate(data)} />
                  </div>
                  <div className='my-4'>
                    <p className='sans-semibold mb-1'>Monthly Payments</p>
                    <NumberFormatInput label='Car Loans or Leases' value={carLoansValue} defaultValue={carLoansValue} decimalScale={0} handleValueChange={(data) => this.carLoansUpdate(data)} />
                  </div>
                  <div className='my-4'>
                    <p className='sans-semibold mb-1'>Monthly Payments</p>
                    <NumberFormatInput label='Student Loans' value={studentLoansValue} defaultValue={studentLoansValue} decimalScale={0} handleValueChange={(data) => this.studentLoansUpdate(data)} />
                  </div>
                  <div className='my-4'>
                    <p className='sans-semibold mb-1'>Monthly Payments</p>
                    <NumberFormatInput label='Other Debt' value={otherDeptValue} defaultValue={otherDeptValue} decimalScale={0} handleValueChange={(data) => this.otherDeptUpdate(data)} />
                  </div>
                  <div>
                    <h2 className='text-center Gt-pink-8'>{monthlyIncomePercentage > 100 ? "Debt > 100%" : monthlyIncomePercentage + " % Debt."}</h2>

                    <div id="chartDiv" className="w-100 mb-5 mt-1 dtiCalculatorChart">
                      {/* place chart code here */}
                    </div>
                    <div className="Gb-pink-2 rounded-lg py-4 px-3">
                      <h3 className='Gfs-15 gt-pink-8'>
                        Your debt-to-income ratio is {monthlyIncomePercentage > 100 ? "above 100" : monthlyIncomePercentage}%.
                      </h3>
                      <p className='sans-medium'> {showDtiRatioMessage(monthlyIncomePercentage)} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default (TRDtiCalculator);