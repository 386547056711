import React, { Component } from 'react';
import { connect } from "react-redux";
import './Loading.css';

class VaultLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return (
      (this.props.loadStatus) || (this.props.externalLoading) ?
        <div className="loading-panel">
          <div className="loader loading"></div>
        </div>
        : null
    );
  }
}
const mapStateToProps = state => ({
  loadStatus: state.api.isLoadingData
});

export default connect(
  mapStateToProps,
  null
)(VaultLoader);
