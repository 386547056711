import React, { useRef } from 'react';
import { Table, Modal, Form } from 'react-bootstrap'
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import SimpleReactValidator from "simple-react-validator";
import Loading from "../../Includes/Loader/Loading";

import {
  getTotalPaidForStatement,
  sumOfApprovedTransactions
} from "../match";
import StatementsDropdown from "./StatementsDropdown";
import { numberFormat } from '../../../common/js/jsFunctions';
import { BoxArrowUpRight, Check2, ExclamationCircle, Plus, Trash, Wrench, XLg } from 'react-bootstrap-icons';
import { useState } from 'react';
import DragAndDropBox from '../../shared/Drag&DropBox/DragAndDropBox';
import FileList from '../../shared/FileList/FileList';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import NumberFormat from 'react-number-format';
import ReactDatePicker from 'react-datepicker';
import S from './Match.module.css'


const TransactionsDropdown = (props) => {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [uploadStatementShow, setUploadStatementShow] = useState(false);
  const [currentServicerAccount, setCurrentServicerAccount] = useState(null);
  const [fileSelected, setFileSelected] = useState(null)
  const [counterForTransactionEntry, setCounterForTransactionEntry] = useState({ 0: { 'paymentDate': '', 'paymentAmount': '' } })
  const loadingFinicityLink = props.loadingFinicityLink;
  const initiatingServicerId = props.initiatingServicerId;
  const servicerAccounts = props.servicerAccounts;
  const transactions = props.transactions;
  const approvedTransaction = transactions.filter((e) => {
    return e.attributes.approved === true
  })

  const setFileData = (file) => {
    props.uploadStatement(file, currentServicerAccount)
    setFileSelected(file)
  }

  const removeFile = () => {
    setFileSelected(null)
  }

  const handlePaymentDateChange = (date, index) => {
    setCounterForTransactionEntry({ ...counterForTransactionEntry, [index]: { ...counterForTransactionEntry[index], paymentDate: date } })
  }


  const handlePaymentAmount = (e, index) => {
    setCounterForTransactionEntry({ ...counterForTransactionEntry, [index]: { ...counterForTransactionEntry[index], paymentAmount: e.target.value } })
  }

  const addNewTransaction = () => {
    // console.log(counterForTransactionEntry)
    let newIndex = Object.keys(counterForTransactionEntry).length;
    // if (counterForTransactionEntry[newIndex]) {
    //   newIndex++;
    // }
    newIndex = parseInt(Object.keys(counterForTransactionEntry)[newIndex - 1]) + 1
    if (isNaN(newIndex)) {
      newIndex = 0;
    }
    setCounterForTransactionEntry({ ...counterForTransactionEntry, [newIndex]: { 'paymentDate': '', 'paymentAmount': '' } })
  }

  const deleteTransactionRow = (index) => {
    let copyOfStateData = { ...counterForTransactionEntry };
    delete copyOfStateData[index];
    setCounterForTransactionEntry({ ...copyOfStateData })
  }

  const closingUpdateStatementModal = () => {
    setUploadStatementShow(false);
    setFileSelected(null);
    setCounterForTransactionEntry({ 0: { 'paymentDate': '', 'paymentAmount': '' } })
  }

  const submitTransactionAndUploadModal = () => {
    const formValid = validator.current.allValid()
    if (!formValid) {
      validator.current.showMessages()
      forceUpdate(1)
    } else {
      props.submitTransactionStatement(counterForTransactionEntry, closingUpdateStatementModal);
    }
  }
  // console.log(servicerAccounts)
  // console.log(counterForTransactionEntry)
  return (
    <>
      <div className="Gtable-div mt-4">
        <Table borderless className="Gborder-05 G-table-theme-grey">
          <thead className="Gt-head">
            <tr className={S.dashboardTableTr}>
              <th className="Gfs-075 p-3">Servicer</th>
              <th className="Gfs-075 p-3 ">Last Updated</th>
              <th className="Gfs-075 p-3 ">Total Paid</th>
              <th className="Gfs-075 p-3">Action</th>
              <th className="Gfs-075 p-3">
                <ExclamationCircle className='mr-2 mb-1' size={18} data-for="tooltipForUploadStatementHeader" data-tip role='button' />
                Upload Statement
                <ReactTooltip id="tooltipForUploadStatementHeader" place="top" effect="solid" >
                  <span className={S.textNormal}>
                    You can upload the statement manually if you noticed your transactions haven’t been pulled automatically
                  </span>
                </ReactTooltip>
              </th>
              <th className="Gfs-075 p-3">Transactions</th>
            </tr>
          </thead>
          <tbody className="Gt-body">
            {
              servicerAccounts.length > 0 ?
                servicerAccounts.map((servicerAccount, index) => {
                  // console.log(index, servicerAccount)
                  const name = servicerAccount.servicer.friendly_name;
                  const isFinicityLinked = servicerAccount.is_finicity_linked;
                  const isFinicitySynced = servicerAccount.is_finicity_synced;
                  const finicityActive = servicerAccount.finicity_active;
                  const needsFinicityReconnect = servicerAccount.needs_finicity_reconnect;

                  //const transactionsSorted = fincityTransactionsSorted(servicerAccount.transactions);
                  const statements = servicerAccount.statements;

                  let statementSum = 0;
                  statements.map((e) => {
                    statementSum = statementSum + getTotalPaidForStatement(approvedTransaction, e.statement_id)
                    return statementSum
                  })

                  const servicerAccountTransactions = transactions.filter((e) => {
                    return e.attributes.servicer_account_id === servicerAccount.servicer_account_id
                  })

                  // console.log(statements)
                  // console.log(servicerAccount)
                  // console.log(transactions)
                  // console.log(statementSum)

                  return (
                    servicerAccount.match_active === true ?
                      <tr key={servicerAccount.servicer_account_id}>
                        <td>{name}</td>
                        <td>
                          {isFinicityLinked ?
                            <> {moment().format('MMM D, YYYY')}</>
                            : '-'}
                        </td>
                        <td>
                          {/* {transactions.length > 0 ?
                              <>
                                {servicerAccount.statements.length === 0 && approvedTransactions.length > 0
                                  ? (sumOfApprovedTransactions(transactions) > 0 ? numberFormat(sumOfApprovedTransactions(transactions)) : '-')
                                  : statementSum ? numberFormat(statementSum) : '-'}
                              </>
                              : '-'} */}
                          {(sumOfApprovedTransactions(servicerAccountTransactions) > 0 ? numberFormat(sumOfApprovedTransactions(servicerAccountTransactions)) : '-')}
                        </td>
                        <td>
                          {
                            finicityActive ?
                              isFinicityLinked ?
                                isFinicitySynced ?
                                  <>
                                    <Check2 size={18} className='Gt-green-6 mr-2 mb-1' />
                                    <span className="Gt-green-6">Account Linked</span>
                                  </>
                                  : loadingFinicityLink && index === initiatingServicerId ?
                                    <Loading />
                                    :
                                    <div className={S.tableRedBtn} onClick={(e) => {
                                      if (needsFinicityReconnect) {
                                        props.linkAccount(servicerAccount, index, e);
                                      }
                                    }}>
                                      <ExclamationCircle size={18} className='Gt-red-5 mr-2 mb-1' />
                                      <span className='Gt-red-5' data-tip data-for="attentionNeeded">Attention Needed</span>
                                      {/* <BoxArrowUpRight className='Gt-pink-8 float-right mt-1 G-cursor-pointer' /> */}
                                      <ReactTooltip id="attentionNeeded" place="top" effect="solid">
                                        { servicerAccount.finicity_user_message }
                                      </ReactTooltip>
                                    </div>
                                :
                                loadingFinicityLink && index === initiatingServicerId ?
                                  <Loading />
                                  :
                                  <div className={S.tableYellowBtn} onClick={(e) => props.linkAccount(servicerAccount, index, e)}>
                                    <Wrench size={18} className='Gt-yellow-6 mr-2 mb-1' />
                                    <span className='Gt-yellow-6' >Link Account</span>
                                    {/* <BoxArrowUpRight onClick={(e) => props.linkAccount(servicerAccount, index, e)} className='Gt-pink-8 mt-1 G-cursor-pointer float-right' /> */}
                                  </div>
                              :
                              '-'
                          }
                        </td>
                        <td className='text-center'>
                          {/* <Upload size={18} className='Gt-blue-5 mr-2 mb-1' /> */}
                          {/* <span className='Gt-blue-5 G-cursor-pointer' onClick={() => { setUploadStatementShow(true); setCurrentServicerAccount(servicerAccount) }}>Upload Statement</span> */}
                          {/* <label for='myfile' className='float-right '>
                                    <input
                                      type="file"
                                      id="myfile"
                                      name="myfile"
                                      accept="application/pdf"
                                      className='d-none'
                                      onChange={(e) => props.uploadStatement(e, servicerAccount)}
                                    />
                                    <BoxArrowUpRight className='Gt-pink-8 G-cursor-pointer' />
                                  </label> */}
                          <BoxArrowUpRight className='Gt-pink-8 G-cursor-pointer ' onClick={() => { setUploadStatementShow(true); setCurrentServicerAccount(servicerAccount) }} />
                        </td>
                        <td className='transaction-statement-modal'>
                          <>
                            {/* Show finicity transactions only where we dont have statements, otherwise it was showing for  */}
                            {/* {console.log(servicerAccount.statements)} */}
                            {/* {servicerAccount.statements.length === 0 &&
                                <FinicityTransactionsTable
                                  servicerAccount={servicerAccount}
                                  customer={props.customer}
                                  transactions={transactions}
                                />
                                } */}
                            {/* <FinicityTransactionsTable
                                servicerAccount={servicerAccount}
                                customer={props.customer}
                                transactions={transactions}
                              /> */}
                            <StatementsDropdown
                              statements={statements}
                              transactions={servicerAccountTransactions}
                              customer={props.customer}
                              servicerAccount={servicerAccount}
                              uploadedStatements={props.uploadedStatements}
                            />
                          </>
                        </td>
                      </tr >
                      :
                      <tr key={servicerAccount.servicer_account_id}>
                        <td>{name}</td>
                        <td colSpan={4}>
                          Servicer is not supported. If you believe this is an error, please contact us at genius@vault.co.
                        </td>
                      </tr>
                  )
                })
                :
                <tr >
                  <td colSpan={5}>You currently do not have any loans added.</td>
                </tr>
            }
          </tbody>
        </Table>
      </div >

      <Modal size="xl" show={uploadStatementShow} animation={false} backdrop="static" keyboard={false} scrollable={true}>
        <Modal.Body className='custom-modal-body'>
          <div className="d-flex">
            <>
              <div className='mr-3'>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M26.25 35V27.5H21.25L30 18.75L38.75 27.5H33.75V35H26.25ZM21.25 40V37.5H38.75V40H21.25Z" fill="var(--icon-color)" />
                </svg>
              </div>
            </>
            <div className='w-100'>
              <div className="d-flex">
                <h4 className='py-3'>Upload Statement </h4>
                <div className="ml-3">
                  <a
                    onClick={() => closingUpdateStatementModal()} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg />
                  </a>
                </div>
              </div>
              {/* body */}
              <div>
                <p className='sans-medium'>
                  To ensure accurate processing of your transactions, please upload your statement and manually enter the transaction details. This will allow our team at Vault to review and verify your entries.
                </p>
                <div>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="var(--icon-background-color)" />
                    <path d="M19.852 26.5V17.968C19.612 18.46 19.24 18.832 18.736 19.084C18.232 19.324 17.674 19.444 17.062 19.444H16.684V16.366H16.774C17.83 16.366 18.622 16.126 19.15 15.646C19.69 15.166 19.96 14.404 19.96 13.36H22.984V26.5H19.852Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h5 className='my-3'>
                  Upload Your Transactions PDF
                </h5>
                <p className='sans-medium'>
                  To begin, please upload a PDF file containing your transactions by clicking the 'Drag and Drop or Browse Files' button below. Make sure that the document clearly lists all the transactions you want to add.
                </p>
                <div>
                  <DragAndDropBox setFileData={(file) => setFileData(file)} />
                </div>
                <div>
                  {fileSelected ?
                    <FileList fileList={new Array(fileSelected)} removeFile={removeFile} />
                    : null}
                </div>
                <div>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="var(--icon-background-color)" />
                    <path d="M14.936 24.142C14.936 23.494 15.05 22.924 15.278 22.432C15.518 21.928 15.824 21.49 16.196 21.118C16.568 20.734 16.982 20.398 17.438 20.11C17.894 19.81 18.35 19.54 18.806 19.3C19.166 19.108 19.502 18.928 19.814 18.76C20.138 18.58 20.414 18.406 20.642 18.238C20.882 18.07 21.068 17.89 21.2 17.698C21.332 17.506 21.398 17.296 21.398 17.068C21.398 16.696 21.272 16.408 21.02 16.204C20.768 15.988 20.39 15.88 19.886 15.88C19.274 15.88 18.758 16.054 18.338 16.402C17.918 16.75 17.624 17.176 17.456 17.68L14.63 16.348C15.134 15.376 15.836 14.608 16.736 14.044C17.648 13.468 18.722 13.18 19.958 13.18C20.69 13.18 21.338 13.282 21.902 13.486C22.466 13.678 22.94 13.948 23.324 14.296C23.72 14.644 24.014 15.058 24.206 15.538C24.41 16.018 24.512 16.534 24.512 17.086C24.512 17.626 24.41 18.106 24.206 18.526C24.002 18.934 23.732 19.3 23.396 19.624C23.072 19.948 22.7 20.236 22.28 20.488C21.86 20.74 21.44 20.98 21.02 21.208C20.684 21.388 20.36 21.568 20.048 21.748C19.736 21.916 19.454 22.096 19.202 22.288C18.95 22.468 18.74 22.666 18.572 22.882C18.404 23.086 18.296 23.314 18.248 23.566H24.404V26.5H14.936V24.142Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h5 className='my-3'>
                  Manual Transaction Entry
                </h5>
                <p className='sans-medium'>
                  Once you've uploaded your PDF, you can enter the transaction detail in the fields provided below. Please input the information as it appears on your PDF, and double-check the accuracy of entries to ensure your records are up-to-date.
                </p>
                <div className='mb-3'>
                  <button className={"mob-separate-v6 " + (!!fileSelected ? 'G-cancel-button' : 'G-disabled-button')}
                    disabled={(!!fileSelected ? false : true)} onClick={() => addNewTransaction()}>
                    <Plus size={24} className='mb-1' /> Add Another Transaction
                  </button>
                </div>
                {fileSelected && Object.keys(counterForTransactionEntry).length > 0 ?
                  Object.keys(counterForTransactionEntry).map((i, index) =>
                    <div className={`d-flex flex-wrap ${S.gap2}`} key={index}>
                      <div className={`flex-grow-1`}>
                        <Form.Group className="mb-3" controlId="formBasicUser">
                          <Form.Label>Payment Date</Form.Label>
                          <ReactDatePicker
                            onKeyDown={event => {
                              event.preventDefault();
                              return false;
                            }}
                            className="form-control"
                            onChange={(e) => handlePaymentDateChange(e, i)}
                            dateFormat="MM/dd/yyyy"
                            selected={counterForTransactionEntry[i]?.paymentDate}
                            name='paymentDate'
                            placeholderText='Select Date'
                          />
                          <ValidatorMessage validator={validator.current} fieldName='paymentDate' fieldValue={counterForTransactionEntry[i]?.paymentDate} message="* Payment Date can' t be blank" />
                        </Form.Group>
                      </div>
                      <div className={`flex-grow-1`}>
                        <Form.Group className="mb-3" controlId="formBasicUser">
                          <Form.Label>Payment Amount</Form.Label>
                          <NumberFormat
                            thousandsGroupStyle="thousand"
                            value={counterForTransactionEntry[i]?.paymentAmount}
                            prefix="$"
                            decimalSeparator="."
                            displayType="input"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                            className={`form-control`}
                            id="salary_cents"
                            name="paymentAmount"
                            placeholder="$0.00"
                            onChange={event => handlePaymentAmount(event, i)}
                          />
                          <ValidatorMessage validator={validator.current} fieldName='paymentAmount' fieldValue={counterForTransactionEntry[i]?.paymentAmount} message="* Payment Amount can' t be blank" />
                        </Form.Group>
                      </div>
                      <div>
                        {Object.keys(counterForTransactionEntry).length === 1 ?
                          <div
                            className='d-flex h-100 mt-1 justify-content-center align-items-center'>
                            <Trash className='Gt-slate-3' size={28} />
                          </div>
                          :
                          <div title='Delete Row' type='button'
                            className='d-flex h-100 mt-1 justify-content-center align-items-center'
                            onClick={() => deleteTransactionRow(i)}>
                            <Trash className='Gt-pink-8' size={28} />
                          </div>
                        }
                      </div>
                    </div>
                  )
                  : null
                }
                <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                  <div className='mr-3 my-auto'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                    </svg>
                  </div>
                  <div>
                    <p className='sans-semibold mb-0'>
                      Thank you for your understanding, and if you have any questions or need assistance, please don't hesitate to contact our support team.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mob-btn-div-column">
                <button className="G-cancel-button mx-3 mob-separate-v6"
                  onClick={() => closingUpdateStatementModal()} >
                  Cancel
                </button>
                <button className="G-orange-button mob-separate-v6" onClick={submitTransactionAndUploadModal}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

    </>

    // <>
    // {
    //     servicerAccounts.length > 0 ?
    //       servicerAccounts.map((servicerAccount, index) => {
    //         const name = servicerAccount.servicer.friendly_name;
    //         const isFinicityLinked = servicerAccount.is_finicity_linked;
    //         const isFinicitySynced = servicerAccount.is_finicity_synced;
    //         const finicityActive = servicerAccount.finicity_active;

    //         //const transactionsSorted = fincityTransactionsSorted(servicerAccount.transactions);
    //         const statements = servicerAccount.statements;

    //         let statementSum = 0;
    //         statements.map((e) => {
    //           statementSum = statementSum + getTotalPaidForStatement(approvedTransaction, e.statement_id)
    //           return statementSum
    //         })

    //         const approvedTransactions = props.transactions.filter((e) => {
    //           return e.attributes.servicer_account_id === servicerAccount.servicer_account_id
    //         })

    //         return (
    //           !servicerAccount.match_active === true ?
    //             <div key={servicerAccount.servicer_account_id}>
    //               <Card.Header className="g-transparent-bg border-0">
    //                 <Accordion.Toggle as={Button} variant="link" eventKey={index === 0 ? '0' : index}>
    //                   <div className="tabs_row_main flex_row g-transparent-bg">
    //                     <div className="tab_text">
    //                       <span>{name}</span>
    //                     </div>
    //                     {transactions.length > 0 ?
    //                       <div className="link_account_text">
    //                         <span className="last_upadted_tex">
    //                           {servicerAccount.statements.length === 0 && approvedTransactions.length > 0
    //                             ? (sumOfApprovedTransactions(transactions) > 0 ? 'Total Paid: ' + numberFormat(sumOfApprovedTransactions(transactions)) : null)
    //                             : statementSum ? 'Total Paid: ' + numberFormat(statementSum) : null}
    //                         </span>
    //                       </div>
    //                       : null}
    //                     <div className="link_account_text d-flex justify-content-end">
    //                       {isFinicityLinked ?
    //                         <span className="last_upadted_tex">Last Updated: {moment().format('MMM D, YYYY')}</span>
    //                         : null}

    //                       {
    //                         finicityActive ?
    //                           isFinicityLinked ?
    //                             isFinicitySynced ?
    //                               <span className="last_text_over">
    //                                 <img className="link_icon" src={link} alt="linkb" />
    //                                 <span className="green__text">Account Linked</span>
    //                               </span>
    //                               : loadingFinicityLink && index === initiatingServicerId ?
    //                                 <Loading />
    //                                 :
    //                                 <span className="last_text_over">
    //                                   <img className="link_icon" src={linkr} alt="linkb" />
    //                                   <span className="red__text" data-tip data-for="attentionNeeded">Attention Needed</span>
    //                                   <ReactTooltip id="attentionNeeded" place="top" effect="solid">
    //                                     Attention Needed - Re-link your account to continue tracking your payments
    //                                   </ReactTooltip>
    //                                 </span>
    //                             :
    //                             loadingFinicityLink && index === initiatingServicerId ?
    //                               <Loading />
    //                               :
    //                               <span className="last_text_over" onClick={(e) => props.linkAccount(servicerAccount, index, e)}>
    //                                 <img className="link_icon" src={linkb} alt="linkb" />
    //                                 <span>Link Account</span>
    //                               </span>
    //                           :
    //                           <span className="last_text_over float_right">
    //                             <label for='myfile' className='choose_file'>
    //                               <input
    //                                 type="file"
    //                                 id="myfile"
    //                                 name="myfile"
    //                                 accept="application/pdf"
    //                                 onChange={(e) => props.uploadStatement(e, servicerAccount)}
    //                               />
    //                               <svg height="24" viewBox="0 0 48 48" width="24" xmlns="http://www.w3.org/2000/svg"><path className='gallery' d="M15 36c-6.08 0-11-4.93-11-11s4.92-11 11-11h21c4.42 0 8 3.58 8 8s-3.58 8-8 8h-17c-2.76 0-5-2.24-5-5s2.24-5 5-5h15v3h-15c-1.1 0-2 .89-2 2s.9 2 2 2h17c2.76 0 5-2.24 5-5s-2.24-5-5-5h-21c-4.42 0-8 3.58-8 8s3.58 8 8 8h19v3h-19z" /><path d="M0 0h48v48h-48z" fill="none" /></svg>
    //                               <span>Upload Statement</span>
    //                             </label>
    //                           </span>
    //                       }
    //                     </div>
    //                   </div>
    //                 </Accordion.Toggle>
    //               </Card.Header>
    //               <Accordion.Collapse className="mb-4" eventKey={index === 0 ? '0' : index}>
    //                 <>
    //                   {/* Show finicity transactions only where we dont have statements, otherwise it was showing for  */}
    //                   {servicerAccount.statements.length === 0 &&
    //                     <FinicityTransactionsTable
    //                       servicerAccount={servicerAccount}
    //                       customer={props.customer}
    //                       transactions={transactions}
    //                     />}

    //                   <StatementsDropdown
    //                     statements={statements}
    //                     transactions={transactions}
    //                   />
    //                 </>
    //               </Accordion.Collapse>
    //             </div>
    //             : <Card key={servicerAccount.servicer_account_id}>
    //               <Card.Header>
    //                 <Accordion.Toggle as={Button} variant="link">
    //                   <div className="tabs_row_main flex_row">
    //                     <div className="tab_text">
    //                       <span className="tooltip-new-text" data-tooltip="Servicer is not supported. If you believe this is an error, please contact us at genius@vault.co.">
    //                         <span>{name}</span>
    //                       </span>
    //                     </div>
    //                   </div>
    //                 </Accordion.Toggle>
    //               </Card.Header>
    //             </Card>
    //         )
    //       })
    //       :
    //       <h2 className="c-heading-5 mt-0">
    //         You have no accounts. Go <Link to="/dashboard/loans/new?fromMatch=true">here</Link> to create one.
    //       </h2>
    //   }
    // </>
  )
}
export default TransactionsDropdown;