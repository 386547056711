import { headerSelector } from "../Components/Includes/Header/Helpers/serviceSelector";

const getUrlForLogin = (props) => {
  const {
    anyFiveTwoNineTerms,
    anyGeneralTerms,
    anyEsignTerms,
    advisorFirst,
    hasProfile,
    hasAdvisorAndSavingsPlan,
    selectedService,
    hasAdvisor,
  } = props

  const headerName = headerSelector();

  if (((hasAdvisor && advisorFirst) || headerName === 'advisorOnly') && !(hasAdvisorAndSavingsPlan && selectedService === 'savings_plan')) {
    if (!anyGeneralTerms || !anyEsignTerms) return "/intro/advisor";
    else if (!hasProfile) return "/welcome/about";
    else return "/advice-and-tools/dashboard";
  } else if (headerName === 'pay') {
    return "/pay/overview";
  } else if (headerName === 'match') {
    return "/match/index";
  } else if (headerName === 'tuition') {
    if (!anyGeneralTerms || !anyEsignTerms) {
      return "/intro/tuition-plan";
    } else {
      return "/tuition-reimbursement-history";
    }
  } else if (headerName === 'fiveTwoNine') {
    if ((anyFiveTwoNineTerms && anyEsignTerms) || selectedService == null) return "/master-dashboard";
    else return "/intro/plan-savings";
  } else return '/error';
}

export default getUrlForLogin;