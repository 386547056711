import { FETCH_EMPLOYER, GET_ERRORS } from './types';

import { apiAction } from './apiActions';
import config from '../config';


export const fetchEmployer = () => {
	
	return apiAction({
	 url: config.apiBaseUrl + "employer",
	 onSuccess: setfetchEmployer,
	 callback : '',
	 onFailure: errorfetchEmployer,
	 label: FETCH_EMPLOYER
   });
}
 
function setfetchEmployer(data) {
   return {
	 type: FETCH_EMPLOYER,
	 payload: data.data
   };
}
function errorfetchEmployer(errors) {
   if (errors.response && errors.response.data) {
       return {
    	 type: GET_ERRORS,
    	 payload: errors.response.data
       };
    }
}