import { ACCEPT_GENERAL_TERMS, FETCH_GENERAL_TERMS } from "../actions/types";

const initialState = {
  generalAgreementData: {},
  acceptGeneralAgreementResponse: {},
};

export default function generalTermsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GENERAL_TERMS:
      return {
        ...state,
        generalAgreementData: action.payload,
      };
    case ACCEPT_GENERAL_TERMS:
      return {
        ...state,
        acceptGeneralAgreementResponse: action.payload,
      };
    default:
      return state;
  }
}
