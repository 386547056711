const sentryIgnoreErrorsList = [
    /Request aborted/i,
    /Network Error/i,
    /Unexpected token 'var'/i,
    /Minified Redux error \#7\;/i,
    /timeout of 0ms exceeded/i,
    /Cannot find function finally in object \[object Promise\]/i,
    /Should not already be working./i,
    /Request failed with status code 422/i,
    'Request failed with status code 401',
    /Object captured as exception with keys\: errors/i,
    /Object captured as exception with keys\: error/i,
    /Non-Error promise rejection captured with value/i,
    /The operation is insecure/i,
    /"_onComplete"/i,
    /Actions must be plain objects. Use custom middleware for async actions./i,
    "TypeError: undefined is not an object (evaluating '__gCrWeb.instantSearch.clearHighlight')",
    "Can't find variable: msDiscoverChatAvailable",
]

export default sentryIgnoreErrorsList;