import React from 'react'
import Timer from './../shared/CountdownTimer/Timer';

const ErrorPage = ({ url }) => {
  return (
    <div className="G-page-background-color">
      <div className="G-page-main-container  my-5">
        <div className='d-flex flex-column align-items-center'>
          <div>
            <svg className='w-100' width="444" height="277" viewBox="0 0 444 277" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_4601_9587)">
                <path d="M277.341 6.77794C277.723 6.77794 278.106 6.39428 278.361 5.88274C278.616 5.49908 278.998 4.85966 279.508 4.476C280.018 4.09234 280.401 4.22023 280.783 4.34812C280.911 4.476 281.038 4.60389 281.294 4.85966C281.549 5.11543 281.804 5.62697 281.931 6.2664C282.059 6.52217 282.059 6.77794 282.059 6.90583C282.059 7.1616 282.186 7.28948 282.186 7.67314V7.92891V8.0568H281.931C281.676 8.0568 281.421 8.05679 281.166 7.92891C280.783 7.92891 280.401 7.80102 280.018 7.67314C279.381 7.54525 278.998 7.28948 278.488 7.03371C278.106 7.03371 277.723 6.77794 277.341 6.77794C276.958 6.77794 276.703 7.1616 276.576 7.92891C276.448 8.69622 276.958 9.97508 278.106 10.9982C278.616 11.5097 279.381 11.8934 280.146 12.1491C280.528 12.277 280.783 12.4049 281.166 12.5328L281.421 12.6607L281.804 12.7886C282.059 12.7886 282.441 12.9164 282.824 12.9164C284.099 13.0443 285.501 12.5328 286.394 11.3818C286.777 10.8703 287.032 10.103 287.159 9.33565C287.159 8.95199 287.159 8.56834 287.159 8.18468V7.67314V7.28948C287.159 6.90583 287.032 6.39428 286.904 6.01063C286.777 5.62697 286.649 5.24331 286.522 4.73177C286.139 3.70869 285.629 2.6856 284.864 1.91829C284.099 1.02309 282.951 0.255779 281.676 0.127894C279.253 -0.255763 277.213 1.27886 276.576 2.6856C275.938 4.09234 276.065 5.24331 276.193 6.01063C276.448 6.52217 276.958 6.90583 277.341 6.77794Z" fill="var(--icon-color)" />
                <path d="M117.567 101.541C117.95 101.541 118.332 101.157 118.587 100.646C118.842 100.262 119.225 99.6228 119.735 99.2391C120.245 98.8555 120.627 98.9834 121.01 99.1113C121.137 99.2391 121.265 99.367 121.52 99.6228C121.775 99.8786 122.03 100.39 122.157 101.03C122.285 101.285 122.285 101.541 122.285 101.669C122.285 101.925 122.412 102.053 122.412 102.436V102.692V102.82C122.412 102.82 122.412 102.82 122.285 102.948C122.285 102.948 122.157 102.948 122.03 102.948H121.647C120.755 102.948 120.117 102.692 119.735 102.564C119.225 102.436 118.842 102.181 118.587 101.925C118.332 101.669 117.95 101.413 117.567 101.541C117.184 101.541 116.929 101.925 116.802 102.436C116.674 103.076 116.802 104.099 117.567 104.994C118.332 106.017 119.607 106.784 120.882 107.168L121.265 107.296C121.392 107.296 121.52 107.424 121.902 107.424C122.412 107.552 123.05 107.552 123.56 107.552C124.708 107.552 125.855 107.04 126.62 106.017C127.003 105.506 127.258 104.738 127.258 103.971C127.258 103.587 127.258 103.204 127.258 102.82V102.308V101.925C127.258 101.541 127.13 101.03 127.003 100.646C126.875 100.262 126.748 99.8786 126.62 99.367C126.238 98.3439 125.728 97.3209 124.963 96.5535C124.198 95.6583 123.05 94.891 121.775 94.7631C119.352 94.3795 117.312 95.9141 116.674 97.3209C116.037 98.7276 116.164 99.8786 116.292 100.646C116.802 101.285 117.184 101.541 117.567 101.541Z" fill="var(--icon-color)" />
                <path d="M121.647 38.4935C122.03 38.4935 122.412 38.1099 122.668 37.5983C122.923 37.2147 123.305 36.5752 123.815 36.1916C124.325 35.8079 124.708 35.9358 125.09 36.0637C125.218 36.1916 125.345 36.3195 125.6 36.5752C125.855 36.831 126.11 37.3425 126.238 37.982C126.365 38.2377 126.365 38.4935 126.365 38.6214C126.365 38.8772 126.493 39.0051 126.493 39.3887V39.6445V39.7724H126.238C125.983 39.7724 125.728 39.7724 125.473 39.6445C125.09 39.6445 124.708 39.5166 124.325 39.3887C123.688 39.2608 123.305 39.0051 122.795 38.7493C122.54 38.7493 122.157 38.3656 121.647 38.4935C121.265 38.4935 121.01 38.8772 120.882 39.6445C120.755 40.4118 121.265 41.6907 122.412 42.7137C122.923 43.2253 123.688 43.6089 124.453 43.8647C124.835 43.9926 125.09 44.1205 125.473 44.2484L125.728 44.3762L126.238 44.5041C126.493 44.5041 126.875 44.632 127.258 44.632C128.533 44.7599 129.936 44.2484 130.828 43.0974C131.211 42.5859 131.466 41.8185 131.593 41.0512C131.593 40.6676 131.593 40.2839 131.593 39.9003V39.3887V39.0051C131.593 38.6214 131.466 38.1099 131.338 37.7262C131.211 37.3425 131.083 36.9589 130.956 36.4473C130.573 35.4243 130.063 34.4012 129.298 33.6339C128.533 32.7387 127.385 31.9714 126.11 31.8435C123.688 31.4598 121.647 32.9944 121.01 34.4012C120.372 35.8079 120.5 36.9589 120.627 37.7262C120.882 38.2377 121.265 38.4935 121.647 38.4935Z" fill="#D080B3" />
                <path d="M173.928 35.0406C172.27 35.0406 171.632 37.982 171.505 41.8186C171.505 41.9465 171.505 42.2022 171.505 42.3301C171.505 42.458 171.505 42.7138 171.505 42.8417C171.632 46.6782 172.27 49.6196 173.928 49.6196C175.458 49.6196 176.223 46.6782 176.35 42.8417C176.35 42.7138 176.35 42.458 176.35 42.3301C176.35 42.2022 176.35 41.9465 176.35 41.8186C176.223 37.8541 175.458 35.0406 173.928 35.0406Z" fill="#D080B3" />
                <path d="M348.493 130.06C348.875 130.06 349.258 129.676 349.513 129.164C349.768 128.781 350.15 128.141 350.66 127.758C351.171 127.374 351.553 127.502 351.936 127.63C352.063 127.758 352.191 127.886 352.446 128.141C352.701 128.397 352.956 128.909 353.083 129.548C353.211 129.804 353.211 130.06 353.211 130.187C353.211 130.443 353.338 130.571 353.338 130.955V131.211V131.338C353.338 131.338 353.338 131.338 353.211 131.466C353.211 131.466 353.083 131.466 352.956 131.466H352.573C351.681 131.466 351.043 131.338 350.66 131.083C350.15 130.955 349.768 130.699 349.513 130.443C349.258 130.187 348.875 130.06 348.493 130.06C348.11 130.06 347.855 130.443 347.728 130.955C347.6 131.594 347.728 132.617 348.493 133.512C349.258 134.536 350.533 135.303 351.808 135.687L352.191 135.814C352.318 135.814 352.446 135.942 352.828 135.942C353.338 136.07 353.976 136.07 354.486 136.07C355.633 136.07 356.781 135.559 357.546 134.536C357.929 134.024 358.184 133.257 358.184 132.489C358.184 132.106 358.184 131.722 358.184 131.338V130.827V130.443C358.184 130.06 358.056 129.548 357.929 129.164C357.801 128.781 357.674 128.397 357.546 127.886C357.164 126.862 356.654 125.839 355.889 125.072C355.123 124.177 353.976 123.41 352.701 123.282C350.278 122.898 348.238 124.433 347.6 125.839C346.963 127.246 347.09 128.397 347.218 129.164C347.728 129.804 348.11 130.06 348.493 130.06Z" fill="#D080B3" />
                <path d="M365.58 70.8486C363.922 70.8486 363.284 73.7899 363.157 77.6265C363.157 77.7544 363.157 78.0102 363.157 78.138C363.157 78.2659 363.157 78.5217 363.157 78.6496C363.284 82.4861 363.922 85.4275 365.58 85.4275C367.11 85.4275 367.875 82.4861 368.002 78.6496C368.002 78.5217 368.002 78.2659 368.002 78.138C368.002 78.0102 368.002 77.7544 368.002 77.6265C367.875 73.6621 367.11 70.8486 365.58 70.8486Z" fill="#D080B3" />
                <path d="M243.805 53.7119C243.805 53.7119 252.221 54.3513 252.221 67.6514C252.221 80.9515 258.086 202.443 252.858 217.405C247.63 232.368 248.65 251.807 248.65 251.807C259.616 250.656 260.891 250.656 260.891 250.656L261.146 53.7119H243.805Z" fill="var(--tertiary-color)" />
                <path d="M444 265.362C444 264.979 438.517 263.828 428.444 262.293C418.37 260.631 403.706 258.84 385.344 256.794C366.982 255.004 344.923 252.957 320.185 251.807C295.448 250.4 268.032 249.888 238.959 250.272C233.094 250.4 227.356 250.4 221.618 250.528C215.879 250.783 210.141 250.911 204.276 251.167C175.203 252.318 147.788 254.236 123.178 256.794C98.5676 259.224 76.6353 262.293 58.401 265.107C40.1666 268.048 25.6302 270.606 15.5567 272.78C5.61065 274.826 0.127592 276.233 0.255105 276.616C0.382618 277 5.86567 276.361 15.9392 274.826C26.0127 273.291 40.6767 271.117 58.9111 268.56C77.1454 266.258 99.0776 263.444 123.688 261.27C148.298 258.84 175.585 257.178 204.531 256.027C210.396 255.771 216.135 255.643 221.873 255.387C227.611 255.259 233.349 255.259 239.214 255.132C268.16 254.748 295.575 255.132 320.185 256.282C344.795 257.178 366.855 258.84 385.217 260.247C403.578 261.909 418.242 263.188 428.444 264.339C438.39 265.362 444 265.746 444 265.362Z" fill="#1B1B1B" />
                <path d="M218.047 248.354C218.94 248.354 219.195 236.205 219.195 216.127C219.195 196.048 218.685 168.169 218.175 136.582C218.047 133.385 218.047 130.315 217.92 127.118C217.792 124.049 217.792 120.852 217.665 117.655C217.41 105.378 217.027 93.7401 216.772 82.8698C216.645 77.3708 216.517 72.2553 216.262 67.1399C216.135 64.5822 216.134 62.1524 216.007 59.8504C216.007 58.6995 215.879 57.4206 215.879 56.2696V55.3744V55.2466V54.9908C222.255 54.8629 228.121 54.735 233.221 54.6071C238.959 54.4792 243.932 54.3514 248.013 54.0956C256.174 53.7119 260.637 53.3283 260.637 52.8167C260.637 52.3052 256.046 51.9215 248.013 51.6658C243.932 51.5379 238.959 51.41 233.221 51.1542C230.416 51.1542 227.356 51.0263 224.04 51.0263C222.383 51.0263 220.725 51.0263 219.067 50.8985C216.645 50.8985 214.222 50.8985 211.799 50.7706C211.927 60.362 212.054 71.2322 212.182 82.7419C212.309 93.6122 212.564 105.25 212.819 117.527C212.947 120.724 212.947 123.793 213.074 126.99C213.202 130.06 213.202 133.257 213.329 136.454C214.094 168.042 214.859 195.921 215.752 215.999C216.517 236.205 217.41 248.354 218.047 248.354Z" fill="#2E2E2E" />
                <path d="M260.636 248.354C262.294 248.354 262.932 209.86 263.059 159.729C263.059 157.171 263.059 154.741 263.059 152.184C263.059 149.754 263.059 147.196 263.059 144.639C262.932 94.5074 262.294 56.0139 260.636 56.0139C259.106 56.0139 258.341 94.5074 258.214 144.639C258.214 147.196 258.214 149.626 258.214 152.184C258.214 154.614 258.214 157.171 258.214 159.729C258.341 209.86 259.234 248.354 260.636 248.354Z" fill="#2E2E2E" />
                <path d="M211.799 51.2821C211.927 51.1542 211.544 50.3869 210.651 48.9802C210.396 48.7244 210.014 48.2129 209.631 47.7013C209.249 47.1898 208.739 46.5503 208.229 45.9109C207.209 44.6321 205.806 42.9696 204.276 41.0513C204.148 45.783 204.148 51.9215 204.021 59.211C204.021 65.2216 204.021 72.2553 204.021 80.0563C204.021 95.5305 204.148 114.202 204.531 134.663C204.531 137.477 204.658 140.29 204.658 143.104C204.658 145.917 204.786 148.731 204.786 151.544C205.168 173.285 205.551 193.107 206.061 209.221C206.316 217.277 206.571 224.439 206.826 230.45C206.954 233.391 207.081 236.205 207.209 238.506C207.336 241.064 207.464 243.366 207.591 245.284C208.229 245.668 208.739 246.052 209.249 246.307C209.631 246.563 210.014 246.819 210.396 246.947C211.161 247.331 211.672 247.714 212.182 247.97C213.202 248.482 213.712 248.609 213.839 248.482C213.967 248.354 213.584 247.842 212.692 247.075C212.309 246.691 211.799 246.307 211.161 245.668C210.906 245.412 210.524 245.157 210.141 244.773C210.014 244.645 209.886 244.517 209.759 244.389C209.631 244.261 209.504 244.133 209.376 244.133C209.376 243.878 209.376 243.494 209.504 243.238C209.504 242.727 209.504 242.215 209.504 241.704C209.504 240.681 209.631 239.53 209.631 238.379C209.631 235.949 209.759 233.263 209.759 230.322C209.886 224.311 209.886 217.15 209.886 209.093C209.886 192.979 209.759 173.157 209.504 151.416C209.504 148.603 209.376 145.789 209.376 142.976C209.376 140.163 209.249 137.349 209.249 134.536C208.866 114.202 208.484 95.5305 208.101 79.9285C207.846 72.1274 207.591 65.2216 207.336 59.211C207.209 56.2696 207.081 53.4562 206.954 50.8985C206.826 49.4917 206.699 48.2129 206.571 47.1898C207.846 48.3407 208.994 49.2359 209.631 49.8754C211.034 51.0263 211.672 51.41 211.799 51.2821Z" fill="#2E2E2E" />
                <path d="M260.636 50.8984C260.891 50.3869 260.254 49.3638 258.596 47.957C257.831 47.1897 256.811 46.4224 255.663 45.5272C255.026 45.0157 254.516 44.632 253.751 44.1205C253.368 43.8647 253.113 43.6089 252.731 43.3532C252.603 43.2253 252.348 43.0974 252.093 42.9695C251.711 42.7137 251.455 42.458 251.073 42.2022C249.033 42.0743 246.865 42.0743 244.57 41.9464C242.53 41.9464 240.234 41.8185 237.939 41.8185C237.302 41.8185 236.536 41.8185 235.899 41.8185C235.261 41.8185 234.496 41.8185 233.859 41.8185C220.087 41.9464 209.376 42.5859 209.376 44.2484C209.376 45.783 219.96 46.5503 233.859 46.6782C234.496 46.6782 235.261 46.6782 235.899 46.6782C236.536 46.6782 237.302 46.6782 237.939 46.6782C240.234 46.6782 242.402 46.6782 244.57 46.5503C245.59 46.5503 246.61 46.4224 247.63 46.4224C248.14 46.4224 248.65 46.4224 249.033 46.4224C249.288 46.4224 249.415 46.4224 249.67 46.4224H249.798H249.925L250.435 46.6782C250.818 46.934 251.2 47.0618 251.583 47.3176C252.348 47.7013 252.986 48.0849 253.623 48.4686C254.898 49.108 256.046 49.7474 257.066 50.1311C259.234 51.0263 260.381 51.41 260.636 50.8984Z" fill="#2E2E2E" />
                <path d="M207.336 245.029C206.826 243.494 205.041 243.238 203.128 243.622L202.873 243.75L202.618 243.878C200.705 244.517 199.303 245.668 199.685 247.203C200.068 248.737 201.853 248.993 203.893 248.609L204.148 248.482L204.403 248.354C206.443 247.714 207.846 246.435 207.336 245.029Z" fill="var(--icon-secondary-color)" />
                <path d="M208.356 247.714C207.846 246.18 206.188 245.924 204.276 246.307L204.021 246.435L203.766 246.563C201.98 247.203 200.705 248.226 201.088 249.888C201.47 251.423 203.128 251.679 205.168 251.295L205.423 251.167L205.678 251.039C207.464 250.272 208.739 249.121 208.356 247.714Z" fill="#1B1B1B" />
                <path d="M213.457 248.226C212.819 246.691 210.779 246.819 208.229 247.331L207.846 247.458L207.464 247.586C204.913 248.226 203.001 249.121 203.128 250.783C203.256 252.318 205.551 252.957 208.484 252.446L208.866 252.318L209.249 252.19C212.182 251.295 214.094 249.632 213.457 248.226Z" fill="var(--icon-secondary-color)" />
                <path d="M219.832 248.993C219.322 247.458 217.154 247.458 214.732 247.842L214.349 247.97L213.967 248.098C211.544 248.737 209.631 249.505 209.759 251.167C209.886 252.702 212.054 253.341 214.987 252.83L215.369 252.702L215.752 252.574C218.557 251.934 220.342 250.4 219.832 248.993Z" fill="var(--icon-secondary-color)" />
                <path d="M206.443 245.924C205.933 244.261 203.766 244.261 201.088 244.773L200.705 244.901L200.323 245.029C197.645 245.668 195.732 246.691 195.86 248.354C196.115 249.888 198.41 250.4 201.343 249.888L201.725 249.76L202.108 249.632C204.913 248.865 206.826 247.458 206.443 245.924Z" fill="var(--icon-secondary-color)" />
                <path d="M209.886 247.586C210.014 245.924 206.316 244.901 201.47 245.029C201.215 245.029 200.96 245.029 200.705 245.029C200.45 245.029 200.195 245.029 199.94 245.029C195.095 245.412 191.524 246.819 191.779 248.482C192.034 250.016 195.605 250.144 200.195 250.016C200.45 250.016 200.705 250.016 200.833 250.016C201.088 250.016 201.343 250.016 201.47 250.016C206.188 249.633 209.759 249.121 209.886 247.586Z" fill="var(--icon-secondary-color)" />
                <path d="M210.524 249.121C210.651 247.458 207.719 246.563 203.766 246.435C203.511 246.435 203.383 246.435 203.128 246.435C202.873 246.435 202.746 246.435 202.491 246.435C198.538 246.691 195.605 247.714 195.86 249.377C195.987 250.911 198.92 251.295 202.618 251.423C202.746 251.423 203.001 251.423 203.128 251.423C203.256 251.423 203.511 251.423 203.638 251.423C207.591 251.167 210.396 250.656 210.524 249.121Z" fill="var(--icon-secondary-color)" />
                <path d="M215.242 250.4C215.369 248.737 211.926 247.714 207.463 247.586C207.208 247.586 206.953 247.586 206.826 247.586C206.571 247.586 206.443 247.586 206.188 247.586C201.725 247.586 198.282 248.482 198.282 250.144C198.41 251.679 201.725 252.318 206.061 252.446C206.316 252.446 206.443 252.446 206.698 252.446C206.953 252.446 207.081 252.446 207.336 252.446C211.671 252.446 215.114 251.934 215.242 250.4Z" fill="var(--icon-secondary-color)" />
                <path d="M276.576 241.576C275.683 240.169 272.24 241.576 268.287 244.006C268.032 244.133 267.905 244.261 267.65 244.389C267.395 244.517 267.267 244.645 267.012 244.773C263.059 247.458 260.381 249.888 261.401 251.295C262.167 252.574 265.737 251.295 269.69 248.865C269.945 248.737 270.072 248.609 270.327 248.481C270.582 248.354 270.71 248.226 270.965 248.098C274.79 245.54 277.468 242.855 276.576 241.576Z" fill="var(--icon-secondary-color)" />
                <path d="M262.677 235.309C261.019 235.309 260.381 238.123 260.254 241.831C260.254 241.959 260.254 242.215 260.254 242.343C260.254 242.471 260.254 242.727 260.254 242.855C260.381 246.563 261.019 249.377 262.677 249.377C264.207 249.377 264.972 246.563 265.099 242.855C265.099 242.727 265.099 242.471 265.099 242.343C265.099 242.215 265.099 241.959 265.099 241.831C264.972 238.123 264.207 235.309 262.677 235.309Z" fill="var(--icon-secondary-color)" />
                <path d="M267.777 238.506C266.247 237.739 264.589 239.53 263.569 242.343L263.442 242.727L263.314 243.11C262.549 246.052 262.804 248.354 264.462 248.609C265.992 248.865 267.012 246.947 268.032 244.517L268.16 244.133L268.287 243.75C268.925 241.32 269.18 239.146 267.777 238.506Z" fill="var(--icon-secondary-color)" />
                <path d="M281.804 250.016C281.804 248.354 278.871 247.714 275.045 247.586C274.79 247.586 274.663 247.586 274.535 247.586C274.408 247.586 274.153 247.586 274.025 247.586C270.2 247.714 267.267 248.354 267.267 250.016C267.267 251.551 270.2 252.318 274.025 252.446C274.28 252.446 274.408 252.446 274.535 252.446C274.663 252.446 274.918 252.446 275.045 252.446C278.871 252.318 281.804 251.551 281.804 250.016Z" fill="var(--icon-secondary-color)" />
                <path d="M273.26 237.1C271.858 236.204 269.945 238.251 267.65 240.936L267.395 241.32L267.012 241.704C264.972 244.517 263.442 246.819 264.589 248.098C265.609 249.249 268.287 247.714 270.838 244.773L271.22 244.261L271.603 243.75C273.898 240.808 274.663 237.867 273.26 237.1Z" fill="var(--icon-secondary-color)" />
                <path d="M276.703 248.737C276.703 247.075 274.79 246.435 272.24 246.307C272.112 246.307 271.985 246.307 271.857 246.307C271.73 246.307 271.602 246.307 271.475 246.307C268.925 246.435 267.012 247.075 267.012 248.737C267.012 250.272 268.925 251.039 271.475 251.167C271.602 251.167 271.73 251.167 271.857 251.167C271.985 251.167 272.112 251.167 272.24 251.167C274.79 251.039 276.703 250.272 276.703 248.737Z" fill="var(--icon-secondary-color)" />
                <path d="M179.283 91.0545L307.051 64.9658L350.405 83.765L318.4 119.061H170.995L178.518 88.7525" fill="white" />
                <path d="M178.518 88.7526C178.39 88.7526 178.135 89.392 177.753 90.6708C177.37 91.9497 176.733 93.9959 176.095 96.5536C175.458 99.1113 174.565 102.308 173.545 106.017C172.525 109.982 171.25 114.585 169.975 119.701C186.041 119.829 207.591 119.957 232.966 120.085C258.214 120.085 287.414 120.212 318.782 120.212C320.567 118.294 322.353 116.248 324.265 114.202C327.836 110.237 331.278 106.401 334.849 102.564C338.292 98.7276 341.862 94.8911 345.432 90.9266C347.728 88.3689 350.023 85.8112 352.318 83.3814C336.506 76.6034 321.332 69.9534 307.051 63.8149C288.179 67.7793 270.71 71.3601 255.026 74.5573C239.597 77.7544 225.953 80.6958 214.732 82.9977C203.51 85.4275 194.585 87.3458 188.464 88.7526C185.404 89.392 183.108 90.0314 181.578 90.4151C180.048 90.7987 179.156 91.0545 179.283 91.1824C179.283 91.3103 180.176 91.1824 181.706 90.9266C183.236 90.6708 185.659 90.2872 188.719 89.6477C194.84 88.4968 203.766 86.8343 215.114 84.6602C226.335 82.4862 239.979 79.6727 255.536 76.6034C270.965 73.5342 288.179 70.0813 306.923 66.2447C320.057 71.9995 333.956 78.0102 348.365 84.2766C346.835 85.9391 345.305 87.6016 343.775 89.2641C340.204 93.2285 336.761 97.0651 333.191 100.902C329.621 104.738 326.178 108.575 322.608 112.539C320.95 114.33 319.42 116.12 317.89 117.783C286.776 117.783 257.959 117.91 233.093 117.91C220.597 117.91 208.994 118.038 198.537 118.038C188.464 118.166 179.538 118.166 171.887 118.294C172.907 113.818 173.928 109.854 174.693 106.273C175.585 102.564 176.35 99.367 176.86 96.6815C177.498 94.1237 177.88 92.0776 178.135 90.7987C178.518 89.5199 178.645 88.7526 178.518 88.7526Z" fill="var(--icon-color)" />
                <path d="M349.13 81.2073C349.003 81.3352 349.258 81.7188 349.768 82.3582C350.278 82.8698 350.916 83.6371 351.681 84.5323C351.426 84.7881 351.171 85.0438 350.916 85.2996C350.788 85.4275 350.661 85.5554 350.533 85.6833C350.278 85.939 350.151 86.1948 349.896 86.3227C349.003 87.2179 347.983 88.3689 346.835 89.5198C344.668 91.8218 342.117 94.6353 339.312 97.7045C336.507 100.774 333.446 104.227 330.004 108.063C326.816 111.644 323.5 115.481 319.803 119.573C314.064 119.445 307.944 119.317 301.568 119.189C294.81 119.061 287.797 118.933 280.401 118.806C277.723 118.806 275.045 118.678 272.368 118.678C269.69 118.678 267.012 118.55 264.334 118.55C237.557 118.294 213.839 118.166 196.88 118.294C179.921 118.422 169.592 118.933 169.592 119.701C169.592 120.468 179.921 121.235 196.88 121.747C213.839 122.386 237.557 122.898 264.334 123.282C267.012 123.282 269.69 123.282 272.368 123.409C275.045 123.409 277.723 123.537 280.401 123.537C287.669 123.537 294.81 123.665 301.568 123.665C308.709 123.665 315.467 123.665 321.843 123.793C325.796 119.061 329.621 114.585 333.064 110.493C336.252 106.529 339.312 102.948 341.99 99.7507C344.668 96.5535 346.963 93.6122 349.003 91.0545C351.171 88.241 352.956 85.939 354.359 84.1486C353.466 83.3813 352.573 82.8698 351.936 82.3582C351.553 82.1025 351.426 81.9746 351.171 81.8467C350.916 81.7188 350.788 81.5909 350.533 81.463C349.641 81.2073 349.258 81.0794 349.13 81.2073Z" fill="var(--icon-color)" />
                <path d="M308.326 162.67L180.558 136.582L137.204 155.381L169.21 190.677H316.615L309.091 160.368" fill="white" />
                <path d="M309.091 160.368C308.964 160.368 309.091 161.136 309.346 162.415C309.601 163.821 309.984 165.74 310.621 168.297C311.259 170.855 311.897 174.052 312.789 177.889C313.554 181.342 314.574 185.306 315.594 189.782C307.944 189.782 299.018 189.654 288.944 189.526C278.488 189.526 267.012 189.398 254.388 189.398C229.523 189.398 200.705 189.271 169.592 189.271C168.062 187.608 166.404 185.818 164.874 184.027C161.304 180.063 157.861 176.226 154.291 172.39C150.848 168.553 147.277 164.717 143.707 160.752C142.177 159.09 140.647 157.427 139.117 155.765C153.526 149.498 167.424 143.488 180.558 137.733C199.175 141.441 216.517 145.022 231.946 148.091C247.503 151.161 261.146 153.846 272.368 156.148C283.589 158.322 292.642 160.113 298.763 161.136C301.823 161.647 304.246 162.159 305.776 162.415C307.434 162.67 308.199 162.798 308.199 162.67C308.199 162.542 307.434 162.415 305.776 161.903C304.246 161.519 301.823 161.008 298.89 160.241C292.77 158.834 283.971 156.916 272.623 154.486C261.401 152.056 247.758 149.242 232.328 146.045C216.772 142.848 199.303 139.139 180.303 135.303C166.022 141.441 150.848 148.091 135.036 154.869C137.331 157.427 139.627 159.857 141.922 162.415C145.492 166.379 148.935 170.216 152.505 174.052C155.948 177.889 159.519 181.725 163.089 185.69C165.002 187.736 166.787 189.782 168.572 191.7C200.068 191.7 229.141 191.572 254.388 191.572C279.891 191.445 301.313 191.317 317.38 191.189C316.104 186.073 314.829 181.47 313.809 177.505C312.789 173.796 312.024 170.599 311.259 168.042C310.621 165.484 309.984 163.566 309.601 162.159C309.346 161.136 309.091 160.368 309.091 160.368Z" fill="var(--icon-color)" />
                <path d="M138.479 152.823C138.351 152.695 137.969 152.823 137.204 153.335C137.076 153.463 136.821 153.59 136.566 153.718C136.311 153.846 136.184 153.974 135.801 154.23C135.164 154.741 134.271 155.253 133.378 156.02C134.781 157.811 136.566 160.241 138.734 162.926C140.647 165.484 143.069 168.297 145.747 171.622C148.425 174.819 151.358 178.4 154.673 182.365C158.116 186.457 161.941 190.933 165.894 195.665C172.27 195.665 179.028 195.537 186.169 195.537C192.927 195.537 200.068 195.409 207.336 195.409C210.014 195.409 212.691 195.281 215.369 195.281C218.047 195.281 220.725 195.281 223.403 195.153C250.18 194.77 273.77 194.258 290.857 193.619C307.816 192.979 318.145 192.34 318.145 191.445C318.145 190.677 307.816 190.294 290.857 190.038C273.898 189.91 250.18 189.91 223.403 190.166C220.725 190.166 218.047 190.294 215.369 190.294C212.691 190.294 210.014 190.294 207.336 190.421C200.068 190.549 192.927 190.677 186.169 190.805C179.793 190.933 173.673 191.061 167.934 191.189C164.364 187.096 160.921 183.26 157.733 179.679C154.291 175.97 151.23 172.39 148.425 169.32C145.62 166.251 143.069 163.438 140.902 161.136C139.754 159.985 138.861 158.962 137.841 157.939C137.586 157.683 137.331 157.427 137.204 157.299C137.076 157.171 136.949 157.043 136.821 156.915C136.566 156.66 136.311 156.404 136.056 156.148C136.949 155.253 137.586 154.486 138.096 153.974C138.351 153.335 138.606 152.951 138.479 152.823Z" fill="var(--icon-color)" />
                <path d="M292.259 103.971C292.259 104.227 292.387 104.482 292.515 104.61C292.897 105.25 293.535 105.633 294.172 105.761C294.682 105.889 295.575 106.145 295.957 105.633C296.212 105.25 296.085 104.61 296.085 104.227C295.957 103.076 295.32 101.541 293.917 101.669C292.642 101.669 292.132 102.948 292.259 103.971Z" fill="white" />
                <path d="M292.26 103.971C292.26 103.971 292.26 103.843 292.387 103.459C292.387 103.204 292.642 102.82 293.152 102.564C293.407 102.436 293.662 102.436 293.79 102.564C293.917 102.692 294.045 102.692 294.172 102.82C294.172 102.948 294.3 102.948 294.427 103.076C294.555 103.204 294.81 103.459 294.937 103.715C294.937 103.843 295.065 103.971 295.065 104.099C295.065 104.227 295.192 104.355 295.192 104.482C295.192 104.738 295.32 104.866 295.32 104.994C295.32 105.122 295.32 105.25 295.447 105.25C295.702 105.378 295.575 105.378 295.575 105.25C295.447 105.122 295.575 105.25 295.447 105.25C295.447 105.25 295.32 105.378 295.065 105.378C294.81 105.378 294.427 105.378 294.172 105.378C293.917 105.378 293.662 105.378 293.407 105.25C292.897 105.122 292.642 104.738 292.515 104.482C292.26 104.099 292.26 103.971 292.26 103.971C292.26 103.971 292.26 104.099 292.26 104.482C292.387 104.738 292.515 105.25 293.025 105.633C293.28 105.889 293.535 106.017 293.79 106.145C294.172 106.273 294.427 106.401 294.81 106.529C295.065 106.529 295.32 106.657 295.575 106.529C295.83 106.529 296.34 106.273 296.595 105.889C296.978 105.122 296.85 104.738 296.85 104.227C296.85 104.099 296.85 103.843 296.85 103.587C296.85 103.332 296.85 103.204 296.723 102.948C296.595 102.436 296.468 101.925 296.085 101.413C295.702 100.902 295.192 100.518 294.427 100.39C293.79 100.262 293.28 100.518 292.77 100.774C292.387 101.03 292.132 101.541 292.005 101.797C291.877 102.436 291.877 102.948 292.005 103.332C292.132 103.843 292.26 103.971 292.26 103.971Z" fill="var(--icon-color)" />
                <path d="M291.495 82.9977C292.26 79.9285 295.575 77.7544 298.635 78.1381C301.823 78.6496 304.246 81.7189 304.118 84.916C303.991 88.1131 301.058 90.9266 297.998 90.9266C297.233 90.9266 296.468 90.7987 295.703 91.0545C294.045 91.4382 293.152 93.2286 293.152 94.8911C293.152 96.5536 293.79 98.0882 294.427 99.6228" fill="white" />
                <path d="M294.555 99.6228C294.555 99.6228 294.555 99.4949 294.427 99.2392C294.3 98.9834 294.3 98.5997 294.172 98.2161C294.045 97.8324 293.917 97.193 293.79 96.5536C293.662 95.9141 293.662 95.1468 293.917 94.5074C294.045 93.868 294.427 93.1007 295.065 92.5891C295.32 92.3333 295.702 92.2055 295.958 92.0776C296.213 91.9497 296.723 91.9497 297.233 92.0776C297.743 92.0776 298.508 92.2055 299.145 91.9497C299.91 91.8218 300.548 91.566 301.186 91.1824C302.461 90.5429 303.481 89.392 304.246 88.1131C304.501 87.6016 304.756 86.9621 304.883 86.4506C305.011 85.8112 305.138 85.2996 305.138 84.6602C305.138 83.1256 304.501 81.7188 303.736 80.5679C302.971 79.4169 301.823 78.6496 300.803 78.138C299.655 77.6265 298.508 77.4986 297.36 77.6265C296.34 77.7544 295.447 78.1381 294.682 78.6496C293.152 79.5448 292.387 80.8236 292.005 81.591C291.622 82.4862 291.622 82.9977 291.622 82.9977C291.622 82.9977 291.75 82.4862 292.26 81.7188C292.77 80.9515 293.662 79.9284 295.065 79.1611C295.83 78.7775 296.595 78.5217 297.615 78.5217C298.508 78.5217 299.528 78.7775 300.42 79.289C301.313 79.8006 302.078 80.5679 302.588 81.4631C303.098 82.3583 303.481 83.5092 303.353 84.6602C303.353 85.0439 303.226 85.5554 303.098 85.9391C302.971 86.3227 302.716 86.7064 302.461 87.09C301.823 87.9852 300.931 88.7525 300.038 89.1362C299.528 89.392 299.145 89.5199 298.635 89.6477C298.125 89.7756 297.743 89.7756 297.105 89.7756C296.468 89.7756 295.83 89.7756 295.065 90.1593C294.427 90.4151 293.917 90.9266 293.407 91.4381C292.642 92.4612 292.387 93.4843 292.387 94.5074C292.387 95.5305 292.642 96.2978 292.897 96.9372C293.152 97.5766 293.407 98.0882 293.662 98.4718C294.3 99.2392 294.555 99.6228 294.555 99.6228Z" fill="var(--icon-color)" />
                <path d="M191.142 177.889C191.142 178.144 191.269 178.4 191.397 178.528C191.779 179.168 192.417 179.551 193.054 179.679C193.565 179.807 194.457 180.063 194.84 179.551C195.095 179.168 194.967 178.528 194.967 178.144C194.84 176.994 194.202 175.459 192.799 175.587C191.524 175.587 191.014 176.866 191.142 177.889Z" fill="white" />
                <path d="M191.142 177.889C191.142 177.889 191.142 177.761 191.269 177.377C191.269 177.121 191.524 176.738 192.035 176.482C192.29 176.354 192.545 176.354 192.672 176.482C192.8 176.61 192.927 176.61 193.055 176.738C193.055 176.866 193.182 176.866 193.31 176.994C193.437 177.121 193.692 177.377 193.82 177.633C193.82 177.761 193.947 177.889 193.947 178.017C193.947 178.144 194.075 178.272 194.075 178.4C194.075 178.656 194.202 178.784 194.202 178.912C194.202 179.04 194.202 179.168 194.33 179.168C194.585 179.295 194.457 179.295 194.457 179.168C194.33 179.04 194.457 179.168 194.33 179.168C194.33 179.168 194.202 179.295 193.947 179.295C193.692 179.295 193.31 179.295 193.055 179.295C192.8 179.295 192.545 179.295 192.29 179.168C191.779 179.04 191.524 178.656 191.397 178.4C191.142 178.017 191.142 177.889 191.142 177.889C191.142 177.889 191.142 178.017 191.142 178.4C191.269 178.656 191.397 179.168 191.907 179.551C192.162 179.807 192.417 179.935 192.672 180.063C193.055 180.191 193.31 180.319 193.692 180.446C193.947 180.446 194.202 180.574 194.457 180.446C194.712 180.446 195.222 180.191 195.477 179.807C195.86 179.04 195.732 178.656 195.732 178.145C195.732 178.017 195.732 177.761 195.732 177.505C195.732 177.249 195.732 177.121 195.605 176.866C195.477 176.354 195.35 175.843 194.967 175.331C194.585 174.819 194.075 174.436 193.31 174.308C192.672 174.18 192.162 174.436 191.652 174.692C191.269 174.947 191.014 175.459 190.887 175.715C190.759 176.354 190.759 176.866 190.887 177.249C191.142 177.633 191.142 177.889 191.142 177.889Z" fill="var(--icon-color)" />
                <path d="M190.504 156.915C191.269 153.846 194.585 151.672 197.645 152.056C200.833 152.567 203.256 155.637 203.128 158.834C203.001 162.031 200.068 164.844 197.008 164.844C196.242 164.844 195.477 164.717 194.712 164.972C193.055 165.356 192.162 167.146 192.162 168.809C192.162 170.471 192.8 172.006 193.437 173.541" fill="white" />
                <path d="M193.437 173.541C193.437 173.541 193.437 173.413 193.31 173.157C193.182 172.901 193.182 172.518 193.055 172.134C192.927 171.75 192.8 171.111 192.672 170.471C192.545 169.832 192.545 169.065 192.8 168.425C192.927 167.786 193.31 167.018 193.947 166.507C194.202 166.251 194.585 166.123 194.84 165.995C195.095 165.867 195.605 165.867 196.115 165.995C196.625 165.995 197.39 166.123 198.028 165.867C198.793 165.74 199.43 165.484 200.068 165.1C201.343 164.461 202.363 163.31 203.128 162.031C203.383 161.519 203.638 160.88 203.766 160.368C203.893 159.729 204.021 159.217 204.021 158.578C204.021 157.043 203.383 155.637 202.618 154.486C201.853 153.335 200.705 152.567 199.685 152.056C198.538 151.544 197.39 151.416 196.242 151.544C195.222 151.672 194.33 152.056 193.565 152.567C192.035 153.463 191.269 154.741 190.887 155.509C190.504 156.404 190.504 156.915 190.504 156.915C190.504 156.915 190.632 156.404 191.142 155.637C191.652 154.869 192.545 153.846 193.947 153.079C194.712 152.695 195.477 152.439 196.498 152.439C197.39 152.439 198.41 152.695 199.303 153.207C200.195 153.718 200.96 154.486 201.471 155.381C201.981 156.276 202.363 157.427 202.236 158.578C202.236 158.962 202.108 159.473 201.981 159.857C201.853 160.241 201.598 160.624 201.343 161.008C200.705 161.903 199.813 162.67 198.92 163.054C198.41 163.31 198.028 163.438 197.518 163.566C197.008 163.693 196.625 163.693 195.987 163.693C195.35 163.693 194.712 163.693 193.947 164.077C193.31 164.333 192.8 164.844 192.417 165.356C191.652 166.379 191.397 167.402 191.397 168.425C191.397 169.448 191.652 170.216 191.907 170.855C192.162 171.494 192.417 172.006 192.672 172.39C193.182 173.157 193.437 173.541 193.437 173.541Z" fill="var(--icon-color)" />
                <path d="M379.351 238.379C378.076 237.356 374.76 240.169 371.955 245.157C371.828 245.412 371.7 245.668 371.573 245.924C371.445 246.18 371.318 246.435 371.19 246.691C368.767 251.807 368.257 256.283 369.787 256.666C371.318 257.05 373.103 253.469 375.526 248.865C375.653 248.609 375.781 248.354 375.908 248.226C376.036 247.97 376.163 247.714 376.291 247.586C378.586 242.855 380.626 239.274 379.351 238.379Z" fill="var(--icon-secondary-color)" />
                <path d="M360.351 224.695C359.841 225.334 360.606 226.741 361.882 229.043C363.157 231.345 364.687 234.542 365.834 238.379C365.962 238.762 366.09 239.146 366.217 239.53C366.345 239.913 366.472 240.297 366.472 240.681C367.492 244.645 368.002 248.354 368.64 251.039C369.277 253.725 369.915 255.387 370.807 255.26C371.573 255.26 372.083 253.469 372.21 250.656C372.338 247.842 372.21 243.878 371.19 239.402C371.063 239.018 370.935 238.506 370.807 238.123C370.68 237.739 370.552 237.228 370.425 236.844C369.022 232.496 366.727 228.915 364.687 226.741C362.519 224.695 360.861 224.055 360.351 224.695Z" fill="var(--icon-secondary-color)" />
                <path d="M381.136 250.911C381.136 250.144 380.371 249.505 379.351 249.249C378.203 248.993 376.673 249.249 375.143 249.76L374.633 250.016L374.123 250.272C372.593 251.039 371.445 252.062 370.808 253.085C370.17 254.108 370.17 254.876 370.808 255.515C371.955 256.666 373.868 255.771 376.291 254.748L376.673 254.62L377.056 254.492C379.223 253.213 381.264 252.446 381.136 250.911Z" fill="var(--icon-secondary-color)" />
                <path d="M359.331 249.121C359.459 250.016 360.224 250.4 361.117 250.783C362.009 251.167 363.157 251.551 364.432 252.318L364.814 252.446L365.197 252.702C366.472 253.341 367.365 254.236 368.13 254.748C368.895 255.259 369.66 255.643 370.552 255.387C371.19 255.132 371.573 254.236 371.19 252.83C370.807 251.423 369.66 249.76 367.875 248.609C367.747 248.482 367.492 248.354 367.365 248.226C367.11 248.098 366.982 247.97 366.727 247.97C364.687 247.075 362.647 246.947 361.372 247.331C359.841 247.586 359.204 248.354 359.331 249.121Z" fill="var(--icon-secondary-color)" />
                <path d="M357.674 239.146C357.036 240.68 358.056 241.831 359.586 242.599C359.714 242.599 359.714 242.727 359.841 242.727C359.969 242.727 359.969 242.855 360.096 242.855C361.754 243.494 363.284 243.366 363.922 241.831C364.559 240.425 363.539 239.146 362.009 238.379C361.882 238.379 361.882 238.251 361.754 238.251C361.627 238.251 361.627 238.123 361.499 238.123C359.841 237.483 358.311 237.739 357.674 239.146Z" fill="#1B1B1B" />
                <path d="M118.969 231.856C117.694 230.833 112.976 235.309 108.896 242.727C108.641 243.11 108.513 243.494 108.258 243.878C108.131 244.261 107.876 244.645 107.621 245.029C103.923 252.574 102.903 259.096 104.433 259.48C105.963 259.863 108.258 254.236 111.956 247.203C112.084 246.819 112.339 246.435 112.466 246.18C112.594 245.796 112.849 245.54 112.976 245.156C116.802 237.995 120.245 232.879 118.969 231.856Z" fill="var(--icon-secondary-color)" />
                <path d="M90.1516 211.139C89.5141 211.778 91.1717 213.825 93.212 217.15C95.2522 220.475 97.8024 225.462 99.5876 231.473C99.7151 232.112 99.9701 232.624 100.098 233.263C100.225 233.903 100.353 234.414 100.608 235.054C102.138 241.192 102.903 246.819 103.668 250.911C104.433 255.004 105.198 257.434 105.963 257.434C106.728 257.434 107.111 254.876 107.111 250.656C107.111 246.435 106.856 240.553 105.326 233.903C105.198 233.263 104.943 232.624 104.816 231.984C104.688 231.345 104.433 230.705 104.306 230.066C102.265 223.544 99.0775 218.173 96.1448 215.103C93.0844 211.778 90.6617 210.627 90.1516 211.139Z" fill="var(--icon-secondary-color)" />
                <path d="M121.647 250.783C121.647 250.016 120.627 249.377 119.097 249.249C117.567 249.121 115.272 249.377 112.976 250.272C112.721 250.4 112.466 250.528 112.339 250.528C112.084 250.656 111.829 250.783 111.701 250.783C109.406 251.934 107.748 253.341 106.728 254.492C105.708 255.771 105.453 256.794 106.091 257.434C107.238 258.584 110.044 256.794 113.742 255.132C113.869 255.004 114.124 255.004 114.252 254.876C114.379 254.748 114.634 254.748 114.762 254.62C118.46 253.213 121.647 252.318 121.647 250.783Z" fill="var(--icon-secondary-color)" />
                <path d="M88.749 248.098C88.8765 248.993 89.8966 249.377 91.4267 249.76C92.9569 250.144 94.8696 250.783 96.9098 251.807C97.1648 251.934 97.2923 252.062 97.5473 252.062C97.8024 252.19 97.9299 252.318 98.1849 252.446C100.225 253.597 101.628 255.004 102.775 256.155C103.923 257.178 104.816 257.945 105.581 257.561C106.218 257.306 106.473 256.027 105.836 254.108C105.198 252.318 103.413 249.888 100.863 248.226C100.608 248.098 100.353 247.842 100.098 247.714C99.8426 247.586 99.5876 247.458 99.3325 247.331C96.5272 245.924 93.722 245.668 91.6818 246.052C89.514 246.563 88.6214 247.331 88.749 248.098Z" fill="var(--icon-secondary-color)" />
                <path d="M86.1988 233.007C85.5612 234.542 87.2189 235.949 89.6416 237.1C89.7691 237.1 89.8966 237.228 90.0242 237.228C90.1517 237.228 90.2792 237.356 90.4067 237.356C92.957 238.379 95.1247 238.506 95.7622 236.972C96.3998 235.565 94.7421 234.03 92.3194 232.88C92.1919 232.88 92.0644 232.752 91.9368 232.752C91.8093 232.752 91.6818 232.624 91.5543 232.624C89.0041 231.729 86.8363 231.601 86.1988 233.007Z" fill="#1B1B1B" />
              </g>
              <defs>
                <clipPath id="clip0_4601_9587">
                  <rect width="444" height="277" fill="white" />
                </clipPath>
              </defs>
            </svg>

          </div>
          <div className='my-3 text-center'>
            <label className='Gt-pink-8 Gfs-2 '>Oops! This page is stuck in traffic.</label>
          </div>
          <div className='text-center w-75'>
            <label className='Gfs-125 sans-medium'>
              The page you're searching for has hit some internet congestion. As we wait for the traffic to clear, cruise around the rest of our site from the homepage. Redirecting you back to your dashboard in <Timer seconds={5} callback={() => window.location.href = url} /> seconds...
            </label>
          </div>
          <div className='my-2'>
            <a href={url}>
              <button className="bg-transparent border-0 G-orange-button " >
                Go back Home
              </button>
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ErrorPage