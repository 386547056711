import React, { Component } from "react";
import { connect } from "react-redux";

import { responseMessage } from "../../../utils/alert";

import Footer from "../../Includes/Footer/Footer";

import ugift_logo from "../../../common/img/ugift_logo.png";

import { fetchBeneficiaryProfiles } from "../../../actions/fiveTwoNineActions";
import Header from "../../Includes/Header/Header";

class ContributionWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiary_name: "",
      picture_url: "",
      picture_thumb_url: "",
      welcome_message: "",
      educational_goals: "",
      servicerAccountId: this.props.match.params.servicerAccountId,
    };
  }
  componentDidMount() {
    document.title = "Vault | Contributions Welcome";
    const { fetchBeneficiaryProfiles } = this.props;
    fetchBeneficiaryProfiles(this.state.servicerAccountId);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.beneficiaryProfiles !== this.props.beneficiaryProfiles) {
      const beneficiaryProfiles = this.props.beneficiaryProfiles;

      const beneficiaryProfilesData = beneficiaryProfiles.find((item) => item.attributes.servicer_account_id === this.state.servicerAccountId);
      if (beneficiaryProfilesData) {
        this.setState({
          beneficiary_name: beneficiaryProfilesData.attributes.beneficiary_name,
          welcome_message: beneficiaryProfilesData.attributes.welcome_message,
          picture_url: beneficiaryProfilesData.attributes.picture_url,
          picture_thumb_url: beneficiaryProfilesData.attributes.picture_thumb_url,
          educational_goals: beneficiaryProfilesData.attributes.educational_goals,
        });
      }
    }
    if (prevProps.errors !== this.props.errors) {
      responseMessage("error", "Error", "");
    }
  }

  render() {
    const { beneficiary_name, welcome_message, educational_goals, picture_thumb_url, servicerAccountId } = this.state;
    return (
      <>
        {/* <MainHeader {...this.props} /> */}
        <Header showLogoOnly={true} />
        <div className="G-page-background-color">
          <div className="G-page-main-container contribution-welcome-page-main-div p-4 heroBannerBackground Gborder-1 rounded-lg my-5 d-flex contribuion-page-div">
            <div className="my-auto ">
              <div>
                <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29.9198 22.6671C30.438 23.5612 31.1829 24.3036 32.0798 24.8197C32.9768 25.3358 33.9941 25.6075 35.0296 25.6075C36.0652 25.6075 37.0825 25.3358 37.9794 24.8197C38.8764 24.3036 39.6213 23.5612 40.1395 22.6671L44.1454 15.7417L48.1512 8.82454C48.6706 7.93071 48.9447 6.91613 48.9457 5.88306C48.9466 4.84999 48.6745 3.83493 48.1568 2.94013C47.639 2.04533 46.894 1.30243 45.9967 0.786255C45.0993 0.270083 44.0814 -0.00110797 43.0455 3.40219e-06C42.0096 -0.000279197 40.9921 0.272037 40.0956 0.789477C39.1991 1.30692 38.4554 2.05113 37.9397 2.94695L29.9198 16.8223C29.4033 17.7161 29.1313 18.7295 29.1313 19.7611C29.1313 20.7926 29.4033 21.8061 29.9198 22.6998" fill="white" />
                  <path d="M25.9131 15.7417L26.3645 16.5194L35.903 3.40219e-06H27.013C25.9771 -0.00110797 24.9591 0.270083 24.0618 0.786255C23.1644 1.30243 22.4194 2.04533 21.9017 2.94013C21.384 3.83493 21.1118 4.84999 21.1128 5.88306C21.1138 6.91613 21.3878 7.93071 21.9072 8.82454L25.9131 15.7417Z" fill="white" />
                  <path d="M17.187 30.0919C17.7044 30.9857 18.4487 31.7278 19.345 32.2437C20.2413 32.7596 21.2579 33.031 22.2928 33.0307H22.5062C23.5421 33.0318 24.5601 32.7606 25.4574 32.2445C26.3548 31.7283 27.0999 30.9854 27.6176 30.0906C28.1353 29.1958 28.4074 28.1807 28.4064 27.1476C28.4054 26.1146 28.1314 25.1 27.612 24.2062L23.5076 17.0352L19.3541 9.86426C18.8359 8.97017 18.0909 8.22778 17.194 7.71165C16.2971 7.19552 15.2797 6.92383 14.2442 6.92383C13.2086 6.92383 12.1913 7.19552 11.2944 7.71165C10.3975 8.22778 9.65241 8.97017 9.1342 9.86426L9.01927 10.0689C8.50134 10.9652 8.22876 11.9814 8.22876 13.0159C8.22876 14.0503 8.50134 15.0666 9.01927 15.9629L17.187 30.0919Z" fill="white" />
                  <path d="M5.90045 33.0322H15.1188L5.39151 16.2754L4.94821 17.0367L0.794663 24.2077C0.275255 25.1015 0.00121872 26.1161 0.000246736 27.1491C-0.00072525 28.1822 0.271327 29.1973 0.789051 30.0921C1.30678 30.9869 2.0519 31.7298 2.94925 32.246C3.8466 32.7621 4.86452 33.0333 5.90045 33.0322Z" fill="white" />
                  <path d="M22.408 47.6176C23.4425 47.6162 24.4586 47.3441 25.3546 46.8284C26.2506 46.3127 26.9951 45.5715 27.5138 44.6788L27.6205 44.4905C28.1376 43.5957 28.4095 42.5809 28.4089 41.5481C28.4082 40.5153 28.1351 39.5009 27.6169 38.6067C27.0987 37.7125 26.3536 36.97 25.4566 36.4538C24.5596 35.9377 23.5421 35.666 22.5065 35.666H5.90851C4.87286 35.666 3.85544 35.9377 2.95841 36.4538C2.06138 36.97 1.31633 37.7125 0.798116 38.6067C0.279905 39.5009 0.00674237 40.5153 0.00610463 41.5481C0.0054669 42.5809 0.277402 43.5957 0.794508 44.4905L0.917559 44.6952C1.43464 45.5916 2.17983 46.3362 3.07775 46.8536C3.97567 47.371 4.99461 47.6431 6.03166 47.6422L22.408 47.6176Z" fill="white" />
                  <path d="M23.8947 50.877H4.4729L4.9161 51.6464L9.06975 58.8174C9.58683 59.7117 10.3309 60.4544 11.2272 60.9707C12.1235 61.4871 13.1404 61.759 14.1755 61.759C15.2107 61.759 16.2275 61.4871 17.1238 60.9707C18.0201 60.4544 18.7642 59.7117 19.2813 58.8174L23.435 51.6464L23.8947 50.877Z" fill="white" />
                  <path d="M40.1886 46.0229L40.0819 45.8428C39.5637 44.9487 38.8187 44.2063 37.9218 43.6902C37.0248 43.174 36.0075 42.9023 34.972 42.9023C33.9364 42.9023 32.9191 43.174 32.0222 43.6902C31.1252 44.2063 30.3802 44.9487 29.862 45.8428L25.692 53.0056L21.5876 60.1765C21.0682 61.0704 20.7942 62.0849 20.7932 63.118C20.7922 64.1511 21.0644 65.1661 21.5821 66.0609C22.0998 66.9557 22.8449 67.6987 23.7422 68.2148C24.6396 68.731 25.6575 69.0022 26.6934 69.0011H26.9315C27.9684 69.0011 28.9869 68.7288 29.8847 68.2114C30.7825 67.6941 31.5278 66.95 32.0455 66.0541L40.2542 51.9004C40.7707 51.0067 41.0426 49.9932 41.0426 48.9617C41.0426 47.9301 40.7707 46.9166 40.2542 46.0229" fill="white" />
                  <path d="M44.2265 53.0041L43.7751 52.1855L34.0806 68.9669H43.2744C44.3103 68.968 45.3282 68.6968 46.2256 68.1806C47.1229 67.6645 47.8679 66.9215 48.3857 66.0267C48.9034 65.1319 49.1754 64.1169 49.1745 63.0838C49.1735 62.0507 48.8996 61.0362 48.3802 60.1423L44.2265 53.0041Z" fill="white" />
                  <path d="M52.7806 38.6552C52.2628 37.7624 51.5182 37.0215 50.6218 36.507C49.7255 35.9925 48.7091 35.7227 47.6748 35.7246H47.4614C46.4267 35.7243 45.4101 35.9957 44.5139 36.5114C43.6177 37.0272 42.8735 37.7691 42.356 38.6627C41.8385 39.5562 41.566 40.5699 41.5659 41.6018C41.5658 42.6336 41.8383 43.6473 42.3556 44.5409L46.501 51.7119L50.6546 58.8829C51.1728 59.777 51.9178 60.5193 52.8147 61.0355C53.7116 61.5516 54.729 61.8233 55.7645 61.8233C56.8 61.8233 57.8174 61.5516 58.7143 61.0355C59.6112 60.5193 60.3562 59.777 60.8744 58.8829L60.9893 58.6782C61.5073 57.7835 61.78 56.7685 61.78 55.7353C61.78 54.7022 61.5073 53.6872 60.9893 52.7925L52.7806 38.6552Z" fill="white" />
                  <path d="M64.0999 35.7246H54.8816L64.6089 52.4814L65.0521 51.7119L69.2057 44.5491C69.7251 43.6553 69.9991 42.6407 70 41.6077C70.001 40.5746 69.729 39.5595 69.2112 38.6647C68.6935 37.7699 67.9485 37.027 67.0511 36.5108C66.1538 35.9947 65.1359 35.7235 64.0999 35.7246Z" fill="white" />
                  <path d="M69.1158 24.0501C68.597 23.1558 67.8513 22.4133 66.9536 21.8975C66.0559 21.3816 65.0379 21.1104 64.0018 21.1113H47.5844C46.5494 21.1152 45.5334 21.3893 44.6376 21.9063C43.7418 22.4233 42.9974 23.1653 42.4785 24.0583L42.3718 24.2384C41.8547 25.1332 41.5829 26.148 41.5835 27.1808C41.5841 28.2136 41.8573 29.2281 42.3755 30.1223C42.8937 31.0165 43.6387 31.7589 44.5357 32.2751C45.4327 32.7913 46.4503 33.063 47.4859 33.063H64.0839C65.1195 33.063 66.1369 32.7913 67.034 32.2751C67.931 31.7589 68.6761 31.0165 69.1943 30.1223C69.7125 29.2281 69.9856 28.2136 69.9863 27.1808C69.9869 26.148 69.715 25.1332 69.1979 24.2384L69.1158 24.0501Z" fill="white" />
                  <path d="M46.1069 17.8777H65.5287L65.0854 17.1164L60.9319 9.94543C60.4148 9.05119 59.6706 8.30851 58.7743 7.79213C57.878 7.27576 56.8611 7.00391 55.826 7.00391C54.7909 7.00391 53.774 7.27576 52.8777 7.79213C51.9814 8.30851 51.2373 9.05119 50.7202 9.94543L46.5667 17.1164L46.1069 17.8777Z" fill="white" />
                </svg>
              </div>
              <h2 className=" text-white mt-2">{beneficiary_name ? `${beneficiary_name}'s` : ""} 529<br /> Contribution Page</h2>
              <p className="sans-medium text-white">
                {welcome_message ? welcome_message : "N/A"}
              </p>
              <div className="d-flex mt-5">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2.5C10.6868 2.5 9.38642 2.75866 8.17317 3.2612C6.95991 3.76375 5.85752 4.50035 4.92893 5.42893C3.05357 7.3043 2 9.84784 2 12.5C2 15.1522 3.05357 17.6957 4.92893 19.5711C5.85752 20.4997 6.95991 21.2362 8.17317 21.7388C9.38642 22.2413 10.6868 22.5 12 22.5C14.6522 22.5 17.1957 21.4464 19.0711 19.5711C20.9464 17.6957 22 15.1522 22 12.5C22 11.34 21.79 10.19 21.39 9.11L19.79 10.71C19.93 11.3 20 11.9 20 12.5C20 14.6217 19.1571 16.6566 17.6569 18.1569C16.1566 19.6571 14.1217 20.5 12 20.5C9.87827 20.5 7.84344 19.6571 6.34315 18.1569C4.84285 16.6566 4 14.6217 4 12.5C4 10.3783 4.84285 8.34344 6.34315 6.84315C7.84344 5.34285 9.87827 4.5 12 4.5C12.6 4.5 13.2 4.57 13.79 4.71L15.4 3.1C14.31 2.71 13.16 2.5 12 2.5ZM19 2.5L15 6.5V8L12.45 10.55C12.3 10.5 12.15 10.5 12 10.5C11.4696 10.5 10.9609 10.7107 10.5858 11.0858C10.2107 11.4609 10 11.9696 10 12.5C10 13.0304 10.2107 13.5391 10.5858 13.9142C10.9609 14.2893 11.4696 14.5 12 14.5C12.5304 14.5 13.0391 14.2893 13.4142 13.9142C13.7893 13.5391 14 13.0304 14 12.5C14 12.35 14 12.2 13.95 12.05L16.5 9.5H18L22 5.5H19V2.5ZM12 6.5C10.4087 6.5 8.88258 7.13214 7.75736 8.25736C6.63214 9.38258 6 10.9087 6 12.5C6 14.0913 6.63214 15.6174 7.75736 16.7426C8.88258 17.8679 10.4087 18.5 12 18.5C13.5913 18.5 15.1174 17.8679 16.2426 16.7426C17.3679 15.6174 18 14.0913 18 12.5H16C16 13.5609 15.5786 14.5783 14.8284 15.3284C14.0783 16.0786 13.0609 16.5 12 16.5C10.9391 16.5 9.92172 16.0786 9.17157 15.3284C8.42143 14.5783 8 13.5609 8 12.5C8 11.4391 8.42143 10.4217 9.17157 9.67157C9.92172 8.92143 10.9391 8.5 12 8.5V6.5Z" fill="white" />
                </svg>

                <h5 className="ml-3 sans-bold text-white">
                  Educational Goals
                </h5>
              </div>
              <p className="sans-medium text-white">
                {educational_goals ? educational_goals : "N/A"}
              </p>
              <div className="mt-4">
                <button
                  className="bg-transparent border-0 G-orange-button"
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/${servicerAccountId}/contribution-setup`,
                    })
                  }
                >
                  Set up Contribution
                </button>
              </div>
            </div>
            <div className="my-auto">
              {picture_thumb_url ? (
                <img className="contribution-page-profile-pic" src={this.state.picture_thumb_url} alt="profile" />
              ) : null}
            </div>
          </div>
          <div className="G-page-main-container p-3 bg-white Gborder-1 rounded-lg my-5 G-minh-unset">
            <div className="align-center d-flex">
              <img className="uGiftLogo my-auto" src={ugift_logo} alt="Ugift" />
              <label className="sans-medium ml-2 my-auto">
                Your contribuion will be made using {beneficiary_name ? `${beneficiary_name}'s` : ""} Ugift 529 code.
                To learn more about how Ugift works, visit <a href="https://www.ugift529.com/" target="_blank" rel="noopener noreferrer" className="G-link text-decoration-none cancelLink">ugift529.com</a>
              </label>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  beneficiaryProfiles: state.five_two_nine.beneficiaryProfiles,
  errors: state.errors,
});
const mapDispatchToProps = (dispatch) => ({
  fetchBeneficiaryProfiles: (selectedServicerId) => dispatch(fetchBeneficiaryProfiles(selectedServicerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContributionWelcome);
