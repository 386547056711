import React, { Component } from 'react';

import isCitizens from '../../../is-citizens';
import {
  getConsolidatedLoan,
  getNonConsolidatedLoans,
  getFederalLoans,
  minimum_payment,
  humanized_name,
  interest_rate,
  calcData,
  getPaymentSchedule,
  isIncomeBased,
  currency_format,
  getMatchPaymentSchedule
} from '../../../eligibility-result';
import {
  monthsToHumanize,
  numberFormat
} from '../../../../../common/js/jsFunctions';
import { ConsolidationNeededSvg, VariablePaymentsSvg } from '../../AdviceAndTools.constant';
import { Col, Row, Table } from 'react-bootstrap';
class LoanBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    const { existingLoans, program, matchEnabled, t } = this.props;
    const consolidatedLoan = getConsolidatedLoan(program, matchEnabled);
    const nonConsolidatedLoans = getNonConsolidatedLoans(program, matchEnabled);
    const federalLoans = getFederalLoans(existingLoans);
    const federalLoanCount = federalLoans.length;
    const loans = calcData(program, matchEnabled);
    const paymentSchedule = getPaymentSchedule(program);
    const matchPaymentSchedule = getMatchPaymentSchedule(program)

    const activeSchedule = matchEnabled ? matchPaymentSchedule : paymentSchedule;
    const show = activeSchedule.length > 1;

    return (

      <>
        <div className='mt-3'>
          <ConsolidationNeededSvg />
          <label className='ml-2 sans-semibold mb-0 G-relative-top-2px'>
            {
              consolidatedLoan ?
                'Consolidation Needed: ' + federalLoanCount + ' Federal Loans become 1 Consolidated Loan'
                : 'Consolidation Not Required: Payment Breakdown By Loan'
            }
          </label>
        </div>
        <div className="mt-4 Gtable-div">
          <Table borderless className="Gborder-05 G-table-theme-grey">
            <thead className="Gt-head">
              <tr>
                <th className="Gfs-075 p-3">Plan Type</th>
                <th className="Gfs-075 p-3">Interest rate</th>
                <th className="Gfs-075 p-3">Monthly Payment</th>
                <th className="Gfs-075 p-3">Time Left to Pay</th>
                <th className="Gfs-075 p-3">Remaining interest</th>
                <th className="Gfs-075 p-3">Projected Pay</th>
              </tr>
            </thead>
            <tbody className="Gt-body">
              {consolidatedLoan ?
                <>
                  <tr>
                    <td>New Consolidated Loan</td>
                    <td>{interest_rate(consolidatedLoan.rate)}</td>
                    <td>{currency_format(consolidatedLoan.initialPayment) + ` - ` + currency_format(consolidatedLoan.maxPayment)}</td>
                    <td>{monthsToHumanize(consolidatedLoan.payoffTime)}</td>
                    <td>{currency_format(consolidatedLoan.accruedInterest)}</td>
                    <td>{currency_format(consolidatedLoan.totalPaid)}</td>
                  </tr>
                  {
                    nonConsolidatedLoans.length > 0 ? nonConsolidatedLoans.map((loan, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <span>{humanized_name(loan.type)}</span>
                          </td>
                          <td>
                            <span>{interest_rate(loan.rate)}</span>
                          </td>
                          <td>
                            <span>{minimum_payment(loan)}</span>
                          </td>
                          <td>
                            <span>{monthsToHumanize(loan.payoffTime)}</span>
                          </td>
                          <td>
                            <span>{currency_format(loan.accruedInterest)}</span>
                          </td>
                          <td>
                            <span>{currency_format(loan.totalPaid)}</span>
                          </td>
                        </tr>)
                    })
                      : null

                  }
                </>
                :
                loans.length > 0 ? loans.map((loan, i) => {
                  return (
                    <tr key={i} >
                      <td>
                        <span>{humanized_name(loan.type)}</span>
                      </td>
                      <td>
                        <span>{interest_rate(loan.rate)}</span>
                      </td>
                      <td>
                        <span>{minimum_payment(loan)}</span>
                      </td>
                      <td>
                        <span>{monthsToHumanize(loan.payoffTime)}</span>
                      </td>
                      <td>
                        <span>{numberFormat(Math.round(loan.accruedInterest))}</span>
                      </td>
                      <td>
                        <span>{numberFormat(Math.round(loan.totalPaid))}</span>
                      </td>
                    </tr>)
                })
                  : null
              }
            </tbody>
          </Table>
        </div>

        {show ?
          <>
            <div className='my-3'>
              <VariablePaymentsSvg />
              <label className='ml-2 sans-semibold mb-0 G-relative-top-2px'>
                Variable Payment Structure: Increases every two years at a known rate.
              </label>
            </div>
            <div className='p-4 Gborder-05 rounded-lg'>
              <Row>
                {
                  paymentSchedule.length > 0 ? paymentSchedule.map((payment, i) => {
                    return (
                      <Col key={i} xl={2} lg={3} sm={4} xs={6} className='my-3'>
                        <div key={i} className='d-flex flex-column align-items-center'>
                          <label className='G-blue-badge'>
                            {payment.year}
                          </label>
                          <label className='Gfs-1 '>
                            {currency_format(payment.amount)}
                          </label>
                        </div>
                      </Col>
                    )
                  }) : null
                }
              </Row>
            </div>
          </>
          : null}

        <p className='sans-semibold my-3 Gfs-087'>
          {t('plan.paymentNote', { multiplier: 'multiplier' })}
        </p>


        {isIncomeBased(program.name) ?
          <>
            <p className="sans-semibold Gfs-1">
              For income-driven plans there are common assumptions
              our tool has to make to estimate your calculations:
            </p>
            <ul className="G-ul-bullets ml-5">
              <li> Your salary will increase at a constant rate of 5% per year </li>
              <li> The government’s poverty threshold will increase at a constant rate of 2.38% per year </li>
              <li> Your family size and marital status will remain the same as they currently are </li>
            </ul>
            <p className="sans-semibold Gfs-1">
              They are made to predict your future monthly payments as
              income-driven plans require recertification every 12 months
              which in turn sets your new monthly payment.
              The assumptions made by {isCitizens ? "Citizens" : "Vault"}
              are the same as the government’s in their calculations.
            </p>
            <p className="sans-semibold Gfs-1">
              If you expect to have a significant change in salary or an
              increase to your family size or a different tax filing status
              you can adjust your profile to model different projections.
              Or contact us to dive further into your scenario.
            </p>
          </>
          : null}
      </>
    )
  }
}
export default (LoanBreakdown);
