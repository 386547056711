import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { fetchCustomer } from '../../../actions/customerActions';
import { fetchEmployer } from '../../../actions/employerActions';
import { fetchNotifications } from '../../../actions/notificationsActions';

import {
  convertCamelCaseNotification,
  createFinicityLinkableServicerAccountNotification,
  createNotPaidPdrNotification,
  createAdvisorPayMatchNotifications,
  createGlobalCovid19Notification
} from '../../notification-service';
import { fetchFinicityLinkableServicerAccount } from '../../Advisor/servicer-account-service';

import { timeInWords } from "../../../common/js/jsFunctions";

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import { getServicerAccounts } from '../../../actions/fiveTwoNineActions';
import style from './Notification.module.css'


const Notifications = (props) => {
  const [customerData, setCustomerData] = useState(null);
  const [employer, setEmployer] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [servicerAccounts, setServicerAccounts] = useState({});

  useEffect(() => {
    document.title = "Vault | Notifications";
    props.fetchCustomer();
    intercomTrackEvent(eventNames.visitedNotificationsPage);
  }, [])

  useEffect(() => {
    if (Object.keys(props.servicerAccounts).length > 0 && Object.keys(servicerAccounts).length === 0) {
      const servicerAccounts = props.servicerAccounts.data
      setServicerAccounts(servicerAccounts);

    }
  }, [props.servicerAccounts])

  useEffect(() => {
    if (Object.keys(props.customerData).length > 0 && !customerData) {
      setCustomerData(props.customerData);
      props.fetchEmployer();
    }
  }, [props.customerData])

  useEffect(() => {
    if (Object.keys(props.employer).length > 0 && !employer) {
      setEmployer(props.employer);
      props.fetchNotifications();
      props.getServicerAccounts();
    }
  }, [props.employer])

  useEffect(() => {
    // if (Object.keys(props.notifications).length !== Object.keys(prevProps.notifications).length) {
    const note = convertCamelCaseNotification(props.notifications);
    setNotifications(note);
    getNotifications();
    // }
  }, [props.notifications])

  function getNotifications() {
    const servicerAccount = null;

    if (customerData && employer) {
      const finicityLinkableServicerAccounts = fetchFinicityLinkableServicerAccount(employer, servicerAccounts);
      if (finicityLinkableServicerAccounts.length === 1) {
        createFinicityLinkableServicerAccountNotification(notifications, customerData);
      }
      createNotPaidPdrNotification(notifications, employer);
      createAdvisorPayMatchNotifications(notifications, customerData, servicerAccount)
      createGlobalCovid19Notification(notifications, customerData);

    }
    setNotifications(notifications)
  }

  //console.log(JSON.stringify(notifications, null, 4));
  return (
    <>
      <Header />
      {/* <div className="container-content">
          <div className="container-custom ">
            <section className="box-header ">
              <div className="align-center heading"></div>
            </section>
            <div className="background text-white c-heading">Notifications 2</div>
            <div className="white-bg single_notify">

              {notifications.length > 0 ?
                notifications.map((note, i) => {
                  return (<div key={i} className="notification_txt single_notification">
                    <span className="warn_icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                    </span>
                    <div className="notification_right_text">
                      <h6>{note.description}</h6>
                      <span className="time">{timeInWords(note.createdAt)}</span>
                    </div>
                  </div>)
                })
                :
                <div className="notification_txt single_notification">
                  <div className="notification_right_text">
                    <h6>You have 0 notifications</h6>
                  </div>
                </div>
              }
            </div>

          </div>
        </div> */}
      <div className="G-page-color-background">
        <div className="G-page-main-container my-5">
          <div className="p-4 Gborder-05 bg-white  my-3">
            <div>
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                <path d="M30 42.5C31.375 42.5 32.5 41.375 32.5 40H27.5C27.5 41.375 28.625 42.5 30 42.5ZM37.5 35V28.75C37.5 24.9125 35.4625 21.7 31.875 20.85V20C31.875 18.9625 31.0375 18.125 30 18.125C28.9625 18.125 28.125 18.9625 28.125 20V20.85C24.55 21.7 22.5 24.9 22.5 28.75V35L20 37.5V38.75H40V37.5L37.5 35ZM35 36.25H25V28.75C25 25.65 26.8875 23.125 30 23.125C33.1125 23.125 35 25.65 35 28.75V36.25Z" fill="var(--icon-color)" />
              </svg>
            </div>
            <h4 className='my-4 '>
              Notifications
            </h4>
            {notifications.length > 0 ?
              notifications.map((note, i) => {
                return (
                  <div key={i} className={'d-flex ' + (notifications.length === i + 1 ? '' : style.borderBottom)}>
                    {!note.read &&
                      <div>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="4" cy="4" r="4" fill="var(--icon-color)" />
                        </svg>
                      </div>
                    }
                    <div className='ml-3'>
                      <label className='Gfs-1'>{note.description}</label>
                      <br />
                      <label className='Gfs-087 Gt-slate-1'>{timeInWords(note.createdAt)}</label>
                    </div>
                  </div>
                )
              })
              :
              <div>
                <h6>You have 0 notifications</h6>
              </div>
            }
          </div>
        </div>
      </div >
      <Footer />
    </>
  )

}

// class Notifications extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       customerData: null,
//       employer: null,
//       notifications: [],
//       servicerAccounts: []
//     }
//   }
//   componentDidMount() {
//     document.title = "Vault | Notifications";
//     const { fetchEmployer, fetchCustomer, fetchNotifications, getServicerAccounts } = this.props;
//     fetchCustomer();
//     fetchEmployer();
//     fetchNotifications();
//     getServicerAccounts();
//     intercomTrackEvent(eventNames.visitedNotificationsPage);
//     this.timer = setTimeout(() => {
//       this.getNotifications();
//     }, 3000);
//   }
//   componentWillUnmount() {
//     clearTimeout(this.timer);
//   }
//   componentDidUpdate(prevProps) {
//     if (this.props.servicerAccounts !== prevProps.servicerAccounts) {
//       const servicerAccounts = this.props.servicerAccounts.data
//       this.setState({
//         servicerAccounts: servicerAccounts
//       })
//     }
//     if (prevProps.customerData !== this.props.customerData) {
//       this.setState({
//         customerData: this.props.customerData
//       })
//     }
//     if (prevProps.employer !== this.props.employer) {
//       this.setState({
//         employer: this.props.employer
//       })
//     }
//     if (Object.keys(this.props.notifications).length !== Object.keys(prevProps.notifications).length) {
//       const note = convertCamelCaseNotification(this.props.notifications);
//       this.setState({
//         notifications: note
//       })
//     }/*else{
//       this.getNotifications();
//     }*/
//   }
//   getNotifications() {
//     const { customerData, employer, notifications, servicerAccounts } = this.state;
//     const servicerAccount = null;

//     if (customerData && employer) {
//       const finicityLinkableServicerAccounts = fetchFinicityLinkableServicerAccount(employer, servicerAccounts);
//       if (finicityLinkableServicerAccounts.length === 1) {
//         createFinicityLinkableServicerAccountNotification(notifications, customerData);
//       }
//       createNotPaidPdrNotification(notifications, employer);
//       createAdvisorPayMatchNotifications(notifications, customerData, servicerAccount)
//       createGlobalCovid19Notification(notifications, customerData);

//     }
//     this.setState({
//       notifications: notifications
//     });
//   }
//   render() {
//     const { notifications } = this.state;
//     //console.log(JSON.stringify(notifications, null, 4));
//     return (
//       <>
//         <Header {...this.props} />
//         {/* <div className="container-content">
//           <div className="container-custom ">
//             <section className="box-header ">
//               <div className="align-center heading"></div>
//             </section>
//             <div className="background text-white c-heading">Notifications 2</div>
//             <div className="white-bg single_notify">

//               {notifications.length > 0 ?
//                 notifications.map((note, i) => {
//                   return (<div key={i} className="notification_txt single_notification">
//                     <span className="warn_icon">
//                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
//                     </span>
//                     <div className="notification_right_text">
//                       <h6>{note.description}</h6>
//                       <span className="time">{timeInWords(note.createdAt)}</span>
//                     </div>
//                   </div>)
//                 })
//                 :
//                 <div className="notification_txt single_notification">
//                   <div className="notification_right_text">
//                     <h6>You have 0 notifications</h6>
//                   </div>
//                 </div>
//               }
//             </div>

//           </div>
//         </div> */}
//         <div className="G-page-color-background">
//           <div className="G-page-main-container my-5">
//             <div className="p-4 Gborder-05 bg-white  my-3">
//               <div>
//                 <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <rect width="60" height="60" rx="30" fill="#ECCCE0" />
//                   <path d="M30 42.5C31.375 42.5 32.5 41.375 32.5 40H27.5C27.5 41.375 28.625 42.5 30 42.5ZM37.5 35V28.75C37.5 24.9125 35.4625 21.7 31.875 20.85V20C31.875 18.9625 31.0375 18.125 30 18.125C28.9625 18.125 28.125 18.9625 28.125 20V20.85C24.55 21.7 22.5 24.9 22.5 28.75V35L20 37.5V38.75H40V37.5L37.5 35ZM35 36.25H25V28.75C25 25.65 26.8875 23.125 30 23.125C33.1125 23.125 35 25.65 35 28.75V36.25Z" fill="var(--icon-color)" />
//                 </svg>
//               </div>
//               <h4 className='my-4 '>
//                 Notifications
//               </h4>
//               {notifications.length > 0 ?
//                 notifications.map((note, i) => {
//                   return (
//                     <div key={i} className={'d-flex ' + (notifications.length == i + 1 ? '' : style.borderBottom)}>
//                       {!note.read &&
//                         <div>
//                           <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <circle cx="4" cy="4" r="4" fill="var(--icon-color)" />
//                           </svg>
//                         </div>
//                       }
//                       <div className='ml-3'>
//                         <label className='Gfs-1'>{note.description}</label>
//                         <br />
//                         <label className='Gfs-087 Gt-slate-1'>{timeInWords(note.createdAt)}</label>
//                       </div>
//                     </div>
//                   )
//                 })
//                 :
//                 <div>
//                   <h6>You have 0 notifications</h6>
//                 </div>
//               }
//             </div>
//           </div>
//         </div >
//         <Footer />
//       </>
//     )
//   }
// }
const mapStateToProps = (state) => {
  return {
    customerData: state.customer_array.customerData,
    employer: state.employer_array.employerData,
    notifications: state.notifications.notifications,
    servicerAccounts: state.five_two_nine.getServicerAccountsData,
    errors: state.errors
  };
};
const mapDispatchToProps = {
  fetchCustomer,
  fetchEmployer,
  fetchNotifications,
  getServicerAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);