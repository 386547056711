import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import { fetchGeneralAgreement } from "../../../actions/generalTermsActions";
import style from './About.module.css'

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalAgreementData: null
    }
  }
  componentDidMount() {
    document.title = "Vault | Terms of Service";
    const { fetchGeneralAgreement } = this.props;
    fetchGeneralAgreement();
  }
  componentDidUpdate(prevProps) {

    if (prevProps.generalAgreementData !== this.props.generalAgreementData) {
      const data = this.props.generalAgreementData
      this.setState({
        generalAgreementData: data.attributes.document
      })
    }
  }
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container  my-5">
            <div className="p-4 bg-white Gborder-1 rounded-lg">
              <h2>Terms of Service</h2>
              <div className={style.container}>
                <p dangerouslySetInnerHTML={{ __html: this.state.generalAgreementData }}>

                </p>
              </div>
            </div>
          </div>
        </div>


        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    generalAgreementData: state.general_terms.generalAgreementData,
    errors: state.errors
  };
}
const mapDispatchToProps = {
  fetchGeneralAgreement
}
export default connect(mapStateToProps, mapDispatchToProps)(About);