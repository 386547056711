import React from 'react';
import { Route } from "react-router-dom";

import TuitionReimbursementIntro from "../../Components/TuitionReimbursement/TuitionReimbursementIntro"
import TuitionReimbursement from "../../Components/TuitionReimbursement/TuitionReimbursement";
import TuitionReimbursementHistory from "../../Components/TuitionReimbursementHistory/TuitionReimbursementHistory";
import TuitionReimbursementStatus from "../../Components/TuitionReimbursementStatus/TuitionReimbursementStatus";
import TRDtiCalculator from "../../Components/TRAdviceAndTools/TRDtiCalculator";
import CollegePlanning from "../../Components/TRAdviceAndTools/CollegePlanning";

import Faqs from '../FiveToNine/AdviceAndTools/Faqs';
import Maps from '../FiveToNine/AdviceAndTools/Maps';

const TuitionRoutes = [
  <Route exact path="/intro/tuition-plan" component={TuitionReimbursementIntro} key={1} />,
  <Route exact path="/tuition-reimbursement" component={TuitionReimbursement} key={1} />,
  <Route exact path="/tuition-reimbursement/:tuitionId" component={TuitionReimbursement} key={1} />,
  <Route exact path="/tuition-reimbursement-history" component={TuitionReimbursementHistory} key={1} />,
  <Route exact path="/tuition-reimbursement-status/:tuitionId" component={TuitionReimbursementStatus} key={1} />,

  <Route exact path="/dashboard/dti-calculator" component={TRDtiCalculator} key={1} />,
  <Route exact path="/dashboard/collegeplanning" component={CollegePlanning} key={1} />,
  <Route exact path="/dashboard/faqs" component={Faqs} key={1} />,
  <Route exact path="/dashboard/map" component={Maps} key={1} />,

]

export default TuitionRoutes;