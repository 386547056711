import React from 'react';
import { Route } from "react-router-dom";

import AdvisorIntro from "./Intro/Advisor";
import AdvisorProfileAbout from './Profile/About';
import AdvisorInvite from '../PrudentialFiveToNine/Invitation/PRINVITE';

import AdvisorWelcomeAbout from "./Welcome/About";
import AdvisorWelcomeNslds from "./Welcome/Nslds";
import AdvisorWelcomeLoans from "./Welcome/Loans";
import AdvisorWelcomeLoansNew from "./Welcome/LoansNew";
import AdvisorWelcomeLoansEdit from "./Welcome/LoansEdit";

import AdvisorDashboardLoans from "./Dashboard/Loans";
import AdvisorDashboardLoansNew from "./Dashboard/LoansNew";
import AdvisorDashboardLoansEdit from "./Dashboard/LoansEdit";
import AdvisorDashboardNslds from "./Dashboard/Nslds";

import AdvisorAdviceAndTools from "./AdviceAndTools/AdviceAndTools";
import AdvisorLowerPayment from "./AdviceAndTools/LowerPayment/LowerPayment";
import AdvisorLowerPaymentPlan from "./AdviceAndTools/LowerPayment/Plan/LowerPaymentPlan";
import AdvisorPayFaster from "./AdviceAndTools/PayFaster/PayFaster";
import AdvisorRefinance from "./AdviceAndTools/Refinance/Refinance";

import AdvisorMatchIndex from "./Match/Index";
import AdvisorMatchOverview from "./Match/Overview";
import AdvisorMatchAccounts from "./Match/Accounts";


import AdvisorGeniusCalc from "./Genius/GeniusCalc";

import PayOverview from "./Pay/PayOverview";
import PayAccounts from "./Pay/PayAccounts";
import AdvisorPayAddContribution from "./Pay/AddContribution"
import AdvisorPayEditContribution from "./Pay/EditContribution";
import AdvisorPayProfilesDeductions from "./Pay/ProfileDeductions";

import History from "./Pay/Payments/History";

import Notification from "./Notification/Notification";
import AdvisorTerms from "./Terms/About";

import ForgivenessEligibility from './AdviceAndTools/ForgivenessEligibility/ForgivenessEligibility';
import MatchFAQ from './Match/MatchFAQ';
import MatchTermsAndConditions from './Match/TermsAndConditions';
import SwitchToFiveTwoNine from '../SwitchBetweenServices/SwitchToFiveTwoNine';
import { headerSelector } from '../Includes/Header/Helpers/serviceSelector';
import RevokedAccessPage from '../shared/RevokedAccessPage/RevokedAccess.page';

const advisorRouting = () => {
  let selectedHeaderType = headerSelector();
  let hasAdvisor = JSON.parse(localStorage.getItem('hasAdvisor'))

  let advisorConditionFor529 = selectedHeaderType === 'fiveTwoNine' && (localStorage.getItem("advisorEnabled") === 'true' || localStorage.getItem("newAdvisorEnabledPruAccount") === 'true');

  const advisorRoutes = [];
  if ((selectedHeaderType === 'fiveTwoNine' && advisorConditionFor529) ||
    (selectedHeaderType !== 'fiveTwoNine' && hasAdvisor)) {
    advisorRoutes.push(
      <Route exact path="/intro/advisor" component={AdvisorIntro} key={1} />,
      <Route exact path="/profile/about" component={AdvisorProfileAbout} key={1} />,
      <Route exact path="/invite" component={AdvisorInvite} key={1} />,

      <Route exact path="/welcome/about" component={AdvisorWelcomeAbout} key={1} />,
      <Route exact path="/welcome/nslds" component={AdvisorWelcomeNslds} key={1} />,
      <Route exact path="/welcome/loans" component={AdvisorWelcomeLoans} key={1} />,
      <Route exact path="/welcome/loans/new" component={AdvisorWelcomeLoansNew} key={1} />,
      <Route exact path="/welcome/loans/edit/:loanId" component={AdvisorWelcomeLoansEdit} key={1} />,

      <Route exact path="/genius-calc" component={AdvisorGeniusCalc} key={1} />,

      <Route exact path="/advice-and-tools/nslds" component={AdvisorDashboardNslds} key={1} />,
      <Route exact path="/advice-and-tools/loans" component={AdvisorDashboardLoans} key={1} />,
      <Route exact path="/advice-and-tools/loans/new" component={AdvisorDashboardLoansNew} key={1} />,
      <Route exact path="/advice-and-tools/loans/edit/:loanId" component={AdvisorDashboardLoansEdit} key={1} />,

      <Route exact path="/advice-and-tools/dashboard" component={AdvisorAdviceAndTools} key={1} />,
      <Route exact path="/advice-and-tools/lower-payment" component={AdvisorLowerPayment} key={1} />,
      <Route exact path="/advice-and-tools/lower-payment/plan/:planName" component={AdvisorLowerPaymentPlan} key={1} />,
      <Route exact path="/advice-and-tools/lower-payment/plan/:planName/details" component={AdvisorLowerPaymentPlan} key={1} />,
      <Route exact path="/advice-and-tools/pay-faster" component={AdvisorPayFaster} key={1} />,
      <Route exact path="/advice-and-tools/pay-faster/details" component={AdvisorPayFaster} key={1} />,
      <Route exact path="/advice-and-tools/refinance/terms" component={AdvisorRefinance} key={1} />,
      <Route exact path="/advice-and-tools/forgiveness-eligibility" component={ForgivenessEligibility} key={1} />,

      <Route exact path="/notifications" component={Notification} key={1} />,

      <Route exact path="/terms/about" component={AdvisorTerms} key={1} />,

    );
  }

  if (advisorConditionFor529) {
    advisorRoutes.push(
      <Route exact path="/master-dashboard" component={SwitchToFiveTwoNine} key={1} />,
    )
  } else if (selectedHeaderType === 'pay') {
    advisorRoutes.push(
      <Route exact path="/pay/*" component={RevokedAccessPage} key={1} />,
      <Route exact path="/pay/accounts" component={PayAccounts} key={1} />,
      <Route exact path="/pay/profiles/payment/new" component={AdvisorPayAddContribution} key={1} />,
      <Route exact path="/pay/profiles/payment/:saId/edit" component={AdvisorPayEditContribution} key={1} />,
      <Route exact path="/pay/profiles/deductions" component={AdvisorPayProfilesDeductions} key={1} />,
      <Route exact path="/pay/profiles/deductions/:saId" component={AdvisorPayProfilesDeductions} key={1} />,

      // <Route exact path="/pay/payments-contribution" component={Payments} key={1} />,
      <Route exact path="/pay/payments-history" component={History} key={1} />,
    )
  } else if (selectedHeaderType === 'match') {
    advisorRoutes.push(
      <Route exact path="/match/*" component={RevokedAccessPage} key={1} />,
      <Route exact path="/match/overview" component={AdvisorMatchOverview} key={1} />,
      <Route exact path="/match/accounts" component={AdvisorMatchAccounts} key={1} />,
      <Route exact path="/match/termsAndConditions" component={MatchTermsAndConditions} key={1} />,
      <Route exact path="/match/faq" component={MatchFAQ} key={1} />,
    )
  }

  return advisorRoutes;
}
export default advisorRouting;