import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { getServicerAccounts } from '../../../actions/fiveTwoNineActions';
import { getTransaction } from '../../../actions/advisorActions';

import { getFormattedTransactions } from "../match";
import { enrolledInMatch as enrolledInMatchFunction } from "../servicer-account-service";

const Index = (props) => {
  const [state, setState] = useState({
    servicerAccounts: [],
    transactions: [],
    formattedTransactions: [],
    enrolledInMatch: null
  })

  useEffect(() => {
    document.title = "Vault Advisor"
    const { getServicerAccounts, getTransaction } = props;
    getServicerAccounts();
    getTransaction();
  }, [])

  useEffect(() => {
    if (Object.keys(props.servicerAccounts).length) {
      const servicerAccounts = props.servicerAccounts.data
      const servicerAccountsIncluded = props.servicerAccounts.included;

      let servicer_accounts = [];
      let transactionsData = [];
      let formattedTransactions = [];

      servicerAccounts.forEach((element, i) => {
        const servicerId = element.relationships.servicer.data.id;

        element.attributes.servicer_account_id = element.id
        servicer_accounts[i] = element.attributes;
        servicer_accounts[i]['servicer'] = {};
        servicer_accounts[i]['statements'] = [];
        servicer_accounts[i]['transactions'] = [];
        if (element.relationships.servicer.data) {
          const servicer = servicerAccountsIncluded.find(item => item.id === servicerId);
          servicer_accounts[i]['servicer'] = servicer.attributes;
        }
        const relationshipsStatements = element.relationships.statements.data;
        relationshipsStatements.forEach((statement, index) => {
          const statementId = statement.id;

          const statements = servicerAccountsIncluded.find(item => item.id === statementId && item.type === 'statements');
          statements.attributes.statement_id = statements.id;
          servicer_accounts[i]['statements'].push(statements.attributes)
        })
        const relationshipsTransactions = element.relationships.transactions?.data;
        relationshipsTransactions?.forEach((transaction, index) => {
          const transactionId = transaction.id;

          const transactions = servicerAccountsIncluded.find(item => item.id === transactionId && item.type === 'transactions');
          transactions.attributes.transaction_id = transactionId;
          servicer_accounts[i]['transactions'].push(transactions.attributes)

          transactionsData.push(transactions.attributes);
        })
      })
      if (servicer_accounts.length > 0) {
        formattedTransactions = getFormattedTransactions(servicer_accounts);
      }

      setState({
        ...state,
        servicerAccounts: servicer_accounts,
        formattedTransactions: formattedTransactions,
        enrolledInMatch: enrolledInMatchFunction(servicer_accounts)
      })
    }
  }, [props.servicerAccounts])


  useEffect(() => {
    if (props.transactions.length) {
      setState({
        ...state,
        transactions: props.transactions
      })
    }
  }, [props.transactions])



  const { enrolledInMatch } = state;
  if (enrolledInMatch !== null) {
    if (enrolledInMatch) {
      return <Redirect to="/match/accounts" />
    } else {
      return <Redirect to="/match/overview" />
    }
  }
  return <></>

}

// class Index extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       servicerAccounts:[],
//       transactions:[],
//       formattedTransactions:[],
//       enrolledInMatch:null
//     }
//   }
//   componentDidMount(){
//     document.title = "Vault Advisor"
//     const { getServicerAccounts,getTransaction } = this.props;
//     getServicerAccounts();
//     getTransaction();
//   }
//   componentDidUpdate(prevProps){

//     if(prevProps.servicerAccounts!==this.props.servicerAccounts){
//       const servicerAccounts = this.props.servicerAccounts.data
//       const servicerAccountsIncluded = this.props.servicerAccounts.included;
//       //console.log(JSON.stringify(servicerAccounts));

//       let servicer_accounts = [];
//       let transactionsData = [];
//       let formattedTransactions = [];

//       servicerAccounts.forEach((element,i) => {
//         const servicerId = element.relationships.servicer.data.id;

//         element.attributes.servicer_account_id = element.id
//         servicer_accounts[i] = element.attributes;
//         servicer_accounts[i]['servicer'] = {};
//         servicer_accounts[i]['statements'] = [];
//         servicer_accounts[i]['transactions'] = [];
//         if(element.relationships.servicer.data){
//           const servicer = servicerAccountsIncluded.find(item => item.id === servicerId);
//           servicer_accounts[i]['servicer'] = servicer.attributes;
//         }
//         const relationshipsStatements = element.relationships.statements.data;
//         relationshipsStatements.forEach((statement,index) => {
//           const statementId = statement.id;

//           const statements = servicerAccountsIncluded.find(item => item.id === statementId && item.type==='statements');
//           statements.attributes.statement_id = statements.id;
//           servicer_accounts[i]['statements'].push(statements.attributes)
//         })
//         const relationshipsTransactions = element.relationships.transactions?.data;
//         relationshipsTransactions?.forEach((transaction,index) => {
//           const transactionId = transaction.id;

//           const transactions = servicerAccountsIncluded.find(item => item.id === transactionId && item.type==='transactions');
//           transactions.attributes.transaction_id = transactionId;
//           servicer_accounts[i]['transactions'].push(transactions.attributes)

//           transactionsData.push(transactions.attributes);
//         })
//       })
//       if(servicer_accounts.length > 0){
//         formattedTransactions = getFormattedTransactions(servicer_accounts);
//       }

//       this.setState({
//         servicerAccounts:servicer_accounts,
//         formattedTransactions:formattedTransactions,
//         enrolledInMatch:enrolledInMatch(servicer_accounts)
//       })
//     }
//     if(prevProps.transactions!==this.props.transactions){
//       this.setState({
//         transactions:this.props.transactions
//       })
//     }
//   }
//   render(){
//     const { enrolledInMatch } = this.state;

//     if(enrolledInMatch!==null){
//       if(enrolledInMatch){
//         return <Redirect to="/match/accounts" />
//       }else{
//         return <Redirect to="/match/overview" />
//       }
//     }
//     return <></>
//   }
// }
const mapStateToProps = (state) => {
  return {
    servicerAccounts: state.five_two_nine.getServicerAccountsData,
    transactions: state.advisor.transactions
  };
}
const mapDispatchToProps = {
  getServicerAccounts,
  getTransaction
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);