const eventType = {
  click: 0,
};

export const customEventObject = (name, value = '') => {
  return {
    target: {
      name:name,
      value:value
    }
  }
}

export default eventType;
