import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from '../Header.module.css'
import Notifications from './Notifications';
import UserProfile from './UserProfile';
import { fetchEmployer } from '../../../../actions/employerActions';
import { fetchCustomer } from '../../../../actions/customerActions';
import { connect } from 'react-redux';
import Logout from './Logout';
import { headerSelector, navbarData, subHeaderSelector } from '../Helpers/serviceSelector'
import { showNotification, subNavBarLinks } from '../Helpers/navBarFeatures';
import headerSelectionsData from '../data/headerSelectionsData.json'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CaretDownFill, CaretUpFill, List } from 'react-bootstrap-icons';
import Logo from './Logo';
import headerWithNoTabs from '../data/headerWithNoTabs.json'
import PartnerLogo from '../../../shared/PartnerLogo/PartnerLogo';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerData: null,
      employer: null,
      showNotification: false,
      showDropDownAdviceAndTools: false,
      partnerLogo:null,
    }
  }

  componentDidMount() {
    const { fetchEmployer, fetchCustomer } = this.props;
    if (!!localStorage.getItem("userToken")) {
      fetchCustomer();
      fetchEmployer();
    }
    const showNotifications = showNotification();
    const navbarListData = navbarData();
    this.setState({ showNotification: showNotifications, navbarListData })
    this.setState({ partnerLogo:  !!localStorage.getItem('partnerLogo') ? localStorage.getItem('partnerLogo') : null })

  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.customerData !== this.props.customerData) {
      this.setState({
        customerData: nextProps.customerData
      })
    }
    if (nextProps.employer !== this.props.employer) {
      this.setState({
        employer: nextProps.employer
      })
    }

    if (nextProps.partnerData?.data !== this.props.partnerData?.data) {
      this.setState({
        partnerLogo: nextProps.partnerData && nextProps.partnerData?.data ? nextProps.partnerData?.data?.attributes?.logo : null,
      })
    }
  }

  openNav(adviceAndToolsData) {
    document.getElementById("mySidenav").style.width = "100%";
    this.checkIfExpandAdviceAndTools(adviceAndToolsData);
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  checkIfExpandAdviceAndTools(adviceAndToolsData) {
    if (!Array.isArray(adviceAndToolsData)) return;
    let pathname = window.location.pathname;
    let arrAfterSplit = pathname.split('/')
    let variant1 = '/' + arrAfterSplit[1] + (arrAfterSplit[2] ? '/' + arrAfterSplit[2] : '');
    adviceAndToolsData.push({
      path: '/advice-and-tools/dashboard'
    })
    let filterData = adviceAndToolsData.filter((x) => x.path === pathname || x.path === variant1)
    this.setState({ showDropDownAdviceAndTools: filterData.length > 0 })
  }
  render() {
    const { showNotification, employer, navbarListData } = this.state;
    let subNavBarData = [];
    let subHeaderData = [];
    let adviceAndToolsData = [];
    if (employer) {
      subNavBarData = subHeaderSelector(navbarListData, employer);
      subHeaderData = navbarListData.filter(e => {
        return 'advice-and-tools' === e.id
      })[0]
      adviceAndToolsData = subNavBarLinks(subHeaderData, employer);
    }
    const headerName = headerSelector();
    let headerSelectedData = [];
    headerSelectedData = headerSelectionsData.filter((x) => x.service === headerName)

    const hasUserToken = !!localStorage.getItem("userToken")


    const { logoNameAndLogoutShowOnly, hideNotificationAndProfileDropdown, hideSubNavBar } = this.props


    return (
      <>
        <Navbar collapseOnSelect expand="lg" className={`navBarBackground ${s.navbar}`} >
          <Container className='col-10'>
            <Navbar.Brand href="#">
              {
                this.state.partnerLogo ? <PartnerLogo src={this.state.partnerLogo} /> : <Logo />
              }</Navbar.Brand>
            {!hideNotificationAndProfileDropdown && !logoNameAndLogoutShowOnly && showNotification ? <Notifications mainIcon={false} dataProps={this.props} /> : null}
            {hasUserToken &&
              <>
                <span className='G-header-menu-btn'>
                  <List size={30} className='color-white mr-2' onClick={() => this.openNav(adviceAndToolsData)} />
                </span>
              </>
              // <Navbar.Toggle aria-controls="responsive-navbar-nav" ><List size={30} className='color-white' /></Navbar.Toggle>
            }
            <Navbar.Collapse>
              {!logoNameAndLogoutShowOnly && !headerWithNoTabs.includes(window.location.pathname) &&
                <Nav className="me-auto">
                  {/* {localStorage.getItem("showProfile") === 'true' && navbarListData?.map(e => { */}
                  {navbarListData?.map((e, index) => {
                    let path = window.location.pathname.split("/")[1];
                    let pathname = window.location.pathname;
                    let headerRoutesArray = headerSelectedData[0][e.id];
                   
                    return (
                      <React.Fragment key={index}>
                        {e.id ?
                          <Nav.Link href={e.headerPath ? e.headerPath : "#"} className={headerSelectedData.length > 0 ? headerRoutesArray.includes(pathname) ? 'activeLink' : headerRoutesArray.includes(path) ? 'activeLink' : '' : ''}>
                            {e.header}
                            {e.id === 'advice-and-tools' ? <CaretDownFill size={15} className='ml-2 mb-1' /> : ''}
                          </Nav.Link>
                          : null}
                      </React.Fragment>
                    )
                  })
                  }
                </Nav>}
              {hasUserToken &&
                <Nav className='ml-auto'>
                  {!hideNotificationAndProfileDropdown && !logoNameAndLogoutShowOnly && showNotification ? <Notifications mainIcon={true} dataProps={this.props} /> : null}
                  <UserProfile hideNotificationAndProfileDropdown={hideNotificationAndProfileDropdown} logoNameAndLogoutShowOnly={logoNameAndLogoutShowOnly} />
                  <Logout />
                </Nav>
              }

            </Navbar.Collapse>
          </Container>
        </Navbar>
        {!headerWithNoTabs.includes(window.location.pathname) && hasUserToken && localStorage.getItem("hasProfile") === 'true' && subNavBarData ?
          <Navbar expand="lg" className={`${s.subNavbar}`} >
            <Container className='col-10 pl-5'>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" >
                <List size={30} className='color-black' />
              </Navbar.Toggle>
              <Navbar.Collapse>
                <Nav className="me-auto">
                  {subNavBarData.map((e, index) => {
                    let pathname = window.location.pathname;
                    let arrAfterSplit = pathname.split('/')
                    let variant1 = '/' + arrAfterSplit[1] + (arrAfterSplit[2] ? '/' + arrAfterSplit[2] : '');
                    return (
                      <React.Fragment key={index}>
                        <Nav.Link href={e.path ? e.path : " "} className={e.path === pathname || e.path === variant1 ? 'activeLink' : ''}>
                          {e.name}
                        </Nav.Link>
                      </React.Fragment>
                    )
                  })
                  }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar >
          : null
        }

        <div id="mySidenav" className="G-sideNav-mob">
          <button className="close-btn cancelLink text-white" onClick={this.closeNav}>&times;</button>

          {!logoNameAndLogoutShowOnly && !headerWithNoTabs.includes(window.location.pathname) &&
            navbarListData?.map((e, index) => {
              let path = window.location.pathname.split("/")[1];
              let pathname = window.location.pathname;
              let headerRoutesArray = headerSelectedData[0][e.id];
              return (
                <React.Fragment key={index}>
                  {e.id ?
                    <>
                      {
                        e.id === 'advice-and-tools' ?
                          headerName === 'fiveTwoNine' && !(localStorage.getItem('selectedService') === 'advisor') ?
                            <a href={e.headerPath ? e.headerPath : "#"}
                            >
                              {e.header}
                            </a>
                            :
                            <button className={headerSelectedData.length > 0 ? headerRoutesArray.includes(pathname) ? 'active' : headerRoutesArray.includes(path) ? 'active' : '' : ''}
                              onClick={() => this.setState({ showDropDownAdviceAndTools: !this.state.showDropDownAdviceAndTools })}
                            >
                              {e.header}
                              {this.state.showDropDownAdviceAndTools ?
                                <CaretUpFill size={25} className='ml-2 mb-1' />
                                : <CaretDownFill size={25} className='ml-2 mb-1' />
                              }
                            </button>
                          :
                          <a href={e.headerPath ? e.headerPath : "#"} className={headerSelectedData.length > 0 ? headerRoutesArray.includes(pathname) ? 'active' : headerRoutesArray.includes(path) ? 'active' : '' : ''}>
                            {e.header}
                          </a>
                      }
                      {hasUserToken && adviceAndToolsData && e.id === 'advice-and-tools' ?
                        <div className={s.dropDownMenu + " " + s.dropDownMenuActive}>
                          <div className={s.dropDownMenu + ' ' + (this.state.showDropDownAdviceAndTools ? s.dropDownMenuActive : '')}>
                            <a href={"/advice-and-tools/dashboard"} className={"/advice-and-tools/dashboard" === window.location.pathname ? 'active' : ''}>
                              {headerName === 'advisorOnly' ? 'ADVICE AND TOOLS DASHBOARD' : "DASHBOARD"}
                            </a>

                            {!hideSubNavBar && localStorage.getItem("hasProfile") === 'true' &&
                              adviceAndToolsData.map((e, index) => {
                                let pathname = window.location.pathname;
                                let arrAfterSplit = pathname.split('/')
                                let variant1 = '/' + arrAfterSplit[1] + (arrAfterSplit[2] ? '/' + arrAfterSplit[2] : '');
                                return (
                                  <React.Fragment key={index}>
                                    <a href={e.path ? e.path : " "} className={e.path === pathname || e.path === variant1 ? 'active' : ''}>
                                      {e.name}
                                    </a>
                                  </React.Fragment>
                                )
                              })
                            }
                          </div>
                        </div>

                        : null
                      }
                    </>
                    : null}
                </React.Fragment>
              )
            })
          }


          {/* <Dropdown className='G-dropdown-header-mob'>
            <Dropdown.Toggle>
              ADVICE AND TOOLS
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className='active'>LOWER PAYMENTS</Dropdown.Item>
              <Dropdown.Item href="#/action-2">PAYOFF FASTER </Dropdown.Item>
              <Dropdown.Item href="#/action-3">REFINANCE </Dropdown.Item>
              <Dropdown.Item href="#/action-3">COLLEGE PLANNING </Dropdown.Item>
              <Dropdown.Item href="#/action-3">PUBLIC SERVICE LOAN FORGIVENSS  </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <a href="#" className='active'>FAQ</a> */}
          {hasUserToken &&
            <>
              <UserProfile hideNotificationAndProfileDropdown={hideNotificationAndProfileDropdown} logoNameAndLogoutShowOnly={logoNameAndLogoutShowOnly} />
              <Logout />
            </>
          }
        </div >
      </>

      // <div>
      //   <Row >
      //     <div className="collapse navbar-collapse justify-content-sb" id="collapsibleNavbar">
      //       <ul className="navbar-nav">
      //         {/* {localStorage.getItem("showProfile") === 'true' && navbarListData?.map(e => { */}
      //         {navbarListData?.map((e, index) => {
      //           let path = window.location.pathname.split("/")[1];
      //           let pathname = window.location.pathname;
      //           let headerRoutesArray = headerSelectedData[0][e.id]
      //           return (
      //             // <li className={`nav-item `}>
      //             //   {e.id ? <NavLink to={e.headerPath ? e.headerPath : " "} activeClassName={s.activeLink} className={"nav-link dropdown-toggle " + (path == e.id ?  : '')} id="navbardrop"> {e.header} </NavLink> : null}
      //             // </li>
      //             <li className={`nav-item `} key={index}>
      //               {e.id ?
      //                 <NavLink to={e.headerPath ? e.headerPath : " "} activeClassName={s.activeLink} id="navbardrop"
      //                   className={headerSelectedData.length > 0 ? headerRoutesArray.includes(pathname) ? s.activeLink : headerRoutesArray.includes(path) ? s.activeLink : '' : ''}
      //                 > {e.header} </NavLink> : null}
      //             </li>
      //           )
      //         })
      //         }
      //       </ul>

      //       <ul className="navbar-nav">
      //         {showNotification ? <Notifications dataProps={this.props} /> : null}
      //         <UserProfile />
      //         <Logout />
      //       </ul>
      //     </div>
      //   </Row>

      //   {/* {localStorage.getItem("showProfile") === 'true' && subNavBarData ? */}
      //   {subNavBarData ?
      //     <Row className={s.subNavBar}>
      //       <div className="navbar-child ">
      //         <ul className="navbar-child">
      //           {subNavBarData.map((e, index) => {
      //             return (
      //               <li key={index}>
      //                 <NavLink to={e.path ? e.path : " "} activeClassName={s.activeLink} id="navbardrop"> {e.name} </NavLink>
      //               </li>
      //             )
      //           })
      //           }
      //         </ul>
      //       </div>
      //     </Row> : null}
      // </div>
    )
  }
}
NavBar.propTypes = {
  fetchCustomer: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  customerData: state.customer_array.customerData,
  employer: state.employer_array.employerData,
  errors: state.errors,
  partnerData: state.authentication.partnerData,
});

const mapDispatchToProps = {
  fetchCustomer,
  fetchEmployer,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
