import React from 'react';
import { Link } from "react-router-dom";
import { setTuitionDetailsAccordionData, setBasicDetailsAccordionData, setBankDetailsAccordionData, tuitionReimburseBreadcrumbData } from './TuitionReimbursementStatus.constant';
import BankDetailsAccordion from './BankDetailsAccordion';
import XCircle from 'react-bootstrap-icons/dist/icons/x-circle';
import TuitionDetailsAccordion from './TuitionDetailsAccordion';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import BasicDetailsAccordion from './BasicDetailsAccordion';

const TuitionReimbursementStatusNeedMoreInfo = ({ fetchIndServiceAccountData, addressData, servicerAccountData }) => {
  let tuitionRejectionNote = "";
  if (fetchIndServiceAccountData) {
    tuitionRejectionNote = fetchIndServiceAccountData?.data?.attributes.tuition_rejection_note
  }
  const redirectUrl = "/tuition-reimbursement-history";
  return (
    <>
      <Breadcrumb list={tuitionReimburseBreadcrumbData()} />
      <div className='p-4 Gborder-05 rounded-lg G-grid11-2 bg-white my-4'>
        <div >
          <div className='pinkCircularDiv mb-3'>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 0.5C6.0875 0.5 0.5 6.0875 0.5 13C0.5 19.9125 6.0875 25.5 13 25.5C19.9125 25.5 25.5 19.9125 25.5 13C25.5 6.0875 19.9125 0.5 13 0.5ZM13 23C7.4875 23 3 18.5125 3 13C3 7.4875 7.4875 3 13 3C18.5125 3 23 7.4875 23 13C23 18.5125 18.5125 23 13 23ZM17.4875 6.75L13 11.2375L8.5125 6.75L6.75 8.5125L11.2375 13L6.75 17.4875L8.5125 19.25L13 14.7625L17.4875 19.25L19.25 17.4875L14.7625 13L19.25 8.5125L17.4875 6.75Z" fill="var(--icon-color)" />
            </svg>
          </div>
          <div className='my-2'>
            <span className='G-red-badge'>Rejected</span>
          </div>
          <h3 className='Gt-slate-5 Gfs-175 my-2'>Employer Approval Status</h3>
          <p className='sans-medium'>Your employer has rejected your reimbursement request. Please contact your HR rep for more details</p>
          <div className='Gb-red-1 my-2 px-2 py-3 rounded-lg d-flex'>
            <div className='mr-3 my-auto'>
              <XCircle size={20} className='Gt-red-5 ' />
            </div >
            <div>
              <p className='mb-0'>Reason: {tuitionRejectionNote}</p>
            </div>
          </div>
          <div className='mt-4'>
            <Link to={redirectUrl} className="G-cancel-button cancelLink text-decoration-none Gt-pink-8">Go Back</Link>
          </div>
        </div>
        <div>
          <h3 className='Gt-slate-5 Gfs-15 mb-3'>Submitted Information</h3>
          <BasicDetailsAccordion basicData={setBasicDetailsAccordionData(fetchIndServiceAccountData.data)} />
          <TuitionDetailsAccordion tuitionData={setTuitionDetailsAccordionData(fetchIndServiceAccountData)} />
          <BankDetailsAccordion bankData={setBankDetailsAccordionData(servicerAccountData, addressData)} />
        </div>
      </div>
    </>
  )
}

export default TuitionReimbursementStatusNeedMoreInfo;