import React, { useState, useEffect } from 'react';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import GeneralAgreement from '../../TermsAndConditions/GeneralAgreement/GeneralAgreement';
import EsignDisclosure from '../../TermsAndConditions/EsignDisclosure/EsignDisclosure';

const TermsAndConditions = (props) => {
  const [showGeneralTerms, setShowGeneralTerms] = useState(true);
  const [showEsignTerms, setShowEsignTerms] = useState(false);

  useEffect(() => {
    document.title = "Vault | Match";
  }, [])


  return (
    <>
      <Header hideNotificationAndProfileDropdown={true} />

      {/* <TermsAndConditions /> */}
      {showGeneralTerms &&
        <GeneralAgreement
          onCloseButtonClick={() => props.history.push("/match/overview")}
          onAcceptButtonClickSuccess={() => {
            setShowGeneralTerms(false)
            setShowEsignTerms(true)
          }}
          alreadyAcceptedRoute={() => {
            setShowGeneralTerms(false)
            setShowEsignTerms(true)
          }} />
      }
      {showEsignTerms &&
        <EsignDisclosure
          onCloseButtonClick={() => props.history.push("/match/overview")}
          onAcceptButtonClickSuccess={() => props.history.push("/match/accounts")}
          alreadyAcceptedRoute={() => props.history.push("/match/accounts")} />
      }

      <div className="G-page-background-color">
        <div className="G-page-main-container my-5">
        </div>
      </div>
      <Footer />
    </>
  )

}


// class TermsAndConditions extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       showGeneralTerms: true,
//       showEsignTerms: false,
//     }
//   }
//   componentDidMount() {
//     document.title = "Vault";
//   }

//   getStarted = (e) => {
//     this.props.history.push({
//       pathname: '/match/accounts'
//     })
//     intercomTrackEvent(eventNames.clickedGetStartedOnMatch);
//   }
//   render() {
//     const { showGeneralTerms, showEsignTerms, } = this.state;

//     return (
//       <>
//         <Header hideNotificationAndProfileDropdown={true} />

//         {/* <TermsAndConditions /> */}
//         {showGeneralTerms &&
//           <GeneralAgreement onCloseButtonClick={() => this.props.history.push("/match/overview")} onAcceptButtonClickSuccess={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} alreadyAcceptedRoute={() => this.setState({ showGeneralTerms: false, showEsignTerms: true })} />
//         }
//         {showEsignTerms &&
//           <EsignDisclosure onCloseButtonClick={() => this.props.history.push("/match/overview")} onAcceptButtonClickSuccess={() => this.props.history.push("/match/accounts")}
//             alreadyAcceptedRoute={() => this.props.history.push("/match/accounts")} />
//         }

//         <div className="G-page-background-color">
//           <div className="G-page-main-container my-5">
//           </div>
//         </div>
//         <Footer />
//       </>
//     )
//   }
// }

export default (TermsAndConditions);