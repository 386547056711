import React from "react";
import { Redirect } from 'react-router-dom';

const requiredAuth = (ChildComponent) => {
  return function ComposedComponent(props) {
    if (localStorage.getItem("userToken")) {
      return (
        <ChildComponent {...props} />
      );
    }
    return <Redirect to={{ pathname: '/login' }} />
  };
};
export default requiredAuth;
