import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import SimpleReactValidator from "simple-react-validator";
import { scroller } from "react-scroll";
import { responseMessage } from '../../../../../utils/alert';

import repayment_calculator from 'program_calculator';

import Header from "../../../../Includes/Header/Header";
import Footer from "../../../../Includes/Footer/Footer";

import EligibilityLoan from "./EligibilityLoan";
import LoanBreakdown from "./LoanBreakdown";
import PayExtraWidget from "./PayExtraWidget";
import Paperwork from "./Paperwork";

import { fetchCustomer, updateCustomer } from '../../../../../actions/customerActions';
import { saveReturnTrip, getLoanType, sendEmailFeedback, trackEvent } from '../../../../../actions/advisorActions';
import { getLoans, getEligibilityResults } from '../../../../../actions/fiveTwoNineActions';

import { convertCamelCaseCustomer } from '../../../../../common/js/jsCustomer';
import { convertHyphensCamelCaseLoansCustom, splitUpperCase } from '../../../../../common/js/jsCustomFunctions';
import { currency_format, eligibilityLoansJoins, isForgivenessEligible, isVariable, _camelize } from '../../../eligibility-result';
import {
  calculatorTranslatorData,
  calculatorResult,
  calculatorResultWithMatch,
  calculatorResultWithExtra,
  calculatorResultWithMatchAndExtra
} from '../../../calculator-result';

import { intercomTrackEvent } from "../../../../customerStatus";
import eventNames from "../../../../event-names-map";

import { longName, payoffTimeInWords } from '../../../../../common/js/jsFunctions';
import { ConsolidationNeededSvg, ForgivenessEligiblePaymentsSvg, PayFasterLargeSvg, StaticPaymentsSvg, VariablePaymentsSvg } from '../../AdviceAndTools.constant'
import { DashCircle, PlusCircle } from 'react-bootstrap-icons';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Breadcrumb from '../../../../shared/Breadcrumb/Breadcrumb';
import { getBreadcrumbForLowerPaymentPlans } from '../../../CommonBreadcrumb/Breadcrumb.constant';
import Loader from '../../../../Includes/Loader/Loader';


class LowerPaymentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      customer_array: {},
      eligibilityResults: [],
      loans: [],
      plan: {},
      existingPlan: {},
      existingLoans: [],
      existingLoansAdvisable: false,
      planName: this.props.match.params.planName,
      seeBreakdown: false,
      matchEnabled: false,
      showPaperwork: false,
      extraAmountValue: 0,
      feedback: "",
      activeHideToggle: "",
      shouldConvertCentsToDollars: true,
      learnAboutPaymentMethods: false,
      externalLoading: true,
    };
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-light">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  changeLanguage(lng) {
    const language = lng === 'extended-graduated' ? 'extendedgraduated' : lng;
    const { i18n } = this.props;
    i18n.changeLanguage(language);
  }
  componentDidMount() {
    document.title = "Vault Advisor | Lower Payments Plan"

    const { saveReturnTrip, fetchCustomer, getLoans, getEligibilityResults, getLoanType } = this.props;
    saveReturnTrip();
    fetchCustomer();
    getLoans();
    getEligibilityResults();
    getLoanType();
    this.changeLanguage(this.state.planName);
    this._isMounted = true;

    this.timerID = setTimeout(() => {
      this.calculateAllLoan();
      this.setState({ externalLoading: false })
    }, 5000);

    const url = this.props.match.url;
    const lastSegment = url.split("/").pop();
    if (lastSegment === 'details') {
      this.setState({
        showPaperwork: true
      })
    }
    intercomTrackEvent(eventNames.visitedAdvisorFedRepaymentPlan);
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  };
  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      const customerData = this.props.customer;
      const customer = convertCamelCaseCustomer(customerData);
      this.setState({
        customer: customer,
        customer_array: customerData
      })
    }
    if (this.props.loans !== prevProps.loans) {
      const loansData = this.props.loans;
      const loans = convertHyphensCamelCaseLoansCustom(loansData);
      this.setState({
        loans: loans
      })

    }
    if (this.props.eligibilityResults !== prevProps.eligibilityResults) {
      const eligibilityResultsData = this.props.eligibilityResults;
      const eligibilityLoans = eligibilityLoansJoins(eligibilityResultsData)
      this.setState({
        eligibilityResults: eligibilityLoans
      });
    }
    if (this.props.emailFeedbackResponse !== prevProps.emailFeedbackResponse) {
      responseMessage("success", "Your question/feedback has been submitted and our team will get back to you very soon!", "");
      this.setState({
        feedback: ""
      });
    }
  }

  calculateAllLoan = () => {
    if (this.chart) {
      this.chart.destroy();
    }
    const { customer, eligibilityResults, loans, shouldConvertCentsToDollars } = this.state;
    let plan_name = this.state.planName;

    if (Object.keys(customer).length > 0 && eligibilityResults.length > 0) {
      const planName = _camelize(plan_name);//plan_name.charAt(0).toUpperCase() + plan_name.slice(1);
      const employerMatch =
        customer.match.length > 0 ? customer.match[0].amount : 0;
      const extraPayment = customer.extraPayment / 100;
      const extraPaymentEmployerMatch = extraPayment + employerMatch;


      const eligibilityNew = eligibilityResults;

      let resultWithMatchAndExtra = null;
      let resultWithExtra = null;
      let result = null;
      let resultWithMatch = null;

      for (var i = 0; i < eligibilityNew.length; i++) {
        const eligibilityLoans = eligibilityNew[i]['loans'];
        const name = eligibilityNew[i]['name'];

        const customerData = calculatorTranslatorData(shouldConvertCentsToDollars, customer, eligibilityLoans);

        resultWithMatchAndExtra = calculatorResultWithMatchAndExtra(customerData, name, extraPaymentEmployerMatch);
        resultWithExtra = calculatorResultWithExtra(customerData, name, extraPayment);
        result = calculatorResult(customerData, name, 0);
        resultWithMatch = calculatorResultWithMatch(customerData, name, employerMatch);

        if (resultWithMatchAndExtra !== null && resultWithExtra !== null && result !== null && resultWithMatch != null) {
          eligibilityNew[i]['resultWithMatchAndExtra'] = resultWithMatchAndExtra;
          eligibilityNew[i]['resultWithExtra'] = resultWithExtra;
          eligibilityNew[i]['result'] = result;
          eligibilityNew[i]['resultWithMatch'] = resultWithMatch;
        }
      }

      const selectedPlan = eligibilityNew.find(item => item.name === planName);
      const existingPlan = eligibilityNew.find(item => item.name === 'Existing');

      const loansArray = existingPlan.result.borrower.loans;
      const existingLoansAdvisable = loansArray.every(function (e) {
        return e.advisable === true
      });

      const considerMatch = (planName === 'Existing') ? false : true;

      const employerMatchAvailable = customer.employerMatchAvailable;
      const hasLifetimeCap = customer.lifetimeCap && (customer.lifetimeCap.employer > 0 || customer.lifetimeCap.customer > 0) ? true : false;
      const match = customer.match;
      const isVesting = match && (match.length > 1 || (match[0] && match[0]['month'] > 1));
      const matchEnabled = employerMatchAvailable && !isVesting && !hasLifetimeCap && existingLoansAdvisable;
      const matchAmount = match.length > 0 ? match[0]['month'] : 0;

      selectedPlan['extraPayment'] = extraPayment;
      selectedPlan['matchEnabled'] = matchEnabled ? matchEnabled : false;
      selectedPlan['considerMatch'] = considerMatch;
      selectedPlan['matchAmount'] = matchAmount;
      selectedPlan['existingLoansAdvisable'] = existingLoansAdvisable;


      this.setState({
        'plan': selectedPlan,
        'existingPlan': existingPlan,
        'existingLoans': loans,
        'existingLoansAdvisable': existingLoansAdvisable,
        'matchEnabled': matchEnabled,
        'extraAmountValue': extraPayment
      })
      this._renderChart();
    }
  }
  _renderChart() {
    const avalancheStrategyData = this._getChartData('avalanche');
    const snowballStrategyData = this._getChartData('snowball');
    const noneStrategyData = this._getChartData('none');

    const labels = noneStrategyData.map(dataRow => {
      return dataRow.x;
    });
    const stepSize = this._getStepSize(labels.length);

    this.chartData = {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'line',
            label: 'Avalanche',
            fill: false,
            data: avalancheStrategyData,
            backgroundColor: '#17c977',
            borderColor: '#17c977',
          }, {
            type: 'line',
            label: 'Snowball',
            fill: false,
            data: snowballStrategyData,
            backgroundColor: '#ff7843',
            borderColor: '#ff7843',
          }, {
            type: 'line',
            label: 'Current plan',
            fill: false,
            data: noneStrategyData,
            backgroundColor: '#8f8f8f',
            borderColor: '#8f8f8f',
          }
        ],
        labels: labels
      },
      options: {
        legend: {
          labels: {
            usePointStyle: true,
          },
          onHover: (e) => {
            e.target.style.cursor = 'pointer';
          },
          onLeave: (e) => {
            e.target.style.cursor = 'default';
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return 'Outstanding Amount: ' + this._toCurrency(tooltipItem.yLabel.toFixed(0));
            },
            title: ([tooltipItem]) => {
              const monthSuffix = this._getSuffix(tooltipItem.xLabel);
              if (tooltipItem.datasetIndex === 0) {
                return tooltipItem.xLabel + monthSuffix + ' month using avalanche strategy';
              } else if (tooltipItem.datasetIndex === 1) {
                return tooltipItem.xLabel + monthSuffix + ' month using snowball strategy';
              } else {
                return tooltipItem.xLabel + monthSuffix + ' month using current plan';
              }
            }
          },
        },
        scales: {
          yAxes: [{
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: 'Outstanding Amount'
            },
            ticks: {
              callback: function (value) {
                return '$' + value;
              }
            }
          }],
          xAxes: [{
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: 'Month'
            },
            ticks: {
              min: 1,
              stepSize: stepSize,
              callback: function (value) {
                const years = Math.floor(value / 12);
                const months = value % 12;
                if (years > 0) {
                  if (months > 0) {
                    return `${years}y ${months}m`;
                  } else {
                    return `${years}y`;
                  }
                } else {
                  return `${months} m`;
                }
              }
            }
          }]
        }
      }
    };
    this.createAvalancheChartWithAmCharts(this.chartData);
    this.createSnowballChartWithAmCharts(this.chartData)

  }
  _getCanvasElement() {
    return document.getElementById('amortization-graph__canvas').getContext('2d');
  }
  _getChartData(type, customer) {
    const { extraAmountValue } = this.state;
    const totalExtraPayment = parseInt(extraAmountValue) + this._getContribution();

    const amortizationData = repayment_calculator(
      this._getCustomerData(),
      {
        program: this.state.plan.name,
        tableRequired: true,
        strategy: type,
        currentExtraPayment: totalExtraPayment
      }
    ).amortizationTable;

    return amortizationData.map(row => {
      return { x: row.month, y: row.outstanding };
    });

  }
  _toCurrency(num) {
    return '$' + num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  _getSuffix(num) {
    return ['st', 'nd', 'rd'][((parseInt(num) + 90) % 100 - 10) % 10 - 1] || 'th';
  }
  _getContribution() {
    const { customer, matchEnabled } = this.state;
    if (matchEnabled) {
      return customer.match[0]['amount'];
    } else {
      return 0;
    }
  }
  _getCustomerData() {
    const { customer_array, plan } = this.state;
    const jsCustomer = convertCamelCaseCustomer(customer_array);
    const jsLoans = plan.loans;
    jsCustomer.loans = jsLoans;

    this.setState({
      shouldConvertCentsToDollars: false,
    });

    return jsCustomer;
  }
  _getStepSize(sequenceLength) {
    if (sequenceLength <= 30) {
      return 1;
    } else if (sequenceLength <= 60) {
      return 2;
    } else if (sequenceLength <= 90) {
      return 3;
    } else if (sequenceLength <= 120) {
      return 4;
    } else {
      return 5;
    }
  }
  getYearFromMonth(month) {
    return payoffTimeInWords('%ys%ms', month)
  }
  createAvalancheChartWithAmCharts = (chartData) => {
    let avalancheData = chartData?.data?.datasets.filter((x) => x.label === "Avalanche")[0].data
    let currentData = chartData?.data?.datasets.filter((x) => x.label === "Current plan")[0].data

    let mergedData = [];

    currentData.forEach((element, i) => {
      let obj;
      if (!isNaN(avalancheData[i]?.y)) {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
          avalanche: currency_format(avalancheData[i].y),
        }
      } else {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
        }
      }
      mergedData.push(obj)
    });

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.addLicense("ch-custom-attribution");
    // Create chart instance
    var chart = am4core.create("avalancheAmCharts", am4charts.XYChart);

    // Add data
    chart.data = mergedData;
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.title.text = "Month";
    categoryAxis.renderer.minGridDistance = 50;
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Outstanding Amount";
    valueAxis.renderer.labels.template.adapter.add("text", function (text, target) { return '$' + text; });

    let label = categoryAxis.renderer.labels.template;
    label.rotation = -90;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "current";
    series.dataFields.categoryX = "month";
    series.strokeWidth = 3;
    series.strokeDasharray = "3,4";
    series.minBulletDistance = 10;
    series.tooltipText = "[bold]{month} using Current Plan\n[bold]Outstanding Amount:[/] {current}";
    // series.tooltip.pointerOrientation = "vertical";
    series.stroke = am4core.color("#898989");
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#D5D5D5");
    series.tooltip.label.fill = am4core.color("#000");
    // Create series
    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "avalanche";
    series2.dataFields.categoryX = "month";
    series2.strokeWidth = 3;
    series2.strokeDasharray = "3,4";
    series2.tooltipText = "[bold]{month} using Avalanche Plan\n[bold]Outstanding Amount:[/] {avalanche}";
    series2.stroke = series.stroke;
    series2.stroke = am4core.color('var(--icon-color)');
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color("#D5D5D5");
    series2.tooltip.label.fill = am4core.color('var(--icon-color)');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }
  createSnowballChartWithAmCharts = (chartData) => {
    let snowballData = chartData?.data?.datasets.filter((x) => x.label === "Snowball")[0].data
    let currentData = chartData?.data?.datasets.filter((x) => x.label === "Current plan")[0].data

    let mergedData = [];

    currentData.forEach((element, i) => {
      let obj;
      if (!isNaN(snowballData[i]?.y)) {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
          snowball: currency_format(snowballData[i].y),
        }
      } else {
        obj = {
          month: this.getYearFromMonth(element.x),
          current: currency_format(element.y),
        }
      }
      mergedData.push(obj)
    });

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.addLicense("ch-custom-attribution");
    // Create chart instance
    var chart = am4core.create("snowballAmCharts", am4charts.XYChart);

    // Add data
    chart.data = mergedData;
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "month";
    categoryAxis.title.text = "Month";
    categoryAxis.renderer.minGridDistance = 50;
    // categoryAxis.renderer.labels.template.adapter.add("text", function (text, target) { return text; });
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Outstanding Amount";
    valueAxis.renderer.labels.template.adapter.add("text", function (text, target) { return '$' + text; });

    let label = categoryAxis.renderer.labels.template;
    label.rotation = -90;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "current";
    series.dataFields.categoryX = "month";
    series.strokeWidth = 3;
    series.strokeDasharray = "3,4";
    series.minBulletDistance = 10;
    series.tooltipText = "[bold]{month} using Current Plan\n[bold]Outstanding Amount:[/] {current}";
    // series.tooltip.pointerOrientation = "vertical";
    series.stroke = am4core.color("#898989");
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#D5D5D5");
    series.tooltip.label.fill = am4core.color("#000");
    // Create series
    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "snowball";
    series2.dataFields.categoryX = "month";
    series2.strokeWidth = 3;
    series2.strokeDasharray = "3,4";
    series2.tooltipText = "[bold]{month} using Avalanche Plan\n[bold]Outstanding Amount:[/] {snowball}";
    series2.stroke = series.stroke;
    series2.stroke = am4core.color('var(--icon-secondary-color)');
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color("#D5D5D5");
    series2.tooltip.label.fill = am4core.color('var(--icon-secondary-color)');
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }
  toggleBreakdown = (status, e) => {
    const rotate = status === true ? 'rotate' : '';
    this.setState({
      seeBreakdown: !this.state.seeBreakdown,
      activeHideToggle: rotate
    })
  }
  toPaperwork = (e) => {
    this.setState({
      showPaperwork: !this.state.showPaperwork
    })
    intercomTrackEvent(eventNames.clickedLearnMoreOnRepaymentPlan);
  }
  trackOneOnOneEvent = (e) => {
    const url = "https://calendly.com/advisor-session/advisor-session";
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
  }
  clickedDownloadPdf = (e, url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
  }
  handelRangeTracker = (e) => {
    const controlVal = e.target.value
    this.setState({
      extraAmountValue: controlVal
    })
  }
  trackExtraPayment = (e) => {
    const { customer_array, customer } = this.state;
    const { updateCustomer } = this.props;
    const controlVal = e.target.value

    customer_array.attributes.extra_payment_cents = controlVal * 100;
    customer.extraPayment = controlVal * 100;

    const jsonRequest = {
      "data": customer_array
    }
    updateCustomer(jsonRequest);

    this.setState({
      extraAmountValue: controlVal,
      customer_array: customer_array,
      customer: customer
    },
      () => {
        this.calculateAllLoan();
      });
  }
  focusOnFeedBack = (e) => {
    scroller.scrollTo("form_box_area", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  handleChangeFeedback = (e) => {
    this.setState({
      feedback: e.target.value
    })
  }
  submitFeedback = (e) => {
    if (this.validator.allValid()) {
      const { feedback, customer } = this.state;
      const { sendEmailFeedback } = this.props;
      const source = "source:Advisor\nabout:Feedback form submission\n\n" + feedback;

      const feedbackJson = {
        "mail_to": customer.advisorSupportEmail,
        "feedback": source
      }
      sendEmailFeedback(feedbackJson);
      intercomTrackEvent(eventNames.submittedFeedbackFormAdvisorDashboard);
    } else {
      this.validator.showMessages();
    }
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  render() {
    const {
      customer,
      customer_array,
      plan,
      seeBreakdown,
      existingLoans,
      existingLoansAdvisable,
      matchEnabled,
      existingPlan,
      showPaperwork,
      extraAmountValue,
    } = this.state;
    const { t } = this.props;
    let planNameForBreadcrumb = plan ? longName(plan.name) ? longName(plan.name) : (plan.name ? splitUpperCase(plan.name) : plan.name) : '';
    return (

      <>
        <Loader externalLoading={this.state.externalLoading} />
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={getBreadcrumbForLowerPaymentPlans(planNameForBreadcrumb)} />
            <div className='p-4 bg-white Gborder-1 rounded-lg'>
              <div>
                <label className='G-badge-purple'>
                  Plan Type
                </label>
                <h4 className=' my-3'>{plan ? longName(plan.name) ? longName(plan.name) : (plan.name ? splitUpperCase(plan.name) : plan.name) : ''}</h4>
              </div>
              <div className='d-flex G-gap-2 mb-2'>
                {
                  isForgivenessEligible(plan.name) ?
                    <div>
                      <ForgivenessEligiblePaymentsSvg />
                      <label className='ml-2'>Forgiveness Eligible</label>
                    </div>
                    : null
                }
                {
                  isVariable(plan.name) ?
                    <div>
                      <VariablePaymentsSvg />
                      <label className='ml-2'>Variable Payments</label>
                    </div>
                    :
                    <div>
                      <StaticPaymentsSvg />
                      <label className='ml-2'>Static Payments</label>
                    </div>
                }
                {
                  plan.consolidating ?
                    <div>
                      <ConsolidationNeededSvg />
                      <label className='ml-2'>Consolidation Needed</label>
                    </div>
                    : null
                }
              </div>

              <div>
                <p className='Gfs-1 sans-medium G-text-justify '>{t('plan.description')}</p>
                {plan.name === 'Save' ?
                  null
                  :
                  <>
                    <p className='Gfs-1 sans-medium G-text-justify '>{t('plan.howPaymentsAreCalculated')}</p>
                    <p className='Gfs-1 sans-medium'>
                      For further information about this repayment plan,
                      visit &nbsp;
                      <a className="G-link text-decoration-none cancelLink sans-semibold" href={t('plan.officialLink')} target="_blank" rel="noopener noreferrer" >
                        www.studentaid.ed.gov
                      </a>
                      , a website administered by the federal government.
                    </p>
                  </>
                }

              </div>

              <EligibilityLoan
                program={plan}
                customer={customer}
                considerExtraPayment={false}
                matchAmount={customer_array.attributes?.employer_match}
              />

              <div className="Gborder-05 rounded-lg p-4 mt-4">
                <div className="mt-2 d-flex G-cursor-pointer" onClick={this.toggleBreakdown.bind(this, true)}>
                  <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
                    See the plan breakdown
                  </label>
                  {
                    seeBreakdown === false ?
                      <div className="ml-auto my-auto G-cursor-pointer" >
                        <PlusCircle size={22} />
                      </div> :
                      <div className="ml-auto my-auto G-cursor-pointer">
                        <DashCircle size={22} />
                      </div>
                  }
                </div>
                {seeBreakdown ?
                  <LoanBreakdown
                    existingLoans={existingLoans}
                    existingLoansAdvisable={existingLoansAdvisable}
                    program={plan}
                    matchEnabled={matchEnabled}
                    t={t}
                  />
                  : null}
              </div>

              <div className='mt-5'>
                <div className="pinkCircularDiv mb-3">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M29.9875 17.5C23.0875 17.5 17.5 23.1 17.5 30C17.5 36.9 23.0875 42.5 29.9875 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 29.9875 17.5ZM30 40C24.475 40 20 35.525 20 30C20 24.475 24.475 20 30 20C35.525 20 40 24.475 40 30C40 35.525 35.525 40 30 40ZM34.375 28.75C35.4125 28.75 36.25 27.9125 36.25 26.875C36.25 25.8375 35.4125 25 34.375 25C33.3375 25 32.5 25.8375 32.5 26.875C32.5 27.9125 33.3375 28.75 34.375 28.75ZM25.625 28.75C26.6625 28.75 27.5 27.9125 27.5 26.875C27.5 25.8375 26.6625 25 25.625 25C24.5875 25 23.75 25.8375 23.75 26.875C23.75 27.9125 24.5875 28.75 25.625 28.75ZM30 36.875C32.9125 36.875 35.3875 35.05 36.3875 32.5H23.6125C24.6125 35.05 27.0875 36.875 30 36.875Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h4>Good to know</h4>
                {plan.name === 'Save' ?
                  <>
                    <p className='Gfs-1 sans-medium G-text-justify '>{t('plan.howPaymentsAreCalculated')}</p>
                    <p className='Gfs-1 sans-medium'>
                      You can apply for SAVE (and other income-driven repayment plans) online:&nbsp;
                      <a className="G-link text-decoration-none cancelLink sans-semibold" href="https://studentaid.gov/idr/" target="_blank" rel="noopener noreferrer" >
                        www.studentaid.gov/idr/
                      </a>
                    </p>
                  </>
                  : null}
              </div>

              <div className='G-grid11-2 mt-4'>
                <div className='Gt-green-6 Gb-green-2 p-4 rounded-lg Gborder-2 Gborder-green-6' >
                  <div className="Gfs-125 align-items-center d-flex h-100 justify-content-center mb-0 text-center">
                    Monthly Payment&nbsp;
                    {t('plan.monthlyPayment')}
                  </div>
                </div>

                <div className=' Gt-red-5 Gb-red-1 p-4 rounded-lg Gborder-2 Gborder-red-5' >
                  <div className="Gfs-125 align-items-center d-flex h-100 justify-content-center mb-0 text-center">
                    Plan-Based Forgiveness&nbsp;
                    {t('plan.forgivenessEligible')}
                  </div>
                </div>
              </div>

              <div className='Gborder-05 rounded-lg p-4 my-3'>
                <div className='G-grid11-2'>
                  <div className='Gborder-r05-responsive pr-3'>
                    <div>
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="#DCF3D1" />
                        <path d="M26.25 35.2125L21.0375 30L19.2625 31.7625L26.25 38.75L41.25 23.75L39.4875 21.9875L26.25 35.2125Z" fill="#44A316" />
                      </svg>
                    </div>
                    <label className='Gfs-125 my-3 sans-semibold'>Advantages</label>
                    <ul className="G-ul-bullets ml-4">
                      {t('advantages.one') ?
                        <li className='sans-medium mb-2'>{t('advantages.one')}</li>
                        : null}
                      {t('advantages.two') ?
                        <li className='sans-medium mb-2'>{t('advantages.two')}</li>
                        : null}
                      {t('advantages.three') ?
                        <li className='sans-medium mb-2'>{t('advantages.three')}</li>
                        : null}
                      {t('advantages.four') ?
                        <li className='sans-medium mb-2'>{t('advantages.four')}</li>
                        : null}
                      {plan.name === 'Save' ?
                        <>
                          {t('advantages.five') ?
                            <li className='sans-medium mb-2'>{t('advantages.five')}</li>
                            : null}
                          {t('advantages.six') ?
                            <li className='sans-medium mb-2'>{t('advantages.six')}</li>
                            : null}
                          {t('advantages.seven') ?
                            <li className='sans-medium mb-2'>{t('advantages.seven')}</li>
                            : null}
                        </>
                        : null}
                    </ul>
                  </div>

                  <div>
                    <div>
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="#FDD3D5" />
                        <path d="M30 40C24.4875 40 20 35.5125 20 30C20 24.4875 24.4875 20 30 20C35.5125 20 40 24.4875 40 30C40 35.5125 35.5125 40 30 40ZM30 17.5C23.0875 17.5 17.5 23.0875 17.5 30C17.5 36.9125 23.0875 42.5 30 42.5C36.9125 42.5 42.5 36.9125 42.5 30C42.5 23.0875 36.9125 17.5 30 17.5ZM33.2375 25L30 28.2375L26.7625 25L25 26.7625L28.2375 30L25 33.2375L26.7625 35L30 31.7625L33.2375 35L35 33.2375L31.7625 30L35 26.7625L33.2375 25Z" fill="#CC1C26" />
                      </svg>

                    </div>
                    <label className='Gfs-125 my-3 sans-semibold'>Disadvantages</label>
                    <ul className="G-ul-bullets ml-4 sans-medium">
                      {t('disadvantages.one') ?
                        <li className='sans-medium mb-2'>{t('disadvantages.one')}</li>
                        : null}
                      {t('disadvantages.two') ?
                        <li className='sans-medium mb-2'>{t('disadvantages.two')}</li>
                        : null}
                      {t('disadvantages.three') ?
                        <li className='sans-medium mb-2'>{t('disadvantages.three')}</li>
                        : null}
                      {t('disadvantages.four') ?
                        <li className='sans-medium mb-2'>{t('disadvantages.four')}</li>
                        : null}
                      {t('disadvantages.five') ?
                        <li className='sans-medium mb-2'>{t('disadvantages.five')}</li>
                        : null}
                      {t('disadvantages.six') ?
                        <li className='sans-medium mb-2'>{t('disadvantages.six')}</li>
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              <h4 className='mt-5 mb-4'>Adjust monthly payment for your new plan</h4>
              <label className='G-blue-badge'>selected Plan Type</label>
              <h4 className='Gfs-125 mb-3'>{plan ? longName(plan.name) ? longName(plan.name) : (plan.name ? splitUpperCase(plan.name) : plan.name) : ''}</h4>
              <PayExtraWidget
                program={plan}
                customer={customer_array}
                matchEnabled={matchEnabled}
                existingLoansAdvisable={existingLoansAdvisable}
                extraAmountValue={extraAmountValue}
                handelRangeTracker={this.handelRangeTracker.bind(this)}
                trackExtraPayment={this.trackExtraPayment.bind(this)}
              />

              <div className="Gborder-05 rounded-lg p-4 mt-3">
                <div className="mt-2 d-flex G-cursor-pointer" onClick={() => this.setState({ learnAboutPaymentMethods: !this.state.learnAboutPaymentMethods })}>
                  <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
                    Learn more about the payment methods
                  </label>
                  {
                    this.state.learnAboutPaymentMethods === false ?
                      <div className="ml-auto my-auto G-cursor-pointer" >
                        <PlusCircle size={22} />
                      </div> :
                      <div className="ml-auto my-auto G-cursor-pointer">
                        <DashCircle size={22} />
                      </div>
                  }
                </div>
                {this.state.learnAboutPaymentMethods ?
                  <>
                    <div>
                      <div className="G-grid11-2 mt-4">
                        <div>
                          <div className="d-flex">
                            <div className="my-auto">
                              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                                <g clipPath="url(#clip0_2807_5107)">
                                  <path d="M14.1414 15.7657L12.1414 13.099H8.14136V22.4324H21.4747L17.4747 17.099L14.1414 15.7657ZM15.1614 17.6124L10.808 19.0657L9.47469 18.619V17.359L10.808 17.8057L13.4414 16.9257L15.1614 17.6124ZM11.4747 14.4324L12.5214 15.8257L10.808 16.399L9.47469 15.9524V14.4324H11.4747ZM9.47469 21.099V20.0257L10.808 20.4724L16.828 18.4657L18.808 21.099H9.47469ZM18.1414 11.7657V8.43235L14.808 7.76569L12.808 9.09902V11.7657L14.808 13.099L18.1414 11.7657ZM14.1414 9.81235L15.088 9.17902L16.808 9.52569V10.8657L14.9614 11.6057L14.1414 11.0524V9.81235ZM19.1414 12.4324L17.4747 13.7657V15.7657L19.1414 17.099L22.1414 15.7657V13.099L19.1414 12.4324ZM20.808 14.899L19.3414 15.5524L18.808 15.1257V14.4057L19.4747 13.8724L20.808 14.1657V14.899Z" fill="var(--icon-color)" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2807_5107">
                                    <rect width="16" height="16" fill="white" transform="translate(7 7.5)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <label className="mb-0 Gfs-125 ml-2">
                              Avalanche Method
                            </label>
                          </div>
                          <p className="sans-medium mt-4">
                            The debt avalanche method targets debts with the highest interest rates first. Order your student loans from highest interest rate to lowest. Focus on paying extra funds towards the loan with the highest interest rate, while continuing to make minimum payments on the other student loans. Once you payoff the student loan with the highest interest rate, focus the extra funds on the debt with the next-highest interest rate. Continue this strategy until all loans have been repaid. The debt avalanche method targets debts with the highest interest rates first.
                          </p>
                          <p className="sans-bold my-3">
                            How do I do it?
                          </p>
                          <div className="d-flex">
                            <p className="sans-medium">1.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Order your student loans from highest interest rate to lowest.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="sans-medium">2.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Focus on paying extra funds towards the loan with the hightest interest rate,
                              while continuing to make minimum payments on the other student loans.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="sans-medium">3.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Once you payoff the student loan with the hightest interest
                              rate, focus the extra funds on the debt with the next-highest interest rate.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="sans-medium">4.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Continue this strategy until all loans have been repaid.
                            </p>
                          </div>
                        </div>


                        <div>
                          <div className="d-flex">
                            <div className="my-auto">
                              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                                <path d="M14.9999 10.1667C17.9399 10.1667 20.3333 12.56 20.3333 15.5C20.3333 18.44 17.9399 20.8333 14.9999 20.8333C12.0599 20.8333 9.66659 18.44 9.66659 15.5C9.66659 12.56 12.0599 10.1667 14.9999 10.1667ZM14.9999 8.83334C11.3199 8.83334 8.33325 11.82 8.33325 15.5C8.33325 19.18 11.3199 22.1667 14.9999 22.1667C18.6799 22.1667 21.6666 19.18 21.6666 15.5C21.6666 11.82 18.6799 8.83334 14.9999 8.83334Z" fill="var(--icon-color)" />
                              </svg>
                            </div>
                            <label className="mb-0 Gfs-125 ml-2">
                              Snowball Method
                            </label>
                          </div>
                          <p className="sans-medium mt-4">
                            The debt snowball method targets the smallest debt first.
                          </p>
                          <p className="sans-bold my-3">
                            How do I do it?
                          </p>
                          <div className="d-flex">
                            <p className="sans-medium">1.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Order your student loans from lowest balance to highest balance.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="sans-medium">2.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Focus on paying extra funds towards the loan with the lowest balance,
                              while continuing to make minimum payments on the other student loans.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="sans-medium">3.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Once you payoff the student loan with the lowest balance,
                              focus the extra funds on the debt with the next-lowest balance.
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="sans-medium">4.&nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              Continue this strategy until all loans have been repaid.
                            </p>
                          </div>
                        </div>
                      </div >
                      <div className="G-grid11-2 mt-4">
                        <div>
                          <div className="d-flex">
                            <div className="my-auto">
                              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="30" height="30" rx="15" fill="var(--icon-background-color)" />
                                <path d="M15.6666 10.8867C17.7066 11.18 19.3199 12.7934 19.6133 14.8334H21.6333C21.3199 11.6867 18.8133 9.18003 15.6666 8.8667V10.8867ZM19.6133 16.1667C19.3199 18.2067 17.7066 19.82 15.6666 20.1134V22.1334C18.8133 21.82 21.3199 19.3134 21.6333 16.1667H19.6133ZM14.3333 20.1134C12.0733 19.7867 10.3333 17.8467 10.3333 15.5C10.3333 13.1534 12.0733 11.2134 14.3333 10.8867V8.8667C10.9666 9.20003 8.33325 12.04 8.33325 15.5C8.33325 18.96 10.9666 21.8 14.3333 22.1334V20.1134Z" fill="var(--icon-color)" />
                              </svg>
                            </div>
                            <label className="mb-0 Gfs-125 ml-2">
                              Standard Method
                            </label>
                          </div>
                          <p className="sans-medium mt-4">
                            Standard assumes that you pay the minimum monthly payments for all loans.
                          </p>
                          <p className="sans-bold my-3">
                            What else should you consider when selecting a payoff strategy?
                          </p>
                          <p className="sans-medium">
                            If you thrive on long-term projects, consider the debt avalanche method.
                          </p>
                          <div className="d-flex">
                            <p className="sans-medium mx-1"> &#183; &nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              The debt avalanche method eliminates your most
                              expensive debts first, and saves you the most on interest.
                              However, paying the highest-interest rate debt first can
                              take some time.
                            </p>
                          </div>
                          <p className="sans-medium">
                            If quick wins drive your motivation, consider the debt snowball method.
                          </p>
                          <div className="d-flex">
                            <p className="sans-medium mx-1"> &#183; &nbsp;&nbsp;</p>
                            <p className="sans-medium">
                              If you prefer short-term victories to fuel your motivation to
                              continue your payoff plan, you may prefer the debt snowball method.
                            </p>
                          </div>
                        </div>
                      </div >
                    </div>
                  </>
                  : null}

              </div>

              <div className="Gborder-05 rounded-lg p-4 mt-3">
                <div className="mt-2 d-flex G-cursor-pointer" onClick={this.toPaperwork.bind(this)}>
                  <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer">
                    Apply to lower your minimum payment
                  </label>
                  {
                    showPaperwork === false ?
                      <div className="ml-auto my-auto G-cursor-pointer" >
                        <PlusCircle size={22} />
                      </div> :
                      <div className="ml-auto my-auto G-cursor-pointer">
                        <DashCircle size={22} />
                      </div>
                  }
                </div>
                {
                  showPaperwork ?
                    <Paperwork
                      originalPlan={existingPlan}
                      newPlan={plan}
                      customer={customer}
                      existingLoansAdvisable={existingLoansAdvisable}
                      matchEnabled={matchEnabled}
                      trackOneOnOneEvent={this.trackOneOnOneEvent.bind(this)}
                      clickedDownloadPdf={this.clickedDownloadPdf.bind(this)}
                      focusOnFeedBack={this.focusOnFeedBack.bind(this)}
                      trackEvent={this.props.trackEvent.bind(this)}
                    />
                    : null
                }
              </div>

              <div className='Gborder-05 rounded-lg p-4 mt-5'>
                <div className="G-grid11-2 mob-reverse-1200">
                  <div>
                    <label className='Gfs-25 G-text-grad sans-bold'>
                      Take control of your finances and pay off your debts faster
                    </label>
                    <label className='Gfs-1 sans-medium my-3'>
                      By putting more money towards your loan each month, you can accelerate the repayment timeline and enjoy greater financial freedom sooner.
                    </label>
                    <button className='bg-transparent border-0 G-orange-button' onClick={() => {
                      this.props.history.push({
                        pathname: '/advice-and-tools/pay-faster'
                      })
                    }}>
                      Explore Payoff Faster
                    </button>
                  </div>
                  <div className="mx-auto">
                    <PayFasterLargeSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    )
  }
}
LowerPaymentPlan.propTypes = {
  saveReturnTrip: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getLoans: PropTypes.func.isRequired,
  getEligibilityResults: PropTypes.func.isRequired,
  getLoanType: PropTypes.func.isRequired,
  sendEmailFeedback: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  returnTrip: state.advisor.returnTrip,
  customer: state.customer_array.customerData,
  updateCustomerResponse: state.customer_array.updateCustomerResponse,
  loans: state.five_two_nine.loans,
  eligibilityResults: state.five_two_nine.eligibilityResults,
  loanTypeData: state.advisor.loanTypeData,
  emailFeedbackResponse: state.advisor.emailFeedbackResponse,
  errors: state.errors
});
export default compose(withTranslation(), connect(mapStateToProps, { saveReturnTrip, fetchCustomer, getLoans, getEligibilityResults, getLoanType, updateCustomer, sendEmailFeedback, trackEvent }))(LowerPaymentPlan);
