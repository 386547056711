import React, { Component } from 'react';
import JohnHancockLogo from "../../../../common/img/JohnHancock_white.png";

class Logo extends Component {
  render() {
    let host = window.location.host;
    let parts = host.split(".");
    let subdomain = "";
    if (parts.length >= 1) {
      subdomain = parts[0];
    }
    return (
      <>
        <span>
          {subdomain === 'johnhancock' ?
            <img src={JohnHancockLogo} alt="check" />
            :
            <svg viewBox="-46.529 44.829 124.233 50.455" xmlns="http://www.w3.org/2000/svg">
              <polygon className="st0" points="9.461 60.001 4.946 73.857 0.396 60.001 -3.554 60.001 3.022 79.66 6.851 79.66 13.409 60.001" style={{ fill: 'rgb(255, 255, 255)' }}></polygon>
              <path className="st0" d="M 22.2 67.316 C 21.291 66.354 19.899 65.701 18.114 65.701 C 15.024 65.701 11.452 67.968 11.452 72.826 C 11.452 77.651 15.144 79.952 18.046 79.952 C 19.83 79.952 21.273 79.213 22.183 78.149 L 22.183 79.677 L 25.942 79.677 L 25.942 65.977 L 22.183 65.977 L 22.183 67.316 Z M 18.801 76.483 C 16.723 76.483 15.127 74.886 15.127 72.792 C 15.127 70.68 16.723 69.117 18.801 69.117 C 20.826 69.117 22.303 70.766 22.303 72.792 C 22.303 74.819 20.826 76.483 18.801 76.483 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M 36.793 73.444 C 36.793 75.745 35.608 76.724 34.184 76.724 C 32.518 76.724 31.659 75.659 31.659 73.822 L 31.659 65.977 L 27.849 65.977 L 27.849 74.32 C 27.849 77.856 29.788 79.935 33.017 79.935 C 34.578 79.935 35.884 79.248 36.793 78.149 L 36.793 79.66 L 40.519 79.66 L 40.519 65.977 L 36.76 65.977 L 36.76 73.444 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <rect x="42.459" y="60.001" className="st0" width="3.76" height="19.658" style={{ fill: 'rgb(255, 255, 255)' }}></rect>
              <path className="st0" d="M 54.151 73.685 L 54.151 69.341 L 56.709 69.341 L 56.709 65.977 L 54.151 65.977 L 54.151 60.001 L 51.524 61.444 C 50.82 61.821 50.391 62.559 50.391 63.349 L 50.391 65.977 L 48.056 65.977 L 48.056 69.341 L 50.391 69.341 L 50.391 73.719 C 50.391 78.183 51.816 79.66 56.177 79.66 L 56.726 79.66 L 56.726 76.158 C 54.427 76.158 54.151 75.831 54.151 73.685 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -18.8 65.723 L -18.8 65.723 C -18.02 67.082 -16.069 67.082 -15.274 65.723 L -13.887 63.339 L -12.499 60.953 C -11.719 59.595 -12.701 57.904 -14.263 57.904 L -14.277 57.904 C -15 57.904 -15.679 58.295 -16.04 58.916 L -18.8 63.685 C -19.161 64.322 -19.161 65.087 -18.8 65.723 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -20.174 63.325 L -20.014 63.598 L -16.733 57.889 L -17.038 57.889 L -19.797 57.889 C -21.359 57.889 -22.341 59.581 -21.56 60.939 L -20.174 63.325 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -23.179 68.296 C -22.818 68.917 -22.139 69.307 -21.416 69.307 L -21.344 69.307 C -19.783 69.307 -18.8 67.617 -19.58 66.257 L -21.025 63.771 L -22.457 61.301 C -23.237 59.942 -25.187 59.942 -25.983 61.301 L -26.026 61.373 C -26.387 62.009 -26.387 62.775 -26.026 63.411 L -23.179 68.296 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -27.066 69.307 L -24.205 69.307 L -23.888 69.307 L -27.254 63.511 L -27.413 63.771 L -28.83 66.257 C -29.61 67.617 -28.642 69.307 -27.066 69.307 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -21.402 74.336 C -20.68 74.336 -19.999 73.947 -19.638 73.324 L -19.595 73.267 C -18.815 71.909 -19.797 70.218 -21.359 70.218 L -24.219 70.218 L -27.081 70.218 C -28.642 70.218 -29.625 71.909 -28.844 73.267 L -28.801 73.339 C -28.439 73.974 -27.76 74.351 -27.037 74.351 L -21.402 74.336 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -20.881 75.464 L -27.573 75.478 L -27.413 75.738 L -25.983 78.21 C -25.201 79.568 -23.251 79.568 -22.457 78.21 L -21.025 75.738 L -20.881 75.464 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -15.26 73.787 L -15.303 73.729 C -16.083 72.371 -18.035 72.371 -18.829 73.729 L -20.259 76.2 L -21.691 78.672 C -22.471 80.03 -21.487 81.722 -19.927 81.722 L -19.84 81.722 C -19.118 81.722 -18.439 81.331 -18.078 80.71 L -15.26 75.825 C -14.898 75.189 -14.898 74.424 -15.26 73.787 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -13.873 76.2 L -14.03 75.926 L -17.369 81.736 L -17.067 81.736 L -14.204 81.736 C -12.644 81.736 -11.661 80.045 -12.441 78.687 L -13.873 76.2 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -10.909 71.244 C -11.271 70.622 -11.95 70.233 -12.672 70.233 L -12.746 70.233 C -14.306 70.233 -15.288 71.923 -14.508 73.281 L -13.077 75.753 L -11.647 78.224 C -10.867 79.582 -8.915 79.582 -8.12 78.224 L -8.077 78.152 C -7.716 77.515 -7.716 76.749 -8.077 76.114 L -10.909 71.244 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -7.022 70.233 L -9.883 70.233 L -10.202 70.233 L -6.848 76.027 L -6.689 75.767 L -5.259 73.295 C -4.479 71.923 -5.461 70.233 -7.022 70.233 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -5.288 66.2 C -5.649 65.564 -6.328 65.188 -7.051 65.188 L -12.687 65.188 C -13.409 65.188 -14.088 65.578 -14.451 66.2 L -14.494 66.257 C -15.274 67.617 -14.292 69.307 -12.73 69.307 L -9.869 69.307 L -7.008 69.307 C -5.447 69.307 -4.464 67.617 -5.244 66.257 L -5.288 66.2 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
              <path className="st0" d="M -13.221 64.06 L -6.531 64.046 L -6.689 63.786 L -8.12 61.315 C -8.901 59.956 -10.852 59.956 -11.647 61.315 L -13.077 63.786 L -13.221 64.06 Z" style={{ fill: 'rgb(255, 255, 255)' }}></path>
            </svg>
          }
        </span>
      </>
    )
  }
}

export default (Logo);