import React from 'react'
import { PayDashboardAdvisorSvg } from './Payment.constant'

const PayToAdvisorBanner = () => {
  let url = "/advice-and-tools/dashboard";

  return (
    <>
      <div className='Gborder-05 rounded-lg p-4 mt-5'>
        <div className="G-grid11-2 mob-reverse-1200">
          <div>
            <label className='Gfs-25 G-text-grad sans-bold'>
              Unleash the Power of Financial Wisdom...
            </label>
            <label className='Gfs-1 sans-medium my-3'>
              Advice and Tools isn't just another platform - it's your compass in the sea of debt. Navigate with our Advisor, see if you're eligible for lower monthly payments, assess refinancing impacts, or learn how to pay off your loans faster. Every tool is crafted to serve your financial needs, ensuring a brighter, debt-free future. Don't wait, explore now!
            </label>
            <a href={url}>
              <button className='bg-transparent border-0 G-orange-button'>
                Explore Advice and tools
              </button>
            </a>
          </div>
          <div className="mx-auto">
            <PayDashboardAdvisorSvg />
          </div>
        </div>
      </div>
    </>
  )
}

export default PayToAdvisorBanner;