import React from 'react'
import { Check } from 'react-bootstrap-icons'

const ArrowLogo = () => {
    return (
        <div className='wizard-step-arrow-logo'>
            <svg width="18" height="72" viewBox="0 0 18 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.666656 0L17.6667 36.2483L0.666656 72" stroke="#D5D5D5" strokeWidth="0.5" />
            </svg>
        </div>
    )
}

const step = [
    {
        stepNumber: 1,
        stepName: 'Set up profile'
    },
    {
        stepNumber: 2,
        stepName: 'Import/add your loans'
    },
    {
        stepNumber: 3,
        stepName: 'Review loans'
    }
]

const WizardSteps = ({ stepNumber }) => {
    return (
        <div className=" px-4 Gborder-05 bg-white my-3 d-flex wizard-step-container rounded-lg">
            {step.map((item, index) => (
                <div key={index} className={'d-flex position-relative flex-grow-1 ' + (item.stepNumber === stepNumber ? ' Gt-orange-5' : '') + (item.stepNumber > stepNumber ? ' Gt-slate-3' : '')}>
                    {item.stepNumber !== 1 ? < ArrowLogo /> : ''}
                    {item.stepNumber < stepNumber ?
                        <Check className='text-white orangeCircularDiv my-auto mx-3 Gb-orange-5' />
                        :
                        <div className={'mx-3 my-auto ' + (item.stepNumber > stepNumber ? ' slateCircularDiv' : ' orangeCircularDiv')} >{('0' + item.stepNumber).slice(-2)}</div>
                    }

                    <div className='my-auto'>{item.stepName}</div>
                </div>
            ))}
        </div>
    )
}

export default WizardSteps;