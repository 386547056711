import {
  GET_NOTIFICATIONS,
  GET_ERRORS 
} from './types';

import { apiAction } from './apiActions';
import config from '../config';


export const fetchNotifications = () => {
  return apiAction({
   url: config.apiBaseUrl + "notifications",
   onSuccess: setGetNotifications,
   callback : '',
   onFailure: errorGetNotifications,
   label: GET_NOTIFICATIONS
   });
}
 
function setGetNotifications(data) {
  return {
    type: GET_NOTIFICATIONS,
    payload: data.data
  };
}
function errorGetNotifications(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}

