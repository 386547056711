import { statusText } from '../../common/js/jsFunctions';
export function getNeedsFinicityLink(servicerAccount){
  return servicerAccount.attributes.finicity_active && servicerAccount.attributes.needs_finicity_reconnect;
}

/**
   * GENIUS-6797: Prevent User From Enrolling in Pay if Balance is Too Low
   *
   * Determine if this servicer account is finicity linked
   * with an account balance that is less than <customer.lowBalanceFactor> times the employer contribution.
 */
export function isAccountBalanceBelowThreshold(is_finicity_linked, servicer_accounts, customer){
  return is_finicity_linked && ( servicer_accounts.account_balance < customer.low_balance_factor * customer.employer_match )
}
export function servicerAccountSortInterestRateDesc(servicerAccountsArray){
  const servicerAccounts = servicerAccountsArray.filter(function(value) {
    return value
  }).sort(sortFunctionInterestRateDesc); 
  return servicerAccounts;
}
function sortFunctionInterestRateDesc(a,b){  
  var dataA = a.attributes.interest_rate
  var dataB = b.attributes.interest_rate

  return dataA < dataB ? 1 : -1;  
};
export function servicerAccountSortInterestRateAsc(servicerAccountsArray){
  const servicerAccounts = servicerAccountsArray.filter(function(value) {
    return value
  }).sort(sortFunctionInterestRateAsc); 
  return servicerAccounts;
}
function sortFunctionInterestRateAsc(a,b){  
  var dataA = a.attributes.interest_rate
  var dataB = b.attributes.interest_rate

  return dataA > dataB ? 1 : -1;  
};
export function servicerAccountSortBalanceDesc(servicerAccountsArray){
  const servicerAccounts = servicerAccountsArray.filter(function(value) {
    return value
  }).sort(sortFunctionBalanceDesc); 
  return servicerAccounts;
}
function sortFunctionBalanceDesc(a,b){  
  var dataA = a.attributes.account_balance
  var dataB = b.attributes.account_balance

  return dataA < dataB ? 1 : -1;  
};
export function servicerAccountSortBalanceAsc(servicerAccountsArray){
  const servicerAccounts = servicerAccountsArray.filter(function(value) {
    return value
  }).sort(sortFunctionBalanceAsc); 
  return servicerAccounts;
}
function sortFunctionBalanceAsc(a,b){  
  var dataA = a.attributes.account_balance
  var dataB = b.attributes.account_balance

  return dataA > dataB ? 1 : -1;  
};
export function needsNelnetAddressValidation(servicerAccount){
  isNelnet(servicerAccount)
}
export function isNelnet(servicerAccount) {
  const servicerName = servicerAccount.servicer_name;
  return servicerName.toLowerCase() === 'nelnet';
}
export function getAtLeastOneFailedDisbursement(disbursements){
  return disbursements.some(disbursement => disbursement.attributes.status.match(/failed/));
}
export function getPausedPayments(servicerAccount){
  const pausedPayments = (servicerAccount.attributes.admin_pause_payments || servicerAccount.attributes.user_pause_payments)
  return pausedPayments;
}
export function getShowCurrentPayrollDate(disbursements){
  return disbursements.some(disbursement => statusText(disbursement.attributes.status, disbursement.attributes.settled_at)==='Sending...');
}
export function isFinicitySynced(servicerAccount){
  return servicerAccount.attributes.is_finicity_linked &&
    servicerAccount.attributes.is_finicity_synced === false;
}