import React from 'react'
import CarouselPay from './CarouselTypes.js/CarouselPay';
import CarouselMatch from './CarouselTypes.js/CarouselMatch';
import CarouselTuition from './CarouselTypes.js/CarouselTuition';
import CarouselFiveTwoNine from './CarouselTypes.js/CarouselFiveTwoNine';
import CarouselAdvisor from './CarouselTypes.js/CarouselAdvisor';
import LoginCarousel from '../LoginCarousel/LoginCarousel';

const RegistrationCarousel = ({ accountType }) => {

  switch (accountType) {
    case 'pay':
      return <CarouselPay />
    case 'match':
      return <CarouselMatch />
    case 'tuition':
      return <CarouselTuition />
    case 'fiveTwoNine':
      return <CarouselFiveTwoNine />
    case 'advisor':
      return <CarouselAdvisor />
    default:
      return <LoginCarousel />
  }
}

export default RegistrationCarousel;