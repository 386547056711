import React from 'react';
import { Carousel } from 'react-bootstrap';
import { VaultMatchSvg1, VaultMatchSvg2, VaultMatchSvg3, } from '../RegistrationCarousel.constant';
import style from '../RegistrationCarousel.module.css'

const CarouselMatch = () => {
  return (
    <div className={'G-carousel-dark ' + style.containerMain}>
      <Carousel fade>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-pink-1 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultMatchSvg1 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                The Key to Retirement Savings and Student Loan Payoff.
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Secure your financial future with Vault Match, the innovative 401(k) and student loan matching program.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-green-1 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultMatchSvg2 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                The Ultimate Solution for Retirement Savings and Student Loan Repayment
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Vault Match is your key to unlocking endless opportunities for financial growth and success.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-orange-2 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultMatchSvg3 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                The Revolutionary 401(k) and Student Loan Matching program
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Secure your future and achieve financial freedom with Vault Tuition Reimbursement, the leading benefit offering for post-COVID financial wellness.
              </label>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default CarouselMatch