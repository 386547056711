import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDisbursements } from '../../../actions/fiveTwoNineActions';
import { numberFormat, statusText } from '../../../common/js/jsFunctions';
import { formatDateUsingMoment, monthDateYearSlash } from '../../../utils/dateUtil';

const statusObject = {
  "Sending...": 'Gt-yellow-6',
  "Under Review": 'Gt-red-5',
  "Sent!": 'Gt-yellow-6',
  'Paid': 'Gt-green-6'
}

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disbursements: []
    }
  }
  componentDidMount() {
    document.title = "Vault | History";
    const { getDisbursements } = this.props;
    getDisbursements();
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.disbursements !== this.props.disbursements) {
      this.setState({
        disbursements: nextProps.disbursements
      })
    }
  }
  exportToCsv(e) {
    const { disbursements } = this.state;
    // Headers for each column
    let headers = ['Created,Servicer,Amount,Status'];

    // Convert disbursements data to a csv
    let disbursementsCsv = disbursements.reduce((disbursement, item) => {
      const created_at = moment(item.attributes.created_at).format('MMMM D YYYY');
      const servicer_name = item.attributes.servicer_name;
      const amount = item.attributes.amount;
      const status = statusText(item.attributes.status, item.attributes.settled_at);
      disbursement.push([created_at, servicer_name, amount, status].join(','))
      return disbursement;
    }, []);

    const disbursementData = [...headers, ...disbursementsCsv].join('\n');
    const fileName = 'pay_history.csv';
    const fileType = 'text/csv';
    this.downloadFile(disbursementData, fileName, fileType);
  }
  downloadFile(data, fileName, fileType) {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    const { disbursements } = this.state;
    return (
      <>
        {disbursements.length > 0 ?
          disbursements.map((row, i) => {
            this.props.checkHistoryData(false)
            let statusData = statusText(row.attributes.status).split('-')[0].trim();
            let dateVal = formatDateUsingMoment(row.attributes.created_at, monthDateYearSlash)
            return (
              <tr key={i}>
                <td>{localStorage.getItem("employerName")}</td>
                <td>{dateVal === 'Invalid date' ? '-' : dateVal}</td>
                <td>{numberFormat(row.attributes.amount)}</td>
                <td>-</td>
                <td className={statusObject[statusData]}>
                  {statusData === 'Paid' ? statusData + ' - ' + formatDateUsingMoment(row.attributes.settled_at, monthDateYearSlash) : statusData}</td>
              </tr>
            )
          })
          : this.props.checkHistoryData(true)}
      </>
    )
  }
}

History.propTypes = {
  getDisbursements: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  disbursements: state.five_two_nine.disbursements,
  errors: state.errors
});
export default connect(mapStateToProps, { getDisbursements })(History);
