import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import loan from "../../../common/img/loan.svg";
import pencil from "../../../common/img/edit.png";

import { getAdvisorServicers } from '../../../actions/advisorActions';
import { fetchCustomer } from '../../../actions/customerActions';
import { getLoans } from '../../../actions/fiveTwoNineActions';
import { convertHyphensCamelCaseLoans, numberFormat, loanTypeFriendly, loanStatusMessage } from '../../../common/js/jsFunctions';
import { sortAdvisorServicers, getServicerById } from '../dashboardCommon';
import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";

class Loans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      loanCount: 0,
      loanWord: 'loan',
      federalLoansCount: 0,
      privateLoansCount: 0,
      loanBalanceTotal: 0,
      loanMonthlyPaymentTotal: 0,
      customer: null,
      advisorServicers: []
    }
  }
  componentDidMount() {
    document.title = "Vault Advisor";
    const { getLoans, fetchCustomer, getAdvisorServicers } = this.props;
    getLoans();
    fetchCustomer();
    getAdvisorServicers();
    intercomTrackEvent(eventNames.visitedAdvisorLoans);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loans !== this.props.loans) {
      let loanData = this.props.loans;
      let loans = convertHyphensCamelCaseLoans(this.props.loans);

      const loanCount = loans.length;
      const loanWord = loanCount > 0 ? 'loans' : 'loan';

      const federalLoans = loans.filter(function (value) {
        return value.loanType !== 'Private'
      });
      const federalLoansCount = federalLoans.length;
      const privateLoansCount = loanCount - federalLoansCount;

      let loanBalanceTotal = 0;
      let loanMonthlyPaymentTotal = 0;
      for (var i = 0; i < loans.length; i++) {
        loanBalanceTotal += loans[i].balance / 100;
        loanMonthlyPaymentTotal += Math.floor(loans[i].monthlyPayment / 100);
      }
      this.setState({
        loanData: loanData,
        loans: loans,
        loanCount: loanCount,
        loanWord: loanWord,
        federalLoansCount: federalLoansCount,
        privateLoansCount: privateLoansCount,
        loanBalanceTotal: numberFormat(loanBalanceTotal),
        loanMonthlyPaymentTotal: numberFormat(loanMonthlyPaymentTotal)
      })
    }
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer
      })
    }
    if (prevProps.advisorServicers !== this.props.advisorServicers) {
      const advisorServicers = this.props.advisorServicers.sort(sortAdvisorServicers)
      this.setState({
        advisorServicers: advisorServicers
      })
    }
  }
  toDashboard = (e) => {
    //console.log(e);
    if (localStorage.getItem("hasAdvisorAndSavingsPlan") === 'true') {
      this.props.history.push({
        // pathname: '/master-dashboard'
        pathname: '/advice-and-tools/dashboard'
      })
    } else {
      if (localStorage.getItem("didChange") === 'true') {
        this.props.history.push({
          // pathname: '/genius-calc'
          pathname: '/advice-and-tools/dashboard'
        })
      } else {
        this.props.history.push({
          // pathname : '/dashboard'
          pathname: '/advice-and-tools/dashboard'
        })
      }
    }
  }
  render() {
    const { advisorServicers, loanData, loans, loanCount, loanWord, federalLoansCount, privateLoansCount, loanBalanceTotal, loanMonthlyPaymentTotal, customer } = this.state;

    // let nsldsConnected = "";
    // let nsldsFetchDate = "";
    // let nextNsldsFetchDate = "";
    if (customer) {
      // nsldsFetchDate = customer.attributes.nslds_fetch_date && moment(customer.attributes.nslds_fetch_date).format('MM/DD/YYYY')
      // nsldsConnected = customer.attributes.nslds_fetch_date && customer.attributes.fsa_credentials_valid;
      // nextNsldsFetchDate = customer.attributes.next_nslds_fetch_date ? moment(customer.attributes.next_nslds_fetch_date).format('MM/DD/YYYY') : ""
    }
    return (
      <>
        <Header {...this.props} />
        <div className="container-content  my_loans">
          <div className="container-custom ">
            <section className="box-header">
              <div className="align-center heading">My Loans</div>
            </section>
            <section className="add_loans_Sec">
              <div className="background">
                <h5>ADD A LOAN</h5>
              </div>
              {/* {nsldsConnected ?
                <img src={Check} alt="Connected to NSLDS" />
                : null} */}
              {/* {nsldsFetchDate ?
                <h3 className="text-center updated_text_last">• Last FSA update was on {nsldsFetchDate}</h3>
                : null}
              {nextNsldsFetchDate ?
                <h3 className="text-center updated_text_last">• FSA automatic loans update will be on {nextNsldsFetchDate}</h3>
                : null} */}
              <div className="loan__col flex_row">
                <div className="flex_25 text-center">
                  <img src={loan} alt="loan" />
                </div>
                <div className="flex_75 loans_manually">
                  <p>
                    <span className="extra-bold">Add your loans manually here, </span>
                    or reconnect your FSA account to update your balance and loan information automatically.
                    <Link to={{ pathname: '/advice-and-tools/nslds' }} className="ember-view"> Connect to FSA here.</Link>
                  </p>
                </div>
              </div>
              <div className="learn_more_button add_loan_button text-center mt-2">
                <Link to={{ pathname: '/advice-and-tools/loans/new' }}>+ Add A Loan</Link>
              </div>
            </section>

            <section className="loan_table_dash_fed">
              <div className="fed_loans_col">
                {
                  loans.length > 0 ?
                    <>
                      <div className="loan_table">
                        <table>
                          <thead>
                            <tr>
                              <th>Loan</th>
                              <th>Current Balance</th>
                              <th>Interest Rate *</th>
                              <th>Monthly Payment</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              loans.length > 0 ? loans.map(function (data, i) {

                                const loanId = data.loanId;
                                //const servicerName = data.servicerName;
                                const loanType = loanTypeFriendly(data.loanType);
                                const balance = numberFormat((data.balance / 100).toFixed(2));
                                const interestRate = data.interestRate;
                                const monthlyPayment = numberFormat((data.monthlyPayment / 100).toFixed(2));
                                const status = data.status;
                                const loanStatus = loanStatusMessage(data.status);


                                const loansArray = loanData.find(function (value) {
                                  return value.id === loanId;
                                });
                                const servicerId = loansArray.relationships.servicer.data.id;
                                const dataServicer = getServicerById(servicerId, advisorServicers);

                                let friendly_name = '';
                                if (dataServicer) {
                                  friendly_name = dataServicer.attributes.friendly_name
                                }


                                return (<tr key={i}>
                                  <td>
                                    <strong>{friendly_name}</strong>
                                    <span className="loan_type">{loanType}</span>
                                  </td>
                                  <td><strong>{balance}</strong></td>
                                  <td><strong>{interestRate + "%"}</strong></td>
                                  <td><strong>{monthlyPayment}</strong></td>
                                  <td>
                                    <div className="tootip_area">
                                      <Link to="#">RP</Link>
                                      <div className="tooltip_text">
                                        <span>{status} - {loanStatus}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="edit_pin">
                                    <Link to={{ pathname: '/advice-and-tools/loans/edit/' + loanId }}>  <img src={pencil} alt="pencil" />  Edit</Link>
                                  </td>
                                </tr>)
                              })
                                : null
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <strong>{loanCount} {loanWord}</strong>
                                <span>{federalLoansCount} Federal, {privateLoansCount} Private</span>
                              </td>
                              <td><strong>{loanBalanceTotal}</strong></td>
                              <td></td>
                              <td>{loanMonthlyPaymentTotal}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <p className="interst_tetx">* Your interest rates may differ if you are enrolled in auto-pay with your servicer.</p>
                    </>
                    : null
                }
              </div>
            </section>
            <div className="learn_more_button add_loan_button text-center">
              <Link to="#" onClick={this.toDashboard.bind(this)}>Back To Dashboard</Link>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
Loans.propTypes = {
  getAdvisorServicers: PropTypes.func.isRequired,
  getLoans: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  advisorServicers: state.advisor.advisorServicers,
  loans: state.five_two_nine.loans,
  customer: state.customer_array.customerData,
  errors: state.errors
});
export default connect(mapStateToProps, { getAdvisorServicers, getLoans, fetchCustomer })(Loans);