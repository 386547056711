import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { refiURL, refiURLIsPresent } from '../../../refi-vendor'
import isCitizens from '../../../is-citizens';

import { fetchEmployer } from "../../../../../actions/employerActions";
import { connect } from 'react-redux';

import { intercomTrackEvent } from "../../../../customerStatus";
import eventNames from "../../../../event-names-map";


const Paperwork = ({ refiVendors, customer, employer, fetchEmployer, trackEvent }) => {
  const clickRefinancer = (url) => {
    url = url.trim();
    if (!(/^https?:\/\//i.test(url) || /^http?:\/\//i.test(url))) {
      url = '//' + url;
    }
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null

    intercomTrackEvent(eventNames.clickedAdvisorRefiVendorInPaperwork);
    trackEvent({
      data: {
        event_key: eventNames.setRefinanceInterestRate,
        params: { 'clicked_refi_tile': true}
      }
    })
  }
  const clickedOneOnOneLink = () => {
    const newWindow = window.open('https://calendly.com/advisor-session/advisor-session', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null

    intercomTrackEvent(eventNames.clickedAdvisorScheduleOneOnOnePaperwork);
  }

  useEffect(() => {
    fetchEmployer();
  }, [])

  const allowOneOnOne = employer?.attributes?.allow_one_on_one;

  return (
    <>
      <div className='rounded-lg Gborder-05 p-4 mt-4'>
        <div>
          <div className="pinkCircularDiv mb-3">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="var(--icon-background-color)" />
              <path d="M19.752 28.5V14.844C19.448 15.468 18.992 15.972 18.384 16.356C17.776 16.724 17.048 16.908 16.2 16.908H15.912V14.316H16.032C16.688 14.316 17.256 14.236 17.736 14.076C18.216 13.9 18.608 13.66 18.912 13.356C19.216 13.052 19.44 12.7 19.584 12.3C19.744 11.9 19.832 11.46 19.848 10.98H22.368V28.5H19.752Z" fill="var(--icon-color)" />
            </svg>
          </div>
          <h4>Get Quotes from Multiple Refinancers</h4>
          <p className='sans-medium Gfs-1'>
            Vault has established business relationships with preferred lenders, selected for their capital, market availability, status, and willingness to form a referral relationship with Vault. The list of these lenders is presented alphabetically below.
          </p>
        </div>
        {
          refiVendors.length > 0 ? refiVendors.map((item, i) => {

            const imageUrl = item.attributes.image_url;
            const description = item.attributes.description

            return (<div key={i} className="rounded-lg Gborder-05 p-4 mt-4 G-cursor-pointer">
              {refiURLIsPresent(item, customer) ?
                <div onClick={(e) => clickRefinancer(refiURL(item, customer))}>
                  <div className='mb-3'>
                    <img src={imageUrl} alt="Refinance Vendor" className='responsive' />
                  </div>
                  <div>
                    <p className='mb-0 sans-medium Gfs-1'>
                      {parse(description)}
                    </p>
                  </div>
                </div>
                :
                <div>
                  <div className='mb-3'>
                    <img src={imageUrl} alt="Refinance Vendor" className='responsive' />
                  </div>
                  <div>
                    <p className='mb-0 sans-medium Gfs-1'>
                      {parse(description)}
                    </p>
                  </div>
                </div>
              }
            </div>)
          })
            : null
        }

        <div className='d-flex Gb-blue-50 mt-4 rounded-lg p-3'>
          <div className='mr-3 my-auto'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#1890FF" />
            </svg>
          </div>
          <div>
            <p className='sans-medium mb-0'>
              You are not required to use one of these refinancing lenders. Other lenders are available with similar services, and you should consider seeking other refinancing lenders and options to identify the best services and the best rate for your student loans.
            </p>
          </div>
        </div>
      </div>
      <div className='rounded-lg Gborder-05 p-4 mt-4'>
        <div>
          <div className="pinkCircularDiv mb-3">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="var(--icon-background-color)" />
              <path d="M14.104 26.004C14.104 25.284 14.24 24.628 14.512 24.036C14.784 23.444 15.144 22.9 15.592 22.404C16.04 21.892 16.544 21.42 17.104 20.988C17.664 20.556 18.224 20.148 18.784 19.764C19.264 19.428 19.728 19.1 20.176 18.78C20.624 18.444 21.016 18.108 21.352 17.772C21.704 17.436 21.984 17.092 22.192 16.74C22.4 16.388 22.504 16.012 22.504 15.612C22.504 14.86 22.248 14.244 21.736 13.764C21.224 13.268 20.496 13.02 19.552 13.02C18.736 13.02 18.048 13.252 17.488 13.716C16.928 14.18 16.472 14.788 16.12 15.54L13.864 14.388C14.376 13.284 15.112 12.404 16.072 11.748C17.048 11.076 18.208 10.74 19.552 10.74C20.416 10.74 21.192 10.868 21.88 11.124C22.584 11.38 23.176 11.732 23.656 12.18C24.136 12.612 24.504 13.132 24.76 13.74C25.032 14.332 25.168 14.964 25.168 15.636C25.168 16.324 25.032 16.948 24.76 17.508C24.504 18.052 24.16 18.564 23.728 19.044C23.296 19.508 22.808 19.94 22.264 20.34C21.72 20.74 21.176 21.132 20.632 21.516C20.136 21.852 19.648 22.196 19.168 22.548C18.704 22.884 18.288 23.236 17.92 23.604C17.568 23.956 17.28 24.332 17.056 24.732C16.848 25.116 16.744 25.54 16.744 26.004H25.192V28.5H14.104V26.004Z" fill="var(--icon-color)" />
            </svg>
          </div>
          <h4>Model the Impact of the Offered Rate(s) using the Refinance Calculator</h4>
          <p className='sans-medium Gfs-1 mb-0'>
            This will help you calculate the exact savings in interest and time. From that you'll have a strong sense of the benefit. This lets you make an educated decision if refinancing offers enough of a benefit.
          </p>
        </div>
      </div>
      <div className='rounded-lg Gborder-05 p-4 mt-4'>
        <div>
          <div className="pinkCircularDiv mb-3">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="var(--icon-background-color)" />
              <path d="M19.672 28.74C18.216 28.74 16.992 28.492 16 27.996C15.024 27.484 14.168 26.716 13.432 25.692L15.616 24.036C16.096 24.852 16.664 25.444 17.32 25.812C17.976 26.18 18.76 26.364 19.672 26.364C20.712 26.364 21.552 26.1 22.192 25.572C22.848 25.028 23.176 24.324 23.176 23.46C23.176 22.612 22.856 21.94 22.216 21.444C21.592 20.948 20.752 20.7 19.696 20.7H17.68V18.276H19.528C20.568 18.276 21.36 18.02 21.904 17.508C22.448 16.996 22.72 16.356 22.72 15.588C22.72 14.868 22.464 14.26 21.952 13.764C21.44 13.268 20.664 13.02 19.624 13.02C18.76 13.02 18.04 13.196 17.464 13.548C16.904 13.884 16.432 14.404 16.048 15.108L13.84 13.836C14.464 12.876 15.248 12.124 16.192 11.58C17.152 11.02 18.304 10.74 19.648 10.74C20.528 10.74 21.312 10.86 22 11.1C22.704 11.324 23.296 11.652 23.776 12.084C24.256 12.5 24.624 13.004 24.88 13.596C25.136 14.172 25.264 14.812 25.264 15.516C25.264 16.38 25.072 17.14 24.688 17.796C24.304 18.452 23.768 18.988 23.08 19.404C23.912 19.772 24.568 20.3 25.048 20.988C25.528 21.66 25.768 22.5 25.768 23.508C25.768 24.26 25.624 24.956 25.336 25.596C25.064 26.22 24.664 26.772 24.136 27.252C23.624 27.716 22.992 28.084 22.24 28.356C21.488 28.612 20.632 28.74 19.672 28.74Z" fill="var(--icon-color)" />
            </svg>
          </div>
          <h4>If Approved, E-Sign Your Official Letter</h4>
          <label className='sans-medium Gfs-1 mb-5'>
            After analyzing your credit report and verifying your documentation, the refinanciers will give you an official offer if you pass their requirements. It is typically valid for a few weeks so you do not have to rush into a decision. E-sign the documentation and you're set.
          </label>
          <p className='sans-medium Gfs-1'>Want to learn more about refinancing or have questions?</p>
          {
            !isCitizens(customer.attributes.partner_name) ?
              <p className='sans-medium Gfs-1'>
                For a more in-depth look, download our&nbsp;
                <Link onClick={(e) => { intercomTrackEvent(eventNames.downloadedAdvisorRefiGuideInPaperwork); }} to={{ pathname: "https://slbappfiles.s3.amazonaws.com/guides/Vault_Refinancing_Walkthrough.pdf" }} target="_blank" rel="noopener noreferrer" className="G-link text-decoration-none cancelLink sans-semibold">
                  Guide to Refinancing PDF
                </Link>&nbsp;
                {
                  allowOneOnOne && <> or&nbsp; <Link to="#" onClick={clickedOneOnOneLink} className="sans-semibold G-link text-decoration-none cancelLink"> schedule 1:1 with our team </Link> and we&apos;d be happy to answer your questions.
                  </>
                }
              </p>
              :
              <p className='sans-medium Gfs-1'>
                For a more in-depth look, download our &nbsp; <Link to={{ pathname: "https://slbappfiles.s3.amazonaws.com/guides/Vault_Refinancing_Walkthrough.pdf" }} target="_blank" rel="noopener noreferrer" className="G-link text-decoration-none cancelLink sans-semibold">Guide to Refinancing PDF</Link>.
              </p>
          }
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    employer: state.employer_array.employerData,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployer: () => dispatch(fetchEmployer()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Paperwork);
