import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Select from 'react-select';
import { Form, Button } from 'react-bootstrap';
import Table from "react-bootstrap/Table";

import Header from "../../../Includes/Header/Header";
import Footer from "../../../Includes/Footer/Footer";
import { getContributions, setSelectedContributionIdToThank, getServicerAccounts } from "../../../../actions/fiveTwoNineActions";
import { InviteFamilyAndFriends } from "../../../TRAdviceAndTools/TRAdviceAndTools.constant";
import { TableTrimData } from "./TableTrimData";
import { formatDateUsingMoment, monthDateYearSlash } from "../../../../utils/dateUtil";
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import { ViewContributionListBreadcrumb } from "../../CommonBreadcrumb/Breadcrumb.constant";
import History from "../../Pay/History";



const ThankYouButton = (props) => {
  const { contributionId, servicerAccountId, emailSent } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const setThankContributorData = () => {
    dispatch(setSelectedContributionIdToThank(contributionId));
    history.push(`/pru-pay/${servicerAccountId}/thank-contributor`);
  };

  return (
    <>
      <Button onClick={() => setThankContributorData()} className={emailSent ? "btn btn-light" : "down_btn btn-default"} disabled={!!emailSent}>
        {emailSent ? "Thank You Email Sent" : "Send a Thank You!"}
      </Button>
    </>
  );
};

const StatusTypeObject = {
  'success': { color: 'Gt-green-6', name: 'Success' },
  'in_progress': { color: 'Gt-yellow-6', name: 'In Progress' },
  'fail': { color: 'Gt-red-5', name: 'Fail' }
}

class ContributionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contributions: [],
      servicerAccounts: [],
      servicerAccountId: this.props.match.params.servicerAccountId,
      hideHistory: false,
    };
  }

  componentDidMount() {
    document.title = "Vault | Contributions List";
    const { getContributions } = this.props;
    const { servicerAccountId } = this.state;
    getContributions(servicerAccountId);
    this.props.getServicerAccounts();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.servicerAccounts !== this.props.servicerAccounts) {
      const servicer_accounts_data = this.props.servicerAccounts.data;
      const servicer_accounts_included = this.props.servicerAccounts.included;

      let servicers_array = servicer_accounts_included.filter(function (value) {
        return value.type === "servicers";
      });
      let servicers_addresses_array = servicer_accounts_included.filter(
        function (value) {
          return value.type === "addresses";
        }
      );

      for (let i = 0; i < servicer_accounts_data.length; i++) {
        const servicerId =
          servicer_accounts_data[i]["relationships"]["servicer"]["data"]["id"];
        let itemServicer = servicers_array.find(
          (item) => item.id === servicerId
        );
        servicer_accounts_data[i]["servicer"] = itemServicer;

        if (servicer_accounts_data[i]["relationships"]["address"]["data"] !== null) {
          const addressId =
            servicer_accounts_data[i]["relationships"]["address"]["data"]["id"];
          let itemAddress = servicers_addresses_array.find(
            (item) => item.id === addressId
          );
          servicer_accounts_data[i]["address"] = itemAddress;
        }
      }
      let pay_active_servicer_account = servicer_accounts_data.filter(function (value) {
        return (
          value.attributes.college_savings === true &&
          value.attributes.pay_active === true
        );
      });
      this.setState({
        ...this.state,
        servicerAccounts: servicer_accounts_data,
        payActiveServicerAccount: pay_active_servicer_account,
      });
    }
    const { contributions } = this.props;
    if (prevProps.contributions !== contributions) {
      this.setState({ ...this.state, contributions });
    }
  };
  handleAccountChange(e) {
    this.props.getContributions(e.value);
    this.setState({ ...this.state, servicerAccountId: e.value });
  }

  checkHistoryData = (bool) => {
    this.setState({ hideHistory: bool })
  }

  render() {
    const { servicerAccounts, contributions } = this.props;
    const { servicerAccountId, hideHistory } = this.state;
    const successfulContributions = contributions;
    const optionsForAccount = servicerAccounts && servicerAccounts.data && servicerAccounts.data.length > 0 ? servicerAccounts?.data.filter((i) => i.attributes.is_fivetwonine).map((x) => ({ value: x?.id, label: x?.attributes?.beneficiary_name + ', ' + x?.attributes?.account_number })) : [];
    const beneficiaryValue = () => {
      if (servicerAccounts?.data) {
        const item = servicerAccounts?.data.filter((x) => (x.id === servicerAccountId))
        if (item.length > 0) {
          return { value: item[0].id, label: item[0]?.attributes?.beneficiary_name + ', ' + item[0]?.attributes?.account_number }
        } return null;
      } return null;
    }
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5">
            <Breadcrumb list={ViewContributionListBreadcrumb} />
            <div className="p-4 bg-white Gborder-1 rounded-lg">
              {/* view contributions header */}
              <div className="">
                <div className="pinkCircularDiv mb-3">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M35 17.5C31.55 17.5 28.75 20.3 28.75 23.75C28.75 27.2 31.55 30 35 30C38.45 30 41.25 27.2 41.25 23.75C41.25 20.3 38.45 17.5 35 17.5ZM35 27.5C32.925 27.5 31.25 25.825 31.25 23.75C31.25 21.675 32.925 20 35 20C37.075 20 38.75 21.675 38.75 23.75C38.75 25.825 37.075 27.5 35 27.5ZM38.75 35H36.25C36.25 33.5 35.3125 32.15 33.9125 31.625L26.2125 28.75H16.25V42.5H23.75V40.7L32.5 43.125L42.5 40V38.75C42.5 36.675 40.825 35 38.75 35ZM21.25 40H18.75V31.25H21.25V40ZM32.4625 40.5125L23.75 38.125V31.25H25.7625L33.0375 33.9625C33.4625 34.125 33.75 34.5375 33.75 35C33.75 35 31.25 34.9375 30.875 34.8125L27.9 33.825L27.1125 36.2L30.0875 37.1875C30.725 37.4 31.3875 37.5 32.0625 37.5H38.75C39.2375 37.5 39.675 37.8 39.875 38.2125L32.4625 40.5125Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h4>View Contributions</h4>
                <p className="sans-medium">
                  View all the contributions made to your 529 plans. Select any of
                  <br /> your account from below to view them.
                </p>
              </div>

              {/* select account number */}
              <div className="Gw-50 mt-5">
                <Form.Group>
                  <Form.Label>Beneficiary and Account Number</Form.Label>
                  <Select
                    value={beneficiaryValue()}
                    className="basic-single"
                    classNamePrefix="reactSelect"
                    onChange={(e) => this.handleAccountChange(e)}
                    isSearchable={true}
                    name="BeneficiaryAccountNumber"
                    placeholder="Select or Search Beneficiary and Account Number"
                    options={optionsForAccount}
                  />
                </Form.Group>
              </div>

              {/* table for contribution details */}
              <div className="mt-5 Gtable-div">
                <Table borderless className="Gborder-05 G-table-theme-grey">
                  <thead className="Gt-head">
                    <tr>
                      <th className="Gfs-075 p-3">Deposited by</th>
                      <th className="Gfs-075 p-3">Date</th>
                      <th className="Gfs-075 p-3">Amount</th>
                      <th className="Gfs-075 p-3">Message</th>
                      <th className="Gfs-075 p-3">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {successfulContributions.length > 0
                      ? successfulContributions.map((row, i) => {
                        const { first_name, last_name, sent_at, dollar_amount, thank_you_email_sent_at: message, status } = row?.attributes || {};
                        let dateVal = formatDateUsingMoment(sent_at, monthDateYearSlash)
                        return (
                          <tr key={row.id}>
                            <td>{`${first_name} ${last_name}`}</td>
                            <td>{dateVal === 'Invalid date' ? '-' : dateVal}</td>
                            <td>{`${dollar_amount.toLocaleString("en", { style: "currency", currency: "USD" })}`}</td>
                            <td><TableTrimData message={message} /></td>
                            <td>
                              {status ?
                                <span className={StatusTypeObject[status].color}>{StatusTypeObject[status].name}</span>
                                : '-'}
                            </td>
                          </tr>
                        );
                      })
                      :
                      null
                    }
                    <History checkHistoryData={this.checkHistoryData} />
                    {successfulContributions.length === 0 && hideHistory &&
                      (
                        <tr >
                          <td colSpan={5}> There are no contributions for this account yet.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </div>

              {/* last part banner ui */}
              <div className="Gborder-05 p-4 rounded-lg Gmt-1 d-flex view-contribution-last-banner-div">
                <div className="my-auto">
                  <h2 className="sans-bold G-text-grad">Be a part of something big!</h2>
                  <p className="sans-medium">
                    Encourage your loved ones to invest in your child's future by
                    contributing to their 529 account.
                  </p>
                  <Link to="/dashboard/manage-friends-and-family">
                    <button className="bg-transparent border-0 G-orange-button" >
                      Invite your friends and family
                    </button>
                  </Link>
                </div>
                <div className="">
                  <InviteFamilyAndFriends />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

ContributionsList.propTypes = {
  getContributions: PropTypes.func.isRequired,
  getServicerAccounts: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  servicerAccounts: state.five_two_nine.getServicerAccountsData,
  contributions: state.five_two_nine.friendsAndFamilyData.contributions,
  employerData: state.employer_array.employerData,
  errors: state.errors,
});
export default connect(mapStateToProps, { getContributions, getServicerAccounts })(ContributionsList);
