import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { XLg } from 'react-bootstrap-icons';

const CustomModal = ({ modalProp, svgComponent }) => {
    const [isOpen, setIsOpen] = useState(modalProp.show);//modalProp.show
    const closeModal = () => {
        if (modalProp.onHide) {
            modalProp.onHide()
        }
        setIsOpen(false)
    }
    useEffect(() => {
        setIsOpen(modalProp.show)
    }, [modalProp])

    return (
        <Modal size="lg" show={isOpen} centered>
            <Modal.Body className='custom-modal-body'>
                <div className="d-flex">
                    <>
                        {svgComponent}
                    </>
                    <div className='w-100'>
                        <div className="d-flex">
                            <h4 className='py-3'>{modalProp.title}</h4>
                            <div className="ml-3"> <button onClick={closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                        </div>
                        {/* body */}
                        <p className="sans-medium">
                            {modalProp.bodyText}
                        </p>
                        {modalProp.footerButton ?
                            <div className='mt-2 d-flex justify-content-end'>
                                {modalProp.footerButtonHref === '' ?
                                    (<button onClick={() => modalProp.footerButtonOnclick(...modalProp.footerButtonOnclickArgs)} className="bg-transparent border-0 G-orange-button">
                                        {modalProp.footerButtonName}
                                    </button>) :
                                    (<a href={modalProp.footerButtonHref}>
                                        <button className="bg-transparent border-0 G-orange-button">
                                            {modalProp.footerButtonName}
                                        </button>
                                    </a>
                                    )
                                }
                            </div> : ''
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default CustomModal