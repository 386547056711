import React, { Component } from 'react';
import { states } from '../../common';
import { BankDetailSvg, requestFormField, requestFormStepperData, requestFormBreadcrumb } from './TuitionReimbursement.constant';
import { FormGroup } from 'react-bootstrap';
import TextInput from '../shared/TextInput/TextInput';
import ValidatorMessage from '../shared/ValidatorMessage/ValidatorMessage';
import Form from 'react-bootstrap/Form'
import Stepper from '../shared/Stepper/Stepper';
import { StepperStage } from './../shared/Stepper/Stepper';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';

class TuitionReimbursementThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    document.title = "Vault | Tuition Reimbursement Step 3"
  }
  handleAddThree = (e) => {
    this.props.onClickHandleAddThree(e)
  }
  handleBackTwo = (e) => {
    this.props.onClickHandleBackTwo(e)
  }
  render() {
    return (
      <>
        <Breadcrumb list={requestFormBreadcrumb()} />
        <Stepper options={requestFormStepperData(StepperStage.complete, StepperStage.complete, StepperStage.active)} />
        <div className='p-4 bg-white my-5 rounded-lg Gborder-1'>
          <h2 className='Gfs-225 mb-5'>Tuition Reimbursement Request Form</h2>
          <div className='G-grid11-2'>
            <div>
              <h3 className='Gfs-175 Gt-slate-5'>Bank Details</h3>
              <p className='sans-medium'>
                Your employer will use the following information to confirm the request is eligible for reimbursement. </p>
              <div className='Gb-blue-50 rounded-lg px-4 py-5'>
                <BankDetailSvg />
              </div>
            </div>
            <div>
              <FormGroup className='mb-4'>
                <TextInput
                  label='Bank Name'
                  name={requestFormField.bankName}
                  value={this.props.data.bank_name}
                  placeholder='Bank Name'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors[`${requestFormField.bankName}`]}</span>
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.bankName} fieldValue={this.props.data.bank_name} message='Bank name is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Account Number'
                  name={requestFormField.accountNumber}
                  value={this.props.data.account_number}
                  placeholder='Account Number'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <Form.Text>Must be checking account</Form.Text>
                <span className="customError">{this.props.customErrors[`${requestFormField.accountNumber}`]}</span>
                <ValidatorMessage validator={this.props.validator} validationType='required|numeric' fieldName={requestFormField.accountNumber} fieldValue={this.props.data.account_number} message='Account number is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Confirm Account Number'
                  name={requestFormField.confirmAccountNumber}
                  value={this.props.data.confirm_account_number}
                  placeholder='Confirm Account Number'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <span className="customError">{this.props.customErrors[`${requestFormField.confirmAccountNumber}`]}</span>
                {this.props.validator.message("confirm_account_number", this.props.data.confirm_account_number, ["required", "integer", { in: [this.props.data.account_number] }], {
                  messages: {
                    required: "Account number confirmation is required",
                    in: "Account numbers do not match",
                    integer: 'Account number must be digits'
                  },
                })}
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Routing Number'
                  name={requestFormField.routingNumber}
                  value={this.props.data.routing_number}
                  placeholder='Routing Number'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <Form.Text>Must be 9 Digits.</Form.Text>
                <span className="customError">{this.props.customErrors[`${requestFormField.routingNumber}`]}</span>
                {this.props.validator.message("routing_number", this.props.data.routing_number, ["required", { size: 9 }, "integer"], {
                  messages: {
                    required: "Routing number is required",
                    size: "Routing number must be 9 digits",
                    integer: 'Routing number must be digits'
                  },
                })}

              </FormGroup>

              <p className='Gfs-125'>Bank Address</p>
              <FormGroup className='my-4'>
                <TextInput
                  label='Address Line 1'
                  name={requestFormField.street1}
                  value={this.props.data.street_1}
                  placeholder='Address Line 1'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.street1} fieldValue={this.props.data.street_1} message='Address Line 1 is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  required={false}
                  label='Address Line 2'
                  name={requestFormField.street2}
                  value={this.props.data.street_2}
                  placeholder='Address Line 2'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <ValidatorMessage validator={this.props.validator} validationType='' customValidationType='includeNoSpecialChar' fieldName={requestFormField.street2} fieldValue={this.props.data.street_2} />
              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='City'
                  name={requestFormField.city}
                  value={this.props.data.city}
                  placeholder='City'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.city} fieldValue={this.props.data.city} message='City is required' />
              </FormGroup>

              <FormGroup className='my-4'>
                <Form.Label>State*</Form.Label>
                <select className="form-control address-select" name={requestFormField.state} value={this.props.data.state} onChange={(e) => this.props.handleInputChange(e)}>
                  <option value="0">Select</option>
                  {states ? states.map(item => {
                    return (
                      <option key={item.value} value={item.value} >{item.name}</option>
                    )
                  }) : ""}
                </select>
                <ValidatorMessage validator={this.props.validator} fieldName={requestFormField.state} fieldValue={this.props.data.state} message='State is required' />

              </FormGroup>

              <FormGroup className='my-4'>
                <TextInput
                  label='Zip Code'
                  name={requestFormField.zipCode}
                  value={this.props.data.zip_code}
                  placeholder='Address Line 1'
                  handleInputChange={(e) => this.props.handleInputChange(e)}
                  handleBlur={(e) => this.props.showValidationMessage(e)}
                />
                <ValidatorMessage validator={this.props.validator} customValidationType='includeNoSpecialChar' fieldName={requestFormField.zipCode} fieldValue={this.props.data.zip_code} message='Zip Code is required' />
              </FormGroup>

              <div className='d-flex justify-content-end mob-btn-div-column'>
                <button type="button" className="G-cancel-button mob-separate-v6 mx-3" onClick={this.handleBackTwo.bind(this)}>
                  Back
                </button>
                <button type="button" className="G-orange-button mob-separate-v6" onClick={this.handleAddThree.bind(this)}>
                  Continue
                </button>
              </div>


            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TuitionReimbursementThree;
