import moment from 'moment';
import camelcase from 'camelcase';
import {fetchEnrolledInMatch} from "./Advisor/servicer-account-service";

export function convertCamelCaseNotification(notifications_array){
  let notifications = [];
  notifications_array.forEach(item => {
    let notificationsData = toCamel(item.attributes);
    notifications.push(notificationsData);
  })
  return notifications; 
}
function toCamel(o) {
  var newO, origKey, newKey, value
  newO = {}
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = camelcase(origKey);
      value = o[origKey]
      newO[newKey] = value
    }
  }
  return newO
}
export function createFinicityLinkableServicerAccountNotification(notifications, customer){
  const notificationType = 'advisor-setup';
  const newArray = notifications.filter(function(note) {
    if(note){
      return note.name==='finicity-linkable'; 
    }
    return false;
  });
  
  if (newArray.length === 0 && !hasReadNotification(notificationType)) {
    let full_name = "";
    if(customer && customer.attributes){
      full_name = customer.attributes.full_name
    }
    const notificationsJson = {
      description: `${full_name}, Vault has a new feature to more accurately track your student loan! Click here to get set up`,
      read: false,
      name: 'finicity-linkable',
      createdAt:null
    }
    notifications.push(notificationsJson);
  }
  return notifications;
}
export function createNotPaidPdrNotification(notifications, employer){
 
  const newArray = notifications.filter(function(note) {
    if(note){
      return note.name==='pdr-not-paid'; 
    }
    return false;
  });
  if (employer && employer.attributes && employer.attributes.late_pdr && newArray.length === 0) {
    let msg = `Vault has not received funds for ${moment(employer.attributes.latest_pdr_due_date).format('MMM. Do, YYYY')} from`;
    msg += ` ${employer.attributes.name}. Please contact your HR team with any questions.`;
    const notificationsJson = {
      description: msg,
      read: false,
      name: 'pdr-not-paid',
      createdAt:null
    };
    notifications.push(notificationsJson);
  }
  return notifications;
}
export function createAdvisorPayMatchNotifications(notifications,customer, servicerAccounts){
  
  const advisorFiltered = notifications.filter(function(note) {
    if(note){
      return note.name==='advisor-setup'; 
    }
    return false;
  });
  const payFiltered = notifications.filter(function(note) {
    if(note){
      return note.name==='pay-setup'; 
    }
    return false;
  });
  const matchFiltered = notifications.filter(function(note) {
    if(note){
      return note.name==='match-setup'; 
    }
    return false;
  });
  if (advisorFiltered.length === 0 && customer){
    const notificationAdvisor = createAdvisorNotification(customer);
    if(notificationAdvisor){
      notifications.push(notificationAdvisor);
    }
  }
  if (payFiltered.length === 0 ){
    const notificationPay = createPayNotification(customer);
    if(notificationPay){
      notifications.push(notificationPay);
    }
  }
  if (matchFiltered.length === 0 ){
    const notificationMatch= createMatchNotification(customer, servicerAccounts);
    if(notificationMatch){
      notifications.push(notificationMatch);
    }
  }
  return notifications;
}
function createAdvisorNotification(customer) {
  const notificationType = 'advisor-setup';
  if (needsAdvisorNotification(customer) && !hasReadNotification(notificationType)) {
    const notificationsJson = {
      description: 'Explore ways to optimize your student loan repayment to reach your financial goals!',
      read: false,
      name: 'advisor-setup',
      createdAt:null
    };
    return notificationsJson;
  }
}
function needsAdvisorNotification(customer) {
  if(customer.attributes){
    const hasAdvisor = customer.attributes.has_advisor;
    const state      = customer.attributes.state;
    const married    = customer.attributes.married;
    const agiCents   = customer.attributes.agi_cents;
    
    const hasProfile = (state !== null && married !== null && agiCents !== null ) ? true : false;
    
    return customer && hasAdvisor && !hasProfile;
  }
}
function createPayNotification(customer) {
  const notificationType = 'advisor-setup';
  if (needsPayNotification(customer) && !hasReadNotification(notificationType)) {
    const matchAmount = customer.attributes.employer_match;
    const employerName = customer.attributes.employer_name;
    const notificationsJson = {
      description: `You have a $${matchAmount} student loan contribution from ${employerName} waiting for you!`,
      read: false,
      name: 'pay-setup',
      createdAt:null
    };
    return notificationsJson
  }
}
function needsPayNotification(customer) {
  if(customer.attributes){
    const hasPay  = customer.attributes.has_contribution;
    const numberOfPayActiveServicerAccounts = customer.attributes.number_of_pay_active_servicer_accounts; 

    return customer && hasPay && numberOfPayActiveServicerAccounts === 0;
  }
}
function createMatchNotification(customer, servicerAccounts) {
  const notificationType = 'advisor-setup';
  if (needsMatchNotification(customer, servicerAccounts) && !hasReadNotification(notificationType)) {
    const employerName = customer.attributes.employer_name;
    const matchOffering = `${customer.attributes.matchPercentage || 0}%`;
    const notificationsJson = {
      description: `You have a student loan matching offer from ${employerName} of up to ${matchOffering}.`,
      read: false,
      name: 'match-setup',
      createdAt:null
    };
    return notificationsJson
  }
}
function needsMatchNotification(customer,servicerAccounts){
  if(customer.attributes){
    const hasMatch = customer.attributes.has_match;
    return customer && hasMatch && servicerAccounts &&
        !fetchEnrolledInMatch(servicerAccounts);
  }
}
export function createGlobalCovid19Notification(notifications, customer){
  const covid19 = 'covid19';
  const version =3;
  if (customer) {
    const covidFiltered = notifications.filter(function(note) {
      if(note){
        return note.name===covid19 && note.version === version; 
      }
      return false;
    });
    if(covidFiltered.length===0 && !hasReadCovid19Notification(version)){
      
      const notificationsJson = {
        description: 'Updated 1/2021 - ' +
            'On January 21, 2021 the Department of Education announced another extension of the administrative forbearance period to September 30, 2021 ' +
            'During this time, payments to federally-held student loans will continue to be automatically suspended and no interest will accrue. '+
            'Vault is monitoring regulations related to student loans and will be adding more information here as it becomes available.',
        read: false,
        name: covid19,
        version: version,
        createdAt:null
      };
      notifications.push(notificationsJson);
    }
  }
}
export function setReadCovid19Notification(notification){
  localStorage.setItem(notification.name, notification.version);
}
export function hasReadCovid19Notification(version){
  const versionVal = localStorage.getItem('covid19')
  if (versionVal) {
    return parseInt(versionVal) === version;
  }
  return false;
}
export function setReadNotification(notification){
  console.log(notification.name)
  localStorage.setItem('notificationType', notification.name);
}
export function hasReadNotification(notificationType){
  const notificationVal = localStorage.getItem('notificationType');
  if (notificationVal) {
    return notificationVal === notificationType;
  }
  return false;
}
