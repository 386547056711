import React, { Component } from "react";
import { connect } from "react-redux";

import Header from "../../../Includes/Header/Header";
import Footer from "../../../Includes/Footer/Footer";

import { fetchCustomer } from "../../../../actions/customerActions";
import { fetchEmployer } from "../../../../actions/employerActions";
import {
  saveReturnTrip,
  getLoanType,
  trackEvent,
  getSatisfactionFeedback,
} from "../../../../actions/advisorActions";
import {
  getLoans,
  getEligibilityResults,
} from "../../../../actions/fiveTwoNineActions";

import {
  payoffTimeInWords,
  monthsToHumanize,
  friendlyName,
  longName,
} from "../../../../common/js/jsFunctions";

import {
  convertHyphensCamelCaseLoansEligibilityCustom,
  convertHyphensCamelCaseLoansCustom,
  convertHyphensCamelCaseCustomerCustom,
  calculatorTranslatorCustomer,
  calculatorProgramResult,
  calculatorProgramResultWithMatch,
  calculatorProgramResultExisting,
} from "../../../../common/js/jsCustomFunctions";
import { currency_format, _snakelize } from "../../eligibility-result";

import eventNames from "../../../event-names-map";
import { BoxArrowUpRight, Check, ExclamationTriangle, XCircle } from "react-bootstrap-icons";
import { Table } from "react-bootstrap";
import { LoadingHalfFilledSvg } from "../AdviceAndTools.constant";
import Breadcrumb from "../../../shared/Breadcrumb/Breadcrumb";
import { AdviceAndToolLowerPaymentsBreadcrumb } from "../../CommonBreadcrumb/Breadcrumb.constant";
import moment from "moment";
import FeedbackModal from "../../../shared/FeedbackModal/FeedbackModal";
import { FeedbackRouteName } from "../../../../helpers/translations";
import { doesNotHaveSatisfactionDataForThisType, userCanLookAtFeedbackModal } from "../../../../helpers/validations";

class LowerPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      loans: [],
      loanTypeData: [],
      matchAmount: "",
      matchEnabled: false,
      existingLoansAdvisable: false,
      inFederalForbearance: false,
      eligiblePrograms: [],
      considerMatch: false,
      payoff: 0,
      payoffFaster: false,
      interestSaved: 0,
      impactAmountDiff: 0,
      ineligibleProgramsCount: 0,
      seeIneligible: false,
      ineligiblePrograms: [],
      loans_array: [],
      activeHideToggle: "",
      loadingLoans: true,
      trackingList:[],
      trackListLength: 0,
      trackSent: false,
      // showFeedbackModal: false,
      previousRoute: null,
    };
  }
  componentDidMount() {
    document.title = "Vault Advisor | Lower Payments";
    const {
      saveReturnTrip,
      fetchCustomer,
      fetchEmployer,
      getLoans,
      getEligibilityResults,
      getLoanType,
      getSatisfactionFeedback,
    } = this.props;
    saveReturnTrip();
    fetchCustomer();
    fetchEmployer();
    getLoans();
    getEligibilityResults();
    getLoanType();
    getSatisfactionFeedback();
    this.timerID = setTimeout(() => {
      this.calculateAllLoans();
      this.setState({
        loadingLoans: false
      })
    }, 4000);
    const getLastUserRoute = localStorage.getItem('USERROUTE');
    if (!!getLastUserRoute) {
      this.setState({
        previousRoute: getLastUserRoute,
      });
      localStorage.setItem('USERROUTE',FeedbackRouteName.lowerPayments);
    } else {
      localStorage.setItem('USERROUTE',FeedbackRouteName.lowerPayments);

    }


  }
  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  componentDidUpdate(prevProps, props) {

    if (this.state.trackingList.length === (this.state.trackListLength - 1) && props.trackingList.length === this.state.trackingList.length) {
        this.setState({
          trackSent: true,
        })

        if(this.state.trackSent) {
          this.props.trackEvent({data: {
            event_key: eventNames.visitedLowerPayments,
            params: this.state.trackingList
          }})
          this.setState({
            trackSent: false,
            trackListLength: 0
          })
        }
      }
  
      if(prevProps.satisfactionData?.length) localStorage.setItem('satisfactionData', JSON.stringify(prevProps.satisfactionData));
    
  }

  toggleIneligible = (status, e) => {
    const rotate = status === true ? "rotate" : "";
    this.setState({
      seeIneligible: status,
      activeHideToggle: rotate,
    });
  };
  onRedirect = (planName) => {
    this.props.history.push({
      pathname: "/advice-and-tools/lower-payment/plan/" + planName,
    });
    const data = {
      data: {
        event_key: eventNames.selectedLowerPaymentsPlan,
        params: { plan_name: planName },
      },
    };
    this.props.trackEvent(data);
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    let loans_array = [];
    let customer_array = {};
    let eligibilityResults = {};
    let loanTypeData = [];
    let matchAmount = "";

    if (
      nextProps.loans && nextProps.loans !== prevState.loans &&
      Object.keys(nextProps.loans).length > 0
    ) {
      loans_array = convertHyphensCamelCaseLoansCustom(nextProps.loans);
    }
    if (
      nextProps.customer !== prevState.customer &&
      Object.keys(nextProps.customer).length > 0
    ) {
      matchAmount = nextProps.customer.attributes.employer_match;
      let customerData = convertHyphensCamelCaseCustomerCustom(
        nextProps.customer
      );
      delete customerData["statusUrl"];
      delete customerData["trRequested"];
      delete customerData["anyTuition"];
      delete customerData["balanceSystemType"];

      customerData.agiGrowthRate = Number(customerData.agiGrowthRate);
      customer_array = calculatorTranslatorCustomer(customerData);
    }
    if (nextProps.eligibilityResults !== prevState.eligibilityResults) {
      eligibilityResults = nextProps.eligibilityResults;
    }
    if (nextProps.loanTypeData !== prevState.loanType) {
      loanTypeData = nextProps.loanTypeData;
    }
    if (
      loans_array.length > 0 &&
      Object.keys(customer_array).length > 0 &&
      Object.keys(eligibilityResults).length > 0 &&
      loanTypeData.length > 0
    ) {
      return {
        loans_array: loans_array,
        customer_array: customer_array,
        eligibilityResults: eligibilityResults,
        loanTypeData: loanTypeData,
        matchAmount: matchAmount,
      };
    }
    return null;
  }
  calculateAllLoans() {
    const { loans_array, customer_array, eligibilityResults, loanTypeData } =
      this.state;
    if (
      loans_array.length > 0 &&
      Object.keys(customer_array).length > 0 &&
      Object.keys(eligibilityResults).length > 0 &&
      loanTypeData.length > 0
    ) {
      let matchEnabled = this.state.matchEnabled;

      let eligibility_results = eligibilityResults;
      let eligibilityResultsData = eligibilityResults.data;
      let eligibility_results_included = eligibility_results.included;

      this.setState({
        trackListLength: eligibilityResultsData.length
      })

      const employerMatch =
        customer_array.match.length > 0 ? customer_array.match[0].amount : 0;

      /* Calculate Eligible Loans */
      const eligibleProgramsArray = eligibilityResultsData.filter(function (
        value
      ) {
        return value.attributes.eligible === true;
      });

      let eligiblePrograms = [];
      let eligibleArray = [];

      for (var index = 0; index < eligibleProgramsArray.length; index++) {
        let relationshipsLoans =
          eligibleProgramsArray[index]["relationships"]["loans"]["data"];
        let elData = [];
        for (var indexL = 0; indexL < relationshipsLoans.length; indexL++) {
          let programLoanId = relationshipsLoans[indexL].id;

          eligibility_results_included.forEach((element, i) => {
            if (element.id === programLoanId) {
              element.attributes.advisable = false;
              element.attributes.interest_rate = Number(
                element.attributes.interest_rate
              );

              delete element.attributes["checksum_identifier"];
              delete element.attributes["data_source"];
              delete element.attributes["repayment_program"];
              delete element.attributes["servicer_name"];
              delete element.attributes["other_servicer_name"];
              elData[i] = element.attributes;
            }
          });
        }

        if (elData.length > 0 && eligibleProgramsArray[index]["attributes"]["name"] !== 'Repaye') {
          const name = eligibleProgramsArray[index]["attributes"]["name"];
          const loans = convertHyphensCamelCaseLoansEligibilityCustom(elData);

          const obb = {
            name: name,
            loans: loans,
          };
          eligibleArray[index] = obb;

          const result = calculatorProgramResult(
            customer_array,
            loans,
            name,
            0
          );
          const resultWithMatch = calculatorProgramResultWithMatch(
            customer_array,
            loans,
            name,
            employerMatch
          );

          if (result && resultWithMatch) {
            const considerMatch = name === "Existing" ? false : true;
            const payoffDifference =
              result.payoffTime - resultWithMatch.payoffTime;
            const payoff = monthsToHumanize(payoffDifference);
            const interestSaved =
              result.accruedInterest - resultWithMatch.accruedInterest;
            const impactAmountDiff =
              result.totalPaid - resultWithMatch.totalPaid;

            let finalData = {
              result: result,
              resultWithMatch: resultWithMatch,
              name: eligibleProgramsArray[index]["attributes"]["name"],
              planName: eligibleProgramsArray[index]["id"],
              considerMatch: considerMatch,
              payoff: payoff,
              payoffFaster: payoffDifference > 0 ? true : false,
              interest: interestSaved,
              total: impactAmountDiff,
            };
            eligiblePrograms.push(finalData);

            this.makeProgramsForTracking(eligibleProgramsArray[index], result);
          }
        }
      }

      /* Calculate Ineligible Loans */
      const ineligibleProgramsArray = eligibilityResultsData.filter(function (
        value
      ) {
        return value.attributes.eligible === false;
      });
      let ineligiblePrograms = [];
      let ineligibleArray = [];

      for (
        var indexIn = 0;
        indexIn < ineligibleProgramsArray.length;
        indexIn++
      ) {
        let relationshipsLoans =
          ineligibleProgramsArray[indexIn]["relationships"]["loans"]["data"];
        let relationshipsProgramRules =
          ineligibleProgramsArray[indexIn]["relationships"]["program_rules"][
          "data"
          ];
        let ineligibleData = [];

        for (
          var indexInL = 0;
          indexInL < relationshipsLoans.length;
          indexInL++
        ) {
          let programLoanId = relationshipsLoans[indexInL].id;

          eligibility_results_included.forEach((element, i) => {
            if (element.id === programLoanId) {
              element.attributes.advisable = false;
              element.attributes.interest_rate = Number(
                element.attributes.interest_rate
              );

              delete element.attributes["checksum_identifier"];
              delete element.attributes["data_source"];
              delete element.attributes["repayment_program"];
              delete element.attributes["servicer_name"];
              delete element.attributes["other_servicer_name"];
              ineligibleData[i] = element.attributes;
            }
          });
        }
        let programRuleData = [];
        for (
          var indexRp = 0;
          indexRp < relationshipsProgramRules.length;
          indexRp++
        ) {
          let programRuleId = relationshipsProgramRules[indexRp].id;

          for (
            let indexInc = 0;
            indexInc < eligibility_results_included.length;
            indexInc++
          ) {
            if (
              eligibility_results_included[indexInc]["id"] === programRuleId
            ) {
              programRuleData[indexRp] = eligibility_results_included[indexInc];
            }
          }
        }
        ineligibleProgramsArray[indexIn]["program_rule"] = programRuleData;

        const name = ineligibleProgramsArray[indexIn]["attributes"]["name"];
        const loans =
          convertHyphensCamelCaseLoansEligibilityCustom(ineligibleData);

        const obb = {
          name: name,
          loans: loans,
        };
        ineligibleArray[indexIn] = obb;

        const ineligibleResult = calculatorProgramResult(
          customer_array,
          loans,
          name,
          0
        );
        const ineligibleResultWithMatch = calculatorProgramResultWithMatch(
          customer_array,
          loans,
          name,
          employerMatch
        );

        let considerMatch = false;
        let payoffDifference = 0;
        let payoff = "";
        let interestSaved = 0;
        let impactAmountDiff = 0;
        if (ineligibleResult && ineligibleResultWithMatch) {
          considerMatch = name === "Existing" ? false : true;
          payoffDifference =
            ineligibleResult.payoffTime - ineligibleResultWithMatch.payoffTime;
          payoff = monthsToHumanize(payoffDifference);
          interestSaved =
            ineligibleResult.accruedInterest -
            ineligibleResultWithMatch.accruedInterest;
          impactAmountDiff =
            ineligibleResult.totalPaid - ineligibleResultWithMatch.totalPaid;
        }
        let finalData1 = {
          result: ineligibleResult,
          resultWithMatch: ineligibleResultWithMatch,
          name: ineligibleProgramsArray[indexIn]["attributes"]["name"],
          program_rule: ineligibleProgramsArray[indexIn]["program_rule"],
          considerMatch: considerMatch,
          payoff: payoff,
          payoffFaster: payoffDifference > 0 ? true : false,
          interest: interestSaved,
          total: impactAmountDiff,
        };
        if (ineligibleProgramsArray[indexIn]["attributes"]["name"] !== 'Repaye') {
          ineligiblePrograms.push(finalData1);
          this.makeProgramsForTracking(ineligibleProgramsArray[indexIn], ineligibleResult);
        }

      }
      /* Start Calculate Existing Plan Loans */
      let hasQualifyingLoan = false;
      const existingPlanData = eligibilityResultsData.find(
        (item) => item.id === "existing"
      );

      const existingPlanLoans = existingPlanData.relationships.loans.data;

      let existingPlanArray = [];
      for (var indexEL = 0; indexEL < existingPlanLoans.length; indexEL++) {
        const existingPlanLoanId = existingPlanLoans[indexEL].id;

        eligibility_results_included.forEach((element, i) => {
          if (element.id === existingPlanLoanId) {
            element.attributes.advisable = false;
            element.attributes.interest_rate = Number(
              element.attributes.interest_rate
            );

            delete element.attributes["checksum_identifier"];
            delete element.attributes["data_source"];
            delete element.attributes["repayment_program"];
            delete element.attributes["servicer_name"];
            delete element.attributes["other_servicer_name"];

            existingPlanArray[i] = element.attributes;
          }
        });
      }
      if (existingPlanArray.length > 0) {
        let existingPlanObj = {
          name: existingPlanData["attributes"]["name"],
          loan: convertHyphensCamelCaseLoansEligibilityCustom(
            existingPlanArray
          ),
        };
        if (customer_array) {
          const existingPlan = calculatorProgramResultExisting(
            customer_array,
            existingPlanObj["loan"],
            existingPlanObj["name"],
            0
          );
          const existingPlanBorrower = existingPlan.borrower.loans;
          let existingLoansAdvisable = existingPlanBorrower.every(function (e) {
            return e.advisable === true;
          });

          const employerMatchAvailable = customer_array.employerMatchAvailable;
          const hasLifetimeCap = customer_array.lifetimeCap;
          const match = customer_array.match;
          const isVesting =
            match && (match.length > 1 || (match[0] && match[0]["month"] > 1));
          matchEnabled =
            employerMatchAvailable &&
            !isVesting &&
            !hasLifetimeCap &&
            existingLoansAdvisable;

          for (let i = 0; i < existingPlanBorrower.length; i++) {
            if (
              loanTypeData.includes(existingPlanBorrower[i].type) &&
              existingPlanBorrower[i].rate === 0.0
            ) {
              hasQualifyingLoan = true;
              break;
            }
          }

          /* End Calculate Existing Plan Loans */
          
          this.setState({
            // matchAmount:matchAmount,
            matchEnabled: matchEnabled,
            inFederalForbearance: hasQualifyingLoan,
            eligiblePrograms: eligiblePrograms,
            existingLoansAdvisable: existingLoansAdvisable,
            ineligibleProgramsCount: ineligibleProgramsArray.length,
            ineligiblePrograms: ineligiblePrograms,
          });
        }
      }
    }
  }

  makeProgramsForTracking(program, result) {
    
    const data = {
      name: program && program.attributes ? program.attributes.name : '',
      eligible:program && program.attributes ? program.attributes.eligible : '',
      start_date: result ? moment(result.startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
      total_paid:result ? result.totalPaid : 0,
      max_payment:result ? result.maxPayment : 0,
      payoff_time:result ? result.payoffTime : 0,
      consolidating:program && program.attributes ? program.attributes.consolidating : '',
      payment_tiers:result ? result.paymentTiers : null,
      total_forgiven:result ? result.totalForgiven : 0,
      initial_payment:result ? result.initialPayment : 0,
      accrued_interest:result ? result.accruedInterest : 0,
      execution_timeout:result ? result.executionTimeout : 0,
      interest_forgiven:result ? result.interestForgiven : 0,
      principal_forgiven:result ? result.principalForgiven:  0,
      max_minimum_payment:result ? result.maxMinimumPayment: 0,
      capitalized_interest:result ? result.capitalizedInterest: 0,
      initial_minimum_payment:result ? result.initialMinimumPayment : 0,
    }

    this.setState({
      trackingList: [data, ...this.state.trackingList ]
    })
  }

  render() {
    const {
      matchAmount,
      matchEnabled,
      existingLoansAdvisable,
      inFederalForbearance,
      eligiblePrograms,
      ineligibleProgramsCount,
      ineligiblePrograms,
      loadingLoans
    } = this.state;

    const { employer } = this.props;
    const allowOneOnOne = employer?.attributes?.allow_one_on_one;

    return (
      <>
        <Header />

        <div className="G-page-background-color">
          <div className="G-page-main-container  my-5">
            <Breadcrumb list={AdviceAndToolLowerPaymentsBreadcrumb} />
            <div className="p-4 bg-white Gborder-1 rounded-lg">
              <div className="G-grid11-g0">
                <div className="d-flex">
                  <div className="pinkCircularDiv ">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M35 37.5L37.8625 34.6375L31.7625 28.5375L26.7625 33.5375L17.5 24.2625L19.2625 22.5L26.7625 30L31.7625 25L39.6375 32.8625L42.5 30V37.5H35Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h2 className="my-auto ml-3">Lower Payments</h2>
                </div>
              </div>
              {loadingLoans ?
                <div className="align-items-center d-flex flex-column">
                  <div>
                    <LoadingHalfFilledSvg />
                  </div>
                  <label className="Gfs-1 sans-semibold Gt-pink-8 my-3">
                    CALCULATING LOANS...
                  </label>
                </div> :
                <>{inFederalForbearance ?
                  <>
                    <div className='d-flex Gb-yellow-2 my-4 rounded-lg p-3'>
                      <div className='mr-3'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13 9H11V7H13M13 17H11V11H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#FAAD14" />
                        </svg>
                      </div>
                      <div>
                        <p className='sans-semibold mb-0'>
                          During <a className="G-link text-decoration-none cancelLink" href="https://get.vault.co/cares-act/"
                            target="_blank"
                            rel="noopener noreferrer">administrative forbearance</a>,
                          you are still able to enroll in federal repayment plans.
                          {allowOneOnOne && (<>
                            &nbsp;If you would like to get additional information,
                            please reach out to us for a
                            <a className="G-link text-decoration-none cancelLink"
                              href="https://calendly.com/advisor-session/advisor-session?back=1&month=2021-02"
                              target="_blank"
                              rel="noopener noreferrer"
                            > 1:1 counselling session</a>.</>)}
                        </p>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="d-flex mt-5">
                      <div className="greenCircularDiv p-3 mr-3 "><Check size={28} className="Gt-green-6" /></div>
                      <label className="sans-semibold my-auto Gfs-125">Eligible Plans</label>
                    </div>
                    <div className="mt-4 Gtable-div">
                      <Table borderless className="Gborder-05 G-table-theme-grey">
                        <thead className="Gt-head">
                          <tr>
                            <th className="Gfs-075 p-3">Plan Type</th>
                            <th className="Gfs-075 p-3">Monthly Payment</th>
                            <th className="Gfs-075 p-3">Time Left to Pay</th>
                            <th className="Gfs-075 p-3">Remaining interest</th>
                            <th className="Gfs-075 p-3">Projected Pay</th>
                          </tr>
                        </thead>
                        <tbody className="Gt-body">
                          {eligiblePrograms.length > 0
                            ? eligiblePrograms.map((program, i) => {
                              const programName = program.name;
                              const considerMatch = program.considerMatch;

                              return programName === "Existing" ? (
                                <tr key={i} className="Gb-white-5 border">
                                  <td>
                                    <>{friendlyName(program.name)}</>
                                    {considerMatch && matchEnabled ? (
                                      <h6 className="d-flex Gt-green-6 mt-2">
                                        {"+$" + matchAmount + " Match"}
                                      </h6>
                                    ) : null}
                                    {longName(program.name) ? (
                                      <h6 className="Gt-pink-8 Gfs-075 mt-0">
                                        {longName(program.name)}
                                      </h6>
                                    ) : null}
                                  </td>
                                  <td>
                                    {considerMatch && matchEnabled ? (
                                      <>
                                        {currency_format(
                                          program.resultWithMatch.initialPayment
                                        )}
                                        {currency_format(
                                          program.resultWithMatch.initialPayment
                                        ) !==
                                          currency_format(
                                            program.resultWithMatch.maxPayment
                                          )
                                          ? "- " +
                                          currency_format(
                                            program.resultWithMatch.maxPayment
                                          )
                                          : ""}
                                      </>
                                    ) : program.name === "Existing" ? (
                                      <>
                                        {currency_format(
                                          program.result.initialPayment
                                        )}
                                        {currency_format(
                                          program.result.initialPayment
                                        ) !==
                                          currency_format(program.result.maxPayment)
                                          ? "- " +
                                          currency_format(program.result.maxPayment)
                                          : ""}
                                        {!existingLoansAdvisable ? "*" : ""}
                                      </>
                                    ) : (
                                      <>
                                        {currency_format(
                                          program.result.initialPayment
                                        )}
                                        {currency_format(
                                          program.result.initialPayment
                                        ) !==
                                          currency_format(program.result.maxPayment)
                                          ? "- " +
                                          currency_format(program.result.maxPayment)
                                          : ""}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {considerMatch && matchEnabled ? (
                                      <>
                                        <>
                                          {payoffTimeInWords(
                                            "%ys%ms",
                                            program.resultWithMatch.payoffTime
                                          )}
                                        </>
                                        {program.payoff ? (
                                          <div
                                            className={`Gfs-075 ${program.payoffFaster
                                              ? "Gt-green-6"
                                              : "Gt-yellow-6"
                                              } `}
                                          >
                                            {program.payoff}
                                            {program.payoffFaster
                                              ? "faster!"
                                              : "slower"}
                                          </div>
                                        ) : null}
                                      </>
                                    ) : (
                                      <>
                                        {payoffTimeInWords(
                                          "%ys%ms",
                                          program.result.payoffTime
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {considerMatch && matchEnabled ? (
                                      <>
                                        <>
                                          {currency_format(
                                            program.resultWithMatch.accruedInterest
                                          )}
                                        </>
                                        <div
                                          className={`Gfs-075 ${program.interest > 0
                                            ? "Gt-green-6"
                                            : "Gt-yellow-6"
                                            } `}
                                        >
                                          {currency_format(program.interest)}
                                          {program.interest !== 0 &&
                                            program.interest > 0
                                            ? "saved!"
                                            : "more"}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {currency_format(
                                          program.result.accruedInterest
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {considerMatch && matchEnabled ? (
                                      <>
                                        <>
                                          {currency_format(
                                            program.resultWithMatch.totalPaid
                                          )}
                                        </>
                                        <div
                                          className={`Gfs-075 ${program.total > 0
                                            ? "Gt-green-6"
                                            : "Gt-yellow-6"
                                            } `}
                                        >
                                          {currency_format(program.total)}
                                          {program.total !== 0 && program.total > 0
                                            ? "impact!"
                                            : "more"}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {currency_format(program.result.totalPaid)}
                                      </>
                                    )}
                                  </td>
                                </tr>)
                                : (
                                  <tr key={i}>
                                    <td>
                                      {longName(program.name) ? (
                                        <> {longName(program.name)}</>
                                      ) : (<>{friendlyName(program.name)}</>)}
                                      <BoxArrowUpRight onClick={() =>
                                        this.onRedirect(_snakelize(program.name))
                                      } className="float-right Gt-pink-8 G-cursor-pointer" size={20} />
                                      {considerMatch && matchEnabled ? (
                                        <h6 className="d-flex Gt-green-6 mt-2">
                                          {"+$" + matchAmount + " Match"}
                                        </h6>
                                      ) : null}
                                    </td>
                                    <td>
                                      {considerMatch && matchEnabled ? (
                                        <>
                                          {currency_format(
                                            program.resultWithMatch.initialPayment
                                          )}
                                          {program.resultWithMatch.initialPayment !==
                                            program.resultWithMatch.maxPayment
                                            ? "- " +
                                            currency_format(
                                              program.resultWithMatch.maxPayment
                                            )
                                            : ""}
                                        </>
                                      ) : program.name === "Existing" ? (
                                        <>
                                          {currency_format(
                                            program.result.initialPayment
                                          )}
                                          {program.result.initialPayment !==
                                            program.result.maxPayment
                                            ? "- " +
                                            currency_format(program.result.maxPayment)
                                            : ""}
                                          {!existingLoansAdvisable ? "*" : ""}
                                        </>
                                      ) : (
                                        <>
                                          {currency_format(
                                            program.result.initialPayment
                                          )}
                                          {program.result.initialPayment !==
                                            program.result.maxPayment
                                            ? "- " +
                                            currency_format(program.result.maxPayment)
                                            : ""}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {considerMatch && matchEnabled ? (
                                        <>
                                          <>
                                            {payoffTimeInWords(
                                              "%ys%ms",
                                              program.resultWithMatch.payoffTime
                                            )}
                                          </>
                                          {program.payoff ? (
                                            <div
                                              className={`Gfs-075 ${program.payoffFaster
                                                ? "Gt-green-6"
                                                : "Gt-yellow-6"
                                                } `}
                                            >
                                              {program.payoff}
                                              {program.payoffFaster
                                                ? "faster!"
                                                : "slower"}
                                            </div>
                                          ) : null}
                                        </>
                                      ) : (
                                        <>
                                          {payoffTimeInWords(
                                            "%ys%ms",
                                            program.result.payoffTime
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {considerMatch && matchEnabled ? (
                                        <>
                                          <>
                                            {currency_format(
                                              program.resultWithMatch.accruedInterest
                                            )}
                                          </>
                                          <div
                                            className={`Gfs-075 ${program.interest > 0
                                              ? "Gt-green-6"
                                              : "Gt-yellow-6"
                                              } `}
                                          >
                                            {currency_format(program.interest)}
                                            {program.interest !== 0 &&
                                              program.interest > 0
                                              ? "saved!"
                                              : "more"}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {currency_format(
                                            program.result.accruedInterest
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {considerMatch && matchEnabled ? (
                                        <>
                                          <>
                                            {currency_format(
                                              program.resultWithMatch.totalPaid
                                            )}
                                          </>
                                          <div
                                            className={`Gfs-075 ${program.total > 0
                                              ? "Gt-green-6"
                                              : "Gt-yellow-6"
                                              } `}
                                          >
                                            {currency_format(program.total)}{" "}
                                            {program.total !== 0 && program.total > 0
                                              ? "impact!"
                                              : "more"}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {currency_format(program.result.totalPaid)}
                                        </>
                                      )}
                                    </td>
                                  </tr>)
                            }) : (<tr><td colSpan={4}>You currently do not have any plans.</td></tr>)}
                        </tbody>
                      </Table>
                    </div>

                    <div className="d-flex mt-5 mb-4">
                      <div className="redCircularDiv p-3 mr-3 "><XCircle size={28} className="Gt-red-5" /></div>
                      <label className="sans-semibold my-auto Gfs-125">Ineligible Plans</label>
                    </div>
                    <div className='G-grid11-2 '>


                      {ineligibleProgramsCount > 0
                        && ineligiblePrograms.length > 0
                        ? ineligiblePrograms.map(function (program, i) {
                          const programName = program.name;
                          const programRule = program.program_rule;

                          return (
                            <div key={i} className='Gborder-05 p-4 rounded-lg bg-white mt-2'>
                              <label className="G-badge-purple">Plan Type</label>
                              <div>
                                <label className="Gfs-125 sans-semibold">
                                  {longName(programName) ? longName(programName) : friendlyName(programName)}
                                </label>
                              </div>
                              <div>
                                <ul>
                                  {programRule.length > 0
                                    ? programRule.map(function (rule, j) {
                                      return (
                                        <li key={rule.id} className='d-flex mb-2'>
                                          <div className="mb-1">
                                            {rule.attributes.eligible ? (
                                              <Check className="Gt-green-6 " size={20} />
                                            ) : (
                                              <ExclamationTriangle className="Gt-yellow-6 my-auto" size={18} />
                                            )}
                                          </div>
                                          <label className="ml-2 my-auto">
                                            {rule.attributes.name}
                                          </label>
                                        </li>
                                      );
                                    })
                                    : null}
                                </ul>
                              </div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                    {!(ineligibleProgramsCount > 0
                      && ineligiblePrograms.length > 0) &&
                      <div className="Gborder-05 p-4 rounded-lg text-center Gt-slate-1 my-3">
                        You currently do not have any ineligible plans.
                      </div>}

                  </>
                }
                </>}
              <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                <div className='mr-3'>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                  </svg>
                </div>
                <div>
                  <p className='sans-semibold mb-0'>
                    Vault has no affiliation with the Federal Government and cannot assure your eligibility for any federal student loan repayment option. Eligibility is determined solely by the Federal Government, which may consider additional factors beyond what Vault evaluates.
                  </p>
                </div>
              </div>

            </div>


          </div>
        </div>

        <Footer />
        {this.state.previousRoute && this.state.previousRoute !== FeedbackRouteName.lowerPayments && doesNotHaveSatisfactionDataForThisType(this.state.previousRoute) && userCanLookAtFeedbackModal() && (
          <FeedbackModal showFeedbackModal={this.state.previousRoute} contentType={this.state.previousRoute} />
        )}
      </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnTrip: state.advisor.returnTrip,
    customer: state.customer_array.customerData,
    employer: state.employer_array.employerData,
    loans: state.five_two_nine.loans,
    eligibilityResults: state.five_two_nine.eligibilityResults,
    loanTypeData: state.advisor.loanTypeData,
    trackEventResponse: state.advisor.trackEventResponse,
    errors: state.errors,
    satisfactionData: state.advisor.satisfactionData,
  };
};
const mapDispatchToProps = {
  saveReturnTrip,
  fetchCustomer,
  fetchEmployer,
  getLoans,
  getEligibilityResults,
  getLoanType,
  trackEvent,
  getSatisfactionFeedback
};
export default connect(mapStateToProps, mapDispatchToProps)(LowerPayment);
