import { Component } from 'react'
import { connect } from 'react-redux';
import { fetchPartnerDetails } from '../actions/authenticationActions';

class PartnerThemeData extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    const subDomain = window.location.host.split('.')[0];
    // to remove previous logo from storage, in case no partner is attached to the sub-domain
    const hasLogo = localStorage.getItem('partnerLogo');
    if(hasLogo){
      localStorage.removeItem('partnerLogo');
    }

    if (this.blackList(subDomain)) {
      return null
    }
    this.props.fetchPartnerDetails({ 'subdomain_name': subDomain })
  }

  componentDidUpdate(prevProps) {
    if (this.props.partnerData !== prevProps.partnerData && this.props.partnerData.data) {

      if(!!this.props.partnerData?.data) {

      const partnerData = this.props.partnerData?.data?.attributes;
      document.body.style.setProperty('--primary-color', partnerData.primary_color);
      document.body.style.setProperty('--secondary-color', partnerData.secondary_color);
      document.body.style.setProperty('--navbar-color', partnerData.navbar_color);
      document.body.style.setProperty('--navbar-secondary-color', partnerData.navbar_color);
      document.body.style.setProperty('--background-color', partnerData.background_color);
      document.body.style.setProperty('--link-color', partnerData.link_color);
      document.body.style.setProperty('--pink-800', partnerData.primary_color);
      document.body.style.setProperty('--orange-500', partnerData.secondary_color);
      document.body.style.setProperty('--tertiary-color', partnerData.tertiary_color);
      document.body.style.setProperty('--pink-200', partnerData.background_color);
      document.body.style.setProperty('--icon-color', partnerData.icon_color);
      document.body.style.setProperty('--icon-background-color',partnerData.background_color);
      document.body.style.setProperty('--icon-secondary-color', partnerData.secondary_color);
      if (partnerData.logo) localStorage.setItem('partnerLogo', partnerData.logo);
      } 
    }
  }

  blackList(subDomain) {
    return subDomain.includes("nyl")
  }

  render() {
    return null
  }
}

const mapStateToProps = state => ({
  partnerData: state.authentication.partnerData,
});

export default connect(mapStateToProps, { fetchPartnerDetails })(PartnerThemeData);