import { includeNoSpecialChar, includeNoSpecialCharMessage } from "../../../helpers/validations";

const ValidatorMessage = ({ validator, fieldName, fieldValue, message, customMessage = {}, validationType = 'required', customValidationType }) => {
  let customValidation = customValidationType === 'includeNoSpecialChar' ? includeNoSpecialChar : "";

  return (
    <>
      {validator.message(
        fieldName,
        fieldValue,
        validationType + customValidation,
        {
          messages: {
            required: message,
            not_regex: includeNoSpecialCharMessage,
            ...customMessage
          }
        }
      )}
    </>
  );
}

export default ValidatorMessage;