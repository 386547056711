export const clearLocalStorageItems = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('notificationType');
  localStorage.removeItem('intercom');
  localStorage.removeItem('saml_logout_page');
  localStorage.removeItem('advisorFirst');
  localStorage.removeItem('customerId');
  localStorage.removeItem('customerFullName');
  localStorage.removeItem('employerName');
  localStorage.removeItem('hasAdvisor');
  localStorage.removeItem('hasSavingsPlan');
  localStorage.removeItem('hasTuitionPlan');
  localStorage.removeItem('hasAdvisorOnly');
  localStorage.removeItem('hasSavingsPlanOnly');
  localStorage.removeItem('hasTuitionPlanOnly');
  localStorage.removeItem('hasAdvisorAndSavingsPlan');
  localStorage.removeItem('selectedService');
  localStorage.removeItem('advisorEnabled');
  localStorage.removeItem('savingsPlanEnabled');
  localStorage.removeItem('pruSavingsPlanEnabled');
  localStorage.removeItem('hasAdvisorAndPayOrMatch');
  localStorage.removeItem('hasPay');
  localStorage.removeItem('hasMatch');
  localStorage.removeItem('showProfile');
  localStorage.removeItem('partnerName');
  localStorage.removeItem('canInvite');
  localStorage.removeItem('newAdvisorEnabledPruAccount');
  localStorage.removeItem('hasProfile');
  localStorage.removeItem('showFiveToNineOrAdvisorSwitcher');
  localStorage.removeItem('didChange');
  localStorage.removeItem('impersonationEnabled');
  localStorage.removeItem('notificationPopupShown');
  localStorage.removeItem('currentCustomer');
  localStorage.removeItem('intercom.intercom-state');
  localStorage.removeItem('pruMatch');
  localStorage.removeItem('pruPay');
  localStorage.removeItem('samlKeepaliveUrl');
  localStorage.removeItem('advisorTimeout');
  localStorage.removeItem('partnerLogo');
  localStorage.removeItem('USERROUTE');
  localStorage.removeItem('satisfactionData');
  localStorage.removeItem('feedbackClosed');
}

