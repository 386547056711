export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const TRACK_EVENT = "TRACK_EVENT";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const VALIDATE_ACCOUNT_NUMBER = "VALIDATE_ACCOUNT_NUMBER";
export const GET_INDIVIDUAL_SERVICER_ACCOUNT = "GET_INDIVIDUAL_SERVICER_ACCOUNT";
export const UPLOAD_STATEMENT = "UPLOAD_STATEMENT";
export const GET_UPLOADED_STATEMENT = "GET_UPLOADED_STATEMENT";
export const FINICITY_CONNECT_SUCCESS = 'FINICITY_CONNECT_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const AUTHENTICATION_PASSWORD = 'AUTHENTICATION_PASSWORD';
export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_MATCH_TRANSACTIONS = 'GET_MATCH_TRANSACTIONS';
export const GET_TEST_LOANS = 'GET_TEST_LOANS';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const GET_REFI_VENDORS = 'GET_REFI_VENDORS';
export const SAVE_RETURN_TRIP = 'SAVE_RETURN_TRIP';
export const ADD_MISSING_SERVICER = 'ADD_MISSING_SERVICER';
export const ADD_LOAN = 'ADD_LOAN';
export const DELETE_LOAN = 'DELETE_LOAN';
export const RESET_DELETED_LOAN_STATE = 'RESET_DELETED_LOAN_STATE';
export const UPDATE_LOAN = 'UPDATE_LOAN';
export const GET_LOAN_TYPE = 'GET_LOAN_TYPE';
export const GET_LOAN_BY_ID = 'GET_LOAN_BY_ID';
export const GET_ADVISOR_SERVICERS = 'GET_ADVISOR_SERVICERS';
export const UPLOAD_NSLDS = 'UPLOAD_NSLDS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const ACCEPT_ADVISOR_SERVICE_AGREEMENT = 'ACCEPT_ADVISOR_SERVICE_AGREEMENT';
export const FETCH_ADVISOR_SERVICE_AGREEMENT = 'FETCH_ADVISOR_SERVICE_AGREEMENT';
export const REGISTRATE_USER = 'REGISTRATE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const FETCH_INVITATION_DATA = 'FETCH_INVITATION_DATA';
export const GET_INDIVIDUAL_CONTRIBUTION = "GET_INDIVIDUAL_CONTRIBUTION";
export const SUBMIT_CONTRIBUTION = "SUBMIT_CONTRIBUTION";
export const GET_BENEFICIARY_PROFILES = "GET_BENEFICIARY_PROFILES";
export const ADD_FNF_CONTRIBUTION = "ADD_FNF_CONTRIBUTION";
export const DELETE_INVITE = "DELETE_INVITE";
export const SEND_INVITE = "SEND_INVITE";
export const GET_INVITATIONS = "GET_INVITATIONS";
export const GET_INVITEQUOTA = "GET_INVITEQUOTA";
export const UPDATE_STATUS_URL = "UPDATE_STATUS_URL";
export const UPDATE_SELECTED_SERVICE = "UPDATE_SELECTED_SERVICE";
export const GET_ELIGIBILITYRESULTS = "GET_ELIGIBILITYRESULTS";
export const GET_LOANS = "GET_LOANS";
export const GET_DISBURSEMENTS = "GET_DISBURSEMENTS";
export const POST_SERVICER = "POST_SERVICER";
export const POST_SERVICER_ACCOUNTS = "POST_SERVICER_ACCOUNTS";
export const GET_ADDRESSES = "GET_ADDRESSES";
export const GET_SERVICERS = "GET_SERVICERS";
export const GET_SERVICER_ACCOUNTS = "GET_SERVICER_ACCOUNTS";
export const ACCEPT_FIVETWONINE_TERMS = "ACCEPT_FIVETWONINE_TERMS";
export const ACCEPT_GENERAL_TERMS = "ACCEPT_GENERAL_TERMS";
export const FETCH_FIVETWONINE_TERMS = "FETCH_FIVETWONINE_TERMS";
export const FETCH_GENERAL_TERMS = "FETCH_GENERAL_TERMS";
export const FETCH_EDUCATION_EXPENSES = "FETCH_EDUCATION_EXPENSES";
export const FETCH_TUITION_BY_ID = "FETCH_TUITION_BY_ID";
export const FETCH_TUITIONS = "FETCH_TUITIONS";
export const FETCH_INCOME_RANGE = "FETCH_INCOME_RANGE";
export const FETCH_AGE_RANGE = "FETCH_AGE_RANGE";
export const SAVE_TR_DETAILS = "SAVE_TR_DETAILS";
export const FETCH_EMPLOYER = "FETCH_EMPLOYER";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_TERMSCONDITIONS = "FETCH_TERMSCONDITIONS";
export const ACCEPT_TERMSCONDITIONS = "ACCEPT_TERMSCONDITIONS";
export const GET_ERRORS = "GET_ERRORS";
export const POST_BENEFICIARY_PROFILE = "POST_BENEFICIARY_PROFILE";
export const EDIT_BENEFICIARY_PROFILE = "EDIT_BENEFICIARY_PROFILE";
export const GET_BENEFICIARY_PROFILE = "GET_BENEFICIARY_PROFILE";
export const SEND_INVITE_TO_FNF_CONTRIBUTOR = "SEND_INVITE_TO_FNF_CONTRIBUTOR";
export const GET_CONTRIBUTIONS = "GET_CONTRIBUTIONS";
export const SET_SELECTED_CONTRIBUTION_ID_TO_THANK =
  "SET_SELECTED_CONTRIBUTION_ID_TO_THANK";
export const POST_THANK_YOU_TO_CONTRIBUTOR = "POST_THANK_YOU_TO_CONTRIBUTOR";
export const GET_BENEFICIARY_PROFILE_AVATARS =
  "GET_BENEFICIARY_PROFILE_AVATARS";
export const FETCH_SERVICER_ACCOUNT = "FETCH_SERVICER_ACCOUNT";
export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const CHECK_FORGIVENESS_ELIGIBILITY = "CHECK_FORGIVENESS_ELIGIBILITY";
export const GET_PELL_GRANTS = "GET_PELL_GRANTS";
export const POST_PELL_GRANTS = "POST_PELL_GRANTS";
export const UPDATE_PELL_GRANTS = "UPDATE_PELL_GRANTS";
export const DELETE_PELL_GRANTS = "DELETE_PELL_GRANTS";
export const FETCH_FINICITY_CONNECT_LINK = "FETCH_FINICITY_CONNECT_LINK";
export const RESET_LOANS_REDUCER = "RESET_LOANS_REDUCER";
export const FETCH_IMPERSONATION = "FETCH_IMPERSONATION";
export const POST_IMPERSONATION = "POST_IMPERSONATION";
export const POST_TRACKABLE_EVENTS = "POST_TRACKABLE_EVENTS";
export const GET_COMPANY_LANDING_DATA = "GET_COMPANY_LANDING_DATA";
export const POST_COMPANY_LANDING_DATA = "POST_COMPANY_LANDING_DATA";
export const INVALIDATE_SESSION = "INVALIDATE_SESSION";
export const FETCH_EMPLOYER_METADATA = "FETCH_EMPLOYER_METADATA";
export const FETCH_PARTNER_DETAILS = "FETCH_PARTNER_DETAILS";
export const CONFIRM_SAML_EMAIL = "CONFIRM_SAML_EMAIL";
export const RESEND_SAML_EMAIL = "RESEND_SAML_EMAIL";
export const POST_FIVETWONINE_ADDRESS = 'POST_FIVETWONINE_ADDRESS';
export const FETCH_ESIGNDISCLOSURE_TERMS = 'FETCH_ESIGNDISCLOSURE_TERMS';
export const ACCEPT_ESIGNDISCLOSURE_TERMS = 'ACCEPT_ESIGNDISCLOSURE_TERMS';
export const GET_HISTORICAL_MATCH_TRXES = 'GET_HISTORICAL_MATCH_TRXES';
export const POST_SATISFACTION_FEEDBACK = 'POST_SATISFACTION_FEEDBACK';
export const GET_SATISFACTION_FEEDBACK = 'GET_SATISFACTION_FEEDBACK';