import React from 'react';
import { Carousel } from 'react-bootstrap';
import { VaultPaySvg1, VaultPaySvg2, VaultPaySvg3, } from '../RegistrationCarousel.constant';
import style from '../RegistrationCarousel.module.css'

const CarouselPay = () => {
  return (
    <div className={'G-carousel-dark ' + style.containerMain}>
      <Carousel fade>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-blue-100 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultPaySvg1 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Unlock Your Student Loan Payoff Potential
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Take control of your student loan debt with Vault Pay and pay off your loans faster than ever before.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-orange-2 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultPaySvg2 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                The Smart Student Loan Contribution Solution
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Get the support you need to pay off your student loans with Vault Pay, and take charge of your financial future with ease.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-pink-1 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultPaySvg3 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Say Goodbye to Student Loan Stress
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Eliminate the burden of student loan debt with Vault Pay and achieve financial freedom sooner than you ever thought possible.
              </label>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default CarouselPay