import {
  TRACK_EVENT,
  VALIDATE_ACCOUNT_NUMBER,
  GET_INDIVIDUAL_SERVICER_ACCOUNT,
  UPLOAD_STATEMENT,
  FINICITY_CONNECT_SUCCESS,
  GET_TRANSACTIONS,
  GET_MATCH_TRANSACTIONS,
  SEND_FEEDBACK,
  GET_REFI_VENDORS,
  SAVE_RETURN_TRIP,
  ADD_MISSING_SERVICER,
  ADD_LOAN,
  DELETE_LOAN,
  RESET_DELETED_LOAN_STATE,
  UPDATE_LOAN,
  GET_LOAN_TYPE,
  GET_LOAN_BY_ID,
  GET_ADVISOR_SERVICERS,
  UPLOAD_NSLDS,
  ACCEPT_ADVISOR_SERVICE_AGREEMENT,
  FETCH_ADVISOR_SERVICE_AGREEMENT,
  GET_ERRORS,
  CHECK_FORGIVENESS_ELIGIBILITY,
  GET_PELL_GRANTS,
  POST_PELL_GRANTS,
  UPDATE_PELL_GRANTS,
  DELETE_PELL_GRANTS,
  FETCH_FINICITY_CONNECT_LINK,
  GET_HISTORICAL_MATCH_TRXES,
  GET_UPLOADED_STATEMENT, 
  POST_SATISFACTION_FEEDBACK,
  GET_SATISFACTION_FEEDBACK
} from './types';

import { intercomTrackEvent } from "../Components/customerStatus";
import eventNames from "../Components/event-names-map";

import { apiAction } from './apiActions';
import config from '../config';


export const fetchAdvisorServiceAgreement = () => {
  return apiAction({
    url: config.apiBaseUrl + "legal_agreements/latest_advisor_service_agreement",
    onSuccess: setFetchAdvisorServiceAgreement,
    callback: '',
    onFailure: errorFetchAdvisorServiceAgreement,
    label: FETCH_ADVISOR_SERVICE_AGREEMENT
  });
}

function setFetchAdvisorServiceAgreement(data) {
  return {
    type: FETCH_ADVISOR_SERVICE_AGREEMENT,
    payload: data.data
  };
}
function errorFetchAdvisorServiceAgreement(errors) {
  if (errors.response && errors.response.data) {
    return {
      type: GET_ERRORS,
      payload: errors.response.data
    };
  }
}
export const acceptAdvisorServiceAgreement = (userAgreements) => {
  return apiAction({
    url: config.apiBaseUrl + "user_agreements",
    method: "POST",
    data: userAgreements,
    onSuccess: setAcceptAdvisorServiceAgreement,
    callback: '',
    onFailure: errorAcceptAdvisorServiceAgreement,
    label: ACCEPT_ADVISOR_SERVICE_AGREEMENT
  });
}
function setAcceptAdvisorServiceAgreement(data) {
  return {
    type: ACCEPT_ADVISOR_SERVICE_AGREEMENT,
    payload: data.data
  };
}
function errorAcceptAdvisorServiceAgreement(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}

export const satisfactionFeedback = (feedbackRequest) => {
  return apiAction({
    url: config.apiBaseUrl + "satisfaction_scores",
    method: "POST",
    data: feedbackRequest,
    onSuccess: setSatisfactionFeedback,
    callback: '',
    onFailure: errorSatisfactionFeedback,
    label: POST_SATISFACTION_FEEDBACK,
  });
}
function setSatisfactionFeedback(data) {
  return {
    type: POST_SATISFACTION_FEEDBACK,
    payload: data.data
  };
}
function errorSatisfactionFeedback(errors) {
  // intercomTrackEvent(eventNames.failedAdvisorNslds2fa);
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}

export const getSatisfactionFeedback = (feedbackRequest) => {
  return apiAction({
    url: config.apiBaseUrl + "satisfaction_scores",
    method: "GET",
    // data: feedbackRequest,
    onSuccess: getUserSatisfactionFeedback,
    callback: '',
    onFailure: errorGetSatisfactionFeedback,
    label: GET_SATISFACTION_FEEDBACK,
  });
}
function getUserSatisfactionFeedback(data) {
  return {
    type: GET_SATISFACTION_FEEDBACK,
    payload: data.data
  };
}
function errorGetSatisfactionFeedback(errors) {
  // intercomTrackEvent(eventNames.failedAdvisorNslds2fa);
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}

export const uploadNslds = (nsldsFile) => {
  return apiAction({
    url: config.apiBaseUrl + "nslds/upload",
    method: "POST",
    data: nsldsFile,
    onSuccess: setUploadNslds,
    callback: '',
    onFailure: errorUploadNslds,
    label: UPLOAD_NSLDS
  });
}
function setUploadNslds(data) {
  return {
    type: UPLOAD_NSLDS,
    payload: data.data
  };
}
function errorUploadNslds(errors) {
  intercomTrackEvent(eventNames.failedAdvisorNsldsFileUpload);
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getAdvisorServicers = () => {
  return apiAction({
    url: config.apiBaseUrl + "servicers",
    onSuccess: setGetAdvisorServicers,
    callback: '',
    onFailure: errorGetAdvisorServicers,
    label: GET_ADVISOR_SERVICERS
  });
}
function setGetAdvisorServicers(response) {
  return {
    type: GET_ADVISOR_SERVICERS,
    payload: response.data
  };
}
function errorGetAdvisorServicers(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getLoanById = (loanId) => {
  return apiAction({
    url: config.apiBaseUrl + "loans/" + loanId,
    onSuccess: setGetLoanById,
    callback: '',
    onFailure: errorGetLoanById,
    label: GET_LOAN_BY_ID
  });
}
function setGetLoanById(response) {
  return {
    type: GET_LOAN_BY_ID,
    payload: response.data
  };
}
function errorGetLoanById(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const addMissingServicer = (missingServicer) => {
  return apiAction({
    url: config.apiBaseUrl + "emails/missing_servicer",
    method: "POST",
    data: missingServicer,
    onSuccess: setAddMissingServicer,
    callback: '',
    onFailure: errorAddMissingServicer,
    label: ADD_MISSING_SERVICER
  });
}
function setAddMissingServicer(response) {
  return {
    type: ADD_MISSING_SERVICER,
    payload: response.data
  };
}
function errorAddMissingServicer(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const addLoan = (loanData) => {
  return apiAction({
    url: config.apiBaseUrl + "loans",
    method: "POST",
    data: loanData,
    onSuccess: setAddLoan,
    callback: '',
    onFailure: errorAddLoan,
    label: ADD_LOAN
  });
}
function setAddLoan(response) {
  return {
    type: ADD_LOAN,
    payload: response.data
  };
}
function errorAddLoan(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const updateLoan = (loanId, loanData) => {
  return apiAction({
    url: config.apiBaseUrl + "loans/" + loanId,
    method: "PATCH",
    data: loanData,
    onSuccess: setUpdateLoan,
    callback: '',
    onFailure: errorUpdateLoan,
    label: UPDATE_LOAN
  });
}
function setUpdateLoan(response) {
  return {
    type: UPDATE_LOAN,
    payload: response.data
  };
}
function errorUpdateLoan(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const deleteLoan = (loanId) => {
  return apiAction({
    url: config.apiBaseUrl + "loans/" + loanId,
    method: "DELETE",
    onSuccess: setDeleteLoan,
    callback: '',
    onFailure: errorDeleteLoan,
    label: DELETE_LOAN
  });
}
function setDeleteLoan(response) {
  return {
    type: DELETE_LOAN,
    payload: response.data
  };
}
function errorDeleteLoan(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const resetDeletedLoanState = () => {
  return {
    type: RESET_DELETED_LOAN_STATE
  };
}

export const getLoanType = () => {
  return apiAction({
    url: config.apiBaseUrl + "loan_type_objects",
    onSuccess: setGetLoanType,
    callback: '',
    onFailure: errorGetLoanType,
    label: GET_LOAN_TYPE
  });
}
function setGetLoanType(response) {
  return {
    type: GET_LOAN_TYPE,
    payload: response.data
  };
}
function errorGetLoanType(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const saveReturnTrip = () => {
  return apiAction({
    url: config.apiBaseUrl + "emails/return_trip",
    method: "POST",
    data: "",
    onSuccess: setGetSaveReturnTrip,
    callback: '',
    onFailure: errorGetSaveReturnTrip,
    label: SAVE_RETURN_TRIP
  });
}
function setGetSaveReturnTrip(response) {
  return {
    type: SAVE_RETURN_TRIP,
    payload: response.data
  };
}
function errorGetSaveReturnTrip(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getRefiVendors = () => {
  return apiAction({
    url: config.apiBaseUrl + "refi_vendors",
    onSuccess: setGetRefiVendors,
    callback: '',
    onFailure: errorGetRefiVendors,
    label: GET_REFI_VENDORS
  });
}
function setGetRefiVendors(response) {
  return {
    type: GET_REFI_VENDORS,
    payload: response.data
  };
}
function errorGetRefiVendors(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const sendEmailFeedback = (feedback) => {
  return apiAction({
    url: config.apiBaseUrl + "emails/feedback",
    method: "POST",
    data: feedback,
    onSuccess: setGetSendEmailFeedback,
    callback: '',
    onFailure: errorGetSendEmailFeedback,
    label: SEND_FEEDBACK
  });
}
function setGetSendEmailFeedback(response) {
  return {
    type: SEND_FEEDBACK,
    payload: response
  };
}
function errorGetSendEmailFeedback(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getTransaction = () => {
  return apiAction({
    url: config.apiBaseUrl + "transactions",
    onSuccess: setGetTransaction,
    callback: '',
    onFailure: errorGetTransaction,
    label: GET_TRANSACTIONS
  });
}
export const getMatchTransaction = () => {
  return apiAction({
    url: config.apiBaseUrl + "match_transactions",
    onSuccess: setGetMatchTransaction,
    callback: '',
    onFailure: errorGetMatchTransaction,
    label: GET_MATCH_TRANSACTIONS
  });
}
function setGetMatchTransaction(response) {
  return {
    type: GET_MATCH_TRANSACTIONS,
    payload: response.data
  };
}
function errorGetMatchTransaction(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
function setGetTransaction(response) {
  return {
    type: GET_TRANSACTIONS,
    payload: response.data
  };
}
function errorGetTransaction(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const finicityConnectSuccess = (request) => {
  return apiAction({
    url: config.apiBaseUrl + "customers/finicity_connect_success",
    method: "POST",
    data: request,
    onSuccess: setFinicityConnectSuccess,
    callback: '',
    onFailure: errorFinicityConnectSuccess,
    label: FINICITY_CONNECT_SUCCESS
  });
}
function setFinicityConnectSuccess(data) {
  return {
    type: FINICITY_CONNECT_SUCCESS,
    payload: data.data
  };
}
function errorFinicityConnectSuccess(errors) {
  intercomTrackEvent(eventNames.failedLinkedFinicityMatch);
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const uploadStatement = (request) => {
  return apiAction({
    url: config.apiBaseUrl + "statements",
    method: "POST",
    data: request,
    onSuccess: setUploadStatement,
    callback: '',
    onFailure: errorUploadStatement,
    label: UPLOAD_STATEMENT
  });
}
function setUploadStatement(data) {
  return {
    type: UPLOAD_STATEMENT,
    payload: data.data
  };
}
function errorUploadStatement(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getUploadedStatements = () => {
  return apiAction({
    url: config.apiBaseUrl + "statements",
    onSuccess: setGetUploadedStatements,
    callback: '',
    onFailure: errorGetUploadedStatements,
    label: GET_UPLOADED_STATEMENT
  });
}
function setGetUploadedStatements(data) {
  return {
    type: GET_UPLOADED_STATEMENT,
    payload: data.data
  };
}
function errorGetUploadedStatements(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getIndividualServicerAccount = (saId) => {
  return apiAction({
    url: config.apiBaseUrl + "servicer_accounts/" + saId,
    onSuccess: setGetIndividualServicerAccount,
    callback: '',
    onFailure: errorGetIndividualServicerAccount,
    label: GET_INDIVIDUAL_SERVICER_ACCOUNT
  });
}
function setGetIndividualServicerAccount(data) {
  return {
    type: GET_INDIVIDUAL_SERVICER_ACCOUNT,
    payload: data.data
  };
}
function errorGetIndividualServicerAccount(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const validateAccountNumber = (saId, requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "servicer_accounts/" + saId + "/validate_account_number",
    method: "POST",
    data: requestData,
    onSuccess: setValidateAccountNumber,
    callback: '',
    onFailure: errorValidateAccountNumber,
    label: VALIDATE_ACCOUNT_NUMBER
  });
}
function setValidateAccountNumber(data) {
  return {
    type: VALIDATE_ACCOUNT_NUMBER,
    payload: data
  };
}
function errorValidateAccountNumber(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const trackEvent = (requestData) => {
  return apiAction({
    url: config.apiBaseUrl + "track_event",
    method: "POST",
    data: requestData,
    onSuccess: setTrackEvent,
    callback: '',
    onFailure: errorTrackEvent,
    label: TRACK_EVENT
  });
}
function setTrackEvent(data) {
  return {
    type: TRACK_EVENT,
    payload: data
  };
}
function errorTrackEvent(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const forgivenessEligibilityCheck = () => {
  return apiAction({
    url: config.apiBaseUrl + "forgiveness",
    onSuccess: setForgivenessEligibilityCheck,
    callback: '',
    onFailure: errorForgivenessEligibilityCheck,
    label: CHECK_FORGIVENESS_ELIGIBILITY
  });
}
function setForgivenessEligibilityCheck(data) {
  return {
    type: CHECK_FORGIVENESS_ELIGIBILITY,
    payload: data
  };
}
function errorForgivenessEligibilityCheck(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getPellGrants = () => {
  return apiAction({
    url: config.apiBaseUrl + "grants",
    onSuccess: setPellGrants,
    callback: '',
    onFailure: errorPellGrants,
    label: GET_PELL_GRANTS
  });
}
function setPellGrants(data) {
  return {
    type: GET_PELL_GRANTS,
    payload: data
  };
}
function errorPellGrants(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const postPellGrants = (pellGrantData) => {
  return apiAction({
    url: config.apiBaseUrl + "grants",
    method: "POST",
    data: pellGrantData,
    callback: '',
    onFailure: errorPostPellGrants,
    label: POST_PELL_GRANTS
  });
}
function errorPostPellGrants(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const updatePellGrants = (pellID, pellGrantData) => {
  return apiAction({
    url: config.apiBaseUrl + "grants/" + pellID,
    method: "PATCH",
    data: pellGrantData,
    callback: '',
    onFailure: errorUpdatePellGrants,
    label: UPDATE_PELL_GRANTS
  });
}
function errorUpdatePellGrants(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const deletePellGrants = (pellID) => {
  return apiAction({
    url: config.apiBaseUrl + "grants/" + pellID,
    method: "DELETE",
    callback: '',
    onFailure: errorDeletePellGrants,
    label: DELETE_PELL_GRANTS
  });
}
function errorDeletePellGrants(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getFinicityConnectLink = (servicerID) => {
  return apiAction({
    url: config.apiBaseUrl + "customers/finicity_connect_link?servicer_account_id=" + servicerID,
    onSuccess: setFinicityConnectLink,
    callback: '',
    onFailure: errorFinicityConnectLink,
    label: FETCH_FINICITY_CONNECT_LINK
  });
}
function setFinicityConnectLink(data) {
  return {
    type: FETCH_FINICITY_CONNECT_LINK,
    payload: data
  };
}
function errorFinicityConnectLink(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}
export const getHistoricalMatchTrxes = () => {
  return apiAction({
    url: config.apiBaseUrl + "historical_match_trxes",
    onSuccess: setGetHistoricalMatchTrxes,
    callback: '',
    onFailure: errorGetHistoricalMatchTrxes,
    label: GET_HISTORICAL_MATCH_TRXES
  });
}
function setGetHistoricalMatchTrxes(response) {
  return {
    type: GET_HISTORICAL_MATCH_TRXES,
    payload: response.data
  };
}
function errorGetHistoricalMatchTrxes(errors) {
  return {
    type: GET_ERRORS,
    payload: errors.message
  };
}