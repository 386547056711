import React from 'react';
import { Carousel } from 'react-bootstrap';
import { VaultAdvisorSvg1, VaultAdvisorSvg2, VaultAdvisorSvg3, VaultAdvisorSvg4 } from '../RegistrationCarousel.constant';
import style from '../RegistrationCarousel.module.css'

const CarouselAdvisor = () => {
  return (
    <div className={'G-carousel-dark ' + style.containerMain}>
      <Carousel fade>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-pink-1 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultAdvisorSvg1 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Discover the Vault Advisor Tools
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Unlock smart ways to manage your debt with our comprehensive informational tools tailored for you.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-green-1 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultAdvisorSvg2 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Lower Your Payments
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Federal repayment plans can be a lifeline—explore your eligibility to reduce your monthly outgoings.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-orange-2 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultAdvisorSvg3 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Consider Refinancing
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Swap out your current loans for a fresh start; let's weigh the pros and cons of refinancing together.
              </label>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className={'flex flex-column align-items-center Gb-blue-100 setHeight_RegistrationPage ' + style.carouselContainer}>
            <div className={style.heightFitContentMob}>
              <VaultAdvisorSvg4 />
            </div>
            <div className={'px-5 mt-2 ' + style.heightFitContent}>
              <label className='Gfs-15 Gt-pink-8 sans-bold'>
                Achieve a Faster Payoff
              </label>
            </div>
            <div className='px-5 '>
              <label>
                Little additions to your monthly payments can lead to big savings. Find out how to speed up your loan clearance and save more.
              </label>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default CarouselAdvisor