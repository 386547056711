import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import { sumOfAmountsComputedTransactions, totalToPay } from "../match";
import { numberFormat } from '../../../common/js/jsFunctions';
import { ExclamationTriangleFill, PencilFill } from 'react-bootstrap-icons';


const MatchProgressBar = (props) => {
  const [, forceUpdate] = useState()
  const validator = useRef(
    new SimpleReactValidator(
      { autoForceUpdate: { forceUpdate: forceUpdate } }
    )
  )

  const customer = props.customer;
  const customerSalary = customer ? customer.attributes.salary_cents / 100 : 0;

  const servicerAccounts = props.servicerAccounts;
  const sumOfAmountsComputed = sumOfAmountsComputedTransactions(servicerAccounts);

  let totalPay = 0;
  if (customer) {
    totalPay = totalToPay(customer, customerSalary);
  }

  const [salary, setSalary] = useState(customerSalary);

  useEffect(() => { setSalary(customerSalary) }, [customerSalary]);

  function onSubmitSalaryModal(e) {
    const formValid = validator.current.allValid()
    if (!formValid) {
      validator.current.showMessages()
    } else {
      props.onSubmitSalary(e, salary);
    }
  }
  return (
   
    <>
      {
        salary > 0 && customerSalary > 0 ?
          <div className='p-4'>
            <label className='G-badge-purple'>
              Your estimated progress
            </label>
            <br />
            <label className='Gfs-15 Gt-pink-8 sans-bold align-middle'>
              {sumOfAmountsComputed > 0 ? numberFormat(sumOfAmountsComputed) : '$0.00'}
            </label>
            <label className='align-middle'>
              &nbsp;of {numberFormat(totalPay)}
            </label>
            <p className='sans-medium'>
              We calculate the percentage based on the income you provide
            </p>
            <label>
              Salary - {numberFormat(salary)}
            </label>
            <Link to="#" onClick={() => props.setShowUpdateSalaryModal(true)} className='mx-4 Gt-pink-8 text-decoration-none cancelLink'><PencilFill className='Gt-pink-8 mr-2' /> Edit</Link>
          </div>
          :
          <div className='p-4 Gb-yellow-1'>
            <label className='G-badge-purple'>
              your estimated progress
            </label>
            <br />
            <label className='Gfs-15 Gt-pink-8 sans-bold align-middle'>
              $0.00
            </label>
            <label className='align-middle'>
              &nbsp;of $0.00
            </label>
            <p className='sans-medium'>
              We calculate the percentage based on the income you provide
            </p>
            <div className='d-flex'>
              <div className='mr-2'>
                <ExclamationTriangleFill size={20} className='Gt-yellow-6' />
              </div>
              <label className='mb-0'>
                Please add your salary so we can project your match progress!
              </label>
            </div>
            <label className='Gfs-125 my-3'>
              What is your salary?
            </label>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={salary}
              prefix="$"
              decimalSeparator="."
              displayType="input"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              type="text"
              className="form-control"
              id="salary_cents"
              name="salary"
              onValueChange={(values) => {
                setSalary(values.floatValue ? values.floatValue : '')
              }
              }
              onChange={() => validator.current.showMessageFor('salary')}
            />
            {validator.current.message('salary', salary, 'required')}

            <button onClick={(e) => onSubmitSalaryModal(e)} className='G-orange-button mt-4'>Save</button>
          </div>
      }
    </>

  )
}

export default MatchProgressBar;