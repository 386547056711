import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import { Form } from 'react-bootstrap';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import SimpleReactValidator from "simple-react-validator";
import { Modal } from "react-bootstrap";
import { BoxArrowUpRight, XLg, Check, InfoCircleFill } from 'react-bootstrap-icons';
import { responseMessage } from "../../../utils/alert";
import { numberFormat } from "../../../common/js/jsFunctions";
import ValidatorMessage from "../../shared/ValidatorMessage/ValidatorMessage";
import { returnValidationMessageStatus } from "../Pay/Pay.constant";
import CustomModal from "../../shared/CustomModal/CustomModal";
import ReactTooltip from "react-tooltip";
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import { ManageFriendsFamilyBreadcrumb } from "../CommonBreadcrumb/Breadcrumb.constant";
import { getBeneficiaryProfiles, sendInviteToFnfContributor, getServicerAccounts } from "../../../actions/fiveTwoNineActions";


class ManageFriendsFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerAccounts: [],
      domain: "",
      urlIsCopied: false,
      beneficiary_name: "",
      picture_thumb_url: "",
      welcome_message: "",
      date_of_birth: "",
      educational_goals: "",
      status: 1,
      invitationEmailData: {
        invitation_name: "",
        invitation_email: "",
      },
      validationError: {
        invitation_name: false,
        invitation_email: false,
      },
      shareModalDetails: {
        beneficiary_name: "",
        account_number: "",
        id: ""

      },
      servicerAccountId: this.props.match.params.servicerAccountId,
      isEmailSent: false,
      modalProp: {
        show: false,
        title: '',
        bodyText: '',
        footerButton: true,
        footerButtonName: '',
        footerButtonOnclick: '',
        footerButtonHref: '',
        footerButtonOnclickArgs: '',
        svgType: 'check'
      }
    };
    this.handleEmailInvitation = this.handleEmailInvitation.bind(this);
    this.validator = new SimpleReactValidator({
      element: (message) => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this,
    });
  }
  showValidationMessage(event) {
    this.setState({
      validationError: {
        ...this.state.validationError,
        [event.target.name]: returnValidationMessageStatus(this.validator, event)
      },
    });
  }
  componentDidMount() {
    document.title = "Vault | Manage Friends and Family Contribution";
    this.props.getServicerAccounts();
    const domain = window.location.href.split("/").slice(0, 3).join("/");
    this.setState({ ...this.state, domain });
    const isConfirm = window.location.pathname.split("/").pop();
    if (isConfirm === 'confirm') {
      let url = window.location.href.split("/");
      url.pop();
      let newUrl = url.join('/');
      window.history.pushState({}, null, newUrl);
      this.onProfileCreationSuccess();
    }
  }

  // define state for openModal & closeModal popup
  openModal = (beneficiary_name, account_number, id) => this.setState({
    ...this.state, isOpen: true, shareModalDetails: {
      beneficiary_name,
      account_number,
      id,
    }, servicerAccountId: id, urlIsCopied: false, isEmailSent: false, status: 1,
    modalProp: {
      show: false,
    }
  });
  closeModal = () => this.setState({ ...this.state, isOpen: false });
  radioHandler = (status) => {
    this.setState({ ...this.state, status });
  };

  copyToClipboard = (str) => {
    const copyText = document.getElementById("copy_url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    this.setState({
      ...this.state,
      urlIsCopied: true,
    });
  };
  change(e) {
    this.setState({
      ...this.state,
      invitationEmailData: {
        ...this.state.invitationEmailData,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleEmailInvitation() {
    const { servicerAccountId } = this.state;
    if (this.validator.allValid()) {
      const { invitation_email: email, invitation_name: name } = this.state.invitationEmailData;

      const fd = new FormData();
      fd.append("data[email]", email);
      fd.append("data[name]", name);

      this.props.sendInviteToFnfContributor(servicerAccountId, fd);
    } else {
      this.validator.showMessages();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.sendInviteResponse !== this.props.sendInviteResponse) {
      this.setState({
        isEmailSent: true,
      });
    }

    if (prevProps.errors !== this.props.errors) {
      responseMessage("error", "Error", "");
    }

    if (prevProps.servicerAccounts !== this.props.servicerAccounts) {
      const servicer_accounts_data = this.props.servicerAccounts.data;
      const servicer_accounts_included = this.props.servicerAccounts.included;

      let servicers_array = servicer_accounts_included.filter(function (value) {
        return value.type === "servicers";
      });
      let servicers_addresses_array = servicer_accounts_included.filter(
        function (value) {
          return value.type === "addresses";
        }
      );

      for (let i = 0; i < servicer_accounts_data.length; i++) {
        const servicerId =
          servicer_accounts_data[i]["relationships"]["servicer"]["data"]["id"];
        let itemServicer = servicers_array.find(
          (item) => item.id === servicerId
        );
        servicer_accounts_data[i]["servicer"] = itemServicer;

        if (servicer_accounts_data[i]["relationships"]["address"]["data"] !== null) {
          const addressId =
            servicer_accounts_data[i]["relationships"]["address"]["data"]["id"];
          let itemAddress = servicers_addresses_array.find(
            (item) => item.id === addressId
          );
          servicer_accounts_data[i]["address"] = itemAddress;
        }
      }
      let pay_active_servicer_account = servicer_accounts_data.filter(function (value) {
        return (
          value.attributes.college_savings === true &&
          value.attributes.pay_active === true
        );
      });
      this.setState({
        servicerAccounts: servicer_accounts_data,
        payActiveServicerAccount: pay_active_servicer_account,
      });
    }

  }

  onProfileCreationSuccess = () => {
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: true,
        title: 'Friends and Family Contributions Profile Created Successfully!',
        bodyText: 'Your Profile Summary is ready to be shared with your friends and family. Click the share button from your dashboard to generate an email invitaion/link invitation',
        footerButton: true,
        footerButtonName: 'Dismiss',
        footerButtonOnclick: this.closeSuccessModal,
        footerButtonHref: '',
        footerButtonOnclickArgs: '',
        svgType: 'check'
      }
    });
  }

  closeSuccessModal = () => {
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: false,
      }
    });
  }
  showUgiftModal = () => {
    const bodyText = `Required for Friends & Family contributions: A Ugift code allows friends and family
        to contribute to a specific beneficiary's 529 account. Account owners are given a unique Ugift code for each beneficiary that can be shared
        with others. To find your beneficiary's Ugift code, log into your 529 account and enable the Ugift feature within it to get your code.`
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: true,
        title: 'More about UGIFT Code',
        bodyText: bodyText,
        footerButton: false,
        footerButtonName: 'Dismiss',
        footerButtonOnclick: this.closeSuccessModal,
        footerButtonHref: '',
        footerButtonOnclickArgs: '',
        svgType: 'info'
      }
    });
  }
  render() {
    const { status, servicerAccounts } = this.state;
    const servicerAccountsFiltered = servicerAccounts.filter((x) => x.attributes.is_fivetwonine)
    const domain = window.location.href.split("/").slice(0, 3).join("/");
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container  my-5">
            <Breadcrumb list={ManageFriendsFamilyBreadcrumb} />
            <div className="p-4 bg-white Gborder-1 rounded-lg">
              <div className="G-grid11-2">
                <div className="">
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M42.1376 25.8881C43.7001 24.3256 42.9876 22.5006 42.1376 21.6131L38.3876 17.8631C36.8126 16.3006 35.0001 17.0131 34.1126 17.8631L31.9876 20.0006H28.7501C26.3751 20.0006 25.0001 21.2506 24.3001 22.6881L18.7501 28.2381V33.2381L17.8626 34.1131C16.3001 35.6881 17.0126 37.5006 17.8626 38.3881L21.6126 42.1381C22.2876 42.8131 23.0126 43.0631 23.7001 43.0631C24.5876 43.0631 25.4001 42.6256 25.8876 42.1381L29.2626 38.7506H33.7501C35.8751 38.7506 36.9501 37.4256 37.3376 36.1256C38.7501 35.7506 39.5251 34.6756 39.8376 33.6256C41.7751 33.1256 42.5001 31.2881 42.5001 30.0006V26.2506H41.7626L42.1376 25.8881ZM40.0001 30.0006C40.0001 30.5631 39.7626 31.2506 38.7501 31.2506H37.5001V32.5006C37.5001 33.0631 37.2626 33.7506 36.2501 33.7506H35.0001V35.0006C35.0001 35.5631 34.7626 36.2506 33.7501 36.2506H28.2376L24.1376 40.3506C23.7501 40.7131 23.5251 40.5006 23.3876 40.3631L19.6501 36.6381C19.2876 36.2506 19.5001 36.0256 19.6376 35.8881L21.2501 34.2631V29.2631L23.7501 26.7631V28.7506C23.7501 30.2631 24.7501 32.5006 27.5001 32.5006C30.2501 32.5006 31.2501 30.2631 31.2501 28.7506H40.0001V30.0006ZM40.3626 24.1131L38.2376 26.2506H28.7501V28.7506C28.7501 29.3131 28.5126 30.0006 27.5001 30.0006C26.4876 30.0006 26.2501 29.3131 26.2501 28.7506V25.0006C26.2501 24.4256 26.4626 22.5006 28.7501 22.5006H33.0126L35.8626 19.6506C36.2501 19.2881 36.4751 19.5006 36.6126 19.6381L40.3501 23.3631C40.7126 23.7506 40.5001 23.9756 40.3626 24.1131Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h4>Manage Friends And Family Contributions</h4>
                  <p className="sans-medium">
                    Easily manage and share Friends and Family Profile and receive contributions to your 529 accounts.
                  </p>
                </div>
              </div>
              {/* beneficiary table */}
              <div className="mt-4 Gtable-div">
                <Table borderless className="Gborder-05 G-table-theme-grey">
                  <thead className="Gt-head">
                    <tr>
                      <th className="Gfs-075 p-3">Beneficiary</th>
                      <th className="Gfs-075 p-3">529 plan name</th>
                      <th className="Gfs-075 p-3">Account number</th>
                      <th className="Gfs-075 p-3">Contribution to date</th>
                      <th className="Gfs-075 p-3">Friends and family profile status</th>
                      <th className="Gfs-075 p-3">Share profile</th>
                    </tr>
                  </thead>
                  <tbody className="Gt-body">
                    {servicerAccountsFiltered && servicerAccountsFiltered.length > 0
                      ? servicerAccountsFiltered.map((row, i) => {
                        return (
                          <tr key={i}>
                            <td>{row.attributes.beneficiary_name}</td>
                            <td>{row.servicer.attributes.name}</td>
                            <td>{row.attributes.account_number}</td>
                            <td>{numberFormat(row.attributes.contribution_to_date)}</td>
                            {
                              row.servicer.attributes.contribution_provider === "ugift" ?
                                (
                                  <>

                                    <td className="G-cursor-pointer">
                                      {row?.relationships?.beneficiary_profile?.data ? (<a className=' text-decoration-none cancelLink G-cursor-pointer' href={`/dashboard/${row.id}/create-friends-and-family`} >
                                        <BoxArrowUpRight className="Gt-green-6 G-cursor-pointer" />
                                        <label className="Gt-green-6 G-vertical-align-sub G-cursor-pointer Gfs-087">&nbsp;&nbsp;ENABLED</label>
                                      </a>)
                                        :
                                        (<a className=' text-decoration-none cancelLink G-cursor-pointer' href={`/dashboard/${row.id}/create-friends-and-family`}>
                                          <BoxArrowUpRight className="Gt-yellow-6 G-cursor-pointer" />
                                          <label className="Gt-yellow-6 G-vertical-align-sub G-cursor-pointer Gfs-087">&nbsp;&nbsp;NOT ACTIVATED</label>
                                        </a>)}
                                    </td>
                                    {row?.relationships?.beneficiary_profile?.data ?
                                      <td className="text-center"
                                        onClick={() => this.openModal(row.attributes.beneficiary_name, row.attributes.account_number, row.id)}>
                                        <BoxArrowUpRight className="Gt-pink-8 G-cursor-pointer" />
                                      </td> : null}
                                  </>)
                                :
                                (
                                  <>
                                    <td>
                                      <ReactTooltip id={row.id} place="top" effect="solid" >
                                        <span>
                                          We only support 529 plans <br />
                                          partnered with Ugift at the moment <br />
                                          We're sorry for any inconvenience and are <br />
                                          looking to expand our offerings in the future.
                                        </span>
                                      </ReactTooltip>
                                      <InfoCircleFill data-tip data-for={row.id} className="Gt-slate-3" />
                                      <label className="Gt-slate-3 G-top-1px Gfs-087">&nbsp;&nbsp;NOT SUPPORTED</label>
                                    </td>
                                  </>
                                )}
                          </tr>
                        );
                      })
                      : (<tr><td colSpan={6}>You currently do not have any 529 accounts that is eligible for Friends and Family Contributions</td></tr>)}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* Modal code */}
        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Body>
            <div className="d-flex">
              <div className="pinkCircularDiv mb-3 mr-3">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M37.5 35.1C36.55 35.1 35.7 35.475 35.05 36.0625L26.1375 30.875C26.2 30.5875 26.25 30.3 26.25 30C26.25 29.7 26.2 29.4125 26.1375 29.125L34.95 23.9875C35.625 24.6125 36.5125 25 37.5 25C39.575 25 41.25 23.325 41.25 21.25C41.25 19.175 39.575 17.5 37.5 17.5C35.425 17.5 33.75 19.175 33.75 21.25C33.75 21.55 33.8 21.8375 33.8625 22.125L25.05 27.2625C24.375 26.6375 23.4875 26.25 22.5 26.25C20.425 26.25 18.75 27.925 18.75 30C18.75 32.075 20.425 33.75 22.5 33.75C23.4875 33.75 24.375 33.3625 25.05 32.7375L33.95 37.9375C33.8875 38.2 33.85 38.475 33.85 38.75C33.85 40.7625 35.4875 42.4 37.5 42.4C39.5125 42.4 41.15 40.7625 41.15 38.75C41.15 36.7375 39.5125 35.1 37.5 35.1ZM37.5 20C38.1875 20 38.75 20.5625 38.75 21.25C38.75 21.9375 38.1875 22.5 37.5 22.5C36.8125 22.5 36.25 21.9375 36.25 21.25C36.25 20.5625 36.8125 20 37.5 20ZM22.5 31.25C21.8125 31.25 21.25 30.6875 21.25 30C21.25 29.3125 21.8125 28.75 22.5 28.75C23.1875 28.75 23.75 29.3125 23.75 30C23.75 30.6875 23.1875 31.25 22.5 31.25ZM37.5 40.025C36.8125 40.025 36.25 39.4625 36.25 38.775C36.25 38.0875 36.8125 37.525 37.5 37.525C38.1875 37.525 38.75 38.0875 38.75 38.775C38.75 39.4625 38.1875 40.025 37.5 40.025Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <div>
                <div className="d-flex">
                  <h4>Generate your Friends and Family Profile share link</h4>
                  <div className="ml-3"> <button onClick={this.closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                </div>
                {/* body */}
                <div className="G-grid11 mt-4 ">
                  <div className="Gborder-r05">
                    <span className="Gt-pink-8">ACCOUNT BENEFICIARY</span> <br />
                    {this.state.shareModalDetails.beneficiary_name}
                  </div>

                  <div >
                    <span className="Gt-pink-8">ACCOUNT NUMBER</span> <br />
                    {this.state.shareModalDetails.account_number}
                  </div>
                </div>
                <div className="mt-5 G-radio-pink">
                  <label className="mr-5">
                    <input type="radio" className="mx-2" name="release" checked={status === 1} onChange={(e) => this.radioHandler(1)} /> Email Invitation
                  </label>
                  <label>
                    <input type="radio" className="mx-2" name="release" checked={status === 2} onChange={(e) => this.radioHandler(2)} />
                    Copy Profile/Invitation Link
                  </label>
                </div>
                {status === 1 && (this.state.isEmailSent ? (<>
                  <div className='py-3 Gb-green-2 pl-3 rounded-lg sans-semibold w-100 my-4 d-flex  '>
                    <div className="my-auto mr-3">
                      <Check size={28} className="Gt-green-6" />
                    </div>
                    <div>
                      Your email invite has been successfully sent! Your recipient should <br /> receive it shortly.
                    </div>
                  </div>
                </>) : (
                  <>
                    <Form.Group className='my-4'>
                      <Form.Label className="mb-0">Full Name</Form.Label>
                      <Form.Control type="text" name='invitation_name' placeholder="Full Name" onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                      <ValidatorMessage validator={this.validator} fieldName='invitation_name' fieldValue={this.state.invitationEmailData.invitation_name} message='Full name is required' />
                    </Form.Group>
                    <Form.Group className='my-4'>
                      <Form.Label className="mb-0">Email</Form.Label>
                      <Form.Control type="text" name='invitation_email' placeholder="Email" onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                      <ValidatorMessage validator={this.validator} fieldName='invitation_email' fieldValue={this.state.invitationEmailData.invitation_email} message='Email is required' />
                    </Form.Group>
                    <div className="d-flex justify-content-end w-100">
                      <button className="bg-transparent border-0 G-orange-button" onClick={() => this.handleEmailInvitation()}>
                        Send
                      </button>
                    </div>

                  </>
                ))}
                {status === 2 && (
                  <>
                    {this.state.urlIsCopied ?
                      (<>
                        <p className='my-4 py-3 Gb-green-2 pl-3 rounded-lg sans-semibold w-100 '>
                          <Check size={28} className="Gt-green-6" />
                          &nbsp; URL is copied
                        </p>
                      </>)
                      :
                      (<><Form.Group className='my-4'>
                        <Form.Control type="text" id="copy_url" name='copy_url' value={`${domain}/${this.state.shareModalDetails.id}/contribution`} onChange={(e) => this.change(e)} onBlur={(e) => this.showValidationMessage(e)} />
                      </Form.Group>
                        <div className="d-flex justify-content-end w-100">
                          <button className="bg-transparent border-0 G-orange-button" onClick={() => this.copyToClipboard()}>
                            Copy URL
                          </button>
                        </div>
                      </>)}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <CustomModal modalProp={{ ...this.state.modalProp }}
          svgComponent={this.state.modalProp.svgType === 'check' ?
            <div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div>
            : (<div className="mb-3 mr-3">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="#D1E9FF" />
                <path d="M28.75 23.75H31.25V26.25H28.75V23.75ZM28.75 28.75H31.25V36.25H28.75V28.75ZM30 17.5C23.1 17.5 17.5 23.1 17.5 30C17.5 36.9 23.1 42.5 30 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 30 17.5ZM30 40C24.4875 40 20 35.5125 20 30C20 24.4875 24.4875 20 30 20C35.5125 20 40 24.4875 40 30C40 35.5125 35.5125 40 30 40Z" fill="#1890FF" />
              </svg>
            </div>
            )}
        />
      </>
    );
  }
}

ManageFriendsFamily.propTypes = {
  getBeneficiaryProfiles: PropTypes.func.isRequired,
  sendInviteToFnfContributor: PropTypes.func.isRequired,
  getServicerAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  beneficiaryProfile: state.five_two_nine?.friendsAndFamilyData?.beneficiaryProfile,
  sendInviteResponse: state.five_two_nine.friendsAndFamilyData.sendInviteToFnfContributor,
  employerData: state.employer_array.employerData,
  servicerAccounts: state.five_two_nine.getServicerAccountsData,
});

export default connect(mapStateToProps, { getBeneficiaryProfiles, sendInviteToFnfContributor, getServicerAccounts })(ManageFriendsFamily);
