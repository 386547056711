import React from 'react';
import { refiURL, refiURLIsPresent } from '../../refi-vendor';
import parse from 'html-react-parser';
import S from './LendersList.module.css'
import { intercomTrackEvent } from "../../../customerStatus";
import eventNames from "../../../event-names-map";

const LendersList = ({ refiVendors, customer, trackEvent }) => {
  const clickRefinancer = (url) => {
    url = url.trim();
    if (!(/^https?:\/\//i.test(url) || /^http?:\/\//i.test(url))) {
      url = '//' + url;
    }
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null

    intercomTrackEvent(eventNames.clickedAdvisorRefiVendorInPaperwork);
    trackEvent({
      data: {
        event_key: eventNames.setRefinanceInterestRate,
        params: { 'clicked_refi_tile': true}
      }
    })
  }
  return (
    <div>
      {
        refiVendors.length > 0 ?
          <div className={`my-4 rounded-lg Gborder-05 p-3 ${S.lenderListMainDiv}`}>
            {
              refiVendors.length > 0 ? refiVendors.map((item, i) => {

                const imageUrl = item.attributes.image_url;
                const description = item.attributes.description

                return (<div key={i} className={"rounded-lg Gborder-05 p-3 G-cursor-pointer " + (i !== 0 ? 'mt-4' : '')}>
                  {refiURLIsPresent(item, customer) ?
                    <div onClick={(e) => clickRefinancer(refiURL(item, customer))}>
                      <div className='mb-3'>
                        <img src={imageUrl} alt="Refinance Vendor" className={`responsive ${S.imageWidth}`} />
                      </div>
                      <div>
                        <p className='mb-0 sans-medium Gfs-1'>
                          {parse(description)}
                        </p>

                      </div>
                    </div>
                    :
                    <div>
                      <div className='mb-3'>
                        <img src={imageUrl} alt="Refinance Vendor" className={`responsive ${S.imageWidth}`} />
                      </div>
                      <div>
                        <p className='mb-0 sans-medium Gfs-1'>
                          {parse(description)}
                        </p>

                      </div>
                    </div>
                  }
                </div>)
              })
                : null
            }
          </div>
          : null
      }
    </div>
  )
}

export default LendersList;