export const ContributionBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'Add a 529 account',
        active: true,
        url: '#'
    }
]
export const EditContributionBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'Edit 529 account',
        active: true,
        url: '#'
    }
]
export const ViewContributionListBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'Contributions',
        active: true,
        url: '#'
    }
]
export const ManageFriendsFamilyBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'Manage Friends And Family Contributions',
        active: true,
        url: '#'
    }
]
export const CreateFriendsFamilyBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'Manage Friends And Family Contributions',
        active: false,
        url: '/dashboard/manage-friends-and-family'
    },
    {
        title: 'Create Friends and Family Profile',
        active: true,
        url: '#'
    }
]
export const EditFriendsFamilyBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'Manage Friends And Family Contributions',
        active: false,
        url: '/dashboard/manage-friends-and-family'
    },
    {
        title: 'Edit Friends and Family Profile',
        active: true,
        url: '#'
    }
]
export const FaqBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: '529 FAQ',
        active: true,
        url: '#'
    }
]
export const FaqBreadcrumbTR = [
    {
        title: 'Dashboard',
        active: false,
        url: '/tuition-reimbursement-history'
    }, {
        title: "What's a 529",
        active: true,
        url: '#'
    }
]
export const MapBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: '529 Tax Benefits by State',
        active: true,
        url: '#'
    }
]
export const MapBreadcrumbTR = [
    {
        title: 'Dashboard',
        active: false,
        url: '/tuition-reimbursement-history'
    }, {
        title: '529 Tax Benefits by State',
        active: true,
        url: '#'
    }
]
export const PayrollDeductionBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: '529 Payroll Deductions',
        active: true,
        url: '#'
    }
]
export const dtiCalculatorBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'DTI Calculator',
        active: true,
        url: '#'
    }
]

export const dtiCalculatorBreadcrumbTR = [
    {
        title: 'Dashboard',
        active: false,
        url: '/tuition-reimbursement-history'
    }, {
        title: 'DTI Calculator',
        active: true,
        url: '#'
    }
]

export const BenefitsOfFiveTwoNinePlanBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'What are the benefits of a 529 plan?',
        active: true,
        url: '#'
    }
]

export const ChoosePlanBestForMeBreadcrumb = [
    {
        title: 'Dashboard',
        active: false,
        url: '/dashboard'
    }, {
        title: 'How do I choose the 529 Plan that is best for me?',
        active: true,
        url: '#'
    }
]