import { GET_ERRORS } from '../actions/types';
import * as Sentry from "@sentry/browser";

const initialState = {
  errors:{}
};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      if (action.payload.error === "Invalid User. Cannot Authenticate.") window.location.href = '/login';;
      Sentry.captureException(action.payload);
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state;
  }
}
