import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { responseMessage } from '../../../utils/alert';
import { sortAdvisorServicers } from '../dashboardCommon';
import { sortLoanType } from '../dashboardCommon';

import Profile from "../../../common/img/myprofile.svg";
import Loan from "../../../common/img/myloans.svg";
import Edit from "../../../common/img/nslds_icon.svg";

import { getAdvisorServicers, getLoanType, addLoan, addMissingServicer } from '../../../actions/advisorActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";

import Select from 'react-select';

class LoansNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advisorServicers: [],
      loanTypeData: [],
      servicerId: "",
      otherServicerName: "",
      federal: "",
      showOtherServicer: false,
      currentBalance: "",
      originationBalance: "",
      monthlyPayment: "",
      interestRate: "",
      originationDate: "",
      addLoanData: {},
      addMissingServicer: {},
      validationError: {
        origination_balance_cents: false,
        current_balance_cents: false,
        monthly_payment_cents: false,
      },

      loan: {
        "data": {
          "attributes": {
          },
          "type": "loans"
        }
      }
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
    this.showValidationMessage = this.showValidationMessage.bind(this);
  }
  componentDidMount() {
    document.title = "Vault Advisor"
    const { getAdvisorServicers, getLoanType } = this.props;
    getAdvisorServicers();
    getLoanType();
    intercomTrackEvent(eventNames.visitedAdvisorNewLoan);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.advisorServicers !== this.props.advisorServicers) {
      const advisorServicers = this.props.advisorServicers.sort(sortAdvisorServicers)

      this.setState({
        advisorServicers: advisorServicers
      })
    }
    if (prevProps.loanTypeData !== this.props.loanTypeData) {
      const loanTypeData = this.props.loanTypeData.sort(sortLoanType);
      this.setState({
        loanTypeData: loanTypeData
      })
    }
    if (prevProps.addLoanData !== this.props.addLoanData) {
      responseMessage("success", "Your loan is saved!", "");
      setTimeout(() =>
        this.props.history.push({
          pathname: '/welcome/loans'
        }), 5000
      )
    }
  }
  changeLoanServicer = (e) => {
    const { loan, advisorServicers } = this.state;
    const servicerId = e.value;
    let servicer = advisorServicers.filter(function (value) {
      return value.id === servicerId;
    });
    let label = servicer[0].attributes.name;

    let showOtherServicer = false;
    if (servicerId) {
      const servicerJson = {
        "servicer": {
          "data": {
            "type": 'servicers',
            "id": servicerId
          }
        }
      }
      loan.data['relationships'] = servicerJson;
      if (label === 'Other') {
        showOtherServicer = true;
      } else {
        loan.data.attributes.other_servicer_name = null;
      }
    }

    this.setState({
      servicerId: servicerId,
      showOtherServicer: showOtherServicer,
      loan: loan
    })
  }
  handleInputChange = (e) => {
    const { loan } = this.state;
    loan.data.attributes.other_servicer_name = e.target.value;

    this.setState({
      otherServicerName: e.target.value,
      loan: loan
    })
  }
  makeLoanFederal = (value, event) => {
    const { loan } = this.state;
    let loanType = '';
    if (value === false) {
      loanType = 'Private';
    }
    loan.data.attributes.loan_type = loanType;
    this.setState({
      federal: value,
      loanType: loanType,
      loan: loan
    })
  }
  changeLoanType = (e) => {
    const { loan } = this.state;
    const loanType = e.target.value
    loan.data.attributes.loan_type = loanType;

    this.setState({
      loanType: loanType,
      loan: loan
    })
  }
  handleOriginationDateChange = (date) => {
    const { loan } = this.state;
    const originationDate = date;
    loan.data.attributes.origination_date = moment(originationDate).format('Y-MM-DD');
    this.setState({
      originationDate: originationDate,
      loan: loan
    })
  }
  handleOriginationBalanceChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    const originationBalance = floatValue * 100;
    loan.data.attributes.origination_balance_cents = originationBalance;

    this.setState({
      originationBalance: value,
      loan: loan
    })
  }
  handleCurrentBalanceChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    const currentBalance = floatValue * 100;
    loan.data.attributes.current_balance_cents = currentBalance;

    this.setState({
      currentBalance: value,
      loan: loan
    })
  }
  handleInterestRateChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    loan.data.attributes.interest_rate = floatValue;

    this.setState({
      interestRate: value,
      loan: loan
    })
  }
  handleMonthlyPaymentChange = (values) => {
    const { loan } = this.state;
    const value = values.value;
    const floatValue = values.floatValue;
    const monthlyPayment = floatValue * 100;
    loan.data.attributes.monthly_payment_cents = monthlyPayment;

    this.setState({
      monthlyPayment: value,
      loan: loan
    })
  }
  handleSubmit = (e) => {
    if (this.validator.allValid()) {
      const { loan, otherServicerName } = this.state;
      const loanRequest = loan
      if (otherServicerName) {
        const missingServicer = { "servicer_name": otherServicerName }
        this.props.addMissingServicer(missingServicer);
      }
      this.props.addLoan(loanRequest);
      intercomTrackEvent(eventNames.submittedAdvisorNewLoan);
    } else {
      this.validator.showMessages();
    }
  }
  showValidationMessage(event) {
    if (this.validator.fieldValid(event.target.name)) {
      this.validator.hideMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: false
        }
      });
    } else {
      this.validator.showMessageFor(event.target.name);
      this.setState({
        validationError: {
          ...this.state.validationError,
          [event.target.name]: true
        }
      });
    }
  }
  handleCancel = (e) => {
    this.props.history.push({
      pathname: '/welcome/loans'
    })
  }
  render() {
    const {
      advisorServicers,
      loanTypeData,
      loanType,
      federal,
      otherServicerName,
      showOtherServicer,
      currentBalance,
      originationBalance,
      monthlyPayment,
      interestRate,
      originationDate
    } = this.state;

    return (
      <>
        <Header {...this.props} />
        <div className="container-content">
          <div className="container-custom">
            <div className="about-custom">
              <h4 className="text-center">Connect to FSA</h4>

              <div className="progress-bar-custom">
                <div className="setup-profile active">
                  <div className="step-1">
                    <img src={Profile} className="img-fluid" alt="profile" />
                    <p>1. Set up profile</p>
                  </div>
                </div>
                <div className="setup-profile active">
                  <div className="step-1">
                    <img src={Edit} className="img-fluid" alt="loan" />
                    <p>2. Import your loans</p>
                  </div>
                </div>
                <div className="setup-profile ">
                  <div className="step-1">
                    <img src={Loan} className="img-fluid" alt="edit" />
                    <p>3. Edit loans</p>
                  </div>
                </div>
              </div>

              {/* Add a loan */}
              <section className="box-header mt-30">
                <p>New Loan</p>
                <div className="bg-white">
                  <div className="content-area-detail border-top">
                    <div className="d-flex area-detail-inner">
                      <p>Who is the loan service provider?</p>
                      <div className="cus-select-main">
                        <div className={`selectInput layout-row layout-wrap input__wrapper  input-group-basic flex-xs-100`}>
                          {advisorServicers.length > 0 ?
                            <Select
                              className="basic-single"
                              classNamePrefix="reactSelect"
                              onChange={this.changeLoanServicer.bind(this)}
                              name="servicerId"
                              placeholder='Select Servicer Account'
                              isSearchable={true}
                              options={
                                advisorServicers.map(item => {
                                  return {
                                    label: item.attributes.name,
                                    value: item.id
                                  }
                                })
                              }
                            />
                            : <select className="form-control classNameic"></select>
                          }
                        </div>
                        {
                          this.validator.message(
                            "servicerId",
                            this.state.servicerId,
                            "required", {
                            messages: {
                              required: "* Servicer can't be blank"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {showOtherServicer ?
                    <div className="content-area-detail">
                      <div className="d-flex area-detail-inner">
                        <div className="cus-text flex-50">
                          <p>What is the name of your servicer?</p>
                        </div>
                        <div className="cus-input-main flex-50 text-right">
                          <input
                            type="text"
                            className="input-custom"
                            id="other_servicer_name"
                            name="other_servicer_name"
                            value={otherServicerName}
                            onChange={this.handleInputChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    : null}
                  <div className="content-area-detail pt-10 pb-10">
                    <div className="d-flex area-detail-inner">
                      <p>Is it a private or federal loan?</p>
                      <div className="radio-btn">
                        <button onClick={this.makeLoanFederal.bind(this, false)} className={"c-button c-button-hollow c-button-primary c-button-small c-button-wide font-12 mr-1" + (federal === false ? ' active-btn' : '')}>Private</button>
                        <button onClick={this.makeLoanFederal.bind(this, true)} className={"c-button c-button-hollow c-button-primary c-button-small c-button-wide font-12" + (federal === true ? ' active-btn' : '')}>Federal</button>
                        {
                          this.validator.message(
                            "federal",
                            this.state.federal,
                            "required", {
                            messages: {
                              required: "* Federal can't be blank"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {federal ?
                    <div className="content-area-detail pt-20 pb-20">
                      <div className="d-flex area-detail-inner">
                        <p>What is the federal loan type?</p>
                        <select
                          className="cus-select"
                          id="loan_type"
                          name="loan_type"
                          value={loanType}
                          onChange={this.changeLoanType.bind(this)}
                        >
                          <option value="">--Select--</option>
                          {loanTypeData ? loanTypeData.map((item, index) => {
                            return (
                              <option key={index} value={item}>{item}</option>
                            )
                          }) : ""}
                        </select>
                      </div>
                    </div>
                    : null}
                  <div className="content-area-detail">
                    <div className="d-flex area-detail-inner">
                      <div className="cus-text">
                        <p>On what date did the loan originate?</p>
                        <span className="font-12">Don't know the exact date? Try month and year, with 01 as the day.</span>
                      </div>
                      <div className="cus-input-main">
                        <DatePicker
                          onKeyDown={event => {
                            event.preventDefault();
                            return false;
                          }}
                          className="input-custom w-4"
                          onChange={this.handleOriginationDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          selected={originationDate}
                        />
                        {
                          this.validator.message(
                            "originationDate",
                            this.state.originationDate,
                            "required", {
                            messages: {
                              required: "* Origination date can't be blank"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                {/* loan financial info */}
                <p className='mt-3'>Loan financial information</p>
                <div className="bg-white">

                  <div className="content-area-detail border-top">
                    <div className="d-flex area-detail-inner">
                      <div className="cus-text flex-50">
                        <p>What was the original borrowed amount?</p>
                        <span className="font-12">If your access to Vault was provided by your employer, this information is not shared with them.</span>
                      </div>
                      <div className="cus-input-main flex-50 text-right">
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={originationBalance}
                          prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="text"
                          className="input-custom g-text-right-align"
                          id="origination_balance_cents"
                          name="origination_balance_cents"
                          onBlur={e => this.showValidationMessage(e)}
                          onValueChange={(values) => { this.handleOriginationBalanceChange(values) }}
                        />
                        {
                          this.validator.message(
                            "originationBalance",
                            this.state.originationBalance,
                            "required", {
                            messages: {
                              required: "* Borrowed Amount must be greater than zero"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="content-area-detail">
                    <div className="d-flex area-detail-inner">
                      <div className="cus-text flex-50">
                        <p>What is the current balance?</p>
                      </div>
                      <div className="cus-input-main flex-50 text-right">
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={currentBalance}
                          prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="text"
                          className="input-custom g-text-right-align"
                          id="current_balance_cents"
                          name="current_balance_cents"
                          onBlur={e => this.showValidationMessage(e)}
                          onValueChange={(values) => { this.handleCurrentBalanceChange(values) }}
                        />
                        {
                          this.validator.message(
                            "currentBalance",
                            this.state.currentBalance,
                            "required", {
                            messages: {
                              required: "* Current Balance can't be blank"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="content-area-detail">
                    <div className="d-flex area-detail-inner">
                      <div className="cus-text flex-50">
                        <p>What is the interest rate?</p>
                        <span className="font-12">Don't know the exact rate? Include an estimate.</span>
                      </div>
                      <div className="cus-input-main flex-50 text-right">
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={interestRate}
                          suffix="%"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={false}
                          type="text"
                          className="input-custom g-text-right-align"
                          id="interest_rate"
                          name="interest_rate"
                          onValueChange={(values) => { this.handleInterestRateChange(values) }}
                        />
                        {
                          this.validator.message(
                            "interestRate",
                            this.state.interestRate,
                            "required", {
                            messages: {
                              required: "* Interest Rate must be greater than zero"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="content-area-detail">
                    <div className="d-flex area-detail-inner">
                      <div className="cus-text flex-50">
                        <p>What is the minimum monthly payment?</p>
                      </div>
                      <div className="cus-input-main flex-50 text-right">
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          value={monthlyPayment}
                          prefix="$"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="text"
                          className="input-custom g-text-right-align"
                          id="monthly_payment_cents"
                          name="monthly_payment_cents"
                          onBlur={e => this.showValidationMessage(e)}
                          onValueChange={(values) => { this.handleMonthlyPaymentChange(values) }}
                        />
                        {
                          this.validator.message(
                            "monthlyPayment",
                            this.state.monthlyPayment,
                            "required", {
                            messages: {
                              required: "* Monthly Payment must be greater than zero"
                            }
                          }
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white mt-30">
                  <div className="btn-box">
                    <button onClick={this.handleCancel.bind(this)} className="c-button c-button-hollow c-button-primary c-button-small c-button-wide font-12">CANCEL</button>
                    <button onClick={this.handleSubmit.bind(this)} className="c-button  c-button-primary c-button-small c-button-wide font-12">SAVE</button>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
LoansNew.propTypes = {
  getAdvisorServicers: PropTypes.func.isRequired,
  getLoanType: PropTypes.func.isRequired,
  addLoan: PropTypes.func.isRequired,
  addMissingServicer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  advisorServicers: state.advisor.advisorServicers,
  loanTypeData: state.advisor.loanTypeData,
  addLoanData: state.advisor.addLoanData,
  addMissingServicerResponse: state.advisor.addMissingServicerResponse,
  errors: state.errors
});
export default connect(mapStateToProps, { getAdvisorServicers, getLoanType, addLoan, addMissingServicer })(LoansNew);