import getPartnerFromDomain from './get-partner-from-domain';

/**
 * Tells us if the passed in partner name is Citizens. For templating checking, refer to the
 * `app/helpers/is-citizens.js` helper.
 *
 * @param {string} partner
 * @return {Boolean}
 */
const isSchwab = partner => {
  const partnerName = partner || getPartnerFromDomain();
  const rexp = /(schwab)/i;
  const result = partnerName.match(rexp);
  return !!result;
};
export default isSchwab;
