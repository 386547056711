import moment from 'moment';
import camelcase from 'camelcase';
import * as Sentry from '@sentry/browser';
import repayment_calculator  from 'program_calculator';
//var repaymentCalculator = require('repayment_calculator');
//var repayment_calculator = require('program_calculator/build/repayment_calculator');
export function numberFormat( value ) {
  if(value!==""){
    //value=parseInt(value,10);
    if(!isNaN(value)){
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        //maximumFractionDigits:2
      }).format(value);
    }else{
      return "";
    }
  }else{
    return value
  }
}
export function payrollDatePlusOneSub(employer, unformatted = false){
  if(employer){
    const date = (employer.attributes.listed_in_current_invoice === false) ? employer.attributes.subsequent_payroll_date : employer.attributes.next_payroll_date;
    if (unformatted) {
      return payrollDatePlusOneDayUnformatted(date);
    }
    return payrollDatePlusOneDay(date);
  }
}
function payrollDatePlusOneDay(payrollDate) {
  return payrollDatePlusOneDayUnformatted(payrollDate).format('MMMM Do');
}
function payrollDatePlusOneDayUnformatted(payrollDate) {
  return moment.utc(payrollDate).add(1, 'days');
}
export function formattedServicers (servicersData){
  return servicersData;
}
export function statusText(status, settled_at){
  /*if(status==='pending' || status==='funded' || status==='exported' || status==='scheduled'){
    return 'Sending...';  
  }*/
  switch (status) {
    case 'pending':
    case 'funded':
    case 'exported':
    case 'scheduled':
      return 'Sending...';
    case 'failed':
      return 'Under Review';
    case 'remitted':
      return 'Sent!';
    case 'settled':
    case 'verified':
    case 'resolved':
    default:
      return `Paid -${_buildSettledAtDate(settled_at)}`;
    }  
}
function _buildSettledAtDate(settled_at) {
  const date = moment(settled_at);
  const year = date.year().length === 2 ? `20${date.year()}` : date.year();
  return date.year(year).format('MMMM D, YYYY');
}
export function monthsToHumanize(monthDiffs) {
  if (monthDiffs === 0){
    return null;
  }
  if (monthDiffs === undefined){
    return 'never';
  }
  const diff = Math.abs(monthDiffs);
  const years  = parseInt(diff / 12);
  const months = diff % 12;
  return `${years}yr ${months}m`;
}
export function splitUpperCase(string){
  const results = string.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
  return results;
}
export function convertHyphensCamelCaseCustomerCustom(customerObj){
  let customerData = customerObj.attributes
  let customer     = toCamel(customerData)
  customer         = renameKeysCustomer(customer, [['extraPaymentCents', 'extraPayment'], ['salaryCents', 'salary']]);
  return customer;
}
export function convertHyphensCamelCaseLoansCustom(loansObj){
  let loans = [];
  loansObj.forEach(item => {
    let loansData = toCamel(item.attributes);
    loansData = renameKeysLoans(loansData,[['currentBalanceCents','balance'],['monthlyPaymentCents','monthlyPayment'],['originationBalanceCents','originationBalance']]);
    loansData.loanId = item.id; 
    loans.push(loansData);
  })
  return loans; 
}
export function convertHyphensCamelCaseLoansEligibilityCustom(loansObj){
  let loans = [];
  loansObj.forEach(item => {
    if(typeof item ==='object'){
      item.origination_balance_cents = item.origination_balance_cents ===null ? 0 : item.origination_balance_cents;
      let loansData = toCamel(item); 
      loansData = renameKeysLoans(loansData,[['currentBalanceCents','balance'],['monthlyPaymentCents','monthlyPayment'],['originationBalanceCents','originationBalance'],['eligibilityResultId','program'],['customerId','customer']]);
      loansData.loanId = item.id; 
      loans.push(loansData);
    }
  })
  return loans; 
}
function toCamel(o) {
  var newO, origKey, newKey, value
  newO = {}
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = camelcase(origKey);
      value = o[origKey]
      newO[newKey] = value
    }
  }
  return newO
}
export function calculatorTranslatorCustomer(customer){
  let jsCustomer = convertCentsToDollars(customer);
  jsCustomer     = renameKeysCustomer(jsCustomer, [['agiCents', 'agi'], ['spouseAgiCents', 'spouseAgi'], ['spouseFederalLoanDebtCents', 'spouseDebt']]);
  return jsCustomer;
}
export function renameKeysCustomer(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object'){
      delete Object.assign(obj, {[item[1]]: obj[item[0]] })[item[0]];
    }
  });
  return obj;
}
/*export function renameKeysCustomerNew(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object'){
      Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
      delete obj[item[0]];
    }
  });
  return obj;
}*/
export function renameKeysLoans(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object'){
      Object.getOwnPropertyDescriptor(obj, item[0]) && Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
      delete obj[item[0]];
      //delete Object.assign(obj, {[item[1]]: obj[item[0]] })[item[0]];
    }
  });
  return obj;
}
export function renameKeysLoansResult(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object'){
      Object.getOwnPropertyDescriptor(obj, item[0]) && Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
      delete obj[item[0]];
      //delete Object.assign(obj, {[item[1]]: obj[item[0]] })[item[0]];
    }
  });
  return obj;
}
export function renameKeysLoansResultWithMatch(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    if(typeof obj ==='object'){
      //Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
      //delete Object.assign(obj, {[item[1]]: obj[item[0]] })[item[0]];
    }
  });
  return obj;
}

export function calculatorProgramResult(customerData, loans, programName, extraPayment = 0){

  const defArray = loans.toArray ? loans.toArray() : loans; 
  let jsLoans = convertCentsToDollars(defArray.map(loan => loan));
  jsLoans = jsLoans.map(loan => {
    return renameKeysLoansResult(loan, [['monthlyPayment', 'minimumPayment'], ['interestRate', 'rate'], ['loanType', 'type'], ['originationDate', 'startDate']]);
  });
  customerData.loans = jsLoans;
  const data = calculateResult(customerData, { program: programName, currentExtraPayment: extraPayment });
  if(data){
    data['name'] = programName;
  }
  return data;
}
export function calculatorProgramResultWithMatch(customerData, loans, programName, extraPayment = 0){
  //console.log(JSON.stringify(jsLoans))
  /*const defArray = loans.toArray ? loans.toArray() : loans; 
  let jsLoans = convertCentsToDollars(defArray.map(loan => loan));
  jsLoans = jsLoans.map(loan => {
    return renameKeysLoansResultWithMatch(loan, [['monthlyPayment', 'minimumPayment'], ['interestRate', 'rate'], ['loanType', 'type'], ['originationDate', 'startDate']]);
  });
  
  customerData.loans = jsLoans;*/
  customerData.loans = loans;
  const data = calculateResultWithMatch(customerData, { program: programName, currentExtraPayment: extraPayment });
  if(data){
    data['name'] = programName;
  }
  return data;
}
export function calculatorProgramResultExisting(customerData, loans, programName, extraPayment = 0){

  const defArray = loans.toArray ? loans.toArray() : loans; 
  let jsLoans = convertCentsToDollars(defArray.map(loan => loan));
  jsLoans = jsLoans.map(loan => {
    return renameKeysLoans(loan, [['monthlyPayment', 'minimumPayment'], ['interestRate', 'rate'], ['loanType', 'type'], ['originationDate', 'startDate']]);
  });
  customerData.loans = jsLoans;
  const data = calculateResultExisting(customerData, { program: programName, currentExtraPayment: extraPayment });
  if(data){
    data['name'] = programName;
  }
  return data;
}
export function calculateResult(customerData, options = {}){
  const opts = Object.assign({ program: 'Existing'}, options);
  let result = null;
  try {
    Sentry.configureScope(scope => {
      result = scope.setExtra({ customer: customerData, options });
    })
    const dt = repayment_calculator(customerData, opts);
    return dt
  } catch (error) {
    //console.log(error)
    // do nothing
  }
  return result;
}
export function calculateResultWithMatch(customerData, options = {}){
  const opts = Object.assign({ program: 'Existing'}, options);
  let result = null;
  try {
    Sentry.configureScope(scope => {
      result = scope.setExtra({ customer: customerData, options });
    })
    const dt = repayment_calculator(customerData, opts);
    return dt
  } catch (error) {
    //console.log(error)
    // do nothing
  }
  return result;
}
export function calculateResultExisting(customerData, options = {}){
  const opts = Object.assign({ program: 'Existing'}, options);
  let result = null;
  try {
    Sentry.configureScope(scope => {
      result = scope.setExtra({ customer: customerData, options });
    })
    const dt = repayment_calculator(customerData, opts);
    return dt
  } catch (error) {
    //console.log(error)
    // do nothing
  }
  return result;
}
export function renameKeys(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach(item => {
    Object.defineProperty(obj, item[1], Object.getOwnPropertyDescriptor(obj, item[0]));
    //delete obj[item[0]];
  });
  return obj;
}
export function convertCentsToDollars(objOrArray) {
  if (objOrArray.length !== undefined) {
    return objOrArray.map(item => _convertToCents(item));
  } else {
    return _convertToCents(objOrArray);
  }
}
export function _convertToCents(obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'number' && key !== 'familySize' && key !=='interestRate' && key !=='creditScore' ) {
      obj[key] = +(obj[key] / 100).toFixed(5);
    }
    if(key === 'lowBalanceFactor'){
      obj[key] = +(obj[key] / 100).toFixed(5);
    }
    if(key === 'interestRate'){
      obj[key] = +(obj[key] / 100).toFixed(5);
    }
    
  });
  return obj;
}
