import React, { Component } from 'react';
import { showProfileDropdown } from '../Helpers/navBarFeatures';
import { Nav, NavDropdown } from 'react-bootstrap';

import { CaretDownFill, CaretUpFill} from 'react-bootstrap-icons';
import s from '../Header.module.css'

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileDropdown: false,
      showDropDownUser: false
    }
  }

  componentDidMount() {
    const showProfileDropdownMenu = showProfileDropdown();
    let pathname = window.location.pathname;
    const expandDropDownOnLinks = ["/profile/about", "/invite", "/terms/about"];
    this.setState({
      showProfileDropdown: showProfileDropdownMenu,
      showDropDownUser: !!expandDropDownOnLinks.includes(pathname)
    })
  }
  render() {
    const { showProfileDropdown } = this.state;
    const nameOfUser = localStorage.getItem("customerFullName") ? localStorage.getItem("customerFullName").split(' ')[0].toUpperCase() : "USERNAME"
    let pathname = window.location.pathname;
    const { logoNameAndLogoutShowOnly, hideNotificationAndProfileDropdown } = this.props;
    return (
      <>
        {!hideNotificationAndProfileDropdown && !logoNameAndLogoutShowOnly && showProfileDropdown ?
          <>
            <div className='G-header-dropDown-container'>
              <button className='cancelLink' onClick={() => this.setState({ showDropDownUser: !this.state.showDropDownUser })}>
                {nameOfUser}
                {this.state.showDropDownUser ?
                  <CaretUpFill size={25} className='ml-2 mb-1' />
                  : <CaretDownFill size={25} className='ml-2 mb-1' />
                }
              </button>
              <div className={s.dropDownMenu + ' ' + (this.state.showDropDownUser ? s.dropDownMenuActive : '')}
              >
                {localStorage.getItem("showProfile") === 'true' ?
                  <a href="/profile/about" className={pathname === "/profile/about" ? 'active' : ''}>MY PROFILE</a>
                  : null}
                {localStorage.getItem("canInvite") === 'true' ?
                  <a href="/invite" className={pathname === "/invite" ? 'active' : ''}>ADD A FAMILY MEMBER</a>
                  : null}
                <a href="/terms/about" className={pathname === "/terms/about" ? 'active' : ''}>TERMS OF SERVICE</a>
              </div>
            </div>
            <NavDropdown title={nameOfUser} className='notification-main-container'>
              {localStorage.getItem("showProfile") === 'true' ?
                <NavDropdown.Item href="/profile/about" className={pathname === "/profile/about" ? 'activeLink' : ''}>MY PROFILE</NavDropdown.Item>
                : null}
              {localStorage.getItem("canInvite") === 'true' ?
                <NavDropdown.Item href="/invite" className={pathname === "/invite" ? 'activeLink' : ''}>ADD A FAMILY MEMBER</NavDropdown.Item>
                : null}
              <NavDropdown.Item href="/terms/about" className={pathname === "/terms/about" ? 'activeLink' : ''}>TERMS OF SERVICE</NavDropdown.Item>
            </NavDropdown>
          </>
          :
          <>
            <Nav.Link className='header-username' href="#">
              {nameOfUser}
            </Nav.Link>
          </>
        }
      </>
    )
  }
}

export default (UserProfile);