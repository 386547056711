import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { fetchEmployer } from '../../actions/employerActions';
import { DtiCalculatorSvg, Tuition529Svg, CollegeSavingSvg, TaxStateSvg } from './TRAdviceAndTools.constant';

class TRAdviceAndTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      employerData: null
    }
  }
  componentDidMount() {
    document.title = "Vault | Advice & Tools";
    const { fetchEmployer } = this.props;
    fetchEmployer()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.employerData !== this.props.employerData) {
      this.setState({
        employerData: this.props.employerData
      })
    }
  };
  render() {
    const { employerData } = this.state;
    const quatroWidget = employerData ? employerData.attributes.quatro_widget : "";
    const hasSavingsPlanOnly = localStorage.getItem('hasSavingsPlanOnly') ?? 'false';
    const savingsPlanEnabled = localStorage.getItem('savingsPlanEnabled') ?? 'false';

    return (
      <div className='mt-5'>
      
        <h4 >Explore Vault Resources</h4>
        <p className="sans-medium">
          Vault is here to provide you with access to resources you can use <br />
          for your own financial education purposes, whether you're<br />
          paying down student loans, looking for repayment programs or<br />
          planning for college.
        </p>

        <div className='G-grid11-2 mt-5'>

          <div className='Gborder-05 p-4 rounded-lg bg-white'>
            <div className='Gb-pink-2 text-center'>
              <DtiCalculatorSvg />
            </div>
            <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>DTI Calculator</h3>
            <p className='sans-medium mb-4'>Find ways to lower your debt-to-income ratio en route to qualifying for better products.</p>
            <Link to="/dashboard/dti-calculator" className='G-cancel-button'>
              <button className='Gt-pink-8 bg-white border-0'>Calculate DTI</button>
            </Link>
          </div>
          {hasSavingsPlanOnly === 'false' && savingsPlanEnabled === 'false' &&
            <div className='Gborder-05 p-4 rounded-lg bg-white'>
              <div className='Gb-orange-2 text-center'>
                <Tuition529Svg />
              </div>
              <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>What’s a 529?</h3>
              <p className='sans-medium mb-4'>Read up on 529 basics to kick off college planning</p>
              <Link to="/dashboard/faqs" className='G-cancel-button'>
                <button className='border-0 bg-white Gt-pink-8'>College Planning 101</button>
              </Link>
            </div>
          }

          {quatroWidget === true &&
            <div className='Gborder-05 p-4 rounded-lg bg-white'>
              <div className='Gb-green-2 text-center'>
                <CollegeSavingSvg />
              </div>
              <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>College Savings</h3>
              <p className='sans-medium mb-4'>Read up on 529 basics to kick off college planning</p>
              <Link to="/dashboard/collegeplanning" className='G-cancel-button'>
                <button className='border-0 bg-white Gt-pink-8'>College Savings</button>
              </Link>

            </div>
          }

          {hasSavingsPlanOnly === 'true' || savingsPlanEnabled === 'true' ?
            <>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-blue-50 text-center'>
                  <TaxStateSvg />
                </div>
                <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>529 Tax Benefits by State</h3>
                <p className='sans-medium mb-4'>Explore each states 529 tax benefits to find the right choice for you.</p>
                <Link to="/dashboard/map" className='G-cancel-button'>
                  <button className='border-0 bg-white Gt-pink-8'>View States</button>
                </Link>
              </div>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-orange-2 text-center'>
                  <Tuition529Svg />
                </div>
                <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>What’s a 529?</h3>
                <p className='sans-medium mb-4'>Read up on 529 basics to kick off college planning</p>
                <Link to="/dashboard/faqs" className='G-cancel-button'>
                  <button className='border-0 bg-white Gt-pink-8'>College Planning 101</button>
                </Link>
              </div>
            </>
            : ''
          }

        </div>
      </div>
    )
  }
}
TRAdviceAndTools.propTypes = {
  fetchEmployer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  employerData: state.employer_array.employerData,
  errors: state.errors
})
export default connect(mapStateToProps, { fetchEmployer })(TRAdviceAndTools);
