import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";

import { responseMessage } from '../../../utils/alert';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";

import PaymentOverview from "./PaymentOverview";
import PaymentSummary from "./PaymentSummary";

import { fetchCustomer } from '../../../actions/customerActions';
import { fetchEmployer } from '../../../actions/employerActions';
import { getServicerAccounts, postEditServicerAccounts, getDisbursements } from '../../../actions/fiveTwoNineActions';
import { finicityConnectSuccess, getFinicityConnectLink } from '../../../actions/advisorActions';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import finicityConnect from "../../../helpers/FinicityConnect";

class PayOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      employer: null,
      servicerAccounts: [],
      fauxFinicityModalEnabled: false,
      fauxFinicityModalSelectedServicerAccountId: null,
      disbursements: [],
      errors: null,
      numberOfPayActiveServicerAccounts: 0
    }
    this.validator = new SimpleReactValidator({
      element: message => {
        return <p className="text-danger">{message}</p>;
      },
      autoForceUpdate: this
    });
  }
  componentDidMount() {
    document.title = "Vault Advisor"
    const { fetchCustomer, fetchEmployer, getServicerAccounts, getDisbursements } = this.props;
    fetchCustomer();
    fetchEmployer();
    getServicerAccounts();
    getDisbursements();
    intercomTrackEvent(eventNames.visitedPayOverview);

    this.setState({
      numberOfPayActiveServicerAccounts: this?.props?.customer?.attributes?.number_of_pay_active_servicer_accounts
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer,
        numberOfPayActiveServicerAccounts: this?.props?.customer?.attributes?.number_of_pay_active_servicer_accounts
      })
    }
    if (prevProps.employer !== this.props.employer) {
      this.setState({
        employer: this.props.employer
      })
    }
    if (prevProps.servicerAccounts !== this.props.servicerAccounts) {

      const servicerAccountsData = this.props.servicerAccounts.data;
      let servicer_accounts = servicerAccountsData.filter(function (value) {
        return value.attributes.pay_active === true
      });

      if (servicer_accounts.length > 0) {
        const servicer_accounts_included = this.props.servicerAccounts.included;

        let servicers_array = servicer_accounts_included.filter(function (value) {
          return value.type === 'servicers'
        });
        let servicers_addresses_array = servicer_accounts_included.filter(function (value) {
          return value.type === 'addresses'
        });

        for (var i = 0; i < servicer_accounts.length; i++) {
          const servicerId = servicer_accounts[i]['relationships']['servicer']['data']['id'];
          var itemServicer = servicers_array.find(item => item.id === servicerId);
          servicer_accounts[i]['servicer'] = itemServicer;

          if (servicer_accounts[i]['relationships']['address']['data'] !== null) {
            const addressId = servicer_accounts[i]['relationships']['address']['data']['id'];
            var itemAddress = servicers_addresses_array.find(item => item.id === addressId);
            servicer_accounts[i]['address'] = itemAddress;
          }
        }
        this.setState({
          servicerAccounts: servicer_accounts
        })
      }
    }
    if (prevProps.editServicerAccountsResponse !== this.props.editServicerAccountsResponse) {
      if (this.state.fauxFinicityModalEnabled === false) {
        const userPausePayments = this.props.editServicerAccountsResponse.data.attributes.user_pause_payments;
        window.$('#pausingPaymentModal').modal('hide');
        let msg = "You have enabled payments to this account";
        if (userPausePayments === true) {
          msg = "You have stopped payments to this account"
        }
        responseMessage("success", msg, "");
        const { fetchCustomer, fetchEmployer, getServicerAccounts } = this.props;
        fetchCustomer();
        fetchEmployer();
        getServicerAccounts();
      }
    }
    if (prevProps.finicityConnectResponse !== this.props.finicityConnectResponse) {
      this.setState({
        'fauxFinicityModalEnabled': null
      })
      const { fetchCustomer, fetchEmployer, getServicerAccounts } = this.props;
      fetchCustomer();
      fetchEmployer();
      getServicerAccounts();
    }
    if (prevProps.disbursements !== this.props.disbursements) {
      this.setState({
        disbursements: this.props.disbursements
      })
    }
  }
  handlerSetupContribution = (e) => {

  }
  getStarted = (e) => {
    this.props.history.push({
      pathname: '/pay/accounts'
    })
  }

  enableServicerAccountPauseReason = (e, selectedServicerAccount) => {
    selectedServicerAccount.attributes.user_pause_payments = false
    const requestData = {
      'data': selectedServicerAccount
    }
    this.props.postEditServicerAccounts(requestData);
  }
  submitServicerAccountPauseReason = (selectedServicerAccount) => {
    const requestData = {
      'data': selectedServicerAccount
    }
    this.props.postEditServicerAccounts(requestData);
  }

  clearFinicityState = () => {
    this.setState({
      loadingFinicityLink: false,
      initiatingServicerId: null
    })
  }

  onSubmitFinicity = (id) => {
    const { finicityConnectSuccess, getServicerAccounts } = this.props;
    const jsonRequest = {
      'servicer_account_id': id
    }
    finicityConnectSuccess(jsonRequest);
    this.timerID = setTimeout(() => {
      getServicerAccounts();
    }, 1000);
  }

  requestFinModal = (e, servicerAccount) => {
    const { employer } = this.props;
    if (employer.attributes.faux_finicity_enabled) {
      this.setState({
        fauxFinicityModalEnabled: true,
        fauxFinicityModalSelectedServicerAccountId: servicerAccount.id
      })
    } else {
      servicerAccount.id && this.props.getFinicityConnectLink(servicerAccount.id);
      this.timerID = setTimeout(() => {
        this.props.finicityConnectLink && finicityConnect(this.props.finicityConnectLink, this.clearFinicityState, this.onSubmitFinicity, servicerAccount.id);
      }, 2000);
    }
  }
  onSubmitFauxFinicity = (e) => {
    const servicerAccountId = this.state.fauxFinicityModalSelectedServicerAccountId;
    const jsonRequest = {
      'servicer_account_id': servicerAccountId
    }
    this.props.finicityConnectSuccess(jsonRequest);
  }
  onCancelFauxFinicity = (e) => {
    this.setState({
      fauxFinicityModalSelectedServicerAccountId: null,
      fauxFinicityModalEnabled: null
    })
  }
  render() {
    const { customer, employer, servicerAccounts, fauxFinicityModalEnabled, disbursements, numberOfPayActiveServicerAccounts } = this.state;

    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container">

            {
              numberOfPayActiveServicerAccounts === 0 || numberOfPayActiveServicerAccounts === undefined ?
                <PaymentOverview
                  customer={customer}
                  employer={employer}
                  getStarted={this.getStarted.bind(this)}
                  handlerSetupContribution={this.handlerSetupContribution.bind(this)}
                />
                :
                <PaymentSummary
                  servicerAccounts={servicerAccounts}
                  customer={customer}
                  employer={employer}
                  fauxFinicityModalEnabled={fauxFinicityModalEnabled}
                  requestFinModal={this.requestFinModal.bind(this)}
                  onSubmitFauxFinicity={this.onSubmitFauxFinicity.bind(this)}
                  onCancelFauxFinicity={this.onCancelFauxFinicity.bind(this)}
                  enableServicerAccountPauseReason={this.enableServicerAccountPauseReason.bind(this)}
                  submitServicerAccountPauseReason={this.submitServicerAccountPauseReason.bind(this)}
                  disbursements={disbursements}
                />
            }
          </div>
        </div>

        <Footer />
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    customer: state.customer_array.customerData,
    employer: state.employer_array.employerData,
    servicerAccounts: state.five_two_nine.getServicerAccountsData,
    editServicerAccountsResponse: state.five_two_nine.servicerAccountsData,
    finicityConnectResponse: state.advisor.finicityConnectResponse,
    finicityConnectLink: state.advisor.finicityConnectLink?.data?.attributes?.link,
    disbursements: state.five_two_nine.disbursements
  };
}
const mapDispatchToProps = {
  fetchCustomer,
  fetchEmployer,
  getServicerAccounts,
  postEditServicerAccounts,
  finicityConnectSuccess,
  getFinicityConnectLink,
  getDisbursements
}
export default connect(mapStateToProps, mapDispatchToProps)(PayOverview);