import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Modal, } from 'react-bootstrap';
import S from './TransactionsTable.module.css'

import { sendEmailFeedback } from '../../../actions/advisorActions';

import { sumOfApprovedTransactions } from "../match";
import { CheckCircle, DashCircle, PlusCircle, XLg } from 'react-bootstrap-icons';
import { numberFormat } from '../../../common/js/jsFunctions';
import ValidatorMessage from '../../shared/ValidatorMessage/ValidatorMessage';
import SimpleReactValidator from 'simple-react-validator';


const FinicityTransactionsTable = (props) => {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const feedbackRes = useRef(props.mailFeedbackResponse);
  const [expandTable, setExpandTable] = useState(false)

  const [show, setShow] = useState(false);
  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  const servicerAccount = props.servicerAccount;
  // const isFinicityLinked = servicerAccount.is_finicity_linked;
  //const isFinicitySynced = servicerAccount.is_finicity_synced;
  // const finicityActive = servicerAccount.finicity_active;
  // const transactions = fincityTransactionsSorted(servicerAccount.transactions);
  const transactions = props.transactions.filter((e) => {
    return e.attributes.servicer_account_id === servicerAccount.servicer_account_id && e.attributes.source_type ===
      "Servicer";
  })
  const totalPaid = numberFormat(sumOfApprovedTransactions(transactions));
  function submitMissingPaymentFeedback(e) {
    const formValid = validator.current.allValid()
    if (!formValid) {
      validator.current.showMessages()
      forceUpdate(1)
    } else {
      const paySupportEmail = props.customer.attributes.pay_support_email;
      const source = "source:Match\nabout:Customer inquiry about missing transaction(s)\n\n" + feedback;

      const feedbackJson = {
        "mail_to": paySupportEmail,
        "feedback": source
      }
      props.sendEmailFeedback(feedbackJson);
    }
  }
  useEffect(() => {
    if (feedbackRes.current !== props.mailFeedbackResponse) {
      //setShow(false);

      setShowSuccessMsg(true)
      setFeedback('');
    }
  }, [props.mailFeedbackResponse]);

  return (
    <>
  
      {
        transactions.length === 0 ?
          null
          :
          <>
          
            <div className={"Gborder-05 rounded-lg p-3 mt-4 " + (expandTable && S.responsiveContainerViewTransaction) + ' ' + (props.counterExpanded > 0 ? S.viewTransactionWidth : "")}>
              <div className="mt-2 G-cursor-pointer d-flex" onClick={() => { props.setCounterExpanded(expandTable ? props.counterExpanded - 1 : props.counterExpanded + 1); setExpandTable(!expandTable); }}>
                <label className="Gfs-125 sans-semibold mb-0 G-cursor-pointer ">
                  Finicity Transactions
                </label>
                <div className="ml-auto my-auto">
                  {
                    expandTable ?
                      <div className="ml-auto my-auto G-cursor-pointer">
                        <DashCircle size={22} />
                      </div>
                      :
                      <div className="ml-auto my-auto G-cursor-pointer" >
                        <PlusCircle size={22} />
                      </div>

                  }
                </div>

              </div>
              {/* body - show/hide */}
              {expandTable &&
                <>
                  <label className="Gfs-115 sans-semibold mb-0 my-3">
                    Total paid: {totalPaid}
                  </label>
                  <div>
                    <button onClick={() => { setShow(true); setShowTransactionTable(!showTransactionTable) }}
                      className="text-decoration-none cancelLink G-cursor-pointer Gt-pink-8">
                      Missing a payment? Click here to contact Vault
                    </button>
                  </div>

                  <div className="Gtable-div mt-4">
                    <Table borderless className="Gborder-05 G-table-theme-grey">
                      <thead className="Gt-head">
                        <tr>
                          <th className="Gfs-075 p-3">Id</th>
                          <th className="Gfs-075 p-3">Date</th>
                          <th className="Gfs-075 p-3">Amount</th>
                          <th className="Gfs-075 p-3">Source</th>
                          <th className="Gfs-075 p-3">Status</th>
                        </tr>
                      </thead>
                      <tbody className="Gt-body">
                        {transactions && transactions.length > 0 ?
                          transactions.map((transaction, index) => {
                            return (
                              <tr key={index}>
                                <td>{transaction.attributes.finicity_id}</td>
                                <td>{moment(transaction.attributes.date).format('MMM D, YYYY')}</td>
                                <td>
                                  <span data-tip data-for="approved">
                                    ${transaction.attributes.amount_cents / 100}
                                  </span>
                                  {!transaction.attributes.approved ?
                                    <ReactTooltip id="approved" place="top" effect="solid">
                                      Once this transaction is approved it will count towards your tracked payments
                                    </ReactTooltip>
                                    : null}
                                </td>
                                <td>{transaction.attributes.source_type}</td>
                                <td>{transaction.attributes.approved ? (<span className='Gt-green-6'>Approved</span>) : (<span className='Gt-yellow-6'>Awaiting Approval</span>)}</td>
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td colSpan={6}>No transactions found.</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </>
              }
            </div>

            <Modal size="lg" show={show} animation={false} backdrop="static" keyboard={false} scrollable={true}>
              <Modal.Body className='custom-modal-body'>
                <div className="d-flex">
                  <>
                    <div className='mr-3'>
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M42.5 22.5C42.5 21.125 41.375 20 40 20H20C18.625 20 17.5 21.125 17.5 22.5V37.5C17.5 38.875 18.625 40 20 40H40C41.375 40 42.5 38.875 42.5 37.5V22.5ZM40 22.5L30 28.75L20 22.5H40ZM40 37.5H20V25L30 31.25L40 25V37.5Z" fill="var(--icon-color)" />
                      </svg>
                    </div>
                  </>
                  <div className='w-100'>
                    <div className="d-flex">
                      <h4 className='py-3'>Missing a payment </h4>
                      <div className="ml-3">
                        <button
                          onClick={() => { setShow(false); setShowSuccessMsg(false) }} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg />
                        </button>
                      </div>
                    </div>
                    {/* body */}
                    {showSuccessMsg ?
                      <>
                        <p className='py-3 Gb-green-2 pl-3 rounded-lg sans-semibold w-100 '>
                          <CheckCircle size={24} className="Gt-green-6" />
                          &nbsp; Perfect! We have received your submission!
                        </p>
                        <div className="d-flex justify-content-end mob-btn-div-column mt-4">
                          <button className="G-cancel-button mx-3 mob-separate-v6" onClick={() => { setShow(false); setShowSuccessMsg(false) }}>
                            Close
                          </button>
                        </div>
                      </>
                      :
                      <>
                        <p className='sans-medium'>Our team will get back to you shortly. Thank You!</p>
                        <textarea
                          type="text"
                          name='feedback'
                          className="form-control"
                          placeholder="Give us as much information as you can about your missing transaction(s)."
                          onChange={(e) => { setFeedback(e.target.value); validator.current.showMessageFor('feedback') }}
                          value={feedback}
                          rows={6}
                        />
                        <ValidatorMessage validator={validator.current} fieldName='feedback' fieldValue={feedback} message="* Textarea can' t be blank" />
                        <div className="d-flex justify-content-end mob-btn-div-column mt-4">
                          <button className="G-cancel-button mx-3 mob-separate-v6" onClick={() => { setShow(false); setShowSuccessMsg(false) }}>
                            Cancel
                          </button>
                          <button className="G-orange-button mob-separate-v6" onClick={(e) => submitMissingPaymentFeedback(e)}>
                            Submit
                          </button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </Modal.Body>
            </Modal >

          </>
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    mailFeedbackResponse: state.advisor.emailFeedbackResponse
  };
}
const mapDispatchToProps = {
  sendEmailFeedback
}
export default connect(mapStateToProps, mapDispatchToProps)(FinicityTransactionsTable);