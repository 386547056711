import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./ErrorBoundary";
import sentryIgnoreErrorsList from "./helpers/sentryIgnoreErrorsList";
const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENABLED, REACT_APP_DEPLOY_TARGET_ENV } = process.env;

if (REACT_APP_SENTRY_ENABLED === "true" || REACT_APP_SENTRY_ENABLED === true) {
  Sentry.init({
    environment: REACT_APP_DEPLOY_TARGET_ENV,
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    ignoreErrors: sentryIgnoreErrorsList,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
