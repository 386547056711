import moment from 'moment';
import camelcase from 'camelcase';
import reverse from 'lodash/reverse';

import { getMonthsForPeriod } from './match-graph';

export function fincityTransactionsSorted(transactions_array){
  const transactions = transactions_array.filter(function(value) {
    return value.source_type === 'Servicer'
  }).sort(sortFunction); 
  return transactions;
}
function sortFunction(a,b){  
  var dateA = new Date(a.date).getTime();
  var dateB = new Date(b.date).getTime();
  return dateA < dateB ? 1 : -1;  
};
export function getStatements(statements_array){
  const map = new Map();
  const statements = statements_array.filter(s => {
    if (s.status && map.get(s.file_name)===undefined) {
      map.set(s.file_name, s);
      return s;
    }else{
     if (map.get(s.file_name)===undefined) {
        map.set(s.file_name, s);
        return s;
      }
      return false;
    } 
  }).filter(s=>{
   return s.hidden ===false;
  });
  return statements;
}
export function sumOfApprovedTransactionAmounts(transactions){
  let sum = 0;
  const approvedTransactions = _approvedTransactions(transactions);

  approvedTransactions.forEach(obj => {
    sum += obj.attributes.amount_cents / 100;
  })
  return sum;
}
function _approvedTransactions(transactions_array){
  const transactions = transactions_array.filter(item => {
    return item.attributes.approved ===true
  });
  return transactions;
}
export function sumOfApprovedTransactions (transactions_array){
  let sum = 0; 
  transactions_array.forEach(obj => {
    if(obj.attributes.approved===true){
      sum+=obj.attributes.amount_cents/100
    }
  })
  return sum;
}
export function isSyncingCheck(servicerAccounts,transactions){
  const hasNoTransactions = transactions.length === 0;
  const isSyncing = servicerAccounts.some(account => (
    // (account.is_finicity_linked || account.statements.length > 0)
    //   && account.transactions.length === 0
    account.is_finicity_linked && account.statements.length > 0 && account.transactions.length === 0
  ));
  return isSyncing && hasNoTransactions;
}

 /**
   * Customer salary * percentage. E.g. (55000*0.05)
   */
export function totalToPay(customer, salary) {
  const matchPercentage = customer.attributes.match_percentage;
  // given that we may be showing a match period that is not 12 months..
  // calculate accordingly

  // @type {Number} salary dollars
  // @type {Number} matchPercentage xx.xx
  const yearlyAmount = salary * (matchPercentage / 100);
  const startDates = {
    'matchInitialStartDate':customer.attributes.match_initial_start_date,
    'matchRecurringStartDate':customer.attributes.match_recurring_start_date
  }
  const { numberOfMonthsInPeriod/*, startingMonthNumber*/ } = getMonthsForPeriod(startDates);
  return (yearlyAmount * numberOfMonthsInPeriod) / 12;
}
export function getProgressBarPercentage( sumOfAmountsComputed,totalPay ){
  let percentage = (sumOfAmountsComputed * 100) / totalPay;
  if (percentage > 100) {
    percentage = 100;
  }
  return percentage.toFixed(2);
}

export function getTotalTransactionsAmount(servicerAccounts){
  let sum = 0;
  servicerAccounts.forEach(obj => {
    const transactions = obj.transactions.filter(transaction => {
      return transaction.approved && moment(transaction.date).year() === new Date().getFullYear();
    });
    transactions.forEach(obj => {
      sum+=obj.amount_cents/100; 
    });
  })
  return sum;
}
export function getSalaryPaidPercentage(totalTransactionsAmount, salary){
  const value = (totalTransactionsAmount * 100) / salary;
  return `${value.toFixed(2)}%`;
}
 /*
 * Salary for the current match period. We assume after the initial enrollment, the next full period will be for
 * 12months.
 */
export function getSalaryForMatchPeriod(customer, salary) {
  if (salary==="") { return '--'; }
  // salary - 12mo
  // x      - `numberOfMonthsInPeriod ` months of current match period
  // (salary * numberOfMonthsInPeriod ) / 100
   const startDates = {
    'matchInitialStartDate':customer.attributes.match_initial_start_date,
    'matchRecurringStartDate':customer.attributes.match_recurring_start_date
  }
  const { numberOfMonthsInPeriod/*, startingMonthNumber*/ } = getMonthsForPeriod(startDates);
  if (numberOfMonthsInPeriod==="") {
    return salary;
  } 
  return (salary * numberOfMonthsInPeriod) / 12;
}
export function getSalaryPaidPercentageForPeriod(salaryPaidPercentage,totalTransactionsAmount,salaryForMatchPeriod){
  if (salaryPaidPercentage==="" || totalTransactionsAmount==="" || salaryForMatchPeriod==="") { return '--'; }
  const value = (totalTransactionsAmount * 100) / salaryForMatchPeriod;
  return `${value.toFixed(2)}%`;
}
export function getTransactionsForThisYear(transactions){
  /*let transactions = [];
  servicerAccounts.forEach(obj => {
    if(obj.transactions.length >0){
      const transactionsData = obj.transactions.filter(transaction => {
        return transaction.approved && moment(transaction.date).year() === new Date().getFullYear();
      });
      console.log(JSON.stringify(transactionsData));
      transactions.push(transactionsData);
    }
  })
  return transactions;*/

  let transactionsData = [];
  transactions.forEach(transaction => {
    transaction = transaction.attributes 
    if (transaction.approved && moment(transaction.date).year() === new Date().getFullYear()) {
      transaction.amount = transaction.amount_cents;
      transactionsData.push(toCamel(transaction));
    }
  })
 return transactionsData;
}
function toCamel(o) {
  var newO, origKey, newKey, value
  newO = {}
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = camelcase(origKey);
      value = o[origKey]
      newO[newKey] = value
    }
  }
  return newO
}
export function getFormattedTransactions(servicerAccounts){
  /*const nestedTransactions = servicerAccounts.map(servicerAccount => {
    const transactions       = servicerAccount.transactions
    const sortedTransactions = transactions.sort(sortFunction);
    return sortedTransactions.map(transaction => ({
      date: moment(transaction.date).format('MM/DD/YY')
    })).filter(obj => obj.amount > 0);
  });*/
  let transactions = [];
  servicerAccounts.forEach(servicerAccount => {
    const transactionsData = servicerAccount.transactions;
    const sortedTransactions = transactionsData.sort(sortFunction);
    sortedTransactions.forEach(transaction =>{
      const jsonTransaction = {
        date: moment(transaction.date).format('MM/DD/YY'),
        servicer: servicerAccount.servicer.friendly_name,
        source: transaction.source_type,
        amount: transaction.amount_cents/100,
        status: transaction.approved ? 'Approved' : 'Awaiting Approval'
      }
      transactions.push(jsonTransaction);
    });
  });
  return transactions.filter(obj => obj.amount > 0);
}
export function getTotalPaidForStatement(transactions, statementId){
  return transactions.filter(item => {
    if(item.attributes.statement_id ===statementId){
      return item
    }
    return false;
  }).reduce((total, sa) => total + sa.attributes.amount_cents/100, 0);
}
export function getTransactionsForStatement(transactions_array,statementId){
  const transactions = transactions_array.filter(function(value) {
    return value.attributes.statement_id === statementId
  }).sort(sortFunction); 
  return reverse(transactions);
}
export function sumOfAmountsComputedTransactions(servicerAccounts){
  let sum = 0;
  servicerAccounts.forEach(obj => {
    const approvedTransactions = obj.transactions.filter(item => {
      return item.approved ===true && moment(item.date).year() === new Date().getFullYear();
    });
    approvedTransactions.forEach(obj => {
      sum+=obj.amount_cents/100; 
    })
  })
  return sum;
}
