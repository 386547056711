import S from './VaultLogo.module.css'

const VaultLogo = ({ x = -30, y = 0, width = 650, height = 230 }) => {
  return (
    <div className={`${S.vaultMatchLogo}`} >
      <svg viewBox={`${x} ${y} ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M273.8 65L247.5 145.7L221 65H198L236.3 179.5H258.6L296.8 65H273.8Z" fill="#FF7843" />
        <path d="M348 107.6C342.7 102 334.6 98.2001 324.2 98.2001C306.2 98.2001 285.4 111.4 285.4 139.7C285.4 167.8 306.9 181.2 323.8 181.2C334.2 181.2 342.6 176.9 347.9 170.7V179.6H369.8V99.8001H347.9V107.6H348ZM328.2 161C316.1 161 306.8 151.7 306.8 139.5C306.8 127.2 316.1 118.1 328.2 118.1C340 118.1 348.6 127.7 348.6 139.5C348.6 151.3 340 161 328.2 161Z" fill="#FF7843" />
        <path d="M433 143.3C433 156.7 426.1 162.4 417.8 162.4C408.1 162.4 403.1 156.2 403.1 145.5V99.8H380.9V148.4C380.9 169 392.2 181.1 411 181.1C420.1 181.1 427.7 177.1 433 170.7V179.5H454.7V99.8H432.8V143.3H433Z" fill="#FF7843" />
        <path d="M487.9 65H466V179.5H487.9V65Z" fill="#FF7843" />
        <path d="M534.1 144.7V119.4H549V99.8H534.1V65L518.8 73.4C514.7 75.6 512.2 79.9 512.2 84.5V99.8H498.6V119.4H512.2V144.9C512.2 170.9 520.5 179.5 545.9 179.5H549.1V159.1C535.7 159.1 534.1 157.2 534.1 144.7Z" fill="#FF7843" />
        <path d="M79.0188 60.1603C84.8328 70.6132 99.3678 70.6132 105.29 60.1603L115.626 41.8119L125.962 23.4636C131.776 13.0106 124.454 0 112.826 0H112.719C107.335 0 102.275 3.00245 99.5832 7.78414L79.0188 44.4808C76.3271 49.3737 76.3271 55.2674 79.0188 60.1603Z" fill="#FF7843" />
        <path d="M67.7003 40.9316L68.9159 43L94 0H91.6794H70.5734C58.6391 0 51.1249 12.7367 57.0921 22.9696L67.7003 40.9316Z" fill="#FF7843" />
        <path d="M45.641 78.3779C48.3804 83.0601 53.5305 86 59.0093 86H59.5572C71.3914 86 78.8426 73.2602 72.9255 63.0248L61.9679 44.2962L51.1198 25.6765C45.2027 15.4412 30.41 15.4412 24.3833 25.6765L24.0546 26.221C21.3151 31.012 21.3151 36.783 24.0546 41.5741L45.641 78.3779Z" fill="#FF7843" />
        <path d="M15.6277 86H37.5628H40L14.1875 43L12.9689 44.9302L2.11208 63.3741C-3.87023 73.4539 3.55226 86 15.6277 86Z" fill="#FF7843" />
        <path d="M58.2875 124.892C63.7458 124.892 68.8766 121.965 71.6058 117.304L71.9333 116.871C77.8282 106.682 70.4049 94 58.615 94H37H15.3851C3.59509 94 -3.82823 106.682 2.06676 116.871L2.39426 117.413C5.12341 122.182 10.2542 125 15.7126 125L58.2875 124.892Z" fill="#FF7843" />
        <path d="M62 133L12 133.111L13.1879 135.115L23.8791 154.152C29.7106 164.616 44.2894 164.616 50.2289 154.152L60.9201 135.115L62 133Z" fill="#FF7843" />
        <path d="M104.941 121.125L104.612 120.689C98.6824 110.437 83.859 110.437 77.8198 120.689L66.9493 139.338L56.0788 157.988C50.1494 168.24 57.6161 181 69.4748 181H70.1336C75.6238 181 80.7845 178.055 83.5296 173.366L104.941 136.503C107.686 131.704 107.686 125.924 104.941 121.125Z" fill="#FF7843" />
        <path d="M115.894 139.08L114.672 137L89 181H91.3338H113.338C125.34 181 132.897 168.194 126.896 157.905L115.894 139.08Z" fill="#FF7843" />
        <path d="M137.865 101.634C135.171 96.9447 130.108 94 124.721 94H124.183C112.548 94 105.222 106.76 111.04 117.012L121.705 135.662L132.37 154.311C138.188 164.563 152.732 164.563 158.657 154.311L158.98 153.766C161.673 148.967 161.673 143.187 158.98 138.388L137.865 101.634Z" fill="#FF7843" />
        <path d="M167.381 94H145.438H143L168.711 138L169.93 136.025L180.902 117.262C186.886 106.838 179.35 94 167.381 94Z" fill="#FF7843" />
        <path d="M180.606 63.3684C177.877 58.7368 172.746 56 167.287 56H124.713C119.254 56 114.123 58.8421 111.394 63.3684L111.067 63.7895C105.172 73.6842 112.595 86 124.385 86H146H167.615C179.405 86 186.828 73.6842 180.933 63.7895L180.606 63.3684Z" fill="#FF7843" />
        <path d="M121 47L171 46.8925L169.812 44.9578L159.121 26.5777C153.289 16.4741 138.711 16.4741 132.771 26.5777L122.08 44.9578L121 47Z" fill="#FF7843" />
      </svg>
    </div>
  )
}

export default VaultLogo
