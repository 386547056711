import React from 'react'
import S from './ImageComponent.module.css'

const ImageComponent = ({ index, id, imageUrl, mainTitle, mainContext }) => {
  const type = index & 1 ? '' : S.reverse;
  return (
    <div className='my-5'>
      <div className={`G-grid11-2 ${type}`}>
        <div className='orderDiv1'>
          <img className='w-100' src={imageUrl} alt={`${id}`} />
        </div>
        <div className={`my-auto orderDiv2 pr-2`}>
          <h4>{mainTitle}</h4>
          <p className='sans-medium'>{mainContext}</p>
        </div>
      </div>
    </div>
  )
}

export default ImageComponent