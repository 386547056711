import S from './Breadcrumb.module.css';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
const Breadcrumb = ({ list }) => {
    const [responsiveClass, setrResponsiveClass] = useState('')
    useEffect(() => {
        const totalLength = list.reduce((sum, current) => sum + current?.title?.length, 0);

        if (totalLength > 45) {
            setrResponsiveClass('breadcrumb-column')
        }
    }, [list])

    return (
        <ul className={`d-flex mb-0 pb-4 ${responsiveClass} ${S.adjustGap}`}>
            {list.map((item, i) => {
                return <li key={i} className={item.active ? `${S.active}` : `${S.notActive}`}>
                    <Link className="Ga" to={item.url}>
                        {item.title}
                    </Link>
                    {item.active ? '' : <ChevronRight className={S.adjustSvg} />}
                </li>
            })}
        </ul>
    );
}

export default Breadcrumb;