import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
} from 'react-bootstrap';

import './StudentLoan.css';
import { fetchCustomer } from '../../../actions/customerActions';
import { getLoans, getEligibilityResults } from '../../../actions/fiveTwoNineActions';
import { monthsToHumanize, numberFormat, splitUpperCase, convertHyphensCamelCaseCustomer, convertHyphensCamelCaseLoans, calculateProgramResults } from '../../../common/js/jsFunctions';
import { Link, Redirect } from "react-router-dom";
import { Pencil } from "react-bootstrap-icons";
import { useRef } from "react";
import FeedbackModal from "../../shared/FeedbackModal/FeedbackModal";
import { FeedbackRouteName } from "../../../helpers/translations";
import { doesNotHaveSatisfactionDataForThisType, userCanLookAtFeedbackModal } from "../../../helpers/validations";
import { getSatisfactionFeedback } from "../../../actions/advisorActions";

const StudentLoan = (props) => {
  const [eligibilityResults, setEligibilityResults] = useState({});
  const [customer, setCustomer] = useState([]);
  const [loansArray, setLoansArray] = useState([]);
  const [redirection, setRedirection] = useState(false);
  const [tableData, setTableData] = useState([]);
  const loanCounter = useRef(false);

  useEffect(() => {
    const { fetchCustomer, getLoans, getEligibilityResults, getSatisfactionFeedback } = props;
    getLoans();
    fetchCustomer();
    getEligibilityResults();
    getSatisfactionFeedback();
  }, [])

  useEffect(() => {
    if(props.satisfactionData.length) localStorage.setItem('satisfactionData', JSON.stringify(props.satisfactionData));
  },[props.satisfactionData])

  useEffect(() => {
    if (Object.keys(props.customer).length !== 0) {
      let customerData = convertHyphensCamelCaseCustomer(props.customer);
      setCustomer(customerData);
    }
  }, [props.customer])

  useEffect(() => {
    if (Object.keys(props.eligibilityResults).length !== 0) {
      setEligibilityResults(props.eligibilityResults);
    }
  }, [props.eligibilityResults])

  useEffect(() => {
    if (Object.keys(props.loans).length !== 0) {
      let loans_array = convertHyphensCamelCaseLoans(props.loans);
      setLoansArray(loans_array)
    } else if (Object.keys(props.loans).length === 0 && loanCounter.current) {
      loanCounter.current = false;
      setRedirection(true);
    }

    if (!loanCounter.current) {
      loanCounter.current = true;
    }
  }, [props.loans])

  useEffect(() => {
    let data = [];
    if (loansArray?.length > 0 && Object.keys(customer).length > 0 && Object.keys(eligibilityResults).length > 0) {

      let eligibilityResultsData = eligibilityResults.data;
      let eligibility_results = eligibilityResults;
      let eligibility_results_included = eligibility_results.included;


      for (var i = 0; i < loansArray.length; i++) {
        const checksum_identifier = loansArray[i].checksumIdentifier
        var item = eligibility_results_included.find(item => item.attributes.checksum_identifier === checksum_identifier);
        delete loansArray[i]['eligibility_result_id'];
        loansArray[i]['eligible'] = item?.attributes?.eligible;
        loansArray[i]['program'] = item?.attributes?.eligibility_result_id;
        loansArray[i]['advisable'] = false;
      }
      for (var j = 0; j < eligibilityResultsData.length; j++) {
        if (j === 0) {
          let loanResults = calculateProgramResults(customer, loansArray, eligibilityResultsData[j]);
          let loansData1 = loanResults.borrower.loans;
          data = loansData1
        }
      }
      if (tableData.length === 0)
        setTableData(data);
    }
  }, [loansArray, customer, eligibilityResults])

  if (redirection) {
    return <Redirect to='/welcome/nslds' />
  }

  return (
    <div className="mt-4 Gtable-div">
      <Table borderless className="Gborder-05 G-table-theme-grey">
        <thead className="Gt-head">
          <tr>
            <th className="Gfs-075 p-3">Loan Type</th>
            <th className="Gfs-075 p-3">Monthly Payment</th>
            <th className="Gfs-075 p-3">Time Left to Pay</th>
            <th className="Gfs-075 p-3">Remaining interest</th>
            <th className="Gfs-075 p-3">Projected Pay</th>
            <th className="Gfs-075 p-3">Edit</th>
          </tr>
        </thead>
        <tbody className="Gt-body">
          {tableData.length > 0
            ? tableData.map((row, i) => {
              return (
                <tr key={i}>
                  <td>{splitUpperCase(row.type)} </td>
                  <td >{numberFormat(row.initialPayment)}</td>
                  <td>{monthsToHumanize(row.payoffTime)}</td>
                  <td>{numberFormat(Math.abs(row.accruedInterest))}</td>
                  <td>{numberFormat(Math.abs(row.totalPaid))}</td>
                  <td >
                    <Link to={{ pathname: '/advice-and-tools/loans/edit/' + row.id, state: { fromForgivenessEligibility: false } }}>
                      <Pencil className="G-link G-cursor-pointer" />
                    </Link>
                  </td>
                </tr>
              );
            })
            : (<tr><td colSpan={6}>You currently do not have any loans.</td></tr>)}
        </tbody>
      </Table>
      {tableData && tableData.length && doesNotHaveSatisfactionDataForThisType(FeedbackRouteName.loanManagement) && userCanLookAtFeedbackModal() && (
        <FeedbackModal showFeedbackModal={!!tableData.length} contentType={FeedbackRouteName.loanManagement} />
      )}
    </div>
  );
}

StudentLoan.propTypes = {
  fetchCustomer: PropTypes.func.isRequired,
  getLoans: PropTypes.func.isRequired,
  getEligibilityResults: PropTypes.func.isRequired,
  getSatisfactionFeedback: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
  customer: state.customer_array.customerData,
  loans: state.five_two_nine.loans,
  eligibilityResults: state.five_two_nine.eligibilityResults,
  satisfactionData:state.advisor.satisfactionData,
});
export default connect(mapStateToProps, { fetchCustomer, getLoans, getEligibilityResults, getSatisfactionFeedback })(StudentLoan);
