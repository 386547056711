import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { responseMessage } from '../../../utils/alert';

import { getAdvisorServicers } from '../../../actions/advisorActions';
import { getLoans } from '../../../actions/fiveTwoNineActions';
import { convertHyphensCamelCaseLoans, numberFormat } from '../../../common/js/jsFunctions';
import { sortAdvisorServicers } from '../dashboardCommon';

import { intercomTrackEvent } from "../../customerStatus";
import eventNames from "../../event-names-map";
import WizardSteps from './WizardSteps';
import { Plus } from 'react-bootstrap-icons';
import StudentLoan from './StudentLoan';


const GreenCircleCheck = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#52C41A" />
    </svg>
  )
}

class Loans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      loanCount: 0,
      loanWord: 'loan',
      federalLoansCount: 0,
      privateLoansCount: 0,
      loanBalanceTotal: 0,
      loanMonthlyPaymentTotal: 0,
      loanData: [],
      advisorServicers: [],
      stepNumber: 3
    }
  }
  componentDidMount() {
    document.title = "Vault Advisor";
    const { getLoans, getAdvisorServicers } = this.props;
    getLoans();
    getAdvisorServicers();
    intercomTrackEvent(eventNames.visitedAdvisorLoans);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loans !== this.props.loans) {
      let loanData = this.props.loans;
      let loans = convertHyphensCamelCaseLoans(this.props.loans);

      const loanCount = loans.length;
      const loanWord = loanCount > 0 ? 'loans' : 'loan';

      const federalLoans = loans.filter(function (value) {
        return value.loanType !== 'Private'
      });
      const federalLoansCount = federalLoans.length;
      const privateLoansCount = loanCount - federalLoansCount;

      let loanBalanceTotal = 0;
      let loanMonthlyPaymentTotal = 0;
      for (var i = 0; i < loans.length; i++) {
        loanBalanceTotal += loans[i].balance / 100;
        loanMonthlyPaymentTotal += Math.floor(loans[i].monthlyPayment / 100);
      }
      this.setState({
        loanData: loanData,
        loans: loans,
        loanCount: loanCount,
        loanWord: loanWord,
        federalLoansCount: federalLoansCount,
        privateLoansCount: privateLoansCount,
        loanBalanceTotal: numberFormat(loanBalanceTotal),
        loanMonthlyPaymentTotal: numberFormat(loanMonthlyPaymentTotal)
      })
    }
    if (prevProps.advisorServicers !== this.props.advisorServicers) {
      const advisorServicers = this.props.advisorServicers.sort(sortAdvisorServicers)
      this.setState({
        advisorServicers: advisorServicers
      })
    }
  }

  handleBack = (e) => {
    this.props.history.push({
      pathname: '/welcome/nslds'
    })
  }
  handleNext = (e) => {
    const { loanCount } = this.state;

    if (loanCount > 0) {
      this.props.history.push({
        pathname: '/genius-calc'
      })
    } else {
      responseMessage("error", "You must have at least one loan to continue.", "");
    }
  }
  addLoanManually = () => {
    this.props.history.push({
      pathname: '/welcome/nslds',
      state: { addLoanManually: true }
    })
  }
  render() {
    const { federalLoansCount, privateLoansCount, loanBalanceTotal } = this.state;

    return (

      <>
        <Header hideNotificationAndProfileDropdown={true} hideSubNavBar={true} />
        <div className="G-page-color-background">
          <div className="G-page-main-container my-5">
            <WizardSteps stepNumber={this.state.stepNumber} />
            <div className=" p-4 Gborder-05 bg-white p-4  my-3 rounded-lg">
              <div className='G-grid21'>
                <div>
                  <div className="pinkCircularDiv mb-3">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                      <path d="M30 17.5C23.1 17.5 17.5 23.1 17.5 30C17.5 36.9 23.1 42.5 30 42.5C36.9 42.5 42.5 36.9 42.5 30C42.5 23.1 36.9 17.5 30 17.5ZM30 40C24.4875 40 20 35.5125 20 30C20 24.4875 24.4875 20 30 20C35.5125 20 40 24.4875 40 30C40 35.5125 35.5125 40 30 40ZM35.7375 24.475L27.5 32.7125L24.2625 29.4875L22.5 31.25L27.5 36.25L37.5 26.25L35.7375 24.475Z" fill="var(--icon-color)" />
                    </svg>
                  </div>
                  <h2>Review and confirm loans</h2>
                  <p className="sans-medium">
                    By adding accurate information about loans, we can help you customize your financial
                    wellness plan, whether it's to lower payments, payoff loans faster, or refinancing.
                    If your access to Vault was provided by your employer, this information is not shared with them
                  </p>
                  <div className='d-flex'>
                    <div className='mr-2'>
                      <GreenCircleCheck />
                    </div>
                    <p className="sans-medium">
                      View all of your imported federal student loans
                    </p>
                  </div>
                  <div className='d-flex'>
                    <div className='mr-2'>
                      <GreenCircleCheck />
                    </div>
                    <p className="sans-medium">
                      'Add a Loan' to add any private or additional federal loans
                    </p>
                  </div>
                  <div className='d-flex'>
                    <div className='mr-2'>
                      <GreenCircleCheck />
                    </div>
                    <p className="sans-medium">
                      'Edit' to update any loan information
                    </p>
                  </div>
                  <div className='d-flex mob-btn-div-column'>
                    <button type="button" className="G-orange-button" onClick={this.addLoanManually.bind(this)} >
                      <Plus size={28} className='mr-1 mb-1' /> Add a loan
                    </button>
                  </div>
                </div>
              </div>
              <div className='d-flex Gb-blue-50 my-4 rounded-lg p-3'>
                <div className='mr-3'>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 9.979H11V7.979H13M13 17.979H11V11.979H13M12 2.979C10.6868 2.979 9.38642 3.23766 8.17317 3.74021C6.95991 4.24276 5.85752 4.97935 4.92893 5.90794C3.05357 7.7833 2 10.3268 2 12.979C2 15.6312 3.05357 18.1747 4.92893 20.0501C5.85752 20.9787 6.95991 21.7153 8.17317 22.2178C9.38642 22.7203 10.6868 22.979 12 22.979C14.6522 22.979 17.1957 21.9254 19.0711 20.0501C20.9464 18.1747 22 15.6312 22 12.979C22 11.6658 21.7413 10.3654 21.2388 9.15217C20.7362 7.93891 19.9997 6.83652 19.0711 5.90794C18.1425 4.97935 17.0401 4.24276 15.8268 3.74021C14.6136 3.23766 13.3132 2.979 12 2.979Z" fill="#1890FF" />
                  </svg>
                </div>
                <div>
                  <p className='sans-medium mb-0'>
                    Your interest rates may differ if you are enrolled in auto-pay with your servicer.
                  </p>
                </div>
              </div>
              <div className="G-container-space-evenly Gborder-05 rounded-lg Gmt-1 " >
                <div className="p-3 my-3 Gborder-r05 Gfs-087">
                  <label className='G-badge-purple mb-2'> Federal Loans</label><br />
                  <span className="Gt-pink-8 Gfs-2 sans-bold ">{federalLoansCount}</span>
                </div>

                <div className=" p-3 my-3 Gborder-r05 Gfs-087">
                  <label className='G-badge-purple mb-2'> Private Loans</label><br />
                  <span className="Gt-pink-8 Gfs-2 sans-bold ">{privateLoansCount}</span>
                </div>

                <div className=" p-3 my-3 Gfs-087">
                  <label className='G-badge-purple mb-2'> Total Current Balance</label><br />
                  <span className="Gt-pink-8 Gfs-2 sans-bold ">{loanBalanceTotal}</span>
                </div>
              </div>

              <StudentLoan />

            </div>
            <div className="d-flex justify-content-end p-3 mob-btn-div-column">
              <button type="button" className="G-cancel-button ml-3 mt-2" onClick={this.handleBack.bind(this)}>
                Go Back
              </button>
              <button type="button" className="G-orange-button ml-3 mt-2" onClick={this.handleNext.bind(this)}>
                Continue
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
Loans.propTypes = {
  getAdvisorServicers: PropTypes.func.isRequired,
  getLoans: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  advisorServicers: state.advisor.advisorServicers,
  loans: state.five_two_nine.loans,
  errors: state.errors
});
export default connect(mapStateToProps, { getAdvisorServicers, getLoans })(Loans);
