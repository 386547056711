import moment from "moment";
import camelcase from "camelcase";
import * as Sentry from "@sentry/browser";
import repayment_calculator from "program_calculator";
//var repaymentCalculator = require('repayment_calculator');
//var repayment_calculator = require('program_calculator/build/repayment_calculator');
export function numberFormat(value) {
  if (value !== "") {
    //value=parseInt(value,10);
    if (!isNaN(value)) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        //maximumFractionDigits:2
      }).format(value);
    } else {
      return "";
    }
  } else {
    return value;
  }
}
export function payrollDatePlusOneSub(employer, unformatted = false) {
  if (employer) {
    const date =
      employer.attributes.listed_in_current_invoice === false
        ? employer.attributes.subsequent_payroll_date
        : employer.attributes.next_payroll_date;
    if (unformatted) {
      return payrollDatePlusOneDayUnformatted(date);
    }
    return payrollDatePlusOneDay(date);
  }
}
export function payrollDatePlusOneDay(payrollDate) {
  return payrollDatePlusOneDayUnformatted(payrollDate).format('MMMM Do');
}
function payrollDatePlusOneDayUnformatted(payrollDate) {
  return moment.utc(payrollDate).add(1, "days");
}
export function formattedServicers(servicersData) {
  return servicersData;
}
export function statusText(status, settled_at) {
  /*if(status==='pending' || status==='funded' || status==='exported' || status==='scheduled'){
    return 'Sending...';  
  }*/
  switch (status) {
    case "pending":
    case "funded":
    case "exported":
    case "scheduled":
      return "Sending...";
    case "failed":
      return "Under Review";
    case "remitted":
      return "Sent!";
    case "settled":
    case "verified":
    case "resolved":
    default:
      return `Paid -${_buildSettledAtDate(settled_at)}`;
  }
}
function _buildSettledAtDate(settled_at) {
  const date = moment(settled_at);
  const year = date.year().length === 2 ? `20${date.year()}` : date.year();
  return date.year(year).format("MMMM D, YYYY");
}
export function monthsToHumanize(monthDiffs) {
  if (monthDiffs === 0) {
    return null;
  }
  if (monthDiffs === undefined) {
    return "never";
  }
  const diff = Math.abs(monthDiffs);
  const years = parseInt(diff / 12);
  const months = diff % 12;
  return `${years}yr ${months}m`;
}
export function splitUpperCase(string) {
  const results = string.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  return results;
}
export function convertHyphensCamelCaseCustomer(customerObj) {
  let customerData = customerObj.attributes;
  let customer = toCamel(customerData);
  //customer.employer = customerObj.relationships.employer.data.id;
  customer = renameKeysCustomer(customer, [
    ["extraPaymentCents", "extraPayment"],
    ["salaryCents", "salary"],
  ]);
  return customer;
}
export function convertHyphensCamelCaseLoans(loansObj) {
  let loans = [];
  loansObj && loansObj.forEach(item => {
    let loansData = toCamel(item.attributes);
    loansData = renameKeysLoans(loansData, [['currentBalanceCents', 'balance'], ['monthlyPaymentCents', 'monthlyPayment'], ['originationBalanceCents', 'originationBalance']]);
    loansData.loanId = item.id;
    loans.push(loansData);
  })
  return loans;
}
export function convertHyphensCamelCaseLoansEligibility(loansObj) {
  let loans = [];
  loansObj.forEach(item => {
    let loansData = toCamel(item);
    loansData = renameKeysLoans(loansData, [['currentBalanceCents', 'balance'], ['monthlyPaymentCents', 'monthlyPayment'], ['originationBalanceCents', 'originationBalance']]);
    loansData.loanId = item.id;
    loans.push(loansData);
  });
  return loans;
}
function toCamel(o) {
  var newO, origKey, newKey, value;
  newO = {};
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = camelcase(origKey);
      value = o[origKey];
      newO[newKey] = value;
    }
  }
  return newO;
}
export function renameKeysCustomer(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach((item) => {
    Object.defineProperty(
      obj,
      item[1],
      Object.getOwnPropertyDescriptor(obj, item[0])
    );
    delete obj[item[0]];
  });
  return obj;
}
export function renameKeysLoans(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach((item) => {
    Object.defineProperty(
      obj,
      item[1],
      Object.getOwnPropertyDescriptor(obj, item[0])
    );
    delete obj[item[0]];
  });
  return obj;
}
export function calculateProgramResultsWithMatch(customer, loans, eligibilityResultsData, extraPayment) {

  const program_name_str = eligibilityResultsData.id
  const programName = program_name_str.charAt(0).toUpperCase() + program_name_str.slice(1);

  const customerData = calculatorTranslator(customer, loans);
  const data = calculate(customerData, { program: programName, currentExtraPayment: extraPayment });
  return data;
}
export function calculateProgramResults(customer, loans, eligibilityResultsData) {
  const program_name_str = eligibilityResultsData.id
  const programName = program_name_str.charAt(0).toUpperCase() + program_name_str.slice(1);
  const customerData = calculatorResult(customer, loans);
  const data = calculate(customerData, { program: programName, currentExtraPayment: 0 });
  data['name'] = programName;
  return data;
}

function calculatorResult(customer, loans) {
  const customerData = calculatorTranslator(customer, loans);
  return customerData;
}
function calculate(customerData, options = {}) {
  const opts = Object.assign({ program: "Existing" }, options);
  let result = null;
  try {
    Sentry.configureScope((scope) => {
      result = scope.setExtra({ customer: customerData, options });
    });
    const dt = repayment_calculator(customerData, opts);
    return dt;
    //return repayment_calculator(customerData, opts);
  } catch (error) {
    Sentry.captureException(error);
    // console.log(error);
  }
  return result;
}
export function calculatorTranslator(customer, loans) {
  let jsCustomer = convertCentsToDollars(customer);
  jsCustomer = renameKeys(jsCustomer, [
    ["agiCents", "agi"],
    ["spouseAgiCents", "spouseAgi"],
    ["spouseFederalLoanDebtCents", "spouseDebt"],
  ]);

  let jsLoans = convertCentsToDollars(loans.map((loan) => loan));
  jsLoans = jsLoans.map((loan) => {
    return renameKeys(loan, [
      ["monthlyPayment", "minimumPayment"],
      ["interestRate", "rate"],
      ["loanType", "type"],
      ["originationDate", "startDate"],
      ["loanId", "id"],
    ]);
  });
  jsCustomer.loans = jsLoans;
  return jsCustomer;
}
export function renameKeys(obj, oldNewKeyArray) {
  oldNewKeyArray.forEach((item) => {
    Object.defineProperty(
      obj,
      item[1],
      Object.getOwnPropertyDescriptor(obj, item[0])
    );
    //console.log(obj[item[0]])
    //delete obj[item[0]];
  });
  return obj;
}
export function convertCentsToDollars(objOrArray) {
  if (objOrArray.length !== undefined) {
    return objOrArray.map((item) => _convertToCents(item));
  } else {
    return _convertToCents(objOrArray);
  }
}
export function _convertToCents(object) {
  //const obj = object['attributes'];
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === "number" && key !== "familySize") {
      object[key] = +(object[key] / 100).toFixed(5);
    }
    if (key === "interestRate") {
      object[key] = +(object[key] / 100).toFixed(5);
    }
  });
  //object.attributes = obj;
  return object;
}
export function loanTypeFriendly(loanType) {
  if (loanType) {
    return loanType.match(/[A-Z][a-z]+/g).join(' ');
  }
  return
}
export function loanStatusMessage(status, allowOneOnOne) {
  const loanStatusMessages = {
    'DA': 'Deferment',
    'FB': 'Forbearance',
    'IA': 'Loan Originated',
    'IG': 'In grace period',
    'IM': 'In military grace',
    'PN': 'Pain in full through consolidation loan',
    'RP': 'Repayment',
    'RF': 'Refinanced'
  };
  return loanStatusMessages[status] || (allowOneOnOne ? 'This loan is in a default status. Learn more here or set up a 1:1 advisor session to discuss options for getting loans out of default.' : 'This loan is in a default status');
}
export function payoffTimeInWords(formatter, monthStr) {
  let _month = monthStr;
  //formatter like this: number, singular, plural number, singular plural
  // capital y, m causes number to always show two digits: 01 year 02 months
  // '%ys%ms' => '8yr 2m', '1m'
  // '%Yp-%Mp-' => '02 years 01 month'
  // options:
  // 'l' long word format ('months, years'),
  // 's' short format ('m, yr'),
  // 'c' custom format example: %Ycp {singular form} {plural form(if p flag)}
  // 'p' pluralize note: when combined with custom format, should pass singular and plural forms
  // '-' use spaces between numbers and words

  var i, n, spacer, nstring, wordstring;
  var fields = formatter.split(/(?=%.)/);
  var years = Math.floor(_month / 12);
  var months = _month % 12;
  var datestring = '';
  for (i = 0; i < fields.length; i++) {
    // are we looking at months or years here?
    n = fields[i].match(/%y|%Y/) ? years : months;
    spacer = fields[i].match(/%.*-/) ? ' ' : '';

    // check if we should zero pad- is the thing upcase?
    if (fields[i].match(/%[\u0041-\u005A]/) && n.toString().length === 1) {
      nstring = '0' + n;
    } else {
      nstring = n.toString();
    }

    if (fields[i].match(/%.c/)) {
      //custom provided names
      wordstring = n !== 1 && fields[i].match(/%.cp/) ? fields[i].split(' ')[2] : fields[i].split(' ')[1];
    } else if (fields[i].match(/%.s/)) {
      //short format
      if (fields[i].match(/%y|%Y/)) {
        wordstring = n !== 1 && fields[i].match(/p/) ? 'yrs' : 'yr';
      } else {
        wordstring = n !== 1 && fields[i].match(/p/) ? 'mo' : 'mo';
      }
    } else {
      //long format (default)
      if (fields[i].match(/%y|%Y/)) {
        wordstring = n !== 1 && fields[i].match(/p/) ? 'years' : 'year';
      } else {
        wordstring = n !== 1 && fields[i].match(/p/) ? 'months' : 'month';
      }
    }
    if (n) {
      datestring += nstring + spacer + wordstring + ' ';
    }
  }
  return datestring.trim();
};
export function friendlyName(name) {

  let friendlyName = name;
  switch (name) {
    case 'Existing':
      friendlyName = 'Your Current Plan';
      break;
    case 'Ibr':
    case 'Repaye':
    case 'Save':
    case 'Paye':
    case 'Icr':
      friendlyName = name.toUpperCase();
      break;
    default:
      friendlyName = name.split(/(?=[A-Z])/).join(' ');
  }
  return friendlyName;
}
export function longName(nameVal) {
  const name = nameVal;
  let longName = null;
  switch (name) {
    case 'Ibr':
      longName = 'Income Based Repayment';
      break;
    case 'Repaye':
      longName = 'Revised Pay As You Earn';
      break;
    case 'Paye':
      longName = 'Pay As You Earn';
      break;
    case 'Icr':
      longName = 'Income Contingent Repayment';
      break;
    case 'Save':
      longName = 'Saving on A Valuable Education';
      break;
    default:
  }
  return longName;
}
export function timeInWords(time) {
  let timeVal = moment();
  if (time) {
    timeVal = time;
  }
  return moment(timeVal).startOf('day').fromNow();
}