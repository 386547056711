import React, { Component } from 'react';
import { Link } from "react-router-dom";

import {
  friendlyName,
  minimum_payment,
  payoff_time_in_words,
  pdfName,
  pdfLink,
  showDownloadStep,
  currency_format,
} from '../../../eligibility-result';
import { mailToSupport } from '../../../mail-to-support';
import { monthsToHumanize } from '../../../../../common/js/jsFunctions';
import isCitizens from '../../../is-citizens';
import { fetchEmployer } from '../../../../../actions/employerActions';
import { connect } from 'react-redux';

import { intercomTrackEvent } from "../../../../customerStatus";
import eventNames from "../../../../event-names-map";
import { Table } from 'react-bootstrap';


class Paperwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  handleFeedback = (e) => {
    this.props.focusOnFeedBack(e);
  }
  handleTrackOneOnOneEvent = (e) => {
    this.props.trackOneOnOneEvent(e);
    intercomTrackEvent(eventNames.clickedScheduleOneOnOneRepaymentPlan);
  }
  handleDownloadPdf = (pdf_link, e) => {
    if (pdf_link !== '') {
      this.props.clickedDownloadPdf(e, pdf_link)
      intercomTrackEvent(eventNames.downloadedAdvisorWalkthroughRepayPlan);
      this.sendTrackingEvent(eventNames.clickedReviewRepaymentWalkthrough);
    }
  }

  sendTrackingEvent = (eventName)=> {
    const { originalPlan, newPlan, trackEvent } = this.props;
  
    trackEvent({data: {
      event_key: eventName,
      params: {
        plan_name: newPlan.name,
        current_plan: {
          monthly_payment: originalPlan.result.initialPayment,
          pay_time_left: originalPlan.result.payoffTime,
          interest: originalPlan.result.accruedInterest,
          projected_pay: originalPlan.result.totalPaid
        },
        extra_payment: newPlan.extraPayment,
        selected_plan: {
          monthly_payment: newPlan.resultWithExtra.initialPayment,
          pay_time_left: newPlan.resultWithExtra.payoffTime,
          interest: newPlan.resultWithExtra.accruedInterest,
          projected_pay: newPlan.resultWithExtra.totalPaid,
        }
      }
    }});
    
  }

  componentDidMount() {
    const { fetchEmployer } = this.props;
    fetchEmployer();
  }
  render() {
    const { employer } = this.props;
    let allowOneOnOne = employer?.attributes?.allow_one_on_one;

    const { originalPlan, newPlan, matchEnabled, customer } = this.props;   
    const iscitizens = isCitizens(customer.partnerName);
    let pdf_name = "";
    let showDownload = "";
    let pdf_link = "";
    if (newPlan.result) {
      pdf_name = pdfName(newPlan.name, newPlan.consolidating)
      showDownload = showDownloadStep(pdf_name, iscitizens)
      pdf_link = pdfLink(pdf_name);
    }

    let selectedTotal = "";
    let selectedPlanTimeLeft = "";
    let selectedPlanPayoff = "";
    let selectedPlanPayoffFaster = "";
    let selectedPayoffMessage = "";
    let selectedPlanInterest = "";
    let selectedPlanInterestSaved = "";
    if (matchEnabled) {
      if (originalPlan && newPlan) {
        if (originalPlan.result && newPlan.resultWithMatchAndExtra) {
          selectedTotal = Number(originalPlan.result.totalPaid) - Number(newPlan.resultWithMatchAndExtra.totalPaid);
          selectedPlanTimeLeft = payoff_time_in_words(newPlan.resultWithMatchAndExtra)

          const payoffDifference = Number(originalPlan.result.payoffTime) - Number(newPlan.resultWithMatchAndExtra.payoffTime);
          selectedPlanPayoffFaster = payoffDifference > 0;
          selectedPayoffMessage = monthsToHumanize(payoffDifference) || null
          selectedPlanPayoff = selectedPayoffMessage;

          selectedPlanInterest = newPlan.resultWithMatchAndExtra.accruedInterest > 0 ? currency_format(newPlan.resultWithMatchAndExtra.accruedInterest) : '$0';

          selectedPlanInterestSaved = Number(originalPlan.result.accruedInterest) - Number(newPlan.resultWithMatchAndExtra.accruedInterest);
        }
      }

    } else {
      if (originalPlan && newPlan) {
        if (originalPlan.result && newPlan.resultWithMatch && newPlan.resultWithExtra) {
          selectedTotal = Number(originalPlan.result.totalPaid) - Number(newPlan.resultWithExtra.totalPaid);
          selectedPlanTimeLeft = payoff_time_in_words(newPlan.resultWithExtra);

          const payoffDifference = Number(originalPlan.result.payoffTime) - Number(newPlan.resultWithExtra.payoffTime);
          selectedPlanPayoffFaster = payoffDifference > 0;
          selectedPayoffMessage = monthsToHumanize(payoffDifference) || null
          selectedPlanPayoff = selectedPayoffMessage;

          selectedPlanInterest = newPlan.resultWithExtra.accruedInterest > 0 ? currency_format(newPlan.resultWithExtra.accruedInterest) : '$0';
          selectedPlanInterestSaved = Number(originalPlan.result.accruedInterest) - Number(newPlan.resultWithExtra.accruedInterest);
        }
      }
    }
    let mailtosupport = "#";
    if (customer) {
      mailtosupport = mailToSupport("Support Request", customer);
    }
    return (

      <>
        {newPlan.name !== 'Existing' ?
          <p className="sans-medium Gfs-1 my-3">Switch to {newPlan.name ? friendlyName(newPlan.name) : ''} paying
            <>
              {newPlan.result ?
                newPlan.extraPayment > 0 ?
                  <>
                    {newPlan.name === "Existing" ? "Payoff loans faster with paying" : null}
                    <span className="Gt-green-6"> ${newPlan.extraPayment} extra </span>every month
                  </>
                  : null
                : null}
            </>

          </p>
          : null}

        <div className="Gtable-div">
          <Table borderless className="Gborder-05 G-table-theme-grey">
            <thead className="Gt-head">
              <tr>
                <th className="Gfs-075 p-3">Plan Type</th>
                <th className="Gfs-075 p-3">Monthly Payment</th>
                <th className="Gfs-075 p-3">Time Left to Pay</th>
                <th className="Gfs-075 p-3">Remaining interest</th>
                <th className="Gfs-075 p-3">Projected Pay</th>
              </tr>
            </thead>
            <tbody className="Gt-body">
              <tr>
                <td>Current Plan</td>
                <td>
                  {originalPlan.result ? minimum_payment(originalPlan.result) : ''}
                </td>
                <td>
                  {
                    originalPlan.result ?
                      originalPlan.result.payoffTime ?
                        monthsToHumanize(originalPlan.result.payoffTime)
                        : '0yr 0m'
                      : null
                  }
                </td>
                <td>
                  {
                    originalPlan.result ?
                      originalPlan.result.accruedInterest > 0 ?
                        currency_format(originalPlan.result.accruedInterest)
                        : '$0'
                      : null
                  }
                </td>
                <td>
                  {
                    originalPlan.result ?
                      currency_format(originalPlan.result.totalPaid)
                      : null
                  }
                </td>
              </tr>
              <tr>
                <td>Selected Plan</td>
                <td>
                  {
                    matchEnabled ?
                      newPlan.resultWithMatchAndExtra ? minimum_payment(newPlan.resultWithMatchAndExtra) : ''
                      : newPlan.resultWithExtra ? minimum_payment(newPlan.resultWithExtra) : ''
                  }
                </td>
                <td>
                  {selectedPlanTimeLeft}
                  <br />
                  {originalPlan.result ?
                    <div className={`Gfs-075 ${originalPlan.result.extraPayment !== 0 ? "Gt-green-6 " : ""} ${selectedPlanPayoffFaster ? "Gt-green-6 " : "Gt-yellow-6 "} `}>{selectedPlanPayoff} {selectedPlanPayoffFaster ? 'faster!' : "slower"}</div>
                    : null
                  }
                </td>
                <td>
                  {selectedPlanInterest}
                  <br />
                  {selectedPlanInterestSaved ?
                    <div className={`Gfs-075 ${originalPlan.result.extraPayment !== 0 ? "Gt-green-6" : ""} ${selectedPlanInterestSaved > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>{currency_format(selectedPlanInterestSaved)} {selectedPlanInterestSaved > 0 ? 'saved!' : "more"}</div>
                    : null
                  }
                </td>
                <td>
                  {matchEnabled ?
                    newPlan.resultWithMatchAndExtra ?
                      currency_format(newPlan.resultWithMatchAndExtra.totalPaid)
                      : null
                    : newPlan.resultWithExtra ?
                      currency_format(newPlan.resultWithExtra.totalPaid)
                      : null
                  }
                  <br />
                  <div className={`Gfs-075 ${selectedTotal > 0 ? "Gt-green-6" : "Gt-yellow-6"} `}>
                    {currency_format(selectedTotal)} {selectedTotal > 0 ? 'impact!' : 'more'}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className='mt-5'>
          {
            newPlan.name !== 'Existing' ?
              <div className="G-grid11-2 ">
                {
                  showDownload ?
                    <div>
                      <div>
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                          <path d="M29.752 38.5V24.844C29.448 25.468 28.992 25.972 28.384 26.356C27.776 26.724 27.048 26.908 26.2 26.908H25.912V24.316H26.032C26.688 24.316 27.256 24.236 27.736 24.076C28.216 23.9 28.608 23.66 28.912 23.356C29.216 23.052 29.44 22.7 29.584 22.3C29.744 21.9 29.832 21.46 29.848 20.98H32.368V38.5H29.752Z" fill="var(--icon-color)" />
                        </svg>
                      </div>
                      <label className="Gfs-125 sans-semibold my-3">
                        Review repayment walkthrough
                      </label>
                      <p className="sans-medium">
                        {/* Download your guide to your new repayment plan. This step-by-step PDF will help you complete your repayment plan switch. */}
                        The debt avalanche method targets debts with the highest interest rates first. Order your student loans from highest interest rate to lowest. Focus on paying extra funds towards the loan with the highest interest rate, while continuing to make minimum payments on the other student loans. Once you payoff the student loan with the highest interest rate, focus the extra funds on the debt with the next-highest interest rate. Continue this strategy until all loans have been repaid. The debt avalanche method targets debts with the highest interest rates first.
                      </p>
                      <button className='bg-transparent border-0 G-orange-button' onClick={this.handleDownloadPdf.bind(this, pdf_link)}>Download PDF</button>
                    </div>
                    : null
                }
                <div>
                  {showDownload ?
                    <div>
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M24.104 36.004C24.104 35.284 24.24 34.628 24.512 34.036C24.784 33.444 25.144 32.9 25.592 32.404C26.04 31.892 26.544 31.42 27.104 30.988C27.664 30.556 28.224 30.148 28.784 29.764C29.264 29.428 29.728 29.1 30.176 28.78C30.624 28.444 31.016 28.108 31.352 27.772C31.704 27.436 31.984 27.092 32.192 26.74C32.4 26.388 32.504 26.012 32.504 25.612C32.504 24.86 32.248 24.244 31.736 23.764C31.224 23.268 30.496 23.02 29.552 23.02C28.736 23.02 28.048 23.252 27.488 23.716C26.928 24.18 26.472 24.788 26.12 25.54L23.864 24.388C24.376 23.284 25.112 22.404 26.072 21.748C27.048 21.076 28.208 20.74 29.552 20.74C30.416 20.74 31.192 20.868 31.88 21.124C32.584 21.38 33.176 21.732 33.656 22.18C34.136 22.612 34.504 23.132 34.76 23.74C35.032 24.332 35.168 24.964 35.168 25.636C35.168 26.324 35.032 26.948 34.76 27.508C34.504 28.052 34.16 28.564 33.728 29.044C33.296 29.508 32.808 29.94 32.264 30.34C31.72 30.74 31.176 31.132 30.632 31.516C30.136 31.852 29.648 32.196 29.168 32.548C28.704 32.884 28.288 33.236 27.92 33.604C27.568 33.956 27.28 34.332 27.056 34.732C26.848 35.116 26.744 35.54 26.744 36.004H35.192V38.5H24.104V36.004Z" fill="var(--icon-color)" />
                      </svg>
                    </div>
                    :
                    <div>
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M29.752 38.5V24.844C29.448 25.468 28.992 25.972 28.384 26.356C27.776 26.724 27.048 26.908 26.2 26.908H25.912V24.316H26.032C26.688 24.316 27.256 24.236 27.736 24.076C28.216 23.9 28.608 23.66 28.912 23.356C29.216 23.052 29.44 22.7 29.584 22.3C29.744 21.9 29.832 21.46 29.848 20.98H32.368V38.5H29.752Z" fill="var(--icon-color)" />
                      </svg>
                    </div>}
                  <label className="Gfs-125 sans-semibold my-3">
                    Apply
                  </label>
                  <p className="sans-medium">Click below to visit <a className="sans-semibold G-link text-decoration-none cancelLink" href="https://studentaid.gov" target="_blank" rel="noopener noreferrer">studentaid.gov,</a> a website administered by the federal government. You'll need about 20 minutes to complete your application. Your eligibility for the federal repayment plan will be determined by the federal government. And remember: we're here if you need any help.</p>

                  <a target='_blank' rel='noreferrer' className='text-white' href='https://studentaid.gov'>
                    <button onClick={() => this.sendTrackingEvent(eventNames.clickedRepaymentApply)} className="bg-transparent border-0 G-orange-button">
                      Apply
                    </button>
                  </a>
                </div>
              </div>
              :
              <p className="sans-semibold Gfs-125 mt-4">
                Start Paying Extra
                <br />
                <span className="sans-medium">
                  Apply an extra {newPlan.extraPayment} towards your student loans. You'll have the most impact if you designate
                  that the extra payment should apply to your highest interest loan.
                </span>
              </p>
          }
          <div className="sans-medium mt-5">
            Want to learn more about loan payoffs or have questions?
            {
              iscitizens ?
                <p className="sans-medium">
                  Call us at <a href="tel:1-866-999-0062" className="sans-semibold G-link text-decoration-none cancelLink">(866) 999-0062</a> or email&nbsp;
                  <a href="mailto:citizenswork@citizensbank.com" className="sans-semibold G-link text-decoration-none cancelLink">citizenswork@citizensbank.com</a>.
                </p>
                :
                <p className="sans-medium">
                  Email us at &nbsp;
                  <Link to='#' onClick={() => window.location = mailtosupport} className="sans-semibold Gt-pink-8 text-decoration-none cancelLink">{customer.advisorSupportEmail}</Link>.&nbsp;
                  {
                    allowOneOnOne && (
                      <>
                        <br />
                        <span className="sans-medium">
                          Have a question that requires a longer conversation?
                          1:1 advisory sessions are available by&nbsp;
                          <Link to="#" onClick={this.handleTrackOneOnOneEvent.bind(this)} className="sans-semibold Gt-pink-8 text-decoration-none cancelLink">scheduling time with a resident genius.</Link>
                        </span>
                      </>
                    )
                  }
                </p>
            }
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    employer: state.employer_array.employerData,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployer: () => dispatch(fetchEmployer()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Paperwork);
