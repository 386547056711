import { FETCH_EMPLOYER } from '../actions/types';

const initialState = {
  employerData: {},
}

export default function employerReducer (state = initialState, action) {
  switch(action.type) {
    case FETCH_EMPLOYER:
      return {
        ...state,
        employerData: action.payload
      }
    default:
      return state;
  }
}