import { useEffect } from "react";
import { connect } from "react-redux";
import { invalidateSession } from '../../actions/authenticationActions';
import { clearLocalStorageItems } from "../../helpers/clearLocalStorage";
import debounce from '../../utils/debounce';

//auto logout the user after desired amount of ms (seconds * 1000)
const logoutTime =  localStorage.getItem('advisorTimeout') * 1000 || 1200 * 1000;

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AutoLogout = (props) => {
  let timer;

  if (localStorage.getItem('samlKeepaliveUrl') !== "undefined" && localStorage.getItem('samlKeepaliveUrl') !== null) {
    debounce(samlKeepAlive(localStorage.getItem('samlKeepaliveUrl')), 30000); // only hit keepAlive after 30 seconds
  }
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, logoutTime);
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 30 mins of inactivity resets.
  // However, if none of the event is triggered within 30 mins, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  function samlKeepAlive(url) {
    const timeStampedURL = `${url}?${Math.round(new Date().getTime() / 1000)}`;
    let keepAliveFrame = document.getElementById('sso-keep-alive-iframe');
    if (keepAliveFrame) {
      document.body.removeChild(keepAliveFrame);
    }
    keepAliveFrame = document.createElement('iframe');
    keepAliveFrame.setAttribute('src', timeStampedURL);
    keepAliveFrame.setAttribute('id', 'sso-keep-alive-iframe');
    keepAliveFrame.setAttribute('style', 'display:none');
    document.body.appendChild(keepAliveFrame);
    return ""
  }

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    props.invalidateSession();
    clearLocalStorageItems();
    alert(`For your security, we have logged you out after ${Math.round(logoutTime/60000)} minutes of inactivity. Please log back in to continue.`);
    if (localStorage.getItem('saml_logout_page') !== null && localStorage.getItem('saml_logout_page') !== "") {
      window.location.href = localStorage.getItem('saml_logout_page');
    } else {
      window.location.pathname = "/login";
    }
  };

  return props.children;
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { invalidateSession } )(AutoLogout);