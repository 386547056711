import { FETCH_ESIGNDISCLOSURE_TERMS, ACCEPT_ESIGNDISCLOSURE_TERMS, GET_ERRORS } from "./types";

import { apiAction } from "./apiActions";
import config from '../config';

export const fetchEsignDisclosureAgreement = () => {
    return apiAction({
        url: config.apiBaseUrl + "legal_agreements/latest_esign_disclosure",
        onSuccess: setFetchEsignDisclosureAgreement,
        callback: "",
        onFailure: errorFetchEsignDisclosureAgreement,
        label: FETCH_ESIGNDISCLOSURE_TERMS,
    });
};
function setFetchEsignDisclosureAgreement(response) {
    return {
        type: FETCH_ESIGNDISCLOSURE_TERMS,
        payload: response.data,
    };
}
function errorFetchEsignDisclosureAgreement(errors) {
    return {
        type: GET_ERRORS,
        payload: errors.response.data,
    };
}
export const acceptEsignDisclosureAgreement = (user_agreements) => {
    return apiAction({
        url: config.apiBaseUrl + "user_agreements",
        method: "POST",
        data: user_agreements,
        onSuccess: setUpdateEsignDisclosureTerms,
        callback: "",
        onFailure: errorUpdateEsignDisclosureTerms,
        label: ACCEPT_ESIGNDISCLOSURE_TERMS,
    });
};
function setUpdateEsignDisclosureTerms(response) {
    return {
        type: ACCEPT_ESIGNDISCLOSURE_TERMS,
        payload: response.data,
    };
}
function errorUpdateEsignDisclosureTerms(errors) {
    return {
        type: GET_ERRORS,
        payload: errors.message,
    };
}
