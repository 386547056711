import React, { Component } from 'react';
import NavBarComponent from './Utilities/NavBar';

import Logo from './Utilities/Logo';
import './Style.css';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import onlyLogoShowRoutes from './data/onlyLogoShowRoutes.json'
import PartnerLogo from '../../shared/PartnerLogo/PartnerLogo';
import { connect } from 'react-redux';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: null
    }
  }
  componentDidMount() {
    this.setState({ logoUrl:  !!localStorage.getItem('partnerLogo') ? localStorage.getItem('partnerLogo') : null })
  }
  componentWillReceiveProps = (nextProps) => {
    if (this.props.partnerData !== nextProps.partnerData) {
      this.setState({logoUrl: nextProps.partnerData && nextProps.partnerData?.data ? nextProps.partnerData?.data?.attributes?.logo : ''})

    }
  }


  render() {
    let pathname = window.location.pathname;
    let path = window.location.pathname.split("/")[1];
    let showOnlyLogo = onlyLogoShowRoutes.includes(pathname) ? true : onlyLogoShowRoutes.includes(path) ? true : false;
    const { hideNotificationAndProfileDropdown, logoNameAndLogoutShowOnly, hideSubNavBar } = this.props;
    return (
      <>
        <nav className="navBarBackground">
          {showOnlyLogo || this.props.showLogoOnly ?
            <Navbar expand="lg" className={`navBarBackground`} >
              <Container className='col-10'>
                <Navbar.Brand href="#">
                  {this.state.logoUrl ? 
                  <PartnerLogo src={this.state.logoUrl} /> : <Logo /> }
                </Navbar.Brand>
              </Container>
            </Navbar>
            :
            <>
              <NavBarComponent
                hideSubNavBar={hideSubNavBar}
                hideNotificationAndProfileDropdown={hideNotificationAndProfileDropdown}
                logoNameAndLogoutShowOnly={logoNameAndLogoutShowOnly}
               />
            </>
          }
        </nav>
      </>
    )
  }
}

const mapStateToProps = (state) =>({
  partnerData: state.authentication.partnerData,
})

export default connect(mapStateToProps)(Header);
