/**
 * Used to check on a users account number when enrolling in pay. If the account number satisfies
 * any of the rules in the rule set, we'll automatically set the address for the servicer account.
 */
const RULES = {
  'Edfinancial': [
    {
      // Any extra rules added, their functionality will have to be handled at the `_makeAddress`
      // function.
      'startsWith': [
        ['F', { 'mailTo': 'US Department of Education' }],
        ['C', { 'mailTo': 'EdFinancial Services' }]
      ]
    }
  ],
  'Nelnet': [
    {
      'startsWith': [
        ['E', { 'mailTo': 'Department of Education' }],
        ['D', { 'address': 'P.O. Box 2970' }],
        ['J', { 'address': 'P.O. Box 2877' }]
      ]
    }
  ]
};


  /**
   * Checks the servicer account's `friendlyName` and `accountNumber` property against the `RULES`.
   * If the servicer account's servicer is in the rules and the `accountNumber` matches one of the rules,
   * this function will return the address required for said account number. If no rule is satisifed,
   * it returns undefined.
   *
   * @param {DS.Model} servicerAccount
   * @return {Object|undefined}
   */
  export function checkForAccount(servicerAccount,addressData) {
    
    const friendlyName = servicerAccount.data.servicer_name;
    const matchingServicer = Object.keys(RULES).find(name => friendlyName === name);
    
    if (matchingServicer) {
      const accountNumber = servicerAccount.data.attributes.account_number || '';
      const address = _makeAddress(RULES[matchingServicer], accountNumber);
      if (address) {
        // NOTE: addresses may not be on the store when this is called.
        return addressData.find(storeAddress => {
          if (address.mailTo) {
            return storeAddress.attributes.mail_to.trim().toUpperCase() === address.mailTo.toUpperCase();
          } else if (address.address) {
            return !!storeAddress.attributes.address.match(address.address);
          } else {
            throw new Error('You must set either a `mailTo` or `address` key for the validation. See `RULES` object');
          }
        });
      }
    }
    return undefined;
  };

  /**
   * Return an address object if one of the rules is a match against the account number. Only the `startsWith`
   * rule is implemented at the moment. Any extra rules would have to be handled here.
   *
   * @param {Object} rules
   * @param {String} accountNumber
   *
   * @return {DS.Model} address
   */
  export function _makeAddress(rules, accountNumber) {
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];

      // Rules
      const startsWith = rule['startsWith'];
      if (startsWith && accountNumber.split('')[0]) {
        const firstChar = accountNumber.split('')[0].toUpperCase();
        for (let j = 0; j < startsWith.length; j++) {
          const ruleChar = startsWith[j][0];
          const address = startsWith[j][1];
          if (firstChar === ruleChar) {
            // match
            return address;
          }
        }
      }

      return null;
    }
  }

  /**
   * Returns true if the current servicerAccount's servicer is included in our ruleset. Used to check if
   * address selection should be disabled.
   *
   * @param {DS.Model} servicerAccount
   * @return {Boolean}
   */
  export  function disableAddressSelection(servicerAccount) {
    return Object.keys(RULES).some(friendlyName => (
      friendlyName === servicerAccount.get('servicer.friendlyName')
    ));
  }
