import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Modal, Form } from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import Header from "../../Includes/Header/Header";
import Footer from "../../Includes/Footer/Footer";
import { getServicerAccounts, postEditServicerAccounts } from "../../../actions/fiveTwoNineActions";
import {
  numberFormat,
  payrollDatePlusOneSub,
} from "../../../common/js/jsFunctions";
import { fetchEmployer } from "../../../actions/employerActions";
import { fetchCustomer } from "../../../actions/customerActions";

import { Plus, Check, ExclamationTriangleFill, XLg, Pencil, BoxArrowUpRight, InfoCircleFill, InfoCircle } from 'react-bootstrap-icons';
import {
  FriendsAndFamilyContributionSvg,
  PayrollDeductionsSvg,
  TaxStateSvg,
  Faq529Svg,
  CollegeSavingSvg, DtiCalculatorSvg, BenefitsOf529PlanSvg, Choose529PlanSvg
} from "../../TRAdviceAndTools/TRAdviceAndTools.constant";
import CustomModal from "../../shared/CustomModal/CustomModal";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerAccounts: [],
      payActiveServicerAccount: {},
      employerData: null,
      customerData: null,
      hideEmployerContribution: false,
      modalProp: {
        show: false,
        title: '',
        bodyText: '',
        footerButton: true,
        footerButtonName: '',
        svgType: '',
        footerButtonOnclick: '',
        footerButtonHref: '',
        footerButtonOnclickArgs: ''
      }
    };
  }
  componentDidMount() {
    document.title = "Vault | Dashboard";
    const { getServicerAccounts, fetchEmployer, fetchCustomer } = this.props;
    getServicerAccounts();
    fetchEmployer();
    fetchCustomer();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.servicerAccounts !== this.props.servicerAccounts) {
      const servicer_accounts_data = this.props.servicerAccounts.data;
      const servicer_accounts_included = this.props.servicerAccounts.included;

      let servicers_array = servicer_accounts_included.filter(function (value) {
        return value.type === "servicers";
      });
      let servicers_addresses_array = servicer_accounts_included.filter(
        function (value) {
          return value.type === "addresses";
        }
      );

      for (let i = 0; i < servicer_accounts_data.length; i++) {
        const servicerId =
          servicer_accounts_data[i]["relationships"]["servicer"]["data"]["id"];
        let itemServicer = servicers_array.find(
          (item) => item.id === servicerId
        );
        servicer_accounts_data[i]["servicer"] = itemServicer;

        if (servicer_accounts_data[i]["relationships"]["address"]["data"] !== null) {
          const addressId =
            servicer_accounts_data[i]["relationships"]["address"]["data"]["id"];
          let itemAddress = servicers_addresses_array.find(
            (item) => item.id === addressId
          );
          servicer_accounts_data[i]["address"] = itemAddress;
        }
      }
      let pay_active_servicer_account = servicer_accounts_data.filter(function (value) {
        return (
          value.attributes.college_savings === true &&
          value.attributes.pay_active === true
        );
      });
      this.setState({
        servicerAccounts: servicer_accounts_data,
        payActiveServicerAccount: pay_active_servicer_account,
      });
    }

    if (prevProps.employerData !== this.props.employerData) {
      this.setState({
        employerData: this.props.employerData
      });
    }

    if (prevProps.customerData !== this.props.customerData) {
      this.setState({
        customerData: this.props.customerData,
        hideEmployerContribution: this.props.customerData.attributes.employer_match === 0
      })
    }

    if (prevProps.servicerAccountsData !== this.props.servicerAccountsData) {
      let pay_active_servicer = this.state.servicerAccounts.filter((value) => {
        return (
          value.id === this.props.servicerAccountsData.data.id
        );
      });
      this.setState({
        payActiveServicerAccount: pay_active_servicer,
      });
    }
  }


  openModal = () => this.setState({
    ...this.state, isOpen: true, modalProp: {
      ...this.state.modalProp,
      show: false,
    }
  });
  closeModal = () => this.setState({ ...this.state, isOpen: false });

  shouldComponentUpdate(prevProp, prevState) {
    return true;
  }

  handleAccountChange = (id, name) => {
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: true,
        title: 'Set this account for 529 Beneficiary contribution?',
        bodyText: 'Are you sure you would like to set this account to receive Employer 529 Beneficiary contribution?',
        footerButton: true,
        footerButtonName: "Yes, I'm sure",
        svgType: 'info',
        footerButtonOnclick: this.changeAccountSuccess,
        footerButtonHref: '',
        footerButtonOnclickArgs: [id, name]
      }
    })

  }

  changeAccountSuccess = (id, name) => {
    const requestData = {
      data: {
        attributes: {
          pay_active: true
        },
        id,
      },
    }
    this.props.postEditServicerAccounts(requestData);

    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: true,
        title: 'Your Employer Contribution has been set to your new 529 account!',
        bodyText: 'Your employer contributions have been directed to the 529 account with the beneficiary "' + name + '."',
        footerButton: true,
        footerButtonName: "Back to Dashboard",
        svgType: 'check',
        footerButtonOnclick: this.resetCustomModal,
        footerButtonHref: '',
        footerButtonOnclickArgs: ''
      }
    })

  }
  resetCustomModal = () => {
    this.setState({
      ...this.state,
      modalProp: {
        ...this.state.modalProp,
        show: false,
      }
    })
  }
  render() {
    const { servicerAccounts, payActiveServicerAccount, employerData, hideEmployerContribution, customerData } = this.state;
    const quatroWidget = employerData ? employerData.attributes.quatro_widget : "";
    // -- used to sort the table data by beneficiary name
    servicerAccounts.sort((a, b) => a?.attributes?.beneficiary_name && a?.attributes?.beneficiary_name.localeCompare(b?.attributes?.beneficiary_name))
    const servicerAccountsFiltered = servicerAccounts.filter((x) => x.attributes.is_fivetwonine)
    if (customerData && (!customerData?.attributes?.any_fivetwonine_terms || !customerData?.attributes?.any_esign_disclosure)) {
      return (
        <Redirect to='/intro/plan-savings' />
      )
    }
    return (
      <>
        <Header {...this.props} />
        <div className="G-page-background-color">
          <div className="G-page-main-container p-4 bg-white Gborder-1 rounded-lg my-5">
            <div >
              {/* Header name */}
              <div >
                <h3 className="Gfs-175 my-3 pb-0 sans-semibold"><span className=" Gt-slate-5">Hey&nbsp;</span>
                  <span className="sans-bold G-text-grad">{localStorage.getItem("customerFullName") ? localStorage.getItem("customerFullName").split(' ')[0] : "User"}!</span>
                </h3>
                <p className="sans-medium">Welcome back! Here's an overview of your dashboard</p>
              </div>

              {/* 529 accounts */}
              <div className="mt-3">
                <div className="pinkCircularDiv mb-3">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                    <path d="M33.125 26.5625C33.5394 26.5625 33.9368 26.7271 34.2299 27.0201C34.5229 27.3132 34.6875 27.7106 34.6875 28.125C34.6875 28.5394 34.5229 28.9368 34.2299 29.2299C33.9368 29.5229 33.5394 29.6875 33.125 29.6875C32.7106 29.6875 32.3132 29.5229 32.0201 29.2299C31.7271 28.9368 31.5625 28.5394 31.5625 28.125C31.5625 27.7106 31.7271 27.3132 32.0201 27.0201C32.3132 26.7271 32.7106 26.5625 33.125 26.5625ZM26.875 26.5625C27.2894 26.5625 27.6868 26.7271 27.9799 27.0201C28.2729 27.3132 28.4375 27.7106 28.4375 28.125C28.4375 28.5394 28.2729 28.9368 27.9799 29.2299C27.6868 29.5229 27.2894 29.6875 26.875 29.6875C26.4606 29.6875 26.0632 29.5229 25.7701 29.2299C25.4771 28.9368 25.3125 28.5394 25.3125 28.125C25.3125 27.7106 25.4771 27.3132 25.7701 27.0201C26.0632 26.7271 26.4606 26.5625 26.875 26.5625ZM24.375 32.5H35.625C34.675 34.7125 32.5 36.25 30 36.25C27.5 36.25 25.325 34.7125 24.375 32.5ZM16.25 30C16.25 27.7375 17.75 25.825 19.825 25.2125C21.6125 21.3875 25.5 18.75 30 18.75C34.5 18.75 38.3875 21.3875 40.1875 25.2125C42.25 25.825 43.75 27.7375 43.75 30C43.75 32.2625 42.25 34.175 40.1875 34.7875C38.3875 38.6125 34.5 41.25 30 41.25C25.5 41.25 21.6125 38.6125 19.825 34.7875C17.75 34.175 16.25 32.2625 16.25 30ZM30 21.25C26.025 21.25 22.675 23.9 21.6 27.5H21.25C20.587 27.5 19.9511 27.7634 19.4822 28.2322C19.0134 28.7011 18.75 29.337 18.75 30C18.75 30.663 19.0134 31.2989 19.4822 31.7678C19.9511 32.2366 20.587 32.5 21.25 32.5H21.6C22.675 36.1 26.025 38.75 30 38.75C33.975 38.75 37.325 36.1 38.4 32.5H38.75C39.413 32.5 40.0489 32.2366 40.5178 31.7678C40.9866 31.2989 41.25 30.663 41.25 30C41.25 29.337 40.9866 28.7011 40.5178 28.2322C40.0489 27.7634 39.413 27.5 38.75 27.5H38.4C37.325 23.9 33.975 21.25 30 21.25Z" fill="var(--icon-color)" />
                  </svg>
                </div>
                <h4>529 Accounts</h4>
                <p className="sans-medium">
                  Connect to existing 529 plans or learn about setting up <br /> new direct sold 529 plans here.
                </p>
                <a href={localStorage.getItem('pruSavingsPlanEnabled') === 'true' ? "/dashboard/add-account" : "/dashboard/add-account"}>
                  <button className="bg-transparent border-0 G-orange-button" >
                    <Plus size={28} className='mr-1 mb-1' /> Add a new account
                  </button>
                </a>
              </div>

              {/* beneficiary table */}
              <div className="mt-5 Gtable-div">
                <Table borderless className="Gborder-05 G-table-theme-grey">
                  <thead className="Gt-head">
                    <tr>
                      <th className="Gfs-075 p-3">Beneficiary</th>
                      <th className="Gfs-075 p-3">Account number</th>
                      <th className="Gfs-075 p-3">Contribution to date</th>
                      <th className="Gfs-075 p-3 text-center">Edit</th>
                      {
                        !hideEmployerContribution &&
                        <th className="Gfs-075 p-3 text-center">RECEIVE EMPLOYER'S CONTRIBUTION</th>
                      }
                      <th className="Gfs-075 p-3 text-center">View contributions</th>
                    </tr>
                  </thead>
                  <tbody className="Gt-body">
                    {servicerAccountsFiltered.length > 0
                      ? servicerAccountsFiltered.map((row, i) => {
                        return (
                          <tr key={i}>
                            <td>{row.attributes.beneficiary_name}</td>
                            <td >{row.attributes.account_number}
                              <span className="float-right" data-tip data-for={row.id}> <InfoCircleFill size={14} className="ml-1 Gt-slate-5 " />  </span>
                              <ReactTooltip id={row.id} place="top" effect="solid" >
                                <span>Plan Name: {row.servicer.attributes.name}</span>
                              </ReactTooltip>
                            </td>
                            <td>{numberFormat(row.attributes.contribution_to_date)}</td>
                            <td className="text-center"
                              onClick={() =>
                                localStorage.getItem("pruSavingsPlanEnabled") === "true" ? this.props.history.push(`/dashboard/${row.id}/edit-account`) : this.props.history.push(`/dashboard/${row.id}/edit-account`)
                              }>
                              <Pencil className="Gt-pink-8 G-cursor-pointer" />
                            </td>
                            {
                              !hideEmployerContribution &&
                              <td className="G-radio-pink text-center ">
                                <Form.Check
                                  inline
                                  aria-label={row.id}
                                  name="select-account"
                                  type='radio'
                                  className="select-account-radio-button "
                                  checked={row.id === payActiveServicerAccount?.[0]?.id}
                                  onChange={() => this.handleAccountChange(row.id, row.attributes.beneficiary_name)}
                                />
                              </td>
                            }
                            <td className="text-center"
                              onClick={() =>
                                localStorage.getItem("pruSavingsPlanEnabled") === "true" ? this.props.history.push(`/dashboard/${row.id}/contribution-list`) : this.props.history.push(`/dashboard/${row.id}/contribution-list`)
                              }>
                              <BoxArrowUpRight className="Gt-pink-8 G-cursor-pointer" />
                            </td>
                          </tr>
                        );
                      })
                      : (<tr><td colSpan={6}>You currently do not have any accounts connected.</td></tr>)}
                  </tbody>
                </Table>
              </div>
              {!hideEmployerContribution && (
                <>
                  {/* benefit summary */}
                  <div className="mt-5">
                    <div className="pinkCircularDiv mb-3">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                        <path d="M30 36.5875L37.725 41.25L35.675 32.4625L42.5 26.55L33.5125 25.7875L30 17.5L26.4875 25.7875L17.5 26.55L24.325 32.4625L22.275 41.25L30 36.5875Z" fill="var(--icon-color)" />
                      </svg>
                    </div>
                    <h4>529 Benefit Summary</h4>
                  </div>

                  {/* beneficiary account */}
                  <div >
                    {payActiveServicerAccount.length > 0 ? (
                      payActiveServicerAccount.map(function (data, i) {
                        let account_number = data.attributes.account_number;
                        let account_number_short = account_number.slice(
                          account_number.length - 4
                        );
                        return (
                          <React.Fragment key={i}>
                            <p className='py-3 Gb-green-2 pl-3 rounded-lg sans-semibold Gw-50 '>
                              <Check size={28} className="Gt-green-6" />
                              &nbsp; Your monthly contribution is confirmed
                            </p>
                            {/* account details */}
                            <div className="G-grid41">
                              <div className="Gborder-r05">
                                <span className="Gt-pink-8">Account Beneficiary</span> <br />
                                {data.attributes.beneficiary_name}
                              </div>

                              <div >
                                <span className="Gt-pink-8">Account number</span> <br />
                                {data.attributes.account_number}
                              </div>
                            </div>

                            <div className="G-container-space-evenly Gborder-05 rounded-lg Gmt-1 " key={i}>
                              <div className="p-3 my-3 Gborder-r05 Gfs-087">
                                <label className='G-badge-purple mb-2'> You'll receive</label><br />
                                <span className="Gt-pink-8 Gfs-15 sans-bold ">{numberFormat(data.attributes.match_payment)}</span> <br />
                                every month from &nbsp;
                                {localStorage.getItem("employerName")}
                              </div>

                              <div className=" p-3 my-3 Gborder-r05 Gfs-087">
                                <label className='G-badge-purple mb-2'> Next Payment on</label><br />
                                <span className="Gt-pink-8 Gfs-15 sans-bold ">{payrollDatePlusOneSub(employerData)}</span> <br />
                                Your next payment will be sent on {payrollDatePlusOneSub(employerData)} to the account
                                ending in ...{account_number_short}
                              </div>

                              <div className=" p-3 my-3 Gfs-087">
                                <label className='G-badge-purple mb-2'> Payment sent to</label><br />
                                <span className="Gt-pink-8 Gfs-15 sans-bold ">{data.servicer.attributes.name}</span> <br />
                                Payment will be sent to&nbsp;
                                {data.servicer.attributes.name} at:
                                <br />
                                {data?.address?.attributes.address}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })
                    )
                      :
                      (
                        <>
                          {/* no beneficiary warning */}
                          <div className='py-3 Gb-yellow-2 pl-3 rounded-lg sans-semibold Gw-50 d-flex pr-2'>
                            <div className="my-auto mr-3">
                              <ExclamationTriangleFill size={24} className="Gt-yellow-6" />
                            </div>
                            <div>
                              Confirm your monthly {localStorage.getItem("employerName")} Beneficiary contribution and start receiving your benefits by
                              &nbsp;
                              {servicerAccounts.length > 0 ?
                                <span>checking the option "Enable 529 Beneficiary Contribution" on the table above.</span>
                                : <a href={localStorage.getItem('pruSavingsPlanEnabled') === 'true' ? "/dashboard/add-account" : "/dashboard/add-account"} className="Gt-pink-8 text-decoration-none cancelLink">adding a 529 account.</a>
                              }
                            </div>
                          </div>
                          {/* connect beneficiary message */}
                          <div className="Gborder-05 p-4 rounded-lg mt-4 text-center Gt-slate-1">
                            Connect your 529 account for a detailed benefit summary here.
                          </div>
                        </>
                      )
                    }
                  </div>
                </>
              )}

              {/* Explore resource vault */}
              <div className="mt-5">
                <h4>Explore Vault Resources</h4>
                <p className="sans-medium">
                  Vault is here to provide you with access to resources you can use <br />
                  for your own financial education purposes, whether you're<br />
                  paying down student loans, looking for repayment programs or<br />
                  planning for college.
                </p>
              </div>
            </div>
            {/* resources section  */}
            <div className='G-grid11-2 mt-5'>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-red-1 text-center'>
                  <FriendsAndFamilyContributionSvg />
                </div>
                <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>Friends and Family Contributions</h3>
                <p className='sans-medium mb-4'>
                  Friends and family members can boost your 529 account by directing one-time or periodic gifts to your account.
                  <br />
                  <button className='Gt-pink-8 text-decoration-none cancelLink G-cursor-pointer' onClick={this.openModal}>Learn more here.</button>
                </p>
                <div className="d-flex">
                  <Link to="/dashboard/manage-friends-and-family" className={'text-center text-decoration-none cancelLink ' + (employerData?.attributes?.friends_and_family_contribution ? 'G-cancel-button' : 'G-slate-button G-disable-click')}>
                    Manage Friends and Family Profile
                  </Link>
                </div>
                {employerData?.attributes?.friends_and_family_contribution ?
                  null
                  :
                  <div className='mt-4 py-3 Gb-yellow-2 pl-3 rounded-lg sans-semibold w-100 d-flex pr-2'>
                    <div className="my-auto mr-3">
                      <InfoCircleFill size={24} className="Gt-yellow-6" />
                    </div>
                    <div>
                      Your employer doesn't support friend and family contributions
                    </div>
                  </div>
                }
              </div>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-pink-2 text-center'>
                  <Choose529PlanSvg />
                </div>
                <div className="mt-4">
                  <label className="G-badge-purple">Vault 529 101</label>
                </div>
                <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>How do I choose the 529 plan that is best for me?</h3>
                <p className='sans-medium mb-4'>
                  A 529 plan is a tax-advantaged savings plan designed to encourage saving for future education expenses. 529 plans are a smart move to save for education expenses with some fantastic tax benefits.
                </p>
                <div className="d-flex">
                  <Link to="/dashboard/choose-plan-best-for-me" className={'text-center text-decoration-none cancelLink G-cancel-button'}>
                    Learn more
                  </Link>
                </div>
                {employerData?.attributes?.friends_and_family_contribution ?
                  null
                  :
                  <div className='mt-4 py-3 Gb-yellow-2 pl-3 rounded-lg sans-semibold w-100 d-flex pr-2'>
                    <div className="my-auto mr-3">
                      <InfoCircleFill size={24} className="Gt-yellow-6" />
                    </div>
                    <div>
                      Your employer doesn't support friend and family contributions
                    </div>
                  </div>
                }
              </div>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-orange-2 text-center'>
                  <BenefitsOf529PlanSvg />
                </div>
                <div className="mt-4">
                  <label className="G-badge-purple">Vault 529 101</label>
                </div>
                <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>What are the benefits of a 529 plan?</h3>
                <p className='sans-medium mb-4'>
                  529 plans offer several benefits that make them an attractive option for saving for education expenses.
                </p>
                <div className="d-flex">
                  <Link to="/dashboard/benefits-of-fiveTwoNine-plan" className={'text-center text-decoration-none cancelLink G-cancel-button'}>
                    Learn more
                  </Link>
                </div>
                {employerData?.attributes?.friends_and_family_contribution ?
                  null
                  :
                  <div className='mt-4 py-3 Gb-yellow-2 pl-3 rounded-lg sans-semibold w-100 d-flex pr-2'>
                    <div className="my-auto mr-3">
                      <InfoCircleFill size={24} className="Gt-yellow-6" />
                    </div>
                    <div>
                      Your employer doesn't support friend and family contributions
                    </div>
                  </div>
                }
              </div>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-green-2 text-center'>
                  <PayrollDeductionsSvg />
                </div>
                <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>529 Payroll Deductions</h3>
                <p className='sans-medium mb-4'>
                  One of the easiest ways to consistently contribute to your child's education savings is by setting up payroll deductions to your 529 account. Many plans offer the option to set this up online, while others require a form to be filled out and sent to your payroll provider for processing.
                </p>
                <a href="/dashboard/payroll-deduction" className='G-cancel-button'>
                  <button className='Gt-pink-8 bg-white border-0'>
                    Learn More
                  </button>
                </a>
              </div>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-yellow-2 text-center'>
                  <Faq529Svg />
                </div>
                <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>529 FAQs</h3>
                <p className='sans-medium mb-4'>
                  Read up on 529 basics to kick off college planning.
                </p>
                <div className="d-flex">
                  <a href="/dashboard/faqs" className='G-cancel-button'>
                    <button className='Gt-pink-8 bg-white border-0'>
                      College Planning 101
                    </button>
                  </a>
                </div>
              </div>
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-blue-50 text-center'>
                  <TaxStateSvg />
                </div>
                <h3 className='Gt-slate-5 Gfs-15 my-3 pb-0'>529 Tax Benefits by State</h3>
                <p className='sans-medium mb-4'>
                  Explore each states 529 tax benefits to find the right choice for you.
                </p>
                <Link to="/dashboard/map" className='G-cancel-button'>
                  <button className='Gt-pink-8 bg-white border-0'>
                    View States
                  </button>
                </Link>
              </div>
              {quatroWidget === true &&
                <div className='Gborder-05 p-4 rounded-lg bg-white'>
                  <div className='Gb-green-2 text-center'>
                    <CollegeSavingSvg />
                  </div>
                  <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>College Savings</h3>
                  <p className='sans-medium mb-4'>Read up on 529 basics to kick off college planning</p>
                  <Link to="/dashboard/collegeplanning" className='G-cancel-button'>
                    <button className='border-0 bg-white Gt-pink-8'>College Savings</button>
                  </Link>
                </div>
              }
              <div className='Gborder-05 p-4 rounded-lg bg-white'>
                <div className='Gb-pink-2 text-center'>
                  <DtiCalculatorSvg />
                </div>
                <h3 className='Gt-slate-5 Gfs-175 my-3 pb-0'>DTI Calculator</h3>
                <p className='sans-medium mb-4'>Find ways to lower your debt-to-income ratio en route to qualifying for better products.</p>
                <Link to="/dashboard/dti-calculator" className='G-cancel-button'>
                  <button className='Gt-pink-8 bg-white border-0'>Calculate DTI</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* Modal for friends and family contributions learn more */}
        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Body>
            <div className="d-flex">
              <div className="pinkCircularDiv mb-3 mr-3">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="60" height="60" rx="30" fill="var(--icon-background-color)" />
                  <path d="M42.1376 25.8881C43.7001 24.3256 42.9876 22.5006 42.1376 21.6131L38.3876 17.8631C36.8126 16.3006 35.0001 17.0131 34.1126 17.8631L31.9876 20.0006H28.7501C26.3751 20.0006 25.0001 21.2506 24.3001 22.6881L18.7501 28.2381V33.2381L17.8626 34.1131C16.3001 35.6881 17.0126 37.5006 17.8626 38.3881L21.6126 42.1381C22.2876 42.8131 23.0126 43.0631 23.7001 43.0631C24.5876 43.0631 25.4001 42.6256 25.8876 42.1381L29.2626 38.7506H33.7501C35.8751 38.7506 36.9501 37.4256 37.3376 36.1256C38.7501 35.7506 39.5251 34.6756 39.8376 33.6256C41.7751 33.1256 42.5001 31.2881 42.5001 30.0006V26.2506H41.7626L42.1376 25.8881ZM40.0001 30.0006C40.0001 30.5631 39.7626 31.2506 38.7501 31.2506H37.5001V32.5006C37.5001 33.0631 37.2626 33.7506 36.2501 33.7506H35.0001V35.0006C35.0001 35.5631 34.7626 36.2506 33.7501 36.2506H28.2376L24.1376 40.3506C23.7501 40.7131 23.5251 40.5006 23.3876 40.3631L19.6501 36.6381C19.2876 36.2506 19.5001 36.0256 19.6376 35.8881L21.2501 34.2631V29.2631L23.7501 26.7631V28.7506C23.7501 30.2631 24.7501 32.5006 27.5001 32.5006C30.2501 32.5006 31.2501 30.2631 31.2501 28.7506H40.0001V30.0006ZM40.3626 24.1131L38.2376 26.2506H28.7501V28.7506C28.7501 29.3131 28.5126 30.0006 27.5001 30.0006C26.4876 30.0006 26.2501 29.3131 26.2501 28.7506V25.0006C26.2501 24.4256 26.4626 22.5006 28.7501 22.5006H33.0126L35.8626 19.6506C36.2501 19.2881 36.4751 19.5006 36.6126 19.6381L40.3501 23.3631C40.7126 23.7506 40.5001 23.9756 40.3626 24.1131Z" fill="var(--icon-color)" />
                </svg>
              </div>
              <div>
                <div className="d-flex">
                  <h4 className="py-3">Friends and Family Contributions</h4>
                  <div> <button onClick={this.closeModal} className="text-decoration-none cancelLink G-cursor-pointer modal-close-button-xlg"><XLg /></button></div>
                </div>
                <p className="sans-medium">
                  Friends and family members can boost your 529 account by directing one-time or periodic gifts to your account.
                  <br />
                  <br />
                  Funds are transferred from a relative's bank account and deposited into your child's account at no fee.
                  <br />
                  <br />
                  Remember, gift-givers can contribute up to $15,000 to a 529 account per beneficiary, per year with no gift tax ramifications. So, a married couple could gift up to $30,000 per year to beneficiary without having to pay a gift tax or erode their lifetime gift tax exclusion.
                  These are the amounts based off federal tax law as of January 2022.
                  <br />
                  <br />
                  There are additional rules regarding gift and estate taxes that may apply to your situation and are subject to change.
                  <br />
                  You should consult a tax professional to discuss your situation and the impact of these complex rules in detail.
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <CustomModal svgComponent={this.state.modalProp.svgType === 'check' ?
          <div className="greenCircularDiv p-3 mb-3 mr-3"><Check size={28} className="Gt-green-6" /></div> :
          <div className="yellowCircularDiv p-3 mb-3 mr-3"><InfoCircle size={28} className="Gt-yellow-6" /></div>
        }
          modalProp={{ ...this.state.modalProp }} />
      </>
    );
  }
}

Dashboard.propTypes = {
  getServicerAccounts: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  postEditServicerAccounts: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  servicerAccounts: state.five_two_nine.getServicerAccountsData,
  employerData: state.employer_array.employerData,
  customerData: state.customer_array.customerData,
  errors: state.errors,
  servicerAccountsData: state.five_two_nine.servicerAccountsData,

});
export default connect(mapStateToProps, { getServicerAccounts, fetchEmployer, fetchCustomer, postEditServicerAccounts })(
  Dashboard
);
