import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from "../Includes/Header/Header";
import Footer from "../Includes/Footer/Footer";
import TuitionReimbursementStatusPending from "./TuitionReimbursementStatusPending";
import TuitionReimbursementStatusApproved from "./TuitionReimbursementStatusApproved";
import TuitionReimbursementStatusNeedMoreInfo from "./TuitionReimbursementStatusNeedMoreInfo";
import TuitionReimbursementStatusRejected from "./TuitionReimbursementStatusRejected";
import { fetchTuitionById, fetchServicerAccount, fetchAddress } from '../../actions/tuitionReimbursementActions';
import { responseMessage } from '../../utils/alert';
import { fetchEmployer } from '../../actions/employerActions';
import { tuitionReimburseStatus } from './TuitionReimbursementStatus.constant';

class TuitionReimbursementStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tuitionId: this.props.match.params.tuitionId,
      fetchIndServiceAccountData: {},
      servicerAccountData: {},
      errors: null,
      employerData: null
    }
  }
  componentDidMount() {
    document.title = "Vault | Tuition Reimbursement Status"
    let tuitionId = this.state.tuitionId;
    const { fetchTuitionById, fetchEmployer } = this.props;
    fetchTuitionById(tuitionId);
    fetchEmployer();
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tuitionByIdData !== this.props.tuitionByIdData) {
      this.setState({
        fetchIndServiceAccountData: nextProps.tuitionByIdData
      })
      const servicerAccountId = nextProps.tuitionByIdData?.data?.relationships?.servicer_account?.data?.id;
      this.props.fetchServicerAccount(servicerAccountId);
    }
    if (this.props.servicerAccountData !== nextProps.servicerAccountData) {
      const servicerAccountData = nextProps.servicerAccountData;
      this.setState({
        ...this.state,
        servicerAccountData: servicerAccountData,
      })
      this.props.fetchAddress(servicerAccountData?.relationships?.address?.data?.id);
    }
    if (this.props.addressData !== nextProps.addressData) {
      const addressData = nextProps.addressData;
      this.setState({
        addressData: addressData
      })
    }
    if (this.props.errors !== nextProps.errors) {
      responseMessage("error", nextProps.errors.errors, "");
    }
    if (nextProps.employerData !== this.props.employerData) {
      this.setState({
        employerData: nextProps.employerData
      })
    }
  }
  render() {
    const { tuitionId, fetchIndServiceAccountData, servicerAccountData, employerData, addressData } = this.state;
    return (
      <>
        <Header hideNotificationAndProfileDropdown={true} />
        <div className="G-page-background-color">
          <div className="G-page-main-container my-5">
            {
              fetchIndServiceAccountData?.data?.attributes ?
                fetchIndServiceAccountData.data.attributes.status.toUpperCase() === tuitionReimburseStatus.pending.toUpperCase() ?
                  <TuitionReimbursementStatusPending tuitionId={tuitionId} fetchIndServiceAccountData={fetchIndServiceAccountData} addressData={addressData} servicerAccountData={servicerAccountData} />
                  : fetchIndServiceAccountData.data.attributes.status.toUpperCase() === tuitionReimburseStatus.approved.toUpperCase() ?
                    <TuitionReimbursementStatusApproved data={fetchIndServiceAccountData} addressData={addressData} servicerAccountData={servicerAccountData} employerData={employerData} />
                    : fetchIndServiceAccountData.data.attributes.status.toUpperCase() === tuitionReimburseStatus.rejected.toUpperCase() ?
                      <TuitionReimbursementStatusRejected tuitionId={tuitionId} fetchIndServiceAccountData={fetchIndServiceAccountData} addressData={addressData} servicerAccountData={servicerAccountData} />
                      : fetchIndServiceAccountData.data.attributes.status.toUpperCase() === tuitionReimburseStatus.needMoreInfoSnake.toUpperCase() ?
                        <TuitionReimbursementStatusNeedMoreInfo tuitionId={tuitionId} fetchIndServiceAccountData={fetchIndServiceAccountData} addressData={addressData} servicerAccountData={servicerAccountData} />
                        : ""
                : ""
            }
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

TuitionReimbursementStatus.propTypes = {
  fetchTuitionById: PropTypes.func.isRequired,
  fetchServicerAccount: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  fetchAddress: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  tuitionByIdData: state.tuition_reimbursement.tuitionByIdData,
  servicerAccountData: state.tuition_reimbursement.servicerAccountData,
  addressData: state.tuition_reimbursement.addressData,
  employerData: state.employer_array.employerData,
  errors: state.errors
});
export default connect(mapStateToProps, { fetchTuitionById, fetchServicerAccount, fetchEmployer, fetchAddress })(TuitionReimbursementStatus);
